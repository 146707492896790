import React, { useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Col,
  Row,
  Select,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { ColCenter, ColSpaceBetween, SelectStyled } from "../general_styled";
import {
  ContentStyled,
  ContentCardStyled,
  TitleStyled,
  SubtitleStyled,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import checkImg from "../../assets/images/sell-nft-check.svg";
import ibfx1 from "../../assets/images/ibfx-1.svg";
import ibfs from "../../assets/images/ibfs.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import {
  getVolunteered,
  projectVolunteerSendReward,
} from "../../modules/benevolence/action";
import BenevolenceOnBoarding from "../../pages/BenevolenceOnBoarding";
// import { extractErrorMessage } from "../../utils/extractErrorMessage"

const { Title, Text } = Typography;

export default function ModalClaimVolunteer(props) {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userVolunteerId = useSelector((state) =>
    state.getIn(["benevolence", "userVolunteer", "volunteer_id"])
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isIBFS, setIsIBFS] = useState(true);
  const [projectVolunteerId, setProjectVolunteerId] = useState(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsIBFS(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const data = {
        project_volunteer_id: props.data.project_volunteer_id,
        paid_with: isIBFS ? "IBFs" : "IBFx",
      };

      await projectVolunteerSendReward(data);
      notification.success({
        message: "Project Reward",
        description: "Claim Project Volunteer Success!",
        duration: 5,
        placement: "bottomRight",
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Project Reward",
        description: "Claim Project Volunteer Failed!",
        duration: 5,
        placement: "bottomRight",
      });
    }

    setIsLoading(false);
    setProjectVolunteerId(null);
    setProjectVolunteerId(null);
    setIsModalVisible(false);
    dispatch(getVolunteered(1));
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  return (
    <>
      <Button
        block
        style={{ height: 40, borderRadius: 4 }}
        type="primary"
        onClick={showModal}
      >
        Claim Reward
      </Button>
      {!props?.isUserVerified ? (
        <Modal
          visible={isModalVisible}
          centered
          className="modal-top-up"
          footer={null}
          onCancel={handleCancel}
          closeIcon={<img src={closeIcon} onClick={handleCancel} />}
          maskStyle={{ backgroundColor: "#f3f6fa" }}
        >
          <BenevolenceOnBoarding onClose={handleCancel} />
        </Modal>
      ) : windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
          width={"500px"}
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Claim Reward
              </Title>
              <Text className="gray-7" align="center">
                Choose your payment reward
              </Text>
              <Row gutter={20} className="w-100 mt-24px">
                {/* <Col span={12}>
                  <ContentCardStyled
                    onClick={() => setIsIBFS(false)}
                    className={!isIBFS ? "selected" : ""}
                  >
                    {!isIBFS && <img src={checkImg} className="check" />}
                    <ColSpaceBetween>
                      <img
                        src={ibfx1}
                        style={{
                          width: "80px",
                          height: "80px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      />
                      <TitleStyled
                        className={"mt-8px " + (isIBFS ? "selected" : "")}
                      >
                        IBFX
                      </TitleStyled>
                      <SubtitleStyled className={isIBFS ? "selected" : ""}>
                        Token for transactions across IBFNex - Netverse network
                      </SubtitleStyled>
                    </ColSpaceBetween>
                  </ContentCardStyled>
                </Col> */}
                <Col span={24}>
                  <ContentCardStyled>
                    {isIBFS && <img src={checkImg} className="selected" />}
                    <ColSpaceBetween>
                      <img
                        src={ibfs}
                        style={{
                          width: "80px",
                          height: "80px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      />
                      <TitleStyled className="selected">IBFS</TitleStyled>
                      <SubtitleStyled className="selected mt-6px mb-6px">
                        This measures your benevolent contribution to the
                        project through IBFS tokens, which are social tokens
                        that function as reward points, making you eligible for
                        gifts or awards based on your accumulated IBFS.
                      </SubtitleStyled>
                      {/* <ModalSellNFTAuction
                        isIBFS={false}
                        isActive={!isIBFS}
                        metadata={props.metadata}
                        handleCancel={handleCancel}
                      /> */}
                    </ColSpaceBetween>
                  </ContentCardStyled>
                </Col>
                {/* <SubtitleStyled className="pt-12px mx-12px">
                  Select Your Volunteer
                </SubtitleStyled>
                <SelectStyled
                  value={projectVolunteerId}
                  onSelect={(e) => setProjectVolunteerId(e)}
                  className="mx-12px"
                >
                  {props?.data?.project_volunteers?.map((e) => {
                    if (
                      e.status === "FINALIZED" &&
                      e.volunteer_id === userVolunteerId
                    ) {
                      return (
                        <Select.Option value={e.project_volunteer_id}>
                          {`${e.title} - ${e.working_hours} Hours - ${e.rating}`}{" "}
                          &#9733;
                        </Select.Option>
                      );
                    }
                  })}
                </SelectStyled> */}
                <Button
                  block
                  // disabled={!projectVolunteerId}
                  loading={isLoading}
                  type="primary"
                  className="my-24px mx-12px"
                  style={{ height: 48, borderRadius: 6 }}
                  onClick={() => onSubmit()}
                >
                  {`Claim ${isIBFS ? "IBFS Token" : "IBFX Token"}`}
                </Button>
              </Row>
              {/*
              <Button
                block
                ghost
                type="primary"
                className="mt-12px"
                size="large"
                onClick={() => handleCancel()}
              >
                Dismiss
              </Button> */}
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Claim Reward
              </Title>
              <Text className="gray-7" align="center">
                Choose your payment reward
              </Text>
              <Row gutter={20} className="w-100 mt-24px">
                <Col span={12}>
                  <ContentCardStyled
                    onClick={() => setIsIBFS(false)}
                    className={!isIBFS ? "selected" : ""}
                  >
                    {!isIBFS && <img src={checkImg} className="check" />}
                    <ColSpaceBetween>
                      <img
                        src={ibfx1}
                        style={{
                          width: "80px",
                          height: "80px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      />
                      <TitleStyled
                        className={"mt-8px " + (isIBFS ? "selected" : "")}
                      >
                        IBFX
                      </TitleStyled>
                      <SubtitleStyled className={isIBFS ? "selected" : ""}>
                        Token for transactions across IBFNex - Netverse network
                      </SubtitleStyled>
                    </ColSpaceBetween>
                  </ContentCardStyled>
                </Col>
                <Col span={12}>
                  <ContentCardStyled
                    onClick={() => setIsIBFS(true)}
                    className={isIBFS ? "selected" : ""}
                  >
                    {isIBFS && <img src={checkImg} className="check" />}
                    <ColSpaceBetween>
                      <img
                        src={ibfs}
                        style={{
                          width: "80px",
                          height: "80px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      />
                      <TitleStyled
                        className={"mt-8px " + (!isIBFS ? "selected" : "")}
                      >
                        IBFS
                      </TitleStyled>
                      <SubtitleStyled className={!isIBFS ? "selected" : ""}>
                        Esteem Value tokens for Benevolence, Currently not
                        supported
                      </SubtitleStyled>
                      {/* <ModalSellNFTAuction
                        isIBFS={false}
                        isActive={!isIBFS}
                        metadata={props.metadata}
                        handleCancel={handleCancel}
                      /> */}
                    </ColSpaceBetween>
                  </ContentCardStyled>
                </Col>
                <SubtitleStyled className="pt-12px mx-12px">
                  Select Your Volunteer
                </SubtitleStyled>
                <SelectStyled
                  value={projectVolunteerId}
                  onSelect={(e) => setProjectVolunteerId(e)}
                  className="mx-12px"
                >
                  {props?.data?.project_volunteers?.map((e) => {
                    if (
                      e.status === "FINALIZED" &&
                      e.volunteer_id === userVolunteerId
                    ) {
                      return (
                        <Select.Option value={e.project_volunteer_id}>
                          {`${e.title} - ${e.working_hours} Hours - ${e.rating}`}{" "}
                          &#9733;
                        </Select.Option>
                      );
                    }
                  })}
                </SelectStyled>
                <Button
                  block
                  disabled={!projectVolunteerId}
                  loading={isLoading}
                  type="primary"
                  className="my-24px mx-12px"
                  style={{ height: 48, borderRadius: 6 }}
                  onClick={() => onSubmit()}
                >
                  {`Claim ${isIBFS ? "IBFS Token" : "IBFX Token"}`}
                </Button>
              </Row>
              {/*
              <Button
                block
                ghost
                type="primary"
                className="mt-12px"
                size="large"
                onClick={() => handleCancel()}
              >
                Dismiss
              </Button> */}
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
