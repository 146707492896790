import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Typography,
  Select,
  Avatar,
  Collapse,
  Progress,
  Popover,
  notification,
} from "antd";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useContractRead,
} from "wagmi";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../utils/windowDimensions";
import LockMini from "../../assets/images/icon_lock_mini.svg";
import {
  CollectionNameStyled,
  TitleStyled,
  UnlockableContent,
  PriceHistory,
  DividerCard,
  MoreStyled,
  PropertiesCard,
  RoyaltyContent,
  TextStyled,
  TextLabel,
  TextValue,
  LabelNFTApproved,
  DetailLabel,
  DetailValue,
} from "./styled";
import {
  RowSpaceBetween,
  RowStart,
  RowEnd,
  ColStart,
  ColEnd,
  ButtonOutline,
  ColCenter,
} from "../../components/general_styled";
import { Line } from "@ant-design/plots";
import IBFxIcon from "../../assets/images/ibfx-icon.svg";
import IconTwitter from "../../assets/images/icon_twitter.svg";
import IconInstagram from "../../assets/images/icon_instagram.svg";
import IconWeb from "../../assets/images/icon_web.svg";
import IconLove from "../../assets/images/icon_love.svg";
import IconUnloved from "../../assets/images/icon_unloved.svg";
import UserDummy from "../../assets/images/default-avatar.png";
import CardShoppe from "../../components/CardShoppe";
import { useDispatch, useSelector } from "react-redux";
import {
  formatterCurrency,
  fromIBFN,
  fromIBFx,
  textSubstring,
} from "../../utils/utils";
import {
  getAllEssence,
  getDetailEssence,
  getPriceHistory,
  updateWishlistEssence,
} from "../../modules/essence/action";
import {
  ALGO_EXPLORER_URL,
  CREDENCE_HOST,
  IS_EVM,
  MEMBERSHIP_HOST,
  nftMarketplaceAddress,
  paymentList,
} from "../../configs";
import { IconStyled } from "../CredenceNFTDetail/styled";
import {
  getUserDetailByAddress,
  getUserDetailById,
} from "../../modules/auth/action";
import ModalBuyNFT from "../../components/ModalBuyNFT";
import jwtDecode from "jwt-decode";
import ModalDelistingNFT from "../../components/ModalDelistingNFT";
import ModalLoansNewRequest from "../../components/ModalLoansNewRequest";
import { setSection } from "../../modules/general/action";
import { setSelectedCollection } from "../../modules/affluence/action";
import moment from "moment";
import SEO from "../../components/SEO";
import { ButtonStyled } from "../CourseOnBoarding/styled";

import AbiERC20 from "../../assets/abi/ERC20.json";
import AbiIBFNFTMarketplace from "../../assets/abi/IBFNFTMarketplaceV1.json";

const { Text, Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

export default function AffluenceDetailNFT() {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const { isConnected, address } = useAccount();
  const dispatch = useDispatch();
  // const [menu, setMenu] = useState('details')
  // const [creatorData, setCreatorData] = useState()
  const [isReadMoreActive, setReadMoreActive] = useState(false);
  const [ownerData, setOwnerData] = useState();
  const [otherCollection, setOtherCollection] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [messageSig, setMessageSig] = useState();
  const [etherSplit, setEtherSplit] = useState({ v: "", r: "", s: "" });
  const [nonce, setNonce] = useState("0");
  const [creator, setCreator] = useState("");
  const [nftCollection, setNftCollection] = useState();

  const params = useSelector((state) => state.getIn(["general", "param"]));

  const detailNft = useSelector((state) =>
    state.getIn(["essence", "detailNft"]).toJS()
  );
  const priceHistory = useSelector((state) =>
    state.getIn(["essence", "priceHistory"]).toJS()
  );
  const wishlist = useSelector((state) =>
    state.getIn(["essence", "wishlist"]).toJS()
  );
  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );

  useEffect(() => {
    if (Object.keys(detailNft).length <= 0 || !detailNft.Transactions) {
      initDetailNft();
    }
    if (!ownerData) {
      initDetailUser();
    }
    // if (priceHistory.length <= 0) {
    if (detailNft?.nft_id) {
      dispatch(getPriceHistory(detailNft?.nft_id));
    }
    // }
    if (!otherCollection) {
      getOtherCollection();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(detailNft).length > 0) {
      if (!ownerData) {
        initDetailUser();
      }
      // if (priceHistory.length <= 0) {
      // dispatch(getPriceHistory(detailNft?.nft_id));
      // }
      if (!otherCollection) {
        getOtherCollection();
      }
      if (IS_EVM) {
        if (isConnected) {
          setIsOwner(detailNft?.seller_address === address);
        }
        setIsFetched(true);
      } else {
        if (localStorage.getItem("USER_AUTH_TOKEN")) {
          const userData = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
          setIsOwner(userData?.user_id === detailNft?.owner_id);
        }
      }

      if (IS_EVM) {
        if (detailNft?.NFT?.EvmCollection?.EvmNFTs?.length > 0) {
          setNftCollection(
            `${CREDENCE_HOST}/files/${detailNft?.NFT?.EvmCollection?.EvmNFTs[0]?.cover_file}`
          );
        } else {
          setNftCollection(UserDummy);
        }
      } else {
        if (detailNft?.NFT?.Collection?.NFTs?.length > 0) {
          setNftCollection(
            `${CREDENCE_HOST}/files/${detailNft?.NFT?.Collection?.NFTs[0]?.cover_file}`
          );
        } else {
          setNftCollection(UserDummy);
        }
      }
    }
  }, [detailNft]);

  useEffect(() => {
    if (Object.keys(detailNft).length > 0) {
      // Get data v, r, s for paramater Buy NFT
      if (IS_EVM) {
        const { v, r, s } = ethers.utils.splitSignature(
          detailNft?.signature_hash
        );
        setEtherSplit({ v, r, s });
        setMessageSig({
          seller: detailNft?.seller_address,
          contractPayment: detailNft?.contract_payment, // if payment use ether, set value address null. But if use another token, set value contract address token
          contractNFT: detailNft?.NFT?.contract_address, // set value contract nft for sell
          tokenId: detailNft?.EvmNFTSub?.token_id?.toString() || "0", // if tokenId not exist, use 0 (zero) for lazy minting
          price: detailNft?.price?.toString(),
          royaltyAddress: detailNft?.NFT?.creator_wallet_address, // set value from royalty address received database (owner contract nft, not only own nft)
          royaltyFee: Number(detailNft?.NFT?.royalty_fee * 100).toFixed(0), // if fee is 1%, set value is 100
          startEpoch: detailNft?.epoch_start, // use epoch time
          endEpoch: detailNft?.epoch_end,
        });
        const dateNow = new Date().getTime();
        setNonce(`${detailNft?.trx_id}${dateNow}`);
      }
      initCreatorData();
    }
  }, [isFetched]);

  const initDetailNft = async () => {
    // console.log({ params })
    await dispatch(getDetailEssence(params));
  };

  const initCreatorData = async () => {
    const _creator = await getUserDetailByAddress(
      detailNft?.NFT?.creator_wallet_address
    );
    setCreator(_creator);
  };

  const initDetailUser = async () => {
    // const creatorData = await getUserDetailById(detailNft?.NFT?.user_id)
    const ownerData = IS_EVM
      ? await getUserDetailByAddress(detailNft?.seller_address)
      : await getUserDetailById(detailNft?.owner_id);

    // setCreatorData(creatorData)
    setOwnerData(ownerData);
  };

  const getOtherCollection = async () => {
    const tempOtherCollection = await dispatch(
      getAllEssence(null, 1, null, detailNft?.collection_id, true)
    );
    setOtherCollection(tempOtherCollection);
  };

  const isExistOnWishlist = (sellEscrowId) => {
    const exist = wishlist.find((w) => w.sell_escrow_id === sellEscrowId);
    return exist ? true : false;
  };

  const onUpdateWishlist = () => {
    dispatch(updateWishlistEssence(detailNft));
  };

  const onUserClicked = () => {
    if (IS_EVM) {
      dispatch(
        setSection("affluence-user", detailNft?.NFT?.creator_wallet_address)
      );
    } else {
      dispatch(setSection("affluence-user", detailNft?.owner_id));
    }
  };

  const onCollectionClicked = () => {
    dispatch(
      setSelectedCollection({
        collection_id: detailNft?.original_collection_id.toString(),
        ...detailNft?.metadata.collection,
      })
    );
    dispatch(
      setSection(
        "affluence-collection",
        detailNft?.original_collection_id.toString()
      )
    );
  };

  const { data: dataAmountAllowanceTokenPayment } = useContractRead({
    address: detailNft?.contract_payment, // set value contract token payment
    abi: AbiERC20,
    functionName: "allowance",
    args: [
      address, // address buyer
      nftMarketplaceAddress, // address NFT Marketplace
    ],
  });

  // Set Allowance Token Payment
  const { config: configSetAllowanceTokenPayment } = usePrepareContractWrite({
    address: detailNft?.contract_payment, // set value contract token payment
    abi: AbiERC20,
    functionName: "increaseAllowance",
    args: [
      nftMarketplaceAddress, // address NFT Marketplace
      "1000000000000000000", // it doesn't matter if you don't replace it
    ],
  });
  const {
    writeAsync: writeSetAllowanceTokenPayment,
    isLoading: isLoadingSetAllowance,
    isSuccess: isSuccessSetAllowanceNft,
  } = useContractWrite(configSetAllowanceTokenPayment);

  // const { v, r, s } = ethers.utils.splitSignature(
  //   "0x3a7bc820562b1c887ded47c96c1d2463024b0115481c63bda0515c0140531df77648f92470eaeea6991d1ec41e890c631bd52ac8e1bd3f1605b8e511f80c58041c"
  // );

  // Buy NFT
  const { config: configBuyNft } = usePrepareContractWrite({
    address: nftMarketplaceAddress,
    abi: AbiIBFNFTMarketplace,
    functionName: "buyNFT",
    args: [
      etherSplit.v.toString(), // from splitSignature
      etherSplit.r, // from splitSignature
      etherSplit.s, // from splitSignature
      messageSig,
      "", // uri (hash ipfs or link json)
      nonce, // id uniq - number (manage from database) / generate nonce
    ],
  });
  const { writeAsync: writeBuyNft, isSuccess: isSuccessBuyNft } =
    useContractWrite(configBuyNft);

  const { data: dataBalanceTokenPayment } = useContractRead({
    address: messageSig?.contractPayment, // set value contract token payment
    abi: AbiERC20,
    functionName: "balanceOf",
    args: [
      address, // address buyer
    ],
  });

  useEffect(() => {
    if (isSuccessBuyNft) {
      notification.success({
        description:
          "Your NFT purchase was successful. Your Balance will soon be deducted, and the transaction activity can be viewed on your wallet app",
      });
    }
  }, [isSuccessBuyNft]);

  useEffect(() => {
    if (isSuccessSetAllowanceNft) {
      notification.success({
        description:
          "Permission granted for NFT Sale, Please wait for blockchain confirmation & refresh the page after 1 mins",
      });
    }
  }, [isLoadingSetAllowance]);

  const config = {
    data: priceHistory,
    padding: "auto",
    xField: "created_at",
    yField: "total_ibfx",
    xAxis: {
      // type: 'timeCat',
      // tickCount: 5,
    },
  };

  const substractDate = (type, value) => {
    var d = new Date();
    if (type === "day") {
      d.setDate(d.getDate() - value);
    } else if (type === "month") {
      d.setMonth(d.getMonth() - value);
    } else if (type === "year") {
      d.setFullYear(d.getFullYear() - value);
    }
    return d.getTime();
  };

  const onTimeChange = (value) => {
    if (value === "all_time") {
      dispatch(getPriceHistory(detailNft?.nft_id));
    } else if (value === "3days") {
      dispatch(
        getPriceHistory(detailNft?.nft_id, substractDate("day", 3)),
        new Date.getTime()
      );
    } else if (value === "1week") {
      dispatch(
        getPriceHistory(detailNft?.nft_id, substractDate("day", 7)),
        new Date.getTime()
      );
    } else if (value === "1month") {
      dispatch(
        getPriceHistory(detailNft?.nft_id, substractDate("month", 1)),
        new Date.getTime()
      );
    } else if (value === "3month") {
      dispatch(
        getPriceHistory(detailNft?.nft_id, substractDate("month", 3)),
        new Date.getTime()
      );
    } else if (value === "6month") {
      dispatch(
        getPriceHistory(detailNft?.nft_id, substractDate("month", 6)),
        new Date.getTime()
      );
    } else if (value === "1year") {
      dispatch(
        getPriceHistory(detailNft?.nft_id, substractDate("year", 1)),
        new Date.getTime()
      );
    }
  };

  const checkAttribute = (type) => {
    if (detailNft) {
      const attribute = detailNft?.metadata?.attributes;
      if (type === "properties") {
        return attribute?.find((item) => !item.display_type);
      } else if (type === "properties") {
        return attribute?.find((item) => item.display_type === "Levels");
      } else if (type === "stats") {
        return attribute?.find((item) => item.display_type === "Stats");
      }
    }
  };

  const formatAttributes = (type) => {
    const attribute = detailNft?.metadata?.attributes;

    if (!attribute) {
      return;
    }

    if (type === "properties") {
      const filteredProperties = attribute?.filter(
        (item) => !item.display_type
      );
      return filteredProperties.map((item, index) => (
        <Col
          key={"property" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="label">{item.trait_type}</div>
            <div className="name">{item.value}</div>
            {/* <div className="value">61% have this trait</div> */}
          </PropertiesCard>
        </Col>
      ));
    } else if (type === "levels") {
      const filteredLevels = attribute?.filter(
        (item) => item.display_type === "Levels"
      );
      return filteredLevels.map((item, index) => (
        <Col
          key={"levels" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="name">{item.trait_type}</div>
            <Progress
              className="mt-8px"
              percent={Math.round(
                (parseInt(item.value) / parseInt(item.max_value)) * 100
              )}
              trailColor="#DEE2E7"
              strokeColor="#3B4552"
            />
          </PropertiesCard>
        </Col>
      ));
    } else if (type === "stats") {
      const filteredStats = attribute?.filter(
        (item) => item.display_type === "Stats"
      );
      return filteredStats.map((item, index) => (
        <Col
          key={"stats" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="name">{item.trait_type}</div>
            <div className="value">
              {Math.round(
                (parseInt(item.value) / parseInt(item.max_value)) * 100
              )}
              % have this trait
            </div>
          </PropertiesCard>
        </Col>
      ));
    }
  };

  const formatLink = (additionalUrl, link) => {
    const isHaveWWW = link.includes("www");
    const isHaveHTTP = link.includes("http");
    const isHaveOnlyCOM = !(isHaveHTTP || isHaveHTTP) && link.includes("com");
    if (isHaveHTTP) {
      return link;
    } else if (isHaveHTTP && isHaveWWW) {
      return link;
    } else if (!isHaveHTTP && isHaveWWW) {
      return `http://${link}`;
    } else if (isHaveOnlyCOM) {
      return `http://${link}`;
    } else {
      return `${additionalUrl}${link}`;
    }
  };

  const renderDetailInfo = () => (
    <Collapse defaultActiveKey={["description"]}>
      <Panel header="Description" key="description">
        <Row gutter={[16, 16]}>
          <Text>{detailNft?.metadata?.description}</Text>
        </Row>
      </Panel>
      {checkAttribute("properties") && (
        <Panel header="Properties" key="properties">
          <Row gutter={[16, 16]}>
            {detailNft && formatAttributes("properties")}
          </Row>
        </Panel>
      )}
      {checkAttribute("levels") && (
        <Panel header="Levels" key="levels">
          <Row gutter={[16, 16]}>{detailNft && formatAttributes("levels")}</Row>
        </Panel>
      )}
      {checkAttribute("stats") && (
        <Panel header="Stats" key="stats">
          <Row gutter={[16, 16]}>{detailNft && formatAttributes("stats")}</Row>
        </Panel>
      )}
      <Panel header="Details" key="details">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <RowSpaceBetween>
              <Text className="text-size-14 text-w400 mb-0px ">
                Contact Address
              </Text>
              <Text className="text-size-16 text-w500 mb-0px ">
                {detailNft?.algo_address
                  ? textSubstring(detailNft?.algo_address)
                  : textSubstring(detailNft?.NFT?.contract_address)}
              </Text>
            </RowSpaceBetween>
            {!IS_EVM && (
              <RowSpaceBetween className="mt-12px">
                <Text className="text-size-14 text-w400 mb-0px ">Token ID</Text>
                <Text className="text-size-16 text-w500 mb-0px ">
                  {detailNft?.NFT?.asa_id || "-"}
                </Text>
              </RowSpaceBetween>
            )}
            <RowSpaceBetween className="mt-12px">
              <Text className="text-size-14 text-w400 mb-0px ">
                {t("token_standard")}
              </Text>
              <Text className="text-size-16 text-w500 mb-0px ">ARC-721</Text>
            </RowSpaceBetween>
            {/* <RowSpaceBetween className="mt-12px">
              <Text className="text-size-14 text-w400 mb-0px ">Blockchain</Text>
              <Text className="text-size-16 text-w500 mb-0px ">Algorand</Text>
            </RowSpaceBetween> */}
            <RowSpaceBetween className="mt-12px">
              <Text className="text-size-14 text-w400 mb-0px ">
                {t("creator_royalty")}
              </Text>
              <Text className="text-size-16 text-w500 mb-0px ">
                {IS_EVM
                  ? detailNft?.royalty_fee / 100
                  : detailNft?.metadata?.seller_fee_basis_point / 100 || 0}{" "}
                %
              </Text>
            </RowSpaceBetween>
            <RowSpaceBetween className="mt-12px">
              <Text className="text-size-14 text-w400 mb-0px ">
                {t("creator")}
              </Text>
              <Text className="text-size-16 text-w500 mb-0px ">
                {IS_EVM ? creator?.name : detailNft?.original_owner?.name}
              </Text>
            </RowSpaceBetween>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );

  return (
    <SEO
      title={detailNft?.metadata?.name}
      description={detailNft?.metadata?.description}
      image={CREDENCE_HOST + "/files/" + detailNft?.NFT?.cover_file}
    >
      <Row className="my-24px" gutter={[24, 24]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={IS_EVM ? 14 : 12}
          xl={IS_EVM ? 14 : 10}
          xxl={IS_EVM ? 14 : 10}
        >
          <ColCenter className="h-100 w-100" style={{ aspectRatio: "1/1" }}>
            {detailNft?.NFT?.cover_file ? (
              <Image
                preview={false}
                src={CREDENCE_HOST + "/files/" + detailNft?.NFT?.cover_file}
                height={"100%"}
                width={"100%"}
                style={{
                  borderRadius: 4,
                  objectFit: "cover",
                }}
              />
            ) : (
              <Image
                preview={false}
                src={`https://ipfs.io/ipfs/${detailNft?.metadata?.image?.replace(
                  "ipfs://",
                  ""
                )}`}
                width={"100%"}
                height={"100%"}
                style={{
                  borderRadius: 4,
                  objectFit: "cover",
                }}
              />
            )}
          </ColCenter>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={IS_EVM ? 10 : 12}
          xl={IS_EVM ? 10 : 14}
          xxl={IS_EVM ? 10 : 14}
        >
          <RowSpaceBetween>
            {/* <CollectionNameStyled>
							<TextStyled underline onClick={onCollectionClicked}>
								{detailNft?.metadata?.collection?.name}
							</TextStyled>{' '}
							by{' '}
							<TextStyled underline onClick={onUserClicked}>
								{creatorData?.name || 'No Name'}
							</TextStyled>
						</CollectionNameStyled> */}
            <TitleStyled>
              {detailNft?.metadata?.name}{" "}
              {detailNft?.EvmNFTSub?.token_id &&
                `# ${detailNft?.EvmNFTSub?.token_id}`}
            </TitleStyled>
            <RowEnd>
              {detailNft?.metadata?.twitter_link && (
                <IconStyled
                  src={IconTwitter}
                  onClick={() => {
                    if (detailNft?.metadata?.twitter_link) {
                      const link = formatLink(
                        "https://twitter.com/",
                        detailNft?.metadata?.twitter_link
                      );
                      window.open(link, "_blank");
                    }
                  }}
                  width={24}
                />
              )}
              {detailNft?.metadata?.instagram_link && (
                <IconStyled
                  src={IconInstagram}
                  onClick={() => {
                    if (detailNft?.metadata?.instagram_link) {
                      const link = formatLink(
                        "https://instagram.com/",
                        detailNft?.metadata?.instagram_link
                      );
                      window.open(link, "_blank");
                    }
                  }}
                  width={24}
                />
              )}
              {detailNft?.metadata?.web_link && (
                <IconStyled
                  src={IconWeb}
                  onClick={() => {
                    if (detailNft?.metadata?.web_link) {
                      const link = formatLink(
                        "",
                        detailNft?.metadata?.web_link
                      );
                      window.open(link, "_blank");
                    }
                  }}
                  width={24}
                />
              )}
              {isExistOnWishlist(detailNft?.sell_escrow_id) ? (
                <IconStyled
                  src={IconLove}
                  onClick={() => onUpdateWishlist()}
                  width={22}
                />
              ) : (
                <IconStyled
                  src={IconUnloved}
                  onClick={() => onUpdateWishlist()}
                  width={22}
                />
              )}
            </RowEnd>
          </RowSpaceBetween>
          {/* <TitleStyled>{detailNft?.metadata?.name}</TitleStyled> */}
          <div className="pt-12px pb-12px pl-4px pr-4px">
            <Text>
              {detailNft?.metadata?.description.length > 200 &&
              !isReadMoreActive
                ? [
                    detailNft?.metadata?.description.substring(0, 199) + "...",
                    <a onClick={() => setReadMoreActive(true)}>Read More</a>,
                  ]
                : [
                    detailNft?.metadata?.description + " ",
                    detailNft?.metadata?.description.length > 200 && (
                      <>
                        <br />
                        <a onClick={() => setReadMoreActive(false)}>
                          Show Less
                        </a>
                      </>
                    ),
                  ]}
            </Text>
          </div>
          <RowSpaceBetween className="mt-16px">
            <RowStart className="pointer" onClick={onUserClicked}>
              <Avatar
                preview={false}
                src={
                  ownerData?.photo !== null
                    ? `${MEMBERSHIP_HOST}/files/${ownerData?.photo}`
                    : UserDummy
                }
                width={36}
                shape="square"
              />
              <ColStart className="ml-8px">
                <TextLabel className="mb-0px">
                  {t("owner").toLocaleUpperCase()}
                </TextLabel>
                <TextValue className="mb-0px">{ownerData?.name}</TextValue>
              </ColStart>
            </RowStart>
            <RowEnd className="pointer">
              <ColEnd className="end mr-8px">
                <TextLabel className="mb-0px">
                  {t("collection").toLocaleUpperCase()}
                </TextLabel>
                <TextValue className="mb-0px">
                  {detailNft?.metadata?.collection?.name || t("no_collection")}
                </TextValue>
              </ColEnd>
              <Avatar
                preview={false}
                src={nftCollection}
                width={36}
                shape="square"
              />
            </RowEnd>
          </RowSpaceBetween>
          {detailNft.content !== null && (
            <RowStart className="mt-16px">
              <UnlockableContent>
                <Image preview={false} src={LockMini} width={12} />
                <div className="ml-4px">{t("include_unlockable")}</div>
              </UnlockableContent>
              {(IS_EVM
                ? detailNft?.royalty_fee
                : detailNft?.metadata?.seller_fee_basis_point) /
                100 >=
                100 && (
                <Popover
                  placement="bottomRight"
                  title="Creator's Royalty"
                  content={
                    <div style={{ width: "150px" }}>{t("loyalty_tooltip")}</div>
                  }
                >
                  <RoyaltyContent>100% {t("royalty")}</RoyaltyContent>
                </Popover>
              )}
            </RowStart>
          )}
          {!IS_EVM && (
            <PriceHistory>
              <RowSpaceBetween>
                <Title
                  className="text-size-14 text-w400 mb-0px "
                  level={5}
                  align="left"
                >
                  {t("price_history")}
                </Title>
                <Select
                  defaultValue="all_time"
                  style={{ width: 120 }}
                  onChange={(value) => onTimeChange(value)}
                >
                  <Option value="all_time">All Time</Option>
                  <Option value="3days">3 Days</Option>
                  <Option value="1week">1 Week</Option>
                  <Option value="1month">1 Month</Option>
                  <Option value="3month">3 Months</Option>
                  <Option value="6month">6 Months</Option>
                  <Option value="1year">1 year</Option>
                </Select>
              </RowSpaceBetween>
              <DividerCard />
              <Line {...config} style={{ height: 250 }} />
            </PriceHistory>
          )}

          {IS_EVM && (
            <div
              style={{
                margin: "48px 0px",
                border: "1px solid #42556e50",
                borderRadius: "4px",
              }}
              className="p-12px"
            >
              <RowSpaceBetween>
                <DetailLabel>Date Of Creation</DetailLabel>
                <DetailValue>
                  {moment(detailNft?.created_at).format("LLLL")}
                </DetailValue>
              </RowSpaceBetween>
              <RowSpaceBetween>
                <DetailLabel>Creator Wallet Address</DetailLabel>
                <DetailValue>
                  {textSubstring(detailNft?.NFT?.creator_wallet_address)}
                </DetailValue>
              </RowSpaceBetween>
              <RowSpaceBetween>
                <DetailLabel>Token Standard</DetailLabel>
                <DetailValue>ARC-721</DetailValue>
              </RowSpaceBetween>
              <RowSpaceBetween>
                <DetailLabel>Fragmentation</DetailLabel>
                <DetailValue>
                  {detailNft?.NFT?.max_supply === 0 ? (
                    <span>&infin;</span>
                  ) : (
                    detailNft?.NFT?.max_supply
                  )}
                </DetailValue>
              </RowSpaceBetween>
              {detailNft?.EvmNFTSub?.token_id ? (
                <RowSpaceBetween>
                  <DetailLabel>Token ID</DetailLabel>
                  <DetailValue># {detailNft?.EvmNFTSub?.token_id}</DetailValue>
                </RowSpaceBetween>
              ) : (
                <RowSpaceBetween>
                  <DetailLabel>Minted</DetailLabel>
                  <DetailValue>{detailNft?.NFT?.supply}</DetailValue>
                </RowSpaceBetween>
              )}
              <RowSpaceBetween>
                <DetailLabel>Network</DetailLabel>
                <DetailValue>Polygon</DetailValue>
              </RowSpaceBetween>
            </div>
          )}
          {IS_EVM && (
            <>
              <RowSpaceBetween>
                <Text className="text-size-12 text-w400 mb-0px">
                  LIST PRICE
                </Text>
                <Text className="text-size-12 text-w400 mb-0px">
                  SALE ENDS IN
                </Text>
              </RowSpaceBetween>
              <RowSpaceBetween>
                <RowStart>
                  {!IS_EVM && (
                    <Image preview={false} src={IBFxIcon} width={24} />
                  )}
                  <Text
                    className={`text-size-20 text-w600 gray-6 mb-0px ${
                      !IS_EVM && "ml-8px"
                    }`}
                  >
                    {formatterCurrency.format(
                      IS_EVM
                        ? fromIBFN(detailNft.price)
                        : fromIBFx(detailNft.price)
                    )}
                  </Text>
                  <Text className="text-size-20 text-w400 gray-6 mb-0px ml-8px">
                    {!IS_EVM
                      ? `($
               ${formatterCurrency.format(
                 parseFloat(fromIBFx(detailNft?.price) / ibfxPerUSD).toFixed(2)
               )})`
                      : paymentList.find(
                          (i) => i.value === detailNft?.contract_payment
                        )?.key}
                  </Text>
                </RowStart>
                <RowEnd className="pointer" onClick={onUserClicked}>
                  <Text className="text-size-20 text-w600 gray-6 mb-0px ml-8px">
                    {moment(detailNft?.epoch_end * 1000).format("LLL")}
                  </Text>
                </RowEnd>
              </RowSpaceBetween>
            </>
          )}
          {IS_EVM && !isOwner && (
            <Row className="pt-12px">
              {!(
                Number(dataAmountAllowanceTokenPayment) >=
                fromIBFx(detailNft?.price)
              ) && (
                <span className="pt-4px pb-4px">
                  Look's like your payment method is not yet allowed to buy,{" "}
                  <span
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => writeSetAllowanceTokenPayment()}
                  >
                    click here
                  </span>{" "}
                  to allow.
                </span>
              )}
              <ButtonStyled
                block
                disabled={
                  !(
                    Number(dataAmountAllowanceTokenPayment) >=
                    fromIBFN(detailNft?.price)
                  ) || !isConnected
                }
                className="mt-0px"
                style={{ height: 40, borderRadius: 4 }}
                onClick={() => {
                  if (dataBalanceTokenPayment >= detailNft?.price) {
                    writeBuyNft();
                  } else {
                    notification.warning({ description: "Insufficient Funds" });
                  }
                }}
              >
                Buy Now
              </ButtonStyled>
            </Row>
          )}
        </Col>
      </Row>

      {!IS_EVM && (
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
            {renderDetailInfo()}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={14}>
            <RowSpaceBetween>
              <Text className="text-size-14 text-w600 mb-0px ">
                {t("credence-card-nft-list-price")}
              </Text>
              <Text className="text-size-14 text-w600 mb-0px ">
                {/* {t("minted_on")} */}
                Created On
              </Text>
            </RowSpaceBetween>
            <RowSpaceBetween>
              <RowStart>
                {!IS_EVM && <Image preview={false} src={IBFxIcon} width={24} />}
                <Text
                  className={`text-size-20 text-w600 gray-6 mb-0px ${
                    !IS_EVM && "ml-8px"
                  }`}
                >
                  {formatterCurrency.format(
                    IS_EVM
                      ? fromIBFN(detailNft.price)
                      : fromIBFx(detailNft.price)
                  )}
                </Text>
                <Text className="text-size-20 text-w400 gray-6 mb-0px ml-8px">
                  {!IS_EVM
                    ? `($
                ${formatterCurrency.format(
                  parseFloat(fromIBFx(detailNft?.price) / ibfxPerUSD).toFixed(2)
                )})`
                    : paymentList.find(
                        (i) => i.value === detailNft?.contract_payment
                      )?.key}
                </Text>
              </RowStart>
              <RowEnd className="pointer" onClick={onUserClicked}>
                <Text className="text-size-20 text-w600 gray-6 mb-0px ml-8px">
                  {moment(
                    detailNft?.original_updated_at ||
                      detailNft?.NFT?.updated_at ||
                      detailNft?.NFT?.created_at
                  ).format("LL")}
                </Text>
              </RowEnd>
            </RowSpaceBetween>
            {!IS_EVM && (
              <RowSpaceBetween className="mt-20px mb-16px">
                {Number(detailNft.quantity) === 0 ? (
                  <LabelNFTApproved>{t("nft_sold")}</LabelNFTApproved>
                ) : (
                  <ButtonOutline
                    block
                    className="mr-8px"
                    style={{ height: 40, borderRadius: 4 }}
                    onClick={() =>
                      window.open(
                        `${ALGO_EXPLORER_URL}/asset/${detailNft?.NFT?.asa_id}`,
                        ""
                      )
                    }
                  >
                    {t("view_on_algo")}
                  </ButtonOutline>
                )}

                {isOwner ? (
                  <ModalDelistingNFT
                    isFromDetail
                    sell_escrow_id={detailNft?.sell_escrow_id}
                    name={detailNft?.metadata?.name}
                    collection={detailNft?.metadata?.collection?.name}
                    fragmentation={detailNft?.metadata?.fragmentation}
                    listed_on={detailNft?.created_at}
                    price={detailNft?.price}
                    ipfsHash={detailNft?.metadata?.image?.replace(
                      "ipfs://",
                      ""
                    )}
                  />
                ) : (
                  <ModalLoansNewRequest
                    isPayLater
                    loanAmount={fromIBFx(detailNft?.price)}
                  />
                )}
              </RowSpaceBetween>
            )}
            {!isOwner &&
              (IS_EVM ? (
                <Row className="pt-12px">
                  {!(
                    Number(dataAmountAllowanceTokenPayment) >=
                    fromIBFx(detailNft?.price)
                  ) && (
                    <span className="pt-4px pb-4px">
                      Look's like your payment method is not yet allowed to buy,{" "}
                      <span
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => writeSetAllowanceTokenPayment()}
                      >
                        click here
                      </span>{" "}
                      to allow.
                    </span>
                  )}
                  <ButtonStyled
                    block
                    disabled={
                      !(
                        Number(dataAmountAllowanceTokenPayment) >=
                        fromIBFN(detailNft?.price)
                      ) || !isConnected
                    }
                    className="mr-8px mt-0px"
                    style={{ height: 40, borderRadius: 4 }}
                    onClick={() => writeBuyNft()}
                  >
                    Buy Now
                  </ButtonStyled>
                </Row>
              ) : (
                <ModalBuyNFT
                  sell_escrow_id={detailNft?.sell_escrow_id}
                  totalPayment={0}
                  name={detailNft?.metadata?.name}
                  collection={detailNft?.metadata?.collection?.name}
                  fragmentation={detailNft?.NFT?.fragmentation}
                />
              ))}
          </Col>
        </Row>
      )}

      {otherCollection.length > 0 && (
        <MoreStyled>{t("more_collection")}</MoreStyled>
      )}
      <Row>
        {otherCollection.length > 0 &&
          otherCollection.map((nft) => {
            return (
              <CardShoppe
                name={nft.name}
                collection={nft.collection}
                price={nft.price}
                image={nft.image}
              />
            );
          })}
      </Row>
    </SEO>
  );
}
