import styled from "@emotion/styled";
import { Layout, Breadcrumb, Typography, Menu, Button, Alert } from "antd";

const { Content, Sider, Header } = Layout;
const { Text } = Typography;
const { Item, SubMenu } = Menu;

export const LayoutCanvasStyled = styled(Layout)`
  position: relative;

  .btn-collapse {
    position: fixed;
    bottom: 75px;
    left: ${(props) => (props.collapsedSidebar ? "60px" : "230px")};
    z-index: 10;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .siderbar-icon {
    width: ${(props) => (props.collapsedSidebar ? "24px" : "16px")};
    height: ${(props) => (props.collapsedSidebar ? "24px" : "16px")};
    transition: all 0.2s ease;
  }

  .sidebar-button {
    font-size: 14px !important;
  }
  .disabled {
    cursor: not-allowed !important;
    color: rgba(0, 0, 0, 0.5);
  }
  .disabled:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title {
    padding: ${(props) =>
      props.collapsedSidebar ? "0 calc(50% - 26px / 2) !important" : "0"};
    transition: all 0.2s ease;
  }
`;

export const BottomSection = styled.div`
  width: 100%;
  transition: ${(props) =>
    props.collapsedSidebar ? "all 0.08s ease" : "all 1s ease"};
  opacity: ${(props) => (props.collapsedSidebar ? "0" : "1")};
`;

export const Logo = styled.img`
  height: ${(props) => (props.collapsedSidebar ? "25px" : "74px")};
  margin: ${(props) => (props.collapsedSidebar ? "17px 6px" : "0")};

  &.on-header {
    margin: 20px;
  }
`;

export const MenuIcon = styled.img`
  height: 32px;
  width: 32px;
`;

export const SiderStyled = styled(Sider)`
  position: relative;
  overflow-x: hidden;
  max-width: 235px;
  min-width: 235px;
  z-index: 22;
  border-radius: 10px;
  padding-bottom: 10px;

  @media screen and (min-width: 835px) {
    overflow-x: hidden;
    min-height: auto;
    left: 0;
    box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px, rgb(9 30 66 / 14%) 0px 0px 20px -6px;
}

  @media screen and (max-width: 834px) {
    overflow-x: hidden;
    // height: calc(100vh - 75px);
    min-height: auto;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
  }
`;

export const ContentStyled = styled.div`
  padding: ${(props) => (props.isNoPadding ? "0" : "24px 40px 0 40px")};
  overflow: initial;
  min-height: 100vh;
  padding-top: ${(props) => (props.isNoPadding ? "64px" : "80px")};
  background: ${(props) => (props.isFromCreateNFT ? "#FFF" : "#fafafa")};
  padding-bottom: 24px;

  @media screen and (max-width: 835px) {
    padding: ${(props) => (props.isNoPadding ? "0" : "100px 20px 0 20px")};
  }

  @media screen and (min-width: 1920px) {
    display: flex;
    justify-content: center;
  }
`;

export const ContainerStyled = styled(Content)`
  overflow: initial;

  @media screen and (min-width: 1920px) {
    width: 1400px;
    max-width: 1400px;
  }
`;

export const LayoutStyled = styled(Layout)`
  background-color: "#FCFCFC";

  @media screen and (min-width: 835px) {
    margin-left: ${(props) => (props.collapsedSidebar ? "80px" : "250px")};
  }
`;

export const HeaderStyled = styled(Header)`
  @media screen and (min-width: 835px) {
    padding: 0 24px;
    background: #fafafa;
    // border-bottom: 1px solid #edeff2;
    position: fixed;
    // overflow: auto;
    width: calc(
      100% - ${(props) => (props.collapsedSidebar ? "80px" : "250px")}
    );
    z-index: 21;
  }

  @media screen and (max-width: 834px) {
    background: #fafafa;
    padding: 0 20px;
    height: 80px;
    position: fixed;
    // overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    z-index: 22;
  }

  &.relative {
    position: relative;
  }
`;

export const BreadcrumbItemBold = styled(Breadcrumb.Item)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0b0e14;
`;
export const BreadcrumbItemLight = styled(Breadcrumb.Item)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #6b7a99;
`;

export const CardRateStyled = styled.div`
  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 11px 4px;
  min-width: 120px;
  padding: 0 12px;
  height: 40px;
`;

export const CardRateLabel = styled.h5`
  margin-bottom: 4px;
  padding-bottom: 0;
  color: #6b7c94;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const CardRateValue = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #273248;
`;

export const ButtonProfileStyled = styled.div`
  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 11px 4px;
  padding: 0 8px 0 4px;
  height: 40px;
`;

export const ImageHeaderStyled = styled.img`
  box-sizing: border-box;
  object-fit: cover;
  border-radius: 2px;
  width: 40px;
  height: 40px;
`;

export const ImageProfileStyled = styled.img`
  border: 1px solid #d6dae1;
  box-sizing: border-box;
  object-fit: cover;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;

export const NameProfileStyled = styled.h5`
  margin-bottom: 4px;
  padding-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #0b0e14;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const SaldoProfileStyled = styled.h5`
  margin-bottom: 0;
  padding-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #273248;
`;

export const SosmedStyled = styled.div`
  margin: 24px 0 24px 24px;

  a {
    margin-right: 8px;

    @media screen and (min-width: 835px) {
      img {
        width: 24px;
        height: 24px;
      }
    }
    @media screen and (max-width: 834px) {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

export const TextStyled = styled(Text)`
  @media screen and (min-width: 835px) {
    /* Regular/14px | 22px */

    // font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Gray/gray-6 */

    color: #44567b;
  }
  @media screen and (max-width: 834px) {
    /* Regular/14px | 22px */

    // font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Neutral/600 */

    color: #44567b;
  }
`;

export const ItemStyled = styled(Item)`
  height: 48px !important;

  img {
    width: 28px;
    height: 28px;
  }

  &.disabled {
    cursor: not-allowed !important;
  }
`;

export const SubMenuStyled = styled(SubMenu)`
  height: 48px !important;
`;

export const ButtonStyled = styled(Button)`
  background: transparent;
  margin: 0 24px 20px 24px;

  border: 1px solid #b2bdd5;
  box-sizing: border-box;
  border-radius: 4px;

  /* Shades/White */

  font-size: 15px;
  font-weight: 500;
  color: #44567b;

  &.less-margin {
    margin-bottom: 10px;
  }

  &.full-width {
    width: 202px;
  }
`;

export const AlertStyled = styled(Alert)`
  cursor: pointer;
  margin: 0;
  min-height: 48px;
  background: #f8ba56;
  color: black;
  font-size: 15px;
  line-height: 17px;

  align-items: center & .ant-alert-icon {
    color: black;
    font-size: 18px;
    margin-right: 10px;
  }
`;

export const Divider = styled.div`
  width: calc(100% - 42px);
  height: 1px;
  margin: 20px 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const BtnRegisterOrLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #3b4552;
  border-radius: 100px;

  margin: 11px 4px;
  min-width: 120px;
  padding: 0 27.5px;
  height: 40px;

  /* Paragraph 01/Semibold */

  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/gray-1 */

  color: #fcfcfc;
`;

export const IrshadHeader = styled.div`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;

  /* Text/500 */

  color: #293544;

  margin-bottom: 4px;
`;
export const IrshadStatus = styled.div`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  /* Text/400 */

  color: #364559;
`;

export const BetaLabel = styled.span`
  font-size: 7px;
  vertical-align: top;
`;

export const ProfileContainer = styled.div`
  padding: 0 8px 0 4px;
  height: 40px;
  margin: 11px 4px;
  display: flex;
`;
