import axios from "axios";
import { CREDENCE_HOST } from "../../configs";
import {
  SET_CREDENCE_NFTS_SUB,
  LOADING_CREDENCE_NFTS_SUB,
  FAILED_CREDENCE_NFTS_SUB,
} from "../constants";

export const getCredenceNFTSub =
  (walletAddress, page = 1, callback) =>
  async (dispatch) => {
    dispatch({ type: LOADING_CREDENCE_NFTS_SUB });
    try {
      const nfts = (
        await axios.get(
          `${CREDENCE_HOST}/evm-nft-sub?wallet_address=${walletAddress}&page=${page}&size=15`
          // `${CREDENCE_HOST}/nft?user_id=0b380480-4073-11ec-a966-290dc59c8230&offset=${
          //   page - 1
          // }&limit=15&status=CREATED`
          // `${CREDENCE_HOST}/nft?user_id=bae43ec0-bec4-11eb-9259-51c4f9a401fc&offset=${
          //   page - 1
          // }&limit=15`
        )
      ).data;
      if (callback) {
        return nfts;
      } else {
        dispatch({
          type: SET_CREDENCE_NFTS_SUB,
          payload: {
            data: nfts.data.records.rows,
            currentPage: page,
            hasNext: page * 15 < nfts.data.total_items,
            total: nfts.data.total_items,
          },
        });
      }
    } catch (e) {
      dispatch({ type: FAILED_CREDENCE_NFTS_SUB, data: e });
      console.log(e);
    }
  };

export const getNftSubBySubId = async (subId) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.get(`${CREDENCE_HOST}/evm-nft-sub/${subId}`, {
        headers: headers,
      })
    ).data;

    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};
