import { createContext, useEffect, useState } from "react";

import {
  getParams,
  indexToGroup,
  txnsToSign,
} from "./helpers";
import { decryptMnemonic, toIBFx } from "../utils/utils";
import algosdk from "algosdk";
import { PeraWalletConnect } from "@perawallet/connect";
import { algodClient } from "../configs";
import { COMMON_CONSTANT } from "../constants/constant_common";
import { donateToProjectWithPera, submitWalletSignedOperation } from "../modules/benevolence_projects/action";

const peraWallet = new PeraWalletConnect();
export const PerawalletContext = createContext();

export const PerawalletProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");

  useEffect(() => {
    peraWallet.reconnectSession().then((accounts) => {
      peraWallet.connector?.on("disconnect", handdleDisconnect);

      if (accounts.length) {
        setIsConnected(true)
        setAddress(accounts[0]);
      }
    });
  }, [peraWallet]);

  const handdleDisconnect = () => {
    peraWallet.disconnect();
    setAddress(null);
    setIsConnected(false)
    setIsLoading(false)
  };

  function handdleConnect() {
    setIsLoading(true)
    peraWallet.connect().then((newAccounts) => {
      peraWallet.connector?.on("disconnect", () => {
        handdleDisconnect()
      });
      setIsConnected(true)
      setAddress(newAccounts[0]);
      setIsLoading(false)
    }).catch(() => setIsLoading(false));
  }

  const signByWalletconnect = async (operations, mnemonic) => {
    try {
      setIsLoading(true);

      const userKey = algosdk.mnemonicToSecretKey(mnemonic);
      const params = await getParams();
      const txns = [];
      const results = [];

      operations.forEach((op) => {
        const bytes = Uint8Array.from(
          Buffer.from(op.algo_transaction, "base64")
        );
        const transaction = algosdk.decodeUnsignedTransaction(bytes);
        transaction.firstRound = params.firstRound;
        transaction.lastRound = params.lastRound;
        op.txn = transaction;
        txns.push(op.txn);
      });
      // assign group id to transaction group
      algosdk.assignGroupID(txns);

      // sign non-walletconnect transaction with user key
      operations.forEach((op) => {
        op.algo_transaction = Buffer.from(
          algosdk.encodeUnsignedTransaction(op.txn)
        ).toString("base64");
        if (!op.is_walletconnect_operation) {
          const signedTxn = algosdk.signTransaction(op.txn, userKey.sk);
          op.signed_txn = signedTxn.blob;
          op.signed_algo_transaction = Buffer.from(signedTxn.blob).toString(
            "base64"
          );
          op.algo_transaction_id = signedTxn.txID;
          results.push(op);
        }
      });

      const txnsToSigning = await txnsToSign(operations);
      const flatTxns = txnsToSigning.reduce((acc, val) => acc.concat(val), []);

      // const walletTxns = flatTxns.map(
      //   ({ txn, signers, authAddr, message }) => ({
      //     txn: Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString(
      //       "base64"
      //     ),
      //     signers, // TODO: put auth addr in signers array
      //     authAddr,
      //     message,
      //   })
      // );
      // const requestParams = [walletTxns];
      // console.log({ requestParams });
      // const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      // console.log({ request });
      // const response = await connector.sendCustomRequest(request);
      const response = await peraWallet.signTransaction([flatTxns]);
      const result = response.filter((element) => {
        return element !== null;
      });

      const signedPartialTxns = txnsToSigning.map(() => []);
      result.forEach((r, i) => {
        const [group, groupIndex] = indexToGroup(i, txnsToSigning);
        const toSign = txnsToSigning[group][groupIndex];

        if (r == null) {
          if (toSign.signers !== undefined && toSign.signers?.length < 1) {
            signedPartialTxns[group].push(null);
            return;
          }
          throw new Error(
            `Transaction at index ${i}: was not signed when it should have been`
          );
        }

        if (toSign.signers !== undefined && toSign.signers?.length < 1) {
          throw new Error(
            `Transaction at index ${i} was signed when it should not have been`
          );
        }
        const rawSignedTxn = Buffer.from(r, "base64");

        signedPartialTxns[group].push(new Uint8Array(rawSignedTxn));
      });

      signedPartialTxns.forEach((signedPartialTxnsInternal, group) => {
        signedPartialTxnsInternal.map((rawSignedTxn, i) => {
          if (rawSignedTxn == null) {
            return null;
          }

          const signedEncoded = Buffer.from(rawSignedTxn).toString("base64");
          const signedTxn = algosdk.decodeSignedTransaction(rawSignedTxn);
          const txn = signedTxn.txn;
          const txID = txn.txID();

          const op = operations.find((operation) => {
            return (
              operation.operation_id === txnsToSigning[group][i].operation_id
            );
          });

          if (op) {
            op.algo_transaction_id = txID;
            op.signed_algo_transaction = signedEncoded;
            results.push(op);
          }
        });
      });

      setIsLoading(false)
      return results;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const testSignTransaction = async () => {
    const suggestedParams = await algodClient.getTransactionParams().do();
    const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: "ELOPYOTRSRN44VRDRDVN2DANEMWPI3YUFDASI6L7V4JUKAVXGCZFIFCJTQ",
      to: "ELOPYOTRSRN44VRDRDVN2DANEMWPI3YUFDASI6L7V4JUKAVXGCZFIFCJTQ",
      assetIndex: 15146760,
      amount: 0,
      suggestedParams,
    });

    const singleTxnGroups = [{ txn: optInTxn, signers: [] }];
    console.log(singleTxnGroups);

    // const operations = [
    //   {
    //     operation_id: "de55c1e0-9346-11ee-a785-1981f0a88fc9",
    //     user_id: "bd163390-624f-11ec-a647-17e738ab4397",
    //     donation_id: "de325b60-9346-11ee-a785-1981f0a88fc9",
    //     project_id: "ede74210-9340-11ee-8bab-433a9a3148a2",
    //     project_volunteer_id: null,
    //     operation_group: "de3f0590-9346-11ee-a785-1981f0a88fc9",
    //     operation_scenario: "PROJECT_WC_DONATION",
    //     algo_transaction_id: null,
    //     algo_transaction:
    //       "iaNhbXTOAGXGsKNmZWXNA+iiZnbOAhfpLqNnZW6sdGVzdG5ldC12MS4womdoxCBIY7UYpLPITsgQ8i1PEIHLD3HwWaesIN7GL39w5Qk6IqJsds4CF+0Wo3JjdsQgPVkoIGCbi4/Gmi56bw1zN7L/HNa5i+8/pvshBGaxtJmjc25kxCAi3Pw6cZRbzlYjiOrdDA0jLPRvFCjBJHl/rxNFArcwsqR0eXBlo3BheQ==",
    //     json_algo_transaction:
    //       '{"name":"Transaction","tag":"TX","from":"ELOPYOTRSRN44VRDRDVN2DANEMWPI3YUFDASI6L7V4JUKAVXGCZFIFCJTQ","to":"HVMSQIDATOFY7RU2FZ5G6DLTG6ZP6HGWXGF66P5G7MQQIZVRWSM5TAWHV4","amount":6670000,"note":{},"type":"pay","flatFee":false,"genesisHash":"SGO1GKSzyE7IEPItTxCByw9x8FmnrCDexi9/cOUJOiI=","fee":1000,"firstRound":35121454,"lastRound":35122454,"genesisID":"testnet-v1.0","appArgs":[],"lease":{}}',
    //     signed_algo_transaction: null,
    //     original_signed_algo_transaction: null,
    //     type: "DONATE_PROJECT_WALLETCONNECT",
    //     status: "INIT",
    //     priority: 0,
    //     is_walletconnect_operation: true,
    //     created_at: 1701764308239,
    //     updated_at: null,
    //   },
    //   {
    //     operation_id: "de588100-9346-11ee-a785-1981f0a88fc9",
    //     user_id: "bd163390-624f-11ec-a647-17e738ab4397",
    //     donation_id: "de325b60-9346-11ee-a785-1981f0a88fc9",
    //     project_id: "ede74210-9340-11ee-8bab-433a9a3148a2",
    //     project_volunteer_id: null,
    //     operation_group: "de3f0590-9346-11ee-a785-1981f0a88fc9",
    //     operation_scenario: "PROJECT_WC_DONATION",
    //     algo_transaction_id: null,
    //     algo_transaction:
    //       "iqRhYW10zgAO9CCkYXJjdsQg/Mld+JcmiWdwOaEiQ/PCW+wQrafWg4WeslWTbponCDajZmVlzQPoomZ2zgIX6S6jZ2VurHRlc3RuZXQtdjEuMKJnaMQgSGO1GKSzyE7IEPItTxCByw9x8FmnrCDexi9/cOUJOiKibHbOAhftFqNzbmTEID1ZKCBgm4uPxpouem8Nczey/xzWuYvvP6b7IQRmsbSZpHR5cGWlYXhmZXKkeGFpZM4A5x8I",
    //     json_algo_transaction:
    //       '{"name":"Transaction","tag":"TX","type":"axfer","from":"HVMSQIDATOFY7RU2FZ5G6DLTG6ZP6HGWXGF66P5G7MQQIZVRWSM5TAWHV4","to":"7TEV36EXE2EWO4BZUEREH46CLPWBBLNH22BYLHVSKWJW5GRHBA3KXVD5UI","amount":980000,"assetIndex":15146760,"note":{},"flatFee":false,"genesisHash":"SGO1GKSzyE7IEPItTxCByw9x8FmnrCDexi9/cOUJOiI=","fee":1000,"firstRound":35121454,"lastRound":35122454,"genesisID":"testnet-v1.0","appArgs":[],"lease":{}}',
    //     signed_algo_transaction: null,
    //     original_signed_algo_transaction: null,
    //     type: "DONATE_PROJECT_ADMIN_ESCROW",
    //     status: "INIT",
    //     priority: 1,
    //     is_walletconnect_operation: false,
    //     created_at: 1701764308240,
    //     updated_at: null,
    //   },
    //   {
    //     operation_id: "de588101-9346-11ee-a785-1981f0a88fc9",
    //     user_id: "bd163390-624f-11ec-a647-17e738ab4397",
    //     donation_id: "de325b60-9346-11ee-a785-1981f0a88fc9",
    //     project_id: "ede74210-9340-11ee-8bab-433a9a3148a2",
    //     project_volunteer_id: null,
    //     operation_group: "de3f0590-9346-11ee-a785-1981f0a88fc9",
    //     operation_scenario: "PROJECT_WC_DONATION",
    //     algo_transaction_id: null,
    //     algo_transaction:
    //       "iaRhcGFhkcQGZG9uYXRlpGFwaWTOHWqgVqNmZWXNA+iiZnbOAhfpLqNnZW6sdGVzdG5ldC12MS4womdoxCBIY7UYpLPITsgQ8i1PEIHLD3HwWaesIN7GL39w5Qk6IqJsds4CF+0Wo3NuZMQgPVkoIGCbi4/Gmi56bw1zN7L/HNa5i+8/pvshBGaxtJmkdHlwZaRhcHBs",
    //     json_algo_transaction:
    //       '{"name":"Transaction","tag":"TX","type":"appl","from":"HVMSQIDATOFY7RU2FZ5G6DLTG6ZP6HGWXGF66P5G7MQQIZVRWSM5TAWHV4","appIndex":493527126,"appOnComplete":0,"appArgs":[{"0":100,"1":111,"2":110,"3":97,"4":116,"5":101}],"note":{},"lease":{},"flatFee":false,"genesisHash":"SGO1GKSzyE7IEPItTxCByw9x8FmnrCDexi9/cOUJOiI=","fee":1000,"firstRound":35121454,"lastRound":35122454,"genesisID":"testnet-v1.0"}',
    //     signed_algo_transaction: null,
    //     original_signed_algo_transaction: null,
    //     type: "DONATE_PROJECT_ADMIN_CALL",
    //     status: "INIT",
    //     priority: 2,
    //     is_walletconnect_operation: false,
    //     created_at: 1701764308241,
    //     updated_at: null,
    //   },
    //   {
    //     operation_id: "de58a810-9346-11ee-a785-1981f0a88fc9",
    //     user_id: "bd163390-624f-11ec-a647-17e738ab4397",
    //     donation_id: "de325b60-9346-11ee-a785-1981f0a88fc9",
    //     project_id: "ede74210-9340-11ee-8bab-433a9a3148a2",
    //     project_volunteer_id: null,
    //     operation_group: "de3f0590-9346-11ee-a785-1981f0a88fc9",
    //     operation_scenario: "PROJECT_WC_DONATION",
    //     algo_transaction_id: null,
    //     algo_transaction:
    //       "iqRhYW10zgAO9CCkYXJjdsQgqwz7q+stiIFrxIQ/7LC2Wb9stnrRO8GVbbtZk0Wjm4qjZmVlzQPoomZ2zgIX6S6jZ2VurHRlc3RuZXQtdjEuMKJnaMQgSGO1GKSzyE7IEPItTxCByw9x8FmnrCDexi9/cOUJOiKibHbOAhftFqNzbmTEID1ZKCBgm4uPxpouem8Nczey/xzWuYvvP6b7IQRmsbSZpHR5cGWlYXhmZXKkeGFpZM4BRsga",
    //     json_algo_transaction:
    //       '{"name":"Transaction","tag":"TX","type":"axfer","from":"HVMSQIDATOFY7RU2FZ5G6DLTG6ZP6HGWXGF66P5G7MQQIZVRWSM5TAWHV4","to":"VMGPXK7LFWEIC26EQQ76ZMFWLG7WZNT22E54DFLNXNMZGRNDTOFLSQ45RE","amount":980000,"assetIndex":21415962,"note":{},"flatFee":false,"genesisHash":"SGO1GKSzyE7IEPItTxCByw9x8FmnrCDexi9/cOUJOiI=","fee":1000,"firstRound":35121454,"lastRound":35122454,"genesisID":"testnet-v1.0","appArgs":[],"lease":{}}',
    //     signed_algo_transaction: null,
    //     original_signed_algo_transaction: null,
    //     type: "DEPOSIT_IBFS_ESCROW",
    //     status: "INIT",
    //     priority: 3,
    //     is_walletconnect_operation: false,
    //     created_at: 1701764308241,
    //     updated_at: null,
    //   },
    //   {
    //     operation_id: "de58a811-9346-11ee-a785-1981f0a88fc9",
    //     user_id: "bd163390-624f-11ec-a647-17e738ab4397",
    //     donation_id: "de325b60-9346-11ee-a785-1981f0a88fc9",
    //     project_id: "ede74210-9340-11ee-8bab-433a9a3148a2",
    //     project_volunteer_id: null,
    //     operation_group: "de3f0590-9346-11ee-a785-1981f0a88fc9",
    //     operation_scenario: "PROJECT_WC_DONATION",
    //     algo_transaction_id: null,
    //     algo_transaction:
    //       "iqRhcGFhkcQHZGVwb3NpdKRhcGF0kcQgItz8OnGUW85WI4jq3QwNIyz0bxQowSR5f68TRQK3MLKkYXBpZM4BRsx4o2ZlZc0D6KJmds4CF+kuo2dlbqx0ZXN0bmV0LXYxLjCiZ2jEIEhjtRiks8hOyBDyLU8QgcsPcfBZp6wg3sYvf3DlCToiomx2zgIX7Rajc25kxCA9WSggYJuLj8aaLnpvDXM3sv8c1rmL7z+m+yEEZrG0maR0eXBlpGFwcGw=",
    //     json_algo_transaction:
    //       '{"name":"Transaction","tag":"TX","type":"appl","from":"HVMSQIDATOFY7RU2FZ5G6DLTG6ZP6HGWXGF66P5G7MQQIZVRWSM5TAWHV4","appIndex":21417080,"appOnComplete":0,"appArgs":[{"0":100,"1":101,"2":112,"3":111,"4":115,"5":105,"6":116}],"appAccounts":[{"publicKey":{"0":34,"1":220,"2":252,"3":58,"4":113,"5":148,"6":91,"7":206,"8":86,"9":35,"10":136,"11":234,"12":221,"13":12,"14":13,"15":35,"16":44,"17":244,"18":111,"19":20,"20":40,"21":193,"22":36,"23":121,"24":127,"25":175,"26":19,"27":69,"28":2,"29":183,"30":48,"31":178},"checksum":{"0":84,"1":20,"2":73,"3":156}}],"note":{},"lease":{},"flatFee":false,"genesisHash":"SGO1GKSzyE7IEPItTxCByw9x8FmnrCDexi9/cOUJOiI=","fee":1000,"firstRound":35121454,"lastRound":35122454,"genesisID":"testnet-v1.0"}',
    //     signed_algo_transaction: null,
    //     original_signed_algo_transaction: null,
    //     type: "DEPOSIT_IBFS_CALL",
    //     status: "INIT",
    //     priority: 4,
    //     is_walletconnect_operation: false,
    //     created_at: 1701764308242,
    //     updated_at: null,
    //   },
    // ];
  
    const body = {
      user_id: "bd163390-624f-11ec-a647-17e738ab4397",
      project_id: "ede74210-9340-11ee-8bab-433a9a3148a2",
      amount: toIBFx(5),
      walletconnect_address: address,
    }
    const operations = await donateToProjectWithPera(body)

    console.log({ operations })

    const getMnemonic = localStorage.getItem(
      COMMON_CONSTANT.ENCRYPTED_MNEMONIC
    );
    const _decryptMnemonic = decryptMnemonic(getMnemonic, "1234");

    const result = await signByWalletconnect(operations, _decryptMnemonic);

    await submitWalletSignedOperation(result);

    // const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
  };

  return (
    <PerawalletContext.Provider
      value={{
        isConnected,
        isLoading,
        address,
        signByWalletconnect,
        handdleConnect,
        handdleDisconnect,
        testSignTransaction,
      }}
    >
      {children}
    </PerawalletContext.Provider>
  );
};
