import styled from "@emotion/styled";
import { Avatar, Row, Typography } from "antd";

const { Text } = Typography;

export const ContentChart = styled.div`
  padding-top: 50px;
`;

export const HeaderSection = styled.div`
  display: flex;
  margin-top: 24px;

  @media screen and (min-width: 950px) {
    flex-direction: row;
    align-items: center;
  }
  @media screen and (max-width: 949px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ImageCanvas = styled.div`
  position: relative;
  width: 144px;
  height: 144px;
  cursor: pointer;

  img.edit {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const ProfilePic = styled(Avatar)`
  width: 136px;
  height: 136px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 2px solid #d6dae1;
  border-radius: 4px;
  background: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormRow = styled(Row)`
  margin-top: 24px;

  display: flex;
  flex-direction: column;
`;

// export const Para = styled(Paragraph)`
// // font-family: "Roboto";
// font-style: normal;
// font-weight: normal;
// font-size: 15px;
// line-height: 22px;
// color: #273248;
// `

export const EditButton = styled.button`
  color: #024FF1;
  border: 2px solid #024FF1;
  width: 65%;
  height: 40px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px 16px;
  cursor: pointer;
`;

export const LabelText = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  /* Text/400 */

  color: #273248;
  margin-bottom: 4px;
`;
export const ValueText = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  /* Text/400 */

  color: #273248;
`;
