import { useEffect, useState } from "react";
import { Row, Col, Image, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSection } from "../../modules/general/action";
import { PlusOutlined } from "@ant-design/icons";
import {
  ColCenter,
  ColSpaceBetween,
  ColStart,
  RowEnd,
  RowSpaceBetween,
} from "../general_styled";
import {
  CardStyled,
  CollectionNameStyled,
  NFTNameStyled,
  CanvasStyled,
  StepText,
  StepTitleText,
  DividerCard,
  PlaceholderNFT,
  PlaceholderNFTText,
  ProgressStyled,
  PriceStyled,
  ImageStyled,
} from "./styled";

import ModalDelistingAuctionNFT from "../ModalDelistingAuctionNFT";
import { CREDENCE_HOST } from "../../configs";
import { setDetailNFT } from "../../modules/affluence/action";
import { AUCTION_STATUS } from "../../constants/constant_credence";
import { fromIBFx, zeroPad } from "../../utils/utils";
import ModalAffluenceDepositNft from "../ModalAffluenceDepositNft";
import ModalSellNFTAuction from "../ModalSellNFTAuction";
import { useCountdown } from "../../utils/useCountdown";

import IBFxIcon from "../../assets/images/ibfx-icon.svg";
import useWindowDimensions from "../../utils/windowDimensions";

const { Text } = Typography;

export default function CardAuction(props) {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();

  const [metadata, setMetadata] = useState({});
  const [transaction, setTransaction] = useState({});

  const [days, hours, minutes, seconds] = useCountdown(props.auction.deadline);

  const resolveContent = (status) => {
    if (status !== AUCTION_STATUS.OPENED) {
      if (status === AUCTION_STATUS.CREATED_WITH_ESCROW) {
        return (
          <ColSpaceBetween className="h-100 w-100">
            <ColCenter className="w-100">
              <PlaceholderNFT>
                <PlusOutlined style={{ fontSize: "64px", color: "#A7B1C0" }} />
              </PlaceholderNFT>
              <RowSpaceBetween className="w-100 pt-20px px-12px">
                <PlaceholderNFTText width={95} height={18} />
                <PlaceholderNFTText width={55} height={18} />
              </RowSpaceBetween>
              <RowSpaceBetween className="w-100 pt-5px px-12px">
                <PlaceholderNFTText width={55} height={18} />
                <PlaceholderNFTText width={95} height={18} />
              </RowSpaceBetween>
            </ColCenter>
            <ColCenter className="w-100 px-12px">
              <DividerCard />
              <Text className="mb-14px" align="center">
                {t("auction_slot_active_1")}
                <br />
                {t("auction_slot_active_2")}
              </Text>
            </ColCenter>
          </ColSpaceBetween>
        );
      } else {
        return (
          <ColSpaceBetween className="h-100 w-100" style={{ minHeight: 535 }}>
            <ColCenter className="w-100 mt-80px">
              <ProgressStyled
                type="circle"
                percent={
                  status === AUCTION_STATUS.CREATED
                    ? 0
                    : status === AUCTION_STATUS.PENDING_ESCROW
                    ? 80
                    : 100
                }
                width={100}
                style={{ color: "#FFFFFF" }}
              />
              <StepText className="mt-16px">
                {t("steps").toUpperCase()}
                {status === AUCTION_STATUS.CREATED ? " 1/2" : " 2/2 "}
              </StepText>
              <StepTitleText className="mt-4px">
                {status === AUCTION_STATUS.CREATED
                  ? t("activate_slot")
                  : status === AUCTION_STATUS.CREATED_WITH_ESCROW
                  ? t("depositing_slot")
                  : AUCTION_STATUS.PENDING_ESCROW
                  ? t("activating_slot")
                  : ""}
              </StepTitleText>
            </ColCenter>
            <Text className="mb-14px white" align="center">
              {t("auction_process")}
            </Text>
          </ColSpaceBetween>
        );
      }
    } else {
      return (
        <ColStart
          className="px-8px mb-8px mt-16px w-100"
          onClick={() => {
            dispatch(setDetailNFT(props.auction));
            dispatch(
              setSection(
                "affluence-auction-detail-nft",
                props.auction.auction_id
              )
            );
          }}
        >
          <CollectionNameStyled>
            {metadata?.collection?.name || t("no_collection")}
          </CollectionNameStyled>
          <NFTNameStyled>{metadata?.name}</NFTNameStyled>
        </ColStart>
      );
    }
  };

  const resolveFooter = (status) => {
    if (status === AUCTION_STATUS.CREATED) {
      return (
        <div className="mb-8px">
          <ModalSellNFTAuction {...props} />
        </div>
      );
    } else if (
      status === AUCTION_STATUS.CREATED_WITH_ESCROW ||
      status === AUCTION_STATUS.PENDING_ESCROW
    ) {
      return (
        <div className="mb-8px">
          <ModalAffluenceDepositNft {...props} />
        </div>
      );
    } else if (status === AUCTION_STATUS.OPENED) {
      return (
        <div className="mb-8px">
          <ModalDelistingAuctionNFT
            data={{ auctionData: props.auction, metadata, transaction }}
            auction_id={props.auction?.auction_id}
            name={metadata?.name}
            collection={metadata?.collection?.name}
            fragmentation={metadata?.fragmentation}
            listed_on={props.auction?.NFT?.created_at}
          />
        </div>
      );
    }
  };

  const resolveImage = (image) => (
    <Row>
      <Col span={24}>
        <ImageStyled
          alt={metadata?.name}
          src={image}
          onClick={() => {
            dispatch(setDetailNFT(props.auction));
            dispatch(
              setSection(
                "affluence-auction-detail-nft",
                props.auction.auction_id
              )
            );
          }}
        />
      </Col>
      <Col span={24}>
        <PriceStyled>
          Price:
          <RowEnd>
            <img className="icon" src={IBFxIcon} />
            <div className="value">
              {fromIBFx(props.auction.minimum_bid) || 0}
            </div>
          </RowEnd>
        </PriceStyled>
      </Col>
    </Row>
  );

  useEffect(() => {
    if (props.auction.NFT) {
      const _metadata = JSON.parse(props.auction.NFT.metadata_json);
      setMetadata(_metadata);
    }

    if (props.auction.Transactions) {
      setTransaction(props.auction.Transactions[0]);
    }
  }, [props.auction]);

  return (
    <Col
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={props.isDelisting || windowDimensions.width < 1441 ? 8 : 6}
      xxl={6}
    >
      <CanvasStyled>
        <CardStyled
          className={
            "main-card " +
            (props.auction.status === AUCTION_STATUS.CREATED_WITH_ESCROW
              ? "deposit "
              : "") +
            (props.isListingStyle ? "listing-style " : "") +
            (props.auction.status === AUCTION_STATUS.OPENED ? "opened " : "") +
            (!props.isDelisting ? "add-border-bottom " : "")
          }
          status={props.auction.status}
        >
          {props.auction.status === AUCTION_STATUS.OPENED && (
            <RowSpaceBetween className="auction">
              <div className="title">{t("ends_in")}:</div>
              <RowEnd>
                <div className="numeric">
                  {zeroPad(days, 2)}
                  {t("time_d")}
                </div>
                <div className="numeric">
                  {zeroPad(hours, 2)}
                  {t("time_h")}
                </div>
                <div className="numeric">
                  {zeroPad(minutes, 2)}
                  {t("time_m")}
                </div>
                <div className="numeric">
                  {zeroPad(seconds, 2)}
                  {t("time_s")}
                </div>
              </RowEnd>
            </RowSpaceBetween>
          )}
          {props.auction.status === AUCTION_STATUS.OPENED ? (
            props.auction?.NFT.cover_file ? (
              resolveImage(
                CREDENCE_HOST + "/files/" + props.auction?.NFT.cover_file
              )
            ) : (
              resolveImage`https://ipfs.io/ipfs/${metadata?.image?.replace(
                "ipfs://",
                ""
              )}`
            )
          ) : (
            <></>
          )}
          <ColSpaceBetween className="h-100">
            {resolveContent(props.auction.status)}
          </ColSpaceBetween>
          {props.auction.status !== AUCTION_STATUS.OPENED &&
            resolveFooter(props.auction.status)}
          {props.isDelisting &&
            props.auction.status === AUCTION_STATUS.OPENED &&
            resolveFooter(props.auction.status)}
        </CardStyled>
      </CanvasStyled>
    </Col>
  );
}
