import styled from "@emotion/styled";

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 412px;
  background: #fff;
  border-radius: 10px;

  .ant-upload.ant-upload-select {
    display: block;
  }

  &.bottom {
    max-height: 150px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: center;
    background: #3a3a3a;
  }
`;

export const ContentCardStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  cursor: pointer;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 4px;

  &.selected {
    background: #ffffff;
    border: 1px solid #289a67;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
    border-radius: 4px;
  }

  .check {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const TitleStyled = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #5b7697;

  &.selected {
    color: #364559;
  }
`;

export const SubtitleStyled = styled.div`
  /* Regular/12px | 20px */

  // font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  /* or 167% */

  /* Gray/gray-7 */

  color: #273248;

  &.bottom {
    color: #fff;
    line-height: 26.5px;
  }
`;
