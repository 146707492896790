import { fromJS } from "immutable"
import {
  GET_COURSES,
  GET_COURSES_FAIL,
  GET_COURSES_SUCCESS,
  SELECT_COURSE,
  SET_ENROLLED,
} from "../constants"

const initialState = fromJS({
  isLoading: fromJS(false),
  hasError: fromJS(false),
  errorMessage: fromJS(""),
  enrolled: fromJS([]),
  courses: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    totalItems: 0,
    totalPage: 0,
  }),
  selectedCourse: fromJS({}),
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COURSES:
      return state.merge({
        isLoading: true,
        hasError: false,
      })
    case GET_COURSES_SUCCESS:
      return state.merge({
        isLoading: false,
        courses: fromJS(action.payload),
      })
    case GET_COURSES_FAIL:
      return state.merge({
        isLoading: false,
        hasError: true,
        errorMessage: action.payload.errorMessage,
      })
    case SET_ENROLLED:
      return state.merge({
        enrolled: fromJS(action.payload),
      })
    case SELECT_COURSE:
      return state.merge({
        selectedCourse: fromJS(action.payload),
      })
    default:
      return state
  }
}
