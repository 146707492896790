import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Tooltip,
  Tabs,
  Progress,
  Table,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RowEnd, RowSpaceBetween } from "../../components/general_styled";
import {
  getCredenceEvmNFTByAddress,
  getCredenceNFTByUserId,
  getNftByNftId,
  setNftDetail,
} from "../../modules/credence/action";
import {
  CollectionNameStyled,
  IconStyled,
  NameStyled,
  DescStyled,
  ColStyled,
  NFTDetailsStyled,
  DetailTitleStyled,
  DetailInfoStyled,
  LabelStyled,
  ValueStyled,
  ColFooterStyled,
  LabelFooterStyled,
  ValueFooterStyled,
  MoreStyled,
  ApprovalPending,
  Canvas,
  LabelNFTApproved,
  PropertiesCard,
} from "./styled";
import IconTwitter from "../../assets/images/icon_twitter.svg";
import IconInstagram from "../../assets/images/icon_instagram.svg";
import IconArrowRight from "../../assets/images/arrow-right-black.svg";
import IconWeb from "../../assets/images/icon_web.svg";
import moment from "moment";
import {
  ALGO_EXPLORER_URL,
  CREDENCE_HOST,
  IS_EVM,
  MEMBERSHIP_HOST,
  POLYGON_EXPLORER_URL,
  nftAirdropAddress,
  nftMarketplaceAddress,
} from "../../configs";
import CardNFT from "../../components/CardNFT";
import ModalSellNFT from "../../components/ModalSellNFT";
// import ModalSellNFTComingSoon from "../../components/ModalSellNFTComingSoon"
import {
  EVM_TRANSACTION_STATUS,
  NFT_STATUS,
} from "../../constants/constant_credence";
import useWindowDimensions from "../../utils/windowDimensions";
import ModalPayFeeNft from "../../components/ModalPayFeeNft";
import {
  openModalLoginRegister,
  setSection,
} from "../../modules/general/action";
import ModalDelistingAuctionNFT from "../../components/ModalDelistingAuctionNFT";
import ModalDelistingNFT from "../../components/ModalDelistingNFT";
import jwtDecode from "jwt-decode";
import SEO from "../../components/SEO";
import ClaimAirdrop from "../../components/ClaimAirdrop";
import { getUserDetailByAddress } from "../../modules/auth/action";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import AbiNFTPort from "../../assets/abi/NFTPort.json";

const { TabPane } = Tabs;

export default function CredenceNFTDetail() {
  const { isConnected, address } = useAccount();
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const param = useSelector((state) => state.getIn(["general", "param"]));
  const nfts = useSelector((state) =>
    state.getIn(["credence", "nfts", "data"]).toJS()
  );
  const [nft, setNft] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isListed, setIsListed] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [menu, setMenu] = useState();
  const [creator, setCreator] = useState("");

  const getNFT = async () => {
    const _nft = await getNftByNftId(param);
    dispatch(setNftDetail(_nft));

    if (localStorage.getItem("USER_AUTH_TOKEN")) {
      if (IS_EVM) {
        if (isConnected) {
          dispatch(getCredenceEvmNFTByAddress(address));
          await initCreatorData(_nft?.creator_wallet_address);
        }
      } else {
        dispatch(getCredenceNFTByUserId(null));
      }
    }
    setNft({ ..._nft, is_minted: _nft.status === NFT_STATUS.CREATED });
    setMetadata({
      nft_id: _nft.nft_id,
      fragmentation: _nft.fragmentation,
      is_airdrop: _nft.airdrop?.length > 0,
      whitelist_address:
        _nft.airdrop?.length > 0
          ? JSON.parse(_nft.airdrop[0]?.whitelist_address)
          : [],
      ...JSON.parse(_nft.metadata_json),
    });
    if (IS_EVM) {
      setIsListed(
        _nft?.NFT.find((i) => i.status === EVM_TRANSACTION_STATUS.ON_SALE)
      );
    } else {
      setIsListed(_nft.Auctions?.length > 0 || _nft.SellEscrows?.length > 0);
    }
  };

  const initCreatorData = async (address) => {
    const _creator = await getUserDetailByAddress(address);
    setCreator(_creator);
  };

  const formatLink = (additionalUrl, link) => {
    const isHaveWWW = link.includes("www");
    const isHaveHTTP = link.includes("http");
    const isHaveOnlyCOM = !(isHaveHTTP || isHaveHTTP) && link.includes("com");
    if (isHaveHTTP) {
      return link;
    } else if (isHaveHTTP && isHaveWWW) {
      return link;
    } else if (!isHaveHTTP && isHaveWWW) {
      return `http://${link}`;
    } else if (isHaveOnlyCOM) {
      return `http://${link}`;
    } else {
      return `${additionalUrl}${link}`;
    }
  };

  const formatAttributes = (type) => {
    const attribute = metadata?.attributes;

    if (!attribute) {
      return;
    }

    if (type === "properties") {
      const filteredProperties = attribute?.filter(
        (item) => !item.display_type
      );
      return filteredProperties.map((item, index) => (
        <Col
          key={"property" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="label">{item.trait_type}</div>
            <div className="name">{item.value}</div>
            {/* <div className="value">61% have this trait</div> */}
          </PropertiesCard>
        </Col>
      ));
    } else if (type === "levels") {
      const filteredLevels = attribute?.filter(
        (item) => item.display_type === "Levels"
      );
      return filteredLevels.map((item, index) => (
        <Col
          key={"levels" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="name">{item.trait_type}</div>
            <Progress
              className="mt-8px"
              percent={Math.round(
                (parseInt(item.value) / parseInt(item.max_value)) * 100
              )}
              trailColor="#DEE2E7"
              strokeColor="#3B4552"
            />
          </PropertiesCard>
        </Col>
      ));
    } else if (type === "stats") {
      const filteredStats = attribute?.filter(
        (item) => item.display_type === "Stats"
      );
      return filteredStats.map((item, index) => (
        <Col
          key={"stats" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="name">{item.trait_type}</div>
            <div className="value">
              {Math.round(
                (parseInt(item.value) / parseInt(item.max_value)) * 100
              )}
              % have this trait
            </div>
          </PropertiesCard>
        </Col>
      ));
    }
  };

  const checkAttribute = (type) => {
    if (metadata) {
      const attribute = metadata?.attributes;
      if (type === "properties") {
        return attribute?.find((item) => !item.display_type);
      } else if (type === "levels") {
        return attribute?.find((item) => item.display_type === "Levels");
      } else if (type === "stats") {
        return attribute?.find((item) => item.display_type === "Stats");
      }
    }
  };

  const getUserId = async () => {
    const jwt = localStorage.getItem("USER_AUTH_TOKEN");
    if (jwt) {
      const decode = await jwtDecode(jwt);
      setUserId(decode.user_id);
    }

    setIsPublic(jwt === null);
  };

  const onUserClicked = () => {
    if (IS_EVM) {
      dispatch(setSection("affluence-user", nft?.creator_wallet_address));
    } else {
      dispatch(setSection("affluence-user", nft?.membership_user?.user_id));
    }
  };

  const onBuyNow = () => {
    if (localStorage.getItem("USER_AUTH_TOKEN")) {
      if (nft?.status === NFT_STATUS.CREATED && nft?.Auctions?.length > 0) {
        dispatch(
          setSection(
            "affluence-auction-detail-nft",
            nft?.Auctions[0].auction_id
          )
        );
      } else if (
        nft?.status === NFT_STATUS.CREATED &&
        nft?.SellEscrows?.length > 0
      ) {
        dispatch(
          setSection(
            "affluence-buy-sell-detail-nft",
            nft?.SellEscrows[0].sell_escrow_id
          )
        );
      }
    } else {
      dispatch(openModalLoginRegister());
    }
  };

  useEffect(() => {
    getUserId();
    getNFT();
  }, [param]);

  // Check Allowance NFT
  const { data: dataCheckAllowanceNft, isSuccess: isSuccessCheckAllowanceNft } =
    useContractRead({
      address: nft?.contract_address, // set value contract nft for sell
      abi: AbiNFTPort,
      functionName: "isApprovedForAll",
      args: [
        address, // address seller
        nftMarketplaceAddress, // address NFT Marketplace
      ],
    });

  const { config: configSetAllowanceNft } = usePrepareContractWrite({
    address: nft?.contract_address, // set value contract nft for sell
    abi: AbiNFTPort,
    functionName: "setApprovalForAll",
    args: [nftMarketplaceAddress, true],
  });

  const {
    writeAsync: writeSetAllowanceNft,
    data: dataSetAllowanceNft,
    isSuccess: isSuccessSetAllowanceNft,
    isLoading: isLoadingSetAllowance,
  } = useContractWrite(configSetAllowanceNft);

  useEffect(() => {
    if (isSuccessSetAllowanceNft) {
      notification.success({
        description:
          "Permission granted for NFT Sale, Please wait for blockchain confirmation & refresh the page after 1 mins",
      });
    }
  }, [isLoadingSetAllowance]);

  return (
    <SEO
      title={`IBFNEX - ${metadata?.name || "NFT Detail"}`}
      description={metadata?.description}
      image={CREDENCE_HOST + "/files/" + nft?.cover_file}
    >
      <Canvas>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
            {nft?.cover_file ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "520px",
                  objectFit: "cover",
                }}
                src={CREDENCE_HOST + "/files/" + nft?.cover_file}
              />
            ) : (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "520px",
                  objectFit: "cover",
                }}
                // src="https://upload.wikimedia.org/wikipedia/id/5/56/Harry_potter_deathly_hallows_US.jpg"
                src={
                  metadata?.image &&
                  `https://ipfs.io/ipfs/${metadata?.image?.replace(
                    "ipfs://",
                    ""
                  )}`
                }
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={14}>
            <RowSpaceBetween
              style={{
                marginBottom: "12px",
              }}
            >
              <CollectionNameStyled>
                {metadata?.collection?.name || ""}
              </CollectionNameStyled>
              <RowEnd>
                {metadata?.twitter_link && (
                  <IconStyled
                    src={IconTwitter}
                    onClick={() => {
                      if (metadata?.twitter_link) {
                        const link = formatLink(
                          "https://twitter.com/",
                          metadata?.twitter_link
                        );
                        window.open(link, "_blank");
                      }
                    }}
                    width={24}
                  />
                )}
                {metadata?.instagram_link && (
                  <IconStyled
                    src={IconInstagram}
                    onClick={() => {
                      if (metadata?.instagram_link) {
                        const link = formatLink(
                          "https://instagram.com/",
                          metadata?.instagram_link
                        );
                        window.open(link, "_blank");
                      }
                    }}
                    width={24}
                  />
                )}
                {metadata?.web_link && (
                  <IconStyled
                    src={IconWeb}
                    onClick={() => {
                      if (metadata?.web_link) {
                        const link = formatLink("", metadata?.web_link);
                        window.open(link, "_blank");
                      }
                    }}
                    width={24}
                  />
                )}
              </RowEnd>
            </RowSpaceBetween>
            <ColStyled span={24} style={{ marginBottom: "8px" }}>
              <NameStyled>{metadata?.name}</NameStyled>
            </ColStyled>
            <ColStyled
              span={24}
              style={{
                marginBottom: "24px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                // minHeight: "90px",
              }}
            >
              <DescStyled>{metadata?.description}</DescStyled>
            </ColStyled>
            <NFTDetailsStyled>
              <DetailTitleStyled>
                <RowSpaceBetween>
                  <span>{t("nft_details")}</span>
                  <RowEnd
                    className="pointer"
                    onClick={() => {
                      if ((IS_EVM && nft.contract_address) || !IS_EVM) {
                        const url = IS_EVM
                          ? `${POLYGON_EXPLORER_URL}/${nft?.contract_address}`
                          : `${ALGO_EXPLORER_URL}/asset/${nft?.asa_id}`;
                        window.open(url, "");
                      }
                    }}
                  >
                    <span className="mt-1px">
                      {IS_EVM ? "View on Explorer" : t("view_on_algo")}
                    </span>
                    <img
                      className="ml-8px"
                      src={IconArrowRight}
                      alt="arrow-right"
                    />
                  </RowEnd>
                </RowSpaceBetween>
              </DetailTitleStyled>
              <DetailInfoStyled>
                <RowSpaceBetween>
                  <LabelStyled>Metadata</LabelStyled>
                  <ValueStyled>
                    <a
                      onClick={() => {
                        window.open(
                          `https://ipfs.io/ipfs/${nft?.metadata_url}`,
                          ""
                        );
                      }}
                    >
                      {nft?.metadata_url
                        ? windowDimensions.width < 1200
                          ? nft?.metadata_url.substring(0, 5) +
                            "..." +
                            nft?.metadata_url.substring(
                              nft?.metadata_url?.length - 5,
                              nft?.metadata_url?.length - 1
                            )
                          : nft?.metadata_url
                        : "-"}
                    </a>
                  </ValueStyled>
                </RowSpaceBetween>
                <RowSpaceBetween className="mt-16px">
                  <LabelStyled>
                    {IS_EVM ? "Royalty Fee" : "Token ID"}
                  </LabelStyled>
                  <ValueStyled>
                    {(IS_EVM ? nft?.royalty_fee + " %" : nft?.asa_id) || "-"}
                  </ValueStyled>
                </RowSpaceBetween>
                <RowSpaceBetween className="mt-16px">
                  <LabelStyled>
                    {IS_EVM ? "NFT Minted" : t("token_standard")}
                  </LabelStyled>
                  <ValueStyled>{IS_EVM ? nft?.supply : "ARC-721"}</ValueStyled>
                </RowSpaceBetween>
                <RowSpaceBetween className="mt-16px">
                  <LabelStyled>
                    {IS_EVM ? "Max Supply" : t("fragmentation")}
                  </LabelStyled>
                  <ValueStyled>
                    {IS_EVM ? nft?.max_supply : nft?.fragmentation}
                  </ValueStyled>
                </RowSpaceBetween>
              </DetailInfoStyled>
            </NFTDetailsStyled>

            <RowSpaceBetween>
              <ColFooterStyled>
                <LabelFooterStyled>
                  {!IS_EVM ? t("current_owner") : "Creator"}
                </LabelFooterStyled>
                <ValueFooterStyled className="pointer" onClick={onUserClicked}>
                  <img
                    src={`${MEMBERSHIP_HOST}/files/${
                      IS_EVM ? creator?.photo : nft?.membership_user?.photo
                    }`}
                  />
                  {IS_EVM ? creator?.name : nft?.membership_user?.name}
                </ValueFooterStyled>
              </ColFooterStyled>
              <ColFooterStyled>
                <LabelFooterStyled>
                  {IS_EVM ? "Created On" : "Mint On"}
                </LabelFooterStyled>
                <ValueFooterStyled>
                  {IS_EVM
                    ? moment(nft?.created_at).format("LL")
                    : moment(
                        nft?.original_updated_at ||
                          nft?.updated_at ||
                          nft?.created_at
                      ).format("LL")}
                </ValueFooterStyled>
              </ColFooterStyled>
            </RowSpaceBetween>
            {IS_EVM ? (
              <Row gutter={[16, 16]} className="mt-24px">
                {!isListed && nft?.status === "CREATED" && (
                  <>
                    {!dataCheckAllowanceNft && (
                      <span>
                        Look's like your nft is not yet allowed to sell,{" "}
                        <span
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => writeSetAllowanceNft()}
                        >
                          click here
                        </span>{" "}
                        to allow. This might take upto 5 Mins
                      </span>
                    )}
                    <ModalSellNFT
                      disabled={!dataCheckAllowanceNft}
                      noMargin
                      metadata={metadata}
                      data={nft}
                    />
                  </>
                )}
              </Row>
            ) : nft?.status === NFT_STATUS.INIT ? (
              <ApprovalPending>{t("approval_pending")}</ApprovalPending>
            ) : (
              <>
                {windowDimensions.width < 835 ? (
                  <div className="absolute-bottom">
                    <Row gutter={[16, 16]} className="mt-24px">
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        {nft?.status === NFT_STATUS.APPROVED ? (
                          <LabelNFTApproved>
                            {t("nft_approved")}
                          </LabelNFTApproved>
                        ) : isPublic && isListed ? (
                          <Button
                            block
                            style={{ height: 40, borderRadius: 4 }}
                            type="primary"
                            onClick={onBuyNow}
                          >
                            {t("buy_now")}
                          </Button>
                        ) : (
                          <Tooltip
                            placement="bottom"
                            title={t("tooltip_nft_detail")}
                          >
                            <Button
                              block
                              style={{ height: 40, borderRadius: 4 }}
                              // type="primary"
                              disabled={!nft?.asa_id}
                              onClick={() =>
                                window.open(
                                  `https://ipfs.io/ipfs/${metadata?.image?.replace(
                                    "ipfs://",
                                    ""
                                  )}`,
                                  ""
                                )
                              }
                            >
                              {t("view_file")}
                            </Button>
                          </Tooltip>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        {nft?.user_id === userId ? (
                          nft?.status === NFT_STATUS.CREATED ? (
                            nft?.Auctions?.length > 0 ? (
                              <ModalDelistingAuctionNFT
                                isFromDetail
                                noMargin
                                data={{
                                  auction: nft?.Auctions[0],
                                  metadata,
                                  transaction: nft?.Auctions[0].Transactions[0],
                                }}
                              />
                            ) : nft?.SellEscrows?.length > 0 ? (
                              <ModalDelistingNFT
                                isFromDetail
                                noMargin
                                sell_escrow_id={
                                  nft?.SellEscrows[0].sell_escrow_id
                                }
                                name={metadata?.name}
                                collection={metadata?.collection?.name}
                                fragmentation={metadata?.fragmentation}
                                listed_on={nft?.created_at}
                                price={nft?.SellEscrows[0].price}
                                ipfsHash={metadata?.image?.replace(
                                  "ipfs://",
                                  ""
                                )}
                                is_pdf={metadata?.is_pdf || false}
                              />
                            ) : (
                              <ModalSellNFT
                                noMargin
                                metadata={metadata}
                                data={nft}
                              />
                            )
                          ) : (
                            <>
                              {metadata && (
                                <ModalPayFeeNft
                                  isFromDetail={true}
                                  toVault={() =>
                                    dispatch(setSection("credence"))
                                  }
                                  disabled={nft.status !== NFT_STATUS.APPROVED}
                                  record={nft}
                                  metadata={metadata}
                                />
                              )}
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {/* <ModalSellNFTComingSoon /> */}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <Row gutter={[16, 16]} className={"mt-24px"}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      {nft?.status === NFT_STATUS.APPROVED ? (
                        <LabelNFTApproved>{t("nft_approved")}</LabelNFTApproved>
                      ) : isPublic && isListed ? (
                        <Button
                          block
                          style={{ height: 40, borderRadius: 4 }}
                          type="primary"
                          onClick={onBuyNow}
                        >
                          {t("buy_now")}
                        </Button>
                      ) : (
                        <Tooltip
                          placement="bottom"
                          title={t("tooltip_nft_detail")}
                        >
                          <Button
                            block
                            style={{ height: 40, borderRadius: 4 }}
                            // type="primary"
                            disabled={!nft?.asa_id}
                            onClick={() =>
                              window.open(
                                `https://ipfs.io/ipfs/${metadata?.image?.replace(
                                  "ipfs://",
                                  ""
                                )}`,
                                ""
                              )
                            }
                          >
                            {t("view_file")}
                          </Button>
                        </Tooltip>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      {nft?.user_id === userId ? (
                        nft?.status === NFT_STATUS.CREATED ? (
                          nft?.Auctions?.length > 0 ? (
                            <ModalDelistingAuctionNFT
                              isFromDetail
                              data={{
                                auction: nft?.Auctions[0],
                                metadata,
                                transaction: nft?.Auctions[0].Transactions[0],
                              }}
                            />
                          ) : nft?.SellEscrows?.length > 0 ? (
                            <ModalDelistingNFT
                              isFromDetail
                              sell_escrow_id={
                                nft?.SellEscrows[0].sell_escrow_id
                              }
                              name={metadata?.name}
                              collection={metadata?.collection?.name}
                              fragmentation={metadata?.fragmentation}
                              listed_on={nft?.created_at}
                              price={nft?.SellEscrows[0].price}
                              ipfsHash={metadata?.image?.replace("ipfs://", "")}
                              is_pdf={metadata?.is_pdf || false}
                            />
                          ) : (
                            <ModalSellNFT
                              noMargin
                              metadata={metadata}
                              data={nft}
                            />
                          )
                        ) : (
                          <>
                            {metadata && (
                              <ModalPayFeeNft
                                isFromDetail={true}
                                toVault={() => dispatch(setSection("credence"))}
                                disabled={nft.status !== NFT_STATUS.APPROVED}
                                record={nft}
                                metadata={metadata}
                              />
                            )}
                          </>
                        )
                      ) : (
                        <></>
                      )}
                      {/* <ModalSellNFTComingSoon /> */}
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>
        {IS_EVM && metadata?.is_airdrop && (
          <NFTDetailsStyled className="mt-36px">
            <DetailTitleStyled>
              <RowSpaceBetween>
                <span>NFT is Airdrop</span>
                <RowEnd className="pointer">
                  <ClaimAirdrop
                    data={{
                      contract_address: nft?.contract_address,
                      whitelist_address: metadata?.whitelist_address,
                    }}
                  />
                </RowEnd>
              </RowSpaceBetween>
            </DetailTitleStyled>
            <DetailInfoStyled>
              <RowSpaceBetween className="mt-16px">
                <LabelStyled>Whitelist Address</LabelStyled>
                <div>
                  {metadata?.whitelist_address?.map((i) => {
                    return <p style={{ margin: 0 }}>{i.address}</p>;
                  })}
                </div>
              </RowSpaceBetween>
            </DetailInfoStyled>
          </NFTDetailsStyled>
        )}
        {!IS_EVM && metadata?.attributes?.length > 0 && (
          <Row className="mt-36px">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Tabs
                size="large"
                onChange={(val) => setMenu(val)}
                defaultActiveKey={menu}
                activeKey={menu}
              >
                {checkAttribute("properties") && (
                  <TabPane tab="Properties" key="properties">
                    <Row gutter={[16, 16]}>
                      {nft && formatAttributes("properties")}
                    </Row>
                  </TabPane>
                )}
                {checkAttribute("levels") && (
                  <TabPane tab="Levels" key="levels">
                    <Row gutter={[16, 16]}>
                      {nft && formatAttributes("levels")}
                    </Row>
                  </TabPane>
                )}
                {checkAttribute("stats") && (
                  <TabPane tab="Stats" key="stats">
                    <Row gutter={[16, 16]}>
                      {nft && formatAttributes("stats")}
                    </Row>
                  </TabPane>
                )}
                {/* <TabPane tab="Details" key="details">
							<Row>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
									<RowSpaceBetween>
										<Text className="text-size-14 text-w400 mb-0px ">Contact Address</Text>
										<Text className="text-size-16 text-w500 mb-0px ">
											{nft?.auction?.algo_address
												? nft?.auction?.algo_address.substring(0, 7) +
												  '...' +
												  nft?.auction?.algo_address.substring(
														nft?.auction?.algo_address.length - 7,
														nft?.auction?.algo_address.length - 1
												  )
												: '-'}
										</Text>
									</RowSpaceBetween>
									<RowSpaceBetween className="mt-12px">
										<Text className="text-size-14 text-w400 mb-0px ">Token ID</Text>
										<Text className="text-size-16 text-w500 mb-0px ">{nft?.auction?.NFT?.asa_id || '-'}</Text>
									</RowSpaceBetween>
									<RowSpaceBetween className="mt-12px">
										<Text className="text-size-14 text-w400 mb-0px ">Token Standard</Text>
										<Text className="text-size-16 text-w500 mb-0px ">ARC-721</Text>
									</RowSpaceBetween>
									<RowSpaceBetween className="mt-12px">
										<Text className="text-size-14 text-w400 mb-0px ">Blockchain</Text>
										<Text className="text-size-16 text-w500 mb-0px ">Algorand</Text>
									</RowSpaceBetween>
								</Col>
							</Row>
						</TabPane> */}
              </Tabs>
            </Col>
          </Row>
        )}
        {nfts.length > 0 && <MoreStyled>More of your NFT</MoreStyled>}
        <Row>
          {nfts?.map((nft) => {
            if (nft.status === "FAILED" || nft.nft_id === param) {
              return <></>;
            }

            const metadata = JSON.parse(nft.metadata_json);
            return (
              <CardNFT
                nftId={nft.nft_id}
                name={metadata.name}
                collectionName={metadata?.collection.name}
                ipfsHash={metadata?.image?.replace("ipfs://", "")}
                is_pdf={metadata?.is_pdf || false}
                data={nft}
              />
            );
          })}
        </Row>
      </Canvas>
    </SEO>
  );
}
