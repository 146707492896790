import React, { useEffect, useState } from "react";
import { Typography, Col, Row, Table, Tag, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fromIBFx } from "../../utils/utils";
import useWindowDimensions from "../../utils/windowDimensions";
import IBFxLogo from "../../assets/images/ibfx-icon.svg";
import { RowEnd } from "../../components/general_styled";
import { SearchStyled } from "../Credence/styled";
import jwtDecode from "jwt-decode";
import { getAllLoans } from "../../modules/loans/action";
import {
  LOAN_COLLATERAL_STATUS,
  LOAN_STATUS,
} from "../../constants/constant_loans";
import ModalLoansNewRequest from "../../components/ModalLoansNewRequest";
import ModalLoansView from "../../components/ModalLoansView";
import ModalLoansAddCollateral from "../../components/ModalLoansAddCollateral";
import ModalLoansClaim from "../../components/ModalLoansClaim";
import ModalLoansClaimBackCollateral from "../../components/ModalLoansClaimBackCollateral";
import { getAllEssencePurchase } from "../../modules/essence/action";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export default function MicrofinanceMyLoans(props) {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const AllLoansData = useSelector((state) =>
    state.getIn(["loans", "allLoans", "data"]).toJS()
  );
  const AllLoansCurrentPage = useSelector((state) =>
    state.getIn(["loans", "allLoans", "currentPage"])
  );
  const AllLoansTotal = useSelector((state) =>
    state.getIn(["loans", "allLoans", "total"])
  );

  const page = useSelector((state) =>
    state.getIn(["essence", "purchases", "currentPage"])
  );

  const [searchVal, setSearchVal] = useState("");
  const [userId, setUserId] = useState("");
  const [menu, setMenu] = useState("all_loans");

  const dispatch = useDispatch();

  const onPageChange = (num) => {
    if (menu === "all_loans") {
      dispatch(getAllLoans(userId, null, num));
    } else if (menu === "due") {
      dispatch(getAllLoans(userId, null, num, LOAN_STATUS.DUE));
    } else if (menu === "overdue") {
      dispatch(getAllLoans(userId, null, num, LOAN_STATUS.OVERDUE));
    }
  };

  const onTabChanged = async (val) => {
    if (val === "all_loans") {
      await dispatch(getAllLoans(userId, null, 1));
    } else if (val === "due") {
      await dispatch(getAllLoans(userId, null, 1, LOAN_STATUS.DUE));
    } else if (val === "overdue") {
      await dispatch(getAllLoans(userId, null, 1, LOAN_STATUS.OVERDUE));
    }
    setMenu(val);
  };

  const columns = [
    {
      title: t("date_requested"),
      dataIndex: "created_at",
      key: "created_at",
      render: (dateTime) => (
        <>{moment(dateTime).format("DD/MM/YYYY HH:mm A")}</>
      ),
    },
    {
      title: t("amount"),
      dataIndex: "total_ibfx",
      key: "total_ibfx",
      align: "right",
      render: (amount) => (
        <RowEnd>
          <img src={IBFxLogo} className="mr-4px" width={18} />
          <Text className="text-w500">{fromIBFx(amount)}</Text>
        </RowEnd>
      ),
    },

    {
      title: t("duration"),
      dataIndex: "duration",
      key: "duration",
      render: (duration, record) => (
        <>{getMonthBetween(record?.created_at, record?.deadline)}</>
      ),
    },
    {
      title: t("deadline"),
      dataIndex: "deadline",
      key: "deadline",
      render: (dateTime) => <>{moment(dateTime).format("DD/MM/YYYY")}</>,
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        let color = "success";

        if (status === LOAN_STATUS.INIT) {
          color = "blue";
        }
        if (
          status === LOAN_STATUS.PENDING ||
          status === LOAN_STATUS.PROVIDING ||
          status === LOAN_STATUS.PAYBACK_PENDING ||
          status === LOAN_STATUS.ON_GOING ||
          status === LOAN_STATUS.PAYING
        ) {
          color = "warning";
        }
        if (status === LOAN_STATUS.CREATED || status === LOAN_STATUS.PAID) {
          color = "success";
        }
        if (status === LOAN_STATUS.FAILED) {
          color = "error";
        }
        if (status === LOAN_STATUS.PROVIDED) {
          color = "purple";
        }
        if (status === LOAN_STATUS.PAYBACK) {
          color = "geekblue";
        }
        if (status === LOAN_STATUS.EXTENSION) {
          color = "gold";
        }
        if (status === LOAN_STATUS.DUE) {
          color = "orange";
        }
        if (status === LOAN_STATUS.CLOSED) {
          // if (status === LOAN_STATUS.CLOSED || isHaveSuccessClaimCollateral) {
          color = "grey";
        }
        if (status === LOAN_STATUS.EXTENSION) {
          color = "gold";
        }
        if (status === LOAN_STATUS.OVERDUE) {
          color = "volcano";
        }
        return (
          <>
            <Tag className="tag-custom" color={color}>
              {status === LOAN_STATUS.ON_GOING
                ? "DUE"
                : status.toUpperCase().replace("_", " ")}
            </Tag>
          </>
        );
      },
    },

    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        let isHaveSuccessClaimCollateral;
        let isSuccessClaimBackCollateral;
        if (record.is_collateral_required) {
          isHaveSuccessClaimCollateral =
            record.LoanCollaterals.filter(
              (e) => e.status === LOAN_COLLATERAL_STATUS.SUCCESS
            ).length >= 1;

          isSuccessClaimBackCollateral = record.LoanCollaterals.find(
            (e) => e.status === LOAN_COLLATERAL_STATUS.CLAIM_BACK_SUCCESS
          );
        }

        if (
          record.status === LOAN_STATUS.INIT ||
          record.status === LOAN_STATUS.CREATED ||
          record.status === LOAN_STATUS.PENDING
        ) {
          return <ModalLoansView record={record} isHasCancel />;
        } else if (
          record.status === LOAN_STATUS.PROVIDED &&
          record.is_collateral_required &&
          record.LoanCollaterals.length <= 0
        ) {
          return <ModalLoansAddCollateral record={record} />;
        } else if (
          (record.status === LOAN_STATUS.PROVIDED &&
            record.is_collateral_required &&
            record.LoanCollaterals.length > 0) ||
          (record.status === LOAN_STATUS.PROVIDED &&
            !record.is_collateral_required)
        ) {
          return <ModalLoansClaim record={record} />;
        } else if (
          record.status === LOAN_STATUS.PAID &&
          record.is_collateral_required &&
          isSuccessClaimBackCollateral
        ) {
          return <ModalLoansView isDetail record={record} />;
        } else if (
          record.status === LOAN_STATUS.PAID &&
          record.is_collateral_required &&
          isHaveSuccessClaimCollateral
        ) {
          return <ModalLoansClaimBackCollateral record={record} />;
        } else if (
          record.status === LOAN_STATUS.PROVIDED &&
          record.is_collateral_required &&
          record.LoanCollaterals.filter(
            (e) => e.status === LOAN_COLLATERAL_STATUS.PENDING
          ).length > 0
        ) {
          return <ModalLoansView isDetail record={record} />;
        } else if (
          record.status === LOAN_STATUS.ON_GOING ||
          record.status === LOAN_STATUS.DUE ||
          record.status === LOAN_STATUS.OVERDUE ||
          record.status === LOAN_STATUS.EXTENSION
        ) {
          return <ModalLoansView record={record} />;
        } else {
          return <ModalLoansView isDetail record={record} />;
        }
      },
    },
  ];

  const renderSearchBar = () => (
    <RowEnd>
      <SearchStyled
        className="search-input"
        placeholder="Search"
        autoComplete="off"
        allowClear
        size="large"
        onSearch={(value) => setSearchVal(value)}
        style={{
          marginRight: "12px",
        }}
      />
      <ModalLoansNewRequest />
    </RowEnd>
  );

  const renderAllLoans = () => (
    <Row className="mt-20px">
      <Col span={24}>
        <Table
          dataSource={AllLoansData}
          columns={columns}
          pagination={{
            onChange: onPageChange,
            current: AllLoansCurrentPage,
            pageSize: 15,
            total: AllLoansTotal,
          }}
        />
      </Col>
    </Row>
  );

  const getData = async (page) => {
    const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
    await dispatch(getAllEssencePurchase(decoded.user_id, page));
  };

  useEffect(() => {
    setUserId(jwtDecode(localStorage.getItem("USER_AUTH_TOKEN")).user_id);
    dispatch(
      getAllLoans(
        jwtDecode(localStorage.getItem("USER_AUTH_TOKEN")).user_id,
        null,
        1
      )
    );
    getData(page);
  }, []);

  return (
    <>
      {windowDimensions.width < 835 && renderSearchBar()}
      <Tabs
        size="large"
        tabBarExtraContent={
          windowDimensions.width > 834 ? renderSearchBar() : null
        }
        onChange={onTabChanged}
        defaultActiveKey={menu}
        activeKey={menu}
      >
        <Tabs.TabPane tab={t("all_loan")} key="all_loans">
          {renderAllLoans()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("due")} key="due">
          {renderAllLoans()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("overdue")} key="overdue">
          {renderAllLoans()}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

const getMonthBetween = (startDate, endDate) => {
  var start = moment(startDate);
  var end = moment(endDate);
  var months = end.diff(start, "months");
  if (months <= 0) {
    const weeks = end.diff(start, "weeks");
    if (weeks <= 0) {
      const days = end.diff(start, "days");
      return days + " days";
    }
    return weeks + " weeks";
  }
  return months + " months";
};
