import styled from "@emotion/styled";

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;

  .ant-upload.ant-upload-select {
    display: block;
  }
`;

export const ErrorCard = styled.div`
  width: 100%;
  background: rgba(253, 223, 223, 0.5);
  /* Error/700 */

  border: 1px solid #c00b0b;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 10px;
  margin-top: 16px;
  margin-bottom: 24px;

  /* Caption/Semibold */

  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  /* or 150% */

  text-align: center;

  /* Error/700 */

  color: #c00b0b;
`;
