import styled from "@emotion/styled";
import { Card } from "antd";

export const CardStyled = styled(Card)`
  margin: 12px;

  background: #ffffff;
  /* Neutral/700 */

  border: 1px solid #a7b1c0;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;

  .ant-card-body {
    padding: 20px;
  }

  .ant-card-cover {
    padding: 8px;
    img {
      width: 100%;
      height: 350px;

      object-fit: cover;
    }
  }

  &.selected {
    border: 1px solid #38cb89;

    transition: all 0.3s ease;
    box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);

    .selected-indicator {
      z-index: 30;
      opacity: 1;

      position: absolute;
      top: 10px;
      left: 10px;
      /* Success/600 */

      background: #289a67;
      /* Success/600 */

      border: 1px solid #289a67;
      box-sizing: border-box;
      border-radius: 2px;
      color: white;
      // font-family: Roboto;
      font-weight: 400;
      letter-spacing: 0.5px;
      padding: 1px 8px;
    }
  }

  &:hover {
    border: 1px solid #38cb89;

    transition: all 0.3s ease;
    box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
  }

  .canvas-cover {
    position: relative;
  }

  .selected-indicator {
    z-index: 30;
    opacity: 0;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  @media screen and (max-width: 834px) {
    .ant-card-cover {
      padding: 8px;
      aspect-ratio: 1/1;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
  }
`;

export const CollectionNameStyled = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text/300 */

  color: #42556e;
  margin-bottom: 6px;

  &.no-collection {
    color: #42556e53;
    font-style: italic;
  }
`;

export const NFTNameStyled = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Text/500 */

  color: #293544;
`;
