import styled from "@emotion/styled";
import { Typography, Steps, Drawer } from "antd";

const { Text } = Typography;

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  // max-width: 1095px;
  // min-height: 610px;

  @media screen and (max-width: 500px) {
    padding-top: 36px;
  }
`;

export const StepsStyled = styled(Steps)`
  background: #fcfcfc;
  border: 1px solid #d6dae1;
  box-sizing: border-box;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  padding: 8px 5%;
  // font-family: 'Roboto';
`;

export const StepContentStyled = styled.div`
  width: 100%;
  min-height: 362px;
  position: relative;
  height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 24px;
  margin: 1rem 2.5%;

  background: #fcfcfc;
  box-sizing: border-box;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  overflow: auto;

  &.height-auto {
    min-height: auto;
  }

  &.center {
    justify-content: center;
  }
`;

export const ContentTextStyled = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #273248;

  flex: none;
  order: 0;
  flex-grow: 0;

  max-width: 831px;
`;

export const TitleStyled = styled(Text)`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;
`;

export const TextStyled = styled(Text)`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* or 167% */

  text-align: center;

  /* Gray/gray-7 */

  color: #273248;
  max-width: 350px;
`;

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-wrapper-body {
    width: 100%;
    height: 100vh !important;
  }

  .ant-drawer-header-title {
    justify-content: flex-end !important;
  }

  .ant-drawer-close {
    margin-right: 0px !important;
  }
`;

export const MobileFooterStyled = styled.div`
  bottom: 0;
  position: absolute;
  margin-bottom: 24px;
  width: 90%;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
`;
