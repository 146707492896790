import { CLOSE_REDEEM_VOUCHER_MODAL, OPEN_REDEEM_VOUCHER_MODAL } from "../constants"

export const openRedeemVoucherModal = () => (dispatch) => {
  dispatch({
    type: OPEN_REDEEM_VOUCHER_MODAL
  })
}

export const closeRedeemVoucherModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_REDEEM_VOUCHER_MODAL
  })
}