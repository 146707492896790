import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  DatePicker,
  AutoComplete,
  Input,
  InputNumber,
  Select,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { Editor } from "react-draft-wysiwyg";
import CoinbaseCommerceButton from "react-coinbase-commerce";

const { RangePicker } = DatePicker;

// const FieldStyled = styled(Field)`
//   padding: 5px 10px;
//   display: block;
//   border-radius: 5px;
//   width: 100%;
//   border: 1px solid #eee;
//   box-sizing: border-box;
// `

export const DatePickerStyled = styled(DatePicker)`
  // padding: 8px 12px;
  border-radius: 2px;
  width: 100%;
  height: 40px;

  background: #f4f6f7;
  border: 1px solid #d6dae1;

  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Text/200 */

    color: #52689588;
  }
`;

export const RangePickerStyled = styled(RangePicker)`
  border-radius: 2px;
  width: 100%;
  height: 40px;

  background: #f4f6f7;
  border: 1px solid #d6dae1;

  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Text/200 */

    color: #52689588;
  }
`;

// const ErrorMessageStyled = styled(ErrorMessage)`
//   padding-top: 10px;
//   color: #f00;
//   font-size: small;
//   font-style: italic;
//   margin-bottom: 20px;
// `

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.start {
    align-items: flex-start;
  }
  &.space-evently {
    justify-content: space-evenly;
  }
`;

export const RowEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &.center {
    align-items: center;
  }
`;

export const RowStart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.align-start {
    align-items: flex-start;
  }

  .expanded {
    flex: 1;
  }
`;

export const ColStart = styled.div`
  display: flex;
  flex-direction: column;
  &.stretch {
    align-items: stretch;
  }
  &.end {
    align-items: flex-end;
  }
`;

export const ColEnd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.end {
    align-items: end;
  }

  &.stretch {
    align-items: stretch;
  }
`;

export const ColCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.start {
    align-items: flex-start;
  }
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.align-center {
    align-items: center;
  }
`;

export const ColSpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.stretch {
    align-items: stretch;
  }

  &.space-evently {
    justify-content: space-evenly;
  }
`;

export const ButtonStyled = styled(Button)`
  background: #024FF1;
  margin: 0;
  min-height: 40px;

  box-sizing: border-box;
  border-radius: 4px;
  color: #fcfcfc;
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

export const ButtonCoinbaseStyled = styled(CoinbaseCommerceButton)`
  background: #024FF1;
  margin: 0;
  min-height: 40px;
  width: 100%;

  box-sizing: border-box;
  border-radius: 4px;
  color: #fcfcfc;
  // font-family: "Roboto";
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 24px;
  border: none;
  transition: 0.2s;

  &:hover {
    background: #024FF181;
    cursor: pointer;
  }
`;

export const ButtonWhiteStyled = styled("button")`
  background: #fff;
  margin: 0;
  min-height: 40px;

  box-sizing: border-box;
  border-radius: 4px;
  color: #024FF1;
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 24px;
  border: none;

  &:hover {
    background: #fafafa;
    color: #0077c7;
    cursor: pointer;
  }
`;

export const InputStyled = styled(Input)`
  &.danger {
    border: 1px solid #f5222d;
  }
  background: #f4f6f7;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 8px;

  padding: 8px 12px;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Text/200 */

    color: #52689588;
  }
  .ant-input {
    background-color: transparent !important;

    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      /* identical to box height, or 157% */

      /* Text/200 */

      color: #52689588;
    }
  }
`;

export const TextAreaStyled = styled(Input.TextArea)`
  &.danger {
    border: 1px solid #f5222d;
  }
  background: #f4f6f7;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 8px;

  padding: 8px 12px;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Text/200 */

    color: #52689588;
  }
`;

export const InputNumberStyled = styled(InputNumber)`
  &.danger {
    border: 1px solid #f5222d;
  }

  &.ant-input-number-group-wrapper {
    padding: 0 !important;
    border: none !important;
  }
  .ant-input-number-affix-wrapper {
    background-color: transparent !important;
  }

  background: #f4f6f7;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 8px;

  padding: 5px 12px;

  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Text/200 */

    color: #526895;
  }

  .ant-input-number-input {
    padding: 0;
  }
`;

export const InputPasswordStyled = styled(Input.Password)`
  background: #f4f6f7;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 2px;

  padding: 8px 12px;

  &.danger {
    border: 1px solid #f5222d;
  }

  input {
    background: #f4f6f7;
  }

  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Text/200 */

    color: #52689588;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  .ant-checkbox-input {
    background: #f4f6f7;
  }
`;

export const SelectStyled = styled(Select)`
  background: #f4f6f7;

  // border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 2px;

  width: 100%;

  &.danger {
    .ant-select-selector {
      border: 1px solid red !important;
    }
  }

  .ant-select-selector {
    background: transparent !important;
    outline: none !important;
    padding: 8px 12px !important;
    height: 40px !important;

    line-height: 22px !important;
  }

  .ant-select-selection-placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px !important;

    color: #52689588;
  }

  .ant-select-selection-item {
    line-height: 22px !important;
  }

  input {
  }
`;

export const AutoCompleteStyled = styled(AutoComplete)`
  background: #f4f6f7;

  // border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 2px;

  width: 100%;

  &.danger {
    .ant-select-selector {
      border: 1px solid red !important;
    }
  }

  .ant-select-selector {
    background: transparent !important;
    outline: none !important;
    padding: 8px 12px !important;
    height: 40px !important;

    line-height: 22px !important;
  }

  .ant-select-selection-placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px !important;

    color: #52689588;
  }

  .ant-select-selection-item {
    line-height: 22px !important;
  }
`;

export const DraggerStyled = styled(Dragger)`
  height: auto !important;
  width: 100% !important;
  border: none !important;

  .ant-upload {
    padding: 0 !important;
    border: none !important;
    background: none;
    height: 160px !important;
    width: 100% !important;
  }

  .upload-cover-photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
    padding: 42px;
    background: #f0f2f7;
    /* Stroke */

    border: 1px dashed #dfe7f7;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;

    img {
      width: 32px;
      margin-bottom: 8px;
    }

    .title {
      // font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;

      /* Inactive */

      color: #6b7a99;
      margin-bottom: 4px;
    }

    .desc {
      // font-family: "Roboto";
      font-style: italic;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;

      /* Inactive */

      color: #6b7a99;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    /* Stroke */
    padding: 42px;
    background: #f0f2f7;

    img {
      width: 63px;
      margin-bottom: 16px;
    }

    .title {
      // font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;
      /* identical to box height */

      /* Secondary Gray */

      color: #3d424d;
      margin-bottom: 4px;
    }

    .desc {
      // font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      /* identical to box height */

      /* Secondary Gray */

      color: #3d424d;
    }
  }
`;

export const ButtonOutline = styled(Button)`
  background-color: transparent;
  color: #024FF1;
  cursor: pointer;
  border: 1px solid #024FF1;
  box-sizing: border-box;
  border-radius: 2px;

  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/500 */

  color: #024FF1;
  display: flex;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ButtonPeraConnect = styled(Button)`
  border-radius: 10px;
  height: 40px;
  font-size: 15px;
  background: #ffee56;
  border: 1px solid #ffee56;
  font-weight: 500;

  &:hover {
    background: #e6d94c;
    border: 1px solid #e6d94c;
    color: #000;
  }

  &.ghost {
    background: none;
    border: 2px solid #ffee56;
  }

  .text-1 {
    color: #000;
  }

  .text-disable {
    color: rgba(0, 0, 0, 0.25);
  }
`;
