import { useTranslation } from "react-i18next";
import {
  ButtonOutline,
  ButtonStyled,
  ColCenter,
  RowCenter,
  RowSpaceBetween,
} from "../general_styled";
import CredenceOnBoarding from "../../pages/CredenceOnBoarding";
import { Col, Drawer, Image, Modal, Row, Typography, Progress } from "antd";
import {
  CanvasNftDetails,
  ContentStyled,
  DividerCard,
  StepContentStyled,
} from "../ModalCreateNFT/styled";
import useWindowDimensions from "../../utils/windowDimensions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IS_EVM } from "../../configs";
import { setSection } from "../../modules/general/action";

import closeIcon from "../../assets/images/close.svg";
import chevronLeftIcon from "../../assets/images/chevron-left.svg";

const { Text, Title } = Typography;

export default function ModalReviewNFT(props) {
  const {
    disabled,
    fileTemp,
    onSubmit,
    isProcessing,
    isSuccess,
    isFailed,
    processingPercent,
    nftName,
    description,
    symbol,
    fragmentation,
    royaltyPercentage,
    externalLink,
    closeModal,
  } = props;
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();

  const dispatch = useDispatch();

  const credenceUser = useSelector((state) =>
    state.getIn(["credence", "user"]).toJS()
  );

  const [current, setCurrent] = useState(2);
  const [visible, setVisible] = useState(false);
  const [continueDisabled, setContinueDisabled] = useState(false);

  const handleCancel = () => {
    closeModal();
    setVisible(false);
    setCurrent(2);
    if (!isProcessing && isSuccess && !isFailed) {
      dispatch(setSection("credence"));
    }
  };

  const toApproval = () => {
    props.toApproval();
    handleCancel();
  };

  const showModal = async () => {
    setVisible(true);
  };

  const onBack = () => {
    setCurrent(current - 1);
    setContinueDisabled(false);
  };

  const resolveContinueButton = () => {
    let text = isSuccess || isFailed ? t("dismiss") : t("continue");
    return (
      <ButtonOutline
        size="large"
        style={{ width: windowDimensions.width > 835 ? "20%" : "100%" }}
        type="primary"
        onClick={() => {
          if (current === 2 && IS_EVM) {
            onSubmit();
          }

          if (current === 0) {
            setCurrent(current + 1);
          } else {
            setCurrent(current + 1);
          }
        }}
        disabled={continueDisabled || isProcessing}
      >
        {current === 2 ? t("submit_for_review") : text}
      </ButtonOutline>
    );
  };

  const resolveContent = () => {
    switch (current) {
      case 2:
        return (
          <StepContentStyled>
            <Row className="h-100 my-8px " gutter={[24, 24]}>
              <Col
                xs={24}
                sm={24}
                md={windowDimensions.width > 925 ? 12 : 24}
                lg={11}
                xl={11}
                xxl={11}
              >
                <RowCenter>
                  <Image
                    src={fileTemp}
                    alt="img"
                    placeholder={
                      <Image
                        preview={false}
                        src={fileTemp}
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "400px",
                          objectFit: "cover",
                          borderRadius: 4,
                        }}
                      />
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "400px",
                      objectFit: "cover",
                      borderRadius: 4,
                    }}
                  />
                  {/* )} */}
                </RowCenter>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={windowDimensions.width > 925 ? 12 : 24}
                lg={13}
                xl={13}
                xxl={13}
              >
                <Title
                  className="text-w700 gray-7 mb-8px"
                  level={windowDimensions.width > 835 ? 4 : 2}
                >
                  {nftName}
                </Title>
                <Text
                  className={
                    "gray-7 " + (windowDimensions.width < 835 && "text-size-16")
                  }
                >
                  {description}
                </Text>
                <CanvasNftDetails>
                  <Title className="text-w500 gray-7 mb-0px" level={5}>
                    {t("nft_details")}
                  </Title>
                  <DividerCard />
                  <RowSpaceBetween>
                    <Text className="gray-7">Symbol</Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {symbol}
                    </Text>
                  </RowSpaceBetween>
                  <RowSpaceBetween className="mt-16px">
                    <Text className="gray-7">
                      {IS_EVM ? "Supply" : t("fragmentation_supply")}
                    </Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {fragmentation}
                    </Text>
                  </RowSpaceBetween>
                  <RowSpaceBetween className="mt-16px">
                    <Text className="gray-7">{t("external_link")}</Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {externalLink}
                    </Text>
                  </RowSpaceBetween>
                  <RowSpaceBetween className="mt-16px">
                    <Text className="gray-7">Royalty Percentage</Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {royaltyPercentage}
                    </Text>
                  </RowSpaceBetween>
                </CanvasNftDetails>
              </Col>
            </Row>
          </StepContentStyled>
        );

      case 3:
        return (
          <StepContentStyled className="center">
            <ColCenter>
              <Progress
                type="circle"
                percent={processingPercent}
                status={isFailed && "exception"}
              />
              <Title className="text-w700 gray-7 mb-8px mt-24px" level={3}>
                {isProcessing && !isSuccess && !isFailed
                  ? t("processing")
                  : isSuccess && !isFailed
                  ? t("nft_uploaded")
                  : t("failed")}
              </Title>
              <Text style={{ width: "60%" }} className="gray-7" align="center">
                {isProcessing && !isSuccess && !isFailed
                  ? t("nft_processing")
                  : isSuccess
                  ? t("nft_success_uploaded")
                  : t("nft_failed_uploaded")}
              </Text>
              {!isProcessing && !isFailed && (
                <ButtonStyled
                  className="mt-24px"
                  type="primary"
                  style={{
                    width: "350px",
                    height: "48px",
                    borderRadius: "4px",
                  }}
                  onClick={() =>
                    isSuccess && !isFailed
                      ? dispatch(setSection("credence"))
                      : dispatch(setSection("wallet-dashboard"))
                  }
                >
                  {isSuccess && !isFailed
                    ? t("check_approval")
                    : t("top_up_wallet")}
                </ButtonStyled>
              )}
              {!isProcessing && (
                <ButtonOutline
                  className="mt-10px"
                  style={{
                    width: "350px",
                    height: "48px",
                    borderRadius: "4px",
                  }}
                  onClick={() =>
                    isSuccess && !isFailed
                      ? handleCancel()
                      : dispatch(setSection("wallet-faq", "credence"))
                  }
                >
                  {isSuccess && !isFailed ? t("dismiss") : t("check_qa")}
                </ButtonOutline>
              )}
            </ColCenter>
          </StepContentStyled>
        );
    }
  };

  const resolveChildren = () => {
    const isVerified = IS_EVM
      ? false
      : Object.keys(credenceUser).length <= 0 ||
        !localStorage.getItem("ENCRYPTED_MNEMONIC");

    if (isVerified) {
      return (
        <Modal
          centered
          className="modal-top-up"
          visible={visible}
          footer={null}
          onCancel={handleCancel}
          closeIcon={<img src={closeIcon} onClick={handleCancel} />}
          maskStyle={{ backgroundColor: "#f3f6fa" }}
        >
          <CredenceOnBoarding />
        </Modal>
      );
    } else {
      return windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={visible}
          onCancel={handleCancel}
          className="modal-sell-nft"
          destroyOnClose
          closeIcon={
            <img
              style={{ marginRight: 35 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
          maskStyle={{ backgroundColor: "#fafafa" }}
          width="65%"
          footer={
            current >= 3
              ? null
              : [
                  <div className="footer-space-between">
                    <ButtonOutline
                      size="large"
                      style={{
                        width: windowDimensions.width > 835 ? "20%" : 150,
                      }}
                      disabled={current < 1 || current >= 3}
                      onClick={() => onBack()}
                    >
                      {t("previous")}
                    </ButtonOutline>
                    {current < 4 && resolveContinueButton()}
                  </div>,
                ]
          }
        >
          <ContentStyled>
            <ColCenter span={24}>
              <ColCenter style={{ paddingBottom: "24px" }}>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  {t("create_nft")}
                </Title>
                <Text className="gray-7">Review and submit your NFT</Text>
              </ColCenter>
              {resolveContent()}
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={visible}
          onCancel={handleCancel}
          placement="bottom"
          className="force-scroll"
          closable={false}
        >
          <ContentStyled>
            <RowSpaceBetween className="w-100">
              {current > 0 && (
                <img
                  style={{ marginRight: 10 }}
                  src={chevronLeftIcon}
                  onClick={() => setCurrent(current - 1)}
                />
              )}
              <img
                style={{ marginRight: 10 }}
                src={closeIcon}
                onClick={handleCancel}
              />
            </RowSpaceBetween>
            <ColCenter>
              <ColCenter style={{ paddingBottom: "24px" }}>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  {t("create_nft")}
                </Title>
                <Text className="gray-7">{t("complete_step_nft")}</Text>
              </ColCenter>
              {resolveContent()}
            </ColCenter>
            {current >= 3 ? null : (
              <div className="footer-space-between absolute-bottom">
                {/* <ButtonOutline
					  size="large"
					  className="mr-10px"
					  style={{
						width: 200,
					  }}
					  disabled={current < 1 || current >= 3}
					  onClick={() => onBack()}
					>
					  Previous
					</ButtonOutline> */}
                {current < 4 && resolveContinueButton()}
              </div>
            )}
          </ContentStyled>
        </Drawer>
      );
    }
  };

  return (
    <div className="my-24px">
      <ButtonStyled
        type={"primary"}
        size="large"
        disabled={disabled}
        onClick={() => showModal()}
        style={{
          width: "100%",
        }}
      >
        {t("create_nft")}
      </ButtonStyled>
      {resolveChildren()}
    </div>
  );
}
