import styled from "@emotion/styled";
import { Input, Row } from "antd";
import Text from "antd/lib/typography/Text";
import { RowSpaceBetween } from "../general_styled";

export const ContentStyled = styled.div`
  position: relative;
`;

export const TitleStyled = styled(Text)`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;
`;

export const TextStyled = styled(Text)`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* or 167% */

  text-align: center;

  /* Gray/gray-7 */

  color: #273248;
  max-width: 350px;
`;

export const ClaimedVoucher = styled(RowSpaceBetween)`
  width: 100%;

  background: #f7f8fa;
  /* Neutral/500 */

  border: 1px solid #edeff2;
  box-sizing: border-box;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  padding: 20px;

  flex-wrap: nowrap;
  align-items: center;

  .label {
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height */

    /* Secondary/300 */

    color: #6b7c94;
  }

  .value {
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    /* identical to box height */

    /* Gray/gray-7 */

    color: #273248;
  }
`;

export const MnemonicBoxStyled = styled(Row)`
  padding: 24px;
  background: #fcfcfc;
  /* Neutral/500 */

  border: 1px solid #edeff2;
  box-sizing: border-box;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  margin: 24px 0px;

  width: 100%;
  min-height: 400px;
`;

export const MnemonicFieldStyled = styled(Row)`
  flex-wrap: nowrap;
  align-items: center;

  padding: 12px 14px;
  background: #f4f6f7;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 2px;

  // font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
`;

export const MnemonicInputStyled = styled(Input)`
  padding: 0;
  background: transparent !important;
  border: none !important;

  margin-left: 6px;
  outline: none !important;
`;

export const DescStyled = styled(Text)`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Inactive */

  color: #6b7a99;
  margin-top: 20px;
  max-width: 700px;
`;

export const AlertRetrieve = styled.div`
  width: 100%;
  padding: 16px;
  background: rgba(253, 223, 223, 0.5);
  /* Error/500 */

  border: 1px solid #f43c3c;
  border-radius: 4px;

  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* or 143% */

  /* Text/400 */

  color: #364559;

  &.yellow {
    background: rgba(253, 238, 213, 0.5);
    /* Warning/500 */

    border: 1px solid #ec950a;
    border-radius: 4px;
  }
`;
