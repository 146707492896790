import styled from "@emotion/styled";
import { Typography } from "antd";

const { Text } = Typography;
export const DescStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 143% */

  text-align: center;

  /* Text/400 */

  color: #273248;

  max-width: 600px;
  margin-top: 24px;
  margin-bottom: 24px;
`;
