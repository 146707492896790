import styled from "@emotion/styled";
import Text from "antd/lib/typography/Text";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

export const TitleStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  /* Text/500 */

  color: #0b0e14;
  margin-top: 24px;
`;

export const TextOne = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 143% */

  text-align: center;

  /* Text/400 */

  color: #273248;
`;

export const TextTwo = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 143% */

  text-align: center;

  /* Text/500 */

  color: #0b0e14;
  margin-top: 24px;
`;

export const DividerStyled = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #d6dae1;
  margin: 24px 0;
`;
