import { ColCenter, ColSpaceBetween } from "../../components/general_styled"
import Waiting from "../../assets/images/waiting-man.png"
import { DividerStyled, Section, TextOne, TextTwo, TitleStyled } from "./styled"
import { Image } from "antd"

export default function AffluenceRestricted() {
  return (
    <ColSpaceBetween style={{ height: "100%" }}>
      <Section>
        <TitleStyled align="center" level={3}>
          Coming Soon to Mobile
        </TitleStyled>
        <TextOne>
          An NFT Marketplace with Auctions, Buy Now, Pay-Later, and Collateralization option
        </TextOne>
      </Section>
      <DividerStyled />
      <Section>
        <Image preview={false} src={Waiting} width={240} />
        <TextTwo>You can access Affluence through your Desktop browser</TextTwo>
      </Section>
    </ColSpaceBetween>
  )
}
