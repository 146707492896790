import React, { useEffect, useState } from "react";
import {
  Modal,
  Steps,
  Row,
  Col,
  Upload,
  message,
  Checkbox,
  Button,
  Select,
  Typography,
  Progress,
  notification,
} from "antd";
import uploadIcon from "../../assets/images/upload_icon_register.svg";
import closeIconModal from "../../assets/images/close_modal.svg";
import {
  uploadFile,
  getCountries,
  getStates,
  registerVolunteer,
  getUserIsVolunteer,
} from "../../modules/benevolence/action";
import { BENEVOLENCE_HOST } from "../../configs";
import { ButtonStyled, ColCenter, InputStyled } from "../general_styled";
import {
  DrawerStyled,
  MobileFooterStyled,
  StepContentStyled,
  StepsStyled,
  TextStyled,
  TitleStyled,
} from "./styled";
import useWindowDimensions from "../../utils/windowDimensions";
import { useDispatch } from "react-redux";

const { Text, Title } = Typography;
const { Dragger } = Upload;
const { Step } = Steps;
const steps = [
  "Your Details",
  "Volunteering",
  "Upload Documents",
  "Verification",
];

export default function ModalRegisterVolunteer(props) {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  //=== Personal Information ===
  const [nationality, setNationality] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [documents, setDocuments] = useState([]);
  const [skill, setSkill] = useState([]);
  const [specificSkill, setSpecificSkill] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    initContries();
  }, []);

  useEffect(() => {
    initState();
  }, [country]);

  const initContries = async () => {
    const results = await getCountries();
    setCountries(results);
  };

  const initState = async () => {
    if (!country) {
      return;
    }

    const results = await getStates(country);
    setStates(results);
  };

  const next = async () => {
    try {
      if (current >= 2) {
        await onRegisterVolunteer();
      }

      setCurrent(current + 1);
    } catch (e) {
      console.log(e);
      message.error("Failed to submit volunteering application");
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onRegisterVolunteer = async () => {
    setLoading(true);
    try {
      const volunteerJobTitle = {
        job_title: "",
        job_title_additional_1: null,
        job_title_additional_2: null,
      };

      skill.forEach((i, index) => {
        const val = i === "Others" ? specificSkill : i;
        if (index === 0) {
          volunteerJobTitle.job_title = val;
        } else {
          volunteerJobTitle[`job_title_additional_${index}`] = val || null;
        }
      });

      await registerVolunteer({
        volunteer: {
          ...volunteerJobTitle,
          nationality: nationality,
          residence_country: country,
          state: state,
        },
        volunteer_documents: documents.map((name, key) => ({
          file_name: name,
          description: "DOCUMENT",
          priority: key,
        })),
      });

      setSuccess(true)
      dispatch(getUserIsVolunteer())
    } catch (error) {
      setSuccess(false)
      notification.error({
        message: "Failed!",
        description: error,
      });
    } finally {
      setLoading(false);
    }
  };

  const onUpload = async (param) => {
    try {
      const response = await uploadFile(param.file);
      param.onSuccess(response, param.file);
    } catch (e) {
      param.onError(e, undefined);
    }
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10.1;
    if (!isLt2M) {
      message.error("File must smaller than 10mb");
    }
    return isLt2M;
  };

  const propsPassportUploader = {
    name: "file",
    multiple: true,
    action: BENEVOLENCE_HOST + "/files",
    maxCount: 5,
    beforeUpload: beforeUpload,
    customRequest: onUpload,
    onChange(info) {
      const { status, response } = info.file;
      // getData response.data.filename
      if (status !== "uploading") {
      }
      if (status === "done") {
        // setImagePassport(response.filename)
        setDocuments([response.filename, ...documents]);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else if (status === "removed") {
        setDocuments([...info.fileList]);
      }
    },
  };

  const handleCheckbox = (value) => {
    const lastAddedVal = value[value.length - 1];
    if (skill.find((val) => val === lastAddedVal)) {
      setSkill(value);
    } else if (skill.length < 3) {
      setSkill(value);
    }
  };

  const resolveContent = () => {
    if (current === 0) {
      return (
        <>
          <h4 className="header">Personal Information</h4>
          <Row className="row-custom">
            <Col span={24}>
              <ColCenter className="h-100 mb-16px">
                <Text className="w-100" align="left">
                  Nationality
                </Text>
              </ColCenter>
            </Col>
            <Col span={24}>
              <Select
                showSearch
                optionFilterProp="children"
                className="w-100"
                name="nationality"
                size="large"
                align="left"
                placeholder="Nationality"
                value={nationality}
                onChange={async (val) => {
                  setNationality(val);
                }}
              >
                {countries.map((country) => (
                  <Select.Option value={country.country_id}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row className="row-custom">
            <Col span={24}>
              <ColCenter className="h-100 mb-16px">
                <Text className="w-100" align="left">
                  Country of Residence
                </Text>
              </ColCenter>
            </Col>
            <Col span={24}>
              <Select
                showSearch
                optionFilterProp="children"
                className="w-100"
                type="dropdown"
                name="country"
                size="large"
                align="left"
                placeholder="Country of Residence"
                value={country}
                dropdownMatchSelectWidth={false}
                onChange={async (val) => {
                  setCountry(val);
                }}
              >
                {countries.map((country) => (
                  <Select.Option value={country.country_id}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row className="row-custom">
            <Col span={24}>
              <ColCenter className="h-100 mb-16px">
                <Text className="w-100" align="left">
                  State
                </Text>
              </ColCenter>
            </Col>
            <Col span={24}>
              <Select
                showSearch
                optionFilterProp="children"
                className="w-100"
                type="dropdown"
                name="state"
                size="large"
                align="left"
                placeholder="State"
                value={state}
                onChange={async (val) => {
                  setState(val);
                }}
              >
                {states.map((state) => (
                  <Select.Option value={state.state_id}>
                    {state.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </>
      );
    } else if (current === 1) {
      return (
        <>
          <Row className="row-custom">
            <Col span={24}>
              <h4 className="header" style={{ marginBottom: "6px" }}>
                Skills
              </h4>
              <p className="subheader">
                This will allow us to recommend you projects based on your skill
                sets{" "}
                <span style={{ fontWeight: 200 }}>
                  (You can choose up to 3 skills)
                </span>
              </p>
              {console.log(skill)}
              <Checkbox.Group
                onChange={handleCheckbox}
                value={skill}
                className="flex-start-custom"
              >
                <Row>
                  <Col span={24} style={{ marginBottom: "16px" }}>
                    <Checkbox value={"Cleric Support"}>Cleric Support</Checkbox>
                    <Checkbox value={"Service & Sales"}>
                      Service & Sales
                    </Checkbox>
                    <Checkbox value={"Design"}>Design</Checkbox>
                    <Checkbox value={"IT"}>IT</Checkbox>
                    <Checkbox value={"Lawyer"}>Lawyer</Checkbox>
                  </Col>
                  <Col span={24} style={{ marginBottom: "16px" }}>
                    <Checkbox value={"Lecturer"}>Lecturer</Checkbox>
                    <Checkbox value={"Teacher"}>Teacher</Checkbox>
                    <Checkbox value={"Doctor"}>Doctor</Checkbox>
                    <Checkbox value={"Healthcare Worker"}>
                      Healthcare Worker
                    </Checkbox>
                    <Checkbox value={"Social Service Worker"}>
                      Social Service Worker
                    </Checkbox>
                  </Col>
                  <Col span={24} style={{ marginBottom: "16px" }}>
                    <Checkbox value={"Others"}>Others</Checkbox>
                  </Col>
                  {skill.find((val) => val === "Others") ? (
                    <InputStyled
                      size="large"
                      placeholder={"Specify your skills"}
                      value={specificSkill}
                      onChange={(e) => setSpecificSkill(e.target.value)}
                    />
                  ) : (
                    <></>
                  )}
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>
        </>
      );
    } else if (current === 2) {
      return (
        <>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Title level={5} className="mb-24px">
                Upload Project Related Documents (Optional)
              </Title>
              <ColCenter>
                <div
                  className={`divider-input w-100 ${
                    windowDimensions.width > 900 ? "pl-12px" : "pl-12px"
                  }`}
                >
                  <Dragger {...propsPassportUploader}>
                    <p className="ant-upload-drag-icon">
                      <img src={uploadIcon} alt="upload-icon" />
                    </p>
                    <p className="ant-upload-text">
                      Drop your file here or{" "}
                      <strong className="color-primary">browse</strong>
                    </p>
                    <p className="ant-upload-hint">
                      Any documents that show your experience or your CV/Resume
                    </p>
                    <p className="ant-upload-hint">
                      <strong>Max file size 10MB</strong>
                    </p>
                  </Dragger>
                </div>
                {/* {windowDimensions.width > 900 && <img className="mt-16px" src={passportCard} alt="passport_card" />} */}
              </ColCenter>
            </Col>
          </Row>
        </>
      );
    } else if (current === 3) {
      return (
        <ColCenter className="h-100 w-100">
          <ColCenter style={{ width: "300px" }}>
            <Progress type="circle" percent={100} status={success ? "success" : "exception"} />
            <TitleStyled
              style={{
                marginTop: "24px",
              }}
            >
              Application Submitted!
            </TitleStyled>
            <TextStyled style={{}}>
              We are currently reviewing your application and will
              get back to you soon on your registered email
            </TextStyled>
            <ButtonStyled
              style={{
                width: "100%",
                marginTop: "24px",
              }}
              onClick={() => {
                setVisible(false);
              }}
            >
              Dismiss
            </ButtonStyled>
          </ColCenter>
        </ColCenter>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Button
        size="large"
        style={{ minWidth: windowDimensions.width > 900 ? 400 : 300 }}
        className="mt-24px mx-24px"
        type="primary"
        onClick={() => setVisible(true)}
        disabled={props.disabled}
      >
        Sign me up
      </Button>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          // title="Modal 1000px width"
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          closeIcon={<img src={closeIconModal} />}
          width={"85%"}
          footer={
            <div className="footer-space-between">
              <Button
                size="large"
                disabled={current < 1 || current >= 4}
                onClick={() => prev()}
              >
                Previous
              </Button>
              {current < 3 && (
                <Button
                  type="primary"
                  size="large"
                  disabled={current >= 3}
                  loading={loading}
                  onClick={() => next()}
                >
                  {current >= 2 ? "Submit" : "Continue"}
                </Button>
              )}
            </div>
          }
        >
          <ColCenter span={24}>
            <ColCenter style={{ paddingBottom: "24px" }}>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Register as Volunteer
              </Title>
              <Text className="gray-7">
                Contribute your skill as a volunteer by registering to our
                database
              </Text>
            </ColCenter>

            <StepsStyled current={current} direction="horizontal">
              {steps.map((item) => (
                <Step
                  key={item}
                  title={windowDimensions.width < 835 ? "" : item}
                />
              ))}
            </StepsStyled>
            <StepContentStyled>{resolveContent()}</StepContentStyled>
          </ColCenter>
        </Modal>
      ) : (
        <DrawerStyled
          visible={visible}
          onCancel={() => setVisible(false)}
          closeIcon={
            <img src={closeIconModal} onClick={() => setVisible(false)} />
          }
          placement="bottom"
          zIndex="9999999999"
          // style={{ height: '100vh' }}
        >
          <>
            <div className="center-title">
              <h2>Register as Volunteer</h2>
              <p>
                Contribute your skill as a volunteer by registering to our
                database
              </p>
            </div>

            <StepsStyled
              direction="horizontal"
              responsive={false}
              size="small"
              current={current}
            >
              {steps.map((item) => (
                <Step key={item} />
              ))}
            </StepsStyled>

            <div className="steps-content">{resolveContent()}</div>
            <MobileFooterStyled>
              <Button
                className="mr-4px"
                size="large"
                block
                disabled={current < 1 || current >= 4}
                onClick={() => prev()}
              >
                Previous
              </Button>
              {current < 3 && (
                <Button
                  className="ml-4px"
                  size="large"
                  type="primary"
                  block
                  disabled={current >= 3}
                  onClick={() => next()}
                >
                  {current >= 2 ? "Submit" : "Continue"}
                </Button>
              )}
            </MobileFooterStyled>
          </>
        </DrawerStyled>
      )}
    </>
  );
}
