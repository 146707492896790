import React from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import CardBalance from "../../CardBalance";
import { InactiveLabel } from "./styled";
import ShimmerCustom from "../../ShimmerCustom";
import useWindowDimensions from "../../../utils/windowDimensions";
import { ASSETS_STATUS } from "../../../constants/constant_wallet";
import { useTranslation } from "react-i18next";

export default function WalletOverview(props) {
  const windowDimensions = useWindowDimensions();
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );

  const { t } = useTranslation();

  const resolveShimmer = () => {
    let children = [];
    for (let i = 0; i < 5; i++) {
      children.push(
        <Col
          key={i}
          xs={24}
          sm={24}
          md={windowDimensions.width > 925 ? 12 : 24}
          lg={12}
          xl={8}
          xxl={6}
        >
          <ShimmerCustom width="100%" height="186px" borderRadius="4px" />
        </Col>
      );
    }

    return children;
  };

  return (
    <>
      <Row gutter={props.isLoading && [16, 16]}>
        {props.isLoading
          ? resolveShimmer()
          : allAssets
              .map(
                (e, i) =>
                  e.status &&
                  e.status !== ASSETS_STATUS.INACTIVE && (
                    <CardBalance data={e} key={i} type="overview" />
                  )
              )}
      </Row>
      {allAssets.filter((e) => !e.status || e.status === ASSETS_STATUS.INACTIVE)
        .length > 0 ? (
        <Row className="mt-20px mb-12px ml-16px">
          <Col span={24}>
            <InactiveLabel>{t("wallet-label-inactive")}</InactiveLabel>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row
        gutter={props.isLoading && [16, 16]}
        className={props.isLoading && "mt-32px"}
      >
        {props.isLoading ? (
          resolveShimmer()
        ) : allAssets.filter(
            (e) => !e.status || e.status === ASSETS_STATUS.INACTIVE
          ).length > 0 ? (
          allAssets
            .map(
              (e, i) =>
                (!e.status || e.status === ASSETS_STATUS.INACTIVE) && (
                  <CardBalance data={e} key={i} type="overview" />
                )
            )
        ) : (
          <></>
        )}
      </Row>
    </>
  );
}
