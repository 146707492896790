import { Col, Row, Modal, notification, Progress, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import noWalletIllustration from "../../assets/images/no-wallet-illustration.png";
import runningMan from "../../assets/images/running-man.png";
import closeIcon from "../../assets/images/close.svg";
import { LoadingOutlined } from "@ant-design/icons";
import {
  AlertRetrieve,
  ContentStyled,
  DescStyled,
  MnemonicBoxStyled,
  MnemonicFieldStyled,
  MnemonicInputStyled,
  TextStyled,
  TitleStyled,
} from "./styled";
import {
  ButtonStyled,
  CheckboxStyled,
  ColCenter,
  ColStart,
  RowCenter,
  RowSpaceBetween,
} from "../general_styled";
import { useEffect, useState } from "react";
import { closeImportMnemonicModal } from "../../modules/import_mnemonic_modal/action";
import algosdk from "algosdk";
import jwtDecode from "jwt-decode";
import { headers, HOST } from "../../configs";
import axios from "axios";
import copyIcon from "../../assets/images/copy.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import ReactCodeInput from "react-code-input";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { decryptMnemonic, encryptMnemonic } from "../../utils/utils";
import { useHistory } from "react-router";
import { getWalletMnemonic, storeMnemonic } from "../../modules/wallet/action";
import { CopyTextStyled, MnemonicSpan } from "../ModalOnboarding/styled";

export default function ModalImportMnemonic() {
  const history = useHistory();
  const isWalletPage = history.location.pathname === "/wallet-dashboard";
  const isModalVisible = useSelector((state) =>
    state.getIn(["importMnemonicModal", "isModalVisible"])
  );

  const title = useSelector((state) =>
    state.getIn(["importMnemonicModal", "title"])
  );

  const dispatch = useDispatch();
  const windowDimensions = useWindowDimensions();

  const [isInputMnemonic, setInputMnemonic] = useState(false);
  const [isInputPin, setInputPin] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  // const [saveRecover, setSaveRecover] = useState(true)
  const [isRetrieve, setIsRetrieve] = useState(false);
  const [isRetrieveLoading, setIsRetrieveLoading] = useState(false);
  const [isShowMnemonic, setIsShowMnemonic] = useState(false);
  const [mnemonic, setMnemonic] = useState([]);
  const [walletUser, setWalletUser] = useState(null);
  const [pinCode, setPinCode] = useState("");

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "16vw",
      height: windowDimensions.width > 835 ? "5rem" : "16vw",
      margin: "4px",
      fontSize: windowDimensions.width > 835 ? "40px" : "30px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "16vw",
      height: windowDimensions.width > 835 ? "5rem" : "16vw",
      margin: "4px",
      fontSize: windowDimensions.width > 835 ? "40px" : "30px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const handleCancel = () => dispatch(closeImportMnemonicModal());

  useEffect(() => {
    setInputMnemonic(true);
    setProcessing(false);
    setSuccess(false);
    setInputPin(false);
  }, [isModalVisible]);

  const onCreatePin = async () => {
    setProcessing(true);
    setSuccess(false);

    try {
      const encryptedMnemonic = encryptMnemonic(
        mnemonic.join(" ").trim(),
        pinCode
      );
      localStorage.setItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC,
        encryptedMnemonic.toString()
      );

      setInputPin(false);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Failed",
        description: "Something went wrong",
      });
    }
  };

  const validateMnemonic = async () => {
    try {
      if (mnemonic.length < 25) {
        throw "Recovery passphrase must be 25 words";
      }
      const key = algosdk.mnemonicToSecretKey(mnemonic.join(" ").trim());
      const walletUser = await (
        await axios.get(`${HOST}/users/${key.addr}`, {
          headers: headers,
        })
      ).data.data;

      const localAuth = localStorage.getItem("USER_AUTH_TOKEN");
      const decode = jwtDecode(localAuth);
      if (!walletUser || walletUser.user_id !== decode.user_id) {
        throw "Invalid mnemonic";
      }
      setWalletUser(walletUser);
      setInputMnemonic(false);
      setInputPin(true);
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Invalid Recovery Passphrase",
        description:
          e === "Recovery passphrase must be 25 words"
            ? e
            : "invalid / unregistered recovery passphrase",
      });
    }
  };

  const onRetriveFinish = async () => {
    setProcessing(true);
    setSuccess(false);
    try {
      const key = algosdk.mnemonicToSecretKey(mnemonic);
      const walletUser = await (
        await axios.get(`${HOST}/users/${key.addr}`, {
          headers: headers,
        })
      ).data.data;

      const localAuth = localStorage.getItem("USER_AUTH_TOKEN");
      const decode = jwtDecode(localAuth);

      if (!walletUser || walletUser.user_id !== decode.user_id) {
        throw "Invalid mnemonic";
      }
      setWalletUser(walletUser);
      setProcessing(false);
      setSuccess(true);
      handleCancel();

      if (!isWalletPage) {
        history.push("/");
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "User not found",
        description: e,
      });
    }
  };

  const onRetriveValidate = async () => {
    try {
      const getEncryptMnemonic = await getWalletMnemonic();
      const _decryptMnemonic = decryptMnemonic(getEncryptMnemonic, pinCode);

      localStorage.setItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC,
        getEncryptMnemonic.toString()
      );

      setMnemonic(_decryptMnemonic);
      setInputPin(false);
      setInputMnemonic(false);
      setIsShowMnemonic(true);
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Invalid Retrieve Passphrase",
        description: e || "Recovery passphrase not stored on database",
      });
    }
  };

  const onRetrieve = async () => {
    setIsRetrieveLoading(true);
    try {
      const getEncryptMnemonic = await getWalletMnemonic();
      if (getEncryptMnemonic) {
        setInputMnemonic(false);
        setInputPin(true);
        setIsRetrieve(true);
        setIsRetrieveLoading(false);
      } else {
        setIsRetrieveLoading(false);
        notification.error({
          message: "Invalid Retrieve Passphrase",
          description: "Recovery passphrase not stored on database",
        });
      }
    } catch (e) {
      setIsRetrieveLoading(false);
      notification.warn({
        message: "Invalid Retrieve Passphrase",
        description: "Recovery passphrase not stored on database",
      });
    }
  };

  const renderMnemonicField = () => {
    const fields = [];
    for (let i = 0; i < 25; i++) {
      fields.push(
        <Col xs={12} sm={8} md={6} lg={6} xl={6} xxl={6}>
          <MnemonicFieldStyled>
            <span>{i + 1}.</span>
            <MnemonicInputStyled
              key={i}
              bordered={false}
              value={mnemonic.length > i ? mnemonic[i] : ""}
              onChange={(e) => {
                const inputArr = e.target.value.split(" ");
                for (let j = 0; j < inputArr.length; j++) {
                  mnemonic[i + j] = inputArr[j];
                }
                setMnemonic([...mnemonic]);
              }}
            />
          </MnemonicFieldStyled>
        </Col>
      );
    }

    return fields;
  };

  const renderImportMnemonic = () => {
    return (
      <ColStart
        style={{
          alignItems: "center",
        }}
      >
        <TitleStyled>
          {title && title !== "" ? title : "Verify Browser"}
        </TitleStyled>
        <TextStyled>
          Enter your 25 word recovery passphrase to continue
        </TextStyled>
        <MnemonicBoxStyled gutter={[24, 24]}>
          {renderMnemonicField()}
        </MnemonicBoxStyled>
      </ColStart>
    );
  };

  const resolveMnemonicSpan = () => {
    const words = mnemonic.split(" ");

    return (
      <Row
        style={{ marginBottom: "16px", marginTop: "24px" }}
        gutter={[16, 16]}
      >
        {words.map((word, key) => (
          <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
            <MnemonicSpan>
              {key + 1}. {word}
            </MnemonicSpan>
          </Col>
        ))}
      </Row>
    );
  };

  const onCopyPhraseClicked = () => {
    navigator.clipboard.writeText(mnemonic);

    message.success("Copied to clipboard");
  };

  const renderSpanMnemonic = () => {
    return (
      <ColStart
        className="w-100 mb-20px"
        style={{
          alignItems: "center",
        }}
      >
        <TitleStyled>Recovery Words</TitleStyled>
        <TextStyled>
          Please save your recovery words securely to maintain access to your
          IBFNex - Netverse Account
        </TextStyled>
        {resolveMnemonicSpan()}
        <ColCenter
          className="w-100"
          style={{
            position: "relative",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Row
            style={{
              position: "absolute",
              right: "0",
              top: "-10px",
              cursor: "pointer",
              alignItems: "center",
            }}
            onClick={() => onCopyPhraseClicked()}
          >
            <img src={copyIcon} style={{ marginRight: "8px" }} />
            <CopyTextStyled>Copy Phrase</CopyTextStyled>
          </Row>
          {/* {isRetrieve && (
            <>
              <AlertRetrieve>
                It is important that you take a backup of your recovery words
                above. You will be asked to re-enter your recovery words when
                you login using a new browser. If you lose access to your
                recovery words, then you will lose access to your wallet as a
                wallet cannot be recovered without the recovery words
              </AlertRetrieve>
              <AlertRetrieve className="mt-12px yellow">
                <CheckboxStyled
                  checked={saveRecover}
                  onChange={() => setSaveRecover(!saveRecover)}
                >
                  Click here if you would like to store the recovery words in an
                  encrypted format in our secure server as a secondary backup to
                  recover your wallet in the future (Optional)
                </CheckboxStyled>
              </AlertRetrieve>
            </>
          )} */}
        </ColCenter>
      </ColStart>
    );
  };

  const renderInputPin = () => {
    return (
      <ColStart
        style={{
          alignItems: "center",
        }}
      >
        <TitleStyled>Create Pin</TitleStyled>
        <TextStyled>Create a new pin for your account</TextStyled>
        <MnemonicBoxStyled>
          <ColCenter
            style={{
              width: "100%",
            }}
          >
            <ReactCodeInput
              type={"number"}
              onChange={(e) => setPinCode(e)}
              fields={4}
              {...codeProps}
            />
            <DescStyled>
              Please set your 4-digit PIN here. Your PIN will be asked for
              completing secure transactions in IBFNex - Netverse. It can be
              reset at anytime using your recovery passphrase
            </DescStyled>
          </ColCenter>
        </MnemonicBoxStyled>
        <ButtonStyled
          style={{
            width: "300px",
          }}
          onClick={() => {
            if (isRetrieve) {
              onRetriveValidate();
            } else {
              onCreatePin();
            }
          }}
        >
          {isRetrieve ? "Continue" : "Create"}
        </ButtonStyled>
      </ColStart>
    );
  };

  const resolveContent = () => {
    if (isSuccess) {
      return (
        <ColCenter span={24}>
          <Progress type="circle" percent={100} />
          <TitleStyled
            style={{
              marginTop: "24px",
            }}
          >
            Success!
          </TitleStyled>
          <TextStyled
            style={{
              marginBottom: "24px",
            }}
          >
            {title && title !== ""
              ? "Reset PIN success"
              : "Your browser has been verified"}
          </TextStyled>
          <ButtonStyled
            style={{
              width: "100%",
              marginTop: "24px",
            }}
            onClick={() => {
              handleCancel();
              history.push("/");
            }}
          >
            Dismiss
          </ButtonStyled>
        </ColCenter>
      );
    }

    if (isInputMnemonic) {
      return renderImportMnemonic();
    }

    if (isInputPin) {
      return renderInputPin();
    }

    if (isShowMnemonic) {
      return renderSpanMnemonic();
    }

    return (
      <ColCenter span={24}>
        <img src={noWalletIllustration} width={287} />
        <TitleStyled style={{ marginTop: "20px" }}>
          New Browser Detected!
        </TitleStyled>
        <TextStyled style={{ marginBottom: "20px" }}>
          In order to continue using your IBF Wallet, you need to re-verify your
          recovery passphrase
        </TextStyled>
        <ButtonStyled
          style={{
            width: "100%",
          }}
          onClick={() => {
            setInputMnemonic(true);
          }}
        >
          Verify Passphrase
        </ButtonStyled>
      </ColCenter>
    );
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#024FF1" }} spin />
  );

  return (
    <Modal
      centered
      visible={isModalVisible}
      onCancel={handleCancel}
      closeIcon={
        !isSuccess ? (
          <img
            style={{ marginRight: 10 }}
            src={closeIcon}
            onClick={handleCancel}
          />
        ) : (
          <></>
        )
      }
      width={isInputMnemonic || isInputPin || isRetrieve ? 1096 : 416}
      footer={
        isRetrieve && isShowMnemonic
          ? [
              <RowCenter className="w-100">
                <Button
                  style={{
                    width: "250px",
                    height: "40px",
                  }}
                  onClick={() => {
                    onRetriveFinish();
                  }}
                >
                  {isWalletPage ? "Continue" : "Verify Browser"}
                </Button>
              </RowCenter>,
            ]
          : isInputMnemonic
          ? [
              <RowSpaceBetween className="w-100">
                <Button
                  ghost
                  type="primary"
                  style={{
                    width: "300px",
                    height: "40px",
                  }}
                  onClick={() => {
                    onRetrieve();
                  }}
                >
                  {isRetrieveLoading ? antIcon : "Retrieve Recovery Words"}
                </Button>
                <Button
                  type="primary"
                  style={{
                    width: "250px",
                    height: "40px",
                  }}
                  onClick={() => {
                    validateMnemonic();
                  }}
                >
                  Continue
                </Button>
              </RowSpaceBetween>,
            ]
          : []
      }
    >
      <ContentStyled>{resolveContent()}</ContentStyled>
    </Modal>
  );
}
