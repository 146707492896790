import styled from "@emotion/styled";
import { Typography } from "antd";

const { Text } = Typography;

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 80%;
  overflow-y: auto;

  .ant-upload.ant-upload-select {
    display: block;
  }
`;

export const TitleStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;
`;

export const TextStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* or 167% */

  text-align: center;

  /* Gray/gray-7 */

  color: #273248;
  max-width: 350px;
`;

export const DividerCard = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
  &.list {
    margin: 16px 0;
  }
`;

export const LabelStyled = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;

  /* Text/400 */

  color: #273248;
  margin-bottom: 8px;
  margin-top: 20px;
`;
