import React, { useState, useEffect } from "react";
import { Dropdown, Image, Menu, Row, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
  Logo,
  SiderStyled,
  ItemStyled,
  ButtonStyled,
  MenuIcon,
  HeaderStyled,
  Divider,
  ButtonProfileStyled,
  ImageProfileStyled,
  SaldoProfileStyled,
} from "./styled";
import logoIbfNex from "../assets/images/nex.png";
import logoIbfNexMin from "../assets/images/nex_min.png";
import heartIcon from "../assets/images/icon_heart.svg";
import walletIcon from "../assets/images/icon_wallet.svg";
import chatTextIcon from "../assets/images/icon_comment.svg";
import UpIcon from "../assets/images/icon_up.svg";
import DownIcon from "../assets/images/icon_down.svg";
import megaphoneIcon from "../assets/images/icon_book.svg";
import penIcon from "../assets/images/icon_read.svg";
import banknoteIcon from "../assets/images/icon_banknote.svg";
import shopIcon from "../assets/images/icon_shop.svg";
import userIcon from "../assets/images/icon_user.svg";
import homeIcon from "../assets/images/icon_home.svg";
import AlgoIcon from "../assets/images/home/algo.svg";
import PolyIcon from "../assets/images/home/polygon.svg";
import closeIcon from "../assets/images/close-sidebar.svg";
import menuHamIcon from "../assets/images/menu-ham.svg";
import timeleapseIcon from "../assets/images/icon_timeleapse.svg";
import {
  ColSpaceBetween,
  ColStart,
  RowSpaceBetween,
  RowStart,
} from "../components/general_styled";
import { useHistory, useLocation } from "react-router";
import useWindowDimensions from "../utils/windowDimensions";
import { logout } from "../modules/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { openModalLoginRegister, setSection } from "../modules/general/action";
import { openCart } from "../modules/course_cart/action";
import { openRedeemVoucherModal } from "../modules/redeem_voucher_modal/action";
import { IS_EVM, LOGOUT_URL } from "../configs";
import ConnectWallet from "../components/ConnectWallet";
import { useAuth0 } from "@auth0/auth0-react";

const { SubMenu } = Menu;
const { Text } = Typography;

const network = [
  {
    id: "algo",
    icon: AlgoIcon,
    name: "Algorand",
  },
  {
    id: "poly",
    icon: PolyIcon,
    name: "Polygon",
  },
];

export default function Sidebar(props) {
  const { t } = useTranslation();

  let history = useHistory();
  const dispatch = useDispatch();
  const generalSection = useSelector((state) =>
    state.getIn(["general", "section"])
  );
  const location = useLocation();
  const windowDimensions = useWindowDimensions();
  const [openKeys, setOpenKeys] = useState([]);
  const [isNetworkVisible, setNetworkVisible] = useState(false);
  const rootKeys = ["confluence", "benevolence", "affluence"];
  const auth0 = useAuth0();

  const onMenuClicked = () => {
    props.setCollapsedSidebar(!props.collapsedSidebar);
  };

  const onLogout = async () => {
    await auth0.logout({ logoutParams: { returnTo: `${LOGOUT_URL}/login` } });
    localStorage.removeItem("USER_AUTH_TOKEN");
    dispatch(logout());
    history.push("/login");
  };
  const onOpenChange = (items) => {
    const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(items);
    } else if (latestOpenKey === "affluence") {
      setOpenKeys(["affluence", "affluence-buy-sell"]);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onMovePage = (item) => {
    if (props.collapsedSidebar) {
      props.setCollapsedSidebar(false);
    }
    if (localStorage.getItem("USER_AUTH_TOKEN")) {
      if (
        location.pathname.includes("detail") ||
        location.pathname.includes("waqf")
      ) {
        window.history.replaceState(null, "IBFNex - Netverse", "/");
      }

      if (item.key === "cart") {
        dispatch(openCart());
        props.setCollapse(false);
        return;
      }

      if (item.key === "redeem-voucher") {
        dispatch(openRedeemVoucherModal());
        props.setCollapse(false);
        return;
      }

      if (
        openKeys.length > 0 &&
        !item.key.toLowerCase().includes(openKeys[0])
      ) {
        setOpenKeys([]);
      }

      if (item.key === "create-nft") {
        dispatch(setSection("credence"));
        props.setCollapse(false);
        return;
      }

      // if (item.key === "irshad") {
      //   props.setCollapse(false);
      //   return;
      // }

      dispatch(setSection(item.key));
      props.setCollapse(false);
    } else {
      if (
        item.key === "affluence-buy-sell-marketplace" ||
        item.key === "affluence-auction-marketplace" ||
        item.key === "courses" ||
        item.key === "home" ||
        item.key === "benevolence-donate"
      ) {
        dispatch(setSection(item.key));
      } else {
        dispatch(openModalLoginRegister());
      }
    }
  };

  const onNetworkChanged = (param) => {
    localStorage.setItem("IS_EVM", param.key === "poly");
    window.location.reload(false);
  };

  const resolveNetwork = (isDekstop) => {
    return (
      <div className={isDekstop ? "mx-12px" : "mx-20px"}>
        <Dropdown
          overlay={() => {
            return (
              <Menu
                defaultSelectedKeys={IS_EVM ? "poly" : "algo"}
                selectedKeys={[IS_EVM ? "poly" : "algo"]}
              >
                {network.map((net) => {
                  return (
                    <Menu.Item
                      key={net.id}
                      onClick={(val) => onNetworkChanged(val)}
                    >
                      <Row style={{ alignItems: "center" }}>
                        <Image
                          preview={false}
                          width={16}
                          src={net.icon}
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "16px",
                          }}
                        />
                        <Text style={{ marginLeft: "8px" }}>{net.name}</Text>
                      </Row>
                    </Menu.Item>
                  );
                })}
              </Menu>
            );
          }}
          onVisibleChange={() => {
            setNetworkVisible(!isNetworkVisible);
          }}
          placement="bottomLeft"
        >
          <ButtonProfileStyled
            style={{
              minWidth: "100px",
              background: "#EDEFF2",
              borderRadius: "6px",
            }}
          >
            <RowSpaceBetween style={{ width: "100%" }}>
              <RowStart>
                <ImageProfileStyled
                  src={
                    network.find((e) => e.id === (IS_EVM ? "poly" : "algo"))
                      ?.icon
                  }
                />
                <ColStart>
                  {/* <NameProfileStyled>Networks</NameProfileStyled> */}
                  {!props.collapsedSidebar && (
                    <SaldoProfileStyled style={{ fontSize: "13px" }}>
                      {
                        network.find((e) => e.id === (IS_EVM ? "poly" : "algo"))
                          ?.name
                      }
                    </SaldoProfileStyled>
                  )}
                </ColStart>
              </RowStart>
              <div className="ml-5px">
                {/* {isNetworkVisible ? <img /> : <DownOutlined />} */}
                <img
                  style={{ width: 13 }}
                  src={isNetworkVisible ? UpIcon : DownIcon}
                />
              </div>
            </RowSpaceBetween>
          </ButtonProfileStyled>
        </Dropdown>
      </div>
    );
  };

  useEffect(() => {
    if (
      location.pathname === "/affluence-buy-sell-marketplace" ||
      location.pathname === "/affluence"
    ) {
      setOpenKeys(["affluence", "affluence-buy-sell"]);
    }
  }, []);

  return windowDimensions.width > 835 ? ( // Dekstop View
    <div style={{ position: "absolute" }}>
      <SiderStyled
        className="m-15px"
        width={235}
        trigger={null}
        collapsible
        collapsed={props.collapsedSidebar}
      >
        <ColSpaceBetween className="h-100 stretch">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: props.collapsedSidebar ? "center" : "",
                alignItems: "center",
              }}
            >
              {!props.collapsedSidebar && (
                <MenuIcon
                  className="mr-12px ml-22px"
                  src={menuHamIcon}
                  onClick={onMenuClicked}
                  style={{ cursor: "pointer", width: "22px" }}
                />
              )}
              {props.collapsedSidebar ? (
                <Logo
                  style={{ height: 30, padding: 5, cursor: "pointer" }}
                  className="mt-14px mb-15px"
                  src={logoIbfNexMin}
                  onClick={onMenuClicked}
                />
              ) : (
                <Logo
                  style={{ height: 35, padding: 5 }}
                  className="mt-10px mb-10px ml-20px"
                  src={logoIbfNex}
                />
              )}
            </div>
            <Menu
              mode="inline"
              onOpenChange={onOpenChange}
              openKeys={openKeys}
              selectedKeys={[generalSection]}
              defaultSelectedKeys={[generalSection]}
              onClick={onMovePage}
            >
              <Menu.Item
                key="home"
                className="sidebar-button"
                icon={<img className="siderbar-icon" src={homeIcon} />}
              >
                {t("menu_home")}
              </Menu.Item>
              {/* <Menu.Item
							key="feed"
							className="sidebar-button"
							icon={<img className="siderbar-icon" src={timelineIcon} />}
						>
							Feed
						</Menu.Item> */}
              {!IS_EVM && (
                <Menu.Item
                  key="wallet-dashboard"
                  className="sidebar-button"
                  icon={<img className="siderbar-icon" src={walletIcon} />}
                >
                  {t("menu_wallet")}
                </Menu.Item>
              )}
              {!IS_EVM && (
                <Menu.Item
                  key="activity"
                  className="sidebar-button"
                  icon={<img className="siderbar-icon" src={timeleapseIcon} />}
                >
                  {t("menu_activity")}
                </Menu.Item>
              )}
              {/* <Menu.Item
              className="sidebar-button"
              key="wallet-faq"
              icon={<img className="siderbar-icon" src={questionIcon} />}
            >
              FAQ
            </Menu.Item> */}
              {!IS_EVM && <Divider />}
              <Menu.Item
                className="sidebar-button"
                key="credence"
                icon={<img className="siderbar-icon" src={shopIcon} />}
              >
                <RowSpaceBetween>
                  {t("menu_credence")}
                  {/* <Tag className='tag-custom new ml-4px' color='#FDEED5'>
									NEW
								</Tag> */}
                </RowSpaceBetween>
              </Menu.Item>
              {/* {!DEV ? (
              <Menu.Item
                className="sidebar-button"
                key="affluence"
                icon={<img className="siderbar-icon" src={shopIcon} />}
              >
                <RowSpaceBetween>
                  Affluence
                  <Tag className="tag-custom coming-soon" color="#DEF6EB">
                    COMING SOON
                  </Tag>
                </RowSpaceBetween>
              </Menu.Item>
            ) : ( */}
              <SubMenu
                key="affluence"
                title={
                  <RowSpaceBetween>
                    {t("menu_affluence")}
                    {/* <Tag className='tag-custom new ml-4px' color='#FDEED5'>
										NEW
									</Tag> */}
                  </RowSpaceBetween>
                }
                className="sidebar-button"
                icon={<img className="siderbar-icon" src={banknoteIcon} />}
                onTitleClick={() => {
                  props.setCollapse(false);
                  dispatch(setSection("affluence-buy-sell-marketplace"));
                }}
              >
                {/* <Menu.Item className='sidebar-button' key='create-nft'>
								Create NFT
							</Menu.Item> */}
                <SubMenu
                  key="affluence-buy-sell"
                  title={"Buy/Sell"}
                  className="sidebar-button"
                >
                  <Menu.Item key="affluence-buy-sell-marketplace">
                    {t("menu_marketplace")}
                  </Menu.Item>
                  <Menu.Item key="affluence-buy-sell-purchases">
                    {t("menu_purchased")}
                  </Menu.Item>
                  <Menu.Item key="affluence-buy-sell-listing">
                    {t("menu_my_listing")}
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  disabled={IS_EVM}
                  key="affluence-auction"
                  title={"Auction"}
                  className="sidebar-button"
                >
                  <Menu.Item key="affluence-auction-marketplace">
                    {t("menu_marketplace")}
                  </Menu.Item>
                  <Menu.Item key="affluence-auction-purchases">
                    {t("menu_purchased")}
                  </Menu.Item>
                  <Menu.Item key="affluence-auction-offers">
                    {t("menu_offer")}
                  </Menu.Item>
                  <Menu.Item key="affluence-auction-listing">
                    {t("menu_my_listing")}{" "}
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  disabled={IS_EVM}
                  key="affluence-microfinance"
                  title={"Loans/BNPL"}
                  className="sidebar-button"
                >
                  <Menu.Item key="affluence-microfinance-myloans">
                    {t("menu_borrowed")}
                  </Menu.Item>
                  <Menu.Item key="affluence-microfinance-loan-requests">
                    {t("menu_loan_request")}
                  </Menu.Item>
                </SubMenu>
              </SubMenu>
              {/* )} */}
              {/* <SubMenu
							key='confluence'
							title='Confluence'
							className='sidebar-button'
							icon={<img className='siderbar-icon' src={lineUpIcon} />}
						>
							<Menu.Item key='confluence-dashboard'>Impact Dashboard</Menu.Item>
							<Menu.Item key='confluence-claim'>Claim</Menu.Item>
							<Menu.Item key='confluence-offers'>Offers</Menu.Item>
							<Menu.Item key='confluence-profiles'>Manage Projects</Menu.Item>
						</SubMenu> */}
              <SubMenu
                key="benevolence"
                title={t("menu_benevolence")}
                className="sidebar-button"
                icon={<img className="siderbar-icon" src={heartIcon} />}
              >
                <Menu.Item key="benevolence-donate">
                  {t("menu_donate")}
                </Menu.Item>
                <Menu.Item key="benevolence-volunteer">
                  {t("menu_volunteer")}
                </Menu.Item>
                <Menu.Item key="benevolence-manage">
                  {t("menu_manage")}
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                className="sidebar-button"
                key="courses"
                icon={<img className="siderbar-icon" src={penIcon} />}
              >
                {t("menu_excellence")}
              </Menu.Item>
              {/* <Menu.Item
              className="sidebar-button"
              key="conference"
              icon={<img className="siderbar-icon" src={megaphoneIcon} />}
            >
              {t("menu_conference")}
            </Menu.Item> */}
              {/* <Menu.Item
              disabled={true}
              className="sidebar-button"
              key="irshad"
              icon={<img className="siderbar-icon" src={chatTextIcon} />}
            >
              {t("menu_irshad")}
            </Menu.Item> */}
            </Menu>
          </div>
          {/* <BottomSection collapsedSidebar={props.collapsedSidebar}>
          <SosmedStyled>
            <TextStyled>{t("menu_connect")}</TextStyled>
            <Row className="mt-6px">
              <a
                href="https://www.linkedin.com/company/ibf-net"
                target="_blank"
              >
                <img src={linkedinIcon} />
              </a>
              <a href="https://t.me/ibfnetwork" target="_blank">
                <img src={telegramIcon} />
              </a>
              <a href="https://twitter.com/ibfnet" target="_blank">
                <img src={twitterIcon} />
              </a>
              <a href="mailto:dev@ibfnet.my" target="_blank">
                <img src={emailIcon} />
              </a>
            </Row>
          </SosmedStyled>

          <ButtonStyled
            className="full-width"
            size="large"
            onClick={(e) => {
              e.preventDefault();
              window.open("https://ibfnet.group/");
            }}
          >
            {t("menu_back")}
          </ButtonStyled>
        </BottomSection> */}
        </ColSpaceBetween>
      </SiderStyled>
      {resolveNetwork(true)}
    </div>
  ) : (
    // Mobile View
    <>
      <HeaderStyled style={{ borderBottom: "1px solid #edeff2" }}>
        <RowSpaceBetween>
          <MenuIcon
            // className="pb-12px"
            src={props.collapsedSidebar ? closeIcon : menuHamIcon}
            onClick={onMenuClicked}
          />
          {/* add pading for logo 12px, borderbottom 1px solid black */}
          <Logo
            className="on-header"
            style={{ height: 40, padding: 5 }}
            src={logoIbfNex}
            onClick={() => onMovePage({ key: "feed" })}
          />
          <div style={{ width: 32 }} />
          {/* <MenuIcon src={bellIcon} /> */}
        </RowSpaceBetween>
      </HeaderStyled>
      <SiderStyled width={props.collapsedSidebar ? "100%" : 0}>
        <ColSpaceBetween className="absolute w-100 mh-100 stretch pt-110px pb-5px">
          <Menu
            mode="inline"
            onOpenChange={onOpenChange}
            openKeys={openKeys}
            defaultSelectedKeys={[location.pathname]}
            onClick={onMovePage}
          >
            <ItemStyled
              style={{ zIndex: 100000 }}
              key="home"
              icon={<img src={homeIcon} />}
            >
              {t("menu_home")}
            </ItemStyled>
            {/* <ItemStyled key="feed" icon={<img src={timelineIcon} />}>
							Feed
						</ItemStyled> */}
            {!IS_EVM && (
              <ItemStyled
                key="wallet-dashboard"
                icon={<img src={walletIcon} />}
              >
                {t("menu_wallet")}
              </ItemStyled>
            )}
            <ItemStyled
              disabled={IS_EVM}
              key="activity"
              icon={<img src={timeleapseIcon} />}
            >
              {t("menu_activity")}
            </ItemStyled>
            {/* <ItemStyled key="wallet-faq" icon={<img src={questionIcon} />}>
              FAQ
            </ItemStyled> */}
            {!IS_EVM && <Divider />}
            <ItemStyled key="credence" icon={<img src={shopIcon} />}>
              <RowSpaceBetween>
                {t("menu_credence")}
                <Tag className="tag-custom new ml-4px" color="#FDEED5">
                  NEW
                </Tag>
              </RowSpaceBetween>
            </ItemStyled>
            <SubMenu
              key="affluence"
              title={t("menu_affluence")}
              icon={<img src={banknoteIcon} style={{ width: 24 }} />}
            >
              <SubMenu key="affluence-buy-sell" title="Buy/Sell">
                <Menu.Item key="affluence-buy-sell-marketplace">
                  {t("menu_marketplace")}
                </Menu.Item>
                <Menu.Item key="affluence-buy-sell-purchases">
                  {t("menu_purchased")}
                </Menu.Item>
                <Menu.Item key="affluence-buy-sell-listing">
                  {t("menu_my_listing")}
                </Menu.Item>
              </SubMenu>
              <SubMenu key="affluence-auction" title="Auction">
                <Menu.Item key="affluence-auction-marketplace">
                  Marketplace
                </Menu.Item>
                <Menu.Item key="affluence-auction-purchases">
                  Purchased
                </Menu.Item>
                <Menu.Item key="affluence-auction-offers">Offers</Menu.Item>
                <Menu.Item key="affluence-auction-listing">
                  My Listing
                </Menu.Item>
              </SubMenu>
              <SubMenu key="microfinance" title="Loans/BNPL">
                <Menu.Item key="microfinance-myloans">My Loans</Menu.Item>
                <Menu.Item key="microfinance-loan-requests">
                  Loan Requests
                </Menu.Item>
              </SubMenu>
            </SubMenu>
            <SubMenu
              key="benevolence"
              disabled={IS_EVM}
              icon={<img src={heartIcon} />}
              title={t("menu_benevolence")}
            >
              <Menu.Item key="benevolence-donate">Donate</Menu.Item>
              <Menu.Item key="benevolence-volunteer">Volunteers</Menu.Item>
              <Menu.Item key="benevolence-manage">Manage</Menu.Item>
            </SubMenu>
            <ItemStyled key="courses" icon={<img src={penIcon} />}>
              {t("menu_excellence")}
            </ItemStyled>
            {/* <ItemStyled key="conference" icon={<img src={megaphoneIcon} />}>
              {t("menu_conference")}
            </ItemStyled> */}
            {/* <ItemStyled
              className="disabled"
              disabled={IS_EVM}
              key="irshad"
              icon={<img src={chatTextIcon} />}
            >
              Irshad
            </ItemStyled> */}
            <ItemStyled key="profile" icon={<img src={userIcon} />}>
              Profile
            </ItemStyled>
          </Menu>
          <ColStart>
            {IS_EVM && (
              <div className="pl-24px pr-24px mt-12px mb-0px">
                <ConnectWallet />
              </div>
            )}
            {resolveNetwork(false)}
            <ButtonStyled className="mb-12px" size="large" onClick={onLogout}>
              Log Out
            </ButtonStyled>
            <ButtonStyled
              size="large"
              onClick={(e) => {
                e.preventDefault();
                window.open("https://ibfnet.group/");
              }}
            >
              Back to IBF Net
            </ButtonStyled>
          </ColStart>
        </ColSpaceBetween>
      </SiderStyled>
    </>
  );
}
