import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Typography, notification, Button, Image } from "antd"

import {
  Canvas,
  CustomRow,
  ImageLogin,
  Content,
  FormCustom,
  TextStyled,
  BlueLink,
} from "./styled"
import mosqueImage from "../../assets/images/bg_landing.jpg"
import { forgotPassword } from "../../modules/auth/action"
import { LoadingOutlined } from "@ant-design/icons"
import { useHistory } from "react-router"
import { Redirect } from "react-router-dom"

import logoColor from "../../assets/images/logo-beta-black.png"
import { InputStyled } from "../../components/general_styled"
import { extractErrorMessage } from "../../utils/extractErrorMessage"

const { Title, Text } = Typography

export default function ForgotPassword() {
  const stateAuth = useSelector((state) => state.getIn(["auth"]).toJS())
  const dispatch = useDispatch()
  const history = useHistory()

  const [email, setEmail] = useState("")

  const [isLoading, setLoading] = useState(false)

  const onSubmitClicked = async () => {
    try {
      setLoading(true)

      const result = await dispatch(
        forgotPassword({
          email: email,
        })
      )

      if (result && result.code === "SUCCESS") {
        history.push("/verification/forgot-password")
      } else {
        notification.error({
          message: "Failed!",
          description: result,
        })
      }
    } catch (e) {
      console.log(e)
      notification.error({
        message: "Failed!",
        description: extractErrorMessage(e),
      })
    }

    setLoading(false)
  }

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  )

  const resolveUI = () => {
    if (stateAuth.isAuthorized) {
      return <Redirect to="/" />
    }

    return (
      <Canvas>
        <CustomRow>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <ImageLogin img={mosqueImage} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Content>
              <Image
                src={logoColor}
                width={204}
                style={{ marginBottom: "36px" }}
                preview={false}
              />
              <Title level={2} style={{ marginBottom: 0 }}>
                Forgot Password
              </Title>
              <Text>We will send you an OTP to reset your password</Text>
              <FormCustom>
                {/* <Input.Group compact>
                    <SelectStyled
                      showSearch
                      allowClear
                      mode="select"
                      style={{ width: "25%" }}
                      onChange={(e) => SelectedCountryCode(e)}
                      defaultValue="+62"
                    >
                      {listCountry.map((e, i) => (
                        <OptionStyled key={i} value={e.dial_code}>
                          {e.dial_code} ({e.name})
                        </OptionStyled>
                      ))}
                    </SelectStyled>
                    <InputStyled
                      style={{ width: "75%" }}
                      placeholder="Phone Number"
                      onChange={(v) => setPhoneNumber(v)}
                    />
                  </Input.Group> */}
                <InputStyled
                  name="email"
                  placeholder="Email"
                  size="large"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  type="primary"
                  block
                  disabled={!(email !== "")}
                  style={{ marginTop: 10, marginBottom: 36 }}
                  size="large"
                  onClick={onSubmitClicked}
                >
                  {isLoading ? loadingIcon : "Send OTP"}
                </Button>
                <TextStyled>Don’t want to reset your password? </TextStyled>
                <BlueLink to="/login">Go Back to Login</BlueLink>
              </FormCustom>
            </Content>
          </Col>
        </CustomRow>
      </Canvas>
    )
  }

  return resolveUI()
}
