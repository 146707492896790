import { ColCenter } from "../../components/general_styled"
import underMaintenanceIcon from "../../assets/images/under-maintenance.svg"
import { Image, Typography } from "antd"

const { Title, Text } = Typography
export default function UnderMaintenance() {
  return (
    <ColCenter style={{ height: "100%", minHeight: "calc(100vh - 120px)" }}>
      <Image
        src={underMaintenanceIcon}
        preview={false}
        width={393}
        style={{ marginBottom: "40px" }}
      />
      <Title level={3} style={{ fontWeight: "bold", margin: 0 }}>
        Under Maintenance
      </Title>
      <Text align="center" style={{ margin: 0 }}>
        The module you are looking for is currently under maintenance
      </Text>
    </ColCenter>
  )
}
