import styled from "@emotion/styled";
import { Card, Typography } from "antd";

const { Title, Text } = Typography;

export const CardStyled = styled(Card)`
  border-radius: 10px;

  .ant-card-cover {
    height: 180px;
    object-fit: cover;
  }

  .ant-image {
    height: 100%;
  }

  .ant-card-cover img {
    border-radius: 10px 10px 0 0;
    height: 100%;
    object-fit: cover;
  }

  .ant-card-body {
    border-radius: 10px;
    height: 200px;
    padding: 20px;
    overflow: hidden;
  }

  .ant-card-actions {
    border-top: none;
    padding: 20px;
    border-radius: 10px;

    li {
      margin: 0 0 !important;
    }
  }
`;

export const GreenText = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;

  letter-spacing: 1px;

  color: #38cb89;
`;

export const CardTitle = styled(Title)`
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;

  color: #273248 !important;

  margin: 0 !important;
  margin-top: 16px !important;
  margin-bottom: 8px !important;
`;

export const OverviewText = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #44567b;
`;
