import React from "react"
import Shimmer from "react-shimmer-effect"
import styled from "@emotion/styled"

const ShimmerStyled = styled.div`
  display: flex;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => props.margin};
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};
`

export default function ShimmerCustom(props) {
  return (
    <Shimmer>
      <ShimmerStyled
        height={props.height}
        width={props.width}
        className={props.className}
        borderRadius={props.borderRadius}
        marginRight={props.marginRight}
        marginBottom={props.marginBottom}
        margin={props.margin}
        marginTop={props.marginTop}
        marginLeft={props.marginLeft}
        style={{ backgroundSize: "cover" }}
      />
    </Shimmer>
  )
}
