import {
  Button,
  Checkbox,
  Col,
  Image,
  Modal,
  Progress,
  Row,
  Alert,
  Typography,
} from "antd";
import IBFx1Logo from "../../assets/images/ibfx-1.svg";
import IBFx2Logo from "../../assets/images/ibfx-2.svg";
import closeIcon from "../../assets/images/close.svg";
import noWalletIllustration from "../../assets/images/no-wallet-illustration.png";
import {
  ColCenter,
  ColSpaceBetween,
  InputNumberStyled,
  RowCenter,
  RowSpaceBetween,
  ButtonStyled,
  RowEnd,
} from "../general_styled";
import {
  BalanceLabel,
  BalanceValue,
  BoxBalanceStyled,
  ContentStyled,
  ModalSubTitle,
  ModalTitle,
  Divider,
  LabelMedium,
  LabelNormal,
  PaymentCard,
  PaymentList,
  PaymentSummary,
  Divider as DividerCard,
} from "../CourseCart/styled";
import { ASSETS_NAME } from "../../constants/constant_wallet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decryptMnemonic, fromIBFx, toIBFx } from "../../utils/utils";
import { useHistory } from "react-router";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import ReactCodeInput from "react-code-input";
import { closeCart } from "../../modules/course_cart/action";
import IBFxIcon from "../../assets/images/ibfx-icon.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import { postBuyNFT } from "../../modules/essence/action";
import { openModalLoginRegister } from "../../modules/general/action";
import CredenceOnBoarding from "../../pages/CredenceOnBoarding";

const { Title, Text } = Typography;

export default function ModalBuyNFT(props) {
  const windowDimensions = useWindowDimensions();
  const [assetType, setAssetType] = useState([ASSETS_NAME.IBFX_TYPE_I]);
  const [processingPercent, setProcessingPercent] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isMoreLimit, setMoreLimit] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [inputPin, setInputPin] = useState(false);
  const [ibfx1Amount, setIbfx1Amount] = useState(0);
  const [ibfx2Amount, setIbfx2Amount] = useState(0);
  const [pinCode, setPinCode] = useState("");

  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );
  const credenceUser = useSelector((state) =>
    state.getIn(["credence", "user"]).toJS()
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  useEffect(() => {
    setAssetType([ASSETS_NAME.IBFX_TYPE_I]);
    setProcessingPercent(0);
    setProcessing(false);
    setSuccess(false);
    setFailed(false);
    setInputPin(false);
    setPinCode("");
  }, [isModalVisible]);

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);

      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const showModal = () => {
    if (!localStorage.getItem("USER_AUTH_TOKEN")) {
      return dispatch(openModalLoginRegister());
    }

    setIsModalVisible(true);
    setIbfx1Amount(0);
    setIbfx2Amount(0);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setIbfx1Amount(0);
    setIbfx2Amount(0);
  };

  const onCheck = (value) => {
    if (assetType.includes(value)) {
      if (value === ASSETS_NAME.IBFX_TYPE_I) {
        setIbfx1Amount(0);
      } else if (value === ASSETS_NAME.IBFX_TYPE_II) {
        setIbfx2Amount(0);
      }

      setAssetType((prevValue) => {
        return prevValue.filter((e) => e !== value);
      });
    } else {
      setAssetType((prevValue) => {
        return [...prevValue, value];
      });
    }
  };

  const onSubmit = async () => {
    if (ibfx2Amount > resolveIBFX2Limit()) {
      return setMoreLimit(true);
    }

    setFailed(false);
    setProcessing(true);
    setProcessingPercent(0);

    try {
      // const order = await createOrder({
      //   user_id: walletUser.user_id,
      //   order_items: cart.map((c) => {
      //     return {
      //       course_id: c.course_id,
      //       quantity: 1,
      //       price: toIBFx(c.price),
      //     }
      //   }),
      // })
      setProcessingPercent(30);
      const encryptedMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const mnemonic = decryptMnemonic(encryptedMnemonic, pinCode);
      await postBuyNFT(mnemonic, {
        sell_escrow_id: props.sell_escrow_id,
        total_ibfx: toIBFx(props.totalPayment),
        total_nft: 1,
      });
      setProcessingPercent(100);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      setFailed(true);
    }
    setProcessing(false);
  };

  const resolveBalance = (assetName) => {
    const asset = allAssets.find((asset) => asset.asset_name === assetName);
    return asset ? asset.amount : 0;
  };

  const resolveIBFX2Limit = () => {
    return (props.totalPayment / 100) * 20;
  };

  const resolveAssetId = (assetName) => {
    const asset = allAssets.find((asset) => asset.asset_name === assetName);
    return asset.asset_id;
  };

  const resolveSelectedAssetAmount = (assetName) => {
    if (assetName === ASSETS_NAME.IBFX_TYPE_I) {
      return ibfx1Amount;
    } else if (assetName === ASSETS_NAME.IBFX_TYPE_II) {
      return ibfx2Amount;
    }
  };

  const resolveContent = () => {
    if (!walletUser || Object.keys(walletUser) < 0) {
      return (
        <ContentStyled>
          <ColCenter>
            <Image preview={false} src={noWalletIllustration} />
            <Title className="text-w700 gray-7 mb-0px mt-24px" level={3}>
              Wallet Not Linked
            </Title>
            <Text>
              In order to pay with IBFX tokens, you will need to create your
              wallet account.
            </Text>
            <Button
              type="primary"
              style={{
                width: "294px",
                marginTop: "24px",
              }}
              onClick={() => {
                handleCancel();
                dispatch(closeCart());
                history.push("/wallet-dashboard");
              }}
            >
              Create
            </Button>
          </ColCenter>
        </ContentStyled>
      );
    }

    const paymentBody = () => {
      return (
        <>
          <div className="px-10px">
            <PaymentList>
              <LabelNormal>Total Items</LabelNormal>
              <LabelMedium>1</LabelMedium>
            </PaymentList>
            <PaymentList>
              <LabelNormal>Total</LabelNormal>
              <RowEnd className="center">
                <Image preview={false} src={IBFxIcon} width={"18px"} />
                <LabelMedium
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  {props.totalPayment}
                </LabelMedium>
              </RowEnd>
            </PaymentList>
            <DividerCard />
            <PaymentList>
              <LabelNormal>IBFX</LabelNormal>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                <Image preview={false} src={IBFxIcon} width={"18px"} />
                <LabelMedium
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  {ibfx1Amount}
                </LabelMedium>
              </Row>
            </PaymentList>
            <DividerCard />
            {/* <PaymentList>
              <LabelNormal>IBFX - 2</LabelNormal>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                <Image preview={false} src={IBFx2Logo} width={"18px"} />
                <LabelMedium
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  {ibfx2Amount}
                </LabelMedium>
              </Row>
            </PaymentList>
            <DividerCard /> */}
            {/* <PaymentList>
              <LabelNormal>Fee</LabelNormal>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                <Image preview={false} src={IBFxIcon} width={"18px"} />
                <LabelMedium
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  2
                </LabelMedium>
              </Row>
            </PaymentList> */}
          </div>
          <PaymentSummary className="buy-nft">
            <LabelMedium className="buy-nft">Total Due</LabelMedium>
            <RowEnd className="center">
              <Image preview={false} src={IBFxIcon} width={"18px"} />

              <LabelMedium
                style={
                  props.totalPayment < 0
                    ? {
                        marginLeft: "4px",
                        color: "red",
                      }
                    : props.totalPayment === 0
                    ? {
                        marginLeft: "4px",
                        color: "green",
                      }
                    : {
                        marginLeft: "4px",
                      }
                }
              >
                {props.totalPayment}
              </LabelMedium>
            </RowEnd>
          </PaymentSummary>
        </>
      );
    };

    if (
      fromIBFx(
        resolveBalance(ASSETS_NAME.IBFX_TYPE_I) +
          resolveBalance(ASSETS_NAME.IBFX_TYPE_II)
      ) < props.totalPayment
    ) {
      return (
        <ContentStyled>
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px mt-0px" level={3}>
              Insufficient Funds
            </Title>
            <Text>You do not have the required funds in your Wallet</Text>
            <BoxBalanceStyled>
              <span className="label">Your IBFX Balance</span>
              <span className="value">
                {fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I))}
              </span>
            </BoxBalanceStyled>
            <BoxBalanceStyled className="mt-8px">
              <span className="label">Your IBFX - 2 Balance</span>
              <span className="value">
                {fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_II))}
              </span>
            </BoxBalanceStyled>
            <Col
              style={{
                width: "100%",
                marginTop: "20px",
                background: "#FCFCFC",
                border: "1px solid #EDEFF2",
                boxShadow: "0px 0px 25px 7px rgba(204, 213, 231, 0.14)",
              }}
            >
              {paymentBody()}
            </Col>
            <Button
              type="primary"
              style={{
                width: "100%",
                marginTop: "24px",
              }}
              onClick={() => {
                handleCancel();
                dispatch(closeCart());
                history.push("/wallet-dashboard?defaultActiveKey=topup");
              }}
            >
              Add Funds
            </Button>
          </ColCenter>
        </ContentStyled>
      );
    }

    if (inputPin) {
      return (
        <ContentStyled>
          <ColSpaceBetween className="stretch" style={{ minHeight: 500 }}>
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Pin
              </Title>
              <Text className="gray-7">
                Please enter your 4 digit wallet pin
              </Text>
            </ColCenter>
            <RowCenter>
              <ReactCodeInput
                type="password"
                onChange={(e) => setPinCode(e)}
                fields={4}
                {...codeProps}
              />
            </RowCenter>
            <Row className="d-flex">
              <Col span={24}>
                <Row className="d-flex">
                  <Col span={24}>
                    <Button
                      block
                      type="primary"
                      size="large"
                      onClick={() => {
                        const _checkPin = checkPin();
                        setInputPin(false);
                        if (_checkPin) {
                          onSubmit(2);
                        }
                      }}
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ColSpaceBetween>
        </ContentStyled>
      );
    }

    if (isProcessing || isSuccess || isFailed) {
      return (
        <ContentStyled>
          <ColCenter>
            <Progress
              type="circle"
              percent={processingPercent}
              status={isFailed && "exception"}
            />
            <Title className="text-w700 gray-7 mb-0px mt-24px" level={3}>
              {isProcessing && !isSuccess && !isFailed
                ? "Processing"
                : isSuccess && !isFailed
                ? "Success"
                : "Failed"}
            </Title>
            <Text className="gray-7" align="center">
              {isProcessing && !isSuccess && !isFailed
                ? "Please wait while we process your transaction"
                : isSuccess && !isFailed
                ? "NFT has been purchased successfully, please wait for the blockchain to mint the NFT"
                : "Payment Failed"}
            </Text>
            <Button
              type="primary"
              style={{
                width: "294px",
                marginTop: "24px",
              }}
              disabled={isProcessing}
              onClick={handleCancel}
            >
              Dismiss
            </Button>
          </ColCenter>
        </ContentStyled>
      );
    }

    return (
      <ContentStyled>
        <ColCenter>
          <ModalTitle>
            Purchase
            {' "' +
              props.name +
              (props.collection !== null ? " - " + props.collection : "")}
            "
          </ModalTitle>
          <ModalSubTitle>
            Select the IBFX token you would like to pay with
          </ModalSubTitle>
        </ColCenter>
        <Row className="mt-24px" gutter={[24, 16]}>
          <Col span={12}>
            <ColCenter>
              <div
                style={
                  assetType.includes(ASSETS_NAME.IBFX_TYPE_I)
                    ? {
                        width: "100%",
                        background: "#FCFCFC",
                        border: "1px solid #024FF1",
                        padding: "12px",
                      }
                    : {
                        background: "#FCFCFC",
                        border: "1px solid #D6DAE1",
                        boxSizing: "border-box",
                        borderRadius: "2px",
                        width: "100%",
                        padding: "12px",
                      }
                }
              >
                <RowSpaceBetween wrap={false}>
                  <Row wrap={false}>
                    <Image preview={false} src={IBFx1Logo} width={40} />
                    <ColSpaceBetween
                      style={{
                        marginLeft: "8px",
                        alignItems: "flex-start",
                      }}
                    >
                      <BalanceLabel>IBFX</BalanceLabel>
                      <BalanceValue>
                        Balance •{" "}
                        {fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I))}
                      </BalanceValue>
                    </ColSpaceBetween>
                  </Row>
                  <Checkbox
                    checked={assetType.includes(ASSETS_NAME.IBFX_TYPE_I)}
                    onClick={() => onCheck(ASSETS_NAME.IBFX_TYPE_I)}
                  />
                </RowSpaceBetween>
                {assetType.includes(ASSETS_NAME.IBFX_TYPE_I) ? (
                  <>
                    <InputNumberStyled
                      type="number"
                      className="mt-12px"
                      defaultValue={0}
                      style={{ width: "100%" }}
                      value={ibfx1Amount}
                      onChange={(v) => {
                        if (v == null) {
                          setIbfx1Amount(0);
                        } else {
                          setIbfx1Amount(v);
                        }
                      }}
                      min={0}
                      // max={fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I))}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* <div
                style={
                  assetType.includes(ASSETS_NAME.IBFX_TYPE_II)
                    ? {
                        width: "100%",
                        background: "#FCFCFC",
                        border: "1px solid #024FF1",
                        padding: "12px",
                        marginTop: "16px",
                      }
                    : {
                        background: "#FCFCFC",
                        border: "1px solid #D6DAE1",
                        boxSizing: "border-box",
                        borderRadius: "2px",
                        width: "100%",
                        padding: "12px",
                        marginTop: "16px",
                      }
                }
              >
                <RowSpaceBetween wrap={false}>
                  <Row wrap={false}>
                    <Image preview={false} src={IBFx2Logo} width={40} />
                    <ColSpaceBetween
                      style={{
                        marginLeft: "8px",
                        alignItems: "flex-start",
                      }}
                    >
                      <BalanceLabel>IBFN - 2</BalanceLabel>
                      <BalanceValue>
                        Balance •{" "}
                        {fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_II))} |{" "}
                        Max Limit •{" "}
                        {resolveIBFX2Limit()}
                      </BalanceValue>
                    </ColSpaceBetween>
                  </Row>
                  <Checkbox
                    checked={assetType.includes(ASSETS_NAME.IBFX_TYPE_II)}
                    onClick={() => onCheck(ASSETS_NAME.IBFX_TYPE_II)}
                    style={{
                      background: "#F4F6F7",
                    }}
                  />
                </RowSpaceBetween>
                {assetType.includes(ASSETS_NAME.IBFX_TYPE_II) ? (
                  <>
                    <Divider />
                    <InputNumberStyled
											type="number"
                      defaultValue={0}
                      style={{ width: "100%" }}
                      value={ibfx2Amount}
                      onChange={(v) => {
                        if (v == null) {
                          setIbfx2Amount(0)
                        } else {
                          setIbfx2Amount(v)
                        }
                      }}
                      min={0}
                      max={resolveIBFX2Limit()}
                    />
                    {isMoreLimit && (
                      <Alert
                        message="Amount cannot be more than the max limit"
                        type="error"
                        showIcon
                        closable
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div> */}
            </ColCenter>
          </Col>
          <Col
            span={12}
            style={{
              background: "#FCFCFC",
              border: "1px solid #EDEFF2",
              boxShadow: "0px 0px 25px 7px rgba(204, 213, 231, 0.14)",
              borderRadius: 4,
            }}
          >
            <ColSpaceBetween className="stretch py-20px px-10px">
              {paymentBody()}
              <Button
                type="primary"
                style={{
                  width: "100%",
                  marginTop: "12px",
                  height: 40,
                  borderRadius: 4,
                }}
                onClick={() => {
                  setInputPin(true);
                  // onSubmit()
                }}
              >
                Confirm & Pay
              </Button>
            </ColSpaceBetween>
          </Col>
        </Row>
      </ContentStyled>
    );
  };

  return (
    <>
      <ButtonStyled
        block
        style={{ height: 40, borderRadius: 4 }}
        className="text-size-14 text-w400"
        onClick={showModal}
      >
        Buy Now
      </ButtonStyled>
      {Object.keys(credenceUser).length <= 0 ||
      !localStorage.getItem("ENCRYPTED_MNEMONIC") ? (
        <Modal
          centered
          className="modal-top-up"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          closeIcon={<img src={closeIcon} onClick={handleCancel} />}
          maskStyle={{ backgroundColor: "#f3f6fa" }}
        >
          <CredenceOnBoarding />
        </Modal>
      ) : (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={
            //   fromIBFx(
            //     resolveBalance(ASSETS_NAME.IBFX_TYPE_I) +
            //       resolveBalance(ASSETS_NAME.IBFX_TYPE_II)
            //   ) < props.totalPayment
            //     ? null
            //     :
            isProcessing || isSuccess || isFailed ? "500px" : "800px"
          }
          style={{
            zIndex: "99",
          }}
          closeIcon={<img style={{ marginRight: 10 }} src={closeIcon} />}
          closable={!(isProcessing || isFailed || isSuccess)}
        >
          {resolveContent()}
        </Modal>
      )}
    </>
  );
}
