import styled from "@emotion/styled";
import { Input } from "antd";

export const PinInputStyled = styled(Input)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  background: #f4f6f7;
  /* Primary/500 */

  border: 1px solid #024FF1;
  box-sizing: border-box;
  border-radius: 2px;

  width: 72px;
  height: 72px;

  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;

  text-align: center;

  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }
`;
