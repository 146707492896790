import { notification } from 'antd'
import axios from 'axios'
// import jwtDecode from 'jwt-decode'
import { MEMBERSHIP_HOST, membershipHeaders } from '../../configs'
// import { COMMON_CONSTANT } from '../../constants/constant_common'
import { GET_COURSES, GET_COURSES_FAIL, GET_COURSES_SUCCESS, SELECT_COURSE, SET_ENROLLED } from '../constants'

export const getCourses =
	(pagination = 1) =>
	async (dispatch) => {
		dispatch({
			type: GET_COURSES,
		})

		try {
			const response = await axios.get(`${MEMBERSHIP_HOST}/courses-by-page`, {
				headers: membershipHeaders,
				params: {
					page: pagination,
					size: 10,
				},
			})
			const { data } = response.data

			dispatch({
				type: GET_COURSES_SUCCESS,
				payload: {
					totalItems: data.total_items,
					totalPage: data.total_page,
					hasNext: pagination < data.total_page,
					data: data.items.sort((a, b) => a.priority - b.priority),
					page: pagination,
				},
			})
		} catch (e) {
			console.log(e)
			notification.error({
				message: 'Failed!',
				description: e.response ? e.response.message : 'Unknown Error',
			})
			dispatch({
				type: GET_COURSES_FAIL,
				payload: {
					errorMessage: e,
				},
			})
		}
	}

export const getEnrolledCourses = () => async (dispatch) => {
	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')
		// const decoded = jwtDecode(jwt)

		const response = await axios.get(`${MEMBERSHIP_HOST}/bought-courses/`, {
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		})
		const { data } = response.data

		const filteredData = data.filter((i) => i !== null)

		dispatch({
			type: SET_ENROLLED,
			payload: filteredData.sort((a, b) => Date.parse(a.commences) - Date.parse(b.commences)),
		})
	} catch (e) {
		console.log(e)
		// notification.error({
		// 	message: 'Failed!',
		// 	description: e.response ? e.response.message : 'Unknown Error',
		// })
	}
}

export const getCourseDetail = async (courseId) => {
	try {
		const response = await axios.get(`${MEMBERSHIP_HOST}/courses/${courseId}`, {
			headers: membershipHeaders,
		})
		const { data } = response.data
		return data
	} catch (e) {
		console.log(e)
		// notification.error({
		// 	message: 'Failed!',
		// 	description: e.response ? e.response.message : 'Unknown Error',
		// })
	}
}

export const selectCourse = (course) => (dispatch) => {
	console.log({ courseAction: course })
	dispatch({
		type: SELECT_COURSE,
		payload: course,
	})
}
