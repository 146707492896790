import styled from "@emotion/styled";
import { Steps } from "antd";

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  // max-width: 1095px;
  // min-height: 610px;

  // @media screen and (max-width: 500px) {
  //   padding-top: 36px;
  // }

  .absolute-bottom {
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background: #ffffff;
    /* Sticky Shadow Up */

    box-shadow: 0px -4px 6px rgba(197, 205, 234, 0.25);
    border-radius: 0px;
    padding: 20px;
  }
`;

export const StepsStyled = styled(Steps)`
  background: #fcfcfc;
  border: 1px solid #d6dae1;
  box-sizing: border-box;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  padding: 8px 5%;
  // font-family: "Roboto";

  .ant-steps-icon {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
  }

  .ant-steps-content {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
  }
`;

export const StepContentStyled = styled.div`
  min-height: 500px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 24px;
  margin: 1rem 2.5%;

  background: #fcfcfc;
  box-sizing: border-box;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  overflow: auto;

  .detail {
    padding: 10px 20%;
  }

  @media screen and (min-width: 835px) {
    height: 70vh;
    width: 95%;
  }

  @media screen and (max-width: 834px) {
    width: 100%;
    height: 66vh;
    margin: 1rem 0;
    padding-bottom: 20px;
    .detail {
      padding: 0;
    }
  }

  &.height-auto {
    min-height: auto;
  }

  &.center {
    justify-content: center;
  }
`;

export const AddButtonStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px dashed #024FF1;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;

  padding: 10px 0;
  margin: 12px 0;

  .fa-plus {
    margin-right: 8px;
  }

  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  /* Field Text */

  color: #024FF1;
`;

export const CanvasNftDetails = styled.div`
  width: 100%;
  padding: 16px;
  margin-top: 20px;

  background: #f7f8fa;
  /* Neutral/300 */

  border: 1px solid #f3f4f6;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const TotalDue = styled.div`
  width: 100%;
  padding: 16px;
  margin-top: 20px;

  background: #f7f8fa;
  /* Secondary/500 */

  border: 1px solid #3b4552;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const DividerCard = styled.div`
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
`;
