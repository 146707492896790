import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Row,
  Col,
  InputNumber,
  Checkbox,
  Alert,
  Drawer,
  Input,
  Progress,
} from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ReactCodeInput from "react-code-input";
import closeIcon from "../../assets/images/close.svg";
import peraIcon from "../../assets/images/pera.png";
import chevronLeftIcon from "../../assets/images/chevron-left.svg";
import chevronUpIcon from "../../assets/images/chevron-up.svg";
import chevronDownIcon from "../../assets/images/chevron-down.svg";
import masterCardIcon from "../../assets/images/master-card.svg";
import masterCardColorIcon from "../../assets/images/master-card-color.svg";
import creditCardIcon from "../../assets/images/credit-card.svg";
import bankActiveIcon from "../../assets/images/bank-active.svg";
import bankIcon from "../../assets/images/bank.svg";
import poweredStripeIcon from "../../assets/images/powered-stripe.svg";
import {
  BackButtonStyled,
  BalanceCardStyled,
  ContentStyled,
  BoxAmountStyled,
  DividerStyled,
  LabelPayment,
  BoxPaymentStyled,
  BoxReceivers,
  BoxReceivedAmount,
  WarningAmountStyled,
  InputNumberStyled,
} from "./styled";
import {
  ButtonStyled,
  ColCenter,
  ColSpaceBetween,
  ColStart,
  RowCenter,
  RowSpaceBetween,
  ButtonPeraConnect,
} from "../general_styled";
import {
  ccFormat,
  formatterCurrency,
  toIBFx,
  decryptMnemonic,
  fromIBFx,
} from "../../utils/utils";
import ModalLoading from "../ModalLoading";
import { stripeKey } from "../../configs";
import { ASSETS_NAME, TRANSACTION_TYPE } from "../../constants/constant_wallet";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import {
  createTransactions,
  addUserBankInfo,
  redeemVoucher,
  getWalletUser,
  getUserBankInfoByUserId,
  getAllAssets,
  createOperations,
  apiSignedOperation,
} from "../../modules/wallet/action";
import StripeBody from "../StripeBody";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../utils/windowDimensions";
import { openImportMnemonicModal } from "../../modules/import_mnemonic_modal/action";
import { useTranslation } from "react-i18next";
import { PerawalletContext } from "../../context/perawallet-context";

const { Title, Text } = Typography;

// const payment = [
//   {
//     type: "master_card",
//     bank_name: "PT.BANK MNC INTERNASIONAL TBK.",
//     bank_swift_code: "BUMIIDJA",
//     bank_account_name: "M ARIZAL BAKRIE",
//     bank_account_number: "12313123123",
//   },
//   {
//     type: "master_card",
//     bank_name: "PT.BANK MNC INTERNASIONAL TBK.",
//     bank_swift_code: "BUMIIDJA",
//     bank_account_name: "M ARIZAL BAKRIE",
//     bank_account_number: "12313123123",
//   },
// ]

// const paymentNew = [
//   {
//     type: "credit_card",
//     name: "Card",
//     subtitle: "Mastercard, Visa, Rupay, Amex & more",
//     newCard: true,
//   },
//   {
//     type: "bank",
//     name: "Netbanking",
//     subtitle: "Mastercard, Visa, Rupay, Amex & more",
//     newCard: true,
//   },
// ]

// const account = [
//   {
//     id: "6fdbfba6-6221-4dc6-9005-5f8ecd8583b7",
//     user_id: "8fe2d7a7-38b0-11ec-b6d3-0242ac120002",
//     bank_name: "PT.BANK MNC INTERNASIONAL TBK.",
//     bank_swift_code: "BUMIIDJA",
//     bank_account_name: "M ARIZAL BAKRIE",
//     bank_account_number: "12313123123",
//     created_at: 1635656130958,
//     updated_at: null,
//   },
//   {
//     id: "6fdbfba6-6221-4dc6-9005-5f8ecd8583b7",
//     user_id: "8fe2d7a7-38b0-11ec-b6d3-0242ac120002",
//     bank_name: "PT.BANK MNC INTERNASIONAL TBK.",
//     bank_swift_code: "BUMIIDJA",
//     bank_account_name: "M ARIZAL BAKRIE",
//     bank_account_number: "12313123123",
//     created_at: 1635656130958,
//     updated_at: null,
//   },
// ]

const newAccount = {
  type: "bank",
  name: "Bank Account",
  subtitle: "HDFC, ICICI & more",
  newAccount: true,
};

const amount = [
  { value: 10, text: "$10" },
  // { value: 30, text: "$30" },
  { value: 50, text: "$50" },
  { value: 100, text: "$100" },
  // { value: 200, text: "$200" },
  // { value: 250, text: "$250" },
  // { value: 500, text: '$500' },
  // // { value: 750, text: "$750" },
  // { value: 1000, text: '$1000' },
  // { value: 2000, text: "$2000" },
  // { value: 2500, text: "$2500" },
  // { value: 5000, text: "$5000" },
  // { value: 10000, text: "$10000" },
];

const mappingPerawalletPercent = {
  0: 100,
  1: 10,
  2: 30,
  3: 60,
  4: 80,
  5: 100,
};

const mappingPerawalletProcess = {
  0: "Rejected by Network",
  1: "Creating Transactions",
  2: "Signing Transaction",
  3: "Pending Call Request, waiting confirmation on wallet",
  4: "Submit Transaction to Network",
  5: "Waiting for network to mine transaction",
};

export default function ModalWallet(props) {
  const { t } = useTranslation();

  const walletContext = useContext(PerawalletContext);

  const windowDimensions = useWindowDimensions();
  const stripeLoader = loadStripe(stripeKey);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(10);
  const [customAmount, setCustomAmount] = useState(undefined);
  const [customAlgoAmount, setCustomAlgoAmount] = useState(undefined);
  const [listAccountBank, setListAccountBank] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [algoAddress, setAlgoAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankSwiftCode, setSwiftCode] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [perawalletProcess, setPerawalletProcess] = useState(0);
  const [algoObtained, setAlgoObtained] = useState(0);

  const [currentStep, setCurrentStep] = useState(0);
  const [isFailed, setFailed] = useState(false);

  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );

  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );
  const algoPerUSD = useSelector((state) =>
    state.getIn(["wallet", "algoPerUSD"])
  );

  const dispatch = useDispatch();

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  useEffect(() => {
    initAccountBank();
  }, []);

  const initAccountBank = async () => {
    if (props.type == "convert") {
      const result = await getUserBankInfoByUserId();
      setListAccountBank(result);
    }
  };

  const showModal = () => {
    if (props.onExtraClick) {
      props.onExtraClick();
    }

    initContent();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedPayment({});
    setCurrentStep(0);
    setSelectedAmount(10);
    setAlgoAddress("");
    setPinCode("");
    setCustomAmount(undefined);
    setCustomAlgoAmount(undefined);
    setAlgoObtained(0);
  };

  const onSelectorClicked = (element) => {
    setSelectedAmount(element);
  };

  const onPaymentChange = (e) => {
    console.log(e);
    setSelectedPayment(e);
  };

  const onBack = () => {
    setCurrentStep((prevState) => {
      if (props.type === "transfer" && prevState === 0) {
        return 4;
      } else if (props.type === "topup" && prevState === 3) {
        return 0;
      } else if (props.type === "transfer" && prevState === 3) {
        return 0;
      } else if (prevState === 3) {
        return prevState - 2;
      } else {
        return prevState - 1;
      }
    });
  };

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initContent = () => {
    if (props.type === "topup") {
      setCurrentStep(0);
    } else if (props.type === "transfer") {
      setCurrentStep(4);
    } else if (props.type === "convert") {
      setCurrentStep(5);
    } else if (props.type === "redeem") {
      setCurrentStep(6);
    }
  };

  const resolveName = () => {
    switch (props.data.asset_name) {
      case ASSETS_NAME.IBFS:
        return "IBFS";
      case ASSETS_NAME.IBFX_TYPE_I:
        return "IBFX";
      case ASSETS_NAME.IBFX_TYPE_II:
        return "IBFX - 2";
      case ASSETS_NAME.IBFX_TYPE_III:
        return "IBFX - 3";
      case ASSETS_NAME.IBFX_TYPE_IV:
        return "IBFX - 4";
    }
  };

  const onSubmitPerawalletTransfer = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      const body = {
        receiver: walletUser.algo_address,
        asset_id: props.data.asset_id,
        amount: Math.floor(algoObtained * 100000),
        walletconnect_address: walletContext.address,
        type: TRANSACTION_TYPE.TOP_UP_WITH_WALLETCONNECT,
      };

      setPerawalletProcess(2);
      const operations = await createOperations(body);

      setPerawalletProcess(3);
      const signedOperation = await walletContext.signByWalletconnect(
        operations,
        _decryptMnemonic
      );

      setPerawalletProcess(4);
      await apiSignedOperation(signedOperation);

      setPerawalletProcess(5);

      dispatch(getWalletUser(walletUser.user_id));
      dispatch(getAllAssets(walletUser));

      return true;
    } catch (e) {
      setPerawalletProcess(0);
      console.log(e);
    }
  };

  const onSubmitTransfer = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      const body = {
        sender: walletUser.algo_address,
        receiver: algoAddress,
        asset_id: props.data.asset_id,
        amount: customAmount ? toIBFx(customAmount) : toIBFx(selectedAmount),
        type: TRANSACTION_TYPE.PAY,
      };

      await createTransactions(_decryptMnemonic, body);

      dispatch(getWalletUser(walletUser.user_id));
      dispatch(getAllAssets(walletUser));
      return true;
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmitConvert = async () => {
    try {
      let userBankInfo;

      if (bankAccountName && bankAccountNumber) {
        userBankInfo = await onSaveBankInfo();
      }

      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);

      const body = {
        sender: walletUser.algo_address,
        asset_id: props.data.asset_id,
        amount: toIBFx(customAmount),
        type: "BUY_BACK",
        user_bank_info_id: userBankInfo ? userBankInfo.id : selectedPayment.id,
      };

      const result = await createTransactions(_decryptMnemonic, body);
      dispatch(getWalletUser(walletUser.user_id));

      return true;
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmitRedeem = async () => {
    try {
      const body = {
        algo_address: walletUser.algo_address,
        voucher_code: voucherCode,
      };

      const result = await redeemVoucher(body);

      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const onSaveBankInfo = async () => {
    try {
      const body = {
        user_id: walletUser.user_id,
        bank_name: bankName,
        bank_swift_code: bankSwiftCode,
        bank_account_name: bankAccountName,
        bank_account_number: bankAccountNumber,
      };

      const result = await addUserBankInfo(body);

      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const resolveContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            {resolveTitle(
              props.type === "topup"
                ? "Top Up"
                : props.type === "transfer"
                ? "Amount"
                : "",
              props.type === "topup" ? (
                <>
                  {t("title_top_up")}{" "}
                  <span className="text-w600">{resolveName()}</span>
                </>
              ) : props.type === "transfer" ? (
                t("title_transfer")
              ) : (
                ""
              )
            )}
            {resolveBalanceCard()}
            {resolveAmountCard()}
          </>
        );
      case 1:
        return (
          <>
            {resolveTitle(
              props.type === "topup"
                ? "Payment"
                : props.type === "convert"
                ? "Select Account"
                : "",
              props.type === "topup"
                ? "Select your mode of payment"
                : props.type === "convert"
                ? `Select the account you want to transfer ${customAmount} to`
                : ""
            )}
            <Row className="mt-24px">
              <Col span={24}>
                <LabelPayment>Saved</LabelPayment>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {listAccountBank.map((element) =>
                  resolveBoxPayment(element, true)
                )}
              </Col>
            </Row>
            <Row className="mt-24px">
              <Col span={24}>
                <LabelPayment>
                  New {props.type === "convert" && "Account"}
                </LabelPayment>
                {resolveBoxPayment(newAccount)}
              </Col>
            </Row>

            <Row className="mt-60px">
              <Col className="mb-12px" span={24}>
                <RowCenter>
                  <img src={poweredStripeIcon} />
                </RowCenter>
              </Col>
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={() => {
                    if (selectedPayment.newCard) {
                      setCurrentStep(2);
                    } else if (selectedPayment.newAccount) {
                      setCurrentStep(2);
                    } else {
                      setCurrentStep(3);
                    }
                  }}
                >
                  {selectedPayment.newCard
                    ? "Add Card"
                    : selectedPayment.newAccount
                    ? "Add Account"
                    : "Add $" + selectedAmount}
                </Button>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <ColSpaceBetween className="stretch" style={{ minHeight: 500 }}>
            <ColStart>
              {resolveTitle(
                props.type === "topup" ? "Card" : "New Account",
                props.type === "topup"
                  ? "Add details about your card"
                  : "Add a new bank account to transfer funds"
              )}
              {props.type === "topup" ? (
                <>
                  <Row className="mt-24px">
                    <Col span={24}>
                      <Elements stripe={stripeLoader}>
                        <StripeBody
                          amount={toIBFx(
                            customAmount
                              ? customAmount * ibfxPerUSD
                              : selectedAmount * ibfxPerUSD
                          )}
                          assetId={props.data.asset_id}
                          data={props.data}
                          pinCode={pinCode}
                          handleCancel={handleCancel}
                        />
                      </Elements>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Input
                    className="w-100 mt-16px"
                    placeholder="Bank Name"
                    size="large"
                    onChange={(e) => setBankName(e.target.value)}
                  />
                  <Input
                    className="w-100 mt-16px"
                    placeholder="Beneficiary Name"
                    size="large"
                    onChange={(e) => setBankAccountName(e.target.value)}
                  />
                  <InputNumber
                    type="number"
                    className="w-100 mt-16px"
                    size="large"
                    placeholder="Account Number"
                    formatter={(value) =>
                      value.replace(/\B(?=(\d{4})+(?!\d))/g, " ")
                    }
                    parser={(value) => value.replace(/\$\s?|( *)/g, "")}
                    onChange={(value) => setBankAccountNumber(value)}
                  />
                  <Input
                    className="w-100 mt-16px"
                    placeholder="Swift Code"
                    size="large"
                    onChange={(e) => setSwiftCode(e.target.value)}
                  />
                </>
              )}
              {/* {props.type !== "topup" && (
                <Row className="mt-16px">
                  <Col span={24}>
                    <Checkbox onChange={(e) => setOnSaveBank(e.target.checked)}>
                      Save this card for future use
                    </Checkbox>
                  </Col>
                </Row>
              )} */}
            </ColStart>
            {props.type !== "topup" && (
              <Row className="d-flex">
                <Col className="mb-12px" span={24}>
                  <RowCenter>
                    <img src={poweredStripeIcon} />
                  </RowCenter>
                </Col>
                <Col span={24}>
                  <Button
                    block
                    type="primary"
                    size="large"
                    onClick={() => {
                      if (props.type === "convert") {
                        onSaveBankInfo();
                      }
                      setCurrentStep(3);
                    }}
                  >
                    Add Account
                  </Button>
                </Col>
              </Row>
            )}
          </ColSpaceBetween>
        );
      case 3:
        return (
          <ColSpaceBetween className="stretch">
            {resolveTitle("Pin", "Please enter your 4 digit wallet pin")}
            <RowCenter className="my-24px">
              <ReactCodeInput
                type="password"
                onChange={(e) => setPinCode(e)}
                fields={4}
                {...codeProps}
              />
            </RowCenter>
            <Row className="d-flex">
              <Col span={24}>
                {props.type === "topup" ? (
                  <>
                    <Row className="d-flex">
                      <Col span={24}>
                        <Button
                          block
                          type="primary"
                          size="large"
                          onClick={() => {
                            const _checkPin = checkPin();
                            if (_checkPin) {
                              if (walletContext.isConnected) {
                                setCurrentStep(7);
                                setPerawalletProcess(1);
                                onSubmitPerawalletTransfer();
                              } else {
                                setCurrentStep(2);
                              }
                            }
                          }}
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                    <Row className="d-flex" style={{ marginTop: "8px" }}>
                      <Col span={24}>
                        <Button
                          block
                          ghost
                          type="primary"
                          size="large"
                          onClick={() => {
                            dispatch(openImportMnemonicModal("Reset PIN"));
                            handleCancel();
                          }}
                        >
                          Reset Pin
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row
                    className="d-flex"
                    style={{ marginTop: "8px" }}
                    gutter={[8, 8]}
                  >
                    <Col span={24}>
                      <ModalLoading
                        centered
                        mode={currentStep}
                        centeredLoading
                        handleCancel={handleCancel}
                        type={props.type}
                        amount={
                          props.type === "topup"
                            ? customAmount
                              ? customAmount * ibfxPerUSD
                              : selectedAmount * ibfxPerUSD
                            : customAmount || selectedAmount
                        }
                        data={props.data}
                        onCheckPin={checkPin}
                        onProcess={
                          props.type === "transfer"
                            ? onSubmitTransfer
                            : onSubmitConvert
                        }
                      />
                    </Col>
                    <Col span={24}>
                      <Button
                        block
                        ghost
                        type="primary"
                        size="large"
                        onClick={() => {
                          dispatch(openImportMnemonicModal("Reset PIN"));
                          handleCancel();
                        }}
                      >
                        Reset Pin
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </ColSpaceBetween>
        );
      case 4:
        return (
          <ColSpaceBetween className="stretch" style={{ minHeight: 500 }}>
            <ColStart>
              {resolveTitle(
                "Transfer",
                windowDimensions < 835
                  ? [
                      `Transfer funds to an Algorand wallet`,
                      <br />,
                      `for ${resolveName()}`,
                    ]
                  : `Transfer funds to an Algorand wallet for ${resolveName()}`
              )}
              <BoxReceivers className="mt-24px">
                <div className="label-receiver">RECEIVERS ALGORAND ADDRESS</div>
                <Input
                  className="w-100 mt-16px mb-8px"
                  placeholder="Enter address"
                  size="large"
                  value={algoAddress}
                  onChange={(e) => {
                    setFailed(false);
                    setAlgoAddress(e.target.value);
                  }}
                />
                {isFailed && (
                  <Alert
                    message="Please Enter Receiver's Address"
                    type="error"
                    showIcon
                  />
                )}
              </BoxReceivers>
              <RowCenter>
                <img
                  className="mt-24px"
                  src={chevronUpIcon}
                  width={24}
                  height={24}
                />
              </RowCenter>
              {resolveBalanceCard()}
            </ColStart>
            <Row className="d-flex">
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={() => {
                    if (algoAddress.length <= 0) {
                      setFailed(true);
                    } else {
                      setCurrentStep(0);
                    }
                  }}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </ColSpaceBetween>
        );
      case 5:
        return (
          <ColSpaceBetween className="stretch" style={{ minHeight: 500 }}>
            <ColStart>
              {resolveTitle(
                "Convert",
                `Enter ${resolveName()} amount to convert to USD`
              )}
              {resolveBalanceCard()}
              <BoxReceivers className="mt-24px">
                <div className="label-receiver">IBFX Amount</div>
                {/* fontsize 16px height 50px */}
                <InputNumber
                  type="number"
                  className="w-100 mt-16px"
                  addonBefore="$"
                  placeholder="Enter amount"
                  size="large"
                  min={1}
                  onChange={(value) => setCustomAmount(value)}
                />
              </BoxReceivers>
              <RowCenter>
                <img
                  className="mt-24px"
                  src={chevronDownIcon}
                  width={24}
                  height={24}
                />
              </RowCenter>
              <BoxReceivers className="mt-24px mb-24px">
                <div className="label-receiver">You Will Receive</div>

                {/* fontsize 16, color white, same height as balance (60px) */}

                <div className="show-receive">
                  <h6 className="receive">
                    {customAmount / ibfxPerUSD || 0}{" "}
                    {props.type === "convert" ? "USD" : "IBFX"}
                    {/* fontsize 16, color white */}
                  </h6>
                </div>
              </BoxReceivers>
            </ColStart>
            <Row className="d-flex">
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </ColSpaceBetween>
        );
      case 6:
        return (
          <ColSpaceBetween className="stretch" style={{ minHeight: 500 }}>
            <ColStart>
              {resolveTitle("Redeem", `Redeem ${resolveName()} Vouchers`)}
              <BoxReceivers className="mt-24px">
                <div className="label-receiver">Voucher Code</div>
                <Input
                  className="w-100 mt-16px"
                  placeholder="Enter Code"
                  size="large"
                  onChange={(e) => setVoucherCode(e.target.value)}
                />
              </BoxReceivers>
              <RowCenter>
                <img
                  className="mt-24px"
                  src={chevronDownIcon}
                  width={24}
                  height={24}
                />
              </RowCenter>
              {resolveBalanceCard()}
            </ColStart>
            <Row className="d-flex">
              <Col span={24}>
                <ModalLoading
                  centered
                  centeredLoading
                  handleCancel={handleCancel}
                  type={props.type}
                  amount={customAmount || selectedAmount}
                  data={props.data}
                  onProcess={onSubmitRedeem}
                  text="Redeem"
                />
                <Button
                  block
                  ghost
                  className="mt-8px"
                  type="primary"
                  size="large"
                  onClick={() => {
                    dispatch(openImportMnemonicModal("Reset PIN"));
                    handleCancel();
                  }}
                >
                  Reset Pin
                </Button>
              </Col>
            </Row>
          </ColSpaceBetween>
        );
      case 7:
        return (
          <ColSpaceBetween className="stretch">
            {resolveTitle(
              "Top-up",
              "Approve or reject request using your wallet"
            )}
            <Row className="d-flex">
              <Col span={24}>
                <ContentStyled>
                  <ColCenter>
                    {/* status={perawalletProcess !== 5 ? "active" : "success"} */}
                    <Progress
                      type="circle"
                      percent={mappingPerawalletPercent[perawalletProcess]}
                      status={perawalletProcess == 0 && "exception"}
                    />
                    <Title
                      className="text-w700 gray-7 mb-0px mt-24px"
                      level={4}
                    >
                      {perawalletProcess == 0
                        ? "Error"
                        : perawalletProcess !== 5
                        ? "Processing"
                        : "Successfully Submit Transaction"}
                    </Title>
                    <Text className="gray-7" align="center">
                      {mappingPerawalletProcess[perawalletProcess]}
                    </Text>
                  </ColCenter>
                  <Row className="mt-20px">
                    {/* {mode === 2 && (
								<Col span={12}>
									<Button size="large" block type="primary" onClick={() => onProcess(true)}>
										Try Again
									</Button>
								</Col>
							)} */}
                    <Col span={24} offset={0}>
                      <Button
                        type={"primary"}
                        size="large"
                        block
                        onClick={handleCancel}
                      >
                        Dismiss
                      </Button>
                    </Col>
                  </Row>
                </ContentStyled>
              </Col>
            </Row>
          </ColSpaceBetween>
        );
    }
  };

  const resolveTitle = (title, subtitle) => {
    return (
      <ColCenter>
        <Title className="text-w700 gray-7 mb-6px text-center" level={3}>
          {title}
        </Title>
        <Text className="gray-7 text-center mb-24px">{subtitle}</Text>
      </ColCenter>
    );
  };

  const resolveBalanceCard = () => {
    return (
      <Row>
        {walletContext.isConnected && props.type === "topup" ? (
          <>
            <Col span={24} className="bg-white">
              <BalanceCardStyled>
                <RowSpaceBetween>
                  <Title
                    level={1}
                    className="text-size-12"
                    style={{
                      marginBottom: 0,
                      color: "#0c2c49 !important",
                      fontWeight: 400,
                    }}
                  >
                    Perawallet Address
                  </Title>
                  <Title
                    level={1}
                    className="gray-8 text-size-12"
                    style={{
                      marginBottom: 0,
                      marginTop: 0,
                      color: "#0c2c49",
                      fontWeight: 400,
                    }}
                  >
                    {walletContext.accounts?.[0]?.slice(0, 15) + "..."}
                  </Title>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <Title
                    level={5}
                    className="text-size-16"
                    style={{
                      marginBottom: 0,
                      lineHeight: 1.7,
                      color: "#0c2c49 !important",
                      fontWeight: 500,
                    }}
                  >
                    Algo Balance
                  </Title>
                  <Title
                    level={4}
                    className="gray-8 text-size-16"
                    style={{
                      marginBottom: 0,
                      marginTop: 0,
                      color: "#0c2c49 !important",
                      fontWeight: "600 !important",
                    }}
                  >
                    {walletContext.assets?.balance || 0}
                  </Title>
                </RowSpaceBetween>
              </BalanceCardStyled>
            </Col>
            <ButtonPeraConnect
              block
              loading={walletContext.isFetching}
              className="mt-12px"
              type="primary"
              size="large"
              onClick={() => {
                walletContext.killSession();
              }}
            >
              <span className="text">
                Disconnect Wallet
                <img
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                    height: 30,
                    paddingBottom: 3,
                  }}
                  src={peraIcon}
                />
              </span>
            </ButtonPeraConnect>
          </>
        ) : (
          <>
            <Col span={24} className="bg-white">
              <BalanceCardStyled>
                <RowSpaceBetween>
                  <Title
                    level={5}
                    className="text-size-16"
                    style={{
                      marginBottom: 0,
                      lineHeight: 1.7,
                      color: "#0c2c49 !important",
                      fontWeight: 500,
                    }}
                  >
                    {resolveName()} Balance
                  </Title>
                  <Title
                    level={4}
                    className="gray-8 text-size-16"
                    style={{
                      marginBottom: 0,
                      marginTop: 0,
                      color: "#0c2c49 !important",
                      fontWeight: "600 !important",
                    }}
                  >
                    {formatterCurrency.format(fromIBFx(props.data.amount) || 0)}
                  </Title>
                </RowSpaceBetween>
              </BalanceCardStyled>
            </Col>
            {/* {props.type === 'topup' && (
							<ButtonPeraConnect
								block
								loading={walletContext.isFetching}
								className="mt-12px"
								type="primary"
								size="large"
								onClick={() => {
									walletContext.walletInit()
								}}
							>
								<span className="text">
									Connect to Pera
									<img style={{ marginLeft: 5, marginRight: 5, height: 30, paddingBottom: 3 }} src={peraIcon} />
								</span>
							</ButtonPeraConnect>
						)} */}
          </>
        )}
      </Row>
    );
  };

  const resolveAmountCard = () => {
    return walletContext.isConnected && props.type === "topup" ? (
      <>
        <Row>
          <Col span={24}>
            <BoxAmountStyled>
              <Row>
                <Col span={24}>
                  <div className="label">Transfer Amount</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <InputNumberStyled
                    type="number"
                    className="w-100"
                    addonAfter={"ALGO"}
                    placeholder="Enter amount"
                    size="large"
                    value={customAlgoAmount}
                    max={
                      props.type === "topup" && walletContext?.assets?.balance
                    }
                    onChange={(value) => {
                      const _algoObtained =
                        ((value / algoPerUSD) * 10).toFixed(5) || 0;
                      setAlgoObtained(Number(_algoObtained));
                      setCustomAlgoAmount(value);
                    }}
                  />
                </Col>
                {customAlgoAmount >= walletContext?.assets?.balance && (
                  <WarningAmountStyled>
                    *Maximum topup limit is {walletContext?.assets?.balance}{" "}
                    ALGO.
                  </WarningAmountStyled>
                )}
              </Row>
            </BoxAmountStyled>
          </Col>
        </Row>
        <BoxReceivedAmount>
          <span className="label">{t("will_receive")}</span>
          <span className="value">{algoObtained ? algoObtained : 0} IBFX</span>
        </BoxReceivedAmount>
        <Row className="mt-24px">
          <Col span={24}>
            <Button
              block
              disabled={walletContext.isConnected && !customAlgoAmount}
              style={{ borderRadius: "10px", height: "50px", fontSize: "15px" }}
              type="primary"
              size="large"
              onClick={() => {
                setCurrentStep(3);
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </>
    ) : (
      <>
        <Row>
          <Col span={24}>
            <BoxAmountStyled>
              <Row>
                <Col span={24}>
                  <div className="label">
                    Select {props.type === "transfer" && "IBFX"} Amount
                  </div>
                </Col>
              </Row>
              <Row>
                {amount.map((element) => (
                  <Col span={8}>
                    <div
                      onClick={() => onSelectorClicked(element.value)}
                      className={
                        "selector " +
                        (selectedAmount === element.value ? "selected" : "")
                      }
                    >
                      <span>
                        {props.type === "transfer"
                          ? element.value
                          : element.text}
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </BoxAmountStyled>
          </Col>
        </Row>
        <DividerStyled plain>
          <span>OR</span>
        </DividerStyled>
        <Row>
          <Col span={24}>
            <BoxAmountStyled>
              <Row>
                <Col span={24}>
                  <div className="label">Custom Amount</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <InputNumberStyled
                    type="number"
                    className="w-100"
                    addonBefore={props.type === "topup" && "$"}
                    addonAfter={props.type === "transfer" && "IBFX"}
                    placeholder="Enter amount"
                    size="large"
                    min={1}
                    max={props.type === "topup" && 1000}
                    onChange={(value) => {
                      onSelectorClicked(0);
                      setCustomAmount(value);
                    }}
                  />
                </Col>
                {customAmount > 100 && (
                  <WarningAmountStyled>
                    {t("waning_top_up")}
                  </WarningAmountStyled>
                )}
              </Row>
            </BoxAmountStyled>
          </Col>
        </Row>
        {props.type === "topup" ? (
          <BoxReceivedAmount>
            <span className="label">{t("will_receive")}</span>
            <span className="value">
              {(customAmount
                ? customAmount * ibfxPerUSD
                : selectedAmount * ibfxPerUSD) || 0}{" "}
              IBFX
            </span>
          </BoxReceivedAmount>
        ) : (
          <></>
        )}
        <Row className="mt-24px">
          <Col span={24}>
            <Button
              block
              style={{ borderRadius: "10px", height: "50px", fontSize: "15px" }}
              type="primary"
              size="large"
              onClick={() => {
                setCurrentStep(3);
              }}
            >
              {props.type === "topup"
                ? "Continue to Payment"
                : props.type === "transfer"
                ? "Send " +
                  (customAmount || selectedAmount) +
                  " " +
                  resolveName()
                : ""}
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const resolveBoxPayment = (element, isAccount) => {
    return (
      <BoxPaymentStyled
        className={selectedPayment === element ? "selected" : ""}
        onClick={() => onPaymentChange(element)}
      >
        <RowSpaceBetween>
          <>
            <img
              width={40}
              src={
                (isAccount && selectedPayment === element) ||
                (element.type === "bank" && selectedPayment === element)
                  ? bankActiveIcon
                  : isAccount
                  ? bankIcon
                  : !element.newCard && selectedPayment === element
                  ? masterCardColorIcon
                  : element.type === "master_card"
                  ? masterCardIcon
                  : element.type === "credit_card"
                  ? creditCardIcon
                  : element.type === "bank"
                  ? bankIcon
                  : ""
              }
            />
            <ColStart className="w-100 ml-8px">
              <div className="name">
                {element.name && element.name}
                {isAccount
                  ? element.bank_account_number.replace(
                      /\B(?=(\d{4})+(?!\d))/g,
                      " "
                    )
                  : element.bank_account_name}

                {selectedPayment === element}
              </div>
              {element.bank_account_number && (
                <div className="number">
                  {isAccount
                    ? element.bank_account_name
                    : ccFormat(element.bank_account_number)}
                </div>
              )}

              {element.subtitle && (
                <div className="number">{element.subtitle}</div>
              )}
            </ColStart>
          </>
          <Checkbox
            checked={selectedPayment === element}
            onChange={(_) => onPaymentChange(element)}
          />
        </RowSpaceBetween>
      </BoxPaymentStyled>
    );
  };

  const resolveBackButton = () => {
    if (props.type === "topup" && currentStep > 0 && currentStep !== 2) {
      return <BackButtonStyled src={chevronLeftIcon} onClick={onBack} />;
    } else if (props.type === "transfer" && currentStep !== 4) {
      return <BackButtonStyled src={chevronLeftIcon} onClick={onBack} />;
    }
  };

  return (
    <>
      {props.isPrimary ? (
        <ButtonStyled
          style={props.style || {}}
          block
          size="large"
          onClick={showModal}
        >
          {props.text || props.type[0].toUpperCase() + props.type.substring(1)}
        </ButtonStyled>
      ) : (
        <Button
          type="primary"
          ghost
          style={props.style || {}}
          block
          size="large"
          onClick={showModal}
        >
          {props.text || props.type[0].toUpperCase() + props.type.substring(1)}
          {/* Under Maintenance */}
        </Button>
      )}

      {windowDimensions.width > 835 ? (
        <Modal
          centered
          className="modal-top-up"
          maskStyle={{ backgroundColor: "#f3f6fa" }}
          visible={isModalVisible}
          onCancel={handleCancel}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
          footer={null}
        >
          <ContentStyled>
            {resolveBackButton()}
            {resolveContent()}
          </ContentStyled>
        </Modal>
      ) : (
        <Modal
          centered
          className="modal-top-up"
          maskStyle={{ backgroundColor: "#f3f6fa" }}
          visible={isModalVisible}
          onCancel={handleCancel}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
          footer={null}
        >
          <ContentStyled>
            {resolveBackButton()}
            {resolveContent()}
          </ContentStyled>
        </Modal>
      )}
    </>
  );
}
