import React, { useState } from "react";
import { Modal, Typography, Input, Drawer, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { ColCenter, RowEnd, RowSpaceBetween } from "../general_styled";

import { ContentStyled } from "./styled";
import closeIcon from "../../assets/images/close.svg";
import {
  getTransactionHistory,
  reportTransaction,
} from "../../modules/wallet/action";
import useWindowDimensions from "../../utils/windowDimensions";
import { ASSETS_NAME } from "../../constants/constant_wallet";
import { BalanceCardStyled } from "../ModalWallet/styled";
import ModalWallet from "../ModalWallet";
import { formatterCurrency, fromIBFx } from "../../utils/utils";
import IBFxLogo from "../../assets/images/ibfx-icon.svg";

const { Title, Text } = Typography;

const { TextArea } = Input;

export default function ModalInsufficientFunds(props) {
  const windowDimensions = useWindowDimensions();

  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );

  const handleCancel = () => {
    props.setModalVisible(false);
  };

  const resolveBalance = (assetName) => {
    const asset = allAssets.find((asset) => asset.asset_name === assetName);
    return asset ? asset.amount : 0;
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const resolveBalanceCard = () => {
    return (
      <Col span={24}>
        <BalanceCardStyled>
          <RowSpaceBetween>
            <Title
              level={5}
              className="secondary text-size-12"
              style={{ marginBottom: 0, lineHeight: 1.7 }}
            >
              Your IBFX Balance
            </Title>
            <Title
              level={4}
              className="gray-8 text-size-14"
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              {formatterCurrency.format(
                fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I)) || 0
              )}
            </Title>
          </RowSpaceBetween>
        </BalanceCardStyled>
      </Col>
    );
  };

  const resolveTotalDue = () => {
    return (
      <Col span={24}>
        <BalanceCardStyled className="bg-white w-100">
          <RowSpaceBetween>
            <Title
              level={5}
              className="gray-8 text-size-14"
              style={{ marginBottom: 0 }}
            >
              Total Due
            </Title>
            <RowEnd>
              <img src={IBFxLogo} className="mr-4px" />
              <Title
                level={4}
                className="gray-8 text-size-14"
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                {formatterCurrency.format(props.amount || 0)}
              </Title>
            </RowEnd>
          </RowSpaceBetween>
        </BalanceCardStyled>
      </Col>
    );
  };

  const resolveButtonTopup = () => {
    return (
      <ModalWallet
        type={"topup"}
        data={allAssets.find((i) => i.asset_name === ASSETS_NAME.IBFX_TYPE_I)}
        text={"Add More Funds"}
        onExtraClick={handleCancel}
        isPrimary={true}
        style={{
          height: 40,
          width: "100%",
          marginTop: "30px",
        }}
      />
    );
  };

  return (
    <>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={props.isModalVisible}
          onCancel={handleCancel}
          width="400px"
          footer={null}
        >
          <ContentStyled>
            {/* onActivateAssets */}

            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Insufficient Funds
              </Title>
              <Text className="gray-7" align="center">
                You do not have the required funds in your Wallet
              </Text>
              <Row>
                {resolveBalanceCard()}
                {resolveTotalDue()}
              </Row>
              {resolveButtonTopup()}
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={props.isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Insufficient Funds
              </Title>
              <Text className="gray-7 mt-5px" align="center">
                You do not have the required funds in your Wallet
              </Text>
              <Row>
                {resolveBalanceCard()}
                {resolveTotalDue()}
              </Row>
              {resolveButtonTopup()}
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
