import { fromJS } from 'immutable'
import {
	SET_CREDENCE_NFTS,
	SET_CREDENCE_PURCHASED,
	SET_CREDENCE_USER,
	SET_ESSENCE_ONSALE,
	SET_CREDENCE_DETAIL,
	SET_CREDENCE_COLLECTION,
	SET_CREDENCE_COLLECTION_DETAIL,
	SET_USER_COLLECTION,
	SET_CREDENCE_PERCENTAGE,
} from '../constants'

const initialState = fromJS({
	isLoading: false,
	hasError: false,
	errorMessage: '',
	nftMaxPercentage: null,
	user: fromJS({}),
	onsale: fromJS([]),
	purchased: fromJS([]),
	userCollections: fromJS([]),
	collections: fromJS([]),
	collectionDetail: fromJS([]),
	nfts: fromJS({
		data: [],
		nftsVault: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
})

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_CREDENCE_USER:
			return state.merge({
				user: fromJS(action.payload.data),
			})
		case SET_CREDENCE_NFTS:
			return state.merge({
				nfts: fromJS(action.payload),
			})
		case SET_ESSENCE_ONSALE:
			return state.merge({
				onsale: fromJS(action.payload),
			})
		case SET_CREDENCE_PURCHASED:
			return state.merge({
				purchased: fromJS(action.payload),
			})
		case SET_CREDENCE_DETAIL:
			return state.merge({
				detail: fromJS(action.payload),
			})
		case SET_CREDENCE_COLLECTION:
			return state.merge({
				collections: fromJS(action.payload),
			})
		case SET_CREDENCE_COLLECTION_DETAIL:
			return state.merge({
				collectionDetail: fromJS(action.payload),
			})
		case SET_USER_COLLECTION:
			return state.merge({
				userCollections: fromJS(action.payload),
			})
		case SET_CREDENCE_PERCENTAGE:
			return state.merge({
				nftMaxPercentage: action.payload.data.data.categories,
			})
		default:
			return state
	}
}
