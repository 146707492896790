import { fromJS } from 'immutable'
import { COMMON_CONSTANT } from '../../constants/constant_common'
import {
	ADD_CART,
	CLOSE_CART,
	CLOSE_SUCCESS_COURSE_MODAL,
	OPEN_CART,
	OPEN_SUCCESS_COURSE_MODAL,
	REMOVE_CART,
} from '../constants'

const initialState = fromJS({
	isDrawerOpen: false,
	isModalSuccessOpen: false,
	cart: localStorage.getItem(COMMON_CONSTANT.CART_COURSE)
		? fromJS(JSON.parse(localStorage.getItem(COMMON_CONSTANT.CART_COURSE)))
		: fromJS([]),
	discount: 0,
})

const addCart = (cart, course) => {
	let existingIndex = -1
	for (let index = 0; index < cart.length; index++) {
		if (cart[index].course_id === course.course_id) {
			existingIndex = index
		}
	}

	if (existingIndex < 0) {
		cart.push(course)
		localStorage.setItem(COMMON_CONSTANT.CART_COURSE, JSON.stringify(cart))
	}

	return cart
}

const removeCart = (cart, course) => {
	let existingIndex = -1
	for (let index = 0; index < cart.length; index++) {
		if (cart[index].course_id === course.course_id) {
			existingIndex = index
		}
	}

	if (existingIndex >= 0) {
		cart.splice(existingIndex, 1)
		localStorage.setItem(COMMON_CONSTANT.CART_COURSE, JSON.stringify(cart))
	}

	return cart
}

const getDiscount = (cart) => {
	let disc = 0
	for (let index = 0; index < cart.length; index++) {
		if (cart[index].discount) {
			disc += Number(cart[index].discount)
		}
	}

	return disc
}

export default (state = initialState, action) => {
	switch (action.type) {
		case ADD_CART:
			return state.merge({
				cart: fromJS(addCart(state.getIn(['cart']).toJS(), action.payload)),
				discount: getDiscount(addCart(state.getIn(['cart']).toJS(), action.payload)),
			})
		case REMOVE_CART:
			return state.merge({
				cart: fromJS(removeCart(state.getIn(['cart']).toJS(), action.payload)),
				discount:
					removeCart(state.getIn(['cart']).toJS(), action.payload).length <= 0
						? 0
						: getDiscount(removeCart(state.getIn(['cart']).toJS(), action.payload)),
			})
		case OPEN_CART:
			return state.merge({
				isDrawerOpen: true,
			})
		case CLOSE_CART:
			return state.merge({
				isDrawerOpen: false,
			})
		case OPEN_SUCCESS_COURSE_MODAL:
			return state.merge({
				isModalSuccessOpen: true,
			})
		case CLOSE_SUCCESS_COURSE_MODAL:
			return state.merge({
				isModalSuccessOpen: false,
			})
		default:
			return state
	}
}
