import styled from "@emotion/styled";
import { Row, Typography } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

export const Canvas = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const CustomRow = styled(Row)`
  width: 100%;
`;

export const ImageLogin = styled.div`
  background-size: cover;
  background-image: url(${(props) => props.img});

  @media screen and (min-width: 768px) {
    width: 100%;
    height: 100vh;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 30vh;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 768px) {
    height: 100vh;
    // padding: 0 24px;
  }

  @media screen and (max-width: 767px) {
    min-height: 70vh;
    padding: 24px 0;
  }
`;

export const FormCustom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  @media screen and (min-width: 768px) {
    width: 80%;
  }

  @media screen and (max-width: 767px) {
    width: 80%;

    // .reactCodeInput {
    //   margin-left: 10%;
    // }
  }
`;

export const LinkStyled = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Secondary/500 */

  color: #3b4552;
`;
export const TextStyled = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;

  color: #273248;
`;

export const BlueLink = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Daybreak Blue/blue-6 */

  color: #024FF1;
`;
export const EmailChange = styled.div`
  cursor: pointer;
  min-width: 40%;
  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 8px 12px;
  margin-top: 10px;

  @media screen and (max-width: 835px) {
    min-width: 75%;
  }

  .change {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height */

    /* Secondary/500 */

    color: #3b4552;
    margin-left: 10px;
  }

  .email {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Text/400 */

    color: #273248;
  }
`;
