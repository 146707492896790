import styled from "@emotion/styled";
import { Typography } from "antd";
import Search from "antd/lib/input/Search";

const { Text } = Typography;
export const TitleStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  /* identical to box height, or 118% */

  /* Text/500 */

  color: #0b0e14;
  margin-top: 24px;
  margin-bottom: 4px;
`;

export const DescStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 143% */

  text-align: center;

  /* Text/400 */

  color: #273248;

  max-width: 600px;
  margin-bottom: 24px;
`;

export const SearchStyled = styled(Search)`
  .ant-input-search-button,
  .ant-input-affix-wrapper,
  .ant-input,
  .ant-input::placeholder {
    background: #f4f6f7;
    color: #44567b;
  }
`;
