import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams, useLocation } from "react-router-dom";
import {
  Col,
  Typography,
  Button,
  Image,
  Row,
  message,
  notification,
  Spin,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Canvas,
  CustomRow,
  ImageLogin,
  Content,
  FormCustom,
  LinkStyled,
  TextSosMedStyled,
  SosMedStyled,
  LoginFormStyled,
  DividerStyled,
  Card,
  ButtonLogin,
} from "./styled";
import mosqueImage from "../../assets/images/fd.png";
import {
  userLogin,
  userLoginWithAuth0,
  userLoginWithJwt,
} from "../../modules/auth/action";

import linkedinIcon from "../../assets/images/linkedin-gray.svg";
import twitterIcon from "../../assets/images/twitter-gray.svg";
import whatsappIcon from "../../assets/images/whatsapp-gray.svg";
import telegramIcon from "../../assets/images/telegram-gray.svg";
import emailIcon from "../../assets/images/email-gray.svg";

import logoColor from "../../assets/images/logoV3.png";
import PolyImg from "../../assets/images/home/poly-card.png";
import AlgoImg from "../../assets/images/home/algo-card.png";
import {
  ButtonOutline,
  CheckboxStyled,
  ColCenter,
  InputPasswordStyled,
  InputStyled,
  RowCenter,
} from "../../components/general_styled";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import { IS_EVM, LOGIN_V2 } from "../../configs";
import { useAuth0 } from "@auth0/auth0-react";

const { Title, Text } = Typography;

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { redirect } = useParams();
  const auth0 = useAuth0();
  const params = new URLSearchParams(location.search);
  const stateAuth = useSelector((state) => state.getIn(["auth"]).toJS());
  const [isUseJWT, setIsUseJWT] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    onLoginWithAuth0();
  }, [auth0.user]);

  useEffect(() => {
    if (params.get("token")) {
      setLoading(true);
      setIsUseJWT(true);
      dispatch(userLoginWithJwt({ jwt: params.get("token") }));
    }

    if (params.get("redirect")) setRedirectTo(`/${params.get("redirect")}`);
    if (redirect === "courses") setRedirectTo("/courses");
    setLoading(false);
  }, []);

  const onLoginWithAuth0 = async (e) => {
    setLoading(true);
    if (!auth0.user) {
      return;
    }

    try {
      await dispatch(userLoginWithAuth0(auth0.user));
      message.success("You have successfully logged in");
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Failed!",
        description: extractErrorMessage(e).includes("email")
          ? "Failed to login"
          : "",
      });
    }
    setLoading(false);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#024FF1" }} spin />
  );

  const onLoginClicked = async (e) => {
    setLoading(true);
    const dataLogin = {
      email,
      password,
    };

    try {
      await dispatch(userLogin(dataLogin));
      message.success("You have successfully logged in");
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Failed!",
        description: extractErrorMessage(e).includes("email")
          ? "Invalid Email or Password"
          : "Password Incorrect",
      });
    } finally {
      setLoading(false);
    }
  };

  const onFieldChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (email !== "" && password !== "") onLoginClicked();
    }
  };

  // const resolveRightCol = () => {
  //   if (isUseJWT) {
  //     return (
  //       <FormCustom>
  //         <ColCenter className="w-100">
  //           <Spin size="large" />
  //         </ColCenter>
  //       </FormCustom>
  //     );
  //   } else if (!auth0.user) {
  //     return (
  //       <FormCustom>
  //         <Button
  //           type="primary"
  //           block
  //           style={{ marginTop: 24, marginBottom: 30 }}
  //           size="large"
  //           onClick={() => {
  //             auth0.loginWithRedirect();
  //           }}
  //         >
  //           {isLoading ? antIcon : "Login / Register"}
  //         </Button>
  //         {/* <ConnectWallet /> */}
  //       </FormCustom>
  //     );
  //   } else {
  //     return (
  //       <FormCustom>
  //         <ColCenter className="w-100">
  //           <Spin size="large" />
  //         </ColCenter>
  //       </FormCustom>
  //     );
  //   }
  // };

  const resolveUI = () => {
    if (stateAuth.isAuthorized) {
      return <Redirect to={redirectTo || "/home"} />;
    } else {
      return LOGIN_V2 ? (
        <Canvas>
          <ColCenter>
            <Image
              src={logoColor}
              width={132}
              style={{ margin: "2rem 0" }}
              preview={false}
            />
            <Title style={{ fontSize: "17px" }}>
              The Next Generation of Ethical Web3 Apps
            </Title>
            <Text>Choose your blockchain network</Text>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Card>
                  <div style={{ minHeight: "50px" }}>
                    <Image src={PolyImg} alt="Image Polygon" width={100} preview={false} />
                  </div>
                  <Text className="text">
                    The fast, low-cost, and secure blockchain for decentralized
                    applications and digital asset transfers.
                  </Text>
                  <ButtonLogin
                    onClick={() => {
                      localStorage.setItem("IS_EVM", "true");
                      auth0.loginWithRedirect();
                    }}
                  >
                    {IS_EVM && isLoading ? antIcon : "Login / Sign Up"}
                    {/* Launching Soon */}
                  </ButtonLogin>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Card>
                  <div style={{ minHeight: "50px" }}>
                    <Image src={AlgoImg} alt="Image Algorand" width={100} preview={false} />
                  </div>
                  <Text className="text">
                    The high-speed, secure blockchain platform with scalable and
                    efficient transactions for decentralized applications.
                  </Text>
                  <ButtonLogin
                    onClick={() => {
                      localStorage.setItem("IS_EVM", "false");
                      auth0.loginWithRedirect();
                    }}
                  >
                    {!IS_EVM && isLoading ? antIcon : "Login / Sign Up"}
                  </ButtonLogin>
                </Card>
              </Col>
            </Row>
            <ColCenter>
              <RowCenter className="mt-12px">
                <a
                  className="ml-4px mr-4px"
                  href="https://www.linkedin.com/company/ibf-net-l-limited"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedinIcon} alt="Linkedin Icon" />
                </a>
                <a
                  className="ml-4px mr-4px"
                  href="https://t.me/IBFNet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={telegramIcon} alt="Telegram Icon" />
                </a>
                <a
                  className="ml-4px mr-4px"
                  href="https://wa.me/message/5S3SIUD354WYK1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={whatsappIcon} alt="Whatsapp Icon" />
                </a>
                <a
                  className="ml-4px mr-4px"
                  href="https://twitter.com/ibfnet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitterIcon} alt="Twitter Icon" />
                </a>
                <a
                  className="ml-4px mr-4px"
                  href="mailto:dev@ibfnet.my"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={emailIcon} alt="Email Icon" />
                </a>
              </RowCenter>
              <TextSosMedStyled
                className="mt-12px"
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
              >
                © 2023 IBF Net Group
              </TextSosMedStyled>
            </ColCenter>
          </ColCenter>
        </Canvas>
      ) : (
        <Canvas>
          <CustomRow>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <ImageLogin img={mosqueImage} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Content>
                <LoginFormStyled>
                  <Image
                    src={logoColor}
                    width={204}
                    style={{ marginBottom: "36px", marginTop: "2rem" }}
                    preview={false}
                  />
                  <Title level={2} style={{ marginBottom: 0 }}>
                    Welcome
                  </Title>
                  <Text>Enter your credentials to login into your account</Text>
                  {isUseJWT ? (
                    <FormCustom>
                      <ColCenter className="w-100">
                        <Spin size="large" />
                      </ColCenter>
                    </FormCustom>
                  ) : (
                    <FormCustom>
                      <InputStyled
                        name="email"
                        placeholder="Email"
                        size="large"
                        onKeyDown={handleKeyDown}
                        onChange={onFieldChange}
                      />
                      <InputPasswordStyled
                        name="password"
                        size="large"
                        placeholder="Password"
                        onChange={onFieldChange}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        style={{ marginTop: 10, marginBottom: "20px" }}
                        onKeyDown={handleKeyDown}
                      />
                      <Row
                        style={{
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <CheckboxStyled>Remember Me</CheckboxStyled>
                        <LinkStyled to="/forgot-password">
                          Forgot Password?
                        </LinkStyled>
                      </Row>
                      <Button
                        type="primary"
                        block
                        disabled={!(email !== "" && password !== "")}
                        style={{ marginTop: 24, marginBottom: 30 }}
                        size="large"
                        onClick={onLoginClicked}
                      >
                        {isLoading ? antIcon : "Login / Register"}
                      </Button>

                      <DividerStyled plain>
                        <span>OR</span>
                      </DividerStyled>
                      <ButtonOutline
                        className="mt-34px"
                        style={{ height: 40 }}
                        block
                        onClick={() => history.push("/register")}
                      >
                        Create a New Account
                      </ButtonOutline>
                      {/* <TextStyled>Don’t have an IBFNex - Netverse Account?</TextStyled>
                  <BlueLink to="/register">Create New</BlueLink> */}
                    </FormCustom>
                  )}
                </LoginFormStyled>
                <SosMedStyled>
                  <TextSosMedStyled>
                    Need Help? Connect With Us
                  </TextSosMedStyled>
                  <RowCenter className="mt-12px">
                    <a
                      href="https://www.linkedin.com/company/ibf-net-l-limited"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={linkedinIcon} alt="Linkedin Icon" />
                    </a>
                    <a href="https://t.me/IBFNet" target="_blank" rel="noreferrer">
                      <img src={telegramIcon} alt="Telegram Icon" />
                    </a>
                    <a
                      href="https://wa.me/message/5S3SIUD354WYK1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={whatsappIcon} alt="Whatsapp Icon" />
                    </a>
                    <a href="https://twitter.com/ibfnet" target="_blank" rel="noreferrer">
                      <img src={twitterIcon} alt="Twitter Icon" />
                    </a>
                    <a href="mailto:dev@ibfnet.my" target="_blank" rel="noreferrer">
                      <img src={emailIcon} alt="Email Icon" />
                    </a>
                  </RowCenter>
                </SosMedStyled>
              </Content>
            </Col>
          </CustomRow>
        </Canvas>
      );
    }
  };
  return resolveUI();
}
