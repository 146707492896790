import styled from "@emotion/styled";

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;

  .ant-upload.ant-upload-select {
    display: block;
  }
`;

export const AddNFTStyled = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  /* Shades/White */

  background: #ffffff;
  /* Neutral/700 */

  border: 1px solid #a7b1c0;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 24px;
`;

export const SlotUsedStyled = styled.div`
  /* Secondary/50 */

  background: #dee2e7;
  /* Secondary/200 */

  border: 1px solid #9ca8b8;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 12px;

  /* Caption/All Caps */

  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height, or 117% */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Secondary/600 */

  color: #2f3742;
`;
