import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Layouts from "../../layouts";
import { recheckToken } from "../../modules/auth/action";
import WalletDashboard from "../WalletDashboard";
import WalletTransactions from "../WalletTransactions";
import Onboarding from "../Onboarding";
import Home from "../Home";
import WalletFAQ from "../WalletFAQ";
import Feed from "../Feed";
import FeedDetails from "../FeedDetails";
import UnderMaintenance from "../UnderMaintenance";
import {
  ASSETS_NAME,
  ASSETS_STATUS,
  USER_STATUS,
} from "../../constants/constant_wallet";
import { getConfig, getWalletUser } from "../../modules/wallet/action";
import { setPageMenu, setSection } from "../../modules/general/action";
import { getUserDataById } from "../../modules/benevolence/action";
import { useTranslation } from "react-i18next";
import { IS_EVM, LOGOUT_URL } from "../../configs";
import { useAccount } from "wagmi";
import { useAuth0 } from "@auth0/auth0-react";
import useWindowDimensions from "../../utils/windowDimensions";

// import Conference from "../Conference";
// import CourseDetail from "../CourseDetail";
import Excellence from "../Excellence";
// import BenevolenceWaqfPool from "../BenevolenceWaqfPool";
import Profile from "../Profile";
import BenevolenceVolunteer from "../BenevolenceVolunteer";
import BenevoleceDonate from "../BenevolenceDonate";
import BenevolenceProjectDetail from "../BenevolenceProjectDetail";
import Affluence from "../Affluence";
import AffluenceShopeeListing from "../AffluenceShopeeListing";
import BenevolenceManage from "../BenevolenceManage";
// import ConfluenceDashboard from "../ConfluenceDashboard";
// import ConfluenceOffers from "../ConfluenceOffers";
// import ConfluenceOnBoarding from "../ConfluenceOnBoarding";
// import ConfluenceClaim from "../ConfluenceClaim";
import Activity from "../Activity";
// import ConfluenceProfiles from "../ConfluenceProfiles";
import Credence from "../Credence";
import CredenceNFTDetail from "../CredenceNFTDetail";
import AffluenceShoppeMarketplace from "../AffluenceShoppeMarketplace";
import AffluenceDetailNFT from "../AffluenceDetailNFT";
import AffluenceShoppePurchases from "../AffluenceShoppePurchases";
import AffluenceAuctionMarketplace from "../AffluenceAuctionMarketplace";
import AffluenceAuctionDetailNFT from "../AffluenceAuctionDetailNFT";
import AffluenceAuctionPurchases from "../AffluenceAuctionPurchases";
import AffluenceAuctionOffers from "../AffluenceAuctionOffers";
import AffluenceAuctionListing from "../AffluenceAuctionListing";
import AffluenceRestricted from "../AffluenceRestricted";
import MicrofinanceMyLoans from "../MicrofinanceMyLoans";
import MicrofinanceLoanRequests from "../MicrofinanceLoanRequests";
import AffluenceUser from "../AffluenceUser";
import AffluenceUserCollection from "../AffluenceUserCollection";
import CreateBenevolenceProject from "../CreateBenevolenceProject";
import CreateNFT from "../CreateNFT";
import CredenceNFTSubDetail from "../CredenceNFTSubDetail";
import CredenceEvmOnBoarding from "../CredenceEvmOnBoarding";

export default function Landing(props) {
  const { isConnected } = useAccount();
  const auth0 = useAuth0();

  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const generalSection = useSelector((state) =>
    state.getIn(["general", "section"])
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );
  // const confluenceUser = useSelector((state) =>
  //   state.getIn(["confluence", "user"]).toJS()
  // );

  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let history = useHistory();
  const windowDimensions = useWindowDimensions();
  const { i18n } = useTranslation();
  const [ibfx1, setIbfx1] = useState({
    status: ASSETS_STATUS.INACTIVE,
  });
  const [isHaveMnemonic, setHaveMnemonic] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [isWalletActive, setIsWalletActive] = useState(false);
  const listPageHaveDefaultMenu = ["wallet-dashboard", "credence"];

  const initIbfAssets = async () => {
    const getIbfx1 = allAssets.filter(
      (e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_I
    );

    setIbfx1(getIbfx1[0]);
  };

  const resolveChildren = () => {
    if (generalSection.includes("affluence") && windowDimensions.width < 500) {
      return <AffluenceRestricted />;
    }

    switch (generalSection) {
      case "dashboard":
        return resolveHome();
      case "home":
        return <Home />;
      case "activity":
        return <Activity />;
      case "feed":
        return <Feed />;
      case "feed-details":
        return <FeedDetails />;
      case "wallet-dashboard":
        if (!isWalletActive) {
          return <Onboarding isLoading={isLoading} />;
        }
        return <WalletDashboard />;
      case "wallet-transactions":
        return <WalletTransactions />;
      case "wallet-faq":
        return <WalletFAQ />;
      case "courses":
        return <Excellence />;
      // case "course-detail":
      //   return <CourseDetail />;
      case "profile":
        return <Profile />;
      case "benevolence-volunteer":
        return <BenevolenceVolunteer isLoading={isLoading} />;
      case "benevolence-donate":
        return <BenevoleceDonate isLoading={isLoading} />;
      case "project-detail":
        return <BenevolenceProjectDetail isLoading={isLoading} />;
      case "benevolence-manage":
        return <BenevolenceManage />;
      // case "benevolence-waqf-pool":
      //   return <BenevolenceWaqfPool />;
      // case "conference":
      //   return <Conference />;
      case "credence":
        if (IS_EVM) {
          if (isConnected) {
            return <Credence />;
          } else {
            return <CredenceEvmOnBoarding />;
          }
        }
        else {
          return <Credence />;
        }

      case "credence-nft-detail":
        return <CredenceNFTDetail />;
      // case "confluence-dashboard":
      //   if (
      //     (Object.keys(confluenceUser).length <= 0 ||
      //       Object.keys(walletUser).length <= 0 ||
      //       walletUser.status !== USER_STATUS.ACTIVE ||
      //       ibfx1.status !== ASSETS_STATUS.ACTIVE ||
      //       // ibfx4.status !== ASSETS_STATUS.ACTIVE ||
      //       !isHaveMnemonic) &&
      //     !isLoading
      //   ) {
      //     return <ConfluenceOnBoarding />;
      //   }
      //   return <ConfluenceDashboard />;
      // case "confluence-claim":
      //   if (
      //     (Object.keys(confluenceUser) <= 0 ||
      //       Object.keys(walletUser) <= 0 ||
      //       walletUser.status !== USER_STATUS.ACTIVE ||
      //       ibfx1.status !== ASSETS_STATUS.ACTIVE ||
      //       // ibfx4.status !== ASSETS_STATUS.ACTIVE ||
      //       !isHaveMnemonic) &&
      //     !isLoading
      //   ) {
      //     return <ConfluenceOnBoarding />;
      //   }
      //   return <ConfluenceClaim />;
      // case "confluence-offers":
      //   if (
      //     (Object.keys(confluenceUser) <= 0 ||
      //       Object.keys(walletUser) <= 0 ||
      //       walletUser.status !== USER_STATUS.ACTIVE ||
      //       ibfx1.status !== ASSETS_STATUS.ACTIVE ||
      //       !isHaveMnemonic) &&
      //     !isLoading
      //   ) {
      //     return <ConfluenceOnBoarding />;
      //   }
      //   return <ConfluenceOffers />;
      // case "confluence-profiles":
      //   if (
      //     (Object.keys(confluenceUser) <= 0 ||
      //       Object.keys(walletUser) <= 0 ||
      //       walletUser.status !== USER_STATUS.ACTIVE ||
      //       ibfx1.status !== ASSETS_STATUS.ACTIVE ||
      //       !isHaveMnemonic) &&
      //     !isLoading
      //   ) {
      //     return <ConfluenceOnBoarding />;
      //   }
      //   return <ConfluenceProfiles />;
      case "affluence":
        return <Affluence />;
      case "affluence-auction-marketplace":
        return <AffluenceAuctionMarketplace />;
      case "affluence-buy-sell-purchases":
        return <AffluenceShoppePurchases />;
      case "affluence-buy-sell-marketplace":
        return <AffluenceShoppeMarketplace />;
      case "affluence-buy-sell-listing":
        return <AffluenceShopeeListing />;
      case "affluence-buy-sell-detail-nft":
        return <AffluenceDetailNFT />;
      case "affluence-auction-detail-nft":
        return <AffluenceAuctionDetailNFT />;
      case "affluence-auction-purchases":
        return <AffluenceAuctionPurchases />;
      case "affluence-auction-offers":
        return <AffluenceAuctionOffers />;
      case "affluence-auction-listing":
        return <AffluenceAuctionListing />;
      case "affluence-microfinance-myloans":
        return <MicrofinanceMyLoans />;
      case "affluence-microfinance-loan-requests":
        return <MicrofinanceLoanRequests />;
      case "affluence-user":
        return <AffluenceUser />;
      case "affluence-collection":
        return <AffluenceUserCollection />;
      case "create-project":
        return <CreateBenevolenceProject />;
      case "create-nft":
        return <CreateNFT />;
      case "credence-nft-sub-detail":
        return <CredenceNFTSubDetail />;
      default:
        return <UnderMaintenance />;
    }
  };

  const checkToken = async () => {
    try {
      if (!(await dispatch(recheckToken()))) {
        await auth0.logout({logoutParams: { returnTo: `${LOGOUT_URL}/login` }});
        localStorage.removeItem('USER_AUTH_TOKEN')
        history.push("/login");
        return window.location.reload(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isPathnameHasParam = (param) => {
    return location.pathname.toLowerCase().includes(param) || false;
  };

  const checkPathname = () => {
    if (location.pathname === "/") {
      dispatch(setSection("home"));
    } else if (
      isPathnameHasParam("project-detail") ||
      isPathnameHasParam("affluence-buy-sell-detail-nft") ||
      isPathnameHasParam("affluence-auction-detail-nft") ||
      isPathnameHasParam("feed-details") ||
      isPathnameHasParam("credence-nft-detail") ||
      isPathnameHasParam("credence-nft-sub-detail") ||
      isPathnameHasParam("course-detail") ||
      isPathnameHasParam("affluence-user") ||
      isPathnameHasParam("affluence-collection")
    ) {
      const split = location.pathname.toLowerCase().split("/");
      if (split.length > 2) {
        dispatch(setSection(props.section, split[split.length - 1]));
      } else {
        dispatch(setSection(props.section));
      }
    } else if (location.pathname !== "/") {
      if (
        listPageHaveDefaultMenu.find((i) => isPathnameHasParam(i)) &&
        params.get("menu")
      ) {
        dispatch(setPageMenu({ menu: params.get("menu") }));
      }
      dispatch(setSection(props.section));
    } else {
      checkMenuOpen();
    }
  };

  const checkMenuOpen = async () => {
    const getMenuOpen = localStorage.getItem("MENU_OPEN");
    const splitSection = getMenuOpen ? getMenuOpen.split("|") : [];

    if (splitSection.length > 0) {
      dispatch(setSection(splitSection[0], splitSection[1]));
    }
  };

  const getLanguage = () => localStorage.getItem("LANGUAGE") || i18n.language;

  const initData = async () => {
    const listPage = [
      "courses",
      "course-detail",
      "credence-nft-detail",
      "credence-nft-sub-detail",
      "affluence",
      "marketplace",
      "benevolence-donate",
      "project-detail",
      "home",
      "/"
    ];

    i18n.changeLanguage(getLanguage());

    try {
      if (
        !listPage.find((i) => isPathnameHasParam(i)) ||
        localStorage.getItem("USER_AUTH_TOKEN")
      ) {
        await checkToken();
        if (IS_EVM) {
          setLoading(false);
        } else {
          await dispatch(getWalletUser());
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("ENCRYPTED_MNEMONIC")) {
      setHaveMnemonic(true);
    }
  }, [localStorage.getItem("ENCRYPTED_MNEMONIC")]);

  useEffect(() => {
    checkPathname();
    initData();
    if (localStorage.getItem("USER_AUTH_TOKEN")) {
      dispatch(getUserDataById());
    }
  }, [location]);

  useEffect(() => {
    if (
      (Object.keys(walletUser) <= 0 ||
        walletUser.status !== USER_STATUS.ACTIVE ||
        !isHaveMnemonic) &&
      !isLoading
    ) {
      setIsWalletActive(false);
    } else {
      setIsWalletActive(true);
    }
  }, [walletUser]);

  useEffect(() => {
    if (localStorage.getItem("USER_AUTH_TOKEN")) {
      if (allAssets.length > 0) {
        if (!ibfx1?.asset_name)
          initIbfAssets();
      }
    }
  }, [allAssets]);
 
  useEffect(() => {
    dispatch(getConfig("IBFX_AMOUNT_PER_USD"));
  }, [])

  const resolveHome = () => <></>;

  return (
    <>
      <Layouts>{resolveChildren()}</Layouts>
    </>
  );
}
