import styled from '@emotion/styled'
import { Tabs, Card } from 'antd'

export const ContentDashboard = styled.div`
	min-height: 100vh;
	position: relative;
`

export const TabsStyled = styled(Tabs)`
	// @media screen and (max-width: 834px) {
	// .ant-tabs-nav-wrap {
	// position: fixed;
	// overflow: auto;
	// top: 75px;
	// left: 20px;
	// right: 20px;
	// bottom: 0;
	// }
`

export const ActionTable = styled.div`
	display: 'flex';
	flex-direction: 'columns';
	justify-content: 'space-beetween';
`

export const CardStyled = styled(Card)`
	background: #fcfcfc;
	border: 1px solid #edeff2;
	box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px, rgb(9 30 66 / 14%) 0px 0px 20px -6px;
	border-radius: 4px;

	.title {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 14px;
		letter-spacing: 0.1em;
		text-transform: uppercase;

		color: #526895;
		margin-bottom: 6px;
	}

	.value {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;

		color: #273248;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.icon {
		font-size: 24px;

		&.green {
			color: #38cb89;
		}
		&.red {
			color: #f43c3c;
		}
	}

	img.impact {
		width: 32px;
		height: 32px;
		margin-right: 8px;
	}
`

export const AnyStyled = styled.div`
	/* Neutral/300 */

	background: #f3f4f6;
	/* Neutral/600 */

	border: 1px solid #d6dae1;
	box-sizing: border-box;
	border-radius: 4px;
	width: 32px;
	height: 32px;
	margin-right: 8px;

	display: flex;
	align-items: center;
	justify-content: center;
`
