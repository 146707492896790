export const ASSET_PROJECT_TYPE = {
	FULLY_FUNDED: 'FULLY_FUNDED',
	BEST_EFFORT: 'BEST_EFFORT',
}

export const USER_STATUS = {
	INACTIVE: 'INACTIVE',
	PENDING: 'PENDING',
	ACTIVE: 'ACTIVE',
}

export const USER_GENDER = {
	MALE: 'MALE',
	FEMALE: 'FEMALE',
}

export const PROJECT_STATUS = {
	SUBMITED: 'SUBMITED',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED',
	PENDING: 'PENDING',
	DEPLOYED: 'DEPLOYED',
	STARTING: 'STARTING',
	OPENED: 'OPENED',
	CLOSED: 'CLOSED',
	COMPLETED: 'COMPLETED',
}

export const ACTIVITY_TYPE = {
	DONATION: 'DONATION',
	PROJECT_CREATION: 'PROJECT_CREATION',
	PROJECT_APPROVAL: 'PROJECT_APPROVAL',
	PROJECT_DEPLOYMENT: 'PROJECT_DEPLOYMENT',
	PROJECT_ACTIVATION: 'PROJECT_ACTIVATION',
	PROJECT_COMPLETION: 'PROJECT_COMPLETION',
	DONATION_RECEIVING: 'DONATION_RECEIVING',
}

export const ACTIVITY_TYPE_DESC = {
	DONATION: 'Donation',
	PROJECT_CREATION: 'Project Creation',
	PROJECT_APPROVAL: 'Project Approved',
	PROJECT_DEPLOYMENT: 'Project Deployed',
	PROJECT_ACTIVATION: 'Activate Project',
	PROJECT_COMPLETION: 'Completing Project',
	DONATION_RECEIVING: 'Receive Donation',
}

export const PROJECT_TYPE = {
	ASSET: 'ASSET',
	EFFORT: 'EFFORT',
}

export const VOLUNTEER_STATUS = {
	REQUESTED: 'REQUESTED',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED',
}

export const CONFIG_KEY = {
	IBFX_AMOUNT_PER_USD: 'IBFX_AMOUNT_PER_USD',
	IBFS_AMOUNT_PER_USD: 'IBFS_AMOUNT_PER_USD',
}

export const PROJECT_VOLUNTEER_STATUS = {
	REQUESTED: 'REQUESTED',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED',
	FINISHED: 'FINISHED',
	REVIEWED: 'REVIEWED',
	FINALIZED: 'FINALIZED',
	COMPLETED: 'COMPLETED',
}
