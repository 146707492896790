/* global BigInt */

import CryptoJS from "crypto-js";
export const formatterCurrency = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 5,
});

export const ccFormat = (value) => {
  const vMaskLength = value.length - 4;
  var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  var matches = v.match(/\d{4,16}/g);
  var match = (matches && matches[0]) || "";
  var parts = [];

  for (var i = 0, len = match.length; i < len; i += 4) {
    if (i <= vMaskLength - 1) {
      parts.push("****");
    } else {
      parts.push(match.substring(i, i + 4));
    }
  }

  if (parts.length) {
    return parts.join(" ");
  } else {
    return value;
  }
};

export const toIBFx = (value) => {
  return (
    Number(value) *
    10 ** (process.env.REACT_APP_ENV_VAR === "production" ? 5 : 6)
  );
};

export const fromIBFx = (value) => {
  return (
    Number(value) /
    10 ** (process.env.REACT_APP_ENV_VAR === "production" ? 5 : 6)
  ).toFixed(2);
};

export const fromIBFN = (value) => {
  return (Number(value) / 10 ** 6).toFixed(2);
};

export const toUSD = (value) => {
  return Number(value) * 10 ** 2;
};

export const fromUSD = (value) => {
  return Number(value) / 10 ** 2;
};
export const fromIUSD = (amount) => {
  return amount / 100;
};

export const encryptMnemonic = (str, value) => {
  try {
    const ecryptedMnemonic = CryptoJS.AES.encrypt(
      JSON.stringify({ str }),
      value
    );
    return ecryptedMnemonic;
  } catch (e) {
    console.log(e);
    throw "Something wrong";
  }
};

export const decryptMnemonic = (encryptedMnemonic, value) => {
  try {
    const decryptedMnemonic = CryptoJS.AES.decrypt(
      encryptedMnemonic.toString(),
      value
    );
    return JSON.parse(decryptedMnemonic.toString(CryptoJS.enc.Utf8))["str"];
  } catch (e) {
    console.log(e);
    throw "Wrong password";
  }
};

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const zeroPad = (num, places) => String(num).padStart(places, "0");

export function formatBigNumWithDecimals(num, decimals) {
  if (num && decimals) {
    const singleUnit = BigInt("1" + "0".repeat(decimals));
    const wholeUnits = num / singleUnit;
    const fractionalUnits = num % singleUnit;

    return (
      wholeUnits.toString() +
      "." +
      fractionalUnits.toString().padStart(decimals, "0")
    );
  } else {
    return 0;
  }
}

export function textSubstring(value) {
  return value
    ? value.substring(0, 7) +
        "..." +
        value.substring(value.length - 7, value.length - 1)
    : "";
}

export function textSlice(value) {
  return value.length > 20 ? value.slice(0, 20) + '...' : value
}