import React, { useEffect } from "react";
import { Typography, Tabs, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { TabsStyled, ContentRelative, ButtonAbsolute } from "./styled";
import VolunteerIllustration from "../../assets/images/volunteer-illustration.svg";
import { ColCenter } from "../../components/general_styled";
import BenevolenceAllProjects from "../../components/Benevolenve/AllProjects";
import BenevolenceVolunteered from "../../components/Benevolenve/Voluteered";
import ModalRegisterVolunteer from "../../components/ModalRegisterVolunteer";
import { getUserIsVolunteer } from "../../modules/benevolence/action";
import { openCreateProjectModal } from "../../modules/benevolence_projects/action";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../utils/windowDimensions";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

export default function BenevolenceVolunteer(props) {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const userVolunteer = useSelector((state) =>
    state.getIn(["benevolence", "userVolunteer"]).toJS()
  );
  const isVolunteer = useSelector((state) =>
    state.getIn(["benevolence", "isVolunteers"])
  );
  const dispatch = useDispatch();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const tabCallback = (key) => {};

  const initIsVolunteer = async () => {
    await dispatch(getUserIsVolunteer());
  };

  const onCreateProject = () => {
    dispatch(openCreateProjectModal());
  };

  useEffect(() => {
    initIsVolunteer();
  }, []);

  useEffect(() => {
    initIsVolunteer();
  }, [isVolunteer]);

  return (
    <div className={`px-24px ${!(windowDimensions.width > 900)}`}>
      {isVolunteer && userVolunteer.status === "APPROVED" ? (
        <ContentRelative>
          {windowDimensions.width > 900 && (
            <ButtonAbsolute
              size="large"
              type="primary"
              onClick={onCreateProject}
            >
              {t("create_project")}
            </ButtonAbsolute>
          )}
          <TabsStyled
            defaultActiveKey={
              params.get("defaultActiveKey")
                ? params.get("defaultActiveKey")
                : "all_project"
            }
            size="large"
            onChange={tabCallback}
          >
            <TabPane tab={t("all_projects")} key="all_projects">
              <BenevolenceAllProjects />
            </TabPane>
            <TabPane tab={t("volunteered")} key="volunteered">
              <BenevolenceVolunteered />
            </TabPane>
          </TabsStyled>
        </ContentRelative>
      ) : (
        <>
          <ColCenter style={{ height: "calc(100vh - 10vh)" }}>
            <Image
              src={VolunteerIllustration}
              preview={false}
              style={{
                marginBottom: "16px",
                width: "30vh",
                height: "30vh",
              }}
            />
            <Title level={2} style={{ fontWeight: "bold", margin: 0 }}>
              {userVolunteer.status === "REQUESTED" ? "Approval pending" : t("become_volunteer")}
            </Title>
            <Text align="center" style={{ margin: 0, marginTop: 10 }}>
              {userVolunteer.status === "REQUESTED" ? "We are currently reviewing your application and will get back to you soon on your registered email" : t("become_volunteer_desc")}
            </Text>
            <ModalRegisterVolunteer disabled={userVolunteer.status === "REQUESTED"} />
          </ColCenter>
        </>
      )}
    </div>
  );
}
