import { fromJS } from 'immutable'
import {
	GET_ALL_VOLUNTEER,
	GET_ALL_VOLUNTEER_FAIL,
	GET_ALL_VOLUNTEER_SUCCESS,
	GET_MANAGE_VOLUNTEER,
	GET_MANAGE_VOLUNTEER_FAIL,
	GET_MANAGE_VOLUNTEER_SUCCESS,
	GET_VOLUNTEERED,
	GET_VOLUNTEERED_FAIL,
	GET_VOLUNTEERED_SUCCESS,
	SET_USER_BENEVOLENCE,
	SET_VOLUNTEER,
	SET_VOLUNTEER_REWARD
} from '../constants'

const initialState = fromJS({
	isLoading: false,
	hasError: false,
	errorMessage: '',
	isVolunteers: false,
	userVolunteer: fromJS({}),
	user: fromJS({}),
	manageVolunteers: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	allVolunteers: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	volunteered: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	volunteerReward: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
})

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_BENEVOLENCE:
			return state.merge({
				user: fromJS(action.payload.data),
			})
		case SET_VOLUNTEER:
			return state.merge({
				isVolunteers: fromJS(action.payload.data),
				userVolunteer: fromJS(action.payload.userVolunteer),
			})
		case GET_MANAGE_VOLUNTEER:
			return state.merge({
				isLoading: fromJS(true),
			})
		case GET_MANAGE_VOLUNTEER_SUCCESS:
			return state.merge({
				manageVolunteers: fromJS({
					data: action.payload.data,
					hasNext: action.payload.hasNext,
					currentPage: action.payload.page,
					total: action.payload.total,
				}),
			})
		case SET_VOLUNTEER_REWARD:
			return state.merge({
				volunteerReward: fromJS({
					data: action.payload.data,
					hasNext: action.payload.hasNext,
					currentPage: action.payload.page,
					total: action.payload.total,
				}),
			})
		case GET_MANAGE_VOLUNTEER_FAIL:
			return state.merge({
				isLoading: fromJS(false),
				hasError: fromJS(true),
				errorMessage: fromJS(action.payload.message),
			})
		case GET_ALL_VOLUNTEER:
			return state.merge({
				isLoading: fromJS(true),
			})
		case GET_ALL_VOLUNTEER_SUCCESS:
			const volunteerData = state.getIn(['allVolunteers', 'data']).toJS()

			const volunteerCurrentPage = state.getIn(['allVolunteers', 'currentPage'])

			return state.merge({
				allVolunteers: fromJS({
					data:
						volunteerData.length <= 0 || volunteerCurrentPage < action.payload.page
							? volunteerData.concat(action.payload.data)
							: volunteerData,
					hasNext: action.payload.hasNext,
					currentPage: action.payload.page,
					total: action.payload.total,
				}),
			})
		case GET_ALL_VOLUNTEER_FAIL:
			return state.merge({
				isLoading: fromJS(false),
				hasError: fromJS(true),
				errorMessage: fromJS(action.payload.message),
			})
		case GET_VOLUNTEERED:
			return state.merge({
				isLoading: fromJS(true),
			})
		case GET_VOLUNTEERED_SUCCESS:
			return state.merge({
				volunteered: fromJS({
					data: action.payload.data,
					hasNext: action.payload.hasNext,
					currentPage: action.payload.page,
					total: action.payload.total,
				}),
			})
		case GET_VOLUNTEERED_FAIL:
			return state.merge({
				isLoading: fromJS(false),
				hasError: fromJS(true),
				errorMessage: fromJS(action.payload.message),
			})
		default:
			return state
	}
}
