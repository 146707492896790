import algosdk from "algosdk";

export const DEV = process.env.REACT_APP_ENV_VAR !== "production";
export const LOGIN_V2 = process.env.REACT_APP_ENV_LOGIN_V2 === "true";
// export const IS_EVM = process.env.REACT_APP_ENV_IS_EVM === "false";
export const IS_EVM = localStorage.getItem("IS_EVM") === "true";

export const HOST = DEV
  ? `https://test-api-wallet.ibf.exchange/api`
  : `https://api-wallet.ibf.exchange/api`;

export const MEMBERSHIP_HOST = DEV
  ? // ? `http://localhost:5005/api`
    `https://test-api-membership.ibf.exchange/api`
  : `https://api-membership.ibf.exchange/api`;

export const BENEVOLENCE_HOST = DEV
  ? `https://test-api-benevolence.ibf.exchange/api` // http://localhost:3003/api https://test-api-benevolence.ibf.exchange/api https://api-benevolence.ibf.exchange/api
  : `https://api-benevolence.ibf.exchange/api`;

export const CONFLUENCE_HOST = DEV
  ? `https://test-api-confluence.ibf.exchange/api`
  : `https://api-confluence.ibf.exchange/api`;

export const ACTIVITY_HOST = DEV
  ? `https://test-api-activity.ibf.exchange/api`
  : `https://api-activity.ibf.exchange/api`;

export const CREDENCE_HOST = DEV
  ? `https://test-api-credence.ibf.exchange/api` // `https://test-api-credence.ibf.exchange/api` `http://localhost:3003/api`
  : `https://api-credence.ibf.exchange/api`;

export const ALGO_EXPLORER_URL = DEV
  ? `https://testnet.explorer.perawallet.app`
  : `https://explorer.perawallet.app`;

export const POLYGON_EXPLORER_URL = DEV
  ? `https://mumbai.polygonscan.com/address`
  : `https://polygonscan.com/address`;

export const AUTH0_REDIRECT = DEV
  ? // ? "http://localhost:3000/login-callback"
    "https://test-membership.ibfnex.io/login-callback"
  : // "https://demo-ibf-nex-web.vercel.app/login-callback"
    // "http://localhost:3000/login-callback";
    "https://ibfnex.io/login-callback";

export const LOGOUT_URL = DEV
  ? "https://test-membership.ibfnex.io/login" // "http://localhost:3000" "https://test-membership.ibfnex.io/"
  : "https://ibfnex.io"; // "http://localhost:3000" "https://ibfnex.io"

const basicAuth =
  "Basic MDRhMjc2NGQtODUzOS0xMWViLWJlMmQtOGM4Y2FhMmJiZjhiOmZmTlVDSVIxRzFvWW1JTXk=";
export const headers = {
  "Content-Type": "application/json",
  Authorization: basicAuth,
};

export const membershipBasicAuth =
  "Basic YjEyY2YzYTYtOTIwZC0xMWViLWE4YjMtMDI0MmFjMTMwMDAzOjFCRk0zTUJFUlNISVAxMjNAQQ==";
export const membershipHeaders = {
  "Content-Type": "application/json",
  Authorization: membershipBasicAuth,
};

const benevolenceBasicAuth =
  "Basic ZDRlZDA2OTEtYTFiOC0xMWViLTkwYjAtNGQxNDMyZjZkMzk4OmFRUGpkQWl3L0JVeWU5enVlSXhWcUxOTUNjUy9LdUlDUlRScEJxZ3hGVG89";
export const benevolenceHeaders = {
  "Content-Type": "application/json",
  Authorization: benevolenceBasicAuth,
};

export const stripeKey = DEV
  ? "pk_test_51IFFOCJKe2iPq4jPOeiDLPiarFkCQiY1ZrEvcAub5EE9pbwCk8LiMvn1vUCh5OfTrnRrL7A1Bw6YOHrdP7y6zmzp00tjhZ7aPo"
  : "pk_live_51IFFOCJKe2iPq4jPSc4lykLy9QmfRHFJnVQt2dR63kdkTcuzkffyQwZU8hWRKn5P6auoxJYB4hPccxaCCkkwSr5c009CZ4yU3o";

const algoClientUrl = DEV
  ? "https://testnet-api.algonode.cloud"
  : "https://mainnet-api.algonode.cloud";
const algoClientPort = "";
const algoClientToken = {
  "X-API-Key": "gRuUTaWQ1c29etSbxXBLla7uX8a0hIQl1PZho8X9",
};
export const algodClient = new algosdk.Algodv2(
  "",
  algoClientUrl,
  algoClientPort
);

export const ibfnTokenAddress = DEV
  ? "0x8E8B859eBE9AB3f25771678E469d8C0B185FBDbe"
  : "0xc2e9312573DecA65346716dc0B54E13620500c43";

export const ibfnftGeneraltAddress = DEV
  ? "0x81bdCde7aF6a5ccFd0BdE0A07aa26A5Ed30D58BD"
  : "";

export const nftFactoryAddress = DEV
  ? "0x14489a07f5516166609B9158A58712aA9B46CB73"
  : "0xb3aa3891061233cbb7b40050672b2512275f23b5";

export const nftAirdropAddress = DEV
  ? "0x76bcD905407a458Bc5E9b61A9Ca5b8b7A91Af7A9"
  : "0x40aac30ed23a05cf541083788753b83e4b52a924";

export const chainIdPolygon = DEV ? 80001 : 137;

export const nftMarketplaceAddress = DEV
  ? "0xdEb798A1C8a7D24455C8E7358948E97FFdd68266"
  : "0xfa5071c1857739fdad6854faa81df006f0f911aa";

export const addressNull = "0x0000000000000000000000000000000000000000";

export const paymentList = DEV
  ? [
      { key: "IBFN", value: "0x94b5A80890Ae86a45f045617afcf7703c61A203E" },
      { key: "USDT", value: "0x3BfaAd6e0F4e8529e5aA8887081ce3a2A4B5FE3d" },
    ]
  : [
      { key: "IBFN", value: "0xc2e9312573DecA65346716dc0B54E13620500c43" },
      { key: "USDT", value: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f" },
    ];

export const benevolenceDonationAddress = DEV
  ? "0xB4CCD0ab687A6C73fc88b1fE971785a6Ee3F9B64"
  : "";

export const coinbaseHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "X-CC-Version": process.env.REACT_APP_ENV_COINBASE_API_VER,
  "X-CC-Api-Key": process.env.REACT_APP_ENV_COINBASE_API_KEY,
};
