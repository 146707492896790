import styled from "@emotion/styled"

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;

  @media screen and (min-width: 835px) {
    min-height: 90vh;
  }

  .ant-upload.ant-upload-select {
    display: block;
  }
`
