import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { Col, Typography, notification, Button, Image } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"

import { Canvas, CustomRow, ImageLogin, Content, FormCustom } from "./styled"
import mosqueImage from "../../assets/images/bg_landing.jpg"
import { recheckToken, updatePassword } from "../../modules/auth/action"
import { LoadingOutlined } from "@ant-design/icons"
import { useHistory } from "react-router"

import logoColor from "../../assets/images/logo-beta-black.png"
import { InputPasswordStyled } from "../../components/general_styled"
import { extractErrorMessage } from "../../utils/extractErrorMessage"

const { Title, Text } = Typography

export default function NewPassword() {
  const stateAuth = useSelector((state) => state.getIn(["auth"]).toJS())
  const stateForgotPassword = useSelector((state) =>
    state.getIn(["auth", "forgotPassword"]).toJS()
  )
  const dispatch = useDispatch()
  const history = useHistory()

  const [password, setPassword] = useState("")
  const [retypePassword, setRetypePassword] = useState("")

  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(recheckToken())
  }, [])

  const onSubmitClicked = async () => {
    try {
      setLoading(true)
      const result = await dispatch(
        updatePassword({ password, jwtToken: stateForgotPassword.token })
      )

      if (result.code === "SUCCESS") {
        notification.success({
          message: "Success!",
          description: "You have successfully update password",
        })
        history.push("/")
      } else {
        notification.error({
          message: "Failed!",
          description: result,
        })
      }
    } catch (e) {
      console.log(e)
      notification.error({
        message: "Failed!",
        description: extractErrorMessage(e),
      })
    } finally {
      setLoading(false)
    }
  }

  const onFieldChange = (e) => {
    if (e.target.name === "retype-password") {
      setRetypePassword(e.target.value)
    } else if (e.target.name === "password") {
      setPassword(e.target.value)
    }
  }

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  )

  const resolveUI = () => {
    if (stateAuth.isAuthorized) {
      return <Redirect to="/" />
    }
    
    return (
      <Canvas>
        <CustomRow>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <ImageLogin img={mosqueImage} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Content>
              <Image
                src={logoColor}
                width={204}
                style={{ marginBottom: "36px" }}
                preview={false}
              />
              <Title level={2} style={{ marginBottom: 0 }}>
                New Password
              </Title>
              <Text>Create new password for your password</Text>
              <Text className="mt-10px" strong>
                {" "}
                {stateForgotPassword.email || "..."}
              </Text>
              <FormCustom>
                <InputPasswordStyled
                  name="password"
                  size="large"
                  placeholder="Password"
                  onChange={onFieldChange}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
                <InputPasswordStyled
                  name="retype-password"
                  size="large"
                  placeholder="Re-enter Password"
                  onChange={onFieldChange}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  style={{ marginTop: 10, marginBottom: "20px" }}
                />
                <Button
                  type="primary"
                  block
                  disabled={
                    !(
                      password !== "" &&
                      retypePassword !== "" &&
                      retypePassword === password
                    )
                  }
                  style={{ marginTop: 10, marginBottom: 36 }}
                  size="large"
                  onClick={onSubmitClicked}
                >
                  {isLoading ? loadingIcon : "Confirm"}
                </Button>
              </FormCustom>
            </Content>
          </Col>
        </CustomRow>
      </Canvas>
    )
  }

  return resolveUI()
}
