import { fromJS } from "immutable";
import { CLOSE_REDEEM_VOUCHER_MODAL, OPEN_REDEEM_VOUCHER_MODAL } from "../constants";

const initialState = fromJS({
  isModalVisible : false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_REDEEM_VOUCHER_MODAL:
      return state.merge({
        isModalVisible: true,
      });
    case CLOSE_REDEEM_VOUCHER_MODAL:
      return state.merge({
        isModalVisible: false,
      });
    default:
      return state;
  }
};
