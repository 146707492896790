import { CLOSE_IMPORT_MNEMONIC_MODAL, OPEN_IMPORT_MNEMONIC_MODAL } from "../constants"

export const openImportMnemonicModal = (title) => (dispatch) => {
  dispatch({
    type: OPEN_IMPORT_MNEMONIC_MODAL,
    payload: {
      title
    }
  })
}

export const closeImportMnemonicModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_IMPORT_MNEMONIC_MODAL
  })
}