import { fromJS } from "immutable"
import {
  GET_ANNOUNCEMENT,
  GET_ANNOUNCEMENT_FAIL,
  GET_ANNOUNCEMENT_SUCCESS,
} from "../constants"

const initialState = fromJS({
  isLoading: false,
  hasError: false,
  errorMessage: "",
  announcements: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    total: 0,
  }),
})

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENT:
      return state.merge({
        isLoading: fromJS(true),
      })
    case GET_ANNOUNCEMENT_SUCCESS:
      const announcementData = state.getIn(["announcements", "data"]).toJS();

      const announcementCurrentPage = state.getIn([
        "announcements",
        "currentPage",
      ]);

      return state.merge({
        announcements: fromJS({
          data:
            announcementData.length <= 0 ||
              announcementCurrentPage < action.payload.page
              ? announcementData.concat(action.payload.data)
              : announcementData,
          hasNext: action.payload.hasNext,
          currentPage: action.payload.page,
          total: action.payload.total,
        }),
      });
    case GET_ANNOUNCEMENT_FAIL:
      return state.merge({
        isLoading: fromJS(false),
        hasError: fromJS(true),
        errorMessage: fromJS(action.payload.message),
      })
    default:
      return state
  }
}
