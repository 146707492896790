export const GET_ASSETS = `GET_ASSETS`;
export const GET_ASSETS_SUCCESS = `GET_ASSETS_SUCCESS`;
export const GET_ASSETS_FAILED = `GET_ASSETS_FAILED`;

export const GET_TRANSACTION_HISTORY = `GET_TRANSACTION_HISTORY`;
export const GET_TRANSACTION_HISTORY_SUCCESS = `GET_TRANSACTION_HISTORY_SUCCESS`;
export const GET_TRANSACTION_HISTORY_FAILED = `GET_TRANSACTION_HISTORY_FAILED`;

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const SET_FORGOT_PASSWORD = "SET_FORGOT_PASSWORD";

export const SET_USER_DATA = "SET_USER_DATA";

export const SET_USER_BENEVOLENCE = "SET_USER_BENEVOLENCE";

export const SET_REGISTER = "SET_REGISTER";

export const SET_WALLET_USER = "SET_WALLET_USER";
export const SET_IBFX_AMOUNT_PER_USD = "SET_IBFX_AMOUNT_PER_USD";

export const GET_ANNOUNCEMENT = `GET_ANNOUNCEMENT`;
export const GET_ANNOUNCEMENT_SUCCESS = `GET_ANNOUNCEMENT_SUCCESS`;
export const GET_ANNOUNCEMENT_FAIL = `GET_ANNOUNCEMENT_FAIL`;

export const GET_COURSES = `GET_COURSES`;
export const GET_COURSES_SUCCESS = `GET_COURSES_SUCCESS`;
export const GET_COURSES_FAIL = `GET_COURSES_FAIL`;
export const SELECT_COURSE = `SELECT_COURSE`;

export const UPDATE_WISHLIST = "UPDATE_WISHLIST";
export const GET_COURSE_WISHLIST = "GET_COURSE_WISHLIST";

export const ADD_CART = "ADD_CART";
export const REMOVE_CART = "REMOVE_CART";
export const OPEN_CART = "OPEN_CART";
export const CLOSE_CART = "CLOSE_CART";

export const GET_BENEVOLENCE_PROJECTS = "GET_BENEVOLENCE_PROJECTS";
export const GET_BENEVOLENCE_PROJECTS_SUCCESS =
  "GET_BENEVOLENCE_PROJECTS_SUCCESS";
export const GET_BENEVOLENCE_PROJECTS_FAIL = "GET_BENEVOLENCE_PROJECTS_FAIL";
export const OPEN_CREATE_PROJECT_MODAL = "OPEN_CREATE_PROJECT_MODAL";
export const CLOSE_CREATE_PROJECT_MODAL = "CLOSE_CREATE_PROJECT_MODAL";

export const GET_BENEVOLENCE_DONORS = "GET_BENEVOLENCE_DONORS";
export const GET_BENEVOLENCE_DONORS_SUCCESS = "GET_BENEVOLENCE_DONORS_SUCCESS";
export const GET_BENEVOLENCE_DONORS_FAIL = "GET_BENEVOLENCE_DONORS_FAIL";

export const GET_MANAGE_VOLUNTEER = `GET_MANAGE_VOLUNTEER`;
export const GET_MANAGE_VOLUNTEER_SUCCESS = `GET_MANAGE_VOLUNTEER_SUCCESS`;
export const GET_MANAGE_VOLUNTEER_FAIL = `GET_MANAGE_VOLUNTEER_FAIL`;

export const GET_ALL_VOLUNTEER = `GET_ALL_VOLUNTEER`;
export const GET_ALL_VOLUNTEER_SUCCESS = `GET_ALL_VOLUNTEER_SUCCESS`;
export const GET_ALL_VOLUNTEER_FAIL = `GET_ALL_VOLUNTEER_FAIL`;

export const GET_VOLUNTEERED = `GET_VOLUNTEERED`;
export const GET_VOLUNTEERED_SUCCESS = `GET_VOLUNTEERED_SUCCESS`;
export const GET_VOLUNTEERED_FAIL = `GET_VOLUNTEERED_FAIL`;

export const SET_VOLUNTEER = "SET_VOLUNTEER";
export const SET_VOLUNTEER_REWARD = "SET_VOLUNTEER_REWARD";

export const GET_ASSET_PROJECTS = "GET_ASSET_PROJECTS";
export const GET_ASSET_PROJECTS_SUCCESS = "GET_ASSET_PROJECTS_SUCCESS";
export const GET_ASSET_PROJECTS_FAIL = "GET_ASSET_PROJECTS_FAIL";

export const OPEN_REDEEM_VOUCHER_MODAL = "OPEN_REDEEM_VOUCHER_MODAL";
export const CLOSE_REDEEM_VOUCHER_MODAL = "CLOSE_REDEEM_VOUCHER_MODAL";

export const SET_SECTION = "SET_SECTION";

export const OPEN_IMPORT_MNEMONIC_MODAL = "OPEN_IMPORT_MNEMONIC_MODAL";
export const CLOSE_IMPORT_MNEMONIC_MODAL = "CLOSE_IMPORT_MNEMONIC_MODAL";

export const SET_CONFLUENCE_USER = "SET_CONFLUENCE_USER";
export const SET_CONFLUENCE_PROJECT = "SET_CONFLUENCE_PROJECT";
export const SET_CONFLUENCE_STATISTIC = "SET_CONFLUENCE_STATISTIC";
export const SET_CONFLUENCE_IMPACT = "SET_CONFLUENCE_IMPACT";
export const SET_CONFLUENCE_IMPACTS = "SET_CONFLUENCE_IMPACTS";
export const SET_CONFLUENCE_SDG = "SET_CONFLUENCE_SDG";
export const SET_CONFLUENCE_COUNTRY = "SET_CONFLUENCE_COUNTRY";
export const SET_CONFLUENCE_STATE = "SET_CONFLUENCE_STATE";
export const SET_CONFLUENCE_PROFILES = "SET_CONFLUENCE_PROFILES";
export const SET_CONFLUENCE_LOADING = "SET_CONFLUENCE_LOADING";
export const SET_CONFLUENCE_IMPACTS_DASHBOARD =
  "SET_CONFLUENCE_IMPACTS_DASHBOARD";
export const SET_CONFLUENCE_PROJECTS_DASHBOARD =
  "SET_CONFLUENCE_PROJECTS_DASHBOARD";
export const SET_CONFLUENCE_SELLER = "SET_CONFLUENCE_SELLER";

export const SET_RECENT_ACTIVITY = "SET_RECENT_ACTIVITY";

export const SET_CREDENCE_USER = "SET_CREDENCE_USER";
export const SET_CREDENCE_NFTS = "SET_CREDENCE_NFTS";
export const SET_CREDENCE_PURCHASED = "SET_CREDENCE_PURCHASED";
export const SET_CREDENCE_DETAIL = "SET_CREDENCE_DETAIL";
export const SET_CREDENCE_COLLECTION = "SET_CREDENCE_COLLECTION";
export const SET_USER_COLLECTION = "SET_USER_COLLECTION";
export const SET_CREDENCE_COLLECTION_DETAIL = "SET_CREDENCE_COLLECTION_DETAIL";
export const SET_CREDENCE_PERCENTAGE = "SET_CREDENCE_PERCENTAGE";

export const OPEN_MODAL_CREATE_NFT = "OPEN_MODAL_CREATE_NFT";
export const CLOSE_MODAL_CREATE_NFT = "CLOSE_MODAL_CREATE_NFT";

export const OPEN_LOGIN_REGISTER_MODAL = "OPEN_LOGIN_REGISTER_MODAL";
export const CLOSE_LOGIN_REGISTER_MODAL = "CLOSE_LOGIN_REGISTER_MODAL";

export const SET_ENROLLED = "SET_ENROLLED";

export const OPEN_SUCCESS_COURSE_MODAL = "OPEN_SUCCESS_COURSE_MODAL";
export const CLOSE_SUCCESS_COURSE_MODAL = "CLOSE_SUCCESS_COURSE_MODAL";

export const SET_ESSENCE = "SET_ESSENCE";
export const SET_DETAIL_ESSENCE = "SET_DETAIL_ESSENCE";
export const SET_PRICE_HISTORY_ESSENCE = "SET_PRICE_HISTORY_ESSENCE";
export const SET_ESSENCE_PURCHASES = "SET_ESSENCE_PURCHASES";
export const UPDATE_ESSENCE_WISHLIST = "UPDATE_ESSENCE_WISHLIST";
export const SET_ESSENCE_ONSALE = "SET_ESSENCE_ONSALE";

export const SET_AFFLUENCE = "SET_AFFLUENCE";
export const SET_DETAIL_AFFLUENCE = "SET_DETAIL_AFFLUENCE";
export const SET_OFFER = "SET_OFFER";
export const SET_OWNED_AUCTION = "SET_OWNED_AUCTION";
export const SET_PRICE_HISTORY_AUCTION = "SET_PRICE_HISTORY_AUCTION";
export const SET_AVAILABLE_NFT = "SET_AVAILABLE_NFT";
export const UPDATE_AUCTION_WISHLIST = "UPDATE_AUCTION_WISHLIST";

export const SET_ALL_LOANS = "SET_ALL_LOANS";
export const SET_LOAN_BY_DETAIL = "SET_LOAN_BY_DETAIL";
export const SET_LOAN_REQUESTS = "SET_LOAN_REQUESTS";

export const SET_VISITED_USER = "SET_VISITED_USER";
export const SET_SELECTED_COLLECTION = "SET_SELECTED_COLLECTION";

export const SET_PAGE_MENU = "SET_PAGE_MENU";

export const SET_BENEVOLENCE_PROJECT_TYPE = "SET_BENEVOLENCE_PROJECT_TYPE";
export const SET_USER_SIGN = "SET_USER_SIGN";

export const FAILED_CREDENCE_NFTS_SUB = "FAILED_CREDENCE_NFTS_SUB";
export const LOADING_CREDENCE_NFTS_SUB = "LOADING_CREDENCE_NFTS_SUB";
export const SET_CREDENCE_NFTS_SUB = "SET_CREDENCE_NFTS_SUB";
