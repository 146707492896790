import styled from "@emotion/styled";
import { Row, Typography, Select } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;
const { Option } = Select;

export const Canvas = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const CustomRow = styled(Row)`
  width: 100%;
`;

export const ImageLogin = styled.div`
  background-size: cover;
  background-image: url(${(props) => props.img});

  @media screen and (min-width: 835px) {
    width: 100%;
    height: 100vh;
  }

  @media screen and (max-width: 834px) {
    width: 100%;
    height: 30vh;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 835px) {
    height: 100vh;
    // padding: 0 24px;
  }

  @media screen and (max-width: 834px) {
    min-height: 70vh;
    padding: 24px 0;
  }
`;

export const FormCustom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  @media screen and (min-width: 835px) {
    width: 60%;
  }

  @media screen and (max-width: 834px) {
    width: 80%;
  }
`;

export const LinkStyled = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Secondary/500 */

  color: #3b4552;
`;
export const TextStyled = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;

  color: #273248;
`;

export const BlueLink = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Daybreak Blue/blue-6 */

  color: #024FF1;
`;

export const SelectStyled = styled(Select)`
  background: #f4f6f7;

  border: none;
  box-sizing: border-box;
  border-radius: 2px;
  height: 43px;

  .ant-select-selector {
    background: #f4f6f7 !important;
    height: 40px !important;
  }

  .ant-select-selection-item {
    line-height: 37px !important;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
  }
`;

export const OptionStyled = styled(Option)`
  background: #f4f6f7;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 2px;

  padding: 8px 12px;

  input {
    background: #f4f6f7;
  }

  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Text/200 */

    color: #526895;
  }
`;
