import styled from "@emotion/styled";
import { Divider, Typography } from "antd";

export const UnselectedMnemonicStyled = styled.span`
  flex-grow: 1;
  margin: 0px 12px;
  margin-left: 0px;
  width: 234px;
  max-width: 100%;

  background: #fcfcfc;
  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 2px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #44567b;

  @media screen and (max-width: 834px) {
    margin: 0;
  }

  &:last-child {
    margin-right: 0px;
  }
`;

export const SelectedMnemonicStyled = styled(UnselectedMnemonicStyled)`
  border: 1px solid #024FF1;
  color: #024FF1;
`;
