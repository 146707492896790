import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Avatar } from "antd";
import { BENEVOLENCE_HOST } from "../../configs";
import userDummy from "../../assets/images/default-avatar.png";
import closeIconModal from "../../assets/images/close-modal.svg";
import { useSelector } from "react-redux";

export default function ModalVolunteered(props) {
  const {selectedVolunteer} = props
  const userData = useSelector((state) =>
    state.getIn(["auth", "userData"]).toJS()
  );

  console.log(props?.selectedVolunteer);

  return (
    <>
      <Modal
        centered
        visible={props.isModalOpen}
        onCancel={() => props.setModalOpen(false)}
        closeIcon={
          <img src={closeIconModal} onClick={() => props.setModalOpen(false)} />
        }
        width={"70%"}
        footer={null}
      >
        <div className="canvas-detail-user">
          <h5>Volunteer Detail</h5>
          <p>
            View volunteering details for project{" "}
            {selectedVolunteer?.Project?.name}
          </p>

          <div className="volunteer-data">
            <div className="main-identity pt-12px">
              <div className="row">
                <Avatar
                  size={40}
                  className="img-avatar"
                  src={
                    userData?.photo
                      ? `${BENEVOLENCE_HOST}/files/${userData?.photo}`
                      : userDummy
                  }
                />
                <div className="col mt-4px">
                  <h6 className="name">{userData?.name}</h6>
                  <span className="email">{userData?.email}</span>
                </div>
              </div>
            </div>
          </div>
          <table className="skills-2">
            <tr>
              <th className="th">Status</th>
              <th className="th">Rating</th>
              <th className="th">Description</th>
            </tr>
            <tr>
              <td style={{ textTransform: "capitalize" }}>
                {selectedVolunteer?.status}
              </td>
              <td>{selectedVolunteer?.rating || '-'}</td>
              <td>{selectedVolunteer?.rejection_reason || selectedVolunteer?.review || '-'}</td>
            </tr>
          </table>
        </div>
      </Modal>
    </>
  );
}
