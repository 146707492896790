import React, { useState, useEffect } from "react";
import { ColCenter } from "../../components/general_styled";
import { CardStyled, CanvasCenter, ButtonStyled, Divider } from "./styled";
// import BenevLogo from "../../assets/images/benevolence-logo.png"
import { Typography, Col, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSection } from "../../modules/general/action";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getCredenceUserById,
  registerUserCredence,
} from "../../modules/credence/action";
import {
  ASSETS_NAME,
  ASSETS_STATUS,
  USER_STATUS,
} from "../../constants/constant_wallet";

const { Title, Text } = Typography;
export default function CredenceOnBoarding() {
  const dispatch = useDispatch();
  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const credenceUser = useSelector((state) =>
    state.getIn(["credence", "user"]).toJS()
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );

  const [isLoading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const onButtonClicked = async () => {
    const getIbfx1 = allAssets.find(
      (e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_I
    );

    if (
      Object.keys(walletUser).length <= 0 ||
      walletUser.status !== USER_STATUS.ACTIVE ||
      (getIbfx1 && getIbfx1.status !== ASSETS_STATUS.ACTIVE)
    ) {
      dispatch(setSection("wallet-dashboard"));
    } else if (Object.keys(credenceUser).length <= 0) {
      try {
        setLoading(true);
        await registerUserCredence(walletUser.algo_address, true);
        // console.log({resultCreateWallet: result})
        // if (result.code === "SUCCESS") {
        dispatch(getCredenceUserById(walletUser.user_id));
        dispatch(setSection("credence"));
        // } else {
        //   notification.error({
        //     message: "Register Credence Failed!",
        //     description: result.message,
        //   })
        // }
      } catch (e) {
        notification.error({
          message: "Register Credence Failed!",
          description: e,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    dispatch(getCredenceUserById(walletUser.user_id));
  }, []);

  return (
    <CanvasCenter>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <CardStyled>
          <ColCenter className="start">
            <Title
              align="left"
              level={3}
              style={{
                fontWeight: "bold",
                margin: 0,
                marginBottom: 8,
                fontSize: 26,
              }}
            >
              Create Shariah-Compliant <br />
              Digital Assets Using NFTs
            </Title>
            <Text align="left" level={5} style={{ margin: 0 }}>
              Preserve tangible and intangible assets - certificates, legal
              documents, works of art, collectibles and any valuables by
              creating transparent, verifiable, and traceable digital records of
              ownership with secure and automatic implementation of smart
              contracts.
            </Text>
            <Text
              align="left"
              italic
              style={{
                fontSize: 13,
                margin: 0,
                marginTop: 12,
                color: "red",
              }}
            >
              Requirements: Activation of IBFX in Wallet
            </Text>
            <ButtonStyled size="large" block onClick={onButtonClicked}>
              {isLoading ? antIcon : "Start Using Credence"}
            </ButtonStyled>
          </ColCenter>
        </CardStyled>
      </Col>
    </CanvasCenter>
  );
}
