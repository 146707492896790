import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Select } from "antd";
import {
  ButtonStyled,
  ColCenter,
  ColStart,
  RowEnd,
  RowSpaceBetween,
  RowStart,
  SelectStyled,
} from "../../components/general_styled";
import {
  BannerContent,
  BannerStyled,
  BannerSubtitle,
  BannerTitle,
  BannerTrending,
  SearchStyled,
} from "./styled";
import CardShoppe from "../../components/CardShoppe";
import ModalAffluenceSellNft from "../../components/ModalAffluenceSellNft";
import { getCredenceNFTByUserId } from "../../modules/credence/action";
import { useDispatch, useSelector } from "react-redux";
import { getAllEssence, getAllEvmEssence } from "../../modules/essence/action";
import ShimmerCustom from "../../components/ShimmerCustom";
import useWindowDimensions from "../../utils/windowDimensions";
import { useTranslation } from "react-i18next";
import { IS_EVM } from "../../configs";
import { EVM_TRANSACTION_STATUS } from "../../constants/constant_credence";

const { Title, Text } = Typography;

export default function AffluenceShoppeMarketplace() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const windowDimensions = useWindowDimensions();
  const [searchVal, setSearchVal] = useState(null);
  // const [isBottom, setIsBottom] = useState(false)
  const [isLoading, setLoading] = useState(true);

  const EssencePageNfts = useSelector((state) =>
    state.getIn(["essence", "nfts", "currentPage"])
  );
  const EssenceNftsHasNext = useSelector((state) =>
    state.getIn(["essence", "nfts", "hasNext"])
  );
  const CredencePageNfts = useSelector((state) =>
    state.getIn(["credence", "nfts", "currentPage"])
  );
  const EssenceNfts = useSelector((state) =>
    state.getIn(["essence", "nfts", "data"]).toJS()
  );

  const handleChangeDropdown = async (val) => {
    setLoading(true);
    if (val !== "ALL_CATEGORY") {
      await dispatch(getAllEssence(null, 1, null, null, val));
    } else {
      await dispatch(getAllEssence(null, 1));
    }
    setLoading(false);
  };

  const initData = async () => {
    if (IS_EVM) {
      await dispatch(
        getAllEvmEssence({
          //   ownerAddress: address,
          status: EVM_TRANSACTION_STATUS.ON_SALE,
        })
      );
    } else {
      await dispatch(getAllEssence(null, 1));
      await dispatch(getCredenceNFTByUserId(null, CredencePageNfts));
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    initData();
  }, []);

  const renderSearchBar = () => {
    if (windowDimensions.width > 835) {
      return (
        <RowEnd>
          <SelectStyled
            className="ml-12px mr-12px"
            showSearch
            placeholder="Type Assets"
            defaultValue="ALL_CATEGORY"
            style={{
              width: "160px",
            }}
            onSelect={handleChangeDropdown}
          >
            <Select.Option value="ALL_CATEGORY">All Categories</Select.Option>
            <Select.Option value="ARTWORK">Artwork</Select.Option>
            <Select.Option value="AUDIO">Audio</Select.Option>
            <Select.Option value="EBOOK">E-Books</Select.Option>
          </SelectStyled>
          <ModalAffluenceSellNft />
        </RowEnd>
      );
    } else {
      return (
        <Row className="w-100" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24}>
            {/* <SearchStyled
							className='search-input'
							placeholder='Search'
							autoComplete='off'
							allowClear
							size='large'
							onSearch={(value) => {
								setSearchVal(value)
								dispatch(getAllEssence(null, 1, value))
							}}
							style={{
								width: '100%',
							}}
						/> */}
          </Col>

          <Col xs={24} sm={24} md={24}>
            <SelectStyled
              showSearch
              placeholder="Type Assets"
              defaultValue="ALL_CATEGORY"
              style={{
                width: "100%",
              }}
              onSelect={handleChangeDropdown}
            >
              <Select.Option value="ALL_CATEGORY">All Categories</Select.Option>
              <Select.Option value="ARTWORK">Artwork</Select.Option>
              <Select.Option value="AUDIO">Audio</Select.Option>
              <Select.Option value="EBOOK">E-Books</Select.Option>
            </SelectStyled>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <ModalAffluenceSellNft fullWidth />
          </Col>
        </Row>
      );
    }
  };

  const resolveShimmer = () => {
    let children = [];
    const limit = EssenceNfts?.length || 9;
    for (let i = 0; i < limit; i++) {
      children.push(
        <Col
          key={i}
          xs={24}
          sm={24}
          md={windowDimensions.width > 925 ? 12 : 24}
          lg={12}
          xl={8}
          xxl={6}
        >
          <ShimmerCustom width="100%" height="322px" borderRadius="2px" />
          <ShimmerCustom
            width="80%"
            height="20px"
            borderRadius="2px"
            className="mt-12px"
          />
          <ShimmerCustom width="60%" height="12px" borderRadius="2px" />
        </Col>
      );
    }

    return children;
  };

  const renderHeader = () => {
    if (windowDimensions.width > 835) {
      return (
        <RowSpaceBetween className="mr-12px ml-12px">
          {searchVal ? (
            <ColStart>
              <RowStart className="mb-0px">
                <Text className="text-size-16">{t("search_for")} </Text>
                <Text className="text-size-16 text-w600">
                  &nbsp;"{searchVal}"
                </Text>
              </RowStart>
              <Text className="text-size-12">
                {EssenceNfts?.length} {t("nft_found")}
              </Text>
            </ColStart>
          ) : (
            <Title level={3} className="mb-0px mt-0px">
              {t("marketplace")}
            </Title>
          )}
          {renderSearchBar()}
        </RowSpaceBetween>
      );
    } else {
      return (
        <ColCenter className="w-100">
          {searchVal ? (
            <RowSpaceBetween className="w-100 mb-16px">
              <RowStart className="mb-0px">
                <Text className="text-size-22">{t("search_for")} </Text>
                <Text className="text-size-22 text-w600">
                  &nbsp;"{searchVal}"
                </Text>
              </RowStart>
              <Text className="text-size-16">
                {EssenceNfts?.length} {t("nft_found")}
              </Text>
            </RowSpaceBetween>
          ) : (
            <Title level={1} className="mb-16px mt-0px">
              {t("marketplace")}
            </Title>
          )}
          {renderSearchBar()}
        </ColCenter>
      );
    }
  };

  return (
    <>
      <ColStart>
        <Row className="mb-24px">
          <BannerStyled className="mr-12px ml-12px">
            <BannerContent>
              <BannerTitle className="white">
                {t("affluence_banner_title")}
              </BannerTitle>
              <BannerSubtitle>{t("affluence_banner_subtitle")}</BannerSubtitle>
              <SearchStyled
                className="search-input mt-12px"
                placeholder="Search"
                autoComplete="off"
                allowClear
                size="large"
                onSearch={(value) => dispatch(getAllEssence(null, 1, value))}
              />
              <BannerTrending className="mt-12px">
                {t("affluence_banner_trending")}
                <span style={{ fontWeight: "lighter" }}>
                  Sunset, Islamic Book, Maldives, Leadership
                </span>
              </BannerTrending>
            </BannerContent>
          </BannerStyled>
        </Row>
        <div>
          {renderHeader()}
          {isLoading ? (
            <Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
              {resolveShimmer()}
            </Row>
          ) : (
            <Row className="mt-20px">
              {EssenceNfts?.map((data) => {
                if (data.NFT) {
                  const metadata = JSON.parse(data.NFT?.metadata_json || "{}");
                  return (
                    <CardShoppe
                      sell_escrow_id={data.sell_escrow_id || data.trx_id}
                      data={data}
                      name={metadata?.name}
                      collectionName={metadata?.collection?.name}
                      price={data.price}
                      ipfsHash={metadata.image.replace("ipfs://", "")}
                      is_pdf={metadata.is_pdf || false}
                    />
                  );
                }
              })}
            </Row>
          )}
          {EssenceNfts?.length > 0 && EssenceNftsHasNext && (
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
              <ButtonStyled
                type={"primary"}
                size="small"
                loading={isLoading}
                onClick={async () => {
                  setLoading(true);
                  await dispatch(getAllEssence(null, EssencePageNfts + 1));
                  setLoading(false);
                }}
              >
                {t("load_more")}
              </ButtonStyled>
            </Row>
          )}
        </div>
      </ColStart>
    </>
  );
}
