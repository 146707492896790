import styled from "@emotion/styled";
import { Typography } from "antd";

const { Text } = Typography;

export const UserImageStyled = styled.img`
  object-fit: cover;
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border: 1px solid #d6dae1;
  border-radius: 2px;
`;

export const UsernameStyled = styled(Text)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;
  margin-bottom: 4px;
`;

export const CollectionStyled = styled(Text)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Text/400 */

  color: #364559;
`;
