import React, { useEffect, useState } from 'react'
import { Row, Col, Tag, notification, Avatar, Table, Space, Button, Dropdown, Menu, Input, Typography } from 'antd'
import { CloseOutlined, MoreOutlined, CheckOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
	getManageVolunteer,
	getProjectById,
	projectVolunteerReview,
	projectVolunteerValidation,
} from '../../modules/benevolence/action'
// import settingAdjustIcon from '../../assets/images/setting-adjust.svg'
// import moreIcon from '../../assets/images/more-benev.svg'
import projectIcon from '../../assets/images/projects-side.svg'
import halfHeart from '../../assets/images/half-heart.svg'
import closePurpleIcon from '../../assets/images/close-purple.svg'
import facebookIcon from '../../assets/images/facebook-icon.svg'
import twitterIcon from '../../assets/images/twitter-icon.svg'
import userDummy from '../../assets/images/default-avatar.png'
import linkedinIcon from '../../assets/images/linkedin-icon.svg'
// import checkIcon from '../../assets/images/check-white.svg'
import { BENEVOLENCE_HOST } from '../../configs'
// import { RowEnd, RowSpaceBetween } from '../../components/general_styled'
import ModalVolunteer from '../../components/ModalVolunteer'
import useWindowDimensions from '../../utils/windowDimensions'
import { setSection } from '../../modules/general/action'
// import { openCreateProjectModal } from '../../modules/benevolence_projects/action'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import CardBenevolenceManage from '../../components/CardManageBenevolence'

export default function BenevolenceVolunteers(props) {
	const { t } = useTranslation()

	const windowDimensions = useWindowDimensions()
	// const history = useHistory()
	const dispatch = useDispatch()
	const { project_id } = useParams()
	const stateManageVolunteer = useSelector((state) => state.getIn(['benevolence', 'manageVolunteers']).toJS())
	const [hasVolunteer, setHasVolunteer] = useState(true)
	const [isProcessing, setIsProcessing] = useState(false)
	const [validateType, setValidateType] = useState(null)

	const [modalMode, setModalMode] = useState('')
	const [selectedVolunteer, setSelectedVolunteer] = useState({})
	const [isModalOpen, setModalOpen] = useState(false)
	const [searchResult, setSearchResult] = useState([])
	const [filter, setFilter] = useState([])
	const [project, setProject] = useState({})
	const [rating, setRating] = useState(0)
	const [isVolunteerCompleted, setIsVolunteerCompleted] = useState(false)
	const [rejectionField, setRejectionField] = useState()
	const [reviewField, setReviewField] = useState()

	const columns = [
		{
			title: t('name'),
			dataIndex: 'name',
			key: 'name',

			width: windowDimensions.width > 1024 ? 300 : null,
			render: (name, record) => {
				return (
					<Row>
						<Col span={24}>
							<div className="field-name row">
								<Avatar
									size={40}
									className="img-avatar"
									shape="square"
									src={record?.user?.photo ? `${BENEVOLENCE_HOST}/files/${record.user.photo}` : userDummy}
								/>
								<div className="col">
									<span
										className="name"
										onClick={() => {
											setSelectedVolunteer(record)
											setModalMode('detail_user')
											setModalOpen(true)
										}}
									>
										{record?.user.name}
									</span>
									<span className="email">{record?.user.email}</span>
								</div>
							</div>
						</Col>
					</Row>
				)
			},
		},
		{
			title: t('Project'),
			dataIndex: 'project',
			key: 'project',
			render: (project) => <span>{project.name}</span>,
		},
		{
			title: t('job_title'),
			dataIndex: 'title',
			key: 'title',

			render: (title) => <div style={{ textTransform: 'capitalize' }}>{title}</div>,
		},
		{
			title: t('status'),
			dataIndex: 'status',
			key: 'status',

			align: 'center',
			width: 150,
			render: (status, record) => {
				let color = 'green'
				let text = ''

				if (status.toLowerCase() === 'requested') {
					color = 'gold'
					text = 'Pending Your Review'
				} else if (status.toLowerCase() === 'approved') {
					if (record.project.status === 'OPENED' && moment().unix() > record.project.end_at) {
						color = 'volcano'
						text = 'Pending Closure'
					} else if (record.project.status === 'COMPLETED') {
						color = 'red'
						text = 'Pending Review'
					} else {
						color = 'green'
						text = 'Approved'
					}
				} else if (status.toLowerCase() === 'rejected') {
					color = 'volcano'
					text = 'Rejected'
				} else if (status.toLowerCase() === 'finished') {
					color = 'lime'
					text = 'Finished'
				} else if (status.toLowerCase() === 'reviewed') {
					color = 'green'
					text = 'Reviewed'
				} else if (status.toLowerCase() === 'finalized') {
					color = 'geekblue'
					text = 'Finalized'
				} else if (status.toLowerCase() === 'completed') {
					color = 'green'
					text = 'Completed'
				}

				return (
					<>
						<Tag className="tag-custom" color={color}>
							{text.toUpperCase()}
						</Tag>
					</>
				)
			},
		},
		{
			title: t('action'),
			key: 'action',
			align: 'right',
			render: (text, record) => (
				<Space>
					{record.status.toLowerCase() === 'approved' && record.project.status.toLowerCase() === 'completed' && (
						<Button className="outline" onClick={() => handleRequested('review', record)}>
							{t('review')}
						</Button>
					)}
					{record.status.toLowerCase() === 'requested' && (
						<div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
							<Button
								className="outline"
								loading={validateType === 'rejected' && isProcessing}
								onClick={() => handleRequested('rejected', record)}
							>
								<CloseOutlined size={12} />
							</Button>
							<Button
								className="approve"
								loading={validateType === 'approve' && isProcessing}
								onClick={() => handleRequested('approve', record)}
							>
								<CheckOutlined size={12} />
							</Button>
						</div>
					)}
					<Dropdown
						overlay={
							<Menu className="canvas-dropdown-custom" onClick={(e) => handleMenuClick(e, record)}>
								<Menu.Item key="view-project">
									<Row>
										<Col span={24} className="menu-dropdown-custom">
											<img src={projectIcon} alt="icon" />
											<span>{t('view_project')}</span>
										</Col>
									</Row>
								</Menu.Item>
							</Menu>
						}
						placement="bottomRight"
					>
						<Button icon={<MoreOutlined />} />
					</Dropdown>
				</Space>
			),
		},
	]

	useEffect(() => {
		if (stateManageVolunteer.data.length < 0) {
			setHasVolunteer(false)
		}
		generateFilter()
	}, [])

	useEffect(() => {
		if (props.menu === 'volunteer' && props.searchVal && props.searchVal !== '') {
			getVolunteersSearch(props.searchVal)
		} else {
			setSearchResult([])
		}
	}, [props.searchVal])

	const generateFilter = async () => {
		if (project_id) {
			let _project = await getProjectById(project_id)
			const filterProjectId = {
				by: 'name',
				value: _project.name,
			}
			setFilter([...filter, filterProjectId])
			setProject(_project)
			await dispatch(getManageVolunteer(project_id, 1))
		} else {
			await dispatch(getManageVolunteer(null, 1))
		}
		if (stateManageVolunteer.data.length < 0) {
			setHasVolunteer(false)
		}
	}

	const onPageChange = (num) => {
		dispatch(getManageVolunteer(project_id || '', num))
	}

	const getVolunteersSearch = (value) => {
		let getResult = stateManageVolunteer.data.filter(
			(e) => e.name.toLowerCase().includes(value.toLowerCase()) || e.email.toLowerCase().includes(value.toLowerCase())
		)
		setSearchResult(getResult)
	}

	const handleMenuClick = (e, record) => {
		if (e.key === 'view-project') {
			dispatch(setSection('project-detail', record.project_id))
		} else if (e.key === 'view-volunteer') {
			dispatch(setSection('benevolence-manage', record.project_id))
		} else if (e.key === 'close') {
			// setModalOpen(true)
			// setModalMode("close")
			// setSelectedProject(record)
		}
	}

	const onValidate = async (record, isApproved) => {
		setIsProcessing(true)

		const dataValidate = {
			project_volunteer_id: record.project_volunteer_id,
			is_approved: isApproved,
		}

		if (!isApproved) {
			dataValidate.rejection_reason = rejectionField
		}

		await projectVolunteerValidation(dataValidate)
		// volunteerValidation

		await dispatch(getManageVolunteer(project_id, 1))

		notification.info({
			message: 'Review Volunteer',
			description: `You have ${isApproved ? 'approved' : 'rejected'} volunteering request from “${
				record?.user?.name
			}” for the project “${record?.project.name}”`,
			duration: 5,
			placement: 'bottomRight',
		})

		setIsProcessing(false)
		setSelectedVolunteer({})
		setModalMode('')
		setModalOpen(false)
	}

	const onReview = async () => {
		setIsProcessing(true)
		try {
			const dataValidate = {
				project_volunteer_id: selectedVolunteer.project_volunteer_id,
				is_volunteer_complete: isVolunteerCompleted,
				rating: rating || 0,
				review: reviewField || 'Volunteering is not completed',
			}

			await projectVolunteerReview(dataValidate)

			notification.success({
				message: 'Volunteering Review',
				description: `You have reviewed volunteering “${selectedVolunteer?.user.name}” for the project “${selectedVolunteer?.project.name}”`,
				duration: 5,
				placement: 'bottomRight',
			})

			setIsVolunteerCompleted(false)
			setRating(0)
			setReviewField()
			setIsProcessing(false)
			setSelectedVolunteer({})
			setModalMode('')
			setModalOpen(false)
			dispatch(getManageVolunteer(null, 1))
		} catch (err) {
			console.log(err)
			notification.error({
				message: 'ALERT',
				description: `Error review volunteering “${selectedVolunteer?.user.name}” for the project “${selectedVolunteer?.project.name}”`,
				duration: 5,
				placement: 'bottomRight',
			})
		}
		setIsProcessing(false)
		setValidateType(null)
		setRating(0)
		setReviewField()
	}

	const onClickSosmed = (e, type) => {
		e.preventDefault()
	}

	const onFilterItemClicked = (e, data) => {
		e.preventDefault()
		let newDataFilter = filter.filter((e) => e !== data)

		setFilter(newDataFilter)
	}

	const onModalCancelClicked = () => {
		setModalOpen(false)
		setModalMode('')
		setSelectedVolunteer({})
	}

	const onModalSubmitClicked = () => {
		if (modalMode === 'reject') {
			onValidate(selectedVolunteer, false)
		} else {
			onReview()
		}
	}

	const handleRequested = (type, record) => {
		if (type === 'rejected') {
			setValidateType(type)
			setSelectedVolunteer(record)
			setModalMode('reject')
			setModalOpen(true)
		} else if (type === 'review') {
			setSelectedVolunteer(record)
			setModalMode('review')
			setModalOpen(true)
		} else {
			setValidateType(type)
			onValidate(record, true)
		}
	}

	const resolveHasVolunteer = () => {
		return !hasVolunteer ? (
			<div className="no-volunteers">
				<img src={halfHeart} alt="icon" />
				<h1>{t('resolve_volunteer')}</h1>
				<p>
					{t('resolve_volunteer_1')}
					<br />
					{t('resolve_volunteer_2')}
				</p>
				<div className="row">
					<button className="sosmed" onClick={(e) => onClickSosmed(e, 'facebook')}>
						<img src={facebookIcon} alt="facebook" />
					</button>
					<button className="sosmed" onClick={(e) => onClickSosmed(e, 'twitter')}>
						<img src={twitterIcon} alt="twitter" />
					</button>
					<button className="sosmed" onClick={(e) => onClickSosmed(e, 'linkedin')}>
						<img src={linkedinIcon} alt="linkedin" />
					</button>
				</div>
			</div>
		) : (
			<>
				{filter > 0 && (
					<Row className="add-space-header">
						<Col span={24}>
							<div className="filter-wrap">
								{filter.map((e, i) => {
									return (
										<div className="filter-item" key={i} onClick={(ev) => onFilterItemClicked(ev, e)}>
											<div className="type">{e.by === 'name' ? 'Project' : e.by}&nbsp;</div>
											<div className="data">• {e.value}</div>
											<img src={closePurpleIcon} alt="close-icon" />
										</div>
									)
								})}
							</div>
						</Col>
					</Row>
				)}
				{windowDimensions.width > 925 ? (
					<Table
						className="table-volunteers w-100"
						columns={columns}
						dataSource={searchResult.length > 0 ? searchResult : stateManageVolunteer.data}
						scroll={{ y: '100%' }}
						pagination={{
							current: stateManageVolunteer.currentPage,
							total: stateManageVolunteer.total,
							pageSize: 12,
							onChange: onPageChange,
						}}
					/>
				) : (
					stateManageVolunteer.data.map((i) => (
						<CardBenevolenceManage
							page={'volunteer'}
							data={i}
							isProcessing={isProcessing}
							handleMenuClick={handleMenuClick}
							handleRequested={handleRequested}
						/>
					))
				)}
			</>
		)
	}

	return (
		<div id="manage-volunteers">
			{resolveHasVolunteer()}
			<ModalVolunteer
				isModalOpen={isModalOpen}
				modalMode={modalMode}
				selectedVolunteer={selectedVolunteer}
				onModalCancelClicked={onModalCancelClicked}
				onModalSubmitClicked={onModalSubmitClicked}
				rejectionField={rejectionField}
				reviewField={reviewField}
				setRejectionField={setRejectionField}
				setReviewField={setReviewField}
				setRating={setRating}
				rating={rating}
				setModalOpen={setModalOpen}
				isProcessing={isProcessing}
				isVolunteerCompleted={isVolunteerCompleted}
				setIsVolunteerCompleted={setIsVolunteerCompleted}
			/>
		</div>
	)
}
