import { Col, Steps } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { PROJECT_TYPE } from '../../../constants/benevolence_constant'
import LastStep from '../Steps/LastStep'
import StepOne from '../Steps/StepOne'
import StepThree from '../Steps/StepThree'
import StepTwo from '../Steps/StepTwo'
import { notification } from 'antd'
import htmlToText from 'html-to-text'
import { useSelector } from 'react-redux'
// import closeModalIcon from '../../../assets/images/close_modal.svg'
import { useDispatch } from 'react-redux'
// import { ButtonStyled, ColCenter, ColStart } from '../../general_styled'
import { closeCreateProjectModal, submitProject } from '../../../modules/benevolence_projects/action'
import { toIBFx, toUSD } from '../../../utils/utils'
import {
	FormBodyStyled,
	FormFooterStyled,
	FormStyled,
	FormSubtitleStyled,
	FormTitleStyled,
	NavButtonStyled,
	StepsStyled,
} from '../styled'
import { INPUT_STATUS } from '../../../constants/constant_common'
import { extractErrorMessage } from '../../../utils/extractErrorMessage'
import useWindowDimensions from '../../../utils/windowDimensions'

export default function CreateAssetProject(props) {
	const { t } = useTranslation()

	const windowDimensions = useWindowDimensions()
	const dispatch = useDispatch()
	const [current, setCurrent] = useState(0)
	const { Step } = Steps
	const steps = [
		{ title: t('setup') },
		{ title: t('details') },
		{ title: t('documents') },
		{ title: t('verification') },
	]

	const [name, setName] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: '',
	})
	const [description, setDescription] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: '',
	})
	const [startAt, setStartAt] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: null,
	})
	const [endAt, setEndAt] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: null,
	})
	const [fundGoal, setFundGoal] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: '',
	})
	const [coverImage, setCoverImage] = useState(null)
	const [location, setLocation] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: null,
	})
	const [projectType, setProjectType] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: '',
	})
	const [assetProjectType, setAssetProjectType] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: '',
	})
	const [state, setState] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: '',
	})
	const [country, setCountry] = useState({
		validateStatus: INPUT_STATUS.SUCCESS,
		help: '',
		value: '',
	})
	const [documents, setDocuments] = useState([])
	const [isProcessing, setProcessing] = useState(false)
	const token = useSelector((state) => state.getIn(['auth', 'token']))

	useEffect(() => {
		setCurrent(0)
		setName({ validateStatus: INPUT_STATUS.SUCCESS, help: '', value: '' })
		setDescription({
			validateStatus: INPUT_STATUS.SUCCESS,
			help: '',
			value: '',
		})
		setStartAt({ validateStatus: INPUT_STATUS.SUCCESS, help: '', value: null })
		setEndAt({ validateStatus: INPUT_STATUS.SUCCESS, help: '', value: null })
		setFundGoal({ validateStatus: INPUT_STATUS.SUCCESS, help: '', value: '' })
		setCoverImage(null)
		setLocation({
			validateStatus: INPUT_STATUS.SUCCESS,
			help: '',
			value: null,
		})
		setProjectType({
			validateStatus: INPUT_STATUS.SUCCESS,
			help: '',
			value: '',
		})
		setAssetProjectType({
			validateStatus: INPUT_STATUS.SUCCESS,
			help: '',
			value: '',
		})
		setState({ validateStatus: INPUT_STATUS.SUCCESS, help: '', value: '' })
		setCountry({ validateStatus: INPUT_STATUS.SUCCESS, help: '', value: '' })
		setDocuments([])
		setProcessing(false)
	}, [])

	const resolveForm = () => {
		switch (current) {
			case 0:
				return (
					<StepOne
						{...{
							assetProjectType,
							setAssetProjectType,
							startAt,
							setStartAt,
							endAt,
							setEndAt,
							fundGoal,
							setFundGoal,
							state,
							setState,
							country,
							setCountry,
							location,
							setLocation,
						}}
						projectType={PROJECT_TYPE.ASSET}
					/>
				)
			case 1:
				return (
					<StepTwo
						{...{
							name,
							setName,
							description,
							setDescription,
							coverImage,
							setCoverImage,
						}}
					/>
				)
			case 2:
				return (
					<StepThree
						{...{
							documents,
							setDocuments,
						}}
					/>
				)
			case 3:
				return <LastStep />
		}
	}

	const validateStepOne = () => {
		let isValid = true
		if (!assetProjectType.value || assetProjectType.value === '') {
			setAssetProjectType({
				...assetProjectType,
				validateStatus: INPUT_STATUS.ERROR,
				help: t('project_category_required'),
			})
			isValid = false
		}

		if (!fundGoal.value || fundGoal.value <= 0) {
			setFundGoal({
				...fundGoal,
				validateStatus: INPUT_STATUS.ERROR,
				help: t('fun_goal_more_zero'),
			})
			isValid = false
		}
		if (!startAt.value || startAt.value === '') {
			setStartAt({
				...startAt,
				validateStatus: INPUT_STATUS.ERROR,
				help: t('start_date_required'),
			})
			isValid = false
		}
		if (
			!endAt.value ||
			endAt.value === '' ||
			new Date(endAt.value).getTime() - new Date(startAt.value).getTime() < 604800
		) {
			setEndAt({
				...endAt,
				validateStatus: INPUT_STATUS.ERROR,
				help: t('end_date_required'),
			})
			isValid = false
		}
		if (!country.value || country.value === '') {
			setCountry({
				...country,
				validateStatus: INPUT_STATUS.ERROR,
				help: t('country_required'),
			})
			isValid = false
		}
		if (!state.value || state.value === '') {
			setState({
				...state,
				validateStatus: INPUT_STATUS.ERROR,
				help: t('state_required'),
			})
			isValid = false
		}
		if (!isValid) {
			throw t('please_check_input')
		}
	}

	const validateStepTwo = () => {
		if (!coverImage || coverImage.length < 1) {
			throw t('cover_photo_required')
		}

		if (!name.value || name.value === '') {
			setName({
				validateStatus: INPUT_STATUS.ERROR,
				help: t('title_required'),
			})
			throw t('title_required')
		}
		if (!description.value || htmlToText.fromString(description.value) === '') {
			setDescription({
				validateStatus: INPUT_STATUS.ERROR,
				help: t('desc_required'),
			})
			throw t('desc_required')
		}
	}

	const validateStepThree = () => {
		// if (!documents || documents.length < 1) {
		// 	throw t('desc_required')
		// }
	}

	const submit = async () => {
		setProcessing(true)

		try {
			const project = {
				name: name.value,
				description: description.value,
				start_at: startAt.value.format('X'),
				end_at: endAt.value.format('X'),
				fund_goal: toIBFx(Number(fundGoal.value)),
				asset_project_type: assetProjectType.value,
				cover_image_file: coverImage[0].response.filename,
				country_id: country.value,
				state_id: state.value,
				project_type: PROJECT_TYPE.ASSET,
			}
			const project_documents = documents.map((document, key) => {
				return {
					file_name: document.response.filename,
					description: null,
					priority: key,
				}
			})

			await submitProject(token, { project, project_documents })
			setProcessing(false)
		} catch (e) {
			setProcessing(false)
			throw extractErrorMessage(e)
		}
	}

	const onClose = () => {
		dispatch(closeCreateProjectModal())
	}

	const onNext = async () => {
		try {
			switch (current) {
				case 0:
					validateStepOne()
					break
				case 1:
					validateStepTwo()
					break
				case 2:
					validateStepThree()
					await submit()
					break
			}
			setCurrent(current + 1)
		} catch (e) {
			console.log(e)
			notification.error({
				message: t('error'),
				description: String(e),
			})
		}
	}

	return (
		<FormStyled layout="vertical" className="create-project-form" labelAlign={'left'}>
			<FormTitleStyled>{t('create_sa_project')}</FormTitleStyled>
			<FormSubtitleStyled>{t('create_sa_project_desc')}</FormSubtitleStyled>
			<StepsStyled
				current={current}
				direction="horizontal"
				// onChange={(_current) => setCurrent(_current)}
			>
				{steps.map((step, key) => {
					return <Step key={key} title={windowDimensions.width < 835 ? '' : step.title} />
				})}
			</StepsStyled>
			<FormBodyStyled>{resolveForm()}</FormBodyStyled>
			{current < steps.length - 1 ? (
				<FormFooterStyled>
					<Col span={8}>
						<NavButtonStyled
							onClick={() => {
								if (current <= 0) {
									setCurrent(0)
									props.setType('')
									return
								}
								setCurrent(current - 1)
							}}
							type={'primary'}
							ghost
						>
							{t('previous')}
						</NavButtonStyled>
					</Col>
					<Col span={8}>
						<NavButtonStyled onClick={onNext}>{t('next')}</NavButtonStyled>
					</Col>
				</FormFooterStyled>
			) : (
				<></>
			)}
		</FormStyled>
	)
}
