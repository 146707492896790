import React, { useState } from "react";
import { Row, Col, Alert, Button, Typography } from "antd";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";

import { LoadingOutlined } from "@ant-design/icons";
import { ASSETS_NAME, TRANSACTION_TYPE } from "../../constants/constant_wallet";
import {
  ColCenter,
  ColSpaceBetween,
  ColStart,
  RowCenter,
} from "../general_styled";
import poweredStripeIcon from "../../assets/images/powered-stripe.svg";
import { createOrder, payWithStripe } from "../../modules/wallet/action";
import { InputStyled } from "./styled";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { decryptMnemonic, fromIBFx, toIBFx } from "../../utils/utils";
import ModalLoading from "../ModalLoading";
import { closeCart, removeCart } from "../../modules/course_cart/action";
import {
  donateToProjectlWitStripe,
  donateToWaqfPoolWitStripe,
} from "../../modules/benevolence_projects/action";

const { Title, Text } = Typography;

export default function StripeBodyCourses(props) {
  const isDonation = props.type === "donation";
  const isWaqf = props.type === "waqf";

  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const userData = useSelector((state) =>
    state.getIn(["auth", "userData"]).toJS()
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );
  const cart = useSelector((state) =>
    state.getIn(["courseCart", "cart"]).toJS()
  );
  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );

  const [isRequest, setRequest] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const resolveAssetId = (assetName) => {
    const asset = allAssets.find((asset) => asset.asset_name === assetName);
    return asset.asset_id;
  };

  const onSubmit = async () => {
    setRequest(true);
    setFailed(false);
    setErrorMessage("");

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    let clientSecret;
    if (isWaqf) {
      clientSecret = await donateToWaqfPoolWitStripe({
        user_id: walletUser.user_id,
        amount: toIBFx(props.totalPayment),
      });
    } else if (isDonation) {
      clientSecret = await donateToProjectlWitStripe({
        user_id: walletUser.user_id,
        project_id: props.project.project_id,
        amount: toIBFx(props.totalPayment),
      });
    } else {
      const order = await createOrder({
        user_id: walletUser.user_id,
        order_items: cart.map((c) => {
          return {
            course_id: c.course_id,
            quantity: 1,
            price: toIBFx(c.price),
          };
        }),
      });

      const body = {
        sender: walletUser.algo_address,
        order_id: order.order_id,
        asset_id: resolveAssetId(ASSETS_NAME.IBFX_TYPE_I),
        amount: toIBFx(props.totalPayment),
      };

      clientSecret = await payWithStripe(body);

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
    }

    const cardElement = elements.getElement(CardElement);

    console.log("client: ", clientSecret?.invoice?.payment_client_secret)

    const payload = await stripe.confirmCardPayment(
      clientSecret?.invoice?.payment_client_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: userData.name,
            email: userData.email,
          },
        },
      }
    );

    console.log({payload})
    
    if (payload.error) {
      setRequest(false);
      setFailed(true);
      setErrorMessage(payload.error.message);
      return false;
    } else {
      setRequest(false);
      setSuccess(true);

      for (const course of cart) {
        dispatch(removeCart(course));
      }

      dispatch(closeCart());
      return true;
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  return (
    <ColSpaceBetween className="stretch">
      <ColCenter className="mb-12px">
        <Title className="text-w700 gray-7 mb-0px" level={3}>
          Enter Card
        </Title>
        <Text className="gray-7" align="center">
          Please enter your card number to process the transaction
        </Text>
      </ColCenter>
      <ColStart>
        <InputStyled
          style={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "22px",
            padding: "15px",
            borderRadius: "6px",
            boxShadow:
              "rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px, rgb(9 30 66 / 14%) 0px 0px 20px -6px",
            border: "0",
            width: "99%",
            marginTop: "20px !important",
            marginBottom: "20px !important",
            marginLeft: "2px !important",
            marginRight: "2px !important",
          }}
          className="my-20px"
        >
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                  fontWeight: "500",
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </InputStyled>
        {isFailed ? (
          <Alert message={errorMessage} type="error" showIcon />
        ) : isSuccess ? (
          <Alert message="Your payment is success" type="success" showIcon />
        ) : (
          <></>
        )}
      </ColStart>
      <ModalLoading
        handleCancel={props.handleCancel}
        type={props.type || "courses"}
        amount={
          isDonation || isWaqf
            ? props.totalPayment
            : props.totalPayment / ibfxPerUSD
        }
        onClose={props.setIsModalVisible}
        isLoading={isRequest}
        onProcess={onSubmit}
        data={allAssets.find(
          (asset) => asset.asset_name === ASSETS_NAME.IBFX_TYPE_I
        )}
      />
      {/* <Button
            block
            type="primary"
            size="large"
            onClick={() => {
              onSubmitTopup()
            }}
          >
            {isRequest ? antIcon : "Submit"}
          </Button> */}
    </ColSpaceBetween>
  );
}
