import { Col, Row } from 'antd'
import ReactCodeInput from 'react-code-input'
import useWindowDimensions from '../../../utils/windowDimensions'
import { ColCenter } from '../../general_styled'
import { ContentTextStyled } from '../styled'
import { PinInputStyled } from './styled'

export default function Pin(props) {
	const windowDimensions = useWindowDimensions()
	const codeProps = {
		className: 'reactCodeInput',
		inputStyle: {
			fontFamily: 'Roboto',
			fontWeight: 500,
			MozAppearance: 'textfield',
			width: windowDimensions.width > 835 ? '6rem' : '14vw',
			height: windowDimensions.width > 835 ? '6rem' : '14vw',
			margin: '4px',
			fontSize: '40px',
			paddingLeft: '7px',
			backgroundColor: '#FBFBFB',
			color: '#828CA0',
			border: '1px solid #EDEFF2',
			borderRadius: '2px',
			textAlign: 'center',
		},
		inputStyleInvalid: {
			fontFamily: 'Roboto',
			fontWeight: 500,
			MozAppearance: 'textfield',
			width: windowDimensions.width > 835 ? '6rem' : '14vw',
			height: windowDimensions.width > 835 ? '6rem' : '14vw',
			margin: '4px',
			fontSize: '40px',
			paddingLeft: '7px',
			backgroundColor: '#FBFBFB',
			color: 'red',
			border: '1px solid red',
			borderRadius: '2px',
		},
	}

	return (
		<ColCenter style={{ height: '100%' }}>
			<Col span={24}>
				<ReactCodeInput type="number" onChange={(e) => props.setPinCode(e)} fields={4} {...codeProps} />
			</Col>
			<ContentTextStyled style={{ maxWidth: '700px', textAlign: 'center', marginTop: '20px' }}>
				Please set your 4-digit PIN here. Your PIN will be asked for completing secure transactions in IBFNex -
				Netverse. It can be reset at anytime using your recovery passphrase
			</ContentTextStyled>
		</ColCenter>
	)
}
