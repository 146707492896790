import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Typography,
  Row,
  Drawer,
  Col,
  Button,
  Progress,
  message,
} from "antd";
import { useDispatch } from "react-redux";
import {
  ColSpaceBetween,
  ColCenter,
  RowCenter,
  ButtonOutline,
} from "../general_styled";

import { ContentStyled, AddNFTStyled, SlotUsedStyled } from "./styled";
import closeIcon from "../../assets/images/close.svg";
import addIcon from "../../assets/images/icon_add.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import ReactCodeInput from "react-code-input";
import { decryptMnemonic } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import {
  createAuction,
  getAllOwnedAuction,
} from "../../modules/affluence/action";
import jwtDecode from "jwt-decode";
import ModalSellNFTAuction from "../ModalSellNFTAuction";

const { Title, Text } = Typography;

export default function ModalAffluenceAddAuction(props) {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMode, setIsMode] = useState(0);
  const [isInputPin, setInputPin] = useState(true);
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [processingPercent, setProcessingPercent] = useState(0);
  const dispatch = useDispatch();

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
    setInputPin(true);
    setPinCode("");
    setProcessing(false);
    setSuccess(false);
    setFailed(false);
    setProcessingPercent(0);
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsModalVisible(false);
  };

  const onSubmit = async () => {
    setProcessing(true);
    setProcessingPercent(10);

    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      const decodedUser = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));

      setProcessingPercent(20);
      await createAuction(_decryptMnemonic);
      setProcessingPercent(50);
      await dispatch(getAllOwnedAuction(decodedUser.user_id));
      setProcessingPercent(100);
      setSuccess(true);
    } catch (e) {
      message.error(`failed_to_create_auction`);
      setFailed(true);
    }

    setProcessing(false);
  };

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderInputPin = () => {
    return (
      <ColSpaceBetween className="stretch" style={{ minHeight: 300 }}>
        <ColCenter>
          <Title className="text-w700 gray-7 mb-0px" level={3}>
            {t("create_slot_auction")}
          </Title>
          <Text className="gray-7">{t("pin_desc")}</Text>
        </ColCenter>
        <RowCenter>
          <ReactCodeInput
            type="password"
            onChange={(e) => setPinCode(e)}
            fields={4}
            {...codeProps}
          />
        </RowCenter>
        <Row className="d-flex">
          <Col span={24}>
            <Row className="d-flex">
              <Col span={24}>
                <Button
                  block
                  style={{
                    borderRadius: 4,
                    height: 40,
                  }}
                  type="primary"
                  onClick={() => {
                    const _checkPin = checkPin();
                    setInputPin(false);
                    if (_checkPin) {
                      onSubmit();
                    }
                  }}
                >
                  {t("submit_pin")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ColSpaceBetween>
    );
  };

  const renderOnProcessing = () => {
    return (
      <ColCenter>
        <Progress
          type="circle"
          percent={processingPercent}
          status={isFailed && "exception"}
        />
        <Title className="text-w700 gray-7 mb-0px mt-24px" level={3}>
          {isProcessing && !isSuccess && !isFailed
            ? t("processing")
            : isSuccess && !isFailed
            ? t("success") + "!"
            : t("failed")}
        </Title>
        <Text className="gray-7" align="center">
          {isProcessing && !isSuccess && !isFailed
            ? t("process_max_one_minutes")
            : isSuccess && !isFailed
            ? t("auction_slot_has_been_created")
            : t("failed_created_auction")}
        </Text>
        <Button
          type="primary"
          style={{
            marginTop: "24px",
            borderRadius: 4,
            height: 40,
          }}
          block
          disabled={isProcessing}
          onClick={handleCancel}
        >
          {t("dismiss")}
        </Button>
      </ColCenter>
    );
  };

  const resolveModalContent = () => {
    if (isInputPin) {
      return renderInputPin();
    } else {
      return renderOnProcessing();
    }
  };

  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
        <AddNFTStyled>
          <ColSpaceBetween>
            <SlotUsedStyled>
              {t("slot_used")} • {props.total}/10
            </SlotUsedStyled>
            <RowCenter style={{ height: "430px" }}>
              <ColCenter>
                <img src={addIcon} />

                {/* <PlusOutlined style={{ fontSize: "64px", color: "#024FF1" }} />
                <Title
                  align="center"
                  className="mb-0px mt-24px text-w400"
                  level={4}
                  style={{ color: "#024FF1" }}
                >
                  Add Auction
                </Title> */}
              </ColCenter>
            </RowCenter>
            <ColCenter>
              <Text align="center" className="text-size-14 mt-16px">
                {t("slot_auction_desc")}
              </Text>
              {/* <Text align="center" italic className="text-size-12 mt-8px">
                We are working on removing this limitation
              </Text> */}
              {/* <ModalSellNFTAuction isCreate /> */}
              <ButtonOutline
                className="mt-12px"
                style={{
                  height: 40,
                  borderRadius: 4,
                }}
                block
                onClick={showModal}
              >
                {t("create_slot")}
              </ButtonOutline>
            </ColCenter>
          </ColSpaceBetween>
        </AddNFTStyled>
      </Col>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <ContentStyled>{resolveModalContent()}</ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>{resolveModalContent()}</ContentStyled>
        </Drawer>
      )}
    </>
  );
}
