import React, { useEffect } from "react";
import { Col, Row, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getCredenceEvmNFTByAddress,
  getCredenceNFTByUserId,
} from "../../modules/credence/action";
import { setSection } from "../../modules/general/action";
import ModalPayFeeNft from "../../components/ModalPayFeeNft";
import { NFT_STATUS } from "../../constants/constant_credence";
import ActivateAirdrop from "../../components/ActivateAirdrop";
import { useAccount } from "wagmi";
import { CREDENCE_HOST, IS_EVM } from "../../configs";
import { ImageTable } from "./styled";

export default function CredenceApprovals(props) {
  const { isConnected, address } = useAccount();

  const credenceData = useSelector((state) =>
    state.getIn(["credence", "nfts", "data"]).toJS()
  );
  const credenceCurrentPage = useSelector((state) =>
    state.getIn(["credence", "nfts", "currentPage"])
  );
  const credenceTotal = useSelector((state) =>
    state.getIn(["credence", "nfts", "total"])
  );

  const dispatch = useDispatch();

  const onPageChange = (num) => {
    if (IS_EVM) {
      if (isConnected) dispatch(getCredenceEvmNFTByAddress(address, num));
    } else {
      dispatch(getCredenceNFTByUserId(null, num));
    }
  };

  const basicColumns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      render: (symbol) => <>{symbol}</>,
    },
    {
      title: "Is Airdrop",
      dataIndex: "airdrop",
      key: "airdrop",
      render: (airdrop) => <>{airdrop?.length > 0 ? "true" : "false"}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        let color = "success";
        let text = status;

        if (status === NFT_STATUS.INIT) {
          color = "blue";
          text = "Pending";
        }
        if (status === NFT_STATUS.CREATING) {
          color = "warning";
          text = "Processing";
        }
        if (status === NFT_STATUS.CREATED) {
          color = "success";
        }
        if (status === NFT_STATUS.FAILED) {
          color = "error";
          text = "Rejected";
        }
        if (status === NFT_STATUS.APPROVED) {
          color = "purple";
        }
        return (
          <>
            <Tag className="tag-custom" color={color}>
              {text.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        const metadata = JSON.parse(record?.metadata_json);
        const activeAirdrop = record.airdrop?.find(
          (i) => i.nft_id === record.nft_id
        );
        const disabled = IS_EVM
          ? record.status !== NFT_STATUS.INIT
          : record.status !== NFT_STATUS.APPROVED;
        if (record.status === "CREATED" && activeAirdrop?.txhash === null) {
          return (
            <ActivateAirdrop
              data={{
                ...activeAirdrop,
                nft_contract_address: record.contract_address,
              }}
            />
          );
        } else {
          return (
            <>
              <ModalPayFeeNft
                toVault={() => props.toVault()}
                disabled={disabled}
                record={record}
                metadata={{
                  nft_id: record.nft_id,
                  ...metadata,
                  max_supply: record?.max_supply || 0,
                  is_airdrop: record?.airdrop?.length > 0 || false,
                }}
              />
            </>
          );
        }
      },
    },
  ];

  const evmColumns = [
    {
      title: "NFT Name & Collection",
      dataIndex: "metadata_json",
      key: "metadata_json",
      render: (string_json, record) => {
        const metadata = JSON.parse(string_json);
        return (
          <Row gutter={[12, 12]}>
            <Col>
              <ImageTable
                src={CREDENCE_HOST + "/files/" + record?.cover_file}
              />
            </Col>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <a
                className="ml-24px"
                onClick={() =>
                  dispatch(setSection("credence-nft-detail", record.nft_id))
                }
              >
                {metadata?.name +
                  (metadata?.collection?.name === null
                    ? ""
                    : " - " + metadata?.collection?.name)}
              </a>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Date Submitted",
      dataIndex: "created_at",
      key: "created_at",
      render: (dateTime) => (
        <>{moment(dateTime).format("DD/MM/YYYY HH:mm A")}</>
      ),
    },
    ...basicColumns,
  ];

  const algoColumns = [
    {
      title: "Date Submitted",
      dataIndex: "created_at",
      key: "created_at",
      render: (dateTime) => (
        <>{moment(dateTime).format("DD/MM/YYYY HH:mm A")}</>
      ),
    },
    {
      title: "NFT",
      dataIndex: "metadata_json",
      key: "metadata_json",
      render: (string_json, record) => {
        const metadata = JSON.parse(string_json);
        return (
          <a
            onClick={() =>
              dispatch(setSection("credence-nft-detail", record.nft_id))
            }
          >
            {metadata?.name +
              (metadata?.collection?.name === null
                ? ""
                : " - " + metadata?.collection?.name)}
          </a>
        );
      },
    },
    ...basicColumns,
  ];

  useEffect(() => {
    if (IS_EVM) {
      if (isConnected)
        dispatch(getCredenceEvmNFTByAddress(address, credenceCurrentPage));
    } else {
      dispatch(getCredenceNFTByUserId(null, credenceCurrentPage));
    }
  }, []);

  return (
    <>
      <Row className="mt-20px">
        <Col span={24}>
          <Table
            dataSource={credenceData}
            columns={IS_EVM ? evmColumns : algoColumns}
            scroll={{ x: 1000 }}
            pagination={{
              onChange: onPageChange,
              current: credenceCurrentPage,
              pageSize: 15,
              total: credenceTotal,
            }}
          />
        </Col>
      </Row>
    </>
  );
}
