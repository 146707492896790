import { Col } from "antd"
import { useDispatch } from "react-redux"
import { setSection } from "../../../modules/general/action"
import useWindowDimensions from "../../../utils/windowDimensions"
import {
  ColCenter,
  ColEnd,
  ColStart,
  RowCenter,
  RowSpaceBetween,
} from "../../general_styled"
import { CardStyled, CollectionNameStyled, NFTNameStyled } from "./styled"
import ModalAffluenceRemoveListing from "../../ModalAffluenceRemoveListing"
import ibfxIcon from "../../../assets/images/affluence-shopee-ibfx.svg"
import { fromIBFx } from "../../../utils/utils"
import { CREDENCE_HOST } from "../../../configs"

export default function CardCollateral(props) {
  const windowDimensions = useWindowDimensions()
  const dispatch = useDispatch()
  const isAffluence = props.isAffluence ? true : false

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
      <CardStyled
        className={props.selectedNFT.includes(props.nftId) ? "selected" : ""}
        onClick={() => {
          props.setSelectedNFT(props.nftId, props.data)
        }}
        cover={
          <div className="canvas-cover">
            {props.is_pdf ? (
              <img
                alt={props.name}
                src={CREDENCE_HOST + "/files/" + props.data?.cover_file}
              />
            ) : (
              <img
                alt={props.name}
                src={`https://ipfs.io/ipfs/${props.ipfsHash}`}
              />
            )}
            <div className="selected-indicator">Selected</div>
          </div>
        }
      >
        <div>
          <RowSpaceBetween>
            <ColStart>
              <CollectionNameStyled
                className={!props.collectionName && "no-collection"}
              >
                {props.collectionName || "No Collection"}
              </CollectionNameStyled>
              <NFTNameStyled>{props.name}</NFTNameStyled>
            </ColStart>
            {isAffluence && (
              <ColEnd>
                <CollectionNameStyled>List Price</CollectionNameStyled>
                <RowSpaceBetween>
                  <img alt="ibfxIcon" src={ibfxIcon} />
                  <NFTNameStyled>
                    {fromIBFx(props.ibfxAmount) || 0}
                  </NFTNameStyled>
                </RowSpaceBetween>
              </ColEnd>
            )}
          </RowSpaceBetween>
        </div>
        {isAffluence && (
          <RowCenter>
            <ModalAffluenceRemoveListing nft={props.metadata} />
          </RowCenter>
        )}
      </CardStyled>
    </Col>
  )
}
