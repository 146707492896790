import React, { useState } from "react"
import {
  Modal,
  Typography,
  Row,
  Drawer,
  Col,
  Button,
  Progress,
  message,
} from "antd"
import { useDispatch } from "react-redux"
import { LoadingOutlined } from "@ant-design/icons"
import {
  ColSpaceBetween,
  ColCenter,
  RowCenter,
  ButtonOutline,
} from "../../general_styled"

import { ContentStyled, AddNFTStyled, SlotUsedStyled } from "./styled"
import closeIcon from "../../../assets/images/close.svg"
import addIcon from "../../../assets/images/icon_add.svg"
import useWindowDimensions from "../../../utils/windowDimensions"
import ReactCodeInput from "react-code-input"
import { decryptMnemonic } from "../../../utils/utils"
import { COMMON_CONSTANT } from "../../../constants/constant_common"
import {
  activateAuction,
  createAuction,
  getAllOwnedAuction,
} from "../../../modules/affluence/action"
import jwtDecode from "jwt-decode"

const { Title, Text } = Typography

export default function ModalPinAuction(props) {
  const windowDimensions = useWindowDimensions()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isMode, setIsMode] = useState(0)
  const [isInputPin, setInputPin] = useState(true)
  const [isProcessing, setProcessing] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [isFailed, setFailed] = useState(false)
  const [pinCode, setPinCode] = useState("")
  const dispatch = useDispatch()

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInputPin(true)
    setPinCode("")
    setProcessing(false)
    setSuccess(false)
    setFailed(false)
  }

  const handleCancel = () => {
    setIsMode(0)
    setPinCode("")
    setIsModalVisible(false)
  }

  const onSubmit = async () => {
    props.setLoading(true)
    props.setProcessingPercent(10)

    handleCancel()

    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      )
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode)
      const decodedUser = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"))

      props.setProcessingPercent(20)
      if (props.isActivate) {
        await activateAuction(_decryptMnemonic, {
          auction_id: props.auction.auction_id,
        })
      } else {
        await createAuction(_decryptMnemonic)
      }
      props.setProcessingPercent(80)
      await dispatch(getAllOwnedAuction(decodedUser.user_id))
      // props.setProcessingPercent(80)
      // const nft = await getNftByNftId(param)
      // dispatch(setNftDetail(nft))
      props.setProcessingPercent(100)
      setSuccess(true)
    } catch (e) {
      if (props.isActivate) {
        message.error(`failed to activate auction`)
      } else {
        message.error(`failed to create auction`)
      }
      setFailed(true)
    }

    props.setLoading(false)
  }

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      )
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode)
      if (_decryptMnemonic) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log(e)
    }
  }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  )

  const renderInputPin = () => {
    return (
      <ColSpaceBetween className="stretch" style={{ minHeight: 300 }}>
        <ColCenter>
          <Title className="text-w700 gray-7 mb-0px" level={3}>
            Enter Pin
          </Title>
          <Text className="gray-7">Please enter your 4 digit wallet pin</Text>
        </ColCenter>
        <RowCenter>
          <ReactCodeInput
            type="password"
            onChange={(e) => setPinCode(e)}
            fields={4}
            {...codeProps}
          />
        </RowCenter>
        <Row className="d-flex">
          <Col span={24}>
            <Row className="d-flex">
              <Col span={24}>
                <Button
                  block
                  style={{
                    borderRadius: 4,
                    height: 40,
                  }}
                  type="primary"
                  onClick={() => {
                    const _checkPin = checkPin()
                    if (_checkPin) {
                      onSubmit()
                    }
                  }}
                >
                  Submit Pin
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ColSpaceBetween>
    )
  }

  return (
    <>
      <Button
        block
        type={props.type}
        disabled={props.disabled}
        className="mt-16px"
        style={{ height: 48, borderRadius: 6 }}
        onClick={() => showModal()}
      >
        {props.isLoading ? antIcon : props.isActivate ? "Activate" : "Create"}
      </Button>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose
        >
          <ContentStyled>{renderInputPin()}</ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          destroyOnClose
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>{renderInputPin()}</ContentStyled>
        </Drawer>
      )}
    </>
  )
}
