import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { Modal, Typography, Button, Row, Col, Alert, Drawer } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import ReactCodeInput from 'react-code-input'
import { LoadingOutlined } from '@ant-design/icons'
import { ButtonOutline, ColCenter, RowCenter } from '../general_styled'

import { ContentStyled } from './styled'
import { activateUserAsset, getAllAssets } from '../../modules/wallet/action'
import { decryptMnemonic } from '../../utils/utils'
import { COMMON_CONSTANT } from '../../constants/constant_common'
import useWindowDimensions from '../../utils/windowDimensions'
import { ASSETS_NAME } from '../../constants/constant_wallet'
import { openImportMnemonicModal } from '../../modules/import_mnemonic_modal/action'

const { Title, Text } = Typography

export default function ModalActiveAssets(props) {
	const { t } = useTranslation()
	const windowDimensions = useWindowDimensions()
	const dispatch = useDispatch()
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isMode, setIsMode] = useState(0)
	const walletUser = useSelector((state) => state.getIn(['wallet', 'user']).toJS())

	const [pinCode, setPinCode] = useState('')

	const codeProps = {
		className: 'reactCodeInput',
		inputStyle: {
			fontFamily: 'Roboto',
			fontWeight: 500,
			MozAppearance: 'textfield',
			width: windowDimensions.width > 835 ? '6rem' : '18vw',
			height: windowDimensions.width > 835 ? '6rem' : '18vw',
			margin: '4px',
			fontSize: '40px',
			paddingLeft: '7px',
			backgroundColor: '#FBFBFB',
			color: '#828CA0',
			border: '1px solid #EDEFF2',
			borderRadius: '2px',
			textAlign: 'center',
		},
		inputStyleInvalid: {
			fontFamily: 'Roboto',
			fontWeight: 500,
			MozAppearance: 'textfield',
			width: windowDimensions.width > 835 ? '5rem' : '20vw',
			height: windowDimensions.width > 835 ? '5rem' : '20vw',
			margin: '4px',
			fontSize: '40px',
			paddingLeft: '7px',
			backgroundColor: '#FBFBFB',
			color: 'red',
			border: '1px solid red',
			borderRadius: '2px',
		},
	}

	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleCancel = () => {
		setIsMode(0)
		setPinCode('')
		setIsModalVisible(false)
	}

	const checkPin = () => {
		try {
			const getMnemonic = localStorage.getItem(COMMON_CONSTANT.ENCRYPTED_MNEMONIC)
			const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode)
			if (_decryptMnemonic) {
				return true
			} else {
				return false
			}
		} catch (e) {
			console.log(e)
		}
	}

	const onActivateAssets = async () => {
		const _check = checkPin()
		if (!_check) {
			setPinCode('')
			return setIsMode(3)
		}

		setIsLoading(true)

		const getMnemonic = localStorage.getItem(COMMON_CONSTANT.ENCRYPTED_MNEMONIC)
		const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode)

		await activateUserAsset(_decryptMnemonic, {
			user_id: walletUser.user_id,
			asset_id: props.data.asset_id,
		})

		await dispatch(getAllAssets(walletUser))
		setIsLoading(false)
	}

	const antIcon = <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />

	const resolveName = () => {
		switch (props.data.asset_name) {
			case ASSETS_NAME.IBFS:
				return 'IBFS'
			case ASSETS_NAME.IBFX_TYPE_I:
				return 'IBFX'
			case ASSETS_NAME.IBFX_TYPE_II:
				return 'IBFX - 2'
			case ASSETS_NAME.IBFX_TYPE_III:
				return 'IBFX - 3'
			case ASSETS_NAME.IBFX_TYPE_IV:
				return 'IBFX - 4'
			case ASSETS_NAME.IBFN:
				return 'IBFN'
		}
	}

	return (
		<>
			<ButtonOutline block size="large" onClick={showModal}>
				{t('activate')}
			</ButtonOutline>

			{windowDimensions.width > 835 ? (
				<Modal centered visible={isModalVisible} onCancel={handleCancel} closable={false} footer={null}>
					<ContentStyled>
						{/* onActivateAssets */}
						<ColCenter>
							<Title className="text-w700 gray-7 mb-0px" level={3}>
								{isMode === 0 ? `Activate ${resolveName()}` : 'Enter Pin'}
							</Title>
							{isMode === 0 ? (
								<Text className="gray-7 mt-12px" align="center">
									{t('are_you_sure_to_activate')} <span className="text-w600">{resolveName()}</span>
									<br />
									{t('wallet_start_exchanging')} {resolveName()} {t('tokens_sm')}
								</Text>
							) : (
								<Row className="mt-24px">
									<Col span={24}>
										<RowCenter>
											<ReactCodeInput type="password" onChange={(e) => setPinCode(e)} fields={4} {...codeProps} />
										</RowCenter>
									</Col>
									{isMode === 3 && (
										<Col className="mt-10px" span={24}>
											<Alert message={t('error_activating_assets')} type="error" showIcon />
										</Col>
									)}
								</Row>
							)}
							<Button
								block
								type="primary"
								className="mt-24px"
								size="large"
								onClick={() => (isMode === 0 ? setIsMode(1) : onActivateAssets())}
							>
								{isLoading ? antIcon : isMode === 0 ? t('activate') : t('submit')}
							</Button>

							<Button
								block
								className="mt-8px"
								size="large"
								onClick={() => {
									dispatch(openImportMnemonicModal('Reset PIN'))
									handleCancel()
								}}
							>
								{t('reset_pin')}
							</Button>
						</ColCenter>
					</ContentStyled>
				</Modal>
			) : (
				<Drawer visible={isModalVisible} onCancel={handleCancel} placement="bottom" closable={false}>
					<ContentStyled>
						{/* onActivateAssets */}
						<ColCenter>
							<Title className="text-w700 gray-7 mb-0px" level={3}>
								{isMode === 0 ? `Activate ${resolveName()}` : 'Enter Pin'}
							</Title>
							{isMode === 0 ? (
								<Text className="gray-7 mt-12px" align="center">
									{t('are_you_sure_to_activate')} <span className="text-w600">{resolveName()}</span>
									<br />
									{t('wallet_start_exchanging')} {resolveName()} {t('tokens_sm')}
								</Text>
							) : (
								<Row className="mt-24px">
									<Col span={24}>
										<RowCenter>
											<ReactCodeInput type="password" onChange={(e) => setPinCode(e)} fields={4} {...codeProps} />
										</RowCenter>
									</Col>
									{isMode === 3 && (
										<Col className="mt-10px" span={24}>
											<Alert message={t('error_activating_assets')} type="error" showIcon />
										</Col>
									)}
								</Row>
							)}
							<Button
								block
								type="primary"
								className="mt-24px"
								size="large"
								onClick={() => (isMode === 0 ? setIsMode(1) : onActivateAssets())}
							>
								{isLoading ? antIcon : isMode === 0 ? t('activate') : t('submit')}
							</Button>
							<Button
								block
								ghost
								className="mt-8px"
								type="primary"
								size="large"
								onClick={() => {
									dispatch(openImportMnemonicModal('Reset PIN'))
									handleCancel()
								}}
							>
								{t('reset_pin')}
							</Button>
							{/* <Button
                block
                className="mt-8px"
                size="large"
                onClick={() => {handleCancel()}}
              >
                Dismiss
              </Button> */}
						</ColCenter>
					</ContentStyled>
				</Drawer>
			)}
		</>
	)
}
