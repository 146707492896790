import styled from "@emotion/styled";
import { Typography } from "antd";

const { Text } = Typography;

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;

  .ant-upload.ant-upload-select {
    display: block;
  }
`;

export const TitleStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;
`;

export const TextStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* or 167% */

  text-align: center;

  /* Gray/gray-7 */

  color: #273248;
  max-width: 350px;
`;

export const SubtitleStyled = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #5b7697;
  text-align: center;

  &.selected {
    color: #364559;
  }
`;

export const StepContentStyled = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 24px;
  margin: 1rem 2.5%;

  background: #fff;
  // box-sizing: border-box;
  // box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px, rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  overflow: auto;

  .detail {
    padding: 0 20%;
  }

  @media screen and (min-width: 835px) {
    // height: 400px;
    width: 95%;
  }

  @media screen and (max-width: 834px) {
    width: 100%;
    height: 66vh;
    margin: 1rem 0;
    padding-bottom: 20px;
    .detail {
      padding: 0;
    }
  }

  &.height-auto {
    min-height: auto;
  }

  &.center {
    justify-content: center;
  }
`;
