import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import {
  AlertStyled,
  ContainerStyled,
  ContentStyled,
  LayoutStyled,
  LayoutCanvasStyled,
} from "./styled";
import CourseCart from "../components/CourseCart";
import CreateProjectModal from "../components/CreateProjectModal";
import ModalRedeemVoucher from "../components/ModalRedeemVoucher";
import { ColStart } from "../components/general_styled";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import ModalImportMnemonic from "../components/ModalImportMnemonic";
import { useDispatch, useSelector } from "react-redux";
import { openImportMnemonicModal } from "../modules/import_mnemonic_modal/action";
import { COMMON_CONSTANT } from "../constants/constant_common";
import useWindowDimensions from "../utils/windowDimensions";
import { USER_STATUS } from "../constants/constant_wallet";
import { getWalletUser } from "../modules/wallet/action";
import { useLocation, useHistory } from "react-router-dom";
import ModalLoginRegister from "../components/ModalLoginRegister";
import { useAccount } from "wagmi";
import { setUSerSign } from "../modules/general/action";
import { IS_EVM } from "../configs";

export default function Layouts(props) {
  const [needImportMnemonic, setNeedImportMnemonic] = useState(false);
  const [isUserAlreadySign, setIsUserAlreadySign] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [collapsedSidebar, setCollapsedSidebar] = useState(false);
  const dispatch = useDispatch();
  const windowDimensions = useWindowDimensions();
  const location = useLocation();
  const history = useHistory();
  const { address, isConnected } = useAccount();

  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const generalSection = useSelector((state) =>
    state.getIn(["general", "section"])
  );
  const userSign = useSelector((state) => state.getIn(["general", "userSign"]));
  const listNoPaddingPage = ["benevolence", "project-detail"];

  const checkNeedImportMnemonic = () => {
    const encryptedMnemonic = localStorage.getItem(
      COMMON_CONSTANT.ENCRYPTED_MNEMONIC
    );
    if (
      walletUser.status &&
      walletUser.status === USER_STATUS.ACTIVE &&
      !encryptedMnemonic
    ) {
      setNeedImportMnemonic(true);
    }
  };

  const isPathnameHasParam = (param) => {
    return location.pathname.toLowerCase().includes(param) || false;
  };

  useEffect(() => {
    const listPage = [
      "courses",
      "course-detail",
      "credence-nft-detail",
      "affluence",
      "marketplace",
      "benevolence-donate",
      "project-detail",
      "home"
    ];

    if (!listPage.find((i) => isPathnameHasParam(i))) {
      dispatch(getWalletUser());
      checkNeedImportMnemonic();
    }
  }, []);

  useEffect(() => {
    if (walletUser.status) {
      checkNeedImportMnemonic();
    }
  }, [walletUser]);

  useEffect(() => {
    const currentSignUser = localStorage.getItem("wagmi.usersign");
    if (currentSignUser && address === currentSignUser) {
      setIsUserAlreadySign(true);
    } else {
      setIsUserAlreadySign(false);
    }
  }, [address, userSign]);

  useEffect(() => {
    if (!isConnected) {
      dispatch(setUSerSign({ address: null }));
    }
  }, [isConnected]);

  return (
    <LayoutCanvasStyled collapsedSidebar={collapsedSidebar}>
      <Sidebar
        collapse={collapse}
        setCollapse={setCollapse}
        collapsedSidebar={collapsedSidebar}
        setCollapsedSidebar={setCollapsedSidebar}
      />
      <LayoutStyled collapsedSidebar={collapsedSidebar}>
        <ColStart>
          <Navbar
            collapsedSidebar={collapsedSidebar}
            collapse={collapse}
            setCollapse={setCollapse}
          />
          {!IS_EVM && needImportMnemonic && walletUser ? (
            <ColStart
              style={{
                position: "fixed",
                top: windowDimensions.width > 835 ? "64px" : "80px",
                width:
                  windowDimensions.width > 835
                    ? collapsedSidebar
                      ? "calc(100% - 80px)"
                      : "calc(100% - 250px)"
                    : "100%",
                zIndex: "19",
              }}
            >
              <AlertStyled
                message="It looks like you’re using a new browser, for security reasons you will need to verify your recovery phrase in order to continue using IBFNex - Netverse"
                type="warning"
                banner
                icon={<InfoCircleOutlined />}
                action={<RightOutlined style={{ cursor: "pointer" }} />}
                onClick={() => dispatch(openImportMnemonicModal())}
              />
            </ColStart>
          ) : IS_EVM && !isUserAlreadySign && isConnected ? (
            <ColStart
              style={{
                position: "fixed",
                top: windowDimensions.width > 835 ? "64px" : "80px",
                width:
                  windowDimensions.width > 835
                    ? collapsedSidebar
                      ? "calc(100% - 80px)"
                      : "calc(100% - 250px)"
                    : "100%",
                zIndex: "19",
              }}
            >
              <AlertStyled
                message="It looks like you’re using a new wallet, for security reasons you will need to verify your wallet to continue using IBFNex - Netverse"
                type="warning"
                banner
                icon={<InfoCircleOutlined />}
                action={<RightOutlined style={{ cursor: "pointer" }} />}
                onClick={() => history.push("/profile")}
              />
            </ColStart>
          ) : (
            <></>
          )}
        </ColStart>
        <ContentStyled
          isFromCreateNFT={isPathnameHasParam("create-nft")}
          isNoPadding={listNoPaddingPage.find((i) => i === generalSection)}
          style={
            needImportMnemonic
              ? {
                  paddingTop: windowDimensions.width > 835 ? "120px" : "160px",
                }
              : {}
          }
        >
          <ContainerStyled>{props.children}</ContainerStyled>
        </ContentStyled>
        <CourseCart />
      </LayoutStyled>
      <CreateProjectModal />
      <ModalRedeemVoucher />
      <ModalImportMnemonic />
      <ModalLoginRegister />
    </LayoutCanvasStyled>
  );
}
