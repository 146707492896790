import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INPUT_STATUS, PROJECT_TYPE } from "../../constants/constant_common";
import { ButtonOutline, ColCenter } from "../../components/general_styled";
import StepOne from "../../components/CreateProjectModal/Steps/StepOne";
import {
  FormBodyStyled,
  FormStyled,
  FormSubtitleStyled,
  FormTitleStyled,
} from "../../components/CreateProjectModal/styled";
import { useTranslation } from "react-i18next";
import StepTwo from "../../components/CreateProjectModal/Steps/StepTwo";
import StepThree from "../../components/CreateProjectModal/Steps/StepThree";
import StepFour from "../../components/CreateProjectModal/Steps/StepFour";
import { ButtonStyled } from "../CourseOnBoarding/styled";
import { Divider, Modal, Progress, Typography } from "antd";
import htmlToText from "html-to-text";
import { toIBFx, toUSD } from "../../utils/utils";
import { submitProject } from "../../modules/benevolence_projects/action";

import closeIcon from "../../assets/images/close.svg";
import { setSection } from "../../modules/general/action";
import useWindowDimensions from "../../utils/windowDimensions";
import { CloseImage } from "./styled";
import { IS_EVM, paymentList } from "../../configs";
import { useAccount } from "wagmi";

const initialState = {
  validateStatus: INPUT_STATUS.SUCCESS,
  help: "",
  value: null,
};

const { Text, Title } = Typography;

export default function CreateBenevolenceProject() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isConnected, address } = useAccount();

  const windowDimensions = useWindowDimensions();
  const benevolenceProjectType = useSelector((state) =>
    state.getIn(["general", "benevolenceProjectType"])
  );
  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );
  const token = useSelector((state) => state.getIn(["auth", "token"]));

  const [name, setName] = useState({ ...initialState, value: "" });
  const [description, setDescription] = useState({
    ...initialState,
    value: "",
  });
  const [startAt, setStartAt] = useState(initialState);
  const [endAt, setEndAt] = useState(initialState);
  const [fundGoal, setFundGoal] = useState({ ...initialState, value: "" });
  const [coverImage, setCoverImage] = useState(null);
  const [location, setLocation] = useState(initialState);
  const [assetProjectType, setAssetProjectType] = useState({
    ...initialState,
    value: "",
  });
  const [state, setState] = useState({ ...initialState, value: "" });
  const [country, setCountry] = useState({ ...initialState, value: "" });
  const [documents, setDocuments] = useState([]);
  const [contributions, setContributions] = useState([]);
  const [isAssetProject, setIsAssetProject] = useState(false);
  const [payment, setPayment] = useState({ ...initialState, value: "" });
  const [listSdgs, setListSdgs] = useState({ ...initialState, value: [] });
  const [category, setCategory] = useState({ ...initialState, value: "" });

  const [isProcessing, setProcessing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isFailed, setisFailed] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [percentage, setPercentage] = useState(10);

  const [isNgo, setIsNgo] = useState(false);
  const [ngoSelected, setNgoSelected] = useState(null);
  const [ngoName, setNgoName] = useState({ ...initialState, value: "" });
  const [ngoDescription, setNgoDescription] = useState({
    ...initialState,
    value: "",
  });
  const [ngoWebsite, setNgoWebsite] = useState({ ...initialState, value: {} });
  const [ngoCountry, setNgoCountry] = useState({ ...initialState, value: "" });
  const [ngoCoverImage, setNgoCoverImage] = useState(null);

  useEffect(() => {
    setIsAssetProject(benevolenceProjectType === PROJECT_TYPE.ASSET);
  }, [benevolenceProjectType]);

  const validate = () => {
    let isValid = true;
    if (isAssetProject) {
      if (!assetProjectType.value || assetProjectType.value === "") {
        setAssetProjectType({
          ...assetProjectType,
          validateStatus: INPUT_STATUS.ERROR,
          help: t("project_category_required"),
        });
        isValid = false;
      }
      if (!fundGoal.value || fundGoal.value <= 0) {
        setFundGoal({
          ...fundGoal,
          validateStatus: INPUT_STATUS.ERROR,
          help: t("fun_goal_more_zero"),
        });
        isValid = false;
      }
      if (!category.value || category.value === "") {
        setCategory({
          ...category,
          validateStatus: INPUT_STATUS.ERROR,
          help: 'Category is required',
        });
        isValid = false;
      }
    }
    
    if (!startAt.value || startAt.value === "") {
      setStartAt({
        ...startAt,
        validateStatus: INPUT_STATUS.ERROR,
        help: t("start_date_required"),
      });
      isValid = false;
    }
    if (
      !endAt.value ||
      endAt.value === "" ||
      new Date(endAt.value).getTime() - new Date(startAt.value).getTime() <
        604800
    ) {
      setEndAt({
        ...endAt,
        validateStatus: INPUT_STATUS.ERROR,
        help: t("end_date_required"),
      });
      isValid = false;
    }

    if (!country.value || country.value === "") {
      setCountry({
        ...country,
        validateStatus: INPUT_STATUS.ERROR,
        help: t("country_required"),
      });
      isValid = false;
    }

    if (!state.value || state.value === "") {
      setState({
        ...state,
        validateStatus: INPUT_STATUS.ERROR,
        help: t("state_required"),
      });
      isValid = false;
    }

    if (!coverImage || coverImage.length < 1) {
      throw t("cover_photo_required");
    }

    if (!name.value || name.value === "") {
      setName({
        validateStatus: INPUT_STATUS.ERROR,
        help: t("title_required"),
      });
      throw t("title_required");
    }

    if (!description.value || htmlToText.fromString(description.value) === "") {
      setDescription({
        validateStatus: INPUT_STATUS.ERROR,
        help: t("desc_required"),
      });
      throw t("desc_required");
    }

    if (!isAssetProject) {
      if (contributions.length < 1) {
        throw t("minimum_one_volunteer");
      }

      let isInvalid = false;
      for (const contribution of contributions) {
        if (!contribution.skill.value || contribution.skill.value === "") {
          contribution.skill = {
            ...contribution.skill,
            validateStatus: INPUT_STATUS.ERROR,
            help: t("skill_required"),
          };

          isInvalid = true;
        }

        if (
          !contribution.totalHour.value ||
          !/^[0-9]*$/.test(contribution.totalHour.value) ||
          Number(contribution.totalHour.value) <= 0
        ) {
          contribution.totalHour = {
            ...contribution.totalHour,
            validateStatus: INPUT_STATUS.ERROR,
            help: t("total_hour_required"),
          };

          isInvalid = true;
        }

        if (
          !contribution.ratePerHour.value ||
          !/^[0-9]*$/.test(contribution.ratePerHour.value) ||
          Number(contribution.ratePerHour.value) <= 0
        ) {
          contribution.ratePerHour = {
            ...contribution.ratePerHour,
            validateStatus: INPUT_STATUS.ERROR,
            help: t("rate_per_hour_required"),
          };

          isInvalid = true;
        }
      }

      if (isInvalid) {
        setContributions([...contributions]);
        throw t("please_check_input");
      }
    }

    if (!isValid) {
      throw t("please_check_input");
    }
  };

  const onSubmit = async () => {
    setVisible(true);
    setisFailed(false);
    setisSuccess(false);
    setProcessing(true);

    try {
      validate();
      setPercentage(30);

      if (isAssetProject) {
        const project = {
          name: name.value,
          description: description.value,
          start_at: startAt.value.format("X"),
          end_at: endAt.value.format("X"),
          fund_goal: toIBFx(Number(fundGoal.value) * ibfxPerUSD),
          asset_project_type: assetProjectType.value,
          category: category.value,
          cover_image_file: coverImage[0].response.filename,
          country_id: country.value,
          state_id: state.value,
          project_type: PROJECT_TYPE.ASSET,
          sdgs: listSdgs.value,
        };
        const project_documents = documents.map((document, key) => {
          return {
            file_name: document.response.filename,
            description: null,
            priority: key,
          };
        });

        const body = { project, project_documents };

        if (IS_EVM) {
          body.is_polygon = true;
          body.polygon_project = {
            payment: JSON.stringify(
              paymentList.find((i) => i.key === payment.value)
            ),
            wallet_address: address,
          };
          body.project.fund_goal = Number(fundGoal.value);
        }

        if (isNgo) {
          body.is_include_ngo = true;
          body.ngo_details = {
            name: ngoSelected ? ngoSelected.name : ngoName.value,
            description: ngoSelected
              ? ngoSelected.description
              : ngoDescription.value,
            website: ngoSelected
              ? ngoSelected.website
              : JSON.stringify(ngoWebsite.value),
            cover_image_file: ngoSelected
              ? ngoSelected.cover_image_file
              : ngoCoverImage[0].response.filename,
            country_id: ngoSelected ? ngoSelected.country_id : ngoCountry.value,
          };
        }

        await submitProject(token, body);
        setPercentage(70);
      } else {
        const project = {
          name: name.value,
          description: description.value,
          start_at: startAt.value.format("X"),
          end_at: endAt.value.format("X"),
          fund_goal: null,
          category: "SADAQAH",
          asset_project_type: null,
          cover_image_file: coverImage[0].response.filename,
          country_id: country.value,
          state_id: state.value,
          project_type: PROJECT_TYPE.EFFORT,
          sdgs: listSdgs.value,
        };
        const project_documents = documents.map((document, key) => {
          return {
            file_name: document.response.filename,
            description: null,
            priority: key,
          };
        });
        const volunteer_requirements = contributions.map((contribution) => {
          return {
            title: contribution.skill.value,
            rate_per_hour: toUSD(contribution.ratePerHour.value),
            working_hours: contribution.totalHour.value,
          };
        });
        const body = {
          project,
          project_documents,
          volunteer_requirements,
        };

        if (isNgo) {
          body.is_include_ngo = true;
          body.ngo_details = {
            name: ngoName.value,
            description: ngoDescription.value,
            website: JSON.stringify(ngoWebsite.value),
            cover_image_file: ngoCoverImage[0].response.filename,
            country_id: ngoCountry.value,
          };
        }

        await submitProject(token, body);
        setPercentage(70);
      }

      setProcessing(false);
      setisSuccess(true);
      setPercentage(100);
    } catch (e) {
      console.log(e);
      setisFailed(true);
      setProcessing(false);
      setisSuccess(false);
    }
  };

  useEffect(() => {
    if (ngoSelected !== null) {
      const data = {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: null,
      };
      setNgoDescription({
        ...data,
        value: ngoSelected.description,
      });
      // setNgoName({ ...data, value: ngoSelected.name });
      setNgoCountry({
        ...data,
        value: ngoSelected.country_id,
      });
      setNgoWebsite({
        ...data,
        value: JSON.parse(ngoSelected.website),
      });
    }
  }, [ngoSelected]);

  useEffect(() => {
    if (ngoSelected !== null && ngoSelected.name !== ngoName.value) {
      const bareData = { ...initialState, value: "" };
      setNgoDescription(bareData);
      setNgoWebsite({ ...bareData, value: {} });
      setNgoCountry(bareData);
      setNgoSelected(null);
    }
  }, [ngoName]);

  return (
    <>
      <ColCenter>
        <FormTitleStyled>
          {isAssetProject ? t("create_sa_project") : t("create_se_project")}
          <CloseImage
            style={{
              right: windowDimensions.width / 6,
            }}
            src={closeIcon}
            onClick={() => dispatch(setSection("benevolence-donate"))}
          />
        </FormTitleStyled>
        <FormSubtitleStyled>
          {isAssetProject
            ? t("create_sa_project_desc")
            : t("create_se_project_desc")}
        </FormSubtitleStyled>
        <FormStyled
          layout="vertical"
          className="create-project-form"
          labelAlign={"left"}
        >
          <FormBodyStyled className="pt-6px">
            <StepOne
              {...{
                assetProjectType,
                setAssetProjectType,
                startAt,
                setStartAt,
                endAt,
                setEndAt,
                fundGoal,
                isNgo,
                setFundGoal,
                state,
                setState,
                country,
                setCountry,
                location,
                setLocation,
                setPayment,
                setIsNgo,
                ngoName,
                setNgoName,
                ngoDescription,
                setNgoDescription,
                ngoWebsite,
                setNgoWebsite,
                ngoCountry,
                setNgoCountry,
                ngoCoverImage,
                setNgoCoverImage,
                ngoSelected,
                setNgoSelected,
                listSdgs,
                setListSdgs,
                category,
                setCategory,
              }}
              projectType={benevolenceProjectType}
            />
          </FormBodyStyled>
          <FormBodyStyled className="pt-6px pb-6px">
            <StepTwo
              {...{
                name,
                setName,
                description,
                setDescription,
                coverImage,
                setCoverImage,
              }}
            />
          </FormBodyStyled>
          <FormBodyStyled className="pt-0px">
            <StepThree
              {...{
                documents,
                setDocuments,
              }}
            />
          </FormBodyStyled>
          {!isAssetProject && (
            <FormBodyStyled className="pt-0px">
              <StepFour
                {...{
                  contributions,
                  setContributions,
                }}
              />
            </FormBodyStyled>
          )}
        </FormStyled>
        <Divider />
        <ButtonStyled
          disabled={IS_EVM && !isConnected}
          onClick={() => onSubmit()}
          type={"primary"}
          size="large"
          loading={isProcessing}
          style={{
            width: "73%",
          }}
        >
          {t("submit")}
        </ButtonStyled>
      </ColCenter>

      <Modal
        centered
        visible={visible}
        onCancel={() => setVisible(!visible)}
        footer={null}
        width="40%"
        closeIcon={
          <img
            style={{ marginRight: 10 }}
            src={closeIcon}
            onClick={() => setVisible(!visible)}
          />
        }
      >
        <ColCenter span={24}>
          <ColCenter style={{ paddingBottom: "24px" }}>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Create Project
            </Title>
            <Text className="gray-7">
              Please wait your Project is being Created
            </Text>
          </ColCenter>
          <ColCenter>
            <Progress
              type="circle"
              percent={percentage}
              status={isFailed && "exception"}
            />
            <Title className="text-w700 gray-7 mb-8px mt-24px" level={3}>
              {isProcessing && !isSuccess && !isFailed
                ? t("processing")
                : isSuccess && !isFailed
                ? t("project_submitted")
                : t("failed")}
            </Title>
            <Text className="gray-7" align="center">
              {isProcessing && !isSuccess && !isFailed
                ? ""
                : isSuccess
                ? t("project_submitted_desc")
                : "Failed to create project"}
            </Text>
            {!isProcessing && !isFailed && (
              <ButtonStyled
                className="mt-24px"
                type="primary"
                style={{
                  width: "100%",
                  height: "48px",
                  borderRadius: "4px",
                }}
                onClick={() => dispatch(setSection("benevolence-manage"))}
              >
                {isSuccess && !isFailed
                  ? t("check_approval")
                  : t("top_up_wallet")}
              </ButtonStyled>
            )}
            {!isProcessing && !isFailed && (
              <ButtonOutline
                className="mt-10px"
                style={{
                  width: "100%",
                  height: "48px",
                  borderRadius: "4px",
                }}
                onClick={() => setVisible(false)}
              >
                {isSuccess && !isFailed ? t("dismiss") : t("check_qa")}
              </ButtonOutline>
            )}
          </ColCenter>
        </ColCenter>
      </Modal>
    </>
  );
}
