import { fromJS } from "immutable";
import {
  SET_CREDENCE_NFTS_SUB,
  LOADING_CREDENCE_NFTS_SUB,
  FAILED_CREDENCE_NFTS_SUB,
} from "../constants";

const initialState = fromJS({
  isLoading: false,
  hasError: false,
  errorMessage: "",
  nfts: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    total: 0,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CREDENCE_NFTS_SUB:
      return state.merge({
        nfts: fromJS(action.payload),
        isLoading: false,
        errorMessage: "",
      });
    case LOADING_CREDENCE_NFTS_SUB:
      return state.merge({
        isLoading: true,
      });
    case FAILED_CREDENCE_NFTS_SUB:
      return state.merge({
        isLoading: false,
        errorMessage: fromJS(action.payload.data),
      });
    default:
      return state;
  }
}
