import React, { useState } from "react";
import { Modal, Typography, Drawer, Alert, Row, Col } from "antd";
import ReactCodeInput from "react-code-input";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  ButtonOutline,
  ColCenter,
  RowCenter,
  ButtonStyled,
} from "../general_styled";

import { ContentStyled, StepContentStyled } from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import StripeBodyCourses from "../StripeBodyCourses";
import { stripeKey } from "../../configs";
import { decryptMnemonic } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { ASSETS_NAME, ASSETS_STATUS } from "../../constants/constant_wallet";
import CourseOnBoarding from "../../pages/CourseOnBoarding";

const { Title, Text } = Typography;

export default function ModalCourseStripe(props) {
  const windowDimensions = useWindowDimensions();
  const stripeLoader = loadStripe(stripeKey);

  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [isPinError, setPinError] = useState(false);

  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "16vw",
      height: windowDimensions.width > 835 ? "5rem" : "16vw",
      margin: "4px",
      fontSize: "35px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "4rem" : "18vw",
      height: windowDimensions.width > 835 ? "4rem" : "18vw",
      margin: "4px",
      fontSize: "35px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmitPin = () => {
    setPinError(false);
    const _check = checkPin();
    if (!_check) {
      setPinCode("");
      return setPinError(true);
    }

    setCurrent(current + 1);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setPinCode("");
    setPinError("");
    setCurrent(0);
    setIsModalVisible(false);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const resolveContent = () => {
    switch (current) {
      case 0:
        return (
          <StepContentStyled className="center">
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Enter pin
              </Title>
              <Text className="gray-7" align="center">
                Please enter your pin code to confirm the transaction
              </Text>
              <Row className="mt-24px">
                <Col span={24}>
                  <RowCenter>
                    <ReactCodeInput
                      type="password"
                      onChange={(e) => setPinCode(e)}
                      fields={4}
                      {...codeProps}
                    />
                  </RowCenter>
                </Col>
                {isPinError && (
                  <Col className="mt-10px" span={24}>
                    <Alert
                      style={{
                        width: "90%",
                      }}
                      message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                      type="error"
                      showIcon
                    />
                  </Col>
                )}
              </Row>
              <ButtonStyled
                style={{
                  marginTop: 20,
                  height: 45,
                  width: "90%",
                }}
                onClick={() => onSubmitPin()}
              >
                {"Confirm Pin"}
              </ButtonStyled>
            </ColCenter>
          </StepContentStyled>
        );
      case 1:
        return (
          <StepContentStyled className="center">
            <Row className="mt-24px">
              <Col span={24}>
                <Elements stripe={stripeLoader}>
                  <StripeBodyCourses
                    project={props?.project || {}}
                    totalPayment={props.totalPayment}
                    pinCode={pinCode}
                    handleCancel={handleCancel}
                    setIsModalVisible={props.setIsModalVisible}
                    type={props.type || "course"}
                  />
                </Elements>
              </Col>
            </Row>
          </StepContentStyled>
        );
    }
  };

  return (
    <>
      {props.type === "donation" ? (
        <ButtonStyled
          disabled={props.disabled}
          onClick={showModal}
          style={{
            width: "98%",
            borderRadius: "10px",
          }}
          className="mb-12px mt-24px"
        >
          Continue to Payment
        </ButtonStyled>
      ) : (
        <ButtonOutline
          style={{ minHeight: "40px" }}
          className="mb-12px"
          block
          disabled={props.disabled}
          onClick={showModal}
        >
          Pay With Stripe
        </ButtonOutline>
      )}
      {Object.keys(walletUser).length <= 0 ||
      !localStorage.getItem("ENCRYPTED_MNEMONIC") ||
      allAssets.find((e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_I)
        ?.status !== ASSETS_STATUS.ACTIVE ? (
        <Modal
          centered
          className="modal-top-up"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          closeIcon={<img src={closeIcon} onClick={handleCancel} />}
          maskStyle={{ backgroundColor: "#f3f6fa" }}
        >
          <CourseOnBoarding />
        </Modal>
      ) : windowDimensions.width > 835 ? (
        <Modal
          centered
          className="modal-pay-course"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>{resolveContent()}</ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>{resolveContent()}</ContentStyled>
        </Drawer>
      )}
    </>
  );
}
