import { notification } from "antd";
import axios from "axios";
import { headers, MEMBERSHIP_HOST } from "../../configs";
import { GET_ANNOUNCEMENT, GET_ANNOUNCEMENT_FAIL, GET_ANNOUNCEMENT_SUCCESS } from "../constants";

export const getAnnouncement =
  (pagination = 1) =>
  async (dispatch) => {
    dispatch({ 
      type: GET_ANNOUNCEMENT
    });

    try {
      const jwt = localStorage.getItem("USER_AUTH_TOKEN");
      
      const response = await axios.get(`${MEMBERSHIP_HOST}/announcement-by-page`, {
        headers: {
          ...headers,
          'Authorization': `Bearer ${jwt}`
        },
        params: {
          page: pagination,
          size: 10
        },
      })

      const { data } = response.data
      dispatch({
        type: GET_ANNOUNCEMENT_SUCCESS,
        payload: {
          total: data.total_items,
          hasNext: pagination < data.total_pages,
          data: data.items,
          page: pagination,
        },
      })
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Failed!",
        description: e.response ? e.response.message : "Unknown Error",
      })
      dispatch({
        type: GET_ANNOUNCEMENT_FAIL,
        payload: {
          errorMessage: e,
        },
      })
    }
};
