import { combineReducers } from "redux-immutable";

import auth from "./auth/reducer";
import wallet from "./wallet/reducer";
import announcement from "./announcement/reducer";
import benevolence from "./benevolence/reducer";
import courses from "./courses/reducer";
import courseWishlist from "./course_wishlist/reducer";
import courseCart from "./course_cart/reducer";
import benevolenceProjects from "./benevolence_projects/reducer";
import benevolenceDonors from "./benevolence_donors/reducer";
import benevolenceAssetProjects from "./benevolence_asset_projects/reducer";
import redeemVoucherModal from "./redeem_voucher_modal/reducer";
import importMnemonicModal from "./import_mnemonic_modal/reducer";
import general from "./general/reducer";
import confluence from "./confluence/reducer";
import credence from "./credence/reducer";
import createNFT from "./create_nft/reducer";
import essence from "./essence/reducer";
import affluence from "./affluence/reducer";
import loans from "./loans/reducer";
import nftSub from "./nft_sub/reducer";

export const rootReducer = combineReducers({
  auth,
  wallet,
  announcement,
  courses,
  courseWishlist,
  courseCart,
  benevolenceProjects,
  benevolenceDonors,
  benevolenceAssetProjects,
  benevolence,
  redeemVoucherModal,
  general,
  importMnemonicModal,
  confluence,
  credence,
  createNFT,
  essence,
  affluence,
  loans,
  nftSub,
});
