import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  BENEVOLENCE_HOST,
  IS_EVM,
  headers,
  paymentList,
} from "../../../../configs";
import {
  ASSET_PROJECT_TYPE,
  PROJECT_TYPE,
} from "../../../../constants/benevolence_constant";
import {
  AutoCompleteStyled,
  ColStart,
  DatePickerStyled,
  DraggerStyled,
  InputStyled,
  RowCenter,
  RowSpaceBetween,
  RowStart,
  SelectStyled,
  TextAreaStyled,
} from "../../../general_styled";
import {
  Col,
  Select,
  Typography,
  Switch,
  Row,
  message,
  notification,
  AutoComplete,
} from "antd";
import { FormGroup } from "../../styled";
import { INPUT_STATUS } from "../../../../constants/constant_common";
import useWindowDimensions from "../../../../utils/windowDimensions";
import GridFour from "../../../../assets/images/icon_globe.svg";
import FileAdd from "../../../../assets/images/icon_file_add.svg";
import { getNgoByUserId } from "../../../../modules/benevolence_asset_projects/action";
import jwtDecode from "jwt-decode";
import {
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const { Title, Text } = Typography;

const category = ["ZAKAT", "SADAQAH", "WAQF", "QURBAN"];

export default (props) => {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [ngoProjects, setNgoProjects] = useState([]);
  const [sdgs, setSdgs] = useState([]);

  const [resultAutoComplete, setResultAutoComplete] = useState([]);

  const jwt = localStorage.getItem("USER_AUTH_TOKEN");

  const isWeb = windowDimensions.width > 600;

  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );

  useEffect(() => {
    getCountries();

    if (props.country.value !== "") {
      getStates(props.country.value);
    }
    getNgoProject();
    getSdgs();
  }, []);

  const getCountries = async () => {
    const result = (
      await axios.get(`${BENEVOLENCE_HOST}/countries`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
    ).data;
    setCountries(result.data);
  };

  const getNgoProject = async () => {
    const decodedUser = jwtDecode(jwt);
    const result = await getNgoByUserId({
      token: jwt,
      userId: decodedUser.user_id,
    });
    setNgoProjects(result.items);
  };

  const getStates = async (countryId) => {
    const result = (
      await axios.get(`${BENEVOLENCE_HOST}/states/get-by-country/${countryId}`)
    ).data;
    setStates(result.data);
  };

  const getSdgs = async () => {
    const result = (await axios.get(`${BENEVOLENCE_HOST}/sdgs/`)).data;
    setSdgs(result?.data?.items || []);
  };

  const onUpload = async (param) => {
    try {
      const formData = new FormData();
      formData.append("file", param.file, param.file.name);
      const response = (
        await axios.post(`${BENEVOLENCE_HOST}/files`, formData, {
          headers: headers,
        })
      ).data;
      param.onSuccess(response.data, param.file);
      message.success(t("success_upload_cover"));
    } catch (e) {
      const error = e.response
        ? e.response.data
          ? e.response.data
          : e.response
        : t("failed_to_upload_cover");
      param.onError(error, undefined);
      message.success(error);
    }
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    customRequest: onUpload,
    maxCount: 1,
    defaultFileList: props.ngoCoverImage ? [props.ngoCoverImage] : [],
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        props.setNgoCoverImage([...info.fileList]);
      } else if (status === "error") {
        notification.error({
          message: t("upload_failed"),
          description: info.file.error,
        });
      } else if (status === "removed") {
        props.setNgoCoverImage([...info.fileList]);
      }
    },
  };

  const handleSearch = (searchText) => {
    setResultAutoComplete(
      !searchText
        ? []
        : ngoProjects?.filter((i) => i.name.toLowerCase() === searchText)
    );
  };

  return (
    <Col span={18}>
      {/* <FormGroupTitle className="mt-24px mb-24px">
        {t("setup_your_project")}
      </FormGroupTitle> */}
      <RowSpaceBetween className="mt-12px mb-24px">
        <RowStart>
          <img src={GridFour} alt="grid-four" />
          <ColStart className="ml-8px">
            <Title
              className="text-size-14 text-w500 mb-0px mt-0px"
              level={5}
              align="left"
            >
              NGO Project
            </Title>
            <Text className="gray-5 text-size-12 text-w300">
              Enable this toggle if you represent an NGO, You will have to add
              additional details. Toggle is disabled by default for Individuals
            </Text>
          </ColStart>
        </RowStart>
        <Switch
          defaultChecked={false}
          onChange={(value) => props.setIsNgo(value)}
        />
      </RowSpaceBetween>
      {props.isNgo && (
        <>
          <RowCenter wrap={"nowrap"}>
            <Col className="input-column pr-12px" span={isWeb ? 12 : 24}>
              <FormGroup
                label="Name of the NGO"
                colon={false}
                validateStatus={props.ngoName.validateStatus}
                help={props.ngoName.help}
              >
                <AutoCompleteStyled
                  className="benevolence-auto"
                  onSelect={(val) => {
                    const projectSelected = ngoProjects.find(
                      (i) => i.name === val
                    );
                    props.setNgoSelected(projectSelected);
                  }}
                  onSearch={handleSearch}
                  placeholder="Enter Name of the NGO"
                  onChange={(val) => {
                    props.setNgoName({
                      validateStatus: INPUT_STATUS.SUCCESS,
                      help: "",
                      value: val,
                    });
                  }}
                >
                  {resultAutoComplete.length > 0
                    ? resultAutoComplete.map((i) => {
                        return (
                          <AutoComplete.Option value={i.name}>
                            {i.name}
                          </AutoComplete.Option>
                        );
                      })
                    : ngoProjects?.map((i) => {
                        return (
                          <AutoComplete.Option value={i.name}>
                            {i.name}
                          </AutoComplete.Option>
                        );
                      })}
                </AutoCompleteStyled>
              </FormGroup>
            </Col>
            <Col className="input-column" span={isWeb ? 12 : 24}>
              <FormGroup
                label="NGO Website"
                colon={false}
                validateStatus={props.ngoWebsite.validateStatus}
                help={props.ngoWebsite.help}
              >
                <InputStyled
                  value={props.ngoWebsite.value?.website_url}
                  type="text"
                  disabled={props.ngoSelected}
                  placeholder="Enter Website of the NGO"
                  onChange={(e) => {
                    props.setNgoWebsite({
                      validateStatus: INPUT_STATUS.SUCCESS,
                      help: "",
                      value: {
                        ...props.ngoWebsite.value,
                        website_url: e.target.value,
                      },
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </RowCenter>
          <FormGroup
            className="pt-6px"
            label={`${t("other_links")} ( ${t("optional")} )`}
          >
            <InputStyled
              className="my-4px"
              value={props.ngoWebsite.value?.linkedin_url}
              type="text"
              disabled={props.ngoSelected}
              placeholder="Enter LinkedIn Url of the NGO"
              prefix={<LinkedinOutlined className="mr-8px" />}
              onChange={(e) => {
                props.setNgoWebsite({
                  validateStatus: INPUT_STATUS.SUCCESS,
                  help: "",
                  value: {
                    ...props.ngoWebsite.value,
                    linkedin_url: e.target.value,
                  },
                });
              }}
            />
            <InputStyled
              className="my-4px"
              value={props.ngoWebsite.value?.twitter_url}
              type="text"
              disabled={props.ngoSelected}
              placeholder="Enter Twitter Url of the NGO"
              prefix={<TwitterOutlined className="mr-8px" />}
              onChange={(e) => {
                props.setNgoWebsite({
                  validateStatus: INPUT_STATUS.SUCCESS,
                  help: "",
                  value: {
                    ...props.ngoWebsite.value,
                    twitter_url: e.target.value,
                  },
                });
              }}
            />
            <InputStyled
              className="my-4px"
              value={props.ngoWebsite.value?.email_url}
              type="text"
              disabled={props.ngoSelected}
              placeholder="Enter Email of the NGO"
              prefix={<MailOutlined className="mr-8px" />}
              onChange={(e) => {
                props.setNgoWebsite({
                  validateStatus: INPUT_STATUS.SUCCESS,
                  help: "",
                  value: {
                    ...props.ngoWebsite.value,
                    email_url: e.target.value,
                  },
                });
              }}
            />
            <InputStyled
              className="my-4px"
              value={props.ngoWebsite.value?.instagram_url}
              type="text"
              disabled={props.ngoSelected}
              placeholder="Enter Instagram Url of the NGO"
              prefix={<InstagramOutlined className="mr-8px" />}
              onChange={(e) => {
                props.setNgoWebsite({
                  validateStatus: INPUT_STATUS.SUCCESS,
                  help: "",
                  value: {
                    ...props.ngoWebsite.value,
                    instagram_url: e.target.value,
                  },
                });
              }}
            />
          </FormGroup>
          <FormGroup
            className="pt-6px"
            label="Description"
            colon={false}
            validateStatus={props.ngoDescription.validateStatus}
            help={props.ngoDescription.help}
          >
            <TextAreaStyled
              rows={3}
              value={props.ngoDescription.value}
              disabled={props.ngoSelected}
              placeholder="Provide a brief description of your NGO (Max 250 Characters)"
              onChange={(e) => {
                if (props.ngoDescription.value.length <= 250) {
                  props.setNgoDescription({
                    validateStatus: INPUT_STATUS.SUCCESS,
                    help: "",
                    value: e.target.value,
                  });
                } else {
                  props.setNgoDescription({
                    validateStatus: INPUT_STATUS.ERROR,
                    help: t("max_length_title"),
                    value: e.target.value,
                  });
                }
              }}
            />
          </FormGroup>
          <FormGroup
            className="pt-6px"
            label={t("country")}
            colon={false}
            validateStatus={props.ngoCountry.validateStatus}
            help={props.ngoCountry.help}
          >
            <SelectStyled
              showSearch
              disabled={props.ngoSelected}
              value={props.ngoCountry.value}
              optionFilterProp="children"
              onChange={async (value) => {
                props.setNgoCountry({
                  validateStatus: INPUT_STATUS.SUCCESS,
                  help: "",
                  value,
                });
                getStates(value);
              }}
              placeholder={t("select_country")}
              defaultValue={
                props.ngoCountry.value !== "" ? props.ngoCountry.value : null
              }
            >
              {countries.map((country) => (
                <Select.Option value={country.country_id}>
                  {country.name}
                </Select.Option>
              ))}
            </SelectStyled>
          </FormGroup>
          {!props.ngoSelected && (
            <FormGroup
              className="mb-24px pt-6px"
              label="Upload NGO Logo"
              colon={false}
            >
              <DraggerStyled {...draggerProps}>
                <div className="upload-cover-photo">
                  <img src={FileAdd} />
                  <span className="title text-bold">Drag and Drop Items</span>
                  <span className="desc">
                    File types supported: JPG, PNG, GIF & SVG
                  </span>
                  <span className="desc pt-6px">
                    Logo needs to be 250x250 (1:1)
                  </span>
                </div>
              </DraggerStyled>
            </FormGroup>
          )}
        </>
      )}
      <RowCenter wrap={"nowrap"}>
        <Col className="input-column pr-12px" span={isWeb ? 12 : 24}>
          {props.projectType === PROJECT_TYPE.ASSET ? (
            <FormGroup
              label={t("project_type")}
              colon={false}
              validateStatus={props.assetProjectType.validateStatus}
              help={props.assetProjectType.help}
            >
              <SelectStyled
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  props.setAssetProjectType({
                    validateStatus: INPUT_STATUS.SUCCESS,
                    help: "",
                    value,
                  });
                }}
                placeholder={t("select_project_category")}
                defaultValue={
                  props.assetProjectType.value !== ""
                    ? props.assetProjectType.value
                    : null
                }
              >
                {Object.keys(ASSET_PROJECT_TYPE).map((type) => (
                  <Select.Option value={type}>
                    {type === ASSET_PROJECT_TYPE.FULLY_FUNDED
                      ? t("fully_funded")
                      : t("best_effort")}
                  </Select.Option>
                ))}
              </SelectStyled>
            </FormGroup>
          ) : (
            <></>
          )}
          <FormGroup
            className="pt-6px"
            label={t("start_date")}
            colon={false}
            validateStatus={props.startAt.validateStatus}
            help={props.startAt.help}
          >
            <DatePickerStyled
              placeholder={t("input_start_date")}
              value={props.startAt.value}
              onChange={(date, dateString) => {
                props.setStartAt({
                  validateStatus: INPUT_STATUS.SUCCESS,
                  help: "",
                  value: date,
                });
              }}
            />
          </FormGroup>
          <FormGroup
            className="pt-6px"
            label={t("country")}
            colon={false}
            validateStatus={props.country.validateStatus}
            help={props.country.help}
          >
            <SelectStyled
              showSearch
              optionFilterProp="children"
              onChange={async (value) => {
                props.setCountry({
                  validateStatus: INPUT_STATUS.SUCCESS,
                  help: "",
                  value,
                });
                getStates(value);
              }}
              placeholder={t("select_country")}
              defaultValue={
                props.country.value !== "" ? props.country.value : null
              }
            >
              {countries.map((country) => (
                <Select.Option value={country.country_id}>
                  {country.name}
                </Select.Option>
              ))}
            </SelectStyled>
          </FormGroup>
        </Col>
        <Col className="input-column" span={isWeb ? 12 : 24}>
          {props.projectType === PROJECT_TYPE.ASSET ? (
            <FormGroup
              label={t("fund_goal")}
              colon={false}
              validateStatus={props.fundGoal.validateStatus}
              help={props.fundGoal.help}
            >
              {IS_EVM ? (
                <RowSpaceBetween>
                  <SelectStyled
                    optionFilterProp="children"
                    onChange={(value) => {
                      props.setPayment({
                        validateStatus: INPUT_STATUS.SUCCESS,
                        help: "",
                        value,
                      });
                    }}
                    style={{ width: "30%" }}
                    placeholder="Select Payment"
                  >
                    {paymentList.map((val) => (
                      <Select.Option value={val.key}>{val.key}</Select.Option>
                    ))}
                  </SelectStyled>
                  <InputStyled
                    value={props.fundGoal.value}
                    placeholder={t("input_fund_goal")}
                    onChange={(value) => {
                      props.setFundGoal({
                        validateStatus: INPUT_STATUS.SUCCESS,
                        help: "",
                        value: value.target.value,
                      });
                    }}
                    style={{ width: "67%", borderRadius: "2px" }}
                  />
                </RowSpaceBetween>
              ) : (
                <InputStyled
                  prefix={<span style={{ marginRight: "10px" }}>$</span>}
                  suffix={
                    <span>
                      IBFX {(props.fundGoal.value * ibfxPerUSD).toFixed(2) || 0}
                    </span>
                  }
                  value={props.fundGoal.value}
                  placeholder={t("input_fund_goal")}
                  onChange={(value) => {
                    props.setFundGoal({
                      validateStatus: INPUT_STATUS.SUCCESS,
                      help: "",
                      value: value.target.value,
                    });
                  }}
                  style={{ width: "100%" }}
                />
              )}
            </FormGroup>
          ) : (
            <></>
          )}
          <FormGroup
            label={t("end_date")}
            colon={false}
            className="pt-6px"
            validateStatus={props.endAt.validateStatus}
            help={props.endAt.help}
          >
            <DatePickerStyled
              placeholder={"Input End Date"}
              value={props.endAt.value}
              onChange={(date, dateString) => {
                props.setEndAt({
                  validateStatus: INPUT_STATUS.SUCCESS,
                  help: "",
                  value: date,
                });
              }}
            />
          </FormGroup>
          <FormGroup
            label={t("state")}
            colon={false}
            className="pt-6px"
            validateStatus={props.state.validateStatus}
            help={props.state.help}
          >
            <SelectStyled
              showSearch
              optionFilterProp="children"
              onChange={(value) => {
                props.setState({
                  validateStatus: INPUT_STATUS.SUCCESS,
                  help: "",
                  value: value,
                });
              }}
              placeholder={"Select State"}
              defaultValue={props.state.value !== "" ? props.state.value : null}
            >
              {states.map((state) => (
                <Select.Option value={state.state_id}>
                  {state.name}
                </Select.Option>
              ))}
            </SelectStyled>
          </FormGroup>
        </Col>
      </RowCenter>
      <FormGroup
        className="pt-6px"
        label="Select SDGs That This Project Might Represent (Optional)"
        colon={false}
        validateStatus={props.listSdgs.validateStatus}
        help={props.listSdgs.help}
      >
        <SelectStyled
          showSearch
          mode="multiple"
          value={props.listSdgs.value}
          optionFilterProp="children"
          optionLabelProp="label"
          onChange={async (value) => {
            if (value.length < 4) {
              props.setListSdgs({
                validateStatus: INPUT_STATUS.SUCCESS,
                help: "",
                value,
              });
            }
          }}
          placeholder="SDGs"
        >
          {sdgs.map((sdg) => (
            <Select.Option value={sdg.sdg_id} label={sdg.name}>
              <span className="mr-4px">
                <img src={`${BENEVOLENCE_HOST}/files/${sdg.photo}`} />
              </span>
              {sdg.name}
            </Select.Option>
          ))}
        </SelectStyled>
      </FormGroup>

      {props.projectType === PROJECT_TYPE.ASSET && (
        <FormGroup
          className="pt-6px"
          label={"Category"}
          colon={false}
          validateStatus={props.category.validateStatus}
          help={props.category.help}
        >
          <SelectStyled
            showSearch
            value={props.category.value}
            optionFilterProp="children"
            onChange={async (value) => {
              props.setCategory({
                validateStatus: INPUT_STATUS.SUCCESS,
                help: "",
                value,
              });
              getStates(value);
            }}
            placeholder={"Select Category"}
            defaultValue={
              props.category.value !== "" ? props.category.value : null
            }
          >
            {category.map((i) => (
              <Select.Option value={i}>{i}</Select.Option>
            ))}
          </SelectStyled>
        </FormGroup>
      )}
    </Col>
  );
};
