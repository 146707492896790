import styled from '@emotion/styled'
import { Card } from 'antd'

export const CardStyled = styled(Card)`
	margin: 12px;
	border-radius: 4px;
	min-width: 300px;

	.ant-card-body {
		padding: 20px;
	}
`

export const DividerCard = styled.div`
	/* Shades/White */

	background: #fcfcfc;
	/* Neutral/500 */

	border: 1px solid #edeff2;
	box-sizing: border-box;
	/* Card Shadow */

	box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px, rgb(9 30 66 / 14%) 0px 0px 20px -6px;
	border-radius: 4px;
	width: 100%;
	height: 1px;
	margin: 24px 0;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
`
