import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Col,
  Row,
  Progress,
  Image,
  Form,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ColCenter,
  InputStyled,
  RowSpaceBetween,
  SelectStyled,
} from "../general_styled";
import closeIcon from "../../assets/images/close.svg";

import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  InputNumberReStyled,
} from "./styled";
import useWindowDimensions from "../../utils/windowDimensions";
import { INPUT_STATUS } from "../../constants/constant_common";
import TrashIcon from "../../assets/images/trash.svg";
import { AddContributionStyled, FormGroup } from "../CreateProjectModal/styled";
import { fromUSD } from "../../utils/utils";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import { contribute } from "../../modules/benevolence_projects/action";
import moment from "moment";

const { Title, Text } = Typography;

export default function ModalContributeVolunteer(props) {
  const windowDimensions = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMode, setIsMode] = useState(0);
  const [contributions, setContributions] = useState([]);
  const [ibfxEarned, setIbfxEarned] = useState(0);
  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );
  const userVolunteer = useSelector((state) =>
    state.getIn(["benevolence", "userVolunteer"]).toJS()
  );
  const token = useSelector((state) => state.getIn(["auth", "token"]));

  useEffect(() => {
    setIbfxEarned(calculateIBFxOrIBFsEarn());
  }, [contributions]);

  const calculateIBFxOrIBFsEarn = () => {
    return (
      contributions
        ?.map((c) => Number(c.ratePerHour.value) * Number(c.totalHour.value))
        .reduce((c1, c2) => c1 + c2, 0) * ibfxPerUSD
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsModalVisible(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (contributeValidation()) {
      try {
        for (const contribution of contributions) {
          await contribute(token, {
            project_volunteer: {
              volunteer_id: userVolunteer.volunteer_id,
              project_id: props.data.project_id,
              working_hours: Number(contribution.totalHour.value),
            },
            effort_requirement_id: contribution.skill.value,
          });
        }
        setIsMode(1);
        setContributions([
          {
            skill: {
              validateStatus: INPUT_STATUS.SUCCESS,
              help: "",
              value: "",
            },
            totalHour: {
              validateStatus: INPUT_STATUS.SUCCESS,
              help: "",
              value: 0,
            },
            ratePerHour: {
              validateStatus: INPUT_STATUS.SUCCESS,
              help: "",
              value: "",
            },
          },
        ]);
      } catch (e) {
        console.log(e);
        notification.error({
          message: "Failed",
          description: extractErrorMessage(e),
        });
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (contributions.length < 1) {
      addContribution();
    }
  }, []);

  const addContribution = () => {
    contributions.push({
      skill: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
      totalHour: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
      ratePerHour: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
    });
    setContributions([...contributions]);
  };

  const contributeValidation = () => {
    let isValid = true;
    let errorMessage = "";
    const existingSkill = [];
    for (const contribute of contributions) {
      //Basic validation
      if (!contribute.skill.value || contribute.skill.value === "") {
        isValid = false;
        errorMessage = "Please select one skill";
        break;
      } else if (
        contribute.totalHour.value === "" ||
        !contribute.totalHour.value ||
        !/^[0-9]*$/.test(contribute.totalHour.value) ||
        Number(contribute.totalHour.value) < 0
      ) {
        isValid = false;
        errorMessage = "Contribution hour must be more than zero";
        break;
      }

      //No duplicate skill
      if (existingSkill.includes(contribute.skill.value)) {
        isValid = false;
        errorMessage = "Duplicate selected skill";
        break;
      }

      //No exceed requirement hours
      const crSelected = props.data.effort_requirement.find((cr) => {
        return cr.effort_requirement_id === contribute.skill.value;
      });
      if (crSelected.working_hours < Number(contribute.totalHour.value)) {
        isValid = false;
        errorMessage =
          "Contribution time exceeds total hours required by the skill";
        break;
      }

      existingSkill.push(contribute.skill.value.value);
    }

    if (!isValid) {
      notification.error({
        message: "Failed",
        description: errorMessage,
      });
    }

    return isValid;
  };

  const deleteContribution = (key) => {
    const newContributions = [];
    for (var index = 0; index < contributions.length; index++) {
      if (index != key) {
        newContributions.push(contributions[index]);
      }
    }
    setContributions(newContributions);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const resolveSuccess = () => (
    <ColCenter className="h-100" span={24} style={{ minHeight: "400px" }}>
      <Progress type="circle" percent={100} />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        Success!
      </TitleStyled>
      <TextStyled>
        Your message has been sent, Our team will get back to you within 24
        hours
      </TextStyled>
      <Button
        block
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  const resolveContent = () => {
    if (Object.keys(props.data) <= 0) {
      return;
    }

    return (
      <Col span={24}>
        {contributions?.map((contribution, key) => {
          return (
            <Row
              gutter={[24, 24]}
              style={{
                marginBottom: "24px",
              }}
            >
              <Col span={contributions?.length > 1 ? 14 : 16}>
                <FormGroup
                  label="Skills"
                  colon={false}
                  validateStatus={contribution.skill.validateStatus}
                  help={contribution.skill.help}
                >
                  <SelectStyled
                    showSearch
                    optionFilterProp="children"
                    className="w-100"
                    type="dropdown"
                    name="skills"
                    size="large"
                    align="left"
                    placeholder="Select Skill"
                    value={contribution.skill.value}
                    options={props.data?.effort_requirement?.map((e) => {
                      return {
                        label:
                          e.title +
                          " - " +
                          fromUSD(e.rate_per_hour) * ibfxPerUSD +
                          " IBFX/Hr",
                        value: e.effort_requirement_id,
                      };
                    })}
                    onChange={async (val) => {
                      contributions[key].skill = {
                        validateStatus: INPUT_STATUS.SUCCESS,
                        help: "",
                        value: val,
                      };
                      contributions[key].ratePerHour = {
                        validateStatus: INPUT_STATUS.SUCCESS,
                        help: "",
                        value: fromUSD(
                          props.data.effort_requirement.find(
                            (e) => e.effort_requirement_id === val
                          ).rate_per_hour
                        ),
                      };
                      setContributions([...contributions]);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col span={8}>
                <FormGroup
                  label="Contribution (Hours)"
                  colon={false}
                  validateStatus={contribution.totalHour.validateStatus}
                  help={contribution.totalHour.help}
                >
                  <InputNumberReStyled
                    showSearch
                    optionFilterProp="children"
                    className="w-100"
                    name="contribution"
                    size="large"
                    align="left"
                    placeholder={"Contribution"}
                    value={contribution.totalHour.value}
                    onChange={async (val) => {
                      contributions[key].totalHour = {
                        validateStatus: INPUT_STATUS.SUCCESS,
                        help: "",
                        value: val,
                      };
                      setContributions([...contributions]);
                    }}
                  />
                </FormGroup>
              </Col>
              {contributions.length > 1 ? (
                <ColCenter span={1}>
                  <Image
                    preview={false}
                    width={24}
                    src={TrashIcon}
                    onClick={() => deleteContribution(key)}
                    style={{
                      pointer: "cursor",
                    }}
                  />
                </ColCenter>
              ) : (
                <></>
              )}
            </Row>
          );
        })}

        <AddContributionStyled
          style={{ height: 45 }}
          onClick={() => addContribution()}
        >
          <PlusOutlined className="mr-6px" />
          <span>Add Skill</span>
        </AddContributionStyled>
      </Col>
    );
  };

  return (
    <>
      <Button
        block
        type="primary"
        style={{ height: "40px" }}
        onClick={showModal}
        disabled={!(props?.data?.end_at > moment().unix()) || props.isVolunteerFull}
      >
        {props.text || "Contribute as Volunteer"}
      </Button>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="50%"
          footer={
            isMode === 0
              ? [
                  <RowSpaceBetween
                    style={{
                      width: "97%",
                      marginLeft: "1.5%",
                      marginRight: "1.5%",
                      marginTop: 12,
                    }}
                  >
                    <Text className="text-size-16">You Earn</Text>
                    <Text className="text-w500 gray-7 mb-0px text-size-18">
                      {ibfxEarned} IBFX/IBFS
                    </Text>
                  </RowSpaceBetween>,
                  <Button
                    style={{
                      height: 40,
                      width: "97%",
                      marginLeft: "1.5%",
                      marginRight: "1.5%",
                    }}
                    type="primary"
                    className="mt-24px mb-12px "
                    onClick={() => onSubmit()}
                  >
                    {isLoading ? antIcon : "Contribute"}
                  </Button>,
                ]
              : null
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {isMode === 1 ? (
              resolveSuccess()
            ) : (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  Volunteer
                </Title>
                <Text className="gray-7 " align="center">
                  {"You are contributing to\n" +
                    '"' +
                    props.data.name +
                    '" campaign'}
                </Text>
                <Form className="mt-32px mb-16px w-100" layout="vertical">
                  <Row>{resolveContent()}</Row>
                </Form>
              </ColCenter>
            )}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {isMode === 1 ? (
              resolveSuccess()
            ) : (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  Dispute
                </Title>
                <Text className="gray-7 mt-5px" align="center">
                  Dispute issue for <br />
                </Text>

                <Form className="mt-32px mb-16px w-100" layout="vertical">
                  <Row>{resolveContent()}</Row>
                </Form>
                <Button
                  block
                  type="primary"
                  className="mt-24px"
                  style={{ height: 40 }}
                  onClick={() => onSubmit()}
                >
                  {isLoading ? antIcon : "Contribute"}
                </Button>
              </ColCenter>
            )}
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
