import React, { useEffect, useState } from 'react'
import { Row, Col, Alert, Button } from 'antd'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingOutlined } from '@ant-design/icons'
import { TRANSACTION_TYPE } from '../../constants/constant_wallet'
import { ColSpaceBetween, ColStart, RowCenter } from '../general_styled'
import poweredStripeIcon from '../../assets/images/powered-stripe.svg'
import { createTransactions, getAllAssets, getWalletUser } from '../../modules/wallet/action'
import { InputStyled } from './styled'
import { COMMON_CONSTANT } from '../../constants/constant_common'
import { decryptMnemonic, fromIBFx } from '../../utils/utils'
import ModalLoading from '../ModalLoading'

export default function StripeBodyCourses(props) {
	const dispatch = useDispatch()

	const walletUser = useSelector((state) => state.getIn(['wallet', 'user']).toJS())
	const userData = useSelector((state) => state.getIn(['auth', 'userData']).toJS())
	const [isDisable, setIsDisable] = useState(true)
	const [isRequest, setRequest] = useState(false)
	const [isFailed, setFailed] = useState(false)
	const [isSuccess, setSuccess] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const stripe = useStripe()
	const elements = useElements()

	const onSubmitTopup = async () => {
		setRequest(true)
		setFailed(false)
		setErrorMessage('')

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return
		}

		const body = {
			receiver: walletUser.algo_address,
			asset_id: props.assetId,
			amount: props.amount,
			type: TRANSACTION_TYPE.TOPUP,
		}

		const getMnemonic = localStorage.getItem(COMMON_CONSTANT.ENCRYPTED_MNEMONIC)
		const _decryptMnemonic = decryptMnemonic(getMnemonic, props.pinCode)

		const clientSecret = await createTransactions(_decryptMnemonic, body, true)

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.

		const cardElement = elements.getElement(CardElement)
		const payload = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: cardElement,
				billing_details: {
					name: userData.name,
					email: userData.email,
				},
			},
		})
		if (payload.error) {
			setRequest(false)
			setFailed(true)
			setErrorMessage(payload.error.message)
			return false
		} else {
			setSuccess(true)
			setRequest(false)

			dispatch(getWalletUser(walletUser.user_id))
			dispatch(getAllAssets(walletUser))
			return true
		}
	}

	const handleChange = (val) => {
		if (val.empty || val.error !== undefined) {
			setIsDisable(true)
		} else {
			setIsDisable(false)
		}
	}

	const antIcon = <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />

	return (
		<ColSpaceBetween className="stretch" style={{ minHeight: 500 }}>
			<ColStart>
				<InputStyled className="mb-20px">
					<CardElement
						onChange={(val) => handleChange(val)}
						options={{
							style: {
								base: {
									fontSize: '16px',
									color: '#424770',
									'::placeholder': {
										color: '#aab7c4',
									},
								},
								invalid: {
									color: '#9e2146',
								},
							},
						}}
					/>
				</InputStyled>
				{isFailed ? (
					<Alert message={errorMessage} type="error" showIcon />
				) : isSuccess ? (
					<Alert message="Your payment is success" type="success" showIcon />
				) : (
					<></>
				)}
			</ColStart>
			<Row className="d-flex">
				<Col className="mb-12px" span={24}>
					<RowCenter>
						<img src={poweredStripeIcon} />
					</RowCenter>
				</Col>
				<Col span={24}>
					<ModalLoading
						disable={isDisable}
						handleCancel={props.handleCancel}
						type={'topup'}
						isLoading={isRequest}
						amount={fromIBFx(props.amount)}
						data={props.data}
						onProcess={onSubmitTopup}
					/>
					{/* <Button
            block
            type="primary"
            size="large"
            onClick={() => {
              onSubmitTopup()
            }}
          >
            {isRequest ? antIcon : "Submit"}
          </Button> */}
				</Col>
			</Row>
		</ColSpaceBetween>
	)
}
