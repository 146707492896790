import React from "react";
import { Image, Typography, Row, Col } from "antd";
import OnboardingBG from "../../assets/images/onboarding-bg.png";
import { ColCenter } from "../../components/general_styled";
import ModalOnboarding from "../../components/ModalOnboarding";
import useWindowDimensions from "../../utils/windowDimensions";
import ShimmerCustom from "../../components/ShimmerCustom";

const { Title, Text } = Typography;

export default function Onboarding(props) {
  const windowDimensions = useWindowDimensions();

  const resolveShimmer = (tab) => {
    let children = [];
    for (let i = tab ? 1 : 0; i < 5; i++) {
      children.push(
        <Col
          key={i}
          xs={tab ? 4 : 24}
          sm={tab ? 4 : 24}
          md={tab ? 4 : windowDimensions.width > 925 ? 12 : 24}
          lg={tab ? 4 : 12}
          xl={tab ? 4 : 8}
          xxl={tab ? 4 : 6}
        >
          <ShimmerCustom
            width={"100%"}
            height={tab ? "58px" : "186px"}
            borderRadius={tab ? "2px" : "4px"}
          />
        </Col>
      );
    }

    return children;
  };

  return props.isLoading ? (
    <>
      <Row gutter={[16, 16]} className="mb-24px">
        {resolveShimmer(true)}
      </Row>
      <Row gutter={[16, 16]}>{resolveShimmer()}</Row>
      <Row gutter={[16, 16]} className={"mt-32px"}>
        {resolveShimmer()}
      </Row>
    </>
  ) : (
    <ColCenter style={{ textAlign: "center", paddingTop: "56px" }}>
      <Image
        src={OnboardingBG}
        preview={false}
        width={windowDimensions.width > 835 ? 332 : "80%"}
        style={{ marginLeft: "32px" }}
      />
      <Title level={4}>Activation Required</Title>
      <Text
        className="mb-24px"
        style={{
          maxWidth: "800px",
        }}
      >
        IBFX Wallet is a unique blockchain based wallet designed exclusively for
        members of IBF Net. Using this wallet you will be able to earn,
        purchase, store and use IBFX and IBFS tokens.
      </Text>
      <ModalOnboarding />
    </ColCenter>
  );
}
