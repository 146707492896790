import { RowCenter } from "../general_styled";

export default function ConnectButton() {
  return (
    <>
      <RowCenter>
        <w3m-button />
      </RowCenter>
    </>
  );
}
