import React, { useEffect, useState } from 'react'
import { Modal, Button, Row, Col, Input, Drawer, Avatar, Radio } from 'antd'
import ReactStars from 'react-rating-stars-component'
import { getVolunteerById } from '../../modules/benevolence/action'
import { getUserDataById } from '../../modules/benevolence/action'
import useWindowDimensions from '../../utils/windowDimensions'
import { BENEVOLENCE_HOST } from '../../configs'
import userDummy from '../../assets/images/default-avatar.png'
import starActiveIcon from '../../assets/images/star-active.svg'
import starInactiveIcon from '../../assets/images/star-inactive.svg'
import closeIconModal from '../../assets/images/close-modal.svg'
import { ButtonStyled, ColCenter, RowCenter } from '../general_styled'

const { TextArea } = Input

export default function ModalVolunteer(props) {
	const windowDimensions = useWindowDimensions()
	// const [volunteerDetail, setVolunteerDetail] = useState(props?.selectedVolunteer?.volunteer || {})
	const volunteerDetail = props?.selectedVolunteer?.volunteer || {}

	// useEffect(() => {
	// 	if (props.modalMode === 'detail_user') {
	// 		getVolunteerDetail()
	// 	}
	// }, [props.modalMode])

	// const getVolunteerDetail = async () => {
	// 	let resGetUserById = await getUserDataById(props.selectedVolunteer.user_id, true)
	// 	let resGetVolunteerById = await getVolunteerById(props.selectedVolunteer.volunteer_id)

	// 	setVolunteerDetail({ ...resGetUserById, ...resGetVolunteerById.volunteer })
	// }

	return (
		<>
			{windowDimensions.width > 835 ? (
				<Modal
					centered
					visible={props.isModalOpen}
					onCancel={() => props.setModalOpen(false)}
					closeIcon={<img src={closeIconModal} onClick={() => props.setModalOpen(false)} />}
					width={props.modalMode === 'detail_user' ? '70%' : '40%'}
					footer={null}
				>
					{props.modalMode === 'detail_user' ? (
						<div className="canvas-detail-user">
							<h5>Volunteer Detail</h5>
							<p>View volunteering details for {`“${props.selectedVolunteer?.user?.name}”`}</p>

							<div className="volunteer-data">
								<div className="main-identity">
									<div className="row">
										<Avatar
											size={40}
											className="img-avatar"
											src={
												props.selectedVolunteer?.user?.photo
													? `${BENEVOLENCE_HOST}/files/${props.selectedVolunteer.user.photo}`
													: userDummy
											}
										/>
										<div className="col mt-4px">
											<h6 className="name">{props.selectedVolunteer?.user?.name}</h6>
											<span className="email">{props.selectedVolunteer?.user?.email}</span>
										</div>
									</div>
								</div>
								<Row style={{ width: '100%' }}>
									{/* <Col span={6}>
										<div className="col">
											<h6 className="label-custom">Nationality</h6>
											<span className="value">{volunteerDetail.nationality_name || '...'}</span>
										</div>
									</Col> */}
									<Col span={6}>
										<div className="col">
											<h6 className="label-custom">Country of Residence</h6>
											<span className="value">{volunteerDetail.Residence?.name || '...'}</span>
										</div>
									</Col>
									{volunteerDetail.phone_number && <Col span={6}>
										<div className="col">
											<h6 className="label-custom">Phone Number</h6>
											<span className="value">
												+{volunteerDetail.phone_number_code} {volunteerDetail.phone_number || '...'}
											</span>
										</div>
									</Col>}
									<Col span={6}>
										<div className="col">
											<h6 className="label-custom">Main Skill</h6>
											<span className="value">{volunteerDetail.job_title || '...'}</span>
										</div>
									</Col>
									{volunteerDetail && volunteerDetail.job_title_additional_1 ? <Col span={6}>
										<div className="col">
											<h6 className="label-custom">Additional Skill</h6>
											<span className="value">{volunteerDetail.job_title_additional_1}</span>
										</div>
									</Col> :  <Col span={6}>
										<div className="col">
											<h6 className="label-custom">State</h6>
											<span className="value">{volunteerDetail.State?.name || '...'}</span>
										</div>
									</Col>}
									{volunteerDetail && volunteerDetail.job_title_additional_2 && <Col span={6}>
										<div className="col">
											<h6 className="label-custom">Additional Skill</h6>
											<span className="value">{volunteerDetail.job_title_additional_2}</span>
										</div>
									</Col>}
								</Row>
							</div>
							<table className="skills">
								<tr>
									<th className="th-left">Skill</th>
									<th className="th-right">Hours</th>
								</tr>
								<tr>
									<td style={{ textTransform: 'capitalize' }}>{props.selectedVolunteer.title}</td>
									<td>{props.selectedVolunteer.working_hours}</td>
								</tr>
							</table>
						</div>
					) : (
						<div className="modal-volunteer">
							{props.modalMode === 'reject' || props.modalMode === 'review' ? (
								<>
									<h5>{props.modalMode === 'reject' ? 'Reject' : 'Review'} Volunteer</h5>
									<p>
										{props.modalMode === 'reject'
											? 'Please provide a reason for rejection'
											: 'Please rate and add feedback for the volunteer'}
									</p>
									<div className="volunteer-data">
										<Avatar
											size={40}
											className="img-avatar"
											src={
												props.selectedVolunteer?.user?.photo
													? `${BENEVOLENCE_HOST}/files/${props.selectedVolunteer?.user?.photo}`
													: userDummy
											}
										/>
										<h6 className="name">{props.selectedVolunteer?.user?.name}</h6>
										<span className="email">{props.selectedVolunteer?.user?.email}</span>
										<RowCenter className='pt-12px'>
											<h6 className="gray-5 text-size-12 text-w300 pr-6px pt-2px">Volunteer completed the task ?</h6>
											<Radio.Group value={props.isVolunteerCompleted} onChange={(e) => props.setIsVolunteerCompleted(e.target.value)} >
												<Radio value={true}>Yes</Radio>
												<Radio value={false}>No</Radio>
											</Radio.Group>
										</RowCenter>
										{props.modalMode === 'review' && props.isVolunteerCompleted && (
											<ColCenter>
												<ReactStars
													classNames="stars"
													count={5}
													onChange={(value) => {
														props.setRating(value)
													}}
													value={props.rating}
													size={24}
													activeColor="#ffd700"
													emptyIcon={<img src={starInactiveIcon} style={{ margin: '0 2px' }} />}
													filledIcon={<img src={starActiveIcon} style={{ margin: '0 2px' }} />}
												/>
												<h6 className="gray-5 text-size-12 text-w300 pr-6px pt-2px">Input Rating: {props.rating}</h6>
											</ColCenter>
										)}
									</div>
									{((props.modalMode === 'review' && props.isVolunteerCompleted) || props.modalMode === 'reject') && <TextArea
										rows={5}
										className="mb-16px"
										placeholder={props.modalMode === 'reject' ? 'Reason for Rejection' : 'Feedback'}
										value={props.modalMode === 'reject' ? props.rejectionField : props.reviewField}
										onChange={(e) =>
											props.modalMode === 'reject'
												? props.setRejectionField(e.target.value)
												: props.setReviewField(e.target.value)
										}
									/>}
									<Button loading={props.isProcessing} type="primary" onClick={() => props.onModalSubmitClicked()}>
										{props.modalMode === 'reject' ? 'Reject' : 'Submit'}
									</Button>
								</>
							) : (
								<></>
							)}

							<Button className="mt-8px" onClick={() => props.onModalCancelClicked()}>
								Cancel
							</Button>
						</div>
					)}
				</Modal>
			) : (
				<Drawer
					visible={props.isModalOpen}
					onCancel={() => props.setModalOpen(false)}
					placement="bottom"
					closeIcon={<img style={{ marginRight: 10 }} src={closeIconModal} onClick={() => props.setModalOpen(false)} />}
				>
					{props.modalMode === 'detail_user' ? (
						<div className="canvas-detail-user">
							<h5>Volunteer Detail</h5>
							<p>View volunteering details for {`“${props.selectedVolunteer?.user?.name}”`}</p>
							<img className="close-btn" src={closeIconModal} onClick={() => props.setModalOpen(false)} />
							<div className="volunteer-data">
								<div className="main-identity">
									<div className="row">
										<Avatar
											size={40}
											className="img-avatar"
											src={
												props.selectedVolunteer?.user?.photo
													? `${BENEVOLENCE_HOST}/files/${props.selectedVolunteer?.user?.photo}`
													: userDummy
											}
										/>
										<div className="col">
											<h6 className="name">{props.selectedVolunteer?.user?.name}</h6>
											<span className="email">{props.selectedVolunteer?.user?.email}</span>
										</div>
									</div>
								</div>
								<Row style={{ width: '100%' }}>
									<Col span={6}>
										<div className="col">
											<h6 className="label-custom">Nationality</h6>
											<span className="value">{volunteerDetail.nationality_name || '...'}</span>
										</div>
									</Col>
									<Col span={6}>
										<div className="col">
											<h6 className="label-custom">Country of Residence</h6>
											<span className="value">{volunteerDetail.residence_country_name || '...'}</span>
										</div>
									</Col>
									<Col span={6}>
										<div className="col">
											<h6 className="label-custom">State</h6>
											<span className="value">{volunteerDetail.state_name || '...'}</span>
										</div>
									</Col>
									<Col span={6}>
										<div className="col">
											<h6 className="label-custom">Phone Number</h6>
											<span className="value">
												+{volunteerDetail.phone_number_code} {volunteerDetail.phone_number || '...'}
											</span>
										</div>
									</Col>
								</Row>
							</div>
							<table className="skills">
								<tr>
									<th className="th-left">Skill</th>
									<th className="th-right">Hours</th>
								</tr>
								<tr>
									<td style={{ textTransform: 'capitalize' }}>{props.selectedVolunteer.title}</td>
									<td>{props.selectedVolunteer.working_hours}</td>
								</tr>
							</table>
						</div>
					) : (
						<div className="modal-volunteer">
							{props.modalMode === 'reject' || props.modalMode === 'review' ? (
								<>
									<h5>{props.modalMode === 'reject' ? 'Reject' : 'Review'} Volunteer</h5>
									<p>
										{props.modalMode === 'reject'
											? 'Please provide a reason for rejection'
											: 'Please rate and add feedback for the volunteer'}
									</p>
									<div className="volunteer-data">
										<Avatar
											size={40}
											className="img-avatar"
											src={
												props.selectedVolunteer?.user?.photo
													? `${BENEVOLENCE_HOST}/files/${props.selectedVolunteer?.user?.photo}`
													: userDummy
											}
										/>
										<h6 className="name">{props.selectedVolunteer?.user?.name}</h6>
										<span className="email">{props.selectedVolunteer?.user?.email}</span>
										{props.modalMode === 'review' && (
											<ReactStars
												classNames="stars"
												count={5}
												onChange={(value) => {
													props.setRating(value)
												}}
												size={24}
												activeColor="#ffd700"
												emptyIcon={<img src={starInactiveIcon} style={{ margin: '0 2px' }} />}
												filledIcon={<img src={starActiveIcon} style={{ margin: '0 2px' }} />}
											/>
										)}
									</div>
									<TextArea
										rows={5}
										placeholder={props.modalMode === 'reject' ? 'Reason for Rejection' : 'Feedback'}
										value={props.modalMode === 'reject' ? props.rejectionField : props.reviewField}
										onChange={(e) =>
											props.modalMode === 'reject'
												? props.setRejectionField(e.target.value)
												: props.setReviewField(e.target.value)
										}
									/>
									<ButtonStyled
										type="primary"
										danger
										loading={props.isProcessing}
										className="mt-16px mb-6px"
										onClick={() => props.onModalSubmitClicked()}
									>
										{props.modalMode === 'reject' ? 'Reject' : 'Send Review'}
									</ButtonStyled>
								</>
							) : (
								<></>
							)}

							<ButtonStyled type="primary" ghost onClick={() => props.onModalCancelClicked()}>
								Cancel
							</ButtonStyled>
						</div>
					)}
				</Drawer>
			)}
		</>
	)
}
