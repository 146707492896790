import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { SelectedMnemonicStyled, UnselectedMnemonicStyled } from "./styled";

const Verify = (props) => {
  const arrMnemonic = props.mnemonic ? props.mnemonic.split(" ") : [];
  const existValidateIndex = {};
  const existWords = {};
  const { Text } = Typography;

  useEffect(() => {
    props.setDisabled(true);
  }, []);

  const randomizeIndex = () => {
    var index = 0;
    do {
      index = (Math.random() * 24).toFixed(0);
    } while (existValidateIndex[index] && existValidateIndex[index] === true);
    existValidateIndex[index] = true;
    return index;
  };

  const randomizeWord = () => {
    var word = "";
    do {
      word = arrMnemonic[randomizeIndex()];
    } while (existWords[word] && existWords[word] === true);
    existWords[word] = true;
    return word;
  };

  const randomizeWordGroup = () => {
    const randomWord = { validateIndex: randomizeIndex(), words: [] };
    for (var i = 0; i < 3; i++) {
      randomWord.words.push({ isSelected: false, value: randomizeWord() });
    }
    const validIndex = (Math.random() * 2).toFixed(0);
    randomWord.words[validIndex].value = arrMnemonic[randomWord.validateIndex];
    return randomWord;
  };

  const randomizeWordGroups = () => {
    const randomWordGroups = [];
    for (var i = 0; i < 4; i++) {
      randomWordGroups.push(randomizeWordGroup());
    }

    return randomWordGroups;
  };

  const [isFailed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [randomWordGroups, setRandomWordGroups] = useState(
    props.mnemonic ? randomizeWordGroups() : {}
  );

  const handleSelectWord = (index, wordIndex) => {
    for (var i = 0; i < 3; i++) {
      randomWordGroups[index].words[i].isSelected = false;
    }
    randomWordGroups[index].words[wordIndex].isSelected = true;
    setRandomWordGroups({ ...randomWordGroups });
    handleOnClick();
  };

  //define randomWordGroups and RandomWordGenerator
  const displayWord = (index) => {
    const wordGroups = randomWordGroups[index];
    const words = [];
    for (var wordIndex = 0; wordIndex < 3; wordIndex++) {
      const currentIndex = wordIndex;
      words.push(
        !wordGroups.words[currentIndex].isSelected ? (
          <UnselectedMnemonicStyled
            key={currentIndex}
            onClick={() => handleSelectWord(index, currentIndex)}
          >
            {wordGroups.words[currentIndex].value}
          </UnselectedMnemonicStyled>
        ) : (
          <SelectedMnemonicStyled
            key={"selected" + currentIndex}
            onClick={() => handleSelectWord(index, currentIndex)}
          >
            {wordGroups.words[currentIndex].value}
          </SelectedMnemonicStyled>
        )
      );
    }

    return (
      <Col style={{ marginBottom: "20px" }}>
        <Text
          style={{
            fontSize: "13px",
            fontWeight: "600",
            lineHeight: "14px",
            letterSpacing: "0.1em",
          }}
        >
          SELECT WORD #<span>{Number(wordGroups.validateIndex) + 1}</span>
        </Text>
        <Row style={{ justifyContent: "space-between", marginTop: "12px" }}>
          {words}
        </Row>
      </Col>
    );
  };

  const verifyMnemonic = (index) => {
    const wordGroup = randomWordGroups[index];
    const validWord = arrMnemonic[wordGroup.validateIndex];
    const selectedWord = wordGroup.words.find((word) => {
      return word.isSelected;
    });

    if (!selectedWord || selectedWord.value !== validWord) {
      throw "Invalid selected words";
    }
  };

  const handleOnClick = () => {
    setFailed(false);
    setErrorMessage("");
    try {
      for (
        let index = 0;
        index < Object.keys(randomWordGroups).length;
        index++
      ) {
        verifyMnemonic(index);
      }
      props.setDisabled(false);
    } catch (e) {
      console.error(e);
      setFailed(true);
      setErrorMessage(e);
    }
  };

  const words = [];
  for (var index = 0; index < 4; index++) {
    words.push(displayWord(index));
  }

  return <>{words}</>;
};

export default Verify;
