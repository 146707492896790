import styled from "@emotion/styled";
import { Typography } from "antd";

const { Text, Title } = Typography;

export const CollectionNameStyled = styled.div`
  font-family: "Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Secondary/500 */

  color: #3b4552;


`;

export const TitleStyled = styled(Title)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;

  /* Text/500 */

  color: #293544;
  margin-bottom: 0 !important;
`;

export const UnlockableContent = styled.div`
  display: flex;

  padding: 4px 8px;

  background: #dee2e7;
  /* Secondary/300 */

  border: 1px solid #6b7c94;
  box-sizing: border-box;
  border-radius: 4px;

  /* Footer/ All Caps */

  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  /* identical to box height, or 140% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Secondary/600 */

  color: #2f3742;
`;

export const FullRoyalty = styled.div`
  display: flex;

  padding: 4px 8px;
  margin-left: 12px;

  background: rgba(253, 238, 213, 0.5);
  border: 1px solid #bc8803;

  box-sizing: border-box;
  border-radius: 4px;

  /* Footer/ All Caps */

  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #bc8803;
`;

export const PriceHistory = styled.div`
  background: #f4f6f7;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  margin: 20px 0;
`;

export const DividerCard = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 24px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const MoreStyled = styled.h1`
  // font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  /* Gray/gray-8 */

  color: #0b0e14;
  margin-top: 36px;
`;

export const PropertiesCard = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 20px;

  background: rgba(222, 226, 231, 0.2);
  /* Secondary/500 */

  border: 1px solid #3b4552;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    /* Caption/All Caps */

    // font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    /* identical to box height, or 117% */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Text/500 */

    color: #293544;
    margin-bottom: 8px;
  }

  .name {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* Text/400 */

    color: #364559;
  }

  .value {
    margin-top: 8px;
    /* Paragraph 01/Regular */

    // font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Text/300 */

    color: #42556e;
  }
`;

export const TextStyled = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`;

export const TextLabel = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text/300 */

  color: #42556e;
`;

export const TextValue = styled(Text)`
  /* Heading 06 (S) / Semibold */

  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  /* Text/400 */

  color: #364559;
`;
