import styled from "@emotion/styled";
import { Card, Drawer, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { ColSpaceBetween, RowSpaceBetween } from "../general_styled";

const { Title, Text } = Typography;

export const CanvasCart = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  padding: 24px 0px;

  @media screen and (min-width: 1200px) {
    padding: 24px 100px;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .cart-items {
    height: 100%;
    z-index: 5;
    position: relative;
    /* Shades/White */

    background: #ffffff;
    /* Neutral/600 */

    border: 1px solid #d6dae1;
    box-sizing: border-box;
    /* Alim Special Shadow */

    box-shadow: 0px 2px 2px rgba(212, 213, 217, 0.4);
    border-radius: 8px;
    padding: 24px;

    @media screen and (min-width: 835px) {
      padding-bottom: 64px;
      .back-button {
        position: absolute;
        bottom: 24px;
        left: 24px;
      }
    }
    @media screen and (max-width: 835px) {
      // height: 50vh;
      background: #ffffff;
      /* Neutral/600 */
      padding: 0 24px !important;

      border: none;
      box-sizing: border-box;
      /* Alim Special Shadow */

      box-shadow: none;
      border-radius: none;
      .back-button {
        margin-top: 24px;
      }
    }
  }

  @media screen and (max-width: 835px) {
    overflow-y: scroll;
    height: auto;
    padding: 0px;
    padding-top: 24px;
  }
`;

export const TitleStyled = styled(Title)`
  .ant-typography h5 {
    cursor: pointer;
  }
`;

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-content-wrapper {
    max-width: 100%;
  }

  .ant-drawer-content {
    height: 100vh !important;
    background-color: #fafafa;
  }

  .ant-drawer-header {
    padding: 20px 24px;
    border-bottom: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-item: center;
    background-color: #fafafa;
  }

  .ant-drawer-body {
    padding-top: 0 !important;
  }

  .ant-drawer-close {
    position: relative;
    padding: 0;
  }

  @media screen and (max-width: 835px) {
    .ant-drawer-content {
      background-color: #ffffff;
    }

    .ant-drawer-header {
      background-color: #ffffff;
    }
    .ant-drawer-body {
      padding: 0 !important;
      position: relative;
      height: calc(100% - 64px);

      .bottom-bar {
        z-index: 10;
        // position: fixed;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // min-height: 50vh;
        background-color: #ffffff;
      }
      .fixed {
        // position: fixed;
        // bottom: 0;
        // left: 20px;
        // right: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
`;

export const PaymentCard = styled.div`
  /* Shades/White */

  background: #ffffff;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  /* Alim Special Shadow */

  box-shadow: 0px 2px 2px rgba(212, 213, 217, 0.4);
  border-radius: 8px;
  padding: 20px 0;
`;

export const CartTitle = styled(Title)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 28px !important;
  line-height: 34px;
  /* identical to box height, or 121% */

  /* Text/500 */

  color: #293544;
  margin-bottom: 2px !important;
`;

export const CartSubTitle = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Text/400 */

  color: #364559;
  margin-bottom: 16px !important;
`;

export const CartItem = styled(Row)`
  padding: 20px 0px;
  border-bottom: 1px dashed #d6dae1;
`;

export const CourseDetails = styled(ColSpaceBetween)`
  margin-left: 12px;
  width: calc(100% - 84px);
  align-item: flex-start;
  text-align: left;
`;

export const PaymentList = styled(RowSpaceBetween)`
  margin-top: 12px;

  &:first-of-type {
    margin-top: 0px;
  }
`;

export const PaymentSummary = styled(RowSpaceBetween)`
  padding: 6px 0px;

  // border-top: 1px dashed #d6dae1;
  // border-bottom: 1px dashed #d6dae1;

  &.buy-nft {
    padding: 10px 14px;
    background: #fafafa;
    /* Neutral/600 */

    border: 1px solid #d6dae1;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const LabelNormal = styled(Text)`
  // font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/400 */

  color: #364559;
`;

export const LabelMedium = styled(Text)`
  /* Paragraph 02/ Regular */

  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */

  /* Text/400 */

  color: #364559;

  &.buy-nft {
    font-weight: 500;
    font-size: 15px !important;
  }

  .usd {
    /* Paragraph 02/ Regular */

    // font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 137% */

    /* Text/100 */

    color: #5b7697;

    &.black {
      color: #364559;
      font-weight: 700;
    }
  }
`;

export const ModalTitle = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;

  color: #0b0e14;
`;

export const ModalSubTitle = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #273248;
`;

export const BalanceLabel = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  color: #273248;
  margin-bottom: 0px;
`;

export const BalanceValue = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  display: flex;
  align-items: center;

  color: #44567b;
  margin-bottom: 0px;
`;

export const ContentStyled = styled(Content)`
  overflow: initial;
  border: none !important;
`;

export const BoxBalanceStyled = styled(RowSpaceBetween)`
  width: 100%;
  padding: 20px 22px;
  margin-top: 24px;
  background: #f7f8fa;

  border: 1px solid #edeff2;
  box-sizing: border-box;

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;

    color: #6b7c94;
  }

  .value {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    /* identical to box height */

    /* Gray/gray-7 */

    color: #273248;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='4%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  &.no-margin {
    margin: 0;
  }
`;

export const AlertDiscount = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  margin-top: 16px;

  &.green {
    background: #def6eb;
    color: #165639;
  }

  &.grey {
    background: #edeff2;
    color: #42556e;
  }

  /* Paragraph 01/Semibold */

  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Warning/800 */
`;

export const TitleMiniCard = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height, or 117% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text/500 */

  color: #293544;
`;
