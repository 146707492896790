import styled from "@emotion/styled";

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 412px;

  .ant-upload.ant-upload-select {
    display: block;
  }
`;

export const ContentCardStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  cursor: pointer;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 15px;

  &.selected {
    /* Shades/White */

    background: #ffffff;
    /* Neutral/600 */

    border: 1px solid #d6dae1;
    box-sizing: border-box;
    /* Card Elevation */

    // box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
    border-radius: 6px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .check {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const TitleStyled = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;

  &.selected {
    color: #364559;
  }
`;

export const SubtitleStyled = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #5b7697;
  text-align: center;

  &.selected {
    color: #364559;
  }
`;
