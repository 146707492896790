import styled from "@emotion/styled";
import { Button, Card, Divider, Typography } from "antd";

const { Title, Text } = Typography;
export const ContentChart = styled.div`
  padding-top: 50px;
`;
export const CardWrapper = styled.div`
  float: right;
`;

export const Rule = styled(Divider)`
  border: 0.5px dashed #d6dae1;
  height: 0;
`;

export const Button1 = styled(Button)`
  background-color: transparent;
  color: #024FF1;
  height: 45px;
  border: 2px solid #024FF1;
  color: #024FF1;
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
`;

export const Button2 = styled(Button)`
  background-color: #024FF1;
  height: 45px;
  border: 2px solid #024FF1;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
  float: right;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
`;

export const Course = styled(Text)`
  margin-top: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #0b0e14;
`;
export const MainCard = styled(Card)`
  border-radius: 4px;
  margin-top: 25px;

  background: #ffffff;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  /* Alim Special Shadow */

  box-shadow: 0px 2px 2px rgba(212, 213, 217, 0.4);
  border-radius: 4px;

  .ant-card-body {
    padding: 24px 24px;
  }
`;

export const CardTitle = styled(Title)`
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;

  color: #273248 !important;

  margin: 0 !important;
  margin-top: 0px !important;
  margin-bottom: 8px !important;
`;

export const OverviewText = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #44567b;
`;

export const Pic = styled.img`
  width: 67%;
`;

export const IBFx1 = styled.img`
  width: 25px;
  height: 25px;
`;

export const CardStyled = styled(Card)`
  background: #ffffff;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  /* Alim Special Shadow */

  box-shadow: 0px 2px 2px rgba(212, 213, 217, 0.4);
  border-radius: 4px;

  .enrolled-text {
    /* Paragraph 02/Semibold */

    // font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */

    /* Text/400 */

    color: #364559;
  }
`;
