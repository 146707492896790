import { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import { nftAirdropAddress } from "../../configs";
import AbiNFTAirdrop from "../../assets/abi/NFTAirdrop.json";
import { generateMerkleProof } from "../../modules/credence/action";
import { Button, notification } from "antd";

<LoadingOutlined style={{ fontSize: 20, color: "#0077c7" }} spin />;

export default function ClaimAirdrop(props) {
  const { data } = props;
  const { isConnected, address } = useAccount();
  const [merkleProof, setMerkleProof] = useState(null);
  const [addressIncluded, setAddressIncluded] = useState(false);
  const [alreadyClaim, setAlreadyClaim] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Check Claim
  const { data: dataCheckClaim } = useContractRead({
    address: nftAirdropAddress,
    abi: AbiNFTAirdrop,
    functionName: "alreadyClaim",
    args: [
      data?.contract_address, // nft_address
      address, // address receiver
      addressIncluded?.quantity, // quantity
    ],
  });

  // Claim
  const { config: configClaim } = usePrepareContractWrite({
    address: nftAirdropAddress,
    abi: AbiNFTAirdrop,
    functionName: "claim",
    args: [
      data?.contract_address, // nft_address
      merkleProof, // merkleProof
      addressIncluded?.quantity, // Quantity
    ],
  });
  const {
    writeAsync: writeClaim,
    data: dataClaim,
    isSuccess: isSuccessClaim,
    isError: isErrorClaim,
    isLoading: isLoadingClaim,
  } = useContractWrite(configClaim);

  const handleSubmit = async () => {
    // await writeAirdrop();
    setIsLoading(true);
    const _merkleProof = await generateMerkleProof({
      wallet_address: address,
      whitelist_address: data?.whitelist_address,
    });
    setMerkleProof(_merkleProof.data);
  };

  useEffect(() => {
    setAddressIncluded(
      data?.whitelist_address.find((i) => i.address === address)
    );
  }, [data, address]);

  useEffect(() => {
    if (merkleProof) {
      executeWriteClaim();
    }
  }, [merkleProof]);

  useEffect(() => {
    if (isSuccessClaim) {
      notification.success({ message: "Successfully Claim NFT" });
    }

    if (isErrorClaim) {
      notification.error({ message: "Failed Claim NFT" });
    }

    setIsLoading(false);
  }, [isSuccessClaim, dataClaim, isErrorClaim]);

  const executeWriteClaim = async () => {
    await writeClaim();
    setAlreadyClaim(true);
  };

  return (
    <Button
      onClick={() => handleSubmit()}
      type="primary"
      ghost
      className="ml-12px"
      disabled={
        !isConnected ||
        dataCheckClaim ||
        !addressIncluded ||
        alreadyClaim ||
        !data?.contract_address
      }
      // icon={<ReloadOutlined />}
      style={{ minHeight: "40px", minWidth: "140px" }}
      size={22}
    >
      {isLoading || isLoadingClaim ? (
        <LoadingOutlined style={{ fontSize: 20, color: "#0077c7" }} spin />
      ) : (
        "Claim Airdrop"
      )}
    </Button>
  );
}
