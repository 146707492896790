import React, { useEffect, useState } from "react";
import { Tag, Row, Col, Table, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ContentDashboard } from "./styled";
import { RowStart } from "../../components/general_styled";
import { TRANSACTION_STATUS } from "../../constants/constant_confluence";
import { getAllTransaction } from "../../modules/affluence/action";
// import { CONFLUENCE_HOST } from "../../configs"

import ModalConfluenceOffers from "../../components/ModalConfluenceOffers";
import ModalConfluenceOfferDetail from "../../components/ModalConfluenceOfferDetail";

import IBFxIcon from "../../assets/images/ibfx-icon.svg";
import { fromIBFx } from "../../utils/utils";
import ModalPendingOfferNFT from "../../components/ModalPendingOfferNFT";
import { setSection } from "../../modules/general/action";
import jwtDecode from "jwt-decode";

const { TabPane } = Tabs;

export default function AffluenceAuctionOffers() {
  const confluenceImpacts = useSelector((state) =>
    state.getIn(["confluence", "impacts"]).toJS()
  );
  const offers = useSelector((state) =>
    state.getIn(["affluence", "offers"]).toJS()
  );
  const [isTabSent, setIsTabSent] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getData(1);
  }, [isTabSent]);

  const onPageChange = (num) => {
    getData(num);
  };

  const getData = (num) => {
    const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
    dispatch(getAllTransaction(decoded.user_id, num, null, "BID", !isTabSent));
  };

  const sentColoumn = [
    {
      title: "Amount",
      dataIndex: "total_ibfx",
      key: "amount",
      width: 120,
      render: (token) => (
        <RowStart className="align-start">
          <img
            src={IBFxIcon}
            style={{
              paddingRight: 4,
            }}
          />
          <p className="text-size-12">{fromIBFx(token)}</p>
        </RowStart>
      ),
    },

    {
      title: "Received on",
      dataIndex: "created_at",
      key: "received_on",
      width: 180,
      render: (date) => <>{moment(date).format("D-MMM-YYYY")}</>,
    },
    {
      title: "NFT",
      dataIndex: "auction",
      key: "auction",
      render: (auction, record) => {
        const metadata = JSON.parse(auction?.NFT?.metadata_json);
        return (
          <a
            onClick={() =>
              dispatch(setSection("credence-nft-detail", record.auction_id))
            }
          >
            {metadata?.name +
              (metadata?.collection?.name === null
                ? ""
                : " - " + metadata?.collection?.name)}
          </a>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        let color = "green";
        let text = "";

        if (status === TRANSACTION_STATUS.OUTBID) {
          color = "volcano";
          text = "outbid";
        } else if (status === TRANSACTION_STATUS.FAILED) {
          color = "volcano";
          text = "failed";
        } else if (status === TRANSACTION_STATUS.SUCCESS) {
          color = "yellow";
          text = "pending";
        } else if (status === TRANSACTION_STATUS.PENDING) {
          color = "yellow";
          text = "pending";
        } else if (status === TRANSACTION_STATUS.INIT) {
          color = "cyan";
          text = "init";
        } else if (status === TRANSACTION_STATUS.BID_ACCEPTED) {
          color = "green";
          text = "success";
        } else if (status === TRANSACTION_STATUS.BID_ACCEPT_FAILED) {
          color = "red";
          text = "Cancelled";
        } else if (status === TRANSACTION_STATUS.BID_ACCEPT_PENDING) {
          color = "yellow";
          text = "counter pending";
        }
        return (
          <>
            <Tag className="tag-custom" color={color}>
              {text.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 200,
      render: (_, record) => {
        if (isTabSent) {
          return <ModalPendingOfferNFT data={record} isView />;
        } else {
          return <ModalPendingOfferNFT data={record} />;
        }
      },
    },
  ];

  return (
    <>
      <ContentDashboard>
        <Tabs
          className="w-100"
          defaultActiveKey={"sent"}
          size="large"
          onChange={(val) => setIsTabSent(val === "sent")}
        >
          <TabPane tab="Sent" key="sent" />
          <TabPane tab="Received" key="received" />
        </Tabs>
        <Row className="mt-24px">
          <Col span={24}>
            <Table
              dataSource={offers.data}
              columns={sentColoumn}
              scroll={{ x: 1000 }}
              pagination={{
                onChange: onPageChange,
                current: offers.currentPage,
                pageSize: 12,
                total: offers.total,
              }}
            />
          </Col>
        </Row>
      </ContentDashboard>
    </>
  );
}
