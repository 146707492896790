import styled from "@emotion/styled";
import { Typography } from "antd";
import Search from "antd/lib/input/Search";
import AfBg from "../../assets/images/aff.png";

const { Text } = Typography;
export const TitleStyled = styled(Text)`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  /* identical to box height, or 118% */

  /* Text/500 */

  color: #0b0e14;
  margin-top: 24px;
  margin-bottom: 4px;
`;

export const DescStyled = styled(Text)`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 143% */

  text-align: center;

  /* Text/400 */

  color: #273248;

  max-width: 600px;
  margin-bottom: 24px;
`;

export const SearchStyled = styled(Search)`
  .ant-input-search-button,
  .ant-input-affix-wrapper,
  .ant-input,
  .ant-input::placeholder {
    background: #f4f6f7;
    color: #44567b;
  }
`;

export const BannerStyled = styled.div`
  background-image: url(${AfBg});
  background-size: cover;
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 14px;
`;

export const BannerContent = styled.div`
  width: 510px;
`;

export const BannerTitle = styled.p`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: #ffffff;
`;

export const BannerSubtitle = styled.p`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  color: #ffffff;

  margin-top: -16px;
`;

export const BannerTrending = styled.p`
  // font-family: 'Roboto';
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  /* or 140% */
  font-weight: bold;

  color: #ffffff;

  opacity: 0.8;

  &.result {
    font-weight: lighter;
  }
`;
