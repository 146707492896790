import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { setSelectedCollection } from "../../modules/affluence/action";
import { setSection } from "../../modules/general/action";
import { CardStyled, CollectionNameStyled, ImageStyled } from "./styled";
import { IS_EVM } from "../../configs";

export default function CardUserCollection(props) {
  const dispatch = useDispatch();

  const onCardClicked = () => {
    dispatch(setSelectedCollection(props.data));
    if (IS_EVM) {
      dispatch(
        setSection(
          "affluence-collection",
          props.data?.evm_collection_id.toString()
        )
      );
    } else {
      dispatch(
        setSection("affluence-collection", props.data?.collection_id.toString())
      );
    }
  };

  const renderImage = () => {
    const usedData = IS_EVM ? props?.data?.EvmNFTs : props.data?.NFTs;
    return usedData?.map((e, i) => {
      if (i < 4) {
        return (
          <Col key={"image-" + i} span={usedData?.length >= 3 ? 12 : 24}>
            <ImageStyled
              className={`img-radius-${i} ${usedData?.length === 1 && "full"}`}
              src={e.file}
            />
          </Col>
        );
      }
    });
  };

  return (
    <Col key={props.key} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
      <CardStyled onClick={() => onCardClicked()}>
        <Row>{renderImage()}</Row>
        <CollectionNameStyled>{props.data.name}</CollectionNameStyled>
      </CardStyled>
    </Col>
  );
}
