import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Modal,
  Typography,
  Button,
  Drawer,
  Progress,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  ButtonOutline,
  ColCenter,
  ColStart,
  InputNumberStyled,
  SelectStyled,
  RowCenter,
} from "../general_styled";

import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  DividerCard,
  LabelStyled,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import IBFxLogo from "../../assets/images/ibfx-icon.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import {
  openModalLoginRegister,
  setSection,
} from "../../modules/general/action";
import ReactCodeInput from "react-code-input";
import { decryptMnemonic, toIBFx } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { getAllLoans, postCreateLoan } from "../../modules/loans/action";
import jwtDecode from "jwt-decode";
import { DEV } from "../../configs";
import CredenceOnBoarding from "../../pages/CredenceOnBoarding";

const { Title, Text } = Typography;

export default function ModalLoansNewRequest(props) {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMode, setIsMode] = useState(0);
  const [loanAmount, setLoanAmount] = useState(0);
  const [paybackDuration, setPaybackDuration] = useState();
  const [pinCode, setPinCode] = useState();
  const [isPinError, setPinError] = useState(false);

  const credenceUser = useSelector((state) =>
    state.getIn(["credence", "user"]).toJS()
  );

  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    if (!localStorage.getItem("USER_AUTH_TOKEN")) {
      return dispatch(openModalLoginRegister());
    }
    if (props.isPayLater) {
      setLoanAmount(props.loanAmount || 0);
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setPinCode();
    setPinError(false);
    setIsModalVisible(false);

    if (props.isPayLater) {
      if (!localStorage.getItem("USER_AUTH_TOKEN")) {
        dispatch(setSection("affluence-microfinance-myloans"));
      }
    }
  };

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.getTime();
  };

  const addMinutes = (date, minutes) => {
    var result = new Date(date);
    result.setDate(result.getMinutes() + minutes);
    return result.getTime();
  };

  const onSubmit = async (mnemonic) => {
    setIsLoading(true);

    let _tempDeadline =
      paybackDuration < 1
        ? addMinutes(new Date(), paybackDuration * 100)
        : addDays(new Date(), paybackDuration);

    await postCreateLoan(mnemonic, {
      total_ibfx: toIBFx(loanAmount),
      deadline: _tempDeadline,
    });

    await dispatch(
      getAllLoans(
        jwtDecode(localStorage.getItem("USER_AUTH_TOKEN")).user_id,
        null,
        1
      )
    );

    setIsMode(1);
    // setIsLoading(false)
    // handleCancel()
  };

  const checkPin = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = await decryptMnemonic(getMnemonic, pinCode);
      setPinError(false);
      await onSubmit(_decryptMnemonic);
    } catch (e) {
      console.log(e);
      setPinError(true);
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const resolveSuccess = () => (
    <ColCenter className="h-100" span={24}>
      <Progress type="circle" percent={100} />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        Success!
      </TitleStyled>
      <TextStyled>
        You loan request has been submitted on the platform
      </TextStyled>
      <Button
        block
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  const resolveContent = () => {
    switch (isMode) {
      case 0:
        return (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Loan Request
            </Title>
            <Text className="gray-7 " align="center">
              Add details for your loan
            </Text>
            <ColStart className="w-100 mt-24px">
              <InputNumberStyled
                type="number"
                prefix={<img src={IBFxLogo} />}
                addonAfter={<Text italic>{"$" + loanAmount / ibfxPerUSD}</Text>}
                style={{ padding: "0px 0px 0px 12px" }}
                className="w-100"
                placeholder="Enter Loan Amount"
                min={0}
                value={loanAmount}
                onChange={(value) => {
                  setLoanAmount(value);
                }}
              />
              <LabelStyled>Payback Duration</LabelStyled>
              <SelectStyled
                className={"w-100 "}
                type="dropdown"
                name="payback_duration"
                size="large"
                align="left"
                placeholder={"Payback Duration"}
                value={paybackDuration}
                filterOption={true}
                onChange={async (val) => {
                  setPaybackDuration(val);
                }}
              >
                {DEV && <Select.Option value={0.1}>10 Minutes</Select.Option>}
                {DEV && <Select.Option value={0.15}>15 Minutes</Select.Option>}
                {DEV && <Select.Option value={0.3}>30 Minutes</Select.Option>}
                {DEV && <Select.Option value={0.6}>1 Hour</Select.Option>}
                <Select.Option value={7}>1 Week</Select.Option>
                <Select.Option value={14}>2 Weeks</Select.Option>
                <Select.Option value={30}>1 Month</Select.Option>
                <Select.Option value={60}>2 Month</Select.Option>
                <Select.Option value={90}>3 Month</Select.Option>
              </SelectStyled>
              <DividerCard />
              <Button
                block
                type="primary"
                disabled={loanAmount === 0 || !paybackDuration}
                style={{ height: 40, borderRadius: 4 }}
                onClick={() => setIsMode(2)}
              >
                {isLoading ? antIcon : "Request Loan"}
              </Button>
              <ButtonOutline
                block
                style={{ height: 40, borderRadius: 4 }}
                className="mt-12px"
                onClick={() => dispatch(setSection("WalletFAQ"))}
              >
                Check FAQ
              </ButtonOutline>
            </ColStart>
          </ColCenter>
        );
      case 1:
        return resolveSuccess();
      case 2:
        return (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Enter pin
            </Title>
            <Text className="gray-7" align="center">
              Please enter your pin code to confirm the transaction
            </Text>
            <Row className="mt-24px">
              <Col span={24}>
                <RowCenter>
                  <ReactCodeInput
                    type="password"
                    onChange={(e) => setPinCode(e)}
                    fields={4}
                    {...codeProps}
                  />
                </RowCenter>
              </Col>
              {isPinError && (
                <Col className="mt-10px" span={24}>
                  <Alert
                    message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                    type="error"
                    showIcon
                  />
                </Col>
              )}
            </Row>
            <Button
              style={{
                height: 40,
                borderRadius: 4,
                width: "calc(100% - 40px)",
              }}
              className="mx-20px mt-20px"
              type="primary"
              onClick={() => checkPin()}
              disabled={isLoading}
            >
              {!isLoading ? "Confirm Pin" : antIcon}
            </Button>
          </ColCenter>
        );
    }
  };

  return (
    <>
      {props.isPayLater ? (
        <ButtonOutline
          block
          className="ml-8px"
          style={{ height: 40, borderRadius: 4 }}
          onClick={showModal}
          disabled={props.disabled || false}
        >
          Avail Pay Later
        </ButtonOutline>
      ) : (
        <Button
          style={{
            height: 40,
            borderRadius: 4,
            minWidth: 200,
          }}
          type="primary"
          onClick={showModal}
        >
          New Loan Request
        </Button>
      )}
      {Object.keys(credenceUser).length <= 0 ||
      !localStorage.getItem("ENCRYPTED_MNEMONIC") ? (
        <Modal
          centered
          className="modal-top-up"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          closeIcon={<img src={closeIcon} onClick={handleCancel} />}
          maskStyle={{ backgroundColor: "#f3f6fa" }}
        >
          <CredenceOnBoarding />
        </Modal>
      ) : windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="500px"
          footer={null}
        >
          <ContentStyled>{resolveContent()}</ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>{resolveContent()}</ContentStyled>
        </Drawer>
      )}
    </>
  );
}
