import React, { useState } from "react";
import moment from "moment";
import { Modal, Typography, Button, Drawer, Progress } from "antd";
import {
  ButtonOutline,
  ColCenter,
  RowCenter,
  RowSpaceBetween,
  RowStart,
} from "../general_styled";
import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  AcceptButton,
  ModalContent,
  DividerCard,
  FooterButtonContent,
  ModalContentKey,
  ModalContentValue,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";

import ModalConfluenceOffers from "../ModalConfluenceOffers";
import ModalConfluenceOfferCounter from "../ModalConfluenceOfferCounter";

import ibfx1 from "../../assets/images/ibfx-1.svg";
import ibfx4 from "../../assets/images/ibfx-4.svg";
import { fromIBFx } from "../../utils/utils";
import ibfxIcon from "../../assets/images/affluence-shopee-ibfx.svg";

const { Title, Text } = Typography;

export default function ModalAffluenceRemoveListing(props) {
  const windowDimensions = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMode, setIsMode] = useState(2);
  const [isSuccess, setIsSuccess] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isActive = props.isActive;
  const data = props.metadata;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(2);
    setIsModalVisible(false);
  };

  const resolveSuccess = (num) => (
    <ColCenter className="h-100" span={24}>
      <Progress
        type="circle"
        percent={100}
        status={num ? "success" : "exception"}
      />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        {num ? "Success!" : "Failed"}
      </TitleStyled>
      <TextStyled>
        {num
          ? "You have succesfully claimed"
          : "Failed when claiming, please try again."}
      </TextStyled>
      <Button
        block
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  const resolvePending = () => (
    <ColCenter className="h-100" span={24}>
      <Progress type="circle" percent={10} status={"active"} />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        Claiming
      </TitleStyled>
      <TextStyled>Please wait while we process your request</TextStyled>
      <Button
        block
        disabled
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  const onSubmit = () => {
    setIsMode(3);
    setTimeout(() => {
      setIsMode(1);
      setIsSuccess(true);
    }, 1000);
  };

  const renderContent = () => {
    return (
      <ContentStyled>
        {isMode === 1 ? (
          isSuccess ? (
            resolveSuccess(1)
          ) : (
            resolveSuccess(0)
          )
        ) : isMode === 2 ? (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Delist NFT
            </Title>
            <Text className="gray-7 " align="center">
              Your listed NFT will be moved back to Credence
            </Text>
            <ModalContent>
              <RowCenter>
                <img
                  src="https://upload.wikimedia.org/wikipedia/id/5/56/Harry_potter_deathly_hallows_US.jpg"
                  style={{
                    width: 256,
                    height: 256,
                    objectFit: "cover",
                    borderRadius: 4,
                  }}
                  className="mb-24px"
                />
              </RowCenter>
              <RowSpaceBetween>
                <ModalContentKey>Fragmentation</ModalContentKey>
                <p>{data?.fragmentation || 0}</p>
              </RowSpaceBetween>
              <DividerCard style={{ marginTop: "-10px" }} />
              <RowSpaceBetween>
                <ModalContentKey>Listed on</ModalContentKey>
                <p>{moment(data?.sold_on).format("D-MMM-YYYY")}</p>
              </RowSpaceBetween>
              <DividerCard style={{ marginTop: "-10px" }} />
              <RowSpaceBetween>
                <ModalContentKey>Unclaimed Token</ModalContentKey>
                <RowStart className="align-start">
                  <img
                    src={ibfxIcon}
                    style={{
                      paddingRight: 4,
                    }}
                  />
                  <p className="text-size-12">{fromIBFx(data?.amount) || 0}</p>
                </RowStart>
              </RowSpaceBetween>
              <DividerCard style={{ marginTop: "-10px" }} />
            </ModalContent>
            <FooterButtonContent>
              <RowSpaceBetween>
                <Button
                  block
                  style={{ height: 40 }}
                  type="danger"
                  onClick={() => onSubmit()}
                  disabled={isLoading}
                >
                  {!isLoading ? "Delist NFT" : "Dismiss"}
                </Button>
              </RowSpaceBetween>
            </FooterButtonContent>
          </ColCenter>
        ) : (
          resolvePending()
        )}
      </ContentStyled>
    );
  };

  return (
    <>
      {props.isFromDetail ? (
        <ButtonOutline
          block
          className="ml-8px"
          style={{ height: 40, borderRadius: 4 }}
          onClick={() => {
            showModal();
          }}
        >
          Remove From Listing
        </ButtonOutline>
      ) : (
        <AcceptButton
          className={isActive ? "pointer" : "pointer"}
          onClick={() => {
            showModal();
          }}
        >
          Remove From Listing
        </AcceptButton>
      )}
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="500px"
          footer={null}
        >
          {renderContent()}
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
              alt="cancel-button"
            />
          }
        >
          {renderContent()}
        </Drawer>
      )}
    </>
  );
}
