import styled from "@emotion/styled";
import { Row, Tabs } from "antd";
import Search from "antd/lib/input/Search";

export const ContentChart = styled.div`
  padding-top: 50px;
`;

export const TabsStyled = styled(Tabs)`
`;

export const RowEnd = styled(Row)`
  justify-content: flex-end;
  align-items: center;
`;

export const SearchStyled = styled(Search)`
  .ant-input-search-button, .ant-input-affix-wrapper, .ant-input, .ant-input::placeholder {
    background: #F4F6F7;
    color: #44567B;
  }
`