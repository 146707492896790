import styled from '@emotion/styled'

export const ContentStyled = styled.div`
	position: relative;
	width: 100%;

	&.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (min-width: 835px) {
		height: ${(props) => (props.isMode === 0 ? '80vh' : props.isMode === 2 ? '40vh' : '60vh')};
		overflow: auto;
	}

	.ant-upload.ant-upload-select {
		display: block;
	}
`
