export const COMMON_CONSTANT = {
	ENCRYPTED_MNEMONIC: 'ENCRYPTED_MNEMONIC',
	WISHLIST_COURSE: 'WISHLIST_COURSE',
	WISHLIST_AUCTION: 'WISHLIST_AUCTION',
	WISHLIST_ESSENCE: 'WISHLIST_ESSENCE',
	CART_COURSE: 'CART_COURSE',
}

export const PROJECT_TYPE = {
	ASSET: 'ASSET',
	EFFORT: 'EFFORT',
}

export const PROJECT_STATUS = {
	SUBMITED: 'SUBMITED',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED',
	PENDING: 'PENDING',
	DEPLOYED: 'DEPLOYED',
	STARTING: 'STARTING',
	OPENED: 'OPENED',
	CLOSED: 'CLOSED',
	COMPLETED: 'COMPLETED',
}

export const INPUT_STATUS = {
	SUCCESS: 'success',
	WARNING: 'warning',
	ERROR: 'error',
	VALIDATING: 'validating',
}
