import axios from "axios";
import jwtDecode from "jwt-decode";
import { CREDENCE_HOST, IS_EVM } from "../../configs";
import { NFT_STATUS } from "../../constants/constant_credence";
import {
  SET_CREDENCE_DETAIL,
  SET_CREDENCE_NFTS,
  SET_CREDENCE_PURCHASED,
  SET_CREDENCE_USER,
  SET_ESSENCE_ONSALE,
  SET_CREDENCE_COLLECTION,
  SET_CREDENCE_COLLECTION_DETAIL,
  SET_USER_COLLECTION,
  SET_CREDENCE_PERCENTAGE,
} from "../constants";
import { signingOperation } from "../wallet/action";

export const getCredenceUserById = (userId, callback) => async (dispatch) => {
  try {
    const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
    const user = (
      await axios.get(
        `${CREDENCE_HOST}/users?user_id=${userId || decoded.user_id}`
      )
    ).data;
    if (callback) {
      return user;
    } else {
      dispatch({
        type: SET_CREDENCE_USER,
        payload: {
          data: user.data,
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getCredenceNFTByUserId =
  (userId, page = 1, callback) =>
  async (dispatch) => {
    try {
      const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
      };
      const nfts = (
        await axios.get(
          `${CREDENCE_HOST}/${IS_EVM ? "evm-nft" : "nft"}?user_id=${
            userId || decoded.user_id
          }&page=${page}&size=15`,
          { headers }
          // `${CREDENCE_HOST}/nft?user_id=0b380480-4073-11ec-a966-290dc59c8230&offset=${
          //   page - 1
          // }&limit=15&status=CREATED`
          // `${CREDENCE_HOST}/nft?user_id=bae43ec0-bec4-11eb-9259-51c4f9a401fc&offset=${
          //   page - 1
          // }&limit=15`
        )
      ).data;
      if (callback) {
        return nfts;
      } else {
        dispatch({
          type: SET_CREDENCE_NFTS,
          payload: {
            data: IS_EVM ? nfts.data.records.rows : nfts.data.records,
            nftsVault:
              (IS_EVM ? nfts.data.records.rows : nfts.data.records).filter(
                (nft) => nft.status === NFT_STATUS.CREATED
              ) || [],
            currentPage: page,
            hasNext: page * 15 < nfts.data.total_items,
            total: nfts.data.total_items,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getCredenceEvmNFTByAddress =
  (address, page = 1, callback, status) =>
  async (dispatch) => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
      };
      let url = `${CREDENCE_HOST}/evm-nft?creator_wallet_address=${address}&page=${page}&size=15`;
      if (status) url += `&status=${status}`;

      const nfts = (
        await axios.get(
          url,
          { headers }
          // `${CREDENCE_HOST}/nft?user_id=0b380480-4073-11ec-a966-290dc59c8230&offset=${
          //   page - 1
          // }&limit=15&status=CREATED`
          // `${CREDENCE_HOST}/nft?user_id=bae43ec0-bec4-11eb-9259-51c4f9a401fc&offset=${
          //   page - 1
          // }&limit=15`
        )
      ).data;
      if (callback) {
        return nfts;
      } else {
        dispatch({
          type: SET_CREDENCE_NFTS,
          payload: {
            data: IS_EVM ? nfts.data.records.rows : nfts.data.records,
            nftsVault:
              (IS_EVM ? nfts.data.records.rows : nfts.data.records).filter(
                (nft) => nft.status === NFT_STATUS.CREATED
              ) || [],
            currentPage: page,
            hasNext: page * 15 < nfts.data.total_items,
            total: nfts.data.total_items,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const registerUserCredence = async (algoAddress) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
    const result = (
      await axios.post(
        `${CREDENCE_HOST}/users`,
        { user_id: decoded.user_id, algo_address: algoAddress },
        {
          headers: headers,
        }
      )
    ).data;

    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getNftByNftId = async (nftId) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.get(
        `${CREDENCE_HOST}/${IS_EVM ? "evm-nft" : "nft"}/${nftId}`,
        {
          headers: headers,
        }
      )
    ).data;

    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const createNFT = async (data) => {
  try {
    const url = `${CREDENCE_HOST}/${IS_EVM ? "evm-nft" : "nft"}/create`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.post(url, data, {
        headers: headers,
      })
    ).data;

    return result
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const updateTxhashEvm = async (nftId, body) => {
  try {
    const url = `${CREDENCE_HOST}/evm-nft/txhash/${nftId}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.put(url, body, {
        headers: headers,
      })
    ).data;
    return result;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const updateTxhashEvmAirdrop = async (airdropId, body) => {
  try {
    const url = `${CREDENCE_HOST}/evm-nft-airdrop/txhash/${airdropId}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.put(url, body, {
        headers: headers,
      })
    ).data;
    return result;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const generateMerkleProof = async (body) => {
  try {
    const url = `${CREDENCE_HOST}/evm-nft-airdrop/merkle-proof/`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.post(url, body, {
        headers: headers,
      })
    ).data;
    return result;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const uploadCoverImage = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.post(`${CREDENCE_HOST}/files`, data, {
        headers: headers,
      })
    ).data;

    return result;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const createASANFT = async (mnemonic, id) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.put(
        `${CREDENCE_HOST}/nft/createASA/${id}`,
        {},
        {
          headers: headers,
        }
      )
    ).data;

    const signedOperations = await signingOperation(mnemonic, result.data);
    await axios.post(
      `${CREDENCE_HOST}/operations/submit_signature`,
      signedOperations,
      {
        headers: headers,
      }
    );
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const createSellEscrow = async (mnemonic, body) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.post(
        `${CREDENCE_HOST}/sell_escrow`,
        { ...body },
        {
          headers: headers,
        }
      )
    ).data;

    const signedOperations = await signingOperation(mnemonic, result.data);
    await axios.post(
      `${CREDENCE_HOST}/operations/submit_signature`,
      signedOperations,
      {
        headers: headers,
      }
    );
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const delistingSellEscrow = async (mnemonic, id) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.put(
        `${CREDENCE_HOST}/sell_escrow/${id}/close`,
        {},
        {
          headers: headers,
        }
      )
    ).data;

    const signedOperations = await signingOperation(mnemonic, result.data);
    await axios.post(
      `${CREDENCE_HOST}/operations/submit_signature`,
      signedOperations,
      {
        headers: headers,
      }
    );
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

/*
export const createASANFT = async (mnemonic, id) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    }
    const result = (
      await axios.post(
        `http://localhost:3003/api/sell_escrow/buy`,
        {
          "sell_escrow_id": "55325460-93dd-11ec-8150-5b97e8d42357",
          "total_ibfx": 10000000,
          "total_nft": 1
        },
        {
          headers: headers,
        }
      )
    ).data

    console.log({ mnemonic })

    const signedOperations = await signingOperation(mnemonic, result.data)
    await axios.post(
      `http://localhost:3003/api/operations/submit_signature`,
      signedOperations,
      {
        headers: headers,
      }
    )
  } catch (e) {
    console.log(e)
    throw e.message
  }
}
*/

export const getNftOnsale = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };

    const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
    const result = (
      await axios.get(`${CREDENCE_HOST}/nft/on-sale`, {
        headers: headers,
        params: {
          user_id: decoded.user_id,
          all_status: true,
        },
      })
    ).data;

    dispatch({
      type: SET_ESSENCE_ONSALE,
      payload: result.data.nfts,
    });
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getEvmNftOnsale =
  ({ ownerAddress, sellerAddress, page = 1, status }) =>
  async (dispatch) => {
    try {
      let url = `${CREDENCE_HOST}/evm-nft-transaction?page=${page}&size=100`;
      if (ownerAddress) {
        url += `&owner_address=${ownerAddress}`;
      }
      if (sellerAddress) {
        url += `&seller_address=${sellerAddress}`;
      }
      if (status) {
        url += `&status=${status}`;
      }

      const nfts = (await axios.get(url)).data;

      dispatch({
        type: SET_ESSENCE_ONSALE,
        payload: nfts.data?.records?.rows || [],
      });
    } catch (e) {
      console.log(e);
      throw e.message;
    }
  };

export const getEvmNftPurchased =
  ({ buyerAddress, page = 1 }) =>
  async (dispatch) => {
    try {
      let url = `${CREDENCE_HOST}/evm-nft-transaction-sub?page=${page}&size=100`;
      if (buyerAddress) {
        url += `&buyer_address=${buyerAddress}`;
      }

      const nfts = (await axios.get(url)).data;

      dispatch({
        type: SET_CREDENCE_PURCHASED,
        payload: nfts.data?.records?.rows || [],
      });
    } catch (e) {
      console.log(e);
      throw e.message;
    }
  };

export const getNftPurchased = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };

    const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
    const result = (
      await axios.get(`${CREDENCE_HOST}/transactions/purchased`, {
        headers: headers,
        params: {
          user_id: decoded.user_id,
        },
      })
    ).data;
    dispatch({
      type: SET_CREDENCE_PURCHASED,
      payload: result.data.nfts,
    });
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const setNftDetail = (data) => async (dispatch) => {
  dispatch({
    type: SET_CREDENCE_DETAIL,
    payload: data,
  });
};

export const getCollectionByUser =
  (userId, page = 1, callback) =>
  async (dispatch) => {
    try {
      const collections = (
        await axios.get(
          `${CREDENCE_HOST}/collections?user_id=${userId}&only_listing=true&page=${page}&size=150000`
        )
      ).data;
      if (callback) {
        return collections;
      } else {
        collections.data.records.forEach((r) => {
          r.NFTs.forEach((i) => {
            const metadata = JSON.parse(i.metadata_json);
            const file = i.cover_file
              ? CREDENCE_HOST + "/files/" + i.cover_file
              : `https://ipfs.io/ipfs/${metadata?.image?.replace(
                  "ipfs://",
                  ""
                )}`;
            i.file = file;
          });
        });

        dispatch({
          type: SET_CREDENCE_COLLECTION,
          payload: {
            data: collections.data.records,
            currentPage: page,
            hasNext: page * 15 < collections.data.total_items,
            total: collections.data.total_items,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getEvmCollectionByAddress =
  (address, page = 1, callback) =>
  async (dispatch) => {
    try {
      const collections = (
        await axios.get(
          `${CREDENCE_HOST}/evm-collections?address=${address}&only_listing=true&with_nft=true&page=${page}&size=150000`
        )
      ).data;
      if (callback) {
        return collections;
      } else {
        collections.data.records.forEach((r) => {
          r.EvmNFTs.forEach((i) => {
            const metadata = JSON.parse(i.metadata_json);
            const file = i.cover_file
              ? CREDENCE_HOST + "/files/" + i.cover_file
              : `https://ipfs.io/ipfs/${metadata?.image?.replace(
                  "ipfs://",
                  ""
                )}`;
            i.file = file;
          });
        });

        dispatch({
          type: SET_CREDENCE_COLLECTION,
          payload: {
            data: collections.data.records,
            currentPage: page,
            hasNext: page * 15 < collections.data.total_items,
            total: collections.data.total_items,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getCollectionDetailByUser =
  (collection_id, non_collection, user_id, page = 1, callback) =>
  async (dispatch) => {
    try {
      let url = `${CREDENCE_HOST}/nft?listing_asa=true?only_listing=true&page=${page}&size=100000`;
      if (collection_id) {
        url += `&collection_id=${collection_id}`;
      }
      if (non_collection) {
        url += `&non_collection=${true}`;
      }
      if (user_id) {
        url += `&user_id=${user_id}`;
      }
      const collections = (await axios.get(url)).data;
      if (callback) {
        return collections;
      } else {
        collections.data.records.forEach((i) => {
          const metadata = JSON.parse(i.metadata_json);
          const file = i.cover_file
            ? CREDENCE_HOST + "/files/" + i.cover_file
            : `https://ipfs.io/ipfs/${metadata?.image?.replace("ipfs://", "")}`;
          i.file = file;
          i.is_auction = i.Auctions.length > 0;
          i.price = i.is_auction
            ? i.Auctions[0]?.total_ibfx || 0
            : i.SellEscrows[0]?.price || 0;
        });

        dispatch({
          type: SET_CREDENCE_COLLECTION_DETAIL,
          payload: {
            data: collections.data.records,
            currentPage: page,
            hasNext: page * 15 < collections.data.total_items,
            total: collections.data.total_items,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getEvmCollectionDetailByAddress =
  (collection_id, non_collection, creator_wallet_address, page = 1, callback) =>
  async (dispatch) => {
    try {
      let url = `${CREDENCE_HOST}/evm-nft?listing_asa=true?only_listing=true&page=${page}&size=100000`;
      if (collection_id) {
        url += `&evm_collection_id=${collection_id}`;
      }
      if (non_collection) {
        url += `&non_collection=${true}`;
      }
      if (creator_wallet_address) {
        url += `&creator_wallet_address=${creator_wallet_address}`;
      }
      const collections = (await axios.get(url)).data;
      if (callback) {
        return collections;
      } else {
        collections?.data?.records?.rows?.forEach((i) => {
          const metadata = JSON.parse(i.metadata_json);
          const file = i.cover_file
            ? CREDENCE_HOST + "/files/" + i.cover_file
            : `https://ipfs.io/ipfs/${metadata?.image?.replace("ipfs://", "")}`;
          i.file = file;
        });

        dispatch({
          type: SET_CREDENCE_COLLECTION_DETAIL,
          payload: {
            data: collections.data.records.rows,
            currentPage: page,
            hasNext: page * 15 < collections.data.total_items,
            total: collections.data.total_items,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const updateDeleteStatus = async (id, body) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.put(`${CREDENCE_HOST}/nft/update-delete-status/${id}`, body, {
        headers: headers,
      })
    ).data;

    return result;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getCredenceCollectionUserById =
  (userId, callback) => async (dispatch) => {
    try {
      const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
      const user = (
        await axios.get(
          `${CREDENCE_HOST}/collections?user_id=${
            userId || decoded.user_id
          }&size=100000000&without_nft=true`
        )
      ).data;
      if (callback) {
        return user;
      } else {
        dispatch({
          type: SET_USER_COLLECTION,
          payload: {
            data: user.data,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getCredenceEvmCollectionUserByAddress =
  (address, callback) => async (dispatch) => {
    try {
      const user = (
        await axios.get(
          `${CREDENCE_HOST}/evm-collections?address=${address}&size=100000000`
        )
      ).data;
      if (callback) {
        return user;
      } else {
        dispatch({
          type: SET_USER_COLLECTION,
          payload: {
            data: user.data,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getCredencePercentage = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const data = (
      await axios.get(`${CREDENCE_HOST}/nft-categories/`, { headers })
    ).data;
    dispatch({
      type: SET_CREDENCE_PERCENTAGE,
      payload: {
        data,
      },
    });
  } catch (e) {
    console.log(e);
  }
};
