import React from "react"
import { Col, Row } from "antd"
import { useSelector } from "react-redux"
import CardBalance from "../../CardBalance"
import { ABILITIES_ASSETS } from "../../../constants/constant_wallet"
import ShimmerCustom from "../../ShimmerCustom"
import useWindowDimensions from "../../../utils/windowDimensions"

export default function WalletConvert(props) {
  const windowDimensions = useWindowDimensions()
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  )

  const resolveShimmer = () => {
    let children = []
    for (let i = 0; i < 5; i++) {
      children.push(
        <Col
          key={i}
          xs={24}
          sm={24}
          md={windowDimensions.width > 925 ? 12 : 24}
          lg={12}
          xl={8}
          xxl={6}
        >
          <ShimmerCustom width="100%" height="186px" borderRadius="4px" />
        </Col>
      )
    }

    return children
  }

  return (
    <>
      <Row gutter={props.isLoading && [16, 16]}>
        {props.isLoading
          ? resolveShimmer()
          : allAssets.map(
              (e, i) =>
                e.abitilites
                  .map((o) => o.abilityType)
                  .includes(ABILITIES_ASSETS.CONVERT) && (
                  <CardBalance key={i} data={e} type="convert" />
                )
            )}
      </Row>
    </>
  )
}
