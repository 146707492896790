import React, { useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Progress,
  Select,
  Row,
  Col,
  Alert,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  ButtonOutline,
  ColCenter,
  ColStart,
  InputNumberStyled,
  RowEnd,
  RowSpaceBetween,
  SelectStyled,
  TextAreaStyled,
  RowCenter,
} from "../general_styled";

import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  DividerCard,
  LabelStyled,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import IBFxLogo from "../../assets/images/ibfx-icon.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import { setSection } from "../../modules/general/action";
import ReactCodeInput from "react-code-input";
import { decryptMnemonic, fromIBFx, toIBFx } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import jwtDecode from "jwt-decode";
import { getAllLoans, putExtendDeadline } from "../../modules/loans/action";

const { Title, Text } = Typography;

export default function ModalLoansDeadlineExtension(props) {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMode, setIsMode] = useState(0);
  const [extensionFor, setExtensionFor] = useState();
  const [pinCode, setPinCode] = useState();
  const [isPinError, setPinError] = useState(false);

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    if (props.onCancel) {
      props.onCancel();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsModalVisible(false);
  };

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.getTime();
  };

  const onSubmit = async (mnemonic) => {
    setIsLoading(true);

    await putExtendDeadline(mnemonic, {
      extended_at: addDays(new Date(), extensionFor),
    });

    await dispatch(
      getAllLoans(
        jwtDecode(localStorage.getItem("USER_AUTH_TOKEN")).user_id,
        null,
        1
      )
    );

    setIsMode(1);
    // setIsLoading(false)
    // handleCancel()
  };

  const checkPin = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = await decryptMnemonic(getMnemonic, pinCode);
      setPinError(false);
      await onSubmit(_decryptMnemonic);
    } catch (e) {
      console.log(e);
      setPinError(true);
    }
  };
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const resolveSuccess = () => (
    <ColCenter className="h-100" span={24}>
      <Progress type="circle" percent={100} />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        Success!
      </TitleStyled>
      <TextStyled>Your loan has been extended.</TextStyled>
      <Button
        block
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  const resolveContent = () => {
    switch (isMode) {
      case 0:
        return (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Deadline Extension
            </Title>
            <ColStart className="w-100 mt-36px">
              <RowSpaceBetween>
                <Text>Sent on</Text>
                <Text>
                  {moment(props.record?.created_at).format(
                    "DD/MM/YYYY HH:mm A"
                  )}
                </Text>
              </RowSpaceBetween>
              <DividerCard className="list" />
              <RowSpaceBetween>
                <Text>Collateral Percentage</Text>
                <Text>50%</Text>
              </RowSpaceBetween>
              <DividerCard className="list" />
              <RowSpaceBetween>
                <Text className="text-w600">Loan Amount</Text>
                <RowEnd>
                  <img src={IBFxLogo} className="mr-6px" />
                  <Text className="text-w600">
                    {fromIBFx(props.record?.total_ibfx)}
                  </Text>
                </RowEnd>
              </RowSpaceBetween>
              <DividerCard className="list" />
              <LabelStyled>Extension For</LabelStyled>
              <SelectStyled
                className={"w-100 "}
                type="dropdown"
                name="payback_duration"
                size="large"
                align="left"
                placeholder={"Select"}
                value={extensionFor}
                filterOption={true}
                onChange={async (val) => {
                  setExtensionFor(val);
                }}
              >
                <Select.Option value={7}>1 Week</Select.Option>
                <Select.Option value={14}>2 Weeks</Select.Option>
                <Select.Option value={30}>1 Month</Select.Option>
                <Select.Option value={60}>2 Month</Select.Option>
                <Select.Option value={90}>3 Month</Select.Option>
              </SelectStyled>
              <DividerCard />
              <Button
                block
                type="primary"
                style={{ height: 40, borderRadius: 4 }}
                onClick={() => setIsMode(2)}
              >
                {isLoading ? antIcon : "Send"}
              </Button>
              <ButtonOutline
                block
                style={{ height: 40, borderRadius: 4 }}
                className="mt-12px"
                onClick={() => dispatch(setSection("WalletFAQ"))}
              >
                Check FAQ
              </ButtonOutline>
            </ColStart>
          </ColCenter>
        );
      case 1:
        return resolveSuccess();
      case 2:
        return (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Enter pin
            </Title>
            <Text className="gray-7" align="center">
              Please enter your pin code to confirm the transaction
            </Text>
            <Row className="mt-24px">
              <Col span={24}>
                <RowCenter>
                  <ReactCodeInput
                    type="password"
                    onChange={(e) => setPinCode(e)}
                    fields={4}
                    {...codeProps}
                  />
                </RowCenter>
              </Col>
              {isPinError && (
                <Col className="mt-10px" span={24}>
                  <Alert
                    message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                    type="error"
                    showIcon
                  />
                </Col>
              )}
            </Row>
            <Button
              style={{
                height: 40,
                borderRadius: 4,
                width: "calc(100% - 40px)",
              }}
              className="mx-20px mt-20px"
              type="primary"
              onClick={() => checkPin()}
              disabled={isLoading}
            >
              {!isLoading ? "Confirm Pin" : antIcon}
            </Button>
          </ColCenter>
        );
    }
  };

  return (
    <>
      <ButtonOutline
        block
        style={{ height: 40, borderRadius: 4 }}
        className="mt-12px"
        onClick={showModal}
      >
        Request Deadline Extension
      </ButtonOutline>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="500px"
          footer={null}
        >
          <ContentStyled>{resolveContent()}</ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>{resolveContent()}</ContentStyled>
        </Drawer>
      )}
    </>
  );
}
