import styled from "@emotion/styled";
import { Typography } from "antd";

const { Text } = Typography;
export const TitleStyled = styled(Text)`
  /* Heading 04 / Bold */

  // font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  /* identical to box height, or 131% */

  /* Text/500 */

  color: #0b0e14;

  color: #0b0e14;
  margin-bottom: 8px;
`;

export const DescStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 143% */

  /* Text/400 */

  color: #273248;

  max-width: 600px;
`;
