import React, { useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Progress,
  Row,
  Col,
  Alert,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import {
  ColCenter,
  ColStart,
  RowCenter,
  RowEnd,
  RowSpaceBetween,
} from "../general_styled";

import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  DividerCard,
  LabelStyled,
} from "./styled";
import { SearchStyled } from "../../pages/Credence/styled";
import closeIcon from "../../assets/images/close.svg";
import IBFxLogo from "../../assets/images/ibfx-icon.svg";
import chevronDownIcon from "../../assets/images/chevron-down.svg";
import chevronRightIcon from "../../assets/images/chevron-right.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import { setSection } from "../../modules/general/action";
import { BalanceCardStyled } from "../ModalWallet/styled";
import CardCollateral from "./CardCollateral";
import ReactCodeInput from "react-code-input";
import { decryptMnemonic, fromIBFx, toIBFx } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import {
  getAllLoans,
  postAddCollateralLoan,
  postPaybackLoan,
} from "../../modules/loans/action";
import jwtDecode from "jwt-decode";

const { Title, Text } = Typography;

export default function ModalLoansAddCollateral(props) {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMode, setIsMode] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [selectedNFT, setSelectedNFT] = useState([]);
  const [selectedNFTAmount, setSelectedNFTAmount] = useState(0);
  const [pinCode, setPinCode] = useState();
  const [isPinError, setPinError] = useState(false);

  const purchases = useSelector((state) =>
    state.getIn(["essence", "purchases", "data"]).toJS()
  );

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsModalVisible(false);
    setSelectedNFT([]);
    setSelectedNFTAmount(0);
  };

  const onSelectNFT = (nft, data) => {
    if (selectedNFT.includes(nft)) {
      const filteredNFT = selectedNFT.filter((id) => id !== nft);
      setSelectedNFT(filteredNFT);
      setSelectedNFTAmount(selectedNFTAmount - fromIBFx(data.total_ibfx));
    } else {
      setSelectedNFT([...selectedNFT, nft]);
      setSelectedNFTAmount(selectedNFTAmount + fromIBFx(data.total_ibfx));
    }
  };

  const onSubmit = async (mnemonic) => {
    setIsLoading(true);

    try {
      await postAddCollateralLoan(mnemonic, {
        loan_id: props.record?.loan_id,
        nfts: selectedNFT,
      });

      await dispatch(
        getAllLoans(
          jwtDecode(localStorage.getItem("USER_AUTH_TOKEN")).user_id,
          null,
          1
        )
      );

      setIsLoading(false);
      setIsMode(1);
    } catch (error) {
      notification.error({
        message: "Failed!",
        description: "Error when add nft as collateral",
      });
      setIsLoading(false);
      handleCancel();
    }
  };

  const getMonthBetween = (startDate, endDate) => {
    var start = moment(startDate);
    var end = moment(endDate);
    var months = end.diff(start, "months");
    if (months <= 0) {
      const weeks = end.diff(start, "weeks");
      if (weeks <= 0) {
        const days = end.diff(start, "days");
        return days + " days";
      }
      return weeks + " weeks";
    }
    return months + " months";
  };

  const checkPin = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = await decryptMnemonic(getMnemonic, pinCode);
      setPinError(false);
      await onSubmit(_decryptMnemonic);
    } catch (e) {
      setPinError(true);
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const resolveSuccess = () => (
    <ColCenter className="h-100" span={24}>
      <Progress type="circle" percent={100} />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        Success!
      </TitleStyled>
      <TextStyled>
        The collateral has been added to your loan request.
      </TextStyled>
    </ColCenter>
  );

  const resolveBox = (title, value, isHasIcon, isTotal) => {
    return (
      <Row>
        <Col span={24}>
          <BalanceCardStyled className="mt-0px">
            <RowSpaceBetween>
              <Title
                level={5}
                className="secondary text-size-13"
                style={{ marginBottom: 0, lineHeight: 1.7 }}
              >
                {title}
              </Title>
              <Title
                level={4}
                className="gray-8 text-w500 text-size-15"
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                {isHasIcon && <img src={IBFxLogo} className="mr-4px" />}
                {value}
              </Title>
            </RowSpaceBetween>
          </BalanceCardStyled>
        </Col>
      </Row>
    );
  };

  const resolveContent = () => {
    switch (isMode) {
      case 0:
        return (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Select Collateral
            </Title>
            <ColStart className="w-100 h-100 mt-24px">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={8} xl={5} xxl={5}>
                  {resolveBox(
                    "Loan Period",
                    getMonthBetween(
                      props.record?.created_at,
                      props.record?.deadline
                    )
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={5} xxl={5}>
                  {resolveBox(
                    "Loan Amount",
                    fromIBFx(props.record?.total_ibfx),
                    true
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={5} xxl={5}>
                  {resolveBox("Percentage", "50%")}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={2} xxl={1}>
                  <RowCenter className="align-center h-100 w-100">
                    <img
                      src={
                        windowDimensions.width > 1200
                          ? chevronRightIcon
                          : chevronDownIcon
                      }
                    />
                  </RowCenter>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={7} xxl={8}>
                  {resolveBox(
                    "Total Value",
                    fromIBFx(
                      props.record?.total_ibfx + props.record?.total_ibfx / 2
                    ),
                    true,
                    true
                  )}
                </Col>
              </Row>
              <DividerCard />
              <RowSpaceBetween>
                <Title
                  level={5}
                  className="text-w500 text-size-16"
                  style={{ marginBottom: 0, lineHeight: 1.7 }}
                >
                  Qualified NFTs
                </Title>
                <RowEnd>
                  <SearchStyled
                    className="search-input"
                    placeholder="Search"
                    autoComplete="off"
                    allowClear
                    size="large"
                    onSearch={(value) => setSearchVal(value)}
                    style={{
                      marginRight: "12px",
                    }}
                  />
                </RowEnd>
              </RowSpaceBetween>
              <Row gutter={[8, 8]}>
                {purchases
                  .filter(
                    (i) => i.nft.fragmentation > 0 && i.nft.status !== "ON_LOAN"
                  )
                  .map((nft) => {
                    const metadata = JSON.parse(nft.nft.metadata_json);
                    return (
                      <CardCollateral
                        nftId={nft.nft.nft_id}
                        name={metadata.name}
                        collectionName={metadata.collection.name}
                        ipfsHash={metadata.image.replace("ipfs://", "")}
                        is_pdf={metadata.is_pdf || false}
                        setSelectedNFT={onSelectNFT}
                        selectedNFT={selectedNFT}
                        data={nft}
                        key={nft.nft.nft_id}
                      />
                    );
                  })}
              </Row>
            </ColStart>
          </ColCenter>
        );
      case 1:
        return resolveSuccess();
      case 2:
        return (
          <ColCenter style={{ minHeight: "500px" }}>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Enter pin
            </Title>
            <Text className="gray-7" align="center">
              Please enter your pin code to confirm the transaction
            </Text>
            <Row className="mt-24px">
              <Col span={24}>
                <RowCenter>
                  <ReactCodeInput
                    type="password"
                    onChange={(e) => setPinCode(e)}
                    fields={4}
                    {...codeProps}
                  />
                </RowCenter>
              </Col>
              {isPinError && (
                <Col className="mt-10px" span={24}>
                  <Alert
                    message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                    type="error"
                    showIcon
                  />
                </Col>
              )}
            </Row>
          </ColCenter>
        );
    }
  };

  return (
    <>
      <a onClick={showModal}>Add Collateral</a>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="80%"
          footer={[
            <RowSpaceBetween className="my-12px mx-16px">
              <ColStart style={{ width: "30%" }}>
                <RowSpaceBetween className="mb-8px">
                  <Text className="text-size-12 text-w600">Selected Value</Text>

                  <RowEnd className="center">
                    <img src={IBFxLogo} className="mr-4px" />
                    <Text className="text-size-14 text-w600">
                      {selectedNFTAmount || 0}
                    </Text>
                  </RowEnd>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <Text className="text-size-12 text-w600">
                    Remaining Value
                  </Text>
                  <RowEnd className="center">
                    <img src={IBFxLogo} className="mr-4px" />
                    <Text className="text-size-14 text-w600">
                      {fromIBFx(props.record?.total_ibfx) - selectedNFTAmount >
                      0
                        ? fromIBFx(props.record?.total_ibfx) - selectedNFTAmount
                        : 0}
                    </Text>
                  </RowEnd>
                </RowSpaceBetween>
              </ColStart>

              <Button
                style={{
                  height: 48,
                  borderRadius: 4,
                  minWidth: 340,
                }}
                type="primary"
                disabled={
                  isMode !== 1 && (isLoading || selectedNFT.length <= 0)
                }
                onClick={() => {
                  if (isMode === 0) {
                    setIsMode(2);
                  } else if (isMode === 2) {
                    checkPin();
                  } else {
                    handleCancel();
                  }
                }}
              >
                {isMode === 1
                  ? "Dismiss"
                  : !isLoading
                  ? isMode === 0
                    ? "Confirm"
                    : "Confirm Pin"
                  : antIcon}
              </Button>
            </RowSpaceBetween>,
          ]}
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {isMode === 1 ? resolveSuccess() : resolveContent()}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {isMode === 1 ? resolveSuccess() : resolveContent()}
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
