import React, { useState } from "react"
import { Modal, Typography, Button, Input, Drawer, notification } from "antd"
import { useDispatch } from "react-redux"
import {
  LoadingOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons"
import { ColCenter, InputPasswordStyled } from "../general_styled"

import { ContentStyled } from "./styled"
import closeIcon from "../../assets/images/close.svg"
import useWindowDimensions from "../../utils/windowDimensions"
import { updatePassword } from "../../modules/auth/action"
import { update } from "immutable"
import { extractErrorMessage } from "../../utils/extractErrorMessage"

const { Title, Text } = Typography

export default function ModalChangePassword() {
  const windowDimensions = useWindowDimensions()
  const dispatch = useDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [oldPassword, setOldPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [retypeNewPassword, setRetypeNewPassword] = useState()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true)

      const result = await dispatch(updatePassword({ password: newPassword }))

      if (result.code === "SUCCESS") {
        notification.success({
          message: "Success!",
          description: "You have successfully update password",
        })
        handleCancel()
      } else {
        notification.error({
          message: "Failed!",
          description: result,
        })
      }
    } catch (e) {
      console.log(e)
      notification.error({
        message: "Failed!",
        description: extractErrorMessage(e),
      })
    } finally {
      setIsLoading(false)
    }
  }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  )

  return (
    <>
      <Button
        block
        size="large"
        ghost
        type="primary"
        className="mt-12px"
        onClick={showModal}
      >
        Change Password
      </Button>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Change Password
              </Title>
              <Text className="gray-7" align="center">
                Type and confirm new account password
              </Text>
              <InputPasswordStyled
                placeholder="Old Password"
                className="mt-24px"
                size="large"
                onChange={(e) => setOldPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <InputPasswordStyled
                placeholder="New Password"
                className="mt-12px"
                size="large"
                onChange={(e) => setNewPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <InputPasswordStyled
                placeholder="Re-enter New Password"
                className="mt-12px"
                size="large"
                onChange={(e) => setRetypeNewPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <Button
                block
                disabled={
                  !(
                    oldPassword !== "" &&
                    newPassword !== "" &&
                    retypeNewPassword !== "" &&
                    retypeNewPassword === newPassword
                  )
                }
                type="primary"
                className="mt-24px"
                size="large"
                onClick={() => onSubmit()}
              >
                {isLoading ? antIcon : "Submit"}
              </Button>
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Change Password
              </Title>
              <Text className="gray-7 mt-12px" align="center">
                Type and confirm new account password
              </Text>
              <InputPasswordStyled
                placeholder="Old Password"
                className="mt-12px"
                size="large"
                onChange={(e) => setOldPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <InputPasswordStyled
                placeholder="New Password"
                className="mt-12px"
                size="large"
                onChange={(e) => setNewPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <InputPasswordStyled
                placeholder="Re-enter New Password"
                className="mt-12px"
                size="large"
                onChange={(e) => setRetypeNewPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <Button
                block
                disabled={!oldPassword && !newPassword && !retypeNewPassword}
                type="primary"
                className="mt-24px"
                size="large"
                onClick={() => onSubmit()}
              >
                {isLoading ? antIcon : "Save Changes"}
              </Button>
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  )
}
