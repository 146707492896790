import { Col, Image, Typography, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardNFT from "../../components/CardNFT";
import { ColCenter } from "../../components/general_styled";
import ModalCreateNFT from "../../components/ModalCreateNFT";
import { getAllEssence } from "../../modules/essence/action";
import { getEvmNftOnsale } from "../../modules/credence/action";
import { RowEnd, SearchStyled, TabsStyled } from "./styled";
import emptyNfts from "../../assets/images/empty_nfts.svg";
import ShimmerCustom from "../../components/ShimmerCustom";
import useWindowDimensions from "../../utils/windowDimensions";
import CardShoppe from "../../components/CardShoppe";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { useAccount } from "wagmi";
import { IS_EVM } from "../../configs";
import { EVM_TRANSACTION_STATUS } from "../../constants/constant_credence";

const { TabPane } = Tabs;
const { Title, Text } = Typography;
export default function AffluenceShopeeListing() {
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();
  const windowDimensions = useWindowDimensions();

  const [menu, setMenu] = useState("listing");
  const [isLoading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState(null);
  const param = useSelector((state) => state.getIn(["general", "param"]));
  const nfts = useSelector((state) =>
    state.getIn(["essence", "nfts", "data"]).toJS()
  );
  const page = useSelector((state) =>
    state.getIn(["essence", "nfts", "currentPage"])
  );
  const nftsOnsale = useSelector((state) =>
    state.getIn(["credence", "onsale"]).toJS()
  );

  const wishlist = useSelector((state) =>
    state.getIn(["essence", "wishlist"]).toJS()
  );

  const dispatch = useDispatch();

  const getData = async (page, search) => {
    if (IS_EVM) {
      if (isConnected) {
        await dispatch(
          getEvmNftOnsale({
            sellerAddress: address,
            status: EVM_TRANSACTION_STATUS.ON_SALE,
          })
        );
      }
    } else {
      const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
      await dispatch(getAllEssence(decoded.user_id, page, search));
    }
    setLoading(false);
  };

  const initData = async () => {
    await getData(1, null);
  };

  const handleSearch = async () => {
    await getData(1, searchVal);
  };

  useEffect(() => {
    initData();
    setMenu(param || "listing");
  }, []);

  useEffect(() => {
    initData();
  }, [isConnected]);

  const resolveShimmer = () => {
    let children = [];
    for (let i = 0; i < 9; i++) {
      children.push(
        <Col
          key={i}
          xs={24}
          sm={24}
          md={windowDimensions.width > 925 ? 12 : 24}
          lg={12}
          xl={8}
          xxl={6}
        >
          <ShimmerCustom width="100%" height="322px" borderRadius="2px" />
          <ShimmerCustom
            width="80%"
            height="20px"
            borderRadius="2px"
            className="mt-12px"
          />
          <ShimmerCustom width="60%" height="12px" borderRadius="2px" />
        </Col>
      );
    }

    return children;
  };

  const renderSearchBar = () => {
    return (
      <RowEnd>
        <Col span={menu === "vault" && nfts?.length <= 0 ? 24 : 14}>
          <SearchStyled
            className="search-input"
            placeholder="Search"
            autoComplete="off"
            allowClear
            size="large"
            onChange={(val) => setSearchVal(val.target.value)}
            onSearch={() => handleSearch()}
            style={{
              marginRight: "12px",
            }}
          />
        </Col>

        {menu === "vault" && nfts?.length <= 0 ? (
          <></>
        ) : (
          <Col offset={1} span={9}>
            <ModalCreateNFT toApproval={() => setMenu("approvals")} />
          </Col>
        )}
      </RowEnd>
    );
  };

  const renderEmpty = (img, title, subtitle) => {
    return (
      <ColCenter style={{ height: "100%", minHeight: "calc(100vh - 180px)" }}>
        <Image preview={false} src={img} />
        <Title align="center" className="mb-0px mt-24px" level={3}>
          {title}
        </Title>
        <Text style={{ width: "37%", textAlign: "center" }}>{subtitle}</Text>
        {menu === "vault" && (
          <div style={{ width: "350px", marginTop: 24 }}>
            <ModalCreateNFT toApproval={() => setMenu("approvals")} />
          </div>
        )}
      </ColCenter>
    );
  };

  const renderMyListing = () => {
    return isLoading ? (
      <Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
        {resolveShimmer()}
      </Row>
    ) : (IS_EVM ? nftsOnsale.length > 0 : nfts?.length > 0) ? (
      <Row style={{ paddingTop: 15 }}>
        {IS_EVM
          ? nftsOnsale?.map((nft) => {
              const metadata = JSON.parse(nft?.NFT.metadata_json);
              return (
                <CardNFT
                  key={nft?.NFT?.nft_id}
                  nftId={nft?.NFT?.nft_id}
                  sellEscrowId={nft?.trx_id}
                  auctionId={null}
                  name={metadata.name}
                  collectionName={metadata.collection.name}
                  ipfsHash={metadata.image.replace("ipfs://", "")}
                  is_pdf={metadata.is_pdf || false}
                  data={{ ...nft, metadata, ...nft?.NFT }}
                />
              );
            })
          : nfts.map((nft) => {
              const metadata = JSON.parse(nft.NFT.metadata_json);
              return (
                <CardShoppe
                  sell_escrow_id={nft.sell_escrow_id}
                  name={metadata.name}
                  collectionName={metadata.collection?.name}
                  fragmentation={metadata.fragmentation}
                  listed_on={nft.created_at}
                  price={nft.price}
                  ipfsHash={metadata.image?.replace("ipfs://", "")}
                  is_pdf={metadata.is_pdf || false}
                  isDelisting={true}
                  data={{ ...nft, metadata }}
                />
              );
            })}
      </Row>
    ) : (
      renderEmpty(emptyNfts, t("no_nft_found"), t("no_nft_desc_2"))
    );
  };

  const renderFavourite = () => {
    return isLoading ? (
      <Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
        {resolveShimmer()}
      </Row>
    ) : wishlist?.length > 0 ? (
      <Row style={{ paddingTop: 15 }}>
        {wishlist?.map((nft) => {
          const metadata = JSON.parse(nft.NFT.metadata_json);
          return (
            <CardShoppe
              sell_escrow_id={nft.sell_escrow_id}
              name={metadata.name}
              collection={metadata.collection?.name}
              fragmentation={metadata.fragmentation}
              listed_on={nft.created_at}
              price={nft.price}
              ipfsHash={metadata.image?.replace("ipfs://", "")}
              is_pdf={metadata.is_pdf || false}
              isDelisting={true}
            />
          );
        })}
      </Row>
    ) : (
      renderEmpty(emptyNfts, t("no_nft_found"), t("no_nft_desc"))
    );
  };

  return (
    <>
      {windowDimensions.width < 835 && renderSearchBar()}
      <TabsStyled
        size="large"
        tabBarExtraContent={
          windowDimensions.width > 834 ? renderSearchBar() : null
        }
        onChange={(val) => setMenu(val)}
        defaultActiveKey={menu}
        activeKey={menu}
      >
        <TabPane tab="My Listing" key="listing">
          {renderMyListing()}
        </TabPane>
        <TabPane tab="Favourites" key="favourites">
          {renderFavourite()}
        </TabPane>
      </TabsStyled>
    </>
  );
}
