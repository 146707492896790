import React, { useState, useEffect, useCallback } from 'react'
import { Typography, Row, Select, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	ButtonStyled,
	ColCenter,
	ColStart,
	RowEnd,
	RowSpaceBetween,
	RowStart,
	SelectStyled,
} from '../../components/general_styled'
import { SearchStyled } from './styled'
import { getAllAuction } from '../../modules/affluence/action'
import { useDispatch, useSelector } from 'react-redux'
import CardAuction from '../../components/CardAuction'
import { getCredenceNFTByUserId } from '../../modules/credence/action'
import useWindowDimensions from '../../utils/windowDimensions'
import ShimmerCustom from '../../components/ShimmerCustom'

const { Title, Text } = Typography

export default function AffluenceAuctionMarketplace() {
	const { t } = useTranslation()

	const windowDimensions = useWindowDimensions()
	const [searchVal, setSearchVal] = useState()
	const [isLoading, setLoading] = useState(true)

	const dispatch = useDispatch()

	const CredencePageNfts = useSelector((state) => state.getIn(['credence', 'nfts', 'currentPage']))
	const pageNfts = useSelector((state) => state.getIn(['affluence', 'auctions', 'currentPage']))
	const AuctionNftsHasNext = useSelector((state) => state.getIn(['affluence', 'auctions', 'hasNext']))
	const nfts = useSelector((state) => state.getIn(['affluence', 'auctions', 'data']).toJS())

	const handleChangeDropdown = (val) => {
		setLoading(true)
		if (val !== 'ALL_CATEGORY') {
			dispatch(getAllAuction(null, 1, null, null, val))
		} else {
			dispatch(getAllAuction(null, 1))
		}
		setLoading(false)
	}

	const renderSearchBar = () => {
		if (windowDimensions.width > 835) {
			return (
				<RowEnd>
					<SearchStyled
						className='search-input'
						placeholder='Search'
						autoComplete='off'
						allowClear
						size='large'
						onSearch={(value) => {
							setSearchVal(value)
							dispatch(getAllAuction(null, 1, value))
						}}
						style={{
							width: 250,
						}}
					/>
					<SelectStyled
						className='ml-12px'
						showSearch
						placeholder='Type Assets'
						defaultValue='ALL_CATEGORY'
						style={{
							width: 180,
						}}
						onSelect={handleChangeDropdown}
					>
						<Select.Option value='ALL_CATEGORY'>All Categories</Select.Option>
						<Select.Option value='ARTWORK'>Artwork</Select.Option>
						<Select.Option value='AUDIO'>Audio</Select.Option>
						<Select.Option value='EBOOK'>E-Books</Select.Option>
					</SelectStyled>
				</RowEnd>
			)
		} else {
			return (
				<Row className='w-100' gutter={[16, 16]}>
					<Col xs={24} sm={24} md={24}>
						<SearchStyled
							className='search-input'
							placeholder='Search'
							autoComplete='off'
							allowClear
							size='large'
							onSearch={(value) => {
								setSearchVal(value)
								dispatch(getAllAuction(null, 1, value))
							}}
							style={{ width: '100%' }}
						/>
					</Col>
					<Col xs={24} sm={24} md={24}>
						<SelectStyled
							showSearch
							placeholder='Type Assets'
							defaultValue='ALL_CATEGORY'
							style={{
								width: '100%',
							}}
							onSelect={handleChangeDropdown}
						>
							<Select.Option value='ALL_CATEGORY'>All Categories</Select.Option>
							<Select.Option value='ARTWORK'>Artwork</Select.Option>
							<Select.Option value='AUDIO'>Audio</Select.Option>
							<Select.Option value='EBOOK'>E-Books</Select.Option>
						</SelectStyled>
					</Col>
				</Row>
			)
		}
	}

	const renderHeader = () => {
		if (windowDimensions.width > 835) {
			return (
				<RowSpaceBetween>
					{searchVal ? (
						<ColStart>
							<RowStart className='mb-0px'>
								<Text className='text-size-16'>Search Result for </Text>
								<Text className='text-size-16 text-w600'>&nbsp;"{searchVal}"</Text>
							</RowStart>
							<Text className='text-size-12'>{nfts.length} NFTs Found </Text>
						</ColStart>
					) : (
						<Title level={3} className='mb-0px mt-0px'>
							Market Auction
						</Title>
					)}
					{renderSearchBar()}
				</RowSpaceBetween>
			)
		} else {
			return (
				<ColCenter className='w-100'>
					{searchVal ? (
						<RowSpaceBetween className='w-100 mb-16px'>
							<RowStart className='mb-0px'>
								<Text className='text-size-22'>{t('search_for')} </Text>
								<Text className='text-size-22 text-w600'>&nbsp;"{searchVal}"</Text>
							</RowStart>
							<Text className='text-size-16'>
								{nfts.length} {t('nft_found')}
							</Text>
						</RowSpaceBetween>
					) : (
						<Title level={1} className='mb-16px mt-0px'>
							{t('market_auction')}
						</Title>
					)}
					{renderSearchBar()}
				</ColCenter>
			)
		}
	}

	const resolveShimmer = () => {
		let children = []
		const limit = nfts.length || 9
		for (let i = 0; i < limit; i++) {
			children.push(
				<Col key={i} xs={24} sm={24} md={windowDimensions.width > 925 ? 12 : 24} lg={12} xl={8} xxl={6}>
					<ShimmerCustom width='100%' height='322px' borderRadius='2px' />
					<ShimmerCustom width='80%' height='20px' borderRadius='2px' className='mt-12px' />
					<ShimmerCustom width='60%' height='12px' borderRadius='2px' />
				</Col>
			)
		}

		return children
	}

	const initData = async () => {
		await dispatch(getCredenceNFTByUserId(null, CredencePageNfts))
		await dispatch(getAllAuction(null, pageNfts))
		setLoading(false)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		initData()
	}, [])

	return (
		<ColStart>
			{renderHeader()}
			{isLoading ? (
				<Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
					{resolveShimmer()}
				</Row>
			) : (
				<Row className='mt-20px' gutter={[16, 16]}>
					{nfts.map((nft) => {
						const metadata = nft.NFT ? JSON.parse(nft.NFT.metadata_json) : {}
						return (
							<CardAuction
								auction_id={nft.auction_id}
								name={metadata?.name}
								collectionName={metadata?.collection?.name}
								image={metadata?.image?.replace('ipfs://', '')}
								auction={nft}
							/>
						)
					})}
				</Row>
			)}
			{nfts.length > 0 && AuctionNftsHasNext && (
				<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
					<ButtonStyled
						type={'primary'}
						size='small'
						loading={isLoading}
						onClick={async () => {
							setLoading(true)
							await dispatch(getAllAuction(null, pageNfts + 1))
							setLoading(false)
						}}
					>
						{t('load_more')}
					</ButtonStyled>
				</Row>
			)}
		</ColStart>
	)
}
