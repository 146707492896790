import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Button,
  Progress,
  Row,
  Col,
  notification,
  Drawer,
} from "antd";
import { ColCenter } from "../general_styled";

import { ContentStyled } from "./styled";
import { ASSETS_NAME } from "../../constants/constant_wallet";
import useWindowDimensions from "../../utils/windowDimensions";

const { Title, Text } = Typography;

export default function ModalLoading(props) {
  const windowDimensions = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mode, setIsMode] = useState(0);
  const [isLoading, setIsLoading] = useState(props.isLoading || false);

  const handleCancel = () => {
    setIsModalVisible(false);
    props.handleCancel();
    props.setIsModalVisible(false);
  };

  const showModal = () => {
    if (props.onCheckPin) {
      const checkPin = props.onCheckPin();
      if (!checkPin) {
        notification.error({
          message: "Failed!",
          description:
            "You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again.",
        });
        return setIsMode(2);
      }
    }

    if (windowDimensions.width > 835) {
      props.handleCancel();
    }

    setIsModalVisible(true);
    onProcess();
  };

  const onFinish = () => {
    // props.onFinish()
    setTimeout(() => {
      setIsModalVisible(false);
    }, 1 * 1000);
  };

  const onProcess = async (retry) => {
    if (retry) {
      setIsMode(0);
    }

    try {
      setIsLoading(true);
      const result = await props.onProcess();

      if (result) {
        setIsMode(1);
      } else {
        setIsMode(2);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      setIsMode(2);
    }
  };

  const resolveName = () => {
    switch (props.data?.asset_name) {
      case ASSETS_NAME.IBFS:
        return "IBFS";
      case ASSETS_NAME.IBFN:
        return "IBFN";
      case ASSETS_NAME.IBFX_TYPE_I:
        return "IBFX";
      case ASSETS_NAME.IBFX_TYPE_II:
        return "IBFX - 2";
      case ASSETS_NAME.IBFX_TYPE_III:
        return "IBFX - 3";
      case ASSETS_NAME.IBFX_TYPE_IV:
        return "IBFX - 4";
      default:
        return "IBFX";
    }
  };

  return (
    <>
      <Button
        disabled={props.disable}
        block
        type="primary"
        size="large"
        onClick={showModal}
      >
        {props.text || "Submit"}
      </Button>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          closable={false}
          footer={null}
          width={windowDimensions.width > 1400 ? "30%" : "40%"}
        >
          <ContentStyled>
            <ColCenter>
              <Progress
                type="circle"
                percent={mode === 1 ? 100 : 10}
                status={mode === 1 ? "success" : mode === 2 ? "exception" : ""}
              />
              <Title className="text-w700 gray-7 mb-0px mt-24px" level={4}>
                {mode === 2
                  ? "Error!"
                  : isLoading
                  ? props.type === "courses"
                  : "Processing..."
                  ? (props.type === "topup"
                      ? "Adding"
                      : props.type === "transfer"
                      ? "Transfer"
                      : "") +
                    " " +
                    props.amount +
                    " " +
                    resolveName()
                  : "Success!"}
              </Title>
              <Text className="gray-7" align="center">
                {mode === 2
                  ? "An error has occured while processing your payment"
                  : isLoading
                  ? "Please wait while we process your transaction"
                  : props.type === "courses"
                  ? "Thank you for your payment. You will hear from us via email once your course schedule is activated."
                  : props.type === "donation"
                  ? "Thank you for your donation"
                  : props.type === "waqf"
                  ? "Thank you for your waqf donation"
                  : "Success your transaction has been completed"}
              </Text>
            </ColCenter>
            <Row className="mt-20px">
              {mode === 2 && (
                <Col span={12}>
                  <Button
                    size="large"
                    block
                    type="primary"
                    onClick={() => onProcess(true)}
                  >
                    Try Again
                  </Button>
                </Col>
              )}
              <Col span={mode === 2 ? 11 : 24} offset={mode === 2 ? 1 : 0}>
                <Button
                  type={mode !== 2 && "primary"}
                  size="large"
                  block
                  onClick={handleCancel}
                >
                  Dismiss
                </Button>
              </Col>
            </Row>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closable={false}
        >
          <ContentStyled>
            <ColCenter>
              <Progress
                type="circle"
                percent={mode === 1 ? 100 : 10}
                status={mode === 2 && "exception"}
              />
              <Title className="text-w700 gray-7 mb-0px mt-24px" level={4}>
                {mode === 2
                  ? "Error!"
                  : isLoading
                  ? props.type === "courses"
                    ? "Processing..."
                    : (props.type === "topup"
                        ? "Adding"
                        : props.type === "transfer"
                        ? "Transfer"
                        : "") +
                      " " +
                      props.amount +
                      " " +
                      (props.type !== "topup" ? resolveName() : "IBFX")
                  : "Success!"}
              </Title>
              <Text className="gray-7" align="center">
                {mode === 2
                  ? "An error has occured while processing your payment"
                  : isLoading
                  ? "Please wait while we process your transaction"
                  : props.type === "courses"
                  ? "Thank you for your payment. You will hear from us via email once your course schedule is activated."
                  : "Success your transaction has been completed"}
              </Text>
            </ColCenter>
            <Row className="mt-20px">
              {mode === 2 && (
                <Col span={12}>
                  <Button
                    size="large"
                    type="primary"
                    block
                    onClick={() => onProcess(true)}
                  >
                    Try Again
                  </Button>
                </Col>
              )}
              <Col span={mode === 2 ? 11 : 24} offset={mode === 2 ? 1 : 0}>
                <Button
                  type={mode !== 2 && "primary"}
                  size="large"
                  block
                  onClick={handleCancel}
                >
                  Dismiss
                </Button>
              </Col>
            </Row>
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
