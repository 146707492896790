import styled from "@emotion/styled";
import { Row, Typography, Divider } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

export const Canvas = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 835px) {
    height: 100vh;
  }
`;

export const CustomRow = styled(Row)`
  width: 100%;
`;

export const ImageLogin = styled.div`
  background-size: cover;
  background-image: url(${(props) => props.img});

  @media screen and (min-width: 835px) {
    width: 100%;
    height: 100vh;
  }

  @media screen and (max-width: 834px) {
    width: 100%;
    height: 30vh;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 835px) {
    height: 100vh;
    // padding: 0 24px;
  }

  @media screen and (max-width: 834px) {
    min-height: 70vh;
    padding-bottom: 20px;
  }
`;

export const FormCustom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  @media screen and (min-width: 835px) {
    width: 60%;
  }

  @media screen and (max-width: 834px) {
    width: 80%;
  }
`;

export const LinkStyled = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Secondary/500 */

  color: #3b4552;
`;
export const TextStyled = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;

  color: #273248;
`;

export const BlueLink = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Daybreak Blue/blue-6 */

  color: #024FF1;
`;

export const LoginFormStyled = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SosMedStyled = styled.div`
  margin-top: auto;
  margin-bottom: 24px;

  a {
    margin: 0 4px;
  }
`;

export const TextSosMedStyled = styled(Text)`
  /* Regular/14px | 22px */

  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/300 */

  color: #44567b;
`;

export const DividerStyled = styled(Divider)`
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  span {
    /* Footer/ All Caps */

    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    /* identical to box height, or 140% */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Text/100 */

    color: #7e92b8;
  }
`;

export const Card = styled.div`
  padding: 3em;
  background-color: #024FF1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 64px 12px;
  max-width: 572px;
  min-height: 270px;

  .text {
    color: #ffffff;
    font-family: "Inter", Sans-serif;
    font-size: 15px;
    font-weight: 100;
    line-height: 22px;
    padding-top: 12px;
  }

  &.disabled {
    background-color: #b0b0b0;
  }

  .text-disabled {
    color: #606061;
    font-family: "Inter", Sans-serif;
    font-size: 15px;
    font-weight: 100;
    line-height: 22px;
    padding-top: 12px;
  }

  .image-disabled {
    filter: grayscale(100%);
  }

  @media (max-width: 768px) {
    margin: 12px 12px;
  }
`;

export const ButtonLogin = styled.button`
  padding: 9px;
  max-width: 160px;
  border-radius: 5px;
  border: 0;
  transition: all 0.2s ease-in-out;
  margin-top: 36px;
  background-color: #f6f6f7;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  &.disabled {
    border: 1px #979899 dashed;
    background-color: transparent;
  }
`;

export const ButtonLoginDisabled = styled.button`
  padding: 9px;
  max-width: 160px;
  border-radius: 5px;
  border: 1px #979899 dashed;
  margin-top: 36px;
  background-color: transparent;
  cursor: not-allowed;
`;
