import styled from "@emotion/styled";

export const InputStyled = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  color: #172b4d;
  background: transparent;
  padding: 8px;
  border: 2px solid #dfe1e6;
`;
