import { Avatar, Col, Dropdown, Input, Progress, Row, Space, Menu, Table, Button, Typography, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { BENEVOLENCE_HOST } from '../../configs'
import { DonorProfileStyled } from './styled'
import userDummy from '../../assets/images/default-avatar.png'
import { getMyProjects, getSumRecentDonation } from '../../modules/benevolence_projects/action'
import { useDispatch, useSelector } from 'react-redux'
import projectIcon from '../../assets/images/projects-side.svg'
import closeIcon from '../../assets/images/close_project.svg'
import moreIcon from '../../assets/images/more.svg'
import settingAdjustIcon from '../../assets/images/setting-adjust.svg'
import campaignDefaultImage from '../../assets/images/campaign_default.svg'
import { getMyDonors } from '../../modules/benevolence_donors/action'
import { useHistory } from 'react-router'
import { fromIBFx, fromIUSD } from '../../utils/utils'
import { RowEnd, RowSpaceBetween } from '../../components/general_styled'
import useWindowDimensions from '../../utils/windowDimensions'
import { setSection } from '../../modules/general/action'
import { useTranslation } from 'react-i18next'
import CardBenevolenceManage from '../../components/CardManageBenevolence'

const customStyles = {
	overlay: {
		background: 'rgba(11, 14, 20, 0.9)',
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		padding: '24px',
		display: 'flex',
		minWidth: '50%',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FCFDFF',
		borderRadius: '12px',
	},
}
export default function BenevolenceDonors(props) {
	const { t } = useTranslation()

	const windowDimensions = useWindowDimensions()
	const [hasProject, setHasProject] = useState(true)
	const [isProcessing, setIsProcessing] = useState(false)
	const [isFinish, setIsFinish] = useState(true)
	const [currentPagination, setCurrentPagination] = useState(1)

	const [modalMode, setModalMode] = useState('')
	const [selectedProject, setSelectedProject] = useState({})
	const [selectedProjectSum, setSelectedProjectSum] = useState({})
	const [selectedProjectProgress, setSelectedProjectProgress] = useState({})
	const [isModalOpen, setModalOpen] = useState(false)
	const [searchResult, setSearchResult] = useState([])

	const donors = useSelector((state) => {
		return state.getIn(['benevolenceDonors', 'donors']).toJS()
	})
	const token = useSelector((state) => state.getIn(['auth', 'token']))

	const dispatch = useDispatch()
	const history = useHistory()

	var formatterCurrency = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',

		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	})

	useEffect(() => {
		dispatch(getMyDonors(token, currentPagination))
	}, [])

	useEffect(() => {
		if (donors.item && donors.item.length < 0) {
			setHasProject(false)
		}
	}, [donors])

	useEffect(() => {
		if (props.menu === 'donors' && props.searchVal && props.searchVal !== '') {
			getDonorsSearch(props.searchVal)
		} else {
			setSearchResult([])
		}
	}, [props.searchVal])

	const getDonorsSearch = (value) => {
		let getResult = donors.data.filter((e) => {
			console.log(e)
			return (
				e.name.toLowerCase().includes(value.toLowerCase()) || e.project_name.toLowerCase().includes(value.toLowerCase())
			)
		})
		setSearchResult(getResult)
	}

	const onPaginationChange = (page) => {
		setCurrentPagination(page)
		if (donors.hasNextPage) {
			dispatch(getMyDonors(token, page))
		}
	}

	const handleMenuClick = (e, record) => {
		if (e.key === 'view-project') {
			dispatch(setSection('project-detail', record.project_id))
		} else if (e.key === 'view-volunteer') {
			dispatch(setSection('benevolence-manage', record.project_id))
		} else if (e.key === 'close') {
			// setModalOpen(true)
			// setModalMode("close")
			// setSelectedProject(record)
		}
	}

	const closeProjectAsset = async () => {}

	const columns = [
		{
			title: t('name'),
			dataIndex: 'name',
			key: 'name',
			width: windowDimensions.width > 1024 ? 300 : null,
			render: (name, record) => {
				return (
					<Row>
						<DonorProfileStyled span={24}>
							<div className="field-name row">
								<Avatar
									size={40}
									className="img-avatar"
									shape="square"
									src={record?.user?.photo ? `${BENEVOLENCE_HOST}/files/${record.user.photo}` : userDummy}
								/>
								<div className="col">
									<span className="name">{record?.user.name}</span>
									<span className="email">{record?.user.email}</span>
								</div>
							</div>
						</DonorProfileStyled>
					</Row>
				)
			},
		},
		{
			title: t('project'),
			dataIndex: 'project_name',
			key: 'project_name',
			width: windowDimensions.width > 1024 ? 300 : null,
			render: (name, record) => {
				let percent =
					(fromIBFx(record?.donation_amount == null ? 0 : fromIUSD(record?.donation_amount)) /
						fromIBFx(record?.project?.fund_goal == null ? 0 : fromIUSD(record?.project?.fund_goal))) *
					100

				return (
					<Row>
						<Col span={24}>{record?.project?.name}</Col>
						<Col span={24}>
							<Progress
								percent={percent}
								status="active"
								showInfo={false}
								strokeWidth={3}
								strokeColor={percent <= 50 ? '#EB5757' : percent <= 75 ? '#F2994A' : percent > 75 ? '#27AE60' : ''}
							/>
						</Col>
					</Row>
				)
			},
		},
		{
			title: t('project_end_date'),
			dataIndex: 'created_at',
			key: 'created_at',
			render: (time, record) => <>{Intl.DateTimeFormat('en-US').format(record?.project.end_at * 1000)}</>,
		},
		{
			title: t('amount_donated'),
			dataIndex: 'amount',
			key: 'amount',
			render: (amount) => <>{formatterCurrency.format(fromIBFx(amount))}</>,
		},
		{
			title: t('action'),
			key: 'action',
			align: 'right',
			render: (text, record) => (
				<Space>
					<Dropdown
						overlay={
							<Menu className="canvas-dropdown-custom" onClick={(e) => handleMenuClick(e, record)}>
								<Menu.Item key="view-project">
									<Row>
										<Col span={24} className="menu-dropdown-custom">
											<img src={projectIcon} alt="icon" />
											<span>{t('view_project')}</span>
										</Col>
									</Row>
								</Menu.Item>
								{/* <Menu.Item key="close">
									<Row>
										<Col span={24} className="menu-dropdown-custom">
											<img src={closeIcon} alt="icon" />
											<span>{t('close_project')}</span>
										</Col>
									</Row>
								</Menu.Item> */}
							</Menu>
						}
						placement="bottomRight"
					>
						<button className="more">
							<img src={moreIcon} alt="more" />
						</button>
					</Dropdown>
				</Space>
			),
		},
	]

	const resolveHasProject = () => {
		return !hasProject ? (
			<div className="no-donors">
				<img src={projectIcon} alt="icon" />
				<h1>{t('no_donors')}</h1>
				<p>
					{t('has_project_desc_1')}
					<br />
					{t('has_project_desc_2')}
				</p>
			</div>
		) : windowDimensions.width > 925 ? (
			<Table
				className="table-project"
				columns={columns}
				dataSource={searchResult.length > 0 ? searchResult : donors.data}
				scroll={{ y: '100%' }}
				pagination={{
					current: currentPagination,
					onChange: onPaginationChange,
				}}
			/>
		) : (
			donors?.data?.map((i) => <CardBenevolenceManage page={'donors'} data={i} handleMenuClick={handleMenuClick} />)
		)
	}

	return (
		<div id={windowDimensions.width > 925 ? 'manage-donors' : ''}>
			{resolveHasProject()}
			<Modal centered visible={isModalOpen} closeIcon={<></>} footer={null}>
				{
					<div className="modal-project">
						<div className="detail-project">
							<img
								src={
									// selectedProject.cover_image_file
									//   ? `${baseUrl}/files/${selectedProject.cover_image_file}`
									//   :
									campaignDefaultImage
								}
								alt=""
							/>
							<div className="up-layer">
								<div className="child-up-layer">
									<div className="row-space-between">
										<h3 className="current-donation">
											{formatterCurrency.format(selectedProjectSum.donation_amount || 0)}
										</h3>
										<h3 className="total-donation">{formatterCurrency.format(selectedProject.fund_goal)}</h3>
									</div>
									<Progress
										percent={selectedProjectProgress}
										status="active"
										showInfo={false}
										strokeWidth={5}
										trailColor={'#FFFFFF'}
										strokeColor={
											selectedProjectProgress <= 50
												? '#EB5757'
												: selectedProjectProgress <= 75
												? '#F2994A'
												: selectedProjectProgress > 75
												? '#27AE60'
												: ''
										}
									/>
								</div>
								<div className="child-up-layer align-end">
									<b>{t('amount_to_reach_goal')}</b>
									<h6 className="amount">{formatterCurrency.format(selectedProject.fund_goal)}</h6>
								</div>
							</div>
						</div>
						<h5>{t('close_project')}</h5>
						<p>
							{t('close_project_desc_1')} "{selectedProject.name}"
							<br />
							{t('close_project_desc_2')}
						</p>
						<div className="row center">
							<button
								className="outline-purple"
								onClick={() => {
									setModalOpen(false)
									setModalMode('')
									setSelectedProject({})
								}}
							>
								{t('cancel')}
							</button>
							<button
								className={'red'}
								onClick={() => {
									closeProjectAsset()
								}}
							>
								{t('close')}
							</button>
						</div>
					</div>
				}
			</Modal>
		</div>
	)
}
