import styled from "@emotion/styled";
import { Row, Button } from "antd";
import Icon, { HeartOutlined } from "@ant-design/icons";

export const CanvasCenter = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;

  // height: calc(100vh - 120px);
`;

export const CardStyled = styled.div`
  /* Shades/White */

  background: #fcfcfc;
  /* Neutral/500 */

  border: 1px solid #edeff2;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  border-radius: 4px;
  padding: 28px;
`;

export const ButtonStyled = styled(Button)`
  background: #024FF1;
  margin: 0;
  margin-top: 24px;

  box-sizing: border-box;
  border-radius: 2px;
  color: white;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23EDEFF2FF' stroke-width='4' stroke-dasharray='1%2c16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;
