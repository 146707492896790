import { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { nftAirdropAddress } from "../../configs";
import AbiNFTAirdrop from "../../assets/abi/NFTAirdrop.json";
import {
  getCredenceNFTByUserId,
  updateTxhashEvmAirdrop,
} from "../../modules/credence/action";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";

<LoadingOutlined style={{ fontSize: 20, color: "#0077c7" }} spin />;

export default function ActivateAirdrop(props) {
  const { data } = props;
  const dispatch = useDispatch();

  const credenceCurrentPage = useSelector((state) =>
    state.getIn(["credence", "nfts", "currentPage"])
  );

  // Airdrop
  const { config: configAirdrop } = usePrepareContractWrite({
    address: nftAirdropAddress,
    abi: AbiNFTAirdrop,
    functionName: "setMerkleRoot",
    args: [
      data.nft_contract_address, // nft_address
      data.roothash, // rootHash
    ],
  });
  const {
    writeAsync: writeAirdrop,
    data: dataAirdrop,
    isSuccess: isSuccessAirdrop,
    error: errorAirdrop,
    isLoading: isLoadingAirdrop,
    isError,
  } = useContractWrite(configAirdrop);

  const handleSubmit = async () => {
    await writeAirdrop();
  };

  useEffect(() => {
    if (isSuccessAirdrop) {
      updateTxHashAndGet();
      notification.success({ message: "Successfully activate airdrop" });
    }

    if (isError) {
      notification.error({ message: "Error when activate airdrop" });
    }
  }, [isSuccessAirdrop, errorAirdrop]);

  const updateTxHashAndGet = async () => {
    await updateTxhashEvmAirdrop(data.airdrop_id, { txhash: dataAirdrop.hash });
    await dispatch(getCredenceNFTByUserId(null, credenceCurrentPage));
  };

  console.log({ isLoadingAirdrop, errorAirdrop, isSuccessAirdrop });

  return (
    <a onClick={() => handleSubmit()}>
      {isLoadingAirdrop ? (
        <LoadingOutlined style={{ fontSize: 20, color: "#0077c7" }} spin />
      ) : (
        "Activate Airdrop"
      )}
    </a>
  );
}
