import React, { useEffect } from "react"
import { Typography, Col, Row, Table, Tag } from "antd"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import {
  getTransactionHistory,
  getWalletUser,
} from "../../modules/wallet/action"
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from "../../constants/constant_wallet"
import { fromIBFx } from "../../utils/utils"
import useWindowDimensions from "../../utils/windowDimensions"
import CardTransaction from "../../components/CardTransactions"
import ModalReport from "../../components/ModalReport"

const { Title } = Typography

export default function WalletTransactions(props) {
  const windowDimensions = useWindowDimensions()
  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  )
  const transactionsData = useSelector((state) =>
    state.getIn(["wallet", "transactionsHistory", "data"]).toJS()
  )
  const transactionsCurrentPage = useSelector((state) =>
    state.getIn(["wallet", "transactionsHistory", "currentPage"])
  )
  const transactionsTotal = useSelector((state) =>
    state.getIn(["wallet", "transactionsHistory", "total"])
  )

  const dispatch = useDispatch()

  const onPageChange = (num) => {
    dispatch(getTransactionHistory(num, walletUser.user_id))
  }

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <>{fromIBFx(amount)}</>,
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (dateTime) => (
        <>{moment(dateTime).format("DD/MM/YYYY HH:mm A")}</>
      ),
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionCode",
      key: "transactionCode",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => {
        let color = "success"
        let text = ""

        if (type === TRANSACTION_TYPE.BUY_BACK) {
          color = "volcano"
        }
        if (type === TRANSACTION_TYPE.PAY) {
          color = "orange"
        }
        if (type === TRANSACTION_TYPE.REDEEM_VOUCHER) {
          color = "blue"
        }
        if (type === TRANSACTION_TYPE.TOPUP) {
          color = "purple"
          text = "TOP-UP"
        }
        return (
          <>
            <Tag className="tag-custom" color={color}>
              {text.length <= 0 ? type.toUpperCase() : text}
            </Tag>
          </>
        )
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        let color = "success"

        if (status === TRANSACTION_STATUS.INIT) {
          color = "blue"
        }
        if (status === TRANSACTION_STATUS.PROCESSING) {
          color = "warning"
        }
        if (status === TRANSACTION_STATUS.SUCCESS) {
          color = "success"
        }
        if (status === TRANSACTION_STATUS.FAILED) {
          color = "error"
        }
        if (status === TRANSACTION_STATUS.EXPIRED) {
          color = "volcano"
        }
        return (
          <>
            <Tag className="tag-custom" color={color}>
              {status.toUpperCase()}
            </Tag>
          </>
        )
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, record) => <ModalReport data={record} />,
    },
  ]

  useEffect(() => {
    dispatch(getWalletUser())
  }, [])

  return (
    <>
      <Row className="mt-10px">
        <Col span={24}>
          <Title level={3}>Transactions</Title>
        </Col>
      </Row>
      {windowDimensions.width > 835 ? (
        <Row className="mt-20px">
          <Col span={24}>
            <Table
              dataSource={transactionsData}
              columns={columns}
              pagination={{
                onChange: onPageChange,
                current: transactionsCurrentPage,
                pageSize: 15,
                total: transactionsTotal,
              }}
            />
          </Col>
        </Row>
      ) : (
        <Row className="mt-10px">
          {transactionsData.map((e, i) => (
            <CardTransaction key={i} data={e} />
          ))}
        </Row>
      )}
    </>
  )
}
