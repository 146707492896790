import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Typography,
  Row,
  Input,
  Drawer,
  Image,
  Button,
  Select,
  Col,
  Progress,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import ReactCodeInput from "react-code-input";
import {
  ColCenter,
  RowCenter,
  ColSpaceBetween,
  InputNumberStyled,
  RowSpaceBetween,
  SelectStyled,
} from "../general_styled";
import ibfx1 from "../../assets/images/ibfx-icon.svg";

import { ContentStyled } from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import emptyNfts from "../../assets/images/empty_nfts.svg";
import CardNFT from "../CardNFT";
import jwtDecode from "jwt-decode";
import {
  createDepositNft,
  getAllOwnedAuction,
} from "../../modules/affluence/action";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { decryptMnemonic, toIBFx } from "../../utils/utils";
import { IconCoins } from "../ModalSellNFTDetail/styled";
import { AUCTION_STATUS } from "../../constants/constant_credence";
import { CREDENCE_HOST } from "../../configs";
import { DividerCard } from "../ModalConfluenceOffers/styled";

const { Title, Text } = Typography;

const { TextArea } = Input;

export default function ModalAffluenceDepositNft(props) {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMode, setIsMode] = useState(0);
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [deadline, setDeadline] = useState();
  const [selectedNFT, setSelectedNFT] = useState();
  const [selectedNFTObj, setSelectedNFTObj] = useState({});
  const [processingPercent, setProcessingPercent] = useState(0);
  const [minimumPrice, setMinimumPrice] = useState(0);

  const nftsVault = useSelector((state) =>
    state.getIn(["affluence", "availableNft"]).toJS()
  );

  useEffect(() => {
    const nft = nftsVault.find((nft) => nft.nft_id === selectedNFT);
    setSelectedNFTObj(nft);
  }, [selectedNFT]);

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
    setPinCode("");
    setProcessing(false);
    setSuccess(false);
    setFailed(false);
    setProcessingPercent(0);
    setIsMode(0);
    setSelectedNFT();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.getTime();
  };

  const onSubmit = async () => {
    setIsMode(3);
    setProcessing(true);
    setProcessingPercent(10);

    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      const decodedUser = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
      const body = {
        auction_id: props.auction?.auction_id,
        nft_id: selectedNFT,
        minimum_bid: toIBFx(minimumPrice),
        deadline: addDays(new Date(), deadline),
      };

      setProcessingPercent(20);
      await createDepositNft(_decryptMnemonic, body);
      setProcessingPercent(80);
      await dispatch(getAllOwnedAuction(decodedUser.user_id));
      setProcessingPercent(100);
      setSuccess(true);
    } catch (e) {
      message.error(e);
      setFailedMessage(e);
      setFailed(true);
    }

    setProcessing(false);
  };

  const onContinue = () => {
    if (isMode === 0) {
      setIsMode(1);
    } else if (isMode === 1) {
      setIsMode(2);
    } else if (isMode === 2) {
      const _checkPin = checkPin();
      if (_checkPin) {
        onSubmit();
      }
    } else {
      handleCancel();
    }
  };

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderCardNFT = (nft) => {
    const metadata = JSON.parse(nft.metadata_json);
    return (
      <CardNFT
        nftId={nft.nft_id}
        name={metadata.name}
        collectionName={metadata.collection.name}
        ipfsHash={metadata.image.replace("ipfs://", "")}
        is_pdf={metadata.is_pdf || false}
        setSelectedNFT={setSelectedNFT}
        selectedNFT={selectedNFT}
        key={nft.nft_id}
        data={nft}
      />
    );
  };

  const resolveInputMinimumBid = () => {
    const metadata = JSON.parse(selectedNFTObj.metadata_json);
    return (
      <ColCenter className="w-100">
        <ColCenter>
          <Title className="text-w700 gray-7 mb-0px" level={3}>
            {t("enter_auction_details")}
          </Title>
          <Text className="gray-7">{t("enter_auction_details_desc")}</Text>
        </ColCenter>
        <Row gutter={[24, 24]} className="w-100 mt-20px">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <ColCenter className="w-100">
              {metadata?.is_pdf ? (
                <Image
                  preview={false}
                  style={{ borderRadius: 4, height: 250 }}
                  src={CREDENCE_HOST + "/files/" + selectedNFTObj?.cover_file}
                />
              ) : (
                <Image
                  preview={false}
                  style={{ borderRadius: 4, height: 250 }}
                  src={`https://ipfs.io/ipfs/${metadata.image.replace(
                    "ipfs://",
                    ""
                  )}`}
                />
              )}
            </ColCenter>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <RowSpaceBetween className="mb-16px">
              <Text>{t("name")}</Text>
              <Text className="text-w600">{metadata.name}</Text>
            </RowSpaceBetween>
            <RowSpaceBetween className="mb-10px">
              <Text>{t("collection")}</Text>
              <Text className="text-w600">{metadata.collection?.name}</Text>
            </RowSpaceBetween>
            <DividerCard />
            <InputNumberStyled
              type="number"
              prefix={<IconCoins className="no-margin" src={ibfx1} />}
              style={{ padding: "0px 0px 0px 12px" }}
              className="w-100"
              placeholder={t("min_price")}
              min={0}
              onChange={(num) => setMinimumPrice(num)}
            />
            <DividerCard />
            <SelectStyled
              className={"w-100 "}
              type="dropdown"
              name="deadline"
              size="large"
              align="left"
              placeholder={t("select_deadline")}
              value={deadline}
              filterOption={true}
              onChange={async (val) => {
                setDeadline(val);
              }}
            >
              <Select.Option value={1}>1 {t("day")}</Select.Option>
              <Select.Option value={2}>2 {t("days")}</Select.Option>
              <Select.Option value={3}>3 {t("days")}</Select.Option>
              <Select.Option value={4}>4 {t("days")}</Select.Option>
              <Select.Option value={5}>5 {t("days")}</Select.Option>
              <Select.Option value={6}>6 {t("days")}</Select.Option>
              <Select.Option value={7}>1 {t("weeks")}</Select.Option>
              <Select.Option value={10}>10 {t("days")}</Select.Option>
              <Select.Option value={14}>2 {t("weeks")}</Select.Option>
              <Select.Option value={21}>3 {t("weeks")}</Select.Option>
              <Select.Option value={30}>1 {t("month")}</Select.Option>
            </SelectStyled>
          </Col>
        </Row>
      </ColCenter>
    );
  };

  const renderInputPin = () => {
    return (
      <ColSpaceBetween className="stretch" style={{ minHeight: 280 }}>
        <ColCenter>
          <Title className="text-w700 gray-7 mb-0px" level={3}>
            {t("enter_pin")}
          </Title>
          <Text className="gray-7"> {t("enter_pin_desc")}</Text>
        </ColCenter>
        <RowCenter>
          <ReactCodeInput
            type="password"
            onChange={(e) => setPinCode(e)}
            fields={4}
            {...codeProps}
          />
        </RowCenter>
      </ColSpaceBetween>
    );
  };

  const renderOnProcessing = () => {
    return (
      <ColCenter className="w-100 h-">
        <Progress
          type="circle"
          percent={processingPercent}
          status={isFailed && "exception"}
        />
        <Title className="text-w700 gray-7 mb-0px mt-24px" level={3}>
          {isProcessing && !isSuccess && !isFailed
            ? t("processing")
            : isSuccess && !isFailed
            ? t("success") + "!"
            : t("failed")}
        </Title>
        <Text className="gray-7" align="center">
          {isProcessing && !isSuccess && !isFailed
            ? t("process_max_one_minutes")
            : isSuccess && !isFailed
            ? t("success_deposit_nft")
            : t("failed_deposit_nft")}
        </Text>
      </ColCenter>
    );
  };

  const resolveModalContent = () => {
    if (isMode === 0) {
      return (
        <ColCenter className="w-100">
          <Title className="text-w700 gray-7 mb-0px" level={3}>
            {t("deposit_nft")}
          </Title>
          <Text className="gray-7" align="center">
            {t("deposit_nft_desc")}
          </Text>
          <Row style={{ paddingTop: 15 }} className="w-100" gutter={[16, 16]}>
            {nftsVault?.length > 0
              ? nftsVault?.map((nft) => renderCardNFT(nft))
              : renderEmpty(
                  emptyNfts,
                  t("no_nfts_found"),
                  t("no_nfts_found_desc")
                )}
          </Row>
        </ColCenter>
      );
    } else if (isMode === 1) {
      return resolveInputMinimumBid();
    } else if (isMode === 2) {
      return renderInputPin();
    } else {
      return renderOnProcessing();
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const renderEmpty = (img, title, subtitle) => {
    return (
      <ColCenter
        className="w-100"
        style={{ height: "100%", minHeight: "calc(100vh - 180px)" }}
      >
        <Image preview={false} src={img} />
        <Title align="center" className="mb-0px mt-24px" level={3}>
          {title}
        </Title>
        <Text style={{ textAlign: "center" }}>{subtitle}</Text>
      </ColCenter>
    );
  };

  return (
    <>
      <Button
        type={"primary"}
        disabled={props.auction.status === AUCTION_STATUS.PENDING_ESCROW}
        style={{
          height: 40,
          borderRadius: 4,
          margin: "0 12px",
          width: "calc(100% - 24px)",
        }}
        onClick={() => showModal()}
      >
        {t("add_nft")}
      </Button>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width={isMode === 0 ? "100%" : "50%"}
          footer={[
            <RowCenter
              style={{ width: "95%", margin: "0 2.5%", marginBottom: "16px" }}
            >
              <Button
                className="mt-16px"
                style={{ width: isMode === 0 ? "30%" : "50%", borderRadius: 4 }}
                disabled={
                  (isMode === 0 && !selectedNFT) ||
                  (isMode === 2 && isProcessing)
                }
                type="primary"
                size="large"
                onClick={() => onContinue()}
              >
                {isMode === 0 || isMode === 1
                  ? t("continue")
                  : isMode === 2
                  ? t("submit_pin")
                  : t("dismiss")}
              </Button>
            </RowCenter>,
          ]}
        >
          <ContentStyled className={isMode === 3 && "center"} isMode={isMode}>
            {resolveModalContent()}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled className={isMode === 3 && "center"} isMode={isMode}>
            {resolveModalContent()}
          </ContentStyled>
          <RowCenter
            style={{ width: "95%", margin: "0 2.5%", marginBottom: "16px" }}
          >
            <Button
              className="mt-16px"
              style={{ width: isMode === 0 ? "30%" : "50%", borderRadius: 4 }}
              disabled={
                (isMode === 0 && !selectedNFT) || (isMode === 2 && isProcessing)
              }
              type="primary"
              size="large"
              onClick={() => onContinue()}
            >
              {isMode === 0 || isMode === 1
                ? t("continue")
                : isMode === 2
                ? t("submit_pin")
                : t("dismiss")}
            </Button>
          </RowCenter>
        </Drawer>
      )}
    </>
  );
}
