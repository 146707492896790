import { fromJS } from "immutable";
import { CLOSE_IMPORT_MNEMONIC_MODAL, OPEN_IMPORT_MNEMONIC_MODAL } from "../constants";

const initialState = fromJS({
  isModalVisible : false,
  title: ''
});

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_IMPORT_MNEMONIC_MODAL:
      return state.merge({
        isModalVisible: true,
        title: action.payload.title
      });
    case CLOSE_IMPORT_MNEMONIC_MODAL:
      return state.merge({
        isModalVisible: false,
        title: ''
      });
    default:
      return state;
  }
};
