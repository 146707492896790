import algosdk from "algosdk";
import axios from "axios";
import {
  GET_ASSETS,
  GET_ASSETS_FAILED,
  GET_ASSETS_SUCCESS,
  GET_TRANSACTION_HISTORY,
  GET_TRANSACTION_HISTORY_FAILED,
  GET_TRANSACTION_HISTORY_SUCCESS,
  SET_IBFX_AMOUNT_PER_USD,
  SET_WALLET_USER,
} from "../constants";
import { algodClient, headers, HOST } from "../../configs";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import jwtDecode from "jwt-decode";

export const registerWalletUser = async (
  userId,
  algoAddress,
  encryptedMnemonic,
  dispatch
) => {
  try {
    const response = (
      await axios.post(
        `${HOST}/users`,
        {
          user_id: userId,
          algo_address: algoAddress,
          encrypted_mnemonic: encryptedMnemonic || null,
        },
        {
          headers: headers,
        }
      )
    ).data;

    dispatch({
      type: SET_WALLET_USER,
      payload: {
        data: response.data,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const storeMnemonic = async (encrypted_mnemonic) => {
  try {
    const localAuth = localStorage.getItem("USER_AUTH_TOKEN");
    const decode = jwtDecode(localAuth);

    const headers = {
      Authorization: `Bearer ${localAuth}`,
    };
    const result = (
      await axios.patch(
        `${HOST}/users/${decode.user_id}/mnemonic`,
        { encrypted_mnemonic },
        {
          headers: headers,
        }
      )
    ).data;

    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getWalletUser = (userId) => async (dispatch) => {
  try {
    const localAuth = localStorage.getItem("USER_AUTH_TOKEN");
    const decode = jwtDecode(localAuth);

    const response = (
      await axios.get(`${HOST}/users/user_id/${userId || decode.user_id}`, {
        headers: headers,
      })
    ).data;
    await dispatch({
      type: SET_WALLET_USER,
      payload: {
        data: response.data,
      },
    });

    await dispatch(getAllAssets(response.data));
    await dispatch(getTransactionHistory(1, response.data.user_id));
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getWalletMnemonic = async (userId) => {
  try {
    const localAuth = localStorage.getItem("USER_AUTH_TOKEN");
    const decode = jwtDecode(localAuth);

    const response = (
      await axios.get(
        `${HOST}/users/user_id/${userId || decode.user_id}/mnemonic`,
        {
          headers: headers,
        }
      )
    ).data;

    return response.data?.encrypted_mnemonic;
  } catch (e) {
    throw e;
  }
};

export const getTransactionHistory =
  (pagination = 1, userId) =>
  async (dispatch) => {
    dispatch({
      type: GET_TRANSACTION_HISTORY,
    });

    try {
      // const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"))
      // console.log(decoded)

      const response = await axios.get(`${HOST}/transactions/get_history`, {
        headers: headers,
        params: {
          user_id: userId,
          page: pagination,
        },
      });

      const { data } = response.data;
      // const { page } = data

      dispatch({
        type: GET_TRANSACTION_HISTORY_SUCCESS,
        payload: {
          total: data.page.totalElements,
          hasNext: !data.page.last,
          data: data.page.content,
          page: pagination,
        },
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: GET_TRANSACTION_HISTORY_FAILED,
        payload: {
          errorMessage: extractErrorMessage(e),
        },
      });
      throw e;
    }
  };

const getUserAssets = async (userId) => {
  const response = await axios.get(`${HOST}/user-assets/${userId}`, {
    headers: headers,
  });
  const { data } = response.data;

  return data;
};

const getUserBalances = async (algoAddress) => {
  const response = await axios.get(`${HOST}/users/${algoAddress}/assets`, {
    headers: headers,
  });
  const { data } = response.data;

  return data;
};

export const getAllAssets = (user) => async (dispatch) => {
  dispatch({
    type: GET_ASSETS,
  });
  try {
    const response = await axios.get(`${HOST}/assets`, {
      headers: headers,
    });
    const userAssets = await getUserAssets(user.user_id);
    const userBalances = await getUserBalances(user.algo_address);

    const { data } = response.data;

    let combinedData = [];

    data.forEach((element) => {
      const _filteredUserAssets = userAssets.filter(
        (e) => e.asset_id === element.asset_id
      );
      const _filteredUserBalance = userBalances
        ? userBalances.filter((e) => e.asset.assetId === element.asset_id)
        : [];

      let _newElement;

      if (_filteredUserBalance) {
        if (_filteredUserAssets) {
          _newElement = {
            ...element,
            ..._filteredUserAssets[0],
            ..._filteredUserBalance[0],
          };
        } else {
          _newElement = {
            ...element,
            ..._filteredUserBalance[0],
          };
        }
      } else {
        _newElement = element;
      }
      combinedData.push(_newElement);
    });
    array_move(
      combinedData,
      combinedData.findIndex((e) =>
        e.asset_name ? e.asset_name === "IBFS" : e.asset_name === "IBFS"
      ),
      combinedData.length - 1
    );

    dispatch({
      type: GET_ASSETS_SUCCESS,
      payload: {
        data: combinedData.sort(),
      },
    });
  } catch (e) {
    dispatch({
      type: GET_ASSETS_FAILED,
      payload: {
        message: extractErrorMessage(e),
      },
    });

    throw e;
  }
};

const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export const activateUserAsset = async (mnemonic, body) => {
  try {
    const response = await axios.post(`${HOST}/user_asset`, body, {
      headers,
    });

    const { data } = response.data;

    await submitSignedOperation(mnemonic, data);
  } catch (e) {
    throw e;
  }
};

export const createOrder = async (body) => {
  try {
    const response = await axios.post(`${HOST}/orders`, body, {
      headers,
    });

    const { data } = response.data;
    return data;
  } catch (e) {
    throw e;
  }
};

export const reportTransaction = async (body) => {
  try {
    const response = await axios.post(`${HOST}/transaction-reports`, body, {
      headers,
    });

    const { data } = response.data;
    return data;
  } catch (e) {
    throw e;
  }
};

export const createTransactions = async (mnemonic, body, isReturn = false) => {
  try {
    const operations = await createOperations(body);
    const result = await submitSignedOperation(mnemonic, operations, isReturn);

    if (isReturn) {
      let { payment_client_secret } = result;
      return payment_client_secret;
    }
  } catch (e) {
    throw e;
  }
};

export const createOperations = async (body) => {
  try {
    const response = await axios.post(`${HOST}/transactions`, body, {
      headers,
    });

    const { data } = response.data;

    return data;
  } catch (e) {
    throw e;
  }
};

export const combinePayment = async (mnemonic, body, isReturn = false) => {
  try {
    const response = await axios.post(
      `${HOST}/transactions/combine_payment`,
      body,
      {
        headers,
      }
    );

    const { data } = response.data;

    const result = await submitSignedOperation(mnemonic, data, isReturn);

    if (isReturn) {
      let { payment_client_secret } = result;
      return payment_client_secret;
    }
  } catch (e) {
    throw e;
  }
};

const submitSignedOperation = async (
  mnemonic,
  operations,
  isReturn = false
) => {
  try {
    const signedOperations = await signingOperation(mnemonic, operations);

    const result = await axios.post(
      `${HOST}/operations/submit_signed_operation`,
      signedOperations,
      {
        headers,
      }
    );

    if (isReturn) {
      const { data } = result.data;
      return data;
    }

    // message.success("Your transactions has been process")
  } catch (e) {
    throw e;
  }
};

export const apiSignedOperation = async (
  signedOperations,
  isReturn = false
) => {
  try {
    const result = await axios.post(
      `${HOST}/operations/submit_signed_operation`,
      signedOperations,
      {
        headers,
      }
    );

    if (isReturn) {
      const { data } = result.data;
      return data;
    }

    // message.success("Your transactions has been process")
  } catch (e) {
    throw e;
  }
};

export const signingOperation = async (mnemonic, operations) => {
  try {
    let params = await algodClient.getTransactionParams().do();

    const userKey = algosdk.mnemonicToSecretKey(mnemonic);
    let signedOperations = [];
    let txns = [];
    for (const operation of operations) {
      var bytes = Uint8Array.from(
        Buffer.from(operation.algo_transaction, "base64")
      );
      var transaction = algosdk.decodeUnsignedTransaction(bytes);
      transaction.firstRound = params.firstRound;
      transaction.lastRound = params.lastRound;
      const signedOperation = {
        operation_id: operation.operation_id,
        operation_group: operation.operation_group,
        transaction: transaction,
      };
      signedOperations.push(signedOperation);
      txns.push(signedOperation.transaction);
    }
    algosdk.assignGroupID(txns);

    for (var signedOperation of signedOperations) {
      signedOperation.algo_transaction = Buffer.from(
        algosdk.encodeUnsignedTransaction(signedOperation.transaction)
      ).toString("base64");
      var signedTxn = algosdk.signTransaction(
        signedOperation.transaction,
        userKey.sk
      );
      var signedEncoded = Buffer.from(signedTxn.blob).toString("base64");
      signedOperation.algo_transaction_id = signedTxn.txID;
      signedOperation.signed_algo_transaction = signedEncoded;

      delete signedOperation.transaction;
    }

    return signedOperations;
  } catch (e) {
    console.log(e);
  }
};

export const addUserBankInfo = async (body) => {
  try {
    const response = await axios.post(`${HOST}/user-bank-infos`, body, {
      headers: headers,
    });

    const { data } = response.data;

    return data;
  } catch (e) {
    throw e;
  }
};

export const getUserBankInfoByUserId = async () => {
  try {
    const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));

    const response = await axios.post(
      `${HOST}/user-bank-infos/by-user-id/${decoded.user_id}`,
      {
        headers: headers,
      }
    );

    const { data } = response.data;

    return data;
  } catch (e) {
    throw e;
  }
};

export const redeemVoucher = async (body) => {
  try {
    const response = await axios.post(`${HOST}/vouchers/redeem`, body, {
      headers: headers,
    });

    const { data } = response.data;

    return data;
  } catch (e) {
    throw e;
  }
};

export const getConfig = (configKey) => async (dispatch) => {
  try {
    const response = await axios.get(`${HOST}/configs/${configKey}`, {
      headers: headers,
    });

    const algoAmount = await axios.get(`${HOST}/configs/ALGO_AMOUNT_PER_USD`, {
      headers: headers,
    });

    const { data } = response.data;
    const { data: algoAmountData } = algoAmount.data;
    dispatch({
      type: SET_IBFX_AMOUNT_PER_USD,
      payload: {
        ...data,
        algoAmountData,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const payWithStripe = async (body) => {
  try {
    const response = await axios.post(`${HOST}/pay_with_stripe`, body, {
      headers: headers,
    });

    const { data } = response.data;

    return data;
  } catch (e) {
    throw e;
  }
};
