import { fromJS } from "immutable";
import {
  CLOSE_LOGIN_REGISTER_MODAL,
  OPEN_LOGIN_REGISTER_MODAL,
  SET_RECENT_ACTIVITY,
  SET_SECTION,
  SET_PAGE_MENU,
  SET_BENEVOLENCE_PROJECT_TYPE,
  SET_USER_SIGN,
} from "../constants";

const initialState = fromJS({
  section: "",
  param: "",
  language: "",
  isModalLoginRegister: false,
  activities: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    total: 0,
  }),
  pageMenu: null,
  benevolenceProjectType: "",
  userSign: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SECTION:
      return state.merge({
        section: action.payload.section,
        param: action.payload.param,
      });
    case SET_RECENT_ACTIVITY:
      return state.merge({
        activities: fromJS({
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          currentPage: action.payload.page,
          total: action.payload.total,
        }),
      });
    case OPEN_LOGIN_REGISTER_MODAL:
      return state.merge({
        isModalLoginRegister: true,
      });
    case CLOSE_LOGIN_REGISTER_MODAL:
      return state.merge({
        isModalLoginRegister: false,
      });
    case SET_PAGE_MENU:
      return state.merge({
        pageMenu: action.payload,
      });
    case SET_BENEVOLENCE_PROJECT_TYPE:
      return state.merge({
        benevolenceProjectType: action.payload,
      });
    case SET_USER_SIGN:
      return state.merge({
        userSign: action.payload,
      });
    default:
      return state;
  }
}
