import { fromJS } from 'immutable'
import moment from 'moment'
import { COMMON_CONSTANT } from '../../constants/constant_common'
import { fromIBFx } from '../../utils/utils'
import {
	SET_AFFLUENCE,
	SET_AVAILABLE_NFT,
	SET_DETAIL_AFFLUENCE,
	SET_OFFER,
	SET_OWNED_AUCTION,
	SET_PRICE_HISTORY_AUCTION,
	SET_SELECTED_COLLECTION,
	SET_VISITED_USER,
	UPDATE_AUCTION_WISHLIST,
} from '../constants'

const initialState = fromJS({
	isLoading: false,
	hasError: false,
	errorMessage: '',
	auctions: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	priceHistory: fromJS([]),
	detailAuctions: fromJS({}),
	offers: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	ownedAuctions: fromJS([]),
	availableNft: fromJS([]),
	visitedUser: fromJS({}),
	selectedCollection: fromJS({}),
	wishlist: localStorage.getItem(COMMON_CONSTANT.WISHLIST_AUCTION)
		? fromJS(JSON.parse(localStorage.getItem(COMMON_CONSTANT.WISHLIST_AUCTION)))
		: fromJS([]),
})

const updateWishlist = (wishlist, course) => {
	let existingIndex = -1
	for (let index = 0; index < wishlist.length; index++) {
		if (wishlist[index].course_id === course.course_id) {
			existingIndex = index
		}
	}

	if (existingIndex >= 0) {
		wishlist.splice(existingIndex, 1)
	} else {
		wishlist.push(course)
	}

	localStorage.setItem(COMMON_CONSTANT.WISHLIST_COURSE, JSON.stringify(wishlist))

	return wishlist
}
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_AFFLUENCE:
			return state.merge({
				auctions: fromJS(action.payload),
			})
		case SET_DETAIL_AFFLUENCE:
			return state.merge({
				detailAuctions: fromJS(action.payload),
			})
		case SET_VISITED_USER:
			return state.merge({
				visitedUser: fromJS(action.payload),
			})
		case SET_SELECTED_COLLECTION:
			return state.merge({
				selectedCollection: fromJS(action.payload),
			})
		case SET_OFFER:
			return state.merge({
				offers: fromJS(action.payload),
			})
		case SET_OWNED_AUCTION:
			return state.merge({
				ownedAuctions: fromJS(action.payload),
			})
		case SET_PRICE_HISTORY_AUCTION:
			const data = action.payload.map((item) => ({
				created_at: moment(item.created_at).format('YYYY-MM-DD'),
				total_ibfx: fromIBFx(item.total_ibfx),
			}))
			return state.merge({
				priceHistory: fromJS(data),
			})
		case UPDATE_AUCTION_WISHLIST:
			return state.merge({
				wishlist: fromJS(updateWishlist(state.getIn(['wishlist']).toJS(), action.payload)),
			})
		case SET_AVAILABLE_NFT:
			return state.merge({
				availableNft: fromJS(action.payload),
			})
		default:
			return state
	}
}
