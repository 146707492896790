import { Col, Image, Typography, Row, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColCenter } from '../../components/general_styled'
import ModalCreateNFT from '../../components/ModalCreateNFT'
import { RowEnd, SearchStyled, TabsStyled } from './styled'
import emptyNfts from '../../assets/images/empty_nfts.svg'
import ShimmerCustom from '../../components/ShimmerCustom'
import useWindowDimensions from '../../utils/windowDimensions'
import CardAuction from '../../components/CardAuction'
import { getAllOwnedAuction, getAvailableNFT } from '../../modules/affluence/action'
import ModalAffluenceAddAuction from '../../components/ModalAffluenceAddAuction'
import jwtDecode from 'jwt-decode'

const { TabPane } = Tabs
const { Title, Text } = Typography

export default function AffluenceAuctionListing() {
	const { t } = useTranslation()

	const windowDimensions = useWindowDimensions()
	const [menu, setMenu] = useState('listing')
	const [isLoading, setLoading] = useState(true)
	const [searchVal, setSearchVal] = useState(null)
	const param = useSelector((state) => state.getIn(['general', 'param']))

	const auctions = useSelector((state) => state.getIn(['affluence', 'ownedAuctions']).toJS())

	const wishlist = useSelector((state) => state.getIn(['affluence', 'wishlist']).toJS())

	const dispatch = useDispatch()

	const initData = async () => {
		const decodedUser = jwtDecode(localStorage.getItem('USER_AUTH_TOKEN'))
		await dispatch(getAllOwnedAuction(decodedUser.user_id))
		await dispatch(getAvailableNFT())
		setLoading(false)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		initData()
		setMenu(param || 'listing')
	}, [])

	const resolveShimmer = () => {
		let children = []
		for (let i = 0; i < 9; i++) {
			children.push(
				<Col key={i} xs={24} sm={24} md={windowDimensions.width > 925 ? 12 : 24} lg={12} xl={8} xxl={6}>
					<ShimmerCustom width='100%' height='460px' borderRadius='2px' />
					<ShimmerCustom width='80%' height='20px' borderRadius='2px' className='mt-12px' />
					<ShimmerCustom width='60%' height='12px' borderRadius='2px' />
				</Col>
			)
		}

		return children
	}

	const renderSearchBar = () => {
		return (
			<RowEnd>
				<Col span={24}>
					<SearchStyled
						className='search-input'
						placeholder='Search'
						autoComplete='off'
						allowClear
						size='large'
						onSearch={(value) => setSearchVal(value)}
						style={{
							marginRight: '12px',
						}}
					/>
				</Col>

				{/* {menu === "vault" && nfts?.length <= 0 ? (
          <></>
        ) : (
          <Col offset={1} span={9}>
            <ModalCreateNFT toApproval={() => setMenu("approvals")} />
          </Col>
        )} */}
			</RowEnd>
		)
	}

	const renderEmpty = (img, title, subtitle) => {
		return (
			<ColCenter style={{ height: '100%', minHeight: 'calc(100vh - 180px)' }}>
				<Image preview={false} src={img} />
				<Title align='center' className='mb-0px mt-24px' level={3}>
					{title}
				</Title>
				<Text style={{ width: '37%', textAlign: 'center' }}>{subtitle}</Text>
				{menu === 'vault' && (
					<div style={{ width: '350px', marginTop: 24 }}>
						<ModalCreateNFT toApproval={() => setMenu('approvals')} />
					</div>
				)}
			</ColCenter>
		)
	}

	const renderMyListing = () => {
		return isLoading
			? resolveShimmer()
			: auctions?.map((auction) => {
					return <CardAuction auction={auction} isListingStyle isDelisting />
			  })
		// : renderEmpty(
		//     emptyNfts,
		//     "No NFTs Found",
		//     "Sell a NFT and it will show up here "
		//   )
	}

	const renderFavourite = () => {
		return isLoading ? (
			<Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
				{resolveShimmer()}
			</Row>
		) : wishlist?.length > 0 ? (
			<Row style={{ paddingTop: 15 }}>
				{wishlist?.map((auction) => {
					return <CardAuction auction={auction} isListingStyle isDelisting />
				})}
			</Row>
		) : (
			renderEmpty(emptyNfts, t('no_nft_found'), t('no_nft_desc'))
		)
	}

	return (
		<>
			{windowDimensions.width < 835 && renderSearchBar()}
			<TabsStyled
				size='large'
				tabBarExtraContent={windowDimensions.width > 834 ? renderSearchBar() : null}
				onChange={(val) => setMenu(val)}
				defaultActiveKey={menu}
				activeKey={menu}
			>
				<TabPane tab={t('my_listing')} key='listing'>
					<Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
						<ModalAffluenceAddAuction total={auctions.length} />
						{renderMyListing()}
					</Row>
				</TabPane>
				<TabPane tab={t('favourites')} key='favourites'>
					{renderFavourite()}
				</TabPane>
			</TabsStyled>
		</>
	)
}
