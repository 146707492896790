import styled from "@emotion/styled";
import { Col, Modal, Row, Steps, Typography, Form } from "antd";
import { ButtonStyled, ColCenter } from "../general_styled";

const { Text } = Typography;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 24px 0 0 0;
    height: 610px;
  }

  .ant-modal-content {
    border-radius: 15px !important;
  }
`;

export const TitleStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  /* Primary Gray */

  color: #0b0e14;
  margin: 0;
  margin-bottom: 4px;
`;

export const SubtitleStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Secondary Gray */

  color: #3d424d;
  margin: 0;
  margin-bottom: 32px;
`;

export const CreateProjectCards = styled(Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  row-gap: 10px;
`;

export const CreateProjectCard = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  width: 360px;
  min-height: 390px;

  /* BG White */

  background: #fcfdff;
  /* Stroke */

  border: 1px solid #dfe7f7;
  box-sizing: border-box;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  border-radius: 8px;
  padding: 24px;

  &:first-of-type {
    margin-right: 32px;
  }
`;

export const TypeLabelStyled = styled.span`
  background: #ebeffe;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  border-radius: 6px;

  padding: 8px;
  margin-bottom: 24px;

  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Secondary CTA */

  color: #4e73f8;
  width: fit-content;
`;

export const CardTitleStyled = styled.p`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  /* Primary Gray */

  color: #0b0e14;
  margin: 0;
  margin-bottom: 4px;
  min-height: 50px;
`;

export const CardDescStyled = styled.p`
  width: 235px;
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 143% */

  /* Secondary Gray */

  color: #3d424d;
  margin-bottom: 24px;
`;

export const CardUlStyled = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const CardLiStyled = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 9px;

  img {
    margin: 0;
    padding: 0;
    width: 10px;
    margin-right: 6px;
  }

  p {
    display: table-cell;
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;

    /* Field Text */

    color: #5d6f93;
    margin: 0;
  }
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const FormTitleStyled = styled.h1`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Primary Gray */

  color: #0b0e14;
  margin: 0;
  margin-bottom: 4px;
`;

export const FormSubtitleStyled = styled.h1`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Secondary Gray */

  color: #3d424d;
  margin: 0;
  margin-bottom: 32px;
`;

export const StepsStyled = styled(Steps)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #fcfcfc;
  border: 1px solid #d6dae1;
  box-sizing: border-box;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  .ant-steps-item:last-child {
    padding-right: 0;
  }

  .ant-steps-item {
    flex: 0 1 auto;
    padding-right: 24px;

    .ant-steps-icon {
      // font-family: "Roboto";
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 17px;
    }

    .ant-steps-content {
      // font-family: "Roboto";
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
    }
  }
`;

export const FormBodyStyled = styled.div`
  height: 100%;
  width: 100%;

  padding: 32px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  overflow: auto;
`;

export const FormFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 24px;
  background: #fcfcfc;
  /* Sticky Shadow Up */

  box-shadow: 0px -4px 6px rgba(197, 205, 234, 0.25);
  border-radius: 0px 0px 4px 4px;
`;

export const StepOneStyled = styled(Col)`
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const NavButtonStyled = styled(ButtonStyled)`
  width: 100%;
`;

export const FormGroupTitle = styled.h4`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;

  display: flex;
  align-items: center;

  color: #404d61;
  margin: 0;
`;

export const FormGroupSubTitle = styled.h5`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-transform: none;
  /* identical to box height */

  /* Secondary Gray */

  color: #3d424d;
  margin-bottom: 24px;
  margin-top: 8px;
`;

export const FormGroup = styled(Form.Item)`
  // display: flex;
  // flex-direction: column;
  // min-height: 90px;
  margin-bottom: 0px;
  // // margin-top: 50px;
  display: flex;
`;

export const FormGroupLabel = styled.span`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  /* Text/400 */

  color: #273248;
  margin-bottom: 8px;
`;

export const AddContributionStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px dashed #6b7a99;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;

  padding: 16px;

  .fa-plus {
    margin-right: 8px;
  }

  span {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    /* identical to box height */

    /* Field Text */

    color: #5d6f93;
  }
`;

export const LastStepStyled = styled(ColCenter)`
  img {
    width: 88px;
    margin-bottom: 16px;
  }

  h1 {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    /* Primary Gray */

    color: #0b0e14;
    margin: 0;
    margin-bottom: 4px;
  }

  span.text {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* or 130% */

    text-align: center;

    /* Secondary Gray */

    color: #3d424d;
    max-width: 269px;
    margin-bottom: 24px;
  }
`;
