import styled from "@emotion/styled";
import { Card, Drawer, Image, Row, Tabs, Typography } from "antd";

const { Text } = Typography;

export const TabStyled = styled(Tabs)`
  .ant-tabs-tab {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-tabs-tab-active {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CardStyled = styled(Card)`
  background: #ffffff;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  /* Alim Special Shadow */

  box-shadow: 0px 2px 2px rgba(212, 213, 217, 0.4);
  border-radius: 4px;

  .ant-card-body {
    height: 161px;
    padding: 20px;
    overflow: hidden;
  }

  .ant-card-actions {
    border-top: none;
    padding: 20px;

    li {
      margin: 0 0 !important;
    }
  }
`;

export const LevelText = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;

  letter-spacing: 1px;

  color: #526895;
`;

export const TimeText = styled(Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Text/400 */

  color: #364559;
`;

export const PriceText = styled(Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Text/100 */

  color: #5b7697;
`;

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-header {
    padding: 20px 24px;
    border-bottom: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-item: center;
  }

  .ant-drawer-body {
    padding-top: 0 !important;
  }

  .ant-drawer-close {
    position: relative;
    padding: 0;
  }
`;

export const PaymentCard = styled(Card)`
  margin-top: auto;
  width: 100%;
  background: #fcfcfc;
  border: 1px solid #edeff2;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  .ant-card-cover {
    height: 160px;
  }

  .ant-card-body {
    height: 161px;
    padding: 20px;
    overflow: hidden;
  }

  .ant-card-actions {
    border-top: none;
    padding: 20px;

    li {
      margin: 0 0 !important;
    }
  }
`;

export const WelcomeStyled = styled.div`
  /* Secondary/800 */

  background: #181c21;
  /* Secondary/900 */

  border: 1px solid #0c0e10;
  box-sizing: border-box;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  border-radius: 6px;
  padding: 24px;
  width: 100%;
  min-height: 100px;
  margin-bottom: 24px;

  .header {
    // font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;

    /* Shades/White */

    color: #ffffff;
    margin-bottom: 8px;
  }

  .text {
    width: 85%;
    /* Paragraph 01/Regular */

    // font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    /* or 143% */

    /* Neutral/400 */

    color: #f0f2f4;
    opacity: 0.9;
  }
`;

export const ScholarshipStyled = styled.div`
  cursor: pointer;
  /* Shades/White */

  background: #ffffff;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  /* Alim Special Shadow */

  box-shadow: 0px 2px 2px rgba(212, 213, 217, 0.4);
  border-radius: 6px;
  padding: 24px;
  width: 100%;
  min-height: 100px;
  margin-top: 24px;

  .header {
    // font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;

    /* Text/500 */

    color: #293544;
    margin-bottom: 8px;
  }

  .text {
    // font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* or 143% */

    /* Text/400 */
    color: #364559;
  }

  .link {
    // font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Primary/500 */

    color: #024FF1;
  }
`;
