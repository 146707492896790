import { Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import VerificationCheck from '../../../../assets/images/verification-check.svg'
import { closeCreateProjectModal } from '../../../../modules/benevolence_projects/action'
import { ButtonStyled } from '../../../general_styled'
import { LastStepStyled } from '../../styled'

export default () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (
		<LastStepStyled>
			<img src={VerificationCheck} />
			<h1>{t('project_submitted')}</h1>
			<span className="text">{t('project_submitted_desc')}</span>
			<ButtonStyled
				type="primary"
				onClick={() => {
					dispatch(closeCreateProjectModal())
				}}
				style={{
					width: '100%',
				}}
			>
				{t('close')}
			</ButtonStyled>
		</LastStepStyled>
	)
}
