import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Tooltip,
  Select,
  Progress,
  Typography,
  Collapse,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RowEnd, RowSpaceBetween } from "../../components/general_styled";
import {
  getCredenceNFTByUserId,
  getNftByNftId,
  setNftDetail,
} from "../../modules/credence/action";
import {
  CollectionNameStyled,
  IconStyled,
  NameStyled,
  DescStyled,
  ColStyled,
  NFTDetailsStyled,
  DetailTitleStyled,
  DetailInfoStyled,
  LabelStyled,
  ValueStyled,
  ColFooterStyled,
  LabelFooterStyled,
  ValueFooterStyled,
  MoreStyled,
  ApprovalPending,
  Canvas,
  LabelNFTApproved,
  PropertiesCard,
} from "./styled";
import { Line } from "@ant-design/plots";
import IconTwitter from "../../assets/images/icon_twitter.svg";
import IconInstagram from "../../assets/images/icon_instagram.svg";
import IconArrowRight from "../../assets/images/arrow-right-black.svg";
import IconWeb from "../../assets/images/icon_web.svg";
import moment from "moment";
import {
  ALGO_EXPLORER_URL,
  CREDENCE_HOST,
  IS_EVM,
  MEMBERSHIP_HOST,
  POLYGON_EXPLORER_URL,
  nftAirdropAddress,
} from "../../configs";
import CardNFT from "../../components/CardNFT";
import ModalSellNFT from "../../components/ModalSellNFT";
// import ModalSellNFTComingSoon from "../../components/ModalSellNFTComingSoon"
import { NFT_STATUS } from "../../constants/constant_credence";
import useWindowDimensions from "../../utils/windowDimensions";
import ModalPayFeeNft from "../../components/ModalPayFeeNft";
import {
  openModalLoginRegister,
  setSection,
} from "../../modules/general/action";
import ModalDelistingAuctionNFT from "../../components/ModalDelistingAuctionNFT";
import ModalDelistingNFT from "../../components/ModalDelistingNFT";
import jwtDecode from "jwt-decode";
import SEO from "../../components/SEO";
import ClaimAirdrop from "../../components/ClaimAirdrop";
import { getNftSubBySubId } from "../../modules/nft_sub/action";
import { DividerCard, PriceHistory } from "../AffluenceDetailNFT/styled";
import { getPriceHistory } from "../../modules/essence/action";
import { textSubstring } from "../../utils/utils";
import { getUserDetailByAddress } from "../../modules/auth/action";

const { Panel } = Collapse;
const { Text, Title } = Typography;
const { Option } = Select;

export default function CredenceNFTSubDetail() {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const param = useSelector((state) => state.getIn(["general", "param"]));
  const nfts = useSelector((state) =>
    state.getIn(["credence", "nfts", "data"]).toJS()
  );
  const priceHistory = useSelector((state) =>
    state.getIn(["essence", "priceHistory"]).toJS()
  );

  const [nft, setNft] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isListed, setIsListed] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [menu, setMenu] = useState();
  const [creator, setCreator] = useState("");

  const getNFT = async () => {
    const _nft = await getNftSubBySubId(param);
    dispatch(setNftDetail(_nft));

    if (localStorage.getItem("USER_AUTH_TOKEN")) {
      dispatch(getCredenceNFTByUserId(null));
    }

    setNft({ ..._nft, is_minted: _nft?.subs?.status === NFT_STATUS.CREATED });
    setMetadata({
      nft_id: _nft?.subs.nft_id,
      fragmentation: _nft?.subs?.fragmentation,
      is_airdrop: _nft?.subs.airdrop?.length > 0,
      whitelist_address:
        _nft?.subs?.airdrop?.length > 0
          ? JSON.parse(_nft?.subs?.airdrop[0]?.whitelist_address)
          : [],
      ...JSON.parse(_nft?.subs?.metadata_json),
    });
    dispatch(getPriceHistory(_nft?.subs.nft_id));
    initCreatorData(_nft?.subs.creator_wallet_address);
    // setIsListed(_nft.Auctions?.length > 0 || _nft.SellEscrows?.length > 0);
  };

  const initCreatorData = async (wallet) => {
    const _creator = await getUserDetailByAddress(wallet);
    setCreator(_creator);
  };

  const formatLink = (additionalUrl, link) => {
    const isHaveWWW = link.includes("www");
    const isHaveHTTP = link.includes("http");
    const isHaveOnlyCOM = !(isHaveHTTP || isHaveHTTP) && link.includes("com");
    if (isHaveHTTP) {
      return link;
    } else if (isHaveHTTP && isHaveWWW) {
      return link;
    } else if (!isHaveHTTP && isHaveWWW) {
      return `http://${link}`;
    } else if (isHaveOnlyCOM) {
      return `http://${link}`;
    } else {
      return `${additionalUrl}${link}`;
    }
  };

  const formatAttributes = (type) => {
    const attribute = metadata?.attributes;

    if (!attribute) {
      return;
    }

    if (type === "properties") {
      const filteredProperties = attribute?.filter(
        (item) => !item.display_type
      );
      return filteredProperties.map((item, index) => (
        <Col
          key={"property" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="label">{item.trait_type}</div>
            <div className="name">{item.value}</div>
            {/* <div className="value">61% have this trait</div> */}
          </PropertiesCard>
        </Col>
      ));
    } else if (type === "levels") {
      const filteredLevels = attribute?.filter(
        (item) => item.display_type === "Levels"
      );
      return filteredLevels.map((item, index) => (
        <Col
          key={"levels" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="name">{item.trait_type}</div>
            <Progress
              className="mt-8px"
              percent={Math.round(
                (parseInt(item.value) / parseInt(item.max_value)) * 100
              )}
              trailColor="#DEE2E7"
              strokeColor="#3B4552"
            />
          </PropertiesCard>
        </Col>
      ));
    } else if (type === "stats") {
      const filteredStats = attribute?.filter(
        (item) => item.display_type === "Stats"
      );
      return filteredStats.map((item, index) => (
        <Col
          key={"stats" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="name">{item.trait_type}</div>
            <div className="value">
              {Math.round(
                (parseInt(item.value) / parseInt(item.max_value)) * 100
              )}
              % have this trait
            </div>
          </PropertiesCard>
        </Col>
      ));
    }
  };

  const checkAttribute = (type) => {
    if (metadata) {
      const attribute = metadata?.attributes;
      if (type === "properties") {
        return attribute?.find((item) => !item.display_type);
      } else if (type === "levels") {
        return attribute?.find((item) => item.display_type === "Levels");
      } else if (type === "stats") {
        return attribute?.find((item) => item.display_type === "Stats");
      }
    }
  };

  const getUserId = async () => {
    const jwt = localStorage.getItem("USER_AUTH_TOKEN");
    if (jwt) {
      const decode = await jwtDecode(jwt);
      setUserId(decode.user_id);
    }

    setIsPublic(jwt === null);
  };

  const onUserClicked = () => {
    dispatch(setSection("affluence-user", nft?.membership_user?.user_id));
  };

  const config = {
    data: priceHistory,
    padding: "auto",
    xField: "created_at",
    yField: "total_ibfx",
    xAxis: {
      // type: 'timeCat',
      // tickCount: 5,
    },
  };

  useEffect(() => {
    // getUserId();
    getNFT();
  }, [param]);

  const renderDetailInfo = () => (
    <Collapse defaultActiveKey={["description"]}>
      <Panel header="Description" key="description">
        <Row gutter={[16, 16]}>
          <Text>{metadata?.description}</Text>
        </Row>
      </Panel>
      {checkAttribute("properties") && (
        <Panel header="Properties" key="properties">
          <Row gutter={[16, 16]}>
            {metadata && formatAttributes("properties")}
          </Row>
        </Panel>
      )}
      {checkAttribute("levels") && (
        <Panel header="Levels" key="levels">
          <Row gutter={[16, 16]}>{metadata && formatAttributes("levels")}</Row>
        </Panel>
      )}
      {checkAttribute("stats") && (
        <Panel header="Stats" key="stats">
          <Row gutter={[16, 16]}>{metadata && formatAttributes("stats")}</Row>
        </Panel>
      )}
      <Panel header="Details" key="details">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <RowSpaceBetween>
              <Text className="text-size-14 text-w400 mb-0px ">
                Contract Address
              </Text>
              <Text className="text-size-16 text-w500 mb-0px ">
                {textSubstring(nft?.subs?.contract_address)}
              </Text>
            </RowSpaceBetween>
            <RowSpaceBetween className="mt-12px">
              <Text className="text-size-14 text-w400 mb-0px ">Token ID</Text>
              <Text className="text-size-16 text-w500 mb-0px ">
                {nft?.token_id || "-"}
              </Text>
            </RowSpaceBetween>
            <RowSpaceBetween className="mt-12px">
              <Text className="text-size-14 text-w400 mb-0px ">
                {t("token_standard")}
              </Text>
              <Text className="text-size-16 text-w500 mb-0px ">ARC-721</Text>
            </RowSpaceBetween>
            <RowSpaceBetween className="mt-12px">
              <Text className="text-size-14 text-w400 mb-0px ">Blockchain</Text>
              <Text className="text-size-16 text-w500 mb-0px ">Polygon</Text>
            </RowSpaceBetween>
            <RowSpaceBetween className="mt-12px">
              <Text className="text-size-14 text-w400 mb-0px ">
                {t("creator_royalty")}
              </Text>
              <Text className="text-size-16 text-w500 mb-0px ">
                {nft?.subs?.royalty_fee + "%"}
              </Text>
            </RowSpaceBetween>
            <RowSpaceBetween className="mt-12px">
              <Text className="text-size-14 text-w400 mb-0px ">
                {t("creator")}
              </Text>
              <Text className="text-size-16 text-w500 mb-0px ">
                {creator?.name}
              </Text>
            </RowSpaceBetween>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );

  return (
    <SEO
      title={`IBFNEX - ${metadata?.name || "Sub NFT Detail"}`}
      description={metadata?.description}
      image={CREDENCE_HOST + "/files/" + nft?.subs?.cover_file}
    >
      <Canvas>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
            {nft?.subs?.cover_file ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "520px",
                  objectFit: "cover",
                }}
                src={CREDENCE_HOST + "/files/" + nft?.subs?.cover_file}
              />
            ) : (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "520px",
                  objectFit: "cover",
                }}
                // src="https://upload.wikimedia.org/wikipedia/id/5/56/Harry_potter_deathly_hallows_US.jpg"
                src={
                  metadata?.image &&
                  `https://ipfs.io/ipfs/${metadata?.image?.replace(
                    "ipfs://",
                    ""
                  )}`
                }
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={14}>
            <RowSpaceBetween
              style={{
                marginBottom: "12px",
              }}
            >
              <CollectionNameStyled>
                {metadata?.collection?.name || ""}
              </CollectionNameStyled>
              <RowEnd>
                {metadata?.twitter_link && (
                  <IconStyled
                    src={IconTwitter}
                    onClick={() => {
                      if (metadata?.twitter_link) {
                        const link = formatLink(
                          "https://twitter.com/",
                          metadata?.twitter_link
                        );
                        window.open(link, "_blank");
                      }
                    }}
                    width={24}
                  />
                )}
                {metadata?.instagram_link && (
                  <IconStyled
                    src={IconInstagram}
                    onClick={() => {
                      if (metadata?.instagram_link) {
                        const link = formatLink(
                          "https://instagram.com/",
                          metadata?.instagram_link
                        );
                        window.open(link, "_blank");
                      }
                    }}
                    width={24}
                  />
                )}
                {metadata?.web_link && (
                  <IconStyled
                    src={IconWeb}
                    onClick={() => {
                      if (metadata?.web_link) {
                        const link = formatLink("", metadata?.web_link);
                        window.open(link, "_blank");
                      }
                    }}
                    width={24}
                  />
                )}
              </RowEnd>
            </RowSpaceBetween>
            <ColStyled span={24} style={{ marginBottom: "8px" }}>
              <NameStyled>
                {metadata?.name} #{nft?.token_id}
              </NameStyled>
            </ColStyled>
            <PriceHistory>
              <RowSpaceBetween>
                <Title
                  className="text-size-14 text-w400 mb-0px "
                  level={5}
                  align="left"
                >
                  {t("price_history")}
                </Title>
                <Select
                  defaultValue="all_time"
                  style={{ width: 120 }}
                  // onChange={(value) => onTimeChange(value)}
                >
                  <Option value="all_time">All Time</Option>
                  <Option value="3days">3 Days</Option>
                  <Option value="1week">1 Week</Option>
                  <Option value="1month">1 Month</Option>
                  <Option value="3month">3 Months</Option>
                  <Option value="6month">6 Months</Option>
                  <Option value="1year">1 year</Option>
                </Select>
              </RowSpaceBetween>
              <DividerCard />
              <Line {...config} style={{ height: 200 }} />
            </PriceHistory>

            {/* <RowSpaceBetween>
              <ColFooterStyled>
                <LabelFooterStyled>{t("current_owner")}</LabelFooterStyled>
                <ValueFooterStyled className="pointer" onClick={onUserClicked}>
                  <img
                    src={
                      `${MEMBERSHIP_HOST}/files/${nft?.membership_user?.photo}`
                      // user?.photo !== null
                      // 	? `${MEMBERSHIP_HOST}/files/${user?.photo}`
                      // 	: nft?.membership_user?.photo
                      // 	? `${MEMBERSHIP_HOST}/files/${nft?.membership_user?.photo}`
                      // 	: UserDummy
                    }
                  />
                  {nft?.membership_user?.name}
                </ValueFooterStyled>
              </ColFooterStyled>
              <ColFooterStyled>
                <LabelFooterStyled>
                  {IS_EVM ? "Created On" : "Mint On"}
                </LabelFooterStyled>
                <ValueFooterStyled>
                  {nft?.is_minted
                    ? moment(
                        nft?.original_updated_at ||
                          nft?.updated_at ||
                          nft?.created_at
                      ).format("LL")
                    : "-"}
                </ValueFooterStyled>
              </ColFooterStyled>
            </RowSpaceBetween> */}

            <>
              {windowDimensions.width < 835 ? (
                <div className="absolute-bottom">
                  <Row gutter={[16, 16]} className="mt-24px">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Tooltip
                        placement="bottom"
                        title={t("tooltip_nft_detail")}
                      >
                        <Button
                          block
                          style={{ height: 40, borderRadius: 4 }}
                          // type="primary"
                          disabled={!nft?.asa_id}
                          onClick={() =>
                            window.open(
                              `https://ipfs.io/ipfs/${metadata?.image?.replace(
                                "ipfs://",
                                ""
                              )}`,
                              ""
                            )
                          }
                        >
                          {t("view_file")}
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <ModalSellNFT
                        noMargin
                        metadata={metadata}
                        data={{ ...nft?.subs, ...nft }}
                      />
                      {/* {nft?.user_id === userId ? (
                          nft?.status === NFT_STATUS.CREATED ? (
                            nft?.Auctions?.length > 0 ? (
                              <ModalDelistingAuctionNFT
                                isFromDetail
                                noMargin
                                data={{
                                  auction: nft?.Auctions[0],
                                  metadata,
                                  transaction: nft?.Auctions[0].Transactions[0],
                                }}
                              />
                            ) : nft?.SellEscrows?.length > 0 ? (
                              <ModalDelistingNFT
                                isFromDetail
                                noMargin
                                sell_escrow_id={
                                  nft?.SellEscrows[0].sell_escrow_id
                                }
                                name={metadata?.name}
                                collection={metadata?.collection?.name}
                                fragmentation={metadata?.fragmentation}
                                listed_on={nft?.created_at}
                                price={nft?.SellEscrows[0].price}
                                ipfsHash={metadata?.image?.replace(
                                  "ipfs://",
                                  ""
                                )}
                                is_pdf={metadata?.is_pdf || false}
                              />
                            ) : (
                              <ModalSellNFT
                                noMargin
                                metadata={metadata}
                                data={nft}
                              />
                            )
                          ) : (
                            <>
                              {metadata && (
                                <ModalPayFeeNft
                                  isFromDetail={true}
                                  toVault={() =>
                                    dispatch(setSection("credence"))
                                  }
                                  disabled={nft.status !== NFT_STATUS.APPROVED}
                                  record={nft}
                                  metadata={metadata}
                                />
                              )}
                            </>
                          )
                        ) : (
                          <></>
                        )} */}
                    </Col>
                  </Row>
                </div>
              ) : (
                <Row gutter={[16, 16]} className={"mt-24px"}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Tooltip placement="bottom" title={t("tooltip_nft_detail")}>
                      <Button
                        block
                        style={{ height: 40, borderRadius: 4 }}
                        // type="primary"
                        onClick={() =>
                          window.open(
                            `https://ipfs.io/ipfs/${metadata?.image?.replace(
                              "ipfs://",
                              ""
                            )}`,
                            ""
                          )
                        }
                      >
                        {t("view_file")}
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <ModalSellNFT
                      noMargin
                      metadata={metadata}
                      data={{ ...nft?.subs, ...nft }}
                    />
                  </Col>
                </Row>
              )}
            </>
          </Col>
        </Row>
        {IS_EVM && metadata?.is_airdrop && (
          <NFTDetailsStyled className="mt-36px">
            <DetailTitleStyled>
              <RowSpaceBetween>
                <span>NFT is Airdrop</span>
                <RowEnd className="pointer">
                  <ClaimAirdrop
                    data={{
                      contract_address: nft?.contract_address,
                      whitelist_address: metadata?.whitelist_address,
                    }}
                  />
                </RowEnd>
              </RowSpaceBetween>
            </DetailTitleStyled>
            <DetailInfoStyled>
              <RowSpaceBetween className="mt-16px">
                <LabelStyled>Whitelist Address</LabelStyled>
                <div>
                  {metadata?.whitelist_address?.map((i) => {
                    return <p style={{ margin: 0 }}>{i.address}</p>;
                  })}
                </div>
              </RowSpaceBetween>
            </DetailInfoStyled>
          </NFTDetailsStyled>
        )}
        <div className="mt-24px">{renderDetailInfo()}</div>
        {/* {nfts.length > 0 && <MoreStyled>{t("more_vault")}</MoreStyled>}
        <Row>
          {nfts?.map((nft) => {
            if (nft.status === "FAILED" || nft.nft_id === param) {
              return <></>;
            }

            const metadata = JSON.parse(nft.metadata_json);
            return (
              <CardNFT
                nftId={nft.nft_id}
                name={metadata.name}
                collectionName={metadata?.collection.name}
                ipfsHash={metadata?.image?.replace("ipfs://", "")}
                is_pdf={metadata?.is_pdf || false}
                data={nft}
              />
            );
          })}
        </Row> */}
      </Canvas>
    </SEO>
  );
}
