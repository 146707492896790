import { Col, Modal, notification, Progress, Spin } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { closeRedeemVoucherModal } from "../../modules/redeem_voucher_modal/action"
import noWalletIllustration from "../../assets/images/no-wallet-illustration.png"
import voucherIllustrations from "../../assets/images/voucher_illustration.svg"
import closeIcon from "../../assets/images/close.svg"
import {
  ClaimedVoucher,
  ContentStyled,
  TextStyled,
  TitleStyled,
} from "./styled"
import { ButtonStyled, ColCenter, InputStyled } from "../general_styled"
import { useHistory } from "react-router"
import { COMMON_CONSTANT } from "../../constants/constant_common"
import { useEffect, useState } from "react"
import { extractErrorMessage } from "../../utils/extractErrorMessage"
import { redeemVoucher } from "../../modules/wallet/action"
import { fromIBFx } from "../../utils/utils"
import { setSection } from "../../modules/general/action"

export default function ModalRedeemVoucher() {
  const isModalVisible = useSelector((state) =>
    state.getIn(["redeemVoucherModal", "isModalVisible"])
  )
  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  )
  const encryptedMnemonic = localStorage.getItem(
    COMMON_CONSTANT.ENCRYPTED_MNEMONIC
  )

  const dispatch = useDispatch()
  const history = useHistory()

  const [isProcessing, setProcessing] = useState(false)
  const [isSuccess, setSuccess] = useState(true)
  const [voucherCode, setVoucherCode] = useState("")
  const [claimedVoucher, setClaimedVoucher] = useState("")

  const handleCancel = () => dispatch(closeRedeemVoucherModal())

  useEffect(() => {
    setProcessing(false)
    setSuccess(false)
    setVoucherCode("")
  }, [isModalVisible])

  const onSubmit = async () => {
    setProcessing(true)
    try {
      const body = {
        algo_address: walletUser.algo_address,
        voucher_code: voucherCode,
      }

      const data = await redeemVoucher(body)
      setClaimedVoucher(fromIBFx(data.amount))
      setSuccess(true)
      dispatch(setSection("wallet-dashboard"))
    } catch (e) {
      console.log(e)
      notification.error({
        message: "Redeem Voucher Failed",
        description: extractErrorMessage(e),
      })
    }

    setProcessing(false)
  }

  const renderActivateWallet = () => {
    return (
      <ColCenter span={24}>
        <img src={noWalletIllustration} width={287} />
        <TitleStyled style={{ marginTop: "20px" }}>
          Activation Required!
        </TitleStyled>
        <TextStyled style={{ marginBottom: "20px" }}>
          You need to activate your IBX Type 2 Tokens in order to redeem your
          voucher
        </TextStyled>
        <ButtonStyled
          style={{
            width: "100%",
          }}
          onClick={() => {
            handleCancel()
            history.push("/wallet-dashboard")
          }}
        >
          Go to Wallet
        </ButtonStyled>
      </ColCenter>
    )
  }

  const resolveContent = () => {
    if (Object.keys(walletUser) <= 0 || !encryptedMnemonic) {
      return renderActivateWallet()
    }

    if (isSuccess) {
      return (
        <ColCenter span={24}>
          <Progress type="circle" percent={100} />
          <TitleStyled
            style={{
              marginTop: "24px",
            }}
          >
            Success!
          </TitleStyled>
          <TextStyled
            style={{
              marginBottom: "24px",
            }}
          >
            The tokens will be added to your wallet in a few minutes
          </TextStyled>
          <ClaimedVoucher>
            <span className="label">Tokens Redeemed</span>
            <span className="value">{claimedVoucher} IBFX - 2</span>
          </ClaimedVoucher>
          <ButtonStyled
            style={{
              width: "100%",
              marginTop: "24px",
            }}
            onClick={() => {
              handleCancel()
            }}
          >
            Dismiss
          </ButtonStyled>
        </ColCenter>
      )
    }

    return (
      <ColCenter span={24}>
        <TitleStyled>Reedem Voucher</TitleStyled>
        <TextStyled
          style={{
            marginBottom: "24px",
          }}
        >
          Enter your voucher code to redeem
        </TextStyled>
        <img src={voucherIllustrations} width={287} />
        <InputStyled
          value={voucherCode}
          onChange={(e) => setVoucherCode(e.target.value)}
          placeholder="Voucher Code"
          style={{
            marginTop: "24px",
            marginBottom: "24px",
          }}
        />
        <ButtonStyled
          style={{
            width: "100%",
          }}
          onClick={() => onSubmit()}
          disabled={isProcessing}
        >
          {!isProcessing ? "Redeem" : <Spin />}
        </ButtonStyled>
      </ColCenter>
    )
  }

  return (
    <Modal
      centered
      centered
      visible={isModalVisible}
      onCancel={handleCancel}
      closeIcon={
        !isSuccess ? (
          <img
            style={{ marginRight: 10 }}
            src={closeIcon}
            onClick={handleCancel}
          />
        ) : (
          <></>
        )
      }
      footer={null}
    >
      <ContentStyled>{resolveContent()}</ContentStyled>
    </Modal>
  )
}
