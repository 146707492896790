import styled from "@emotion/styled";
import { Col, Row, Typography } from "antd";
import Icon from "@ant-design/icons";

const { Text } = Typography;

export const Canvas = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 170px;

  .absolute-bottom {
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background: #ffffff;
    /* Sticky Shadow Up */

    box-shadow: 0px -4px 6px rgba(197, 205, 234, 0.25);
    border-radius: 0px;
    padding: 20px;
    padding-top: 0px;
  }
`;

export const MediaSocialSection = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
`;

export const CollectionNameStyled = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Text/300 */

  color: #42556e;
`;

export const IconStyled = styled.img`
  cursor: pointer;
  margin-right: 8px;
`;

export const NameStyled = styled(Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;

  /* Text/500 */

  color: #293544;
`;

export const DescStyled = styled(Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 143% */

  /* Text/400 */

  color: #364559;
`;

export const ColStyled = styled(Col)`
  padding: 0 !important;
`;

export const NFTDetailsStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #f7f8fa;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 4px;

  // height: 209px;
  padding: 16px 16px;
  margin-bottom: 24px;
`;

export const DetailTitleStyled = styled(Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  /* Text/500 */

  color: #293544;
  border-bottom: 1px dashed #d6dae1;

  padding-bottom: 16px;
  margin-bottom: 16px;
`;
export const DetailInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // min-height: calc(100% - 50px);
`;

export const LabelStyled = styled(Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Text/300 */

  color: #42556e;
`;

export const ValueStyled = styled(Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* Text/400 */

  color: #364559;
  text-overflow: ellipsis;

  a {
    text-decoration: underline;
  }
`;

export const ColFooterStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-child {
    align-items: flex-end;
  }
`;

export const LabelFooterStyled = styled(Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height, or 117% */

  text-transform: capitalize;

  /* Text/300 */

  color: #42556e;
  margin-bottom: 8px;
`;

export const ValueFooterStyled = styled(Text)`
  display: flex;
  justify-content: center;
  align-item: center;

  // font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* Text/400 */

  color: #364559;

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;

    margin-right: 8px;
  }

  @media screen and (max-width: 834px) {
    font-size: 16px;
  }
`;

export const MoreStyled = styled.h1`
  // font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  /* Gray/gray-8 */

  color: #0b0e14;
  margin-top: 36px;
`;

export const ApprovalPending = styled.div`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  /* Neutral/100 */

  background: #f7f8fa;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  // font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Text/200 */

  color: #4f6683;
`;

export const LabelNFTApproved = styled.div`
  background: rgba(222, 246, 235, 0.6);
  border-radius: 4px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Paragraph 01/Semibold */

  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Success/700 */

  color: #1b6745;
`;

export const PropertiesCard = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 20px;

  background: rgba(222, 226, 231, 0.2);
  /* Secondary/500 */

  border: 1px solid #3b4552;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    /* Caption/All Caps */

    // font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    /* identical to box height, or 117% */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Text/500 */

    color: #293544;
    margin-bottom: 8px;
  }

  .name {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* Text/400 */

    color: #364559;
  }

  .value {
    margin-top: 8px;
    /* Paragraph 01/Regular */

    // font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Text/300 */

    color: #42556e;
  }
`;
