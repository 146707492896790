import React, { useState } from "react";
import { Modal, Typography, Button, Drawer, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  ColCenter,
  RowSpaceBetween,
  ButtonCoinbaseStyled,
  CheckboxStyled,
  InputStyled,
} from "../general_styled";
import {
  ContentStyled,
  ContentCardStyled,
  ContentPaymentStyled,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
// import checkImg from "../../assets/images/sell-nft-check.svg";
// import shopImg from "../../assets/images/sell-nft-shop.svg";
// import shopImgActive from "../../assets/images/sell-nft-shop-active.svg";
// import dollarImg from "../../assets/images/sell-nft-dollar.svg";
// import dollarImgActive from "../../assets/images/sell-nft-dollar-active.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import { openModalLoginRegister } from "../../modules/general/action";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import USDT from "../../assets/images/donate/usdt.svg";
import IBFN from "../../assets/images/donate/ibfn.svg";
import IBFX from "../../assets/images/donate/ibfx.svg";
import VISA from "../../assets/images/donate/visa.svg";
import MC from "../../assets/images/donate/mc.svg";
import MC2 from "../../assets/images/donate/google.svg";
import CR1 from "../../assets/images/donate/cr1.svg";
import CR2 from "../../assets/images/donate/cr2.svg";
import CR3 from "../../assets/images/donate/cr3.svg";
import CR4 from "../../assets/images/donate/cr4.svg";
import CR5 from "../../assets/images/donate/cr5.svg";
import CR6 from "../../assets/images/donate/cr6.svg";
import CR7 from "../../assets/images/donate/cr7.svg";
import CR8 from "../../assets/images/donate/cr8.svg";
import Pera from "../../assets/images/donate/pera.svg";
import { donateToProjectlWitStripe } from "../../modules/benevolence_projects/action";

const { Title, Text } = Typography;

const cryptoImage = [IBFN, USDT];
const stripeImage = [VISA, MC, MC2];
const coinbaseImage = [CR5, CR6, CR4, CR3, CR2, CR1, CR7, CR8];
const peraWalletImage = [Pera];

export default function ModalDonationSelection(props) {
  const { project, mode, setMode } = props;
  const history = useHistory();
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBehalf, setIsBehalf] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [behalfName, setBehalfName] = useState();
  const [behalfEmail, setBehalfEmail] = useState();
  const userData = useSelector((state) =>
    state.getIn(["auth", "userData"]).toJS()
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setMode();
    setBehalfName();
    setBehalfEmail();
    setIsBehalf(false);
    setIsGuest(false);
  };

  const handleDonationUsingStripe = async () => {
    try {
      setIsLoading(true);
      const body = {
        user_id: userData?.user_id || null,
        project_id: project?.project_id,
        success_url: window.location.href,
        is_guest: isGuest,
      };

      if (isBehalf) {
        body.behalf_name = behalfName;
        body.behalf_email = behalfEmail;
      }

      const resp = await donateToProjectlWitStripe(body);
      window.open(resp.url, "_self");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resolveButton = () => {
    return mode === 3 ? (
      <div className="my-24px" style={{ width: "100%" }}>
        {project?.coinbase_checkout_id &&
          (userData?.user_id ? (
            <ButtonCoinbaseStyled
              checkoutId={project?.coinbase_checkout_id}
              customMetadata={JSON.stringify({
                ...userData,
                behalf_name: behalfName || null,
                behalf_email: behalfEmail || null,
                is_guest: isGuest,
              })}
            >
              Proceed With Donation
            </ButtonCoinbaseStyled>
          ) : (
            <ButtonCoinbaseStyled
              checkoutId={project?.coinbase_checkout_id}
              customMetadata={JSON.stringify({
                behalf_name: behalfName || null,
                behalf_email: behalfEmail || null,
                is_guest: isGuest,
              })}
            >
              Proceed With Donation
            </ButtonCoinbaseStyled>
          ))}
      </div>
    ) : (
      <Button
        block
        loading={isLoading}
        type="primary"
        disabled={!mode}
        className="my-24px"
        size="large"
        onClick={async () => {
          if ((mode === 1 || mode === 4) && userData.user_id) {
            props.openDonationModal();
          } else if (mode === 2) {
            await handleDonationUsingStripe();
          } else {
            dispatch(openModalLoginRegister());
          }
          setIsModalVisible(false);
        }}
      >
        Proceed With Donation
      </Button>
    );
  };

  return (
    <>
      <Button
        block
        style={{ height: 40, borderRadius: 100, background: '#000', borderColor: '#000' }}
        type="primary"
        // className={!props.noMargin && "mt-24px"}
        onClick={showModal}
      >
        DONATE
      </Button>
      {windowDimensions.width > 925 ? (
        <Modal
          centered
          className="modal-donation"
          visible={isModalVisible}
          onCancel={handleCancel}
          maskStyle={{ backgroundColor: "#000" }}
          footer={null}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
          width={"750px"}
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Donate
              </Title>
              <Text className="gray-7" align="center">
                You're contributing to
              </Text>
              <Text className="gray-7" align="center">
                <b>"{project?.name}"</b> campaign
              </Text>
              <Row gutter={20} className="w-100 mt-24px">
                <ContentCardStyled>
                  <p className="title">SELECT PAYMENT METHOD</p>
                  <RowSpaceBetween>
                    <ContentPaymentStyled
                      onClick={() => setMode(3)}
                      className={`${mode === 3 && "selected"}`}
                    >
                      <p className="payment-title">Donate using Crypto</p>
                      {coinbaseImage.map((i) => (
                        <img
                          style={{ height: "38px" }}
                          src={i}
                          className="pr-6px"
                        />
                      ))}
                    </ContentPaymentStyled>
                  </RowSpaceBetween>
                  <RowSpaceBetween className="mt-12px">
                    <ContentPaymentStyled
                      onClick={() => setMode(2)}
                      className={`${mode === 2 && "selected"}`}
                    >
                      <p className="payment-title">
                        Donate using{" "}
                        <span style={{ color: "blue" }}>Stripe</span>
                      </p>
                      {stripeImage.map((i) => (
                        <img
                          style={{ height: "32px" }}
                          src={i}
                          className="pr-6px"
                        />
                      ))}
                    </ContentPaymentStyled>
                    <ContentPaymentStyled
                      onClick={() => userData?.user_id && setMode(4)}
                      className={`ml-12px ${mode === 4 && "selected"}`}
                    >
                      <p className="payment-title">
                        Donate using{" "}
                        <span style={{ color: "blue" }}>Perawallet</span>
                      </p>
                      {peraWalletImage.map((i) => (
                        <img
                          style={
                            !userData?.user_id
                              ? { filter: "grayscale(1)", height: "32px" }
                              : { height: "32px" }
                          }
                          src={i}
                          className="pr-12px"
                        />
                      ))}
                    </ContentPaymentStyled>
                    <ContentPaymentStyled
                      className={`ml-12px ${mode === 1 && "selected"}`}
                      onClick={() => userData?.user_id && setMode(1)}
                    >
                      <p className="payment-title">
                        Donate using{" "}
                        {project?.chain === "POLYGON" ? "IBFN / USDT" : "IBFX"}
                      </p>
                      {project?.chain === "POLYGON" ? (
                        cryptoImage.map((i) => (
                          <img
                            style={
                              !userData?.user_id
                                ? { filter: "grayscale(1)", height: "32px" }
                                : { height: "32px" }
                            }
                            src={i}
                            className="pr-12px"
                          />
                        ))
                      ) : (
                        <img
                          style={
                            !userData?.user_id
                              ? { filter: "grayscale(1)", height: "32px" }
                              : { height: "32px" }
                          }
                          src={IBFX}
                          className="pr-12px"
                        />
                      )}
                    </ContentPaymentStyled>
                  </RowSpaceBetween>
                  {!userData?.user_id && (
                    <Row className="mt-12px">
                      <Col
                        md={1}
                        lg={1}
                        xl={1}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <InfoCircleOutlined style={{ color: "red" }} />
                      </Col>
                      <Col
                        md={23}
                        lg={23}
                        xl={23}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          className="ml-4px text-size-12"
                          style={{ color: "red" }}
                        >
                          For guest donations on IBFNEX, only Crypto & Stripe
                          are currently accepted. Other payment methods for
                          guest accounts will be available soon. For immediate
                          access to all payment options, please{" "}
                          <span
                            style={{ fontWeight: "bold", cursor: "pointer" }}
                            onClick={() => history.push("/login")}
                          >
                            register/login
                          </span>
                        </Text>
                      </Col>
                    </Row>
                  )}
                </ContentCardStyled>
              </Row>

              {/* Behalf section */}
              <ContentCardStyled className={`mt-12px ${isGuest && "disabled"}`}>
                <CheckboxStyled
                  checked={isBehalf}
                  disabled={isGuest}
                  onChange={() => setIsBehalf(!isBehalf)}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    Gift or Donate on Someone's Behalf
                  </Text>
                </CheckboxStyled>
                {isBehalf && (
                  <>
                    <InputStyled
                      value={behalfName}
                      onChange={(e) => setBehalfName(e.target.value)}
                      placeholder="Enter Name"
                      size="large"
                      className="mt-12px"
                      style={{ borderRadius: 3 }}
                    />
                    <InputStyled
                      value={behalfEmail}
                      onChange={(e) => setBehalfEmail(e.target.value)}
                      placeholder="Enter Email (Optional)"
                      size="large"
                      className="my-6px"
                      style={{ borderRadius: 3 }}
                    />
                  </>
                )}
              </ContentCardStyled>

              {/* Anonymous section */}
              <ContentCardStyled
                className={`mt-12px ${isBehalf && "disabled"}`}
              >
                <CheckboxStyled
                  disabled={isBehalf}
                  checked={isGuest}
                  onChange={() => setIsGuest(!isGuest)}
                >
                  <Text style={{ fontWeight: "bold" }}>Donate Anonymously</Text>
                </CheckboxStyled>
              </ContentCardStyled>
              {resolveButton()}
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          className="force-scroll"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
          footer={resolveButton()}
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Donate
              </Title>
              <Text className="gray-7" align="center">
                You're contributing to
              </Text>
              <Text className="gray-7" align="center">
                <b>"{project?.name}"</b> campaign
              </Text>
              <Row gutter={20} className="w-100 mt-24px">
                <ContentCardStyled>
                  <p className="title">SELECT PAYMENT METHOD</p>
                  <Col className="p-0px" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <ContentPaymentStyled
                      onClick={() => setMode(3)}
                      className={`mt-12px ${mode === 3 && "selected"}`}
                    >
                      <p className="payment-title-m">Donate using Crypto</p>
                      {coinbaseImage.map((i) => (
                        <img
                          style={{ height: "40px" }}
                          src={i}
                          className="pr-6px pb-6px"
                        />
                      ))}
                    </ContentPaymentStyled>
                  </Col>
                  <Col className="p-0px" xs={18} sm={18} md={18} lg={12} xl={12} xxl={12}>
                    <ContentPaymentStyled
                      className={`mt-12px ${mode === 1 && "selected"}`}
                      onClick={() => userData?.user_id && setMode(1)}
                    >
                      <p className="payment-title-m">
                        Donate using{" "}
                        {project?.chain === "POLYGON" ? "IBFN / USDT" : "IBFX"}
                      </p>
                      {project?.chain === "POLYGON" ? (
                        cryptoImage.map((i) => (
                          <img
                            style={
                              !userData?.user_id
                                ? { filter: "grayscale(1)", height: "32px" }
                                : { height: "32px" }
                            }
                            src={i}
                            className="pr-12px"
                          />
                        ))
                      ) : (
                        <img
                          style={
                            !userData?.user_id
                              ? { filter: "grayscale(1)", height: "32px" }
                              : { height: "32px" }
                          }
                          src={IBFX}
                          className="pr-12px"
                        />
                      )}
                    </ContentPaymentStyled>
                  </Col>
                  <Col className="p-0px" xs={18} sm={18} md={18} lg={12} xl={12} xxl={12}>
                    <ContentPaymentStyled
                      onClick={() => userData?.user_id && setMode(4)}
                      className={`mt-12px ${mode === 4 && "selected"}`}
                    >
                      <p className="payment-title-m">
                        Donate using{" "}
                        <span style={{ color: "blue" }}>Perawallet</span>
                      </p>
                      {peraWalletImage.map((i) => (
                        <img
                          style={
                            !userData?.user_id
                              ? { filter: "grayscale(1)", height: "32px" }
                              : { height: "32px" }
                          }
                          src={i}
                          className="pr-12px"
                        />
                      ))}
                    </ContentPaymentStyled>
                  </Col>
                  <Col className="p-0px" xs={18} sm={18} md={18} lg={12} xl={12} xxl={12}
                  >
                    <ContentPaymentStyled
                      onClick={() => setMode(2)}
                      className={`mt-12px ${mode === 2 && "selected"}`}
                    >
                      <p className="payment-title-m">
                        Donate using{" "}
                        <span style={{ color: "blue" }}>Stripe</span>
                      </p>
                      {stripeImage.map((i) => (
                        <img
                          style={{ height: "32px" }}
                          src={i}
                          className="pr-6px"
                        />
                      ))}
                    </ContentPaymentStyled>
                  </Col>
                  {!userData?.user_id && (
                    <Row className="mt-12px">
                      <Col
                        md={23}
                        lg={23}
                        xl={23}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          className="ml-4px text-size-12"
                          style={{ color: "red" }}
                        >
                          For guest donations on IBFNEX, only Crypto & Stripe
                          are currently accepted. Other payment methods for
                          guest accounts will be available soon. For immediate
                          access to all payment options, please{" "}
                          <span
                            style={{ fontWeight: "bold", cursor: "pointer" }}
                            onClick={() => history.push("/login")}
                          >
                            register/login
                          </span>
                        </Text>
                      </Col>
                    </Row>
                  )}
                </ContentCardStyled>
              </Row>

              {/* Behalf section */}
              <ContentCardStyled className={`mt-12px ${isGuest && "disabled"}`}>
                <CheckboxStyled
                  checked={isBehalf}
                  disabled={isGuest}
                  onChange={() => setIsBehalf(!isBehalf)}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    Gift or Donate on Someone's Behalf
                  </Text>
                </CheckboxStyled>
                {isBehalf && (
                  <>
                    <InputStyled
                      value={behalfName}
                      onChange={(e) => setBehalfName(e.target.value)}
                      placeholder="Enter Name"
                      size="large"
                      className="mt-12px"
                      style={{ borderRadius: 3 }}
                    />
                    <InputStyled
                      value={behalfEmail}
                      onChange={(e) => setBehalfEmail(e.target.value)}
                      placeholder="Enter Email (Optional)"
                      size="large"
                      className="my-6px"
                      style={{ borderRadius: 3 }}
                    />
                  </>
                )}
              </ContentCardStyled>

              {/* Anonymous section */}
              <ContentCardStyled
                className={`mt-12px mb-12px ${isBehalf && "disabled"}`}
              >
                <CheckboxStyled
                  disabled={isBehalf}
                  checked={isGuest}
                  onChange={() => setIsGuest(!isGuest)}
                >
                  <Text style={{ fontWeight: "bold" }}>Donate Anonymously</Text>
                </CheckboxStyled>
              </ContentCardStyled>
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
