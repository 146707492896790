import { fromJS } from 'immutable'
import { USER_STATUS } from '../../constants/constant_wallet'
import {
	GET_TRANSACTION_HISTORY,
	GET_TRANSACTION_HISTORY_SUCCESS,
	GET_TRANSACTION_HISTORY_FAILED,
	GET_ASSETS,
	GET_ASSETS_SUCCESS,
	GET_ASSETS_FAILED,
	SET_WALLET_USER,
	SET_IBFX_AMOUNT_PER_USD,
} from '../constants'

const initialState = fromJS({
	isLoading: fromJS(false),
	hasError: fromJS(false),
	errorMessage: fromJS(''),
	transactionsHistory: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	allAssets: fromJS([]),
	user: fromJS({
		status: USER_STATUS.INACTIVE,
	}),
	ibfxPerUSD: 10,
	algoPerUSD: 0,
})

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GET_TRANSACTION_HISTORY:
			return state.merge({
				isLoading: true,
				hasError: false,
			})
		case GET_TRANSACTION_HISTORY_SUCCESS:
			return state.merge({
				isLoading: false,
				transactionsHistory: fromJS({
					data: action.payload.data,
					hasNext: action.payload.hasNext,
					currentPage: action.payload.page,
					total: action.payload.total,
				}),
			})
		case GET_TRANSACTION_HISTORY_FAILED:
			return state.merge({
				isLoading: false,
				hasError: true,
				errorMessage: action.payload.errorMessage,
			})

		case GET_ASSETS:
			return state.merge({
				isLoading: true,
				hasError: false,
			})
		case GET_ASSETS_SUCCESS:
			return state.merge({
				isLoading: false,
				allAssets: fromJS(action.payload.data),
			})
		case GET_ASSETS_FAILED:
			return state.merge({
				isLoading: false,
				hasError: true,
				errorMessage: action.payload.errorMessage,
			})
		case SET_WALLET_USER:
			return state.merge({
				user: fromJS(action.payload.data),
			})
		case SET_IBFX_AMOUNT_PER_USD:
			return state.merge({
				ibfxPerUSD: Number(action.payload.value),
				algoPerUSD: Number(action.payload.algoAmountData.value),
			})
		default:
			return state
	}
}
