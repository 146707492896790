import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Image,
  Row,
  Col,
  Progress,
  Spin,
  Alert,
} from "antd";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  ColCenter,
  RowEnd,
  RowSpaceBetween,
  RowCenter,
  ButtonStyled,
} from "../general_styled";

import { ContentStyled, DetailStyled, DividerCard } from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import IBFxLogo from "../../assets/images/ibfx-icon.svg";
import { setSection } from "../../modules/general/action";
import { TRANSACTION_STATUS } from "../../constants/constant_confluence";
import moment from "moment";
import { decryptMnemonic, fromIBFx } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import ReactCodeInput from "react-code-input";
import { acceptBidAuction } from "../../modules/affluence/action";

const { Title, Text } = Typography;

export default function ModalPendingOfferNFT(props) {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState({});
  const [isMode, setIsMode] = useState("confirmation");
  const [pinCode, setPinCode] = useState(null);
  const [isPinError, setPinError] = useState(false);
  const [processingPercent, setProcessingPercent] = useState(10);
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const checkPin = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = await decryptMnemonic(getMnemonic, pinCode);
      setPinError(false);
      await onSubmit(_decryptMnemonic);
    } catch (e) {
      console.log(e);
      setPinError(true);
    }
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsMode("confirmation");
    setIsModalVisible(false);
    setPinCode(null);
    setPinError(false);
    setProcessing(false);
    setIsSuccess(true);
    setProcessingPercent(10);
  };

  const onSubmit = async (mnemonic) => {
    setIsLoading(true);
    setIsMode("processing");
    setProcessing(true);

    try {
      await acceptBidAuction(mnemonic, props.data.auction_id);
      setProcessingPercent(100);
      setProcessing(false);
      setIsSuccess(true);
    } catch (error) {
      setProcessingPercent(0);
      setProcessing(false);
      setIsSuccess(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const _metadata = JSON.parse(props?.data?.auction?.NFT?.metadata_json);
    setMetadata(_metadata);
  }, [props]);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  return (
    <>
      {props.data?.status === TRANSACTION_STATUS.OUTBID ? (
        <p>{props.isView ? "View" : "Accept Offer"}</p>
      ) : (
        <a href="#" onClick={showModal}>
          {props.isView ? "View" : "Accept Offer"}
        </a>
      )}
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="450px"
          className="modal-delisting-nft"
          footer={null}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {isMode === "confirmation" ? (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  Pending Offer
                </Title>
                <Text className="gray-7" align="center">
                  {metadata?.name +
                    (metadata?.collection?.name === null
                      ? ""
                      : " - " + metadata?.collection?.name)}
                </Text>
                <img
                  className="img-nft"
                  alt={metadata?.name}
                  src={`https://ipfs.io/ipfs/${metadata?.image?.replace(
                    "ipfs://",
                    ""
                  )}`}
                />
                <DetailStyled>
                  <RowSpaceBetween className="px-20px pt-20px">
                    <Text className="gray-7">Received On</Text>
                    <Text className="gray-8 text-size-16 text-w600">
                      {moment(props?.data?.created_at).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </Text>
                  </RowSpaceBetween>
                  <DividerCard />
                  <RowSpaceBetween className="px-20px">
                    <Text className="gray-7 text-w600">Offer Amount</Text>
                    <RowEnd>
                      <Image preview={false} src={IBFxLogo} width={"18px"} />
                      <Text className="ml-4px gray-8 text-size-16 text-w600">
                        {fromIBFx(props?.data?.total_ibfx)}
                      </Text>
                    </RowEnd>
                  </RowSpaceBetween>
                  <DividerCard />
                </DetailStyled>
                {!props.isView && (
                  <Button
                    type="primary"
                    style={{
                      width: "calc(100% - 40px)",
                      margin: "0 20px",
                      height: 40,
                      marginTop: 12,
                      borderRadius: 4,
                    }}
                    onClick={() => setIsMode("pin")}
                  >
                    {isLoading ? antIcon : "Accept Offer"}
                  </Button>
                )}
                <Button
                  type="primary"
                  disabled={props.data?.status === TRANSACTION_STATUS.OUTBID}
                  ghost={!props.isView}
                  style={{
                    width: "calc(100% - 40px)",
                    margin: "0 20px",
                    height: 40,
                    marginTop: 12,
                    borderRadius: 4,
                  }}
                  onClick={() =>
                    dispatch(
                      setSection(
                        "affluence-auction-detail-nft",
                        props?.data?.auction?.NFT?.nft_id
                      )
                    )
                  }
                >
                  {"View NFT"}
                </Button>
              </ColCenter>
            ) : isMode === "pin" ? (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  Enter pin
                </Title>
                <Text className="gray-7" align="center">
                  Please enter your pin code to confirm the transaction
                </Text>
                <Row className="mt-24px">
                  <Col span={24}>
                    <RowCenter>
                      <ReactCodeInput
                        type="password"
                        onChange={(e) => setPinCode(e)}
                        fields={4}
                        {...codeProps}
                      />
                    </RowCenter>
                  </Col>
                  {isPinError && (
                    <Col className="mt-10px" span={24}>
                      <Alert
                        message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                        type="error"
                        showIcon
                      />
                    </Col>
                  )}
                </Row>
                <ButtonStyled
                  style={{
                    width: "40%",
                    marginTop: 20,
                    marginBottom: 70,
                  }}
                  onClick={() => checkPin()}
                  disabled={isProcessing}
                >
                  {!isProcessing ? "Confirm Pin" : <Spin />}
                </ButtonStyled>
              </ColCenter>
            ) : (
              <ColCenter>
                <Progress
                  type="circle"
                  percent={processingPercent}
                  status={!isSuccess && "exception"}
                />
                <Title className="text-w700 gray-7 mb-8px mt-24px" level={3}>
                  {isProcessing
                    ? "Processing"
                    : isSuccess
                    ? "Success!"
                    : "Failed!"}
                </Title>
                <Text
                  style={{ width: "60%" }}
                  className="gray-7"
                  align="center"
                >
                  {isProcessing
                    ? "Please wait while accept bid"
                    : isSuccess
                    ? "The bid has been successfully accepted!"
                    : "An error occured, please try again later"}
                </Text>
                {!isProcessing && (
                  <ButtonStyled
                    className="mt-24px"
                    type="primary"
                    danger={!isSuccess}
                    style={{
                      width: "350px",
                      height: "48px",
                      borderRadius: "4px",
                    }}
                    onClick={() => handleCancel()}
                  >
                    Dismiss
                  </ButtonStyled>
                )}
              </ColCenter>
            )}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Delist NFT
              </Title>
              <Text className="gray-7" align="center">
                Your listed NFT will be moved back to Credence
              </Text>
              <img
                className="img-nft"
                alt={props.name}
                // src={`https://ipfs.io/ipfs/${props.ipfsHash}`}
                src={props.image}
              />
              <DetailStyled>
                <RowSpaceBetween className="px-20px pt-20px">
                  <Text className="gray-7">Fragmentation</Text>
                  <Text className="gray-8 text-size-16 text-w600">
                    {props.fragmentation}
                  </Text>
                </RowSpaceBetween>
                <DividerCard />
                <RowSpaceBetween className="px-20px">
                  <Text className="gray-7">Listed On</Text>
                  <Text className="gray-8 text-size-16 text-w600">
                    {props.listed_on}
                  </Text>
                </RowSpaceBetween>
                <DividerCard />
                <RowSpaceBetween className="px-20px">
                  <Text className="gray-7 text-w600">Value</Text>
                  <RowEnd>
                    <Image preview={false} src={IBFxLogo} width={"18px"} />
                    <Text className="ml-4px gray-8 text-size-16 text-w600">
                      {props.price}
                    </Text>
                  </RowEnd>
                </RowSpaceBetween>
                <DividerCard />
              </DetailStyled>

              <Button
                type="primary"
                danger
                style={{
                  width: "calc(100% - 40px)",
                  margin: "0 20px",
                  marginTop: 24,
                }}
                size="large"
                onClick={() => onSubmit()}
              >
                {isLoading ? antIcon : "Delist NFT"}
              </Button>
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
