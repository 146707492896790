import styled from "@emotion/styled";

export const ContentChart = styled.div`
  padding-top: 50px;
`;

export const ImageTable = styled.img`
  padding: 6px;
  width: 83px;
  height: 83px;
  object-fit: cover;
  border-radius: 8px;
`;
