import React, { useEffect, useState } from "react";
import {
  Steps,
  Typography,
  message,
  Upload,
  Switch,
  Row,
  Col,
  Button,
  Spin,
  AutoComplete,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { AddButtonStyled } from "../../components/ModalCreateNFT/styled";
// import closeIcon from "../../assets/images/close.svg";
import {
  ColCenter,
  InputStyled,
  RowSpaceBetween,
  RowStart,
  ColStart,
  TextAreaStyled,
  InputNumberStyled,
  RowCenter,
  AutoCompleteStyled,
} from "../../components/general_styled";
import { CREDENCE_HOST, IS_EVM } from "../../configs";
import { COMMON_CONSTANT, INPUT_STATUS } from "../../constants/constant_common";
import GridFour from "../../assets/images/icon_collection.svg";
import FileAdd from "../../assets/images/icon_cover_image.svg";
import FileCover from "../../assets/images/icon_cover_add.svg";
import IconBoxes from "../../assets/images/max_supply.svg";
import StarCredence from "../../assets/images/icon_project.svg";
import ColumnUp from "../../assets/images/icon_one.svg";
import Rocket from "../../assets/images/airdrop.svg";
import ViewList from "../../assets/images/view_list.svg";
// import chevronLeftIcon from "../../assets/images/chevron-left.svg";
// import ibfx1 from "../../assets/images/ibfx-1.svg";
import axios from "axios";
import {
  createNFT,
  getCredenceEvmCollectionUserByAddress,
  getCredenceEvmNFTByAddress,
  getCredencePercentage,
  uploadCoverImage,
} from "../../modules/credence/action";
import { decryptMnemonic } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import Pdf from "react-pdf-viewer-js";
import FileResizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";
import ModalReviewNFT from "../../components/ModalReviewNFT";
import { useAccount } from "wagmi";
import {
  DropboxOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GlobalOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const PDFJS = window["pdfjs-dist/build/pdf"];
PDFJS.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js";

const { Step } = Steps;
const { Text, Title } = Typography;
const { Dragger } = Upload;

const dataURLtoFile = async (dataurl, filename) => {
  let image = await fetch(dataurl);
  image = await image.blob();
  return new File([image], filename, { type: "image/png" });
};

export default function CreateNFT() {
  const { address, isConnected } = useAccount();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const nftMaxPercentage = useSelector((state) =>
    state.getIn(["credence", "nftMaxPercentage"])
  );
  const userCollections = useSelector((state) =>
    state.getIn(["credence", "userCollections"]).toJS()
  );
  const [file, setFile] = useState();
  const [fileTemp, setFileTemp] = useState();
  const [fileCover, setCoverFile] = useState();
  //   const [compressedImage, setCompressedImage] = useState();
  const [fileCoverTemp, setCoverFileTemp] = useState();
  const [feeBasis, setFeeBasis] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [isFilePdf, setFilePdf] = useState(false);
  const [typeFile, setTypeFile] = useState();
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isNameOverlap, setNameOverlap] = useState(false);
  const [isCollection, setCollection] = useState(false);
  const [showCoverImage, setShowCoverImage] = useState(false);
  // const [isPdfOrAudio, setIsPdfOrAudio] = useState(false);
  const [isMoreOneFragment, setMoreOneFragment] = useState(false);
  const [isAirdrop, setIsAirdrop] = useState(false);
  const [nftName, setNftName] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [description, setDescription] = useState("");
  const [fragmentation, setFragmentation] = useState();
  const [externalLink, setExternalLink] = useState("");
  const [webLink, setWebLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [whitelists, setWhitelists] = useState([]);
  const [properties, setProperties] = useState([]);
  const [levels, setLevels] = useState([]);
  const [stats, setStats] = useState([]);
  const [pinCode, setPinCode] = useState("");
  const [isPinError, setPinError] = useState(false);
  const [processingPercent, setProcessingPercent] = useState(10);
  const [page, setPage] = useState(1);
  const [resultAutoComplete, setResultAutoComplete] = useState([]);
  const [maxPercentage, setMaxPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [symbol, setSymbol] = useState(null);

  const closeModal = () => {
    setProcessing(false);
    setSuccess(false);
    setFailed(false);
    setIsUpload(false);
    setProcessingPercent(10);
  };

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async () => {
    if (!IS_EVM) {
      setPinError(false);
      const _check = checkPin();
      if (!_check) {
        setPinCode("");
        return setPinError(true);
      }
    }

    try {
      setProcessing(true);
      const _file = await onUpload(file);
      setProcessingPercent(30);

      let cover_file = null;
      const cover = fileCover;
      if (cover) {
        const formData = new FormData();
        // const fileObj = await dataURLtoFile(fileCover, uuidv4());
        const resizeImage = await resizeFile(cover);
        // new Compressor(resizeImage, {
        // 	quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        // 	success: (compressedResult) => {
        // 		// compressedResult has the compressed file.
        // 		// Use the compressed file to upload the images to your server.
        // 		setCompressedImage(compressedResult)
        // 	},
        // })

        formData.append("file", resizeImage, `${uuidv4()}.jpeg`);

        const uploadCover = await uploadCoverImage(formData);
        setProcessingPercent(70);
        cover_file = uploadCover.data.filename;
      }

      let data = {
        name: nftName,
        description: description,
        ipfs_hash: _file.data?.ipfs_hash,
        cover_file,
        is_pdf: isFilePdf,
        fragmentation: isMoreOneFragment ? fragmentation : 1,
        collection_name: isCollection ? collectionName : null,
        fee: 200000,
        fee_ibfx2: 0,
        category: typeFile,
        external_link: externalLink !== "" ? externalLink : null,
        web_link: webLink !== "" ? webLink : null,
        instagram_link: instagramLink !== "" ? instagramLink : null,
        twitter_link: twitterLink !== "" ? twitterLink : null,
        seller_fee_basis_point: feeBasis * 100,
        is_airdrop: isAirdrop,
        properties: properties.map((p) => {
          return {
            name: p.type.value,
            value: p.name.value,
          };
        }),
        levels: levels.map((l) => {
          return {
            name: l.name.value,
            value: l.currentValue.value.toString(),
            max_value: l.fromValue.value.toString(),
          };
        }),
        stats: stats.map((s) => {
          return {
            name: s.name.value,
            value: s.currentValue.value.toString(),
            max_value: s.fromValue.value.toString(),
          };
        }),
      };

      if (IS_EVM) {
        delete data.fragmentation;
        delete data.fee_ibfx2;
        delete data.seller_fee_basis_point;

        data.max_supply = isMoreOneFragment ? fragmentation : 1;
        data.symbol = symbol;
        data.royalty_fee = feeBasis;
        data.supply = 0;
        data.address = address;

        if (data.is_airdrop) {
          let airdropMaxSupply = 0;
          data.whitelist_address = whitelists.map((w) => {
            airdropMaxSupply += Number(w.quantity.value);
            return {
              address: w.address.value,
              quantity: w.quantity.value,
            };
          });
          data.airdrop_max_supply = airdropMaxSupply;
        }
      }

      // const getMnemonic = localStorage.getItem(
      //   COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      // )
      // const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode)
      await createNFT(data);

      message.success(t("create_nft_success"));
      dispatch(getCredenceEvmNFTByAddress(address, 1));

      setProcessingPercent(100);
      setProcessing(false);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      setProcessingPercent(0);
      setProcessing(false);
      setFailed(true);
      message.error(t("create_nft_success"));
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        700,
        700,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.addEventListener("load", () => callback(reader.result));
  };

  const beforeUpload = (file) => {
    if (!/jpe?g|png|pdf|svg|gif|heic|mp3|flac|wav|m4a$/.test(file.type)) {
      // message.error('Picture format error');
      file.flag = true;
      return false;
    }

    let firstFeePercentage = 0;

    if (/jpe?g|png|svg|gif$/.test(file.type)) {
      setTypeFile("ARTWORK");
      firstFeePercentage = Number(
        nftMaxPercentage.find((i) => i.key === "ARTWORK")?.first_fee_percentage
      );
    }

    if (/pdf$/.test(file.type)) {
      setTypeFile("EBOOK");
      firstFeePercentage = Number(
        nftMaxPercentage.find((i) => i.key === "EBOOK")?.first_fee_percentage
      );
    }

    if (/mp3|flac|wav|m4a$/.test(file.type)) {
      setTypeFile("AUDIO");
      firstFeePercentage = Number(
        nftMaxPercentage.find((i) => i.key === "AUDIO")?.first_fee_percentage
      );
    }

    setMaxPercentage((Number(10000) - Number(firstFeePercentage)) / 100);

    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error(`${t("error_size_limit")} 50MB!`);
      return false;
    }

    setFile(file);
    return true;
  };

  const onUpload = async (param) => {
    try {
      const formData = new FormData();

      formData.append(
        "file",
        param.file.fileList[0]?.originFileObj,
        param.file.file.name
      );
      const response = (
        await axios.post(`${CREDENCE_HOST}/nft/upload`, formData)
      ).data;

      return {
        isError: false,
        data: response.data,
        errorMessage: null,
      };
      param.onSuccess(response.data, param.file);
    } catch (e) {
      console.log(e);
      const error = e.response
        ? e.response.data
          ? e.response.data
          : e.response
        : "Failed to upload document";
      return { isError: true, data: [], errorMessage: error, cover: null };
      param.onError(error, undefined);
    }
  };

  const onGetCoverImageFromPDF = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = function (ev) {
      var loadingTask = PDFJS.getDocument(fileReader.result);

      loadingTask.promise.then(
        function (pdf) {
          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(function (page) {
            var scale = 1.5;
            var viewport = page.getViewport({ scale: scale });

            var canvas = document.getElementById("the-canvas");
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            var renderTask = page.render(renderContext);

            renderTask.promise.then(function async() {
              dataURLtoFile(canvas.toDataURL("image/jpeg")).then((val) =>
                setCoverFile(val)
              );
            });
          });
        },
        function (error) {
          console.log(error);
        }
      );
    };
    fileReader.readAsArrayBuffer(file);
  };

  const propsFile = {
    name: "file",
    multiple: false,
    // action: `${CREDENCE_HOST}/nft/upload`,
    beforeUpload: beforeUpload,
    // customRequest: onUpload,
    onChange: (info) => {
      if (/jpe?g|png|svg|gif$/.test(info.file.type)) {
        setCoverFile(info?.fileList[0]?.originFileObj);
      }

      if (!info.hasOwnProperty("status")) {
        setIsUpload(true);
        // }
        // console.log(info);
        // if (status === "done") {
        getBase64(info?.fileList[0]?.originFileObj, (fileUrl) =>
          setFileTemp(fileUrl)
        );
        const base64File = URL.createObjectURL(
          info?.fileList[0]?.originFileObj
        );
        setFileTemp(base64File);

        if (/pdf$/.test(info.file.type)) {
          onGetCoverImageFromPDF(info?.fileList[0]?.originFileObj);
          setFilePdf(true);
        } else {
          setFilePdf(false);
        }

        const fileBlob = new Blob([info]);
        setFile({ file: info, fileBlob });

        // if (
        //   /pdf$/.test(info.file.type) ||
        //   /mp3|flac|wav$/.test(info.file.type)
        // ) {
        //   setIsPdfOrAudio(true);
        // }

        // setFile(response?.ipfs_hash);
        message.success(`${info.file.name} ${t("file_upload_success")}`);
        setIsUpload(false);
        // } else if (status === "error") {
        //   message.error(`${info.file.name}  ${t("file_upload_failed")}`);
        //   setIsUpload(false);
        // }
      }
    },
  };

  const propsCoverFile = {
    name: "file",
    multiple: false,
    maxCount: 1,
    customRequest: (param) => param.onSuccess({}, param.file),
    onChange: (info) => {
      const { status, response } = info.file;
      // console.log(info);
      // console.log(status);
      // console.log(info.file.name);
      // getData response.data.filename
      if (status !== "uploading") {
      }
      if (status === "done") {
        getBase64(info.file.originFileObj, (imageUrl) => {
          setCoverFileTemp(imageUrl);
        });
        setCoverFile(info.file.originFileObj);
        message.success(`${info.file.name} ${t("file_upload_success")}`);
      } else if (status === "error") {
        message.error(`${info.file.name}  ${t("file_upload_failed")}`);
      }
    },
  };

  const onNameNFTChanged = (e) => {
    if (e.target.value.length > 32) {
      setNameOverlap(true);
    } else {
      setNameOverlap(false);
    }
    setNftName(e.target.value);
  };

  const addProperties = () => {
    properties.push({
      type: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
      name: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
    });
    setProperties([...properties]);
  };

  const addWhitelists = () => {
    whitelists.push({
      address: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
      quantity: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: null,
      },
    });
    setWhitelists([...whitelists]);
  };

  const addLevels = () => {
    levels.push({
      name: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
      currentValue: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
      fromValue: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
    });
    setLevels([...levels]);
  };

  const addStats = () => {
    stats.push({
      name: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
      currentValue: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
      fromValue: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
    });
    setStats([...stats]);
  };

  const deleteWhitelists = (key) => {
    setWhitelists((whitelists) =>
      whitelists.filter((_, index) => index !== key)
    );
  };

  const deleteProperty = (key) => {
    setProperties((newProperty) =>
      newProperty.filter((_, index) => index !== key)
    );
  };

  const deleteLevel = (key) => {
    setLevels((newLevels) => newLevels.filter((_, index) => index !== key));
  };

  const deleteStat = (key) => {
    setStats((newStats) => newStats.filter((_, index) => index !== key));
  };

  const handleSearch = (searchText) => {
    setResultAutoComplete(
      !searchText
        ? []
        : userCollections?.data?.records?.filter(
            (i) => i.name.toLowerCase() === searchText
          )
    );
  };

  useEffect(() => {
    dispatch(getCredencePercentage());
    dispatch(getCredenceEvmCollectionUserByAddress(address));
  }, []);

  return (
    <ColCenter
      style={{
        height: "100%",
        minHeight: "calc(100vh - 120px)",
      }}
    >
      <div style={{ minWidth: "60vw" }}>
        {fileTemp ? (
          isFilePdf ? (
            <Pdf file={fileTemp} page={page}>
              {({ pdfDocument, pdfPage, canvas }) => (
                <ColCenter>
                  {!pdfDocument && <span>Loading...</span>}
                  {canvas && (
                    <div
                      style={{
                        height: 500,
                        width: "auto",
                        objectFit: "contain",
                      }}
                    >
                      {canvas}
                    </div>
                  )}
                  {Boolean(pdfDocument && pdfDocument.numPages) && (
                    <RowCenter className="w-100 mt-10px">
                      <Button
                        type="primary"
                        style={{ width: "100px" }}
                        disabled={page === 1}
                        onClick={() => setPage(page - 1)}
                      >
                        {t("previous")}
                      </Button>
                      <Button
                        type="primary"
                        className="ml-4px"
                        style={{ width: "100px" }}
                        disabled={page === pdfDocument.numPages}
                        onClick={() => setPage(page + 1)}
                      >
                        {t("next")}
                      </Button>
                    </RowCenter>
                  )}
                </ColCenter>
              )}
            </Pdf>
          ) : (
            <ColCenter>
              <img
                src={fileTemp}
                alt="avatar"
                style={{ height: 170, marginTop: -13 }}
              />
            </ColCenter>
          )
        ) : (
          <Dragger
            name="avatar"
            listType="picture-card"
            className="mt-24px dragger-nft"
            showUploadList={false}
            {...propsFile}
          >
            {isUpload ? (
              <Spin />
            ) : (
              <div>
                <p className="ant-upload-drag-icon">
                  <DropboxOutlined />
                </p>
                <p className="ant-upload-text">{t("click_or_drop")} items</p>
                <p className="ant-upload-hint">
                  {t("file_types_hint")}: JPG, PNG, SVG & PDF
                </p>
              </div>
            )}
          </Dragger>
        )}
        <Title
          className="text-size-15 text-w600 mb-8px mt-24px"
          level={5}
          align="left"
        >
          {t("nft_name")}
        </Title>
        <InputStyled
          value={nftName}
          onChange={onNameNFTChanged}
          placeholder={t("nft_name_placeholder")}
          size="large"
        />
        {IS_EVM && (
          <>
            <Title
              className="text-size-15 text-w600 mb-8px mt-24px"
              level={5}
              align="left"
            >
              Symbol
            </Title>
            <InputStyled
              value={symbol}
              onChange={(val) => setSymbol(val.target.value)}
              placeholder={"Enter Symbol"}
              size="large"
            />
          </>
        )}
        {isNameOverlap && (
          <RowStart className="mt-8px align-center">
            <InfoCircleOutlined style={{ color: "red", fontSize: 13 }} />
            <Text className="ml-4px text-size-12" style={{ color: "red" }}>
              {t("name_limit_warning")}
            </Text>
          </RowStart>
        )}
        <>
          <RowSpaceBetween className="mt-24px">
            <RowStart>
              <img src={FileAdd} alt="grid-four" />
              <ColStart className="ml-8px">
                <Title
                  className="text-size-14 text-w500 mb-0px mt-0px"
                  level={5}
                  align="left"
                >
                  {t("cover_image")}
                </Title>
                <Text className="gray-5 text-size-12 text-w300 pr-12px">
                  {t("cover_image_toogle")}
                </Text>
              </ColStart>
            </RowStart>
            <Switch
              defaultChecked={false}
              onChange={(v) => setShowCoverImage(v)}
            />
          </RowSpaceBetween>
          {showCoverImage && (
            <ColStart>
              <Title
                className="text-size-15 text-w600 mb-8px mt-24px"
                level={5}
                align="left"
              >
                {t("cover_image")}
              </Title>
              <Dragger
                name="avatar"
                listType="picture-card"
                className="dragger-nft"
                showUploadList={false}
                {...propsCoverFile}
              >
                {fileCoverTemp ? (
                  <>
                    <img
                      src={fileCoverTemp}
                      alt="avatar"
                      style={{ height: 170, marginTop: -13 }}
                    />
                  </>
                ) : (
                  <div>
                    <p className="ant-upload-drag-icon">
                      {/* <DropboxOutlined /> */}
                      <img src={FileCover} alt="grid-four" />
                    </p>
                    <p className="ant-upload-text">
                      {t("click_or_drop")} Image
                    </p>
                    <p className="ant-upload-hint">
                      {t("file_types_hint")}: JPG & PNG
                    </p>
                  </div>
                )}
              </Dragger>
            </ColStart>
          )}
        </>
        <RowSpaceBetween className="mt-24px">
          <RowStart>
            <img src={GridFour} alt="grid-four" />
            <ColStart className="ml-8px">
              <Title
                className="text-size-14 text-w500 mb-0px mt-0px"
                level={5}
                align="left"
              >
                {t("part_of_collection")}
              </Title>
              <Text className="gray-5 text-size-12 text-w300">
                {t("part_of_collection_toogle")}
              </Text>
            </ColStart>
          </RowStart>
          <Switch defaultChecked={false} onChange={(v) => setCollection(v)} />
        </RowSpaceBetween>
        {isCollection && (
          <>
            <Title
              className="text-size-15 text-w600 mb-8px mt-24px"
              level={5}
              align="left"
            >
              {t("collection_name")}
            </Title>
            <AutoCompleteStyled
              onSelect={(val) => setCollectionName(val)}
              onSearch={handleSearch}
              placeholder={t("collection_name")}
              onChange={(val) => setCollectionName(val)}
            >
              {resultAutoComplete.length > 0
                ? resultAutoComplete.map((i) => {
                    return (
                      <AutoComplete.Option value={i.name}>
                        {i.name}
                      </AutoComplete.Option>
                    );
                  })
                : userCollections?.data?.records?.map((i) => {
                    return (
                      <AutoComplete.Option value={i.name}>
                        {i.name}
                      </AutoComplete.Option>
                    );
                  })}
            </AutoCompleteStyled>
          </>
        )}

        <RowSpaceBetween className="mt-24px">
          <RowStart>
            <img src={IconBoxes} alt="grid-four" />
            <ColStart className="ml-8px">
              <Title
                className="text-size-14 text-w500 mb-0px mt-0px"
                level={5}
                align="left"
              >
                {IS_EVM ? "Max Supply" : t("fragmentation")}
              </Title>
              <Text className="gray-5 text-size-12 text-w300">
                {t("fragmentation_toogle")}
              </Text>
            </ColStart>
          </RowStart>
          <Switch
            defaultChecked={false}
            onChange={(v) => setMoreOneFragment(v)}
          />
        </RowSpaceBetween>
        {isMoreOneFragment && (
          <>
            <Title
              className="text-size-15 text-w600 mb-8px mt-24px"
              level={5}
              align="left"
            >
              {t("fragmentation_supply")}
            </Title>
            <InputNumberStyled
              type="number"
              className="w-100"
              value={fragmentation}
              onChange={(e) => setFragmentation(e)}
              placeholder={"Enter Quantity"}
            />
          </>
        )}

        <RowSpaceBetween className="mt-24px">
          <RowStart>
            <img
              style={{ width: 35.99, height: 35.99 }}
              src={Rocket}
              alt="rocket"
            />
            <ColStart className="ml-8px">
              <Title
                className="text-size-14 text-w500 mb-0px mt-0px"
                level={5}
                align="left"
              >
                Airdrop
              </Title>
              <Text className="gray-5 text-size-12 text-w300">
                {t("airdrop_toogle")}
              </Text>
            </ColStart>
          </RowStart>
          <Switch defaultChecked={false} onChange={(v) => setIsAirdrop(v)} />
        </RowSpaceBetween>
        {isAirdrop && (
          <>
            <RowSpaceBetween className="mt-24px">
              <ColStart>
                <Title
                  className="text-size-15 text-w600 mb-0px mt-0px"
                  level={5}
                  align="left"
                >
                  Whitelist
                </Title>
                <Text
                  style={{ minHeight: 38 }}
                  className="gray-5 text-size-12 text-w300"
                >
                  Contains wallet address and value
                </Text>
              </ColStart>
              <img className="ml-8px" src={ViewList} alt="properties" />
            </RowSpaceBetween>
            <AddButtonStyled onClick={addWhitelists}>Add</AddButtonStyled>
            {whitelists.length > 0 && (
              <div
                style={{
                  border: "2px #D6DAE1 solid",
                  borderRadius: 10,
                  padding: 12,
                }}
              >
                {whitelists.length > 0 && (
                  <Row className="mt-12px" gutter={[8, 8]}>
                    <Col span={18}>
                      <Title
                        className="text-size-15 text-w600 mb-8px "
                        level={5}
                        align="left"
                      >
                        Address
                      </Title>
                    </Col>
                    <Col span={4}>
                      <Title
                        className="text-size-15 text-w600 mb-8px "
                        level={5}
                        align="left"
                      >
                        Quantity
                      </Title>
                    </Col>
                  </Row>
                )}
                {whitelists.map((property, key) => (
                  <Row
                    gutter={8}
                    className={key > 0 && "mt-12px"}
                    key={"property" + key}
                  >
                    <Col span={18}>
                      <InputStyled
                        value={property.address.value}
                        onChange={async (val) => {
                          whitelists[key].address = {
                            validateStatus: INPUT_STATUS.SUCCESS,
                            help: "",
                            value: val.target.value,
                          };
                          setWhitelists([...whitelists]);
                        }}
                        placeholder="Wallet Address"
                      />
                    </Col>
                    <Col span={5}>
                      <InputStyled
                        value={property.quantity.value}
                        onChange={async (val) => {
                          whitelists[key].quantity = {
                            validateStatus: INPUT_STATUS.SUCCESS,
                            help: "",
                            value: val.target.value,
                          };
                          setWhitelists([...whitelists]);
                        }}
                        placeholder="0"
                      />
                    </Col>
                    <Col span={1}>
                      <Button
                        icon={<DeleteOutlined style={{ fontSize: 13 }} />}
                        size="large"
                        style={{ borderRadius: "2px" }}
                        onClick={() => deleteWhitelists(key)}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            )}
          </>
        )}

        <Title
          className="text-size-15 text-w600 mb-8px mt-24px"
          level={5}
          align="left"
        >
          {t("royalty_percentage")}
        </Title>
        <InputNumberStyled
          type="number"
          disabled={isLoading}
          className="w-100 with-addon"
          addonAfter={"%"}
          status={feeBasis > maxPercentage ? "error" : null}
          min={1}
          max={100}
          value={feeBasis}
          onChange={(e) => setFeeBasis(e)}
          placeholder={
            isLoading
              ? "Getting the data"
              : `${t("maximum_allowed")}: ${maxPercentage}%`
          }
        />
        {feeBasis > maxPercentage ? (
          <RowStart className="mt-8px align-center">
            <InfoCircleOutlined className="danger" />
            <Text className="ml-4px text-size-12 danger">
              {t("amount_limit_warning")}: {maxPercentage}%
            </Text>
          </RowStart>
        ) : (
          <RowStart className="mt-8px align-center">
            <InfoCircleOutlined />
            <Text className="ml-4px text-size-12">
              {t("amount_limit_warning")}
            </Text>
          </RowStart>
        )}

        <Title
          className="text-size-15 text-w600 mb-8px mt-24px"
          level={5}
          align="left"
        >
          {t("description")}
        </Title>
        <TextAreaStyled
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={t("description_placeholder")}
          size="large"
          rows={4}
        />

        <Title
          className="text-size-15 text-w600 mb-8px mt-24px"
          level={5}
          align="left"
        >
          {t("external_link")}
        </Title>
        <InputStyled
          value={externalLink}
          onChange={(e) => setExternalLink(e.target.value)}
          placeholder={t("external_link_placeholder")}
          size="large"
        />

        <Title
          className="text-size-15 text-w600 mb-8px mt-24px"
          level={5}
          align="left"
        >
          {t("other_links")}{" "}
          <span style={{ color: "#c9c9c9" }}>({t("optional")})</span>
        </Title>
        <InputStyled
          value={webLink}
          onChange={(e) => setWebLink(e.target.value)}
          prefix={<GlobalOutlined className="mr-8px" />}
          placeholder="yoursite.com"
          size="large"
        />
        <InputStyled
          value={twitterLink}
          onChange={(e) => setTwitterLink(e.target.value)}
          className="mt-8px"
          prefix={<TwitterOutlined className="mr-8px" />}
          placeholder="http://twitter.com/twitterhandle"
          size="large"
        />
        <InputStyled
          value={instagramLink}
          onChange={(e) => setInstagramLink(e.target.value)}
          className="mt-8px"
          prefix={<InstagramOutlined className="mr-8px" />}
          placeholder="http://instagram.com/instagramhandle"
          size="large"
        />

        <Row className="mt-24px" gutter={[20, 20]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <RowSpaceBetween>
              <ColStart>
                <Title
                  className="text-size-15 text-w600 mb-0px mt-0px"
                  level={5}
                  align="left"
                >
                  {t("properties")}
                </Title>
                <Text
                  style={{ minHeight: 38 }}
                  className="gray-5 text-size-12 text-w300"
                >
                  {t("properties_desc")}
                </Text>
              </ColStart>
              <img className="ml-8px" src={ViewList} alt="properties" />
            </RowSpaceBetween>
            <AddButtonStyled onClick={addProperties}>Add</AddButtonStyled>
            <hr color="#D6DAE1" />
            {properties.length > 0 && (
              <Row className="mt-12px" gutter={[8, 8]}>
                <Col span={10}>
                  <Title
                    className="text-size-15 text-w600 mb-8px "
                    level={5}
                    align="left"
                  >
                    {t("type")}
                  </Title>
                </Col>
                <Col span={10}>
                  <Title
                    className="text-size-15 text-w600 mb-8px "
                    level={5}
                    align="left"
                  >
                    {t("name")}
                  </Title>
                </Col>
              </Row>
            )}
            {properties.map((property, key) => (
              <Row
                gutter={8}
                className={key > 0 && "mt-12px"}
                key={"property" + key}
              >
                <Col span={10}>
                  <InputStyled
                    value={property.type.value}
                    onChange={async (val) => {
                      properties[key].type = {
                        validateStatus: INPUT_STATUS.SUCCESS,
                        help: "",
                        value: val.target.value,
                      };
                      setProperties([...properties]);
                    }}
                    placeholder="Enter type"
                  />
                </Col>
                <Col span={10}>
                  <InputStyled
                    value={property.name.value}
                    onChange={async (val) => {
                      properties[key].name = {
                        validateStatus: INPUT_STATUS.SUCCESS,
                        help: "",
                        value: val.target.value,
                      };
                      setProperties([...properties]);
                    }}
                    placeholder="Enter name"
                  />
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined style={{ fontSize: 13 }} />}
                    size="large"
                    style={{ borderRadius: "2px" }}
                    onClick={() => deleteProperty(key)}
                  />
                </Col>
              </Row>
            ))}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <RowSpaceBetween>
              <ColStart>
                <Title
                  className="text-size-15 text-w600 mb-0px mt-0px"
                  level={5}
                  align="left"
                >
                  {t("levels")}
                </Title>
                <Text
                  style={{ minHeight: 38 }}
                  className="gray-5 text-size-12 text-w300"
                >
                  {t("levels_desc")}
                </Text>
              </ColStart>
              <img className="ml-8px" src={StarCredence} alt="levels" />
            </RowSpaceBetween>
            <AddButtonStyled onClick={addLevels}>Add</AddButtonStyled>
            <hr color="#D6DAE1" />
            {levels.length > 0 && (
              <Row className="mt-12px" gutter={[8, 8]}>
                <Col span={10}>
                  <Title
                    className="text-size-15 text-w600 mb-8px "
                    level={5}
                    align="left"
                  >
                    {t("name")}
                  </Title>
                </Col>
                <Col span={10}>
                  <Title
                    className="text-size-15 text-w600 mb-8px "
                    level={5}
                    align="left"
                  >
                    {t("value")}
                  </Title>
                </Col>
              </Row>
            )}

            {levels.map((level, key) => (
              <Row
                gutter={8}
                className={key > 0 && "mt-12px"}
                key={"level" + key}
              >
                <Col span={10}>
                  <InputStyled
                    value={level.name.value}
                    onChange={async (e) => {
                      levels[key].name = {
                        validateStatus: INPUT_STATUS.SUCCESS,
                        help: "",
                        value: e.target.value,
                      };
                      setLevels([...levels]);
                    }}
                    placeholder="Enter name"
                  />
                </Col>
                <Col span={10}>
                  <RowSpaceBetween>
                    <InputNumberStyled
                      type="number"
                      value={level.currentValue.value}
                      onChange={async (val) => {
                        levels[key].currentValue = {
                          validateStatus: INPUT_STATUS.SUCCESS,
                          help: "",
                          value: val,
                        };
                        setLevels([...levels]);
                      }}
                      defaultValue={0}
                    />
                    <Text className="gray-5 text-size-12 text-w300 mx-4px">
                      of
                    </Text>
                    <InputNumberStyled
                      type="number"
                      value={level.fromValue.value}
                      onChange={async (val) => {
                        levels[key].fromValue = {
                          validateStatus: INPUT_STATUS.SUCCESS,
                          help: "",
                          value: val,
                        };
                        setLevels([...levels]);
                      }}
                      defaultValue={0}
                    />
                  </RowSpaceBetween>
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined style={{ fontSize: 13 }} />}
                    size="large"
                    style={{ borderRadius: "2px" }}
                    onClick={() => deleteLevel(key)}
                  />
                </Col>
              </Row>
            ))}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <RowSpaceBetween>
              <ColStart>
                <Title
                  className="text-size-15 text-w600 mb-0px mt-0px"
                  level={5}
                  align="left"
                >
                  {t("stats")}
                </Title>
                <Text
                  style={{ minHeight: 38 }}
                  className="gray-5 text-size-12 text-w300"
                >
                  {t("stats_desc")}
                </Text>
              </ColStart>
              <img className="ml-8px" src={ColumnUp} alt="stats" />
            </RowSpaceBetween>
            <AddButtonStyled onClick={addStats}>Add</AddButtonStyled>
            <hr color="#D6DAE1" />
            {stats.length > 0 && (
              <Row className="mt-12px" gutter={[8, 8]}>
                <Col span={10}>
                  <Title
                    className="text-size-15 text-w600 mb-8px "
                    level={5}
                    align="left"
                  >
                    {t("name")}
                  </Title>
                </Col>
                <Col span={10}>
                  <Title
                    className="text-size-15 text-w600 mb-8px "
                    level={5}
                    align="left"
                  >
                    {t("value")}
                  </Title>
                </Col>
              </Row>
            )}
            {stats.map((stat, key) => (
              <Row
                gutter={8}
                className={key > 0 && "mt-12px"}
                key={"level" + key}
              >
                <Col span={10}>
                  <InputStyled
                    value={stat.name.value}
                    onChange={async (e) => {
                      stats[key].name = {
                        validateStatus: INPUT_STATUS.SUCCESS,
                        help: "",
                        value: e.target.value,
                      };
                      setStats([...stats]);
                    }}
                    placeholder="Enter name"
                  />
                </Col>
                <Col span={10}>
                  <RowSpaceBetween>
                    <InputNumberStyled
                      type="number"
                      value={stat.currentValue.value}
                      onChange={async (val) => {
                        stats[key].currentValue = {
                          validateStatus: INPUT_STATUS.SUCCESS,
                          help: "",
                          value: val,
                        };
                        setStats([...stats]);
                      }}
                      defaultValue={0}
                    />
                    <Text className="gray-5 text-size-12 text-w300 mx-4px">
                      of
                    </Text>
                    <InputNumberStyled
                      type="number"
                      value={stat.fromValue.value}
                      onChange={async (val) => {
                        stats[key].fromValue = {
                          validateStatus: INPUT_STATUS.SUCCESS,
                          help: "",
                          value: val,
                        };
                        setStats([...stats]);
                      }}
                      defaultValue={0}
                    />
                  </RowSpaceBetween>
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined style={{ fontSize: 13 }} />}
                    size="large"
                    style={{ borderRadius: "2px" }}
                    onClick={() => deleteStat(key)}
                  />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>

        <ModalReviewNFT
          disabled={
            !fileTemp ||
            isProcessing ||
            !(file && nftName && description && feeBasis) ||
            feeBasis > maxPercentage ||
            (isAirdrop && whitelists.length === 0) ||
            (IS_EVM && !isConnected)
          }
          fileTemp={fileTemp}
          onSubmit={onSubmit}
          isProcessing={isProcessing}
          isSuccess={isSuccess}
          isFailed={isFailed}
          processingPercent={processingPercent}
          nftName={nftName}
          description={description}
          symbol={symbol}
          fragmentation={fragmentation || 0}
          royaltyPercentage={feeBasis}
          externalLink={externalLink}
          closeModal={closeModal}
        />
      </div>
    </ColCenter>
  );
}
