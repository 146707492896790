import styled from "@emotion/styled"
import { Tabs } from "antd"

export const ContentChart = styled.div`
  padding-top: 50px;
`

export const TabsStyled = styled(Tabs)`
  // @media screen and (max-width: 834px) {
  // .ant-tabs-nav-wrap {
  // position: fixed;
  // overflow: auto;
  // top: 75px;
  // left: 20px;
  // right: 20px;
  // bottom: 0;
  // }
`
