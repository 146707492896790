import { Button, Col, Modal, Progress, Row, Steps, Typography, Drawer, notification, message } from 'antd'
import { ButtonWhiteStyled, CheckboxStyled, ColCenter } from '../general_styled'
import closeIcon from '../../assets/images/close.svg'
import { useEffect, useState } from 'react'
import {
	ContentIconStyled,
	ContentStyled,
	ContentTextStyled,
	CopyTextStyled,
	LiStyled,
	MnemonicSpan,
	RedTextStyled,
	StepContentStyled,
	UlStyled,
	ButtonContinueStyled,
	CanvasButtonStyled,
} from './styled'
import { BackButtonStyled } from '../ModalWallet/styled'
import chevronLeftIcon from '../../assets/images/chevron-left.svg'
import walletIcon from '../../assets/images/wallet.svg'
import keyIcon from '../../assets/images/key.svg'
import copyIcon from '../../assets/images/copy.svg'
import Verify from './Verify'
import Pin from './Pin'
import { getWalletUser, registerWalletUser } from '../../modules/wallet/action'
import algosdk from 'algosdk'
import { useDispatch, useSelector } from 'react-redux'
import { USER_STATUS } from '../../constants/constant_wallet'
import CryptoJS from 'crypto-js'
import { COMMON_CONSTANT } from '../../constants/constant_common'
import { encryptMnemonic } from '../../utils/utils'
import useWindowDimensions from '../../utils/windowDimensions'
import { AlertRetrieve } from '../ModalImportMnemonic/styled'

const { Title, Text } = Typography

export default function ModalOnboarding(props) {
	const windowDimensions = useWindowDimensions()
	const [current, setCurrent] = useState(0)
	const { Step } = Steps
	const steps = [
		{ title: 'Instructions' },
		{ title: 'Recovery Passphrase' },
		{ title: 'Verification' },
		{ title: 'Key' },
	]
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [mnemonic, setMnemonic] = useState('')
	const [processingPercent, setProcessingPercent] = useState(0)
	const [saveRecover, setSaveRecover] = useState(false)
	const [isProcessing, setProcessing] = useState(false)
	const [isSuccess, setSuccess] = useState(false)
	const [isFailed, setFailed] = useState(false)
	const [continueDisabled, setContinueDisabled] = useState(false)

	const [pinCode, setPinCode] = useState('')

	const walletUser = useSelector((state) => state.getIn(['wallet', 'user']).toJS())
	const userData = useSelector((state) => state.getIn(['auth', 'userData']).toJS())
	const dispatch = useDispatch()

	useEffect(() => {
		if (pinCode !== '' && pinCode.length >= 4) {
			setContinueDisabled(false)
		}
	}, [pinCode])

	const showModal = () => {
		setPinCode('')
		setProcessing(false)
		setSuccess(false)
		setFailed(false)

		const key = algosdk.generateAccount()
		setMnemonic(algosdk.secretKeyToMnemonic(key.sk))
		setIsModalVisible(true)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
		setPinCode('')
		setProcessing(false)
		setSuccess(false)
		setFailed(false)
		setMnemonic('')
		setCurrent(0)
		setContinueDisabled(false)
	}

	const onBack = () => {
		setCurrent(current - 1)
		setContinueDisabled(false)
	}

	const getTillActive = async (processingPercent) => {
		try {
			const walletUser = await dispatch(getWalletUser(userData.user_id))
			if (walletUser.status === USER_STATUS.ACTIVE) {
				setProcessingPercent(100)
				setSuccess(true)
				setProcessing(false)
			} else {
				setProcessingPercent(processingPercent + 5)
				setTimeout(() => {
					getTillActive(processingPercent + 5)
				}, 2000)
			}
		} catch (e) {
			console.log(e)
			setFailed(true)
			setProcessing(false)
		}
	}

	const onSubmit = async () => {
		setProcessing(true)
		setFailed(false)
		setSuccess(false)

		try {
			const encryptedMnemonic = encryptMnemonic(mnemonic, pinCode)
			localStorage.setItem(COMMON_CONSTANT.ENCRYPTED_MNEMONIC, encryptedMnemonic.toString())
			setProcessingPercent(30)

			await registerWalletUser(
				userData.user_id,
				algosdk.mnemonicToSecretKey(mnemonic).addr,
				saveRecover ? encryptedMnemonic.toString() : null,
				dispatch
			)

			if (walletUser.status === USER_STATUS.ACTIVE) {
				setSuccess(true)
			} else {
				getTillActive(30)
			}
		} catch (e) {
			console.log(e)
			setFailed(true)
		}
	}

	const onCopyPhraseClicked = () => {
		navigator.clipboard.writeText(mnemonic)

		message.success('Copied to clipboard')
	}

	const resolveBackButton = () => {
		if (current > 0) {
			return <BackButtonStyled style={{ top: -5 }} src={chevronLeftIcon} onClick={onBack} />
		}
	}

	const resolveContinueButton = () => {
		let text = isSuccess || isFailed ? 'Dismiss' : 'Continue'

		return (
			<CanvasButtonStyled>
				<ButtonContinueStyled
					type="primary"
					block
					size="large"
					onClick={() => {
						if (current == 3 && !isSuccess && !isFailed) {
							onSubmit()
						} else if (current == 3 && (isSuccess || isFailed)) {
							handleCancel()
						} else {
							setCurrent(current + 1)
						}
					}}
					disabled={continueDisabled || isProcessing}
				>
					{text}
				</ButtonContinueStyled>
			</CanvasButtonStyled>
		)
	}

	const resolveMnemonicSpan = () => {
		const words = mnemonic.split(' ')

		return (
			<Row style={{ marginBottom: '16px' }}>
				{words.map((word, key) => (
					<Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
						<MnemonicSpan>
							{key + 1}. {word}
						</MnemonicSpan>
					</Col>
				))}
			</Row>
		)
	}

	const resolveContent = () => {
		switch (current) {
			case 0:
				if (windowDimensions.width < 835) {
					return (
						<StepContentStyled>
							<Col
								span={24}
								style={{
									textAlign: 'center',
									marginBottom: '24px',
								}}
							>
								<img preview={false} src={keyIcon} width={96} />
							</Col>
							<Col
								span={24}
								style={{
									marginBottom: '24px',
									width: '100%',
									textAlign: 'center',
								}}
							>
								<ContentTextStyled>
									The recovery passphrase is your private key in a human readable format
								</ContentTextStyled>
								<UlStyled>
									<LiStyled>It offers direct access to your wallet and the funds in it.</LiStyled>
									<LiStyled>It should be handled with extreme care.</LiStyled>
								</UlStyled>
							</Col>
							<ColCenter
								style={{
									justifyContent: 'flex-start',
									alignItems: 'flex-start',
									textAlign: 'center',
								}}
							>
								<RedTextStyled>PLEASE NOTE</RedTextStyled>
								<ContentTextStyled>
									Mnemonic phrases consists of a list of words (25 in the case of Algorand) that grants you access to a
									specific wallet address.
									<br />
									The main use case of the Mnemonic Phrase is for recovery purposes to your traditional method of
									access.
								</ContentTextStyled>
							</ColCenter>
						</StepContentStyled>
					)
				}

				return (
					<StepContentStyled>
						<Col style={{ marginBottom: '24px' }}>
							<ContentTextStyled>
								The recovery passphrase is your private key in a human readable format
							</ContentTextStyled>
							<UlStyled>
								<LiStyled>It offers direct access to your wallet and the funds in it.</LiStyled>
								<LiStyled>It should be handled with extreme care.</LiStyled>
							</UlStyled>
						</Col>
						<ColCenter style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
							<RedTextStyled>PLEASE NOTE</RedTextStyled>
							<ContentTextStyled>
								Recovery Passphrase consist of a list of words (25 in the case of Algorand) that grant you access to a
								specific wallet address. The main use case of Recovery Passphrase is for recovery purposes to your
								traditional method of access.
							</ContentTextStyled>
						</ColCenter>
						<ContentIconStyled src={keyIcon} />
					</StepContentStyled>
				)
			case 1:
				return (
					<StepContentStyled>
						{resolveMnemonicSpan()}
						<ColCenter
							style={{
								position: 'relative',
								justifyContent: 'flex-start',
								alignItems: 'flex-start',
							}}
						>
							<Row
								style={{
									position: 'absolute',
									right: '0',
									top: '-10px',
									cursor: 'pointer',
									alignItems: 'center',
								}}
								onClick={() => onCopyPhraseClicked()}
							>
								<img src={copyIcon} style={{ marginRight: '8px' }} />
								<CopyTextStyled>Copy Phrase</CopyTextStyled>
							</Row>
							<AlertRetrieve className="mt-30px">
								It is important that you take a backup of your recovery words above. You will be asked to re-enter your
								recovery words when you login using a new browser. If you lose access to your recovery words, then you
								will lose access to your wallet as a wallet cannot be recovered without the recovery words
							</AlertRetrieve>
							<AlertRetrieve className="mt-12px yellow">
								<CheckboxStyled checked={saveRecover} onChange={() => setSaveRecover(!saveRecover)}>
									Click here if you would like to store the recovery words in an encrypted format in our secure server
									as a secondary backup to recover your wallet in the future (Recommended)
								</CheckboxStyled>
							</AlertRetrieve>
						</ColCenter>
					</StepContentStyled>
				)
			case 2:
				return (
					<StepContentStyled>
						<Verify mnemonic={mnemonic} setDisabled={setContinueDisabled} />
					</StepContentStyled>
				)
			case 3:
				if (isProcessing || isSuccess || isFailed) {
					return (
						<StepContentStyled>
							<ColCenter className="h-100">
								<Progress type="circle" percent={processingPercent} status={isFailed && 'exception'} />
								<Title className="text-w700 gray-7 mb-0px mt-24px" level={3}>
									{isProcessing && !isSuccess && !isFailed
										? 'Processing'
										: isSuccess && !isFailed
										? 'Success'
										: 'Failed'}
								</Title>
								<Text className="gray-7" align="center">
									{isProcessing && !isSuccess && !isFailed
										? 'Please wait while we create your account'
										: isSuccess && !isFailed
										? 'Your account has been created'
										: 'Failed to create your account'}
								</Text>
							</ColCenter>
						</StepContentStyled>
					)
				}
				return (
					<StepContentStyled>
						<Pin setPinCode={setPinCode} />
					</StepContentStyled>
				)
		}
	}

	return (
		<>
			{props.page === 'home' ? (
				<ButtonWhiteStyled
					className={`${windowDimensions.width < 835 ? 'mt-12px' : ''} px-12px`}
					onClick={() => showModal()}
				>
					Activate IBFX Wallet
				</ButtonWhiteStyled>
			) : (
				<Button
					type="primary"
					size="large"
					onClick={showModal}
					style={
						windowDimensions.width < 835
							? {
									position: 'absolute',
									bottom: 20,
									left: 20,
									right: 20,
									height: 50,
									width: 'calc(100% - 40px)',
							  }
							: {}
					}
				>
					Activate IBFX Wallet
				</Button>
			)}
			{windowDimensions.width > 835 ? (
				<Modal
					centered
					visible={isModalVisible}
					onCancel={handleCancel}
					closeIcon={<img style={{ marginRight: 10 }} src={closeIcon} />}
					footer={null}
					width={1096}
				>
					<ContentStyled>
						{resolveBackButton()}
						<ColCenter span={24} style={{ position: 'relative', minHeight: '620px' }}>
							<ColCenter style={{ paddingBottom: '24px' }}>
								<Title className="text-w700 gray-7 mb-0px" level={3}>
									Create Recovery Passphrase
								</Title>
								<Text className="gray-7">Proceed with the steps to create your key</Text>
							</ColCenter>
							<Steps current={current} direction="horizontal">
								{steps.map((step, key) => {
									return <Step key={key} title={windowDimensions.width < 835 ? '' : step.title} />
								})}
							</Steps>
							{resolveContent()}
							{resolveContinueButton()}
						</ColCenter>
					</ContentStyled>
				</Modal>
			) : (
				<Drawer
					className="force-scroll"
					visible={isModalVisible}
					onCancel={handleCancel}
					placement="bottom"
					height={'100%'}
					// contentWrapperStyle={{ height: "100%", overflowY: "auto" }}
					closeIcon={
						<img style={{ marginTop: 3, position: 'absolute', right: 20 }} src={closeIcon} onClick={handleCancel} />
					}
				>
					<ContentStyled>
						{resolveBackButton()}
						<ColCenter span={24} style={{ position: 'relative', minHeight: '620px' }}>
							<ColCenter style={{ paddingBottom: '24px' }}>
								<Title className="text-w700 gray-7 mb-0px" level={3}>
									Create Recovery Passphrase
								</Title>
								<Text className="gray-7">Proceed with the steps to create your key</Text>
							</ColCenter>
							<Steps
								// direction="vertical"
								size="small"
								current={current}
								// direction="horizontal"
								responsive={false}
							>
								{steps.map((step, key) => {
									return <Step key={key} title={windowDimensions.width < 835 ? '' : step.title} />
								})}
							</Steps>
							{resolveContent()}
							{resolveContinueButton()}
						</ColCenter>
					</ContentStyled>
				</Drawer>
			)}
		</>
	)
}
