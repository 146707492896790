import { Col, Tabs } from 'antd'
import { useDispatch } from 'react-redux'
import { ButtonStyled } from '../../components/general_styled'
import { openCreateProjectModal } from '../../modules/benevolence_projects/action'
import BenevolenceDonors from '../BenevolenceDonors'
import BenevolenceProjects from '../BenevolenceProjects'
import BenevolenceVolunteers from '../BenevolenceVolunteers'
import { RowEnd, SearchStyled, TabsStyled } from './styled'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../../utils/windowDimensions'
// import BenevolenceVolunteersReward from '../BenevolenceVolunteersReward'

const { TabPane } = Tabs
export default function BenevolenceManage() {
	const { t } = useTranslation()
	const windowDimensions = useWindowDimensions()

	const dispatch = useDispatch()
	const [menu, setMenu] = useState('projects')
	const [searchVal, setSearchVal] = useState(null)

	const onCreateProject = () => {
		dispatch(openCreateProjectModal())
	}

	const renderSearchBar = () => {
		return (
			<RowEnd gutter={[12, 12]}>
				<Col span={windowDimensions.width > 925 ? 15 : 24}>
					<SearchStyled
						className="search-input"
						placeholder="Search"
						autoComplete="off"
						allowClear
						size="large"
						onSearch={(value) => setSearchVal(value)}
						style={{
							marginRight: '12px',
						}}
					/>
				</Col>
				{windowDimensions.width > 925 && (
					<Col span={9}>
						<ButtonStyled
							type={'primary'}
							size="large"
							onClick={(e) => {
								e.preventDefault()
								onCreateProject()
							}}
							style={{
								width: '100%',
							}}
						>
							<div className="text">{t('create_project')}</div>
						</ButtonStyled>
					</Col>
				)}
			</RowEnd>
		)
	}

	return (
		<>
			{!(windowDimensions.width > 925) && renderSearchBar()}
			<TabsStyled
				size="large"
				tabBarExtraContent={windowDimensions.width > 925 ? renderSearchBar() : null}
				onChange={(val) => setMenu(val)}
			>
				<TabPane tab={`${t('project')}s`} key="projects">
					<BenevolenceProjects menu={menu} searchVal={searchVal} />
				</TabPane>
				<TabPane tab={t('donors')} key="donors">
					<BenevolenceDonors menu={menu} searchVal={searchVal} />
				</TabPane>
				<TabPane tab={t('volunteer')} key="volunteer">
					<BenevolenceVolunteers menu={menu} searchVal={searchVal} />
				</TabPane>
				{/* <TabPane tab="Volunteer Reward" key="volunteer-reward">
					<BenevolenceVolunteersReward menu={menu} searchVal={searchVal} />
				</TabPane> */}
			</TabsStyled>
		</>
	)
}
