import React, { useEffect, useState } from "react";
import { Modal, Typography, Button, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
// import { ColCenter } from "../general_styled";
import { ContentStyled, SubtitleStyled } from "./styled";
// import closeIcon from "../../assets/images/close.svg";
import NeedLoginImg from "../../assets/images/login_logo.svg";
// import useWindowDimensions from "../../utils/windowDimensions";
import { closeModalLoginRegister } from "../../modules/general/action";
// import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { userLoginWithAuth0 } from "../../modules/auth/action";
import { extractErrorMessage } from "../../utils/extractErrorMessage";

// import { extractErrorMessage } from "../../utils/extractErrorMessage"

const { Title } = Typography;

function ModalLoginRegister(props) {
  const [isLoading, setLoading] = useState(false);
//   const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
//   const history = useHistory();
  const auth0 = useAuth0();

  const isDrawerOpen = useSelector((state) =>
    state.getIn(["general", "isModalLoginRegister"])
  );

  const handleCancel = () => {
    dispatch(closeModalLoginRegister());
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const onLoginWithAuth0 = async (e) => {
    if (!auth0.user) return;
    if (localStorage.getItem("USER_AUTH_TOKEN")) return;

    setLoading(true);
    try {
      await dispatch(userLoginWithAuth0(auth0.user));
      message.success("You have successfully logged in");
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Failed!",
        description: extractErrorMessage(e).includes("email")
          ? "Failed to login"
          : "",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    onLoginWithAuth0();
  }, [auth0.user]);

  return (
    <>
      {/* {windowDimensions.width > 835 ? ( */}
        <Modal
          centered
          visible={isDrawerOpen}
          className="modal-login"
          onCancel={handleCancel}
          footer={null}
          width={360}
		  closeIcon={<></>}
        //   closeIcon={
        //     <img
        //       style={{ marginRight: 10 }}
        //       src={closeIcon}
        //       onClick={handleCancel}
        //     />
        //   }
          maskStyle={{ backgroundColor: "#000" }}
		  style={{background: '#000'}}
        >
          <ContentStyled className="p-24px">
            <img
              src={NeedLoginImg}
              style={{
                marginBottom: "12px",
                marginTop: "12px",
                height: "34px",
              }}
            />
            <Title className="text-w700 gray-7 mb-12px mt-18px" level={3}>
              Login or Register
            </Title>
            <SubtitleStyled className="mb-18px pr-12px">
              You’ve reached a section that requires you to be logged in
            </SubtitleStyled>
            <Button
              block
              type="primary"
              className="mt-24px"
              size="large"
              style={{
                borderRadius: "100px",
                padding: "6px",
                height: "48px",
                background: "#000",
                fontSize: "16px",
                border: "none",
              }}
              onClick={() => {
                // history.push("/login");
                auth0.loginWithRedirect();
                //   handleCancel();
              }}
            >
              {isLoading ? antIcon : "CONTINUE"}
            </Button>
          </ContentStyled>
		  <div style={{height: '48px', background: '#000', borderRight: '3px solid #000', borderLeft: '3px solid #000',}} />
		  <ContentStyled className="bottom p-20px py-36px">
            <SubtitleStyled className="bottom">
				We use secure authentication powered by Auth0, the world’s leading authenticator
            </SubtitleStyled>
          </ContentStyled>
        </Modal>
      {/* ) : (
        <Drawer
          visible={isDrawerOpen}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            <img
              src={NeedLoginImg}
              style={{
                marginBottom: "12px",
                marginTop: "12px",
                height: "34px",
              }}
            />
            <Title className="text-w700 gray-7 mb-12px mt-18px" level={3}>
              Login or Register
            </Title>
            <SubtitleStyled className="mb-18px pr-12px">
              You’ve reached a section that requires you to be logged in
            </SubtitleStyled>
            <Button
              block
              type="primary"
              className="mt-24px"
              size="large"
              style={{
                borderRadius: "100px",
                padding: "6px",
                height: "48px",
                background: "#000",
                fontSize: "16px",
                border: "none",
              }}
              onClick={() => {
                // history.push("/login");
                auth0.loginWithRedirect();
                //   handleCancel();
              }}
            >
              {isLoading ? antIcon : "CONTINUE"}
            </Button>
          </ContentStyled>
        </Drawer>
      )} */}
    </>
  );
}

export default React.memo(ModalLoginRegister);
