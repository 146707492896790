import { notification } from 'antd'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { BENEVOLENCE_HOST, headers, benevolenceHeaders } from '../../configs'
import { PROJECT_STATUS } from '../../constants/constant_common'
import {
	GET_ALL_VOLUNTEER,
	GET_ALL_VOLUNTEER_FAIL,
	GET_ALL_VOLUNTEER_SUCCESS,
	GET_MANAGE_VOLUNTEER,
	GET_MANAGE_VOLUNTEER_FAIL,
	GET_MANAGE_VOLUNTEER_SUCCESS,
	GET_VOLUNTEERED,
	GET_VOLUNTEERED_SUCCESS,
	SET_USER_BENEVOLENCE,
	SET_VOLUNTEER,
	SET_VOLUNTEER_REWARD
} from '../constants'

export const getManageVolunteer = (projectId, page) => async (dispatch) => {
	dispatch({
		type: GET_MANAGE_VOLUNTEER,
	})

	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')

		const response = await axios.get(`${BENEVOLENCE_HOST}/manage-volunteer`, {
			headers: {
				...headers,
				Authorization: `Bearer ${jwt}`,
			},
			params: {
				project: projectId || '',
				page: page,
			},
		})

		const { data } = response.data

		dispatch({
			type: GET_MANAGE_VOLUNTEER_SUCCESS,
			payload: {
				total: data.total_items,
				hasNext: page < data.total_pages,
				data: data.items,
				page: page,
			},
		})
	} catch (e) {
		console.log(e)
		notification.error({
			message: 'Failed!',
			description: e.response ? e.response.message : 'Unknown Error',
		})
		dispatch({
			type: GET_MANAGE_VOLUNTEER_FAIL,
			payload: {
				errorMessage: e,
			},
		})
	}
}

export const getManageVolunteerReward = (projectId, page) => async (dispatch) => {
	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')

		const response = await axios.get(`${BENEVOLENCE_HOST}/manage-volunteer`, {
			headers: {
				...headers,
				Authorization: `Bearer ${jwt}`,
			},
			params: {
				project: projectId || '',
				page: page,
				status: 'FINALIZED',
				paid_with: 'IBFx'
			},
		})

		const { data } = response.data

		dispatch({
			type: SET_VOLUNTEER_REWARD,
			payload: {
				total: data.total_items,
				hasNext: page < data.total_pages,
				data: data.items,
				page: page,
			},
		})
	} catch (e) {
		console.log(e)
		notification.error({
			message: 'Failed!',
			description: e.response ? e.response.message : 'Unknown Error',
		})
	}
}

export const getAllVolunteer = (type, page) => async (dispatch) => {
	dispatch({
		type: GET_ALL_VOLUNTEER,
	})

	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')

		const response = await axios.get(`${BENEVOLENCE_HOST}/projects`, {
			headers: {
				...headers,
				Authorization: `Bearer ${jwt}`,
			},
			params: {
				type: type,
				page: page,
			},
		})

		const { data } = response.data

		const _filtered = data.items.filter(
			(e) => e.status === PROJECT_STATUS.OPENED && new Date(e.end_at * 1000) > new Date()
		)

		dispatch({
			type: GET_ALL_VOLUNTEER_SUCCESS,
			payload: {
				total: data.total_items,
				hasNext: page < data.total_pages,
				data: _filtered,
				page: page,
			},
		})
	} catch (e) {
		console.log(e)
		notification.error({
			message: 'Failed!',
			description: e.response ? e.response.message : 'Unknown Error',
		})
		dispatch({
			type: GET_ALL_VOLUNTEER_FAIL,
			payload: {
				errorMessage: e,
			},
		})
	}
}

export const getVolunteered = (page) => async (dispatch) => {
	dispatch({
		type: GET_VOLUNTEERED,
	})

	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')

		const response = await axios.get(`${BENEVOLENCE_HOST}/dashboard/volunteered-projects`, {
			headers: {
				...headers,
				Authorization: `Bearer ${jwt}`,
			},
			params: {
				page: page,
			},
		})

		const { data } = response.data

		dispatch({
			type: GET_VOLUNTEERED_SUCCESS,
			payload: {
				total: data.total_items,
				hasNext: page < data.total_pages,
				data: data.items,
				page: page,
			},
		})
	} catch (e) {
		console.log(e)
		notification.error({
			message: 'Failed!',
			description: e.response ? e.response.message : 'Unknown Error',
		})
		dispatch({
			type: GET_ALL_VOLUNTEER_FAIL,
			payload: {
				errorMessage: e,
			},
		})
	}
}

export const uploadFile = async (file) => {
	try {
		const formData = new FormData()
		formData.append('file', file, file.name)
		const response = (
			await axios.post(`${BENEVOLENCE_HOST}/files`, formData, {
				headers: benevolenceHeaders,
			})
		).data
		return response.data
	} catch (e) {
		throw e.response ? (e.response.data ? e.response.data : e.response) : e
	}
}

export const getCountries = async () => {
	try {
		const result = (
			await axios.get(`${BENEVOLENCE_HOST}/countries`, {
				headers: benevolenceHeaders,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getCities = async (countryId) => {
	try {
		const result = (
			await axios.get(`${BENEVOLENCE_HOST}/cities/get-by-country/${countryId}`, {
				headers: benevolenceHeaders,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getStates = async (countryId) => {
	try {
		const result = (
			await axios.get(`${BENEVOLENCE_HOST}/states/get-by-country/${countryId}`, {
				headers: benevolenceHeaders,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getProjectById = async (projectId) => {
	try {
		const result = (
			await axios.get(`${BENEVOLENCE_HOST}/projects?project_id=${projectId}`, {
				headers: benevolenceHeaders,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const registerVolunteer = async (request) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${BENEVOLENCE_HOST}/volunteers`, request, {
				headers,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const volunteerDocuments = async (request) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${BENEVOLENCE_HOST}/volunteers/add-docs`, request, {
				headers,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const projectVolunteerValidation = async (request) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${BENEVOLENCE_HOST}/project-volunteers/validate`, request, {
				headers,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const projectVolunteerReview = async (request) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${BENEVOLENCE_HOST}/project-volunteers/review`, request, {
				headers,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const projectVolunteerChoosePayment = async (request) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${BENEVOLENCE_HOST}/project-volunteers/choose-payment`, request, {
				headers,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const projectVolunteerSendReward = async (request) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${BENEVOLENCE_HOST}/project-volunteers/reward`, request, {
				headers,
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getVolunteerById = async (volunteerId) => {
	try {
		// const headers = {
		//   Authorization: `Bearer ${user.token}`,
		// }
		const result = (await axios.get(`${BENEVOLENCE_HOST}/volunteers/${volunteerId}`)).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getProjectVolunteer = async (project_id, volunteer_id) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${BENEVOLENCE_HOST}/project-volunteers/get-by-project-and-volunteer`, null, {
				headers,
				params: { project_id, volunteer_id },
			})
		).data
		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getUserIsVolunteer = () => async (dispatch) => {
	try {
		const decoded = jwtDecode(localStorage.getItem('USER_AUTH_TOKEN'))

		const result = (
			await axios.get(`${BENEVOLENCE_HOST}/volunteers?user_id=${decoded.user_id}`, {
				headers: benevolenceHeaders,
			})
		).data

		const isVolunteer = result.data ? (result.data.code !== 'DATA_NOT_FOUND' ? true : false) : false

		dispatch({
			type: SET_VOLUNTEER,
			payload: {
				data: isVolunteer,
				userVolunteer: result.data,
			},
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getUserDataById = (userId, callback) => async (dispatch) => {
	try {
		const decoded = jwtDecode(localStorage.getItem('USER_AUTH_TOKEN'))
		const user = (
			await axios.get(`${BENEVOLENCE_HOST}/users/${userId || decoded.user_id}`, {
				headers: benevolenceHeaders,
			})
		).data
		if (callback) {
			return user.data
		} else {
			dispatch({
				type: SET_USER_BENEVOLENCE,
				payload: {
					data: user.data,
				},
			})
		}
	} catch (e) {
		console.log(e)
	}
}

export const registerUserBenevolence = async (algoAddress) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(
				`${BENEVOLENCE_HOST}/users`,
				{ algo_address: algoAddress },
				{
					headers: headers,
				}
			)
		).data

		return result.data
	} catch (e) {
		console.log(e)
		throw e.message
	}
}
