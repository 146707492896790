import axios from "axios";
import { CREDENCE_HOST, IS_EVM } from "../../configs";
import {
  SET_ESSENCE,
  SET_DETAIL_ESSENCE,
  SET_PRICE_HISTORY_ESSENCE,
  SET_ESSENCE_PURCHASES,
  UPDATE_ESSENCE_WISHLIST,
} from "../constants";
import { signingOperation } from "../wallet/action";

export const getAllEssence =
  (userId, page = 1, search, collectionId, category = null, callback) =>
  async (dispatch) => {
    try {
      const jwt = localStorage.getItem("USER_AUTH_TOKEN");
      let url = `${CREDENCE_HOST}/sell_escrow?page=${page}&size=12`;
      const fromMarketplace = url;
      if (userId) {
        url += `&owner_id=${userId}`;
      }
      if (search) {
        url += `&search=${search}`;
      }
      if (collectionId) {
        url += `&collection_id=${collectionId}`;
      }
      if (category) {
        url += `&category=${category}`;
      }
      const nfts = (
        await axios.get(url, { headers: { Authorization: `Bearer ${jwt}` } })
      ).data;
      if (callback) {
        return nfts;
      } else {
        dispatch({
          type: SET_ESSENCE,
          payload: {
            data: nfts.data.records,
            currentPage: page,
            hasNext: page * 12 < nfts.data.total_items,
            total: nfts.data.total_items,
            fromMarketplace: fromMarketplace === url,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getAllEvmEssence =
  ({ ownerAddress, sellerAddress, page = 1, status }) =>
  async (dispatch) => {
    try {
      let url = `${CREDENCE_HOST}/evm-nft-transaction?page=${page}&size=12&status=ON_SALE`;
      const fromMarketplace = url;
      if (ownerAddress) {
        url += `&owner_address=${ownerAddress}`;
      }
      if (sellerAddress) {
        url += `&seller_address=${sellerAddress}`;
      }

      const nfts = (await axios.get(url)).data;

      dispatch({
        type: SET_ESSENCE,
        payload: {
          data: nfts.data.records.rows,
          currentPage: page,
          hasNext: page * 12 < nfts.data.total_items,
          total: nfts.data.total_items,
          fromMarketplace: fromMarketplace === url,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const setDetailNFT = (data) => async (dispatch) => {
  // console.log({ data })
  const metadata = JSON.parse(data.NFT.metadata_json);
  // console.log({ ...data, metadata })

  dispatch({
    type: SET_DETAIL_ESSENCE,
    payload: { ...data, metadata },
  });
};

export const getDetailEssence = (sell_escrow_id) => async (dispatch) => {
  try {
    const nfts = (
      await axios.get(
        `${CREDENCE_HOST}/${
          IS_EVM ? "evm-nft-transaction" : "sell_escrow"
        }/${sell_escrow_id}`
      )
    ).data;
    dispatch(setDetailNFT(IS_EVM ? nfts.data : nfts.data.sellEscrowData));
  } catch (e) {
    console.log(e);
  }
};

export const getPriceHistory =
  (nft_id, startDate, endDate) => async (dispatch) => {
    try {
      // const jwt = localStorage.getItem("USER_AUTH_TOKEN");
      let result;
      let priceHistory;

      if (IS_EVM) {
        result = (
          await axios.get(`${CREDENCE_HOST}/evm-nft/price-history/${nft_id}`, {
            params: {
              start_date: startDate,
              end_date: endDate,
            },
          })
        ).data;
        priceHistory = result?.data;
      } else {
        result = (
          await axios.get(`${CREDENCE_HOST}/nft/price_history/${nft_id}`, {
            params: {
              start_date: startDate,
              end_date: endDate,
              type: "BUY",
            },
          })
        ).data;
        priceHistory = result?.data?.nft?.priceHistory;
      }

      dispatch({
        type: SET_PRICE_HISTORY_ESSENCE,
        payload: priceHistory || [],
      });
    } catch (e) {
      console.log(e);
    }
  };

export const getAllEssencePurchase =
  (userId, page = 1, callback) =>
  async (dispatch) => {
    try {
      const jwt = localStorage.getItem("USER_AUTH_TOKEN");
      let url = `${CREDENCE_HOST}/transactions/purchased?user_id=${userId}`;
      const results = (
        await axios.get(url, { headers: { Authorization: `Bearer ${jwt}` } })
      ).data;

      let data = [];
      results.data.nfts.forEach((trx) => {
        if (!data.find((i) => i.nft.nft_id === trx.nft.nft_id)) {
          data.push(trx);
        }
      });

      if (callback) {
        return results.data;
      } else {
        dispatch({
          type: SET_ESSENCE_PURCHASES,
          payload: {
            data,
            currentPage: page,
            // hasNext: page * 15 < results.data.total_items,
            // total: results.data.total_items,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const postBuyNFT = async (mnemonic, data) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.post(`${CREDENCE_HOST}/sell_escrow/buy`, data, {
        headers: headers,
      })
    ).data;

    const signedOperations = await signingOperation(mnemonic, result.data);
    await axios.post(
      `${CREDENCE_HOST}/operations/submit_signature`,
      signedOperations,
      {
        headers: headers,
      }
    );
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const updateWishlistEssence = (course) => (dispatch) => {
  dispatch({
    type: UPDATE_ESSENCE_WISHLIST,
    payload: {
      ...course,
    },
  });
};

export const sellEVMNft = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("USER_AUTH_TOKEN")}`,
    };
    const result = (
      await axios.post(`${CREDENCE_HOST}/evm-nft-transaction`, data, {
        headers: headers,
      })
    ).data;

    return result;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};
