import React, { useContext, useState } from 'react'
import { Modal, Typography, Drawer, Alert, Row, Col, Progress, Button } from 'antd'
import ReactCodeInput from 'react-code-input'
import { useDispatch, useSelector } from 'react-redux'
import { ColCenter, RowCenter, ButtonStyled, ButtonPeraConnect, ColSpaceBetween } from '../general_styled'
import { ContentStyled, StepContentStyled } from './styled'
import closeIcon from '../../assets/images/close.svg'
import useWindowDimensions from '../../utils/windowDimensions'
import { decryptMnemonic, toIBFx } from '../../utils/utils'
import { COMMON_CONSTANT } from '../../constants/constant_common'
import { PerawalletContext } from '../../context/perawallet-context'
import { TRANSACTION_TYPE } from '../../constants/constant_wallet'
import { apiSignedOperation, createOperations, createOrder } from '../../modules/wallet/action'
import { closeCart, removeCart } from '../../modules/course_cart/action'
import {
	donateToProjectWithPera,
	donateToWaqfPoolWithPera,
	submitWalletSignedOperation,
} from '../../modules/benevolence_projects/action'

const { Title, Text } = Typography

const mappingPerawalletPercent = {
	0: 100,
	1: 10,
	2: 30,
	3: 60,
	4: 80,
	5: 100,
}

const mappingPerawalletProcess = {
	0: 'Rejected by Network',
	1: 'Creating Transactions',
	2: 'Signing Transaction',
	3: 'Pending Call Request, waiting confirmation on wallet',
	4: 'Submit Transaction to Network',
	5: 'Waiting for network to mine transaction',
}

export default function ModalCourseWallet(props) {
	const windowDimensions = useWindowDimensions()

	const walletContext = useContext(PerawalletContext)

	const dispatch = useDispatch()
	const [current, setCurrent] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [pinCode, setPinCode] = useState('')
	const [isPinError, setPinError] = useState(false)
	const [perawalletProcess, setPerawalletProcess] = useState(0)

	const walletUser = useSelector((state) => state.getIn(['wallet', 'user']).toJS())
	const cart = useSelector((state) => state.getIn(['courseCart', 'cart']).toJS())

	const codeProps = {
		className: 'reactCodeInput',
		inputStyle: {
			fontFamily: 'Roboto',
			fontWeight: 500,
			MozAppearance: 'textfield',
			width: windowDimensions.width > 835 ? '5rem' : '16vw',
			height: windowDimensions.width > 835 ? '5rem' : '16vw',
			margin: '4px',
			fontSize: '40px',
			paddingLeft: '7px',
			backgroundColor: '#FBFBFB',
			color: '#828CA0',
			border: '1px solid #EDEFF2',
			borderRadius: '2px',
			textAlign: 'center',
		},
		inputStyleInvalid: {
			fontFamily: 'Roboto',
			fontWeight: 500,
			MozAppearance: 'textfield',
			width: windowDimensions.width > 835 ? '4rem' : '18vw',
			height: windowDimensions.width > 835 ? '4rem' : '18vw',
			margin: '4px',
			fontSize: '40px',
			paddingLeft: '7px',
			backgroundColor: '#FBFBFB',
			color: 'red',
			border: '1px solid red',
			borderRadius: '2px',
		},
	}

	const checkPin = () => {
		try {
			const getMnemonic = localStorage.getItem(COMMON_CONSTANT.ENCRYPTED_MNEMONIC)
			const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode)
			if (_decryptMnemonic) {
				return true
			} else {
				return false
			}
		} catch (e) {
			console.log(e)
		}
	}

	const onSubmitPerawalletTransfer = async () => {
		try {
			const getMnemonic = localStorage.getItem(COMMON_CONSTANT.ENCRYPTED_MNEMONIC)
			const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode)

			setPerawalletProcess(1)
			let operations
			if (props.type === 'waqf') {
				setPerawalletProcess(2)
				const body = {
					user_id: walletUser.user_id,
					amount: toIBFx(props.totalPayment),
					walletconnect_address: walletContext.address,
				}
				operations = await donateToWaqfPoolWithPera(body)
			} else if (props.type === 'donation') {
				setPerawalletProcess(2)
				const body = {
					user_id: walletUser.user_id,
					project_id: props.project.project_id,
					amount: toIBFx(props.totalPayment),
					walletconnect_address: walletContext.address,
					...props?.behalfDetail
				}
				operations = await donateToProjectWithPera(body)
			} else {
				const order = await createOrder({
					user_id: walletUser.user_id,
					order_items: cart.map((c) => {
						return {
							course_id: c.course_id,
							quantity: 1,
							price: toIBFx(c.price),
						}
					}),
				})

				const body = {
					sender: walletUser.algo_address,
					order_id: order.order_id,
					walletconnect_address: walletContext.address,
					type: TRANSACTION_TYPE.PAY_WITH_WALLETCONNECT,
				}
				setPerawalletProcess(2)
				operations = await createOperations(body)
			}

			setPerawalletProcess(3)
			const signedOperation = await walletContext.signByWalletconnect(operations, _decryptMnemonic)

			setPerawalletProcess(4)
			if (props.type === 'waqf' || props.type === 'donation') {
				await submitWalletSignedOperation(signedOperation)
			} else {
				await apiSignedOperation(signedOperation)
			}

			setPerawalletProcess(5)

			if (props.type === 'waqf' || props.type === 'donation') {
				props.onClose()
			} else {
				for (const course of cart) {
					dispatch(removeCart(course))
				}

				dispatch(closeCart())
			}
			return true
		} catch (e) {
			setPerawalletProcess(0)
			console.log(e)
		}
	}

	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleCancel = () => {
		setPinCode('')
		setPinError('')
		setCurrent(0)
		setIsModalVisible(false)
	}

	const resolveContent = () => {
		switch (current) {
			case 0:
				return (
					<StepContentStyled className="center">
						<ColCenter>
							<Title className="text-w700 gray-7 mb-0px" level={3}>
								Enter pin
							</Title>
							<Text className="gray-7" align="center">
								Please enter your pin code to confirm the transaction
							</Text>
							<Row className="mt-24px">
								<Col span={24}>
									<RowCenter>
										<ReactCodeInput type="password" onChange={(e) => setPinCode(e)} fields={4} {...codeProps} />
									</RowCenter>
								</Col>
								{isPinError && (
									<Col className="mt-10px" span={24}>
										<Alert
											style={{
												width: '90%',
											}}
											message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
											type="error"
											showIcon
										/>
									</Col>
								)}
							</Row>
							<ButtonStyled
								style={{
									marginTop: 20,
									height: 45,
									width: '90%',
								}}
								onClick={() => {
									const _checkPin = checkPin()
									if (_checkPin) {
										setPinError(false)
										setCurrent(2)
										onSubmitPerawalletTransfer()
									} else {
										setPinError(true)
									}
								}}
							>
								{'Confirm Pin'}
							</ButtonStyled>
						</ColCenter>
					</StepContentStyled>
				)
			case 2:
				return (
					<ColSpaceBetween className="stretch">
						<ColCenter>
							<Title className="text-w700 gray-7 mb-6px text-center" level={3}>
								Pay Course
							</Title>
							<Text className="gray-7 text-center mb-24px">Approve or reject request using your wallet</Text>
						</ColCenter>
						<Row className="d-flex">
							<Col span={24}>
								<ContentStyled>
									<ColCenter>
										{/* status={perawalletProcess !== 5 ? "active" : "success"} */}
										<Progress
											type="circle"
											percent={mappingPerawalletPercent[perawalletProcess]}
											status={perawalletProcess == 0 && 'exception'}
										/>
										<Title className="text-w700 gray-7 mb-0px mt-24px" level={4}>
											{perawalletProcess == 0
												? 'Error'
												: perawalletProcess !== 5
												? 'Processing'
												: 'Successfully Submit Transaction'}
										</Title>
										<Text className="gray-7" align="center">
											{mappingPerawalletProcess[perawalletProcess]}
										</Text>
									</ColCenter>
									<Row className="mt-20px">
										<Col span={24} offset={0}>
											<Button type={'primary'} size="large" block onClick={handleCancel}>
												Dismiss
											</Button>
										</Col>
									</Row>
								</ContentStyled>
							</Col>
						</Row>
					</ColSpaceBetween>
				)
		}
	}

	return (
		<>
			<ButtonStyled
				style={{ borderRadius: "10px", }}
				className="m-12px"
				block
				disabled={props.disabled}
				onClick={showModal}
			>
				<span className={props.disabled ? 'text-disable' : 'text'}>Pay With Algo</span>
			</ButtonStyled>
			{windowDimensions.width > 835 ? (
				<Modal
					centered
					visible={isModalVisible}
					onCancel={handleCancel}
					footer={null}
					closeIcon={<img style={{ marginRight: 10 }} src={closeIcon} onClick={handleCancel} />}
				>
					<ContentStyled>{resolveContent()}</ContentStyled>
				</Modal>
			) : (
				<Drawer
					visible={isModalVisible}
					onCancel={handleCancel}
					placement="bottom"
					closeIcon={<img style={{ marginRight: 10 }} src={closeIcon} onClick={handleCancel} />}
				>
					<ContentStyled>{resolveContent()}</ContentStyled>
				</Drawer>
			)}
		</>
	)
}
