import styled from "@emotion/styled";
import { Col } from "antd";

export const DonorProfileStyled = styled(Col)`
  .field-name {
    padding: 0 16px;
  }

  .name {
    cursor: pointer;
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    /* identical to box height */

    /* text-decoration-line: underline; */

    /* Secondary CTA */

    color: #0b0e14;
    margin-bottom: 4px;
  }

  .email {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Secondary Gray */

    color: #3d424d;
  }

  .img-avatar {
    margin-right: 8px;
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
`;
