import styled from "@emotion/styled";
import { Card, Collapse, Steps } from "antd";
import { RowSpaceBetween } from "../../components/general_styled";

const { Step } = Steps;

export const CardStyled = styled(Card)`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  &.black {
    background-color: #024FF1;
    border: 1px solid #024FF1;
    color: white;
  }

  .white {
    color: white;
  }

  &.top {
    padding: 16px 10px;
    // min-height: 268px;

    .ant-card-body {
      // min-height: 268px;
    }
  }

  &.bottom {
    .ant-card-body {
      min-height: 260px;
    }
  }

  &.platform {
    .ant-card-body {
      padding-top: 12px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
    }
  }

  .ant-card-body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-card-cover {
    height: 20%;
    object-fit: cover;
  }

  .ant-image {
    height: 100%;
  }

  .ant-card-cover img {
    border-radius: 4px 4px 0 0;
    height: 100%;
    object-fit: cover;
  }

  &.ant-card-bordered {
    border: 0 !important;
  }
`;

export const StepStyled = styled(Step)`
  &.ant-steps-item-active .ant-steps-item-title {
    color: white !important;
  }

  &.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #434d5d !important;
  }

  &.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #289a67 !important;
  }

  .anticon {
    color: #289a67 !important;
  }

  &.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #a7b1c0;
  }

  &.ant-steps-item-finish .ant-steps-item-icon {
    background: #fff !important;
    border-color: #289a67 !important;
  }

  &.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    background: #fff !important;
  }

  &.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.65) !important;
  }

  &.ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.45) !important;
  }

  &.ant-steps-item-process .ant-steps-item-icon {
    border-color: #289a67 !important;
  }

  &.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #289a67 !important;
  }

  &.ant-steps-item-active .ant-steps-item-icon {
    background: #289a67 !important;
  }

  &.ant-steps-item-wait .ant-steps-item-icon {
    background-color: #434d5d !important;
    border-color: rgba(100, 116, 139) !important;
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: -1.5px !important;
    font-size: 15px;
  }

  &.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #434d5d !important;
  }
`;

export const ImageStyled = styled.img`
  height: 108px;
  align-self: start;
`;

export const EmptyActivityStyled = styled.img`
  height: 115px;
`;

export const CheckStyled = styled.img`
  height: 20px;
  width: 20px;
  object-fit: cover;
  margin-right: 6px;
  margin-top: 1px;
`;

export const IconStyled = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin-bottom: 16px;

  &.activity {
    margin-right: 12px;
    margin-bottom: 0;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const ActivityTitle = styled.div`
  /* Paragraph 01/Regular */

  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Text/400 */

  color: #273248;
  margin-bottom: 4px;
`;
export const ActivityTime = styled.div`
  /* Footer/ All Caps */

  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text/300 */

  color: #44567b;
`;

export const ImportantBadge = styled.div`
  background: #def6eb;
  border-radius: 4px;
  padding: 4px 12px;
  margin-left: 16px;

  /* Footer/ All Caps */

  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Shades/White */

  color: #289a67;
`;

export const CollapseStyled = styled(Collapse)`
  & .ant-collapse {
    background-color: #fff !important;
    border-radius: 10px !important;
    border: 0 !important;
    box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
      rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  }
`;

export const HomeBannerStyled = styled(RowSpaceBetween)`
  padding: ${(props) => (props.isDekstop ? "0px 48px" : "24px 24px")};
  gap: 8px;
  background-size: cover;
  background-image: url(${(props) => props.img});
  border-radius: 6px;
  margin-top: 12px;
  min-height: ${(props) => (props.isDekstop ? "348px" : "200px")};

  .title {
    font-family: Inter;
    font-size: ${(props) => (props.isDekstop ? "35px" : "20px")};
    font-weight: 500;
    line-height: ${(props) => (props.isDekstop ? "42px" : "20px")};;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-bottom: 12px;
  }

  .desc {
    font-family: Inter;
    font-size: ${(props) => (props.isDekstop ? "18px" : "14px")};;
    font-weight: 300;
    line-height: ${(props) => (props.isDekstop ? "25px" : "18px")};
    color: #F0F2F4;
  }

  .action {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 183% */

    /* BG White */

    color: #fcfdff;
  }
`;
