import React, { useState, useEffect } from "react";
import { ColCenter } from "../../components/general_styled";
import { CardStyled, CanvasCenter, ButtonStyled, Divider } from "./styled";
// import BenevLogo from "../../assets/images/benevolence-logo.png"
import { Typography, Col, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSection } from "../../modules/general/action";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getCredenceUserById,
  registerUserCredence,
} from "../../modules/credence/action";

const { Title, Text } = Typography;
export default function CourseOnBoarding() {
  const dispatch = useDispatch();
  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );

  const [isLoading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const onButtonClicked = async () => {
    dispatch(setSection("wallet-dashboard"));
  };

  useEffect(() => {
    dispatch(getCredenceUserById(walletUser.user_id));
  }, []);

  return (
    <CanvasCenter>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <CardStyled>
          <ColCenter className="start">
            <Title
              align="left"
              level={3}
              style={{
                fontWeight: "bold",
                margin: 0,
                marginBottom: 8,
                fontSize: 26,
              }}
            >
              Welcome to Excellence <br />
            </Title>
            <Text align="left" level={5} style={{ margin: 0 }}>
              {/* You may enroll in any or all of the courses below by paying the USD price. As a member of IBF Net, the
							Islamic Business and Finance Network, you may enroll in any of the courses below. However, if you pay
							using IBFN (the Network’s native token) after creating and topping up your wallet, you may save up to 80
							percent of the fee In this case however, the certification for the courses will be issued by the
							Netversity alone without further validation by MGNU or any of our partner universities. */}
              You can enroll in the courses using either USD or IBFX. Enrolling
              with USD will require payment of the full price, while enrolling
              with IBFX will grant you an 80% discount
            </Text>
            <Text
              align="left"
              italic
              style={{
                fontSize: 13,
                margin: 0,
                marginTop: 12,
                color: "red",
              }}
            >
              Requirements: To pay with IBFX you need to activate the IBFX in
              Wallet
            </Text>
            <ButtonStyled size="large" block onClick={onButtonClicked}>
              {isLoading ? antIcon : "Start Using Credence"}
            </ButtonStyled>
          </ColCenter>
        </CardStyled>
      </Col>
    </CanvasCenter>
  );
}
