import styled from "@emotion/styled";
import { Card } from "antd";

export const CardStyled = styled(Card)`
  margin: 12px;

  background: #ffffff;
  /* Neutral/700 */

  border: 1px solid #a7b1c0;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;

  .ant-card-body {
    padding: 20px;
  }

  .ant-card-cover {
    padding: 8px;
    aspect-ratio: 1/1;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  &.selected {
    border: 1px solid #024FF1;

    transition: all 0.3s ease;
    box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
  }

  &:hover {
    border: 1px solid #024FF1;

    transition: all 0.3s ease;
    box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
  }

  @media screen and (max-width: 834px) {
    .ant-card-cover {
      padding: 8px;
      aspect-ratio: 1/1;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
  }
`;

export const CollectionNameStyled = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text/300 */

  color: #42556e;
  margin-bottom: 6px;

  &.no-collection {
    color: #42556e53;
    font-style: italic;
  }
`;

export const NFTNameStyled = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Text/500 */

  color: #293544;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  min-height: 40px;
`;
