import React from "react";
import { Col, Row, Typography, Popover } from "antd";
import { useTranslation } from "react-i18next";
import {
  CardStyled,
  BalanceSection,
  LearnMoreButton,
  LabelStyled,
  ValueStyled,
  IconCoins,
} from "./styled";
import {
  RowSpaceBetween,
  RowCenter,
  RowStart,
  ColCenter,
  ColStart,
} from "../general_styled";
import { fromIBFx } from "../../utils/utils";
import ModalWallet from "../ModalWallet";
import ModalActiveAssets from "../ModalActivateAssets";
import ConfluenceIcon from "../../assets/images/confluence-wallet.svg";
import BenevolenceIcon from "../../assets/images/benevolence-wallet.svg";
import RemoveIcon from "../../assets/images/remove-red.svg";
import CheckIcon from "../../assets/images/check-green-small.svg";
import ibfx1 from "../../assets/images/ibfx-1.svg";
import ibfx2 from "../../assets/images/ibfx-2.svg";
import ibfx3 from "../../assets/images/ibfx-3.svg";
import ibfx4 from "../../assets/images/ibfx-4.svg";
import ibfs from "../../assets/images/ibfs.svg";
import ibfn from "../../assets/images/ibfx-1.svg";
import {
  ABILITIES_ASSETS,
  ASSETS_NAME,
  ASSETS_STATUS,
} from "../../constants/constant_wallet";
import useWindowDimensions from "../../utils/windowDimensions";

const { Title, Text } = Typography;

export default function CardBalance(props) {
  const windowDimensions = useWindowDimensions();

  const { t } = useTranslation();

  const resolveFooter = () => {
    if (props.data.status === ASSETS_STATUS.INACTIVE || !props.data.status) {
      return <ModalActiveAssets data={props.data} />;
    } else if (
      props.data.status === ASSETS_STATUS.PENDING ||
      props.type === "overview"
    ) {
      return <></>;
    } else {
      return <ModalWallet type={props.type} data={props.data} />;
    }
  };

  const resolveIcon = () => {
    switch (props.data.asset_name) {
      case ASSETS_NAME.IBFS:
        return <img src={BenevolenceIcon} height="40px" />;
      case ASSETS_NAME.IBFX_TYPE_I:
        return;
      // <img src={IbfnexIcon} height="20px" />
      case ASSETS_NAME.IBFX_TYPE_II:
        return;
      // <img src={IbfnexIcon} height="20px" />
      case ASSETS_NAME.IBFX_TYPE_III:
        return <img src={BenevolenceIcon} height="40px" />;
      case ASSETS_NAME.IBFX_TYPE_IV:
        return <img src={ConfluenceIcon} height="40px" />;
    }
  };

  const resolveCoins = () => {
    switch (props.data.asset_name) {
      case ASSETS_NAME.IBFS:
        return <IconCoins src={ibfs} />;
      case ASSETS_NAME.IBFX_TYPE_I:
        return <IconCoins src={ibfx1} />;
      case ASSETS_NAME.IBFX_TYPE_II:
        return <IconCoins src={ibfx2} />;
      case ASSETS_NAME.IBFX_TYPE_III:
        return <IconCoins src={ibfx3} />;
      case ASSETS_NAME.IBFX_TYPE_IV:
        return <IconCoins src={ibfx4} />;
      case ASSETS_NAME.IBFN:
        return <IconCoins src={ibfn} />;
    }
  };

  const resolveName = () => {
    switch (props.data.asset_name) {
      case ASSETS_NAME.IBFS:
        return "IBFS";
      case ASSETS_NAME.IBFN:
        return "IBFN";
      case ASSETS_NAME.IBFX_TYPE_I:
        return "IBFX";
      case ASSETS_NAME.IBFX_TYPE_II:
        return "IBFX - 2";
      case ASSETS_NAME.IBFX_TYPE_III:
        return "IBFX - 3";
      case ASSETS_NAME.IBFX_TYPE_IV:
        return "IBFX - 4";
    }
  };

  const resolveAbilities = () => {
    if (props.data.asset_name === ASSETS_NAME.IBFS) {
      return (
        <Text className="primary text-size-12">{t("wallet-ability-1")}</Text>
      );
    }

    let abilities = [
      <RowStart className="align-start">
        <img
          src={RemoveIcon}
          style={{
            paddingRight: 10,
          }}
        />
        <Text className="dark-blue text-size-12">{t("wallet-ability-2")}</Text>
      </RowStart>,
      <RowStart className="align-start">
        <img
          src={RemoveIcon}
          style={{
            paddingRight: 10,
          }}
        />
        <Text className="text-size-12">{t("wallet-ability-3")}</Text>
      </RowStart>,
      <RowStart className="align-start">
        <img
          src={RemoveIcon}
          style={{
            paddingRight: 10,
          }}
        />
        <Text className="text-size-12">{t("wallet-ability-4")}</Text>
      </RowStart>,
      <RowStart className="align-start">
        <img
          src={RemoveIcon}
          style={{
            paddingRight: 10,
          }}
        />
        <Text className="text-size-12">{t("wallet-ability-5")}</Text>
      </RowStart>,
    ];

    props.data.abitilites.forEach((e) => {
      if (e.abilityType === ABILITIES_ASSETS.TOPUP) {
        abilities[0] = (
          <RowStart className="align-start">
            <img
              src={CheckIcon}
              style={{
                paddingRight: 10,
              }}
            />
            <Text className="text-size-12">{t("wallet-ability-2")}</Text>
          </RowStart>
        );
      } else if (e.abilityType === ABILITIES_ASSETS.TRANSFER) {
        abilities[1] = (
          <RowStart className="align-start">
            <img
              src={CheckIcon}
              style={{
                paddingRight: 10,
              }}
            />
            <Text className="text-size-12">{t("wallet-ability-3")}</Text>
          </RowStart>
        );
      } else if (e.abilityType === ABILITIES_ASSETS.CONVERT) {
        abilities[2] = (
          <RowStart className="align-start">
            <img
              src={CheckIcon}
              style={{
                paddingRight: 10,
              }}
            />
            <Text className="text-size-12">{t("wallet-ability-4")}</Text>
          </RowStart>
        );
      } else if (e.abilityType === ABILITIES_ASSETS.VOUCHER) {
        abilities[3] = (
          <RowStart className="align-start">
            <img
              src={CheckIcon}
              style={{
                paddingRight: 10,
              }}
            />
            <Text className="text-size-12">{t("wallet-ability-5")}</Text>
          </RowStart>
        );
      }
    });

    return props.data.asset_name !== ASSETS_NAME.IBFX_TYPE_IV
      ? abilities
      : null;
  };

  return (
    <Col
      xs={24}
      sm={24}
      md={windowDimensions.width > 925 ? 12 : 24}
      lg={12}
      xl={8}
      xxl={6}
    >
      <CardStyled>
        <RowSpaceBetween className="start">
          <div>
            <Row>
              {resolveCoins()}
              <Title level={4} className="gray-8" style={{ marginBottom: 0 }}>
                {resolveName()}
              </Title>
            </Row>
            <Row>
              <Popover
                //padding content 24px, font-color: dark blue, popper-inner borderradius 10px
                placement="bottomLeft"
                title={"What is " + resolveName() + "?"}
                content={
                  <div style={{ width: 200 }}>
                    {props.data.asset_name !== ASSETS_NAME.IBFS &&
                      (props.data.asset_name === ASSETS_NAME.IBFX_TYPE_IV ? (
                        <Text style={{ fontSize: 13 }}>
                          {t("wallet-label-IBFX-4")}
                        </Text>
                      ) : (
                        <Text
                          className="pt-24px"
                          style={{ fontSize: 13, color: "#0c2c49" }}
                        >
                          {/* {t('wallet-use')} {resolveName()} {t('wallet-transaction-across')} */}
                          {t("wallet-transaction")}
                        </Text>
                      ))}
                    {/* <div className={props.data.asset_name !== ASSETS_NAME.IBFS && 'mt-10px'}>{resolveAbilities()}</div> */}
                    {props.data.asset_name == ASSETS_NAME.IBFS && (
                      <div>
                        <Text className="text-size-12">
                          {t("wallet-ability-1")}
                        </Text>
                      </div>
                    )}
                  </div>
                }
                trigger="click"
              >
                <LearnMoreButton underline className="gray-7">
                  {t("wallet-label-learn-more")}
                </LearnMoreButton>
              </Popover>
            </Row>
          </div>
          <ColStart className="mt-4px mr-4px">{resolveIcon()}</ColStart>
        </RowSpaceBetween>
        <BalanceSection>
          {(!props.data.status ||
            props.data.status === ASSETS_STATUS.INACTIVE) && (
            <RowCenter className="align-center" style={{ minHeight: 30 }}>
              <Text italic className="secondary text-size-12">
                {t("wallet-label-need-active-token")}
              </Text>
            </RowCenter>
          )}
          {props.data.status && props.data.status === ASSETS_STATUS.PENDING && (
            <RowCenter
              className="px-12px w-100 align-center"
              style={{ minHeight: 30 }}
            >
              <Text align="center" italic className="text-size-12 secondary">
                {t("wallet-label-processing")}
              </Text>
            </RowCenter>
          )}
          {props.data.status && props.data.status === ASSETS_STATUS.ACTIVE && (
            <Row>
              <Col span={props.data.asset_name !== ASSETS_NAME.IBFS ? 12 : 24}>
                <ColCenter>
                  <LabelStyled>{t("wallet-label-balance")}</LabelStyled>
                  <ValueStyled>{fromIBFx(props.data?.amount || 0)}</ValueStyled>
                </ColCenter>
              </Col>
              {props.data.asset_name !== ASSETS_NAME.IBFS && (
                <Col span={12}>
                  <ColCenter>
                    <LabelStyled>{t("wallet-label-transactions")}</LabelStyled>
                    <ValueStyled>
                      {props.data.total_activity ||
                        props.data.total_transaction_success}
                    </ValueStyled>
                  </ColCenter>
                </Col>
              )}
            </Row>
          )}
        </BalanceSection>
        {/* <DividerCard /> */}
        {/* {props.data.status ? (
          <RowSpaceBetween>
            <Title
              level={5}
              className="secondary"
              style={{ marginBottom: 0, lineHeight: 1.7 }}
            >
              Balance
            </Title>
            <Title
              level={4}
              className="gray-8"
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              {fromIBFx(props.data.amount)}
            </Title>
          </RowSpaceBetween>
        ) : (
          <RowCenter>
            <Text italic className="secondary">
              You need to activate this token
            </Text>
          </RowCenter>
        )} */}
        {/* <DividerCard /> */}
        <Row className="mt-20px">
          <Col span={24}>
            <RowCenter>{resolveFooter()}</RowCenter>
          </Col>
        </Row>
      </CardStyled>
    </Col>
  );
}
