import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Col,
  Row,
  Alert,
  Select,
  Divider,
  Switch,
  Progress,
  notification,
} from "antd";
// import { useDispatch } from "react-redux"
import { LoadingOutlined } from "@ant-design/icons";
import {
  ColCenter,
  RowStart,
  ColStart,
  RowCenter,
  InputNumberStyled,
  ButtonStyled,
  SelectStyled,
  DatePickerStyled,
  TextAreaStyled,
  RowSpaceBetween,
  RowEnd,
  InputStyled,
} from "../general_styled";
import { IconCoins, ContentStyled, TotalFee } from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import ibfx1 from "../../assets/images/ibfx-icon.svg";
// import { extractErrorMessage } from "../../utils/extractErrorMessage"
import nftLock from "../../assets/images/sell-nft-lock.svg";
import ReactCodeInput from "react-code-input";
import { decryptMnemonic, toIBFx } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { createSellEscrow } from "../../modules/credence/action";
import { createAuction } from "../../modules/affluence/action";
import { useDispatch, useSelector } from "react-redux";
import { setSection } from "../../modules/general/action";
import {
  CREDENCE_HOST,
  IS_EVM,
  chainIdPolygon,
  nftMarketplaceAddress,
  paymentList,
} from "../../configs";
import { useAccount, useSignTypedData } from "wagmi";
import { sellEVMNft } from "../../modules/essence/action";

const { Title, Text } = Typography;

const domainSig = {
  name: "IBFNFTMarketplace",
  version: "1",
  chainId: String(chainIdPolygon),
  verifyingContract: nftMarketplaceAddress,
};
const typesSig = {
  SetData: [
    { name: "seller", type: "address" },
    { name: "contractPayment", type: "address" },
    { name: "contractNFT", type: "address" },
    { name: "tokenId", type: "uint256" },
    { name: "price", type: "uint256" },
    { name: "royaltyAddress", type: "address" },
    { name: "royaltyFee", type: "uint256" },
    { name: "startEpoch", type: "uint256" },
    { name: "endEpoch", type: "uint256" },
  ],
};

export default function ModalSellNFTDetail(props) {
  const dispatch = useDispatch();
  const { address } = useAccount();

  const { isEssence, metadata, data } = props;
  const windowDimensions = useWindowDimensions();
  // const dispatch = useDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contentToogle, setContentToogle] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isPinError, setPinError] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [mode, setMode] = useState("confirmation");
  const [price, setPrice] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [content, setContent] = useState(null);
  const [isSuccess, setIsSuccess] = useState(true);
  const [processingPercent, setProcessingPercent] = useState(10);

  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );
  const [paymentType, setPaymentType] = useState(null);
  const [messageSig, setMessageSig] = useState({
    seller: address,
    contractPayment: null, // if payment use ether, set value address null. But if use another token, set value contract address token
    contractNFT: data?.contract_address, // set value contract nft for sell
    tokenId: data?.token_id?.toString() || "0", // if tokenId not exist, use 0 (zero) for lazy minting
    price: "0",
    royaltyAddress: data?.creator_wallet_address, // set value from royalty address received database (owner contract nft, not only own nft)
    royaltyFee: Number(data?.royalty_fee * 100).toFixed(0), // if fee is 1%, set value is 100
    startEpoch: 0, // use epoch time
    endEpoch: 0,
  });

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSigChange = (key, value) => {
    setMessageSig({ ...messageSig, [key]: value });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsLoading(false);
    setMode(false);
    setProcessing(false);
    setMode("confirmation");
    setPrice(null);
    setProcessingPercent(10);
    setQuantity(null);
    setContent(null);
    setPaymentType(null);
    setMessageSig(null);
    reset();
    props.handleCancel();
  };

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      if (_decryptMnemonic) {
        return { isError: true, decryptMnemonic: _decryptMnemonic };
      } else {
        return { isError: false, decryptMnemonic: _decryptMnemonic };
      }
    } catch (e) {
      console.log(e);
      return { isError: false, decryptMnemonic: null };
    }
  };

  const onSubmit = async () => {
    setPinError(false);
    const _check = checkPin();
    if (!_check.isError) {
      setPinCode("");
      return setPinError(true);
    }

    setProcessing(true);
    try {
      if (isEssence) {
        const body = {
          nft_id: metadata.nft_id,
          price: toIBFx(price),
          content: content,
          quantity,
        };
        await createSellEscrow(_check.decryptMnemonic, body);
      } else {
        await createAuction(_check.decryptMnemonic);
      }
      // message.success(isEssence ? "NFT has been listed": )
      setMode("success");
      setProcessingPercent(100);
      setProcessing(false);
      setIsSuccess(true);
    } catch (error) {
      setProcessing(false);
      setProcessingPercent(0);
      setProcessing(false);
      setIsSuccess(false);
    }

    setIsLoading(false);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  useEffect(() => {
    if (!isEssence) {
      setMode("pin");
    }
  }, [isEssence]);

  const {
    data: dataSellNft,
    isSuccess: isSuccessSellNft,
    isLoading: isLoadingSellNft,
    reset,
    signTypedData,
  } = useSignTypedData({
    domain: domainSig,
    value: messageSig,
    primaryType: "SetData",
    types: typesSig,
  });

  useEffect(() => {
    if (dataSellNft) {
      const body = {
        nft_id: data?.nft_id,
        sub_id: data?.sub_id || null,
        seller_address: address,
        is_creator: address === data?.creator_wallet_address,
        contract_payment: messageSig?.contractPayment,
        price: messageSig?.price,
        royalty_address: messageSig?.royaltyAddress,
        royalty_fee: messageSig?.royaltyFee,
        content: contentToogle ? content : null,
        signature_hash: dataSellNft,
        epoch_start: messageSig?.startEpoch,
        epoch_end: messageSig?.endEpoch,
      };
      sellEVMNft(body);
      setMode("success");
      setProcessingPercent(100);
      setProcessing(false);
      setIsSuccess(true);
    }
  }, [isSuccessSellNft]);

  return (
    <>
      <Button
        block
        type={props.isActive ? "primary" : "default"}
        className="mt-24px"
        style={{ height: 48, borderRadius: 6 }}
        onClick={() => {
          props.handleCancel();
          showModal();
        }}
      >
        {isEssence ? "Sell at fixed price" : "Sell using Auctions"}
      </Button>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          className="modal-sell-nft"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          maskStyle={{ backgroundColor: "#fafafa" }}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
          width="60%"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              {props?.data?.cover_file ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "486px",
                    objectFit: "contain",
                  }}
                  src={CREDENCE_HOST + "/files/" + props?.data?.cover_file}
                />
              ) : (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "486px",
                    objectFit: "contain",
                  }}
                  // src="https://upload.wikimedia.org/wikipedia/id/5/56/Harry_potter_deathly_hallows_US.jpg"
                  src={
                    metadata?.image &&
                    `https://ipfs.io/ipfs/${metadata?.image?.replace(
                      "ipfs://",
                      ""
                    )}`
                  }
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <ContentStyled>
                {/* onActivateAssets */}
                {mode === "pin" ? (
                  <>
                    {!isEssence && (
                      <ColCenter>
                        <Title className="text-w700 gray-7 mb-0px" level={3}>
                          Create Listing Slot
                        </Title>
                        <Text className="gray-7" align="center">
                          The blockchain requires manualcreation of a buy/sell
                          slot
                        </Text>
                      </ColCenter>
                    )}
                    <ColCenter style={{ height: "100%" }}>
                      {isEssence && (
                        <Title className="text-w700 gray-7 mb-0px" level={3}>
                          Enter pin
                        </Title>
                      )}
                      <Text className="gray-7" align="center">
                        Please enter your pin code to confirm the transaction
                      </Text>
                      <Row className="mt-24px">
                        <Col span={24}>
                          <RowCenter>
                            <ReactCodeInput
                              type="password"
                              onChange={(e) => setPinCode(e)}
                              fields={4}
                              {...codeProps}
                            />
                          </RowCenter>
                        </Col>
                        {isPinError && (
                          <Col className="mt-10px" span={24}>
                            <Alert
                              message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                              type="error"
                              showIcon
                            />
                          </Col>
                        )}
                      </Row>
                      <ButtonStyled
                        style={{
                          width: "40%",
                          marginTop: 20,
                          marginBottom: 70,
                        }}
                        onClick={() => onSubmit()}
                        disabled={isProcessing}
                      >
                        {!isProcessing ? "Confirm Pin" : antIcon}
                      </ButtonStyled>
                    </ColCenter>
                  </>
                ) : mode === "confirmation" ? (
                  <ColCenter>
                    <Title className="text-w700 gray-7 mb-0px" level={3}>
                      {isEssence ? "Sell on Affluence" : "Sell on Affluence"}
                    </Title>
                    <Text className="gray-7" align="center">
                      Add additional details on your NFT
                    </Text>
                    {IS_EVM ? (
                      <Row gutter={10} className="w-100 mt-24px">
                        <Col span={12}>
                          <SelectStyled
                            showSearch
                            optionFilterProp="children"
                            placeholder={"Payment Type"}
                            onSelect={(val) => {
                              setPaymentType(val);
                              handleSigChange("contractPayment", val);
                            }}
                          >
                            {paymentList.map((i) => {
                              return (
                                <Select.Option value={i.value}>
                                  {i.key}
                                </Select.Option>
                              );
                            })}
                          </SelectStyled>
                        </Col>
                        <Col span={12}>
                          <InputNumberStyled
                            type="number"
                            style={{
                              padding: "0px 0px 0px 12px",
                              height: 40,
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "2px",
                            }}
                            className="w-100"
                            placeholder="Price"
                            disabled={!paymentType}
                            min={0}
                            onChange={(val) => {
                              setPrice(val);
                              handleSigChange(
                                "price",
                                (val * 10 ** 6).toString()
                              );
                            }}
                          />
                        </Col>
                        <Col span={12} className="pt-12px">
                          <DatePickerStyled
                            placeholder="Select Start Date"
                            mode="date"
                            onChange={(val) =>
                              handleSigChange("startEpoch", val.unix())
                            }
                          />
                        </Col>
                        <Col span={12} className="pt-12px">
                          <DatePickerStyled
                            placeholder="Select End Date"
                            mode="date"
                            onChange={(val) =>
                              handleSigChange("endEpoch", val.unix())
                            }
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row gutter={10} className="w-100 mt-24px">
                        <Col span={12}>
                          <InputStyled
                            type="number"
                            prefix={
                              <IconCoins className="no-margin" src={ibfx1} />
                            }
                            suffix={
                              <span>
                                $ {(price / ibfxPerUSD).toFixed(2) || 0}
                              </span>
                            }
                            // style={{ padding: "0px 0px 0px 12px" }}
                            className="w-100"
                            placeholder="0"
                            value={price}
                            // min={0}
                            onChange={(val) =>
                              setPrice(Number(val.target.value))
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <SelectStyled
                            // className="ml-12px"
                            showSearch
                            optionFilterProp="children"
                            placeholder={"Quantity"}
                            onSelect={(val) => setQuantity(val)}
                          >
                            {/* {metadata?.fragmentation?.map(i => ())} */}
                            {[...Array(metadata.fragmentation)].map(
                              (i, key) => (
                                <Select.Option value={key + 1}>
                                  {key + 1}
                                </Select.Option>
                              )
                            )}
                            {/* <Select.Option value={metadata.fragmentation}>{metadata.fragmentation}</Select.Option> */}
                          </SelectStyled>
                        </Col>
                      </Row>
                    )}
                    {!isEssence && (
                      <>
                        <Divider dashed />
                        <Row gutter={10} className="w-100">
                          <Col span={12}>
                            <DatePickerStyled
                              placeholder="Select Start Date"
                              mode="date"
                            />
                          </Col>
                          <Col span={12}>
                            <DatePickerStyled
                              placeholder="Select Start Time"
                              mode="time"
                            />
                          </Col>
                        </Row>
                        <Row gutter={10} className="w-100 mt-12px">
                          <Col span={12}>
                            <DatePickerStyled
                              placeholder="Select End Date"
                              mode="date"
                            />
                          </Col>
                          <Col span={12}>
                            <DatePickerStyled
                              placeholder="Select End Time"
                              mode="time"
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    <Divider dashed />
                    {/* {!IS_EVM && ( */}
                    <>
                      <RowSpaceBetween className="w-100">
                        <RowStart>
                          <img src={nftLock} alt="grid-four" />
                          <ColStart className="ml-8px">
                            <Title
                              className="text-size-14 text-w500 mb-0px mt-0px"
                              level={5}
                              align="left"
                            >
                              Unlockable Content
                            </Title>
                            <Text className="gray-5 text-size-12">
                              Include unlockable content that can only be
                              revealed by the owner of the item.
                            </Text>
                          </ColStart>
                        </RowStart>
                        <Switch
                          className="ml-20px"
                          defaultChecked={false}
                          onChange={(val) => setContentToogle(val)}
                        />
                      </RowSpaceBetween>
                      <TextAreaStyled
                        disabled={!contentToogle}
                        className="mt-24px"
                        placeholder="Enter content (access key code to redeem, link to a file, etc.)"
                        size="large"
                        rows={5}
                        onChange={(val) => setContent(val.target.value)}
                      />
                      <Divider dashed />
                    </>
                    {/* )} */}
                    {/* <TotalFee>
                      <RowSpaceBetween>
                        <Text className="text-w500 gray-6 mb-0px ">
                          Total Fee
                        </Text>
                        <RowEnd className="center">
                          <img src={ibfx1} width={18} className="mt-2px" />
                          <Title
                            className="ml-6px text-w500 gray-6 mb-0px text-size-18"
                            level={5}
                          >
                            100
                          </Title>
                        </RowEnd>
                      </RowSpaceBetween>
                    </TotalFee> */}
                    {/* <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    > */}
                    <Button
                      block
                      disabled={
                        IS_EVM ? !price || !paymentType : !price || !quantity
                      }
                      type="primary"
                      className="mt-24px"
                      size="large"
                      loading={isLoadingSellNft}
                      onClick={() => {
                        if (IS_EVM) {
                          signTypedData();
                        } else {
                          setMode("pin");
                        }
                      }}
                    >
                      {isLoading ? antIcon : `List`}
                    </Button>
                    {/* </div> */}
                  </ColCenter>
                ) : (
                  <ColCenter style={{ height: "100%" }}>
                    <Progress
                      type="circle"
                      percent={processingPercent}
                      status={!isSuccess && "exception"}
                    />
                    <Title
                      className="text-w700 gray-7 mb-8px mt-24px"
                      level={3}
                    >
                      {isProcessing
                        ? "Processing"
                        : isSuccess
                        ? "Success!"
                        : "Failed!"}
                    </Title>
                    <Text
                      style={{ width: "60%" }}
                      className="gray-7"
                      align="center"
                    >
                      {isProcessing
                        ? "Please wait while we processing your transaction"
                        : isSuccess
                        ? isEssence
                          ? "Your NFT has been listed on the auction marketplace!"
                          : "Your auction has been successfully created!"
                        : "An error occured, please try again later"}
                    </Text>
                    {!isProcessing && (
                      <Button
                        block
                        type="primary"
                        className="mt-24px"
                        size="large"
                        style={{
                          width: "350px",
                          height: "48px",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          if (!isEssence) {
                            dispatch(setSection("affluence-auction-listing"));
                          } else {
                            dispatch(setSection("affluence-buy-sell-listing"));
                          }
                        }}
                      >
                        Go to My Listing
                      </Button>
                    )}
                    {!isProcessing && (
                      <ButtonStyled
                        className="mt-24px"
                        type="primary"
                        danger={!isSuccess}
                        style={{
                          width: "350px",
                          height: "48px",
                          borderRadius: "4px",
                        }}
                        onClick={() => handleCancel()}
                      >
                        Dismiss
                      </ButtonStyled>
                    )}
                  </ColCenter>
                )}
              </ContentStyled>
            </Col>
          </Row>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                {isEssence ? "Sell on Affluence" : "Sell on Affluence"}
              </Title>
              <Text className="gray-7" align="center">
                Add additional details on your NFT
              </Text>
              <Row gutter={20} className="w-100 mt-24px">
                <Col span={12}>
                  <InputNumberStyled
                    type="number"
                    prefix={<IconCoins className="no-margin" src={ibfx1} />}
                    style={{ padding: "0px 0px 0px 12px" }}
                    className="w-100"
                    placeholder="Price"
                    min={0}
                  />
                </Col>
              </Row>
              <Button
                block
                disabled
                type="primary"
                className="mt-24px"
                size="large"
                onClick={() => onSubmit()}
              >
                {isLoading ? antIcon : "Continue"}
              </Button>
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
