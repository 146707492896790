import React, { useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Progress,
  Row,
  Col,
  Alert,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  ButtonOutline,
  ColCenter,
  ColStart,
  InputNumberStyled,
  RowEnd,
  RowSpaceBetween,
  RowCenter,
} from "../general_styled";

import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  DividerCard,
  LabelStyled,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import IBFxLogo from "../../assets/images/ibfx-icon.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import { setSection } from "../../modules/general/action";
import ReactCodeInput from "react-code-input";
import { decryptMnemonic, fromIBFx, toIBFx } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { getAllLoans, postClaim } from "../../modules/loans/action";
import jwtDecode from "jwt-decode";

const { Title, Text } = Typography;

export default function ModalLoansClaim(props) {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMode, setIsMode] = useState(0);
  const [pinCode, setPinCode] = useState();
  const [isPinError, setPinError] = useState(false);

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setPinCode();
    setPinError(false);
    setIsModalVisible(false);
  };

  const onSubmit = async (mnemonic) => {
    if (props.isHasCancel) {
      return;
    }
    setIsLoading(true);

    await postClaim(mnemonic, props.record?.loan_id);

    await dispatch(
      getAllLoans(
        jwtDecode(localStorage.getItem("USER_AUTH_TOKEN")).user_id,
        null,
        1
      )
    );

    setIsMode(1);
    // setIsLoading(false)
    // handleCancel()
  };

  const checkPin = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = await decryptMnemonic(getMnemonic, pinCode);
      setPinError(false);
      await onSubmit(_decryptMnemonic);
    } catch (e) {
      console.log(e);
      setPinError(true);
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const getMonthBetween = (startDate, endDate) => {
    var start = moment(startDate);
    var end = moment(endDate);
    var months = end.diff(start, "months");
    if (months <= 0) {
      const weeks = end.diff(start, "weeks");
      if (weeks <= 0) {
        const days = end.diff(start, "days");
        return days + " days";
      }
      return weeks + " weeks";
    }
    return months + " months";
  };

  const resolveSuccess = () => (
    <ColCenter className="h-100" span={24}>
      <Progress type="circle" percent={100} />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        Success!
      </TitleStyled>
      <TextStyled>
        Your loan has been claimed successfully. You can check your loan status
      </TextStyled>
      <Button
        block
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  const resolveContent = () => {
    switch (isMode) {
      case 0:
        return (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={4}>
              View Loan {props.isDetail && "Details"}
            </Title>
            <ColStart className="w-100 mt-24px">
              <RowSpaceBetween>
                <Text>Sent on</Text>
                <Text>
                  {moment(props.record?.created_at).format(
                    "DD/MM/YYYY HH:mm A"
                  )}
                </Text>
              </RowSpaceBetween>
              <DividerCard />
              <RowSpaceBetween>
                <Text>Duration</Text>
                <Text>
                  {getMonthBetween(
                    props.record?.created_at,
                    props.record?.deadline
                  )}
                </Text>
              </RowSpaceBetween>
              <DividerCard />
              {!props.isHasCancel && (
                <>
                  <RowSpaceBetween>
                    <Text>Deadline</Text>
                    <Text>
                      {moment(props.record?.deadline).format("DD/MM/YYYY ")}
                    </Text>
                  </RowSpaceBetween>
                  <DividerCard />
                </>
              )}
              <RowSpaceBetween>
                <Text className="text-w600">Loan Amount</Text>
                <RowEnd>
                  <img src={IBFxLogo} className="mr-6px" />
                  <Text className="text-w600">
                    {fromIBFx(props.record?.total_ibfx)}
                  </Text>
                </RowEnd>
              </RowSpaceBetween>
              <DividerCard className="mb-24px" />
              <Button
                block
                type="primary"
                style={{ height: 40, borderRadius: 4 }}
                onClick={() => setIsMode(2)}
              >
                {isLoading ? antIcon : "Claim"}
              </Button>
              <ButtonOutline
                block
                style={{ height: 40, borderRadius: 4 }}
                className="mt-12px"
                onClick={() => dispatch(setSection("WalletFAQ"))}
              >
                Check FAQ
              </ButtonOutline>
            </ColStart>
          </ColCenter>
        );
      case 1:
        return resolveSuccess();
      case 2:
        return (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Enter pin
            </Title>
            <Text className="gray-7" align="center">
              Please enter your pin code to confirm the transaction
            </Text>
            <Row className="mt-24px">
              <Col span={24}>
                <RowCenter>
                  <ReactCodeInput
                    type="password"
                    onChange={(e) => setPinCode(e)}
                    fields={4}
                    {...codeProps}
                  />
                </RowCenter>
              </Col>
              {isPinError && (
                <Col className="mt-10px" span={24}>
                  <Alert
                    message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                    type="error"
                    showIcon
                  />
                </Col>
              )}
            </Row>
            <Button
              style={{
                height: 40,
                borderRadius: 4,
                width: "calc(100% - 40px)",
              }}
              className="mx-20px mt-20px"
              type="primary"
              onClick={() => checkPin()}
              disabled={isLoading}
            >
              {!isLoading ? "Confirm Pin" : antIcon}
            </Button>
          </ColCenter>
        );
    }
  };

  return (
    <>
      <a onClick={showModal}>Claim</a>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="500px"
          footer={null}
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {resolveContent()}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {resolveContent()}
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
