import React, {  useEffect, useState } from "react";
import { Breadcrumb, Dropdown, Row, Menu, Image, Typography } from "antd";
import { ASSETS_NAME } from "../constants/constant_wallet";
import {
  BreadcrumbItemBold,
  BreadcrumbItemLight,
  ImageProfileStyled,
  HeaderStyled,
  BtnRegisterOrLogin,
  ImageHeaderStyled,
  IrshadHeader,
  IrshadStatus,
  ProfileContainer,
} from "./styled";
import { Link } from "react-router-dom";
import {
  RowEnd,
  RowSpaceBetween,
  ColStart,
  RowStart,
} from "../components/general_styled";
import { useDispatch, useSelector } from "react-redux";
import { fromIBFx } from "../utils/utils";
import UserIcon from "../assets/images/user.svg";
import LoginIcon from "../assets/images/login.svg";
import CartIcon from "../assets/images/cart.svg";
import WebIcon from "../assets/images/globe_nav.svg";
import BotIcon from "../assets/images/bot.svg";
import { logout } from "../modules/auth/action";
import { setSection, openModalLoginRegister } from "../modules/general/action";
import { openCart } from "../modules/course_cart/action";
import useWindowDimensions from "../utils/windowDimensions";
import { IS_EVM, LOGOUT_URL, MEMBERSHIP_HOST } from "../configs";
import DefaultAvatar from "../assets/images/default-avatar.png";
import { getProjectById } from "../modules/benevolence_projects/action";
import { PROJECT_TYPE } from "../constants/benevolence_constant";
import { getNftByNftId } from "../modules/credence/action";
import { useTranslation } from "react-i18next";
import ConnectWallet from "../components/ConnectWallet/Web3ModalTest";
import { useAuth0 } from "@auth0/auth0-react";

const { Text } = Typography;
export default function Navbar(props) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const windowDimensions = useWindowDimensions();
  const auth0 = useAuth0();

  const userData = useSelector((state) =>
    state.getIn(["auth", "userData"]).toJS()
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );
  const selectedCourse = useSelector((state) =>
    state.getIn(["courses", "selectedCourse"]).toJS()
  );
  const generalSection = useSelector((state) =>
    state.getIn(["general", "section"])
  );
  const params = useSelector((state) => state.getIn(["general", "param"]));
  const detailAuctionNft = useSelector((state) =>
    state.getIn(["affluence", "detailAuctions"]).toJS()
  );
  const visitedUser = useSelector((state) =>
    state.getIn(["affluence", "visitedUser"]).toJS()
  );
  const selectedCollection = useSelector((state) =>
    state.getIn(["affluence", "selectedCollection"]).toJS()
  );
  const detailEssenceNft = useSelector((state) =>
    state.getIn(["essence", "detailNft"]).toJS()
  );
  const benevolenceProjectType = useSelector((state) =>
    state.getIn(["general", "benevolenceProjectType"])
  );

  const [ibfxIBalance, setIBFXIBalance] = useState(0);
  const [isVisible, setVisible] = useState(false);
  const [isIndo, setIsIndo] = useState(false);
  const [userPhoto, setUserPhoto] = useState(DefaultAvatar);
  const [project, setProject] = useState({});
  
  const menus = [
    {
      icon: WebIcon,
      name: `Languages: ${isIndo ? "Indonesia" : "English"}`,
      action: () => {
        setIsIndo(!isIndo);
        onLanguageChanged({ key: !isIndo ? "id" : "en" });
      },
    },
    {
      icon: CartIcon,
      name: "Cart",
      action: () => {
        dispatch(openCart());
      },
    },
    {
      icon: UserIcon,
      name: "Profile",
      action: () => {
        dispatch(setSection("profile"));
      },
    },
    {
      icon: LoginIcon,
      name: "Logout",
      action: async () => {
        await auth0.logout({
          logoutParams: { returnTo: `${LOGOUT_URL}/login` },
        });
        dispatch(logout());
      },
    },
  ];

  const onLanguageChanged = (param) => {
    i18n.changeLanguage(param.key);
    localStorage.setItem("LANGUAGE", param.key);
  };

  const resolveIBFxIBalance = () => {
    const ibfxI = allAssets.find(
      (asset) => asset.asset_name === ASSETS_NAME.IBFX_TYPE_I
    );
    return ibfxI ? fromIBFx(ibfxI.amount) : 0;
  };

  const resolveProjectDetail = async () => {
    if (generalSection === "project-detail") {
      try {
        const _project = await getProjectById(params);
        setProject(_project);
      } catch (e) {
        console.log(e);
      }
    }

    if (generalSection === "credence-nft-detail") {
      try {
        const project = await getNftByNftId(params[0]);
        setProject(project);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const setLink = (params) => {
    dispatch(setSection(params));
  };

  useEffect(() => {
    if (allAssets.length > 0) {
      setIBFXIBalance(resolveIBFxIBalance());
    }
  }, [allAssets]);

  useEffect(() => {
    if (userData.photo && userData.photo !== "null") {
      setUserPhoto(`${MEMBERSHIP_HOST}/files/${userData.photo}`);
    }
  }, [userData]);

  useEffect(() => {
    resolveProjectDetail();
  }, [generalSection]);


  const resolveBreadcrumb = () => {
    switch (generalSection) {
      case "feed":
        return (
          <>
            <BreadcrumbItemBold>Feed</BreadcrumbItemBold>
          </>
        );
      case "feed-details":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("feed")}
            >
              Feed
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Feed</BreadcrumbItemBold>
          </>
        );
      case "wallet-dashboard":
        return (
          <>
            <BreadcrumbItemLight>Wallet</BreadcrumbItemLight>
            <BreadcrumbItemBold>Dashboard</BreadcrumbItemBold>
          </>
        );
      case "wallet-transactions":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("wallet-dashboard")}
            >
              Wallet
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Transactions</BreadcrumbItemBold>
          </>
        );
      case "wallet-faq":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("wallet-dashboard")}
            >
              Wallet
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>FAQs</BreadcrumbItemBold>
          </>
        );
      case "courses":
        return (
          <>
            <BreadcrumbItemBold>Excellence</BreadcrumbItemBold>
          </>
        );
      case "course-detail":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("courses")}
            >
              Excellence
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>{selectedCourse.title}</BreadcrumbItemBold>
          </>
        );
      case "edit-profile":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("profile")}
            >
              Profile
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Edit Profile</BreadcrumbItemBold>
          </>
        );
      case "benevolence-volunteer":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("benevolence-donate")}
            >
              Benevolence
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Volunteer</BreadcrumbItemBold>
          </>
        );
      case "benevolence-manage":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("benevolence-donate")}
            >
              Benevolence
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Manage</BreadcrumbItemBold>
          </>
        );
      case "benevolence-donate":
        return (
          <>
            <BreadcrumbItemLight>Benevolence</BreadcrumbItemLight>
            <BreadcrumbItemBold>Donate</BreadcrumbItemBold>
          </>
        );
      case "project-detail":
        return (
          <>
            <BreadcrumbItemLight>Benevolence</BreadcrumbItemLight>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() =>
                project?.project_type === PROJECT_TYPE.ASSET
                  ? setLink("benevolence-donate")
                  : setLink("benevolence-volunteer")
              }
            >
              {project?.project_type === PROJECT_TYPE.ASSET
                ? "Donate"
                : "Volunteer"}
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>{project.name}</BreadcrumbItemBold>
          </>
        );
      case "benevolence-waqf-pool":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("benevolence-donate")}
            >
              Benevolence
            </BreadcrumbItemLight>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("benevolence-donate")}
            >
              Donate
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Waqf Pool</BreadcrumbItemBold>
          </>
        );
      case "confluence-dashboard":
        return (
          <>
            <BreadcrumbItemLight>Confluence</BreadcrumbItemLight>
            <BreadcrumbItemBold>Impact Dashboard</BreadcrumbItemBold>
          </>
        );
      case "confluence-claim":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("confluence-dashboard")}
            >
              Confluence
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Claim</BreadcrumbItemBold>
          </>
        );
      case "confluence-offers":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("confluence-dashboard")}
            >
              Confluence
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Offers</BreadcrumbItemBold>
          </>
        );
      case "confluence-profiles":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("confluence-profiles")}
            >
              Confluence
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Manage Projects</BreadcrumbItemBold>
          </>
        );
      case "marketplace":
        return (
          <>
            <BreadcrumbItemBold>Essence</BreadcrumbItemBold>
          </>
        );
      case "affluence-auction-marketplace":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight>Auction</BreadcrumbItemLight>
            <BreadcrumbItemBold>Marketplace</BreadcrumbItemBold>
          </>
        );
      case "affluence-auction-purchases":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight>Auction</BreadcrumbItemLight>
            <BreadcrumbItemBold>Purchased</BreadcrumbItemBold>
          </>
        );
      case "affluence-auction-offers":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight>Auction</BreadcrumbItemLight>
            <BreadcrumbItemBold>Offers</BreadcrumbItemBold>
          </>
        );
      case "affluence-auction-offers":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight>Auction</BreadcrumbItemLight>
            <BreadcrumbItemBold>Listing</BreadcrumbItemBold>
          </>
        );
      case "affluence-buy-sell-marketplace":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight>Buy Sell</BreadcrumbItemLight>
            <BreadcrumbItemBold>Marketplace</BreadcrumbItemBold>
          </>
        );
      case "affluence-buy-sell-purchases":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight>Buy Sell</BreadcrumbItemLight>
            <BreadcrumbItemBold>Purchased</BreadcrumbItemBold>
          </>
        );
      case "affluence-buy-sell-listing":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight>Buy Sell</BreadcrumbItemLight>
            <BreadcrumbItemBold>Listing</BreadcrumbItemBold>
          </>
        );

      case "affluence-auction-purchases":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight>Buy Sell</BreadcrumbItemLight>
            <BreadcrumbItemBold>Purchased</BreadcrumbItemBold>
          </>
        );
      case "affluence-auction-listing":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("affluence-auction-marketplace")}
            >
              Auction
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Listing</BreadcrumbItemBold>
          </>
        );
      case "affluence-auction-offers":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("affluence-auction-marketplace")}
            >
              Auction
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>Offers</BreadcrumbItemBold>
          </>
        );
      case "credence-nft-detail":
        const metadata = project?.metadata_json
          ? JSON.parse(project?.metadata_json)
          : undefined;
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("credence")}
            >
              Credence
            </BreadcrumbItemLight>
            <BreadcrumbItemLight>NFT</BreadcrumbItemLight>
            <BreadcrumbItemBold>{metadata?.name}</BreadcrumbItemBold>
          </>
        );
      case "affluence-buy-sell-detail-nft":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("affluence-buy-sell-marketplace")}
            >
              Buy Sell
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>
              {detailEssenceNft?.metadata?.name}
            </BreadcrumbItemBold>
          </>
        );
      case "affluence-auction-detail-nft":
        return (
          <>
            <BreadcrumbItemLight>Affluence</BreadcrumbItemLight>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("affluence-auction-marketplace")}
            >
              Auction
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>
              {detailAuctionNft?.metadata?.name}
            </BreadcrumbItemBold>
          </>
        );
      case "affluence-microfinance-myloans":
        return (
          <>
            <BreadcrumbItemLight>Microfinance</BreadcrumbItemLight>
            <BreadcrumbItemBold>Borrowed</BreadcrumbItemBold>
          </>
        );
      case "affluence-microfinance-loan-requests":
        return (
          <>
            <BreadcrumbItemLight>Microfinance</BreadcrumbItemLight>
            <BreadcrumbItemBold>Loan Request</BreadcrumbItemBold>
          </>
        );
      case "affluence-user":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("affluence-buy-sell-marketplace")}
            >
              Affluence
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>{visitedUser?.name}</BreadcrumbItemBold>
          </>
        );
      case "affluence-collection":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("affluence-buy-sell-marketplace")}
            >
              Affluence
            </BreadcrumbItemLight>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() =>
                IS_EVM
                  ? {}
                  : dispatch(setSection("affluence-user", visitedUser?.user_id))
              }
            >
              {visitedUser?.name}
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>{selectedCollection?.name}</BreadcrumbItemBold>
          </>
        );
      // case "irshad":
      //   return (
      //     <RowStart>
      //       <ImageHeaderStyled src={BotIcon} />
      //       <ColStart className="ml-10px">
      //         <IrshadHeader>Irshad</IrshadHeader>
      //         <IrshadStatus>Online</IrshadStatus>
      //       </ColStart>
      //     </RowStart>
      //   );
      case "create-project":
        return (
          <>
            <BreadcrumbItemLight
              className="pointer"
              onClick={() => setLink("benevolence-donate")}
            >
              Benevolence
            </BreadcrumbItemLight>
            <BreadcrumbItemBold>
              Create Project -{" "}
              {benevolenceProjectType === PROJECT_TYPE.ASSET
                ? "Donation"
                : "Volunteer"}
            </BreadcrumbItemBold>
          </>
        );
      default:
        const texts = generalSection.split("-");
        let text = "";
        for (var index = 0; index < texts.length; index++) {
          text = text + texts[index] + " ";
        }
        return (
          <>
            <BreadcrumbItemBold>
              <Link
                to={`/${generalSection}`}
                style={{ textTransform: "capitalize" }}
              >
                {text.substring(0, text.length - 1)}
              </Link>
            </BreadcrumbItemBold>
          </>
        );
    }
  };

  return (
    windowDimensions.width > 835 && (
      <HeaderStyled collapsedSidebar={props.collapsedSidebar}>
        <RowSpaceBetween>
          <Breadcrumb className="ml-16px">{resolveBreadcrumb()}</Breadcrumb>
          <RowEnd>
            {!localStorage.getItem("USER_AUTH_TOKEN") ? (
              <BtnRegisterOrLogin
                className="pointer"
                onClick={() => dispatch(openModalLoginRegister())}
              >
                Login/Register
              </BtnRegisterOrLogin>
            ) : (
              <Dropdown
                overlay={() => {
                  return (
                    <Menu style={{ minWidth: 220 }}>
                      {menus.map((menu, i) => {
                        return (
                          <Menu.Item onClick={menu.action}>
                            <Row style={{ alignItems: "center" }}>
                              <Image
                                preview={false}
                                src={menu.icon}
                                className={!i && "ml-1px"}
                              />
                              <Text style={{ marginLeft: "10px" }}>
                                {menu.name}
                              </Text>
                            </Row>
                          </Menu.Item>
                        );
                      })}
                      {IS_EVM && (
                        <Menu.Item>
                          <ConnectWallet />
                        </Menu.Item>
                      )}
                    </Menu>
                  );
                }}
                onVisibleChange={() => {
                  setVisible(!isVisible);
                }}
                placement="bottomLeft"
              >
                <ProfileContainer>
                  <ImageProfileStyled
                    style={{ width: "40px", height: "40px" }}
                    src={userPhoto}
                  />
                </ProfileContainer>
              </Dropdown>
            )}
          </RowEnd>
        </RowSpaceBetween>
      </HeaderStyled>
    )
  );
}
