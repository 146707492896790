import {
  Col,
  Dropdown,
  Progress,
  Row,
  Space,
  Tag,
  Menu,
  Table,
  Modal,
  Spin,
  Image,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import {
  PROJECT_STATUS,
  PROJECT_TYPE,
} from "../../constants/benevolence_constant";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import {
  activateProject,
  completionProject,
  deployProject,
  getMyProjects,
  openCreateProjectModal,
  startProject,
  submitSignedOperation,
} from "../../modules/benevolence_projects/action";
import projectIcon from "../../assets/images/projects-side.svg";
import closeIcon from "../../assets/images/close_project.svg";
import moreIcon from "../../assets/images/more.svg";
import successIcon from "../../assets/images/success.svg";
import verificationCrossIcon from "../../assets/images/verification-cross.svg";

import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { ColCenter } from "../../components/general_styled";
import {
  ActionButtonStyled,
  MenuDropdownCustom,
  MoreInfoBtnStyled,
  NotFoundTextStyled,
  NotFoundTitleStyled,
} from "./styled";
import ReactCodeInput from "react-code-input";
import { decryptMnemonic, fromIBFx, fromIUSD } from "../../utils/utils";
import useWindowDimensions from "../../utils/windowDimensions";
import { setSection } from "../../modules/general/action";
import moment from "moment";
import CardBenevolenceManage from "../../components/CardManageBenevolence";
import {
  IS_EVM,
  benevolenceDonationAddress,
} from "../../configs";
import { useAccount, useContractWrite, usePrepareContractWrite } from "wagmi";
import AbiBenevolanceDonation from "../../assets/abi/BenevolanceDonation.json";

export default function BenevolenceProjects(props) {
  const windowDimensions = useWindowDimensions();
  const { isConnected } = useAccount();

  const [hasProject, setHasProject] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [modalMode, setModalMode] = useState("");
  const [selectedProject, setSelectedProject] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const [pin, setPin] = useState("");
  const [nonce, setNonce] = useState("");

  const token = useSelector((state) => state.getIn(["auth", "token"]));

  const projects = useSelector((state) =>
    state.getIn(["benevolenceProjects", "projects"]).toJS()
  );
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getMyProjects(currentPagination));
  // }, []);

  useEffect(() => {
    dispatch(getMyProjects(currentPagination));
  }, [currentPagination]);

  useEffect(() => {
    setIsFailed(false);
    setIsFinish(false);
    if (!IS_EVM) {
      setIsProcessing(false);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (
      props.menu === "projects" &&
      props.searchVal &&
      props.searchVal !== ""
    ) {
      getProjectsSearch(props.searchVal);
    } else {
      setSearchResult([]);
    }
  }, [props.searchVal]);

  const getProjectsSearch = (value) => {
    let getResult = projects.data.filter((e) => e.name.includes(value));
    setSearchResult(getResult);
  };

  const handleMenuClick = (e, record) => {
    if (e.key === "view-project") {
      dispatch(setSection("project-detail", record.project_id));
    } else if (e.key === "view-volunteer") {
      dispatch(setSection("benevolence-manage", record.project_id));
    } else if (e.key === "close") {
      setSelectedProject(record);
      setModalMode("close_project");
      setModalOpen(true);

      // setModalOpen(true)
      // setModalMode("close")
      // setSelectedProject(record)
    }
  };

  const onPaginationChange = (page) => {
    setCurrentPagination(page)
  };

  const closeProjectAsset = async () => {};

  const onDeployProject = async (record) => {
    const isPolygon = record?.chain === "POLYGON";

    if (!isPolygon && (!pin || pin === "")) {
      return;
    }

    try {
      // const checkoutBody = {
      //   name: record?.name || selectedProject?.name,
      //   description: `Benevolence Project`,
      //   pricing_type: "no_price",
      //   requested_info: ["name", "email"],
      // };
      // const config = {
      //   method: "post",
      //   url: "https://api.commerce.coinbase.com/checkouts",
      //   headers: coinbaseHeaders,
      //   data: checkoutBody,
      // };

      // const checkoutResult = (await axios(config)).data;

      setIsProcessing(true);
      if (isPolygon) {
        await deployProject(
          token,
          record.project_id,
          isPolygon,
        );
      } else {
        const operations = await deployProject(
          token,
          selectedProject.project_id,
          isPolygon,
        );

        const encryptedMnemonic = localStorage.getItem(
          COMMON_CONSTANT.ENCRYPTED_MNEMONIC
        );
        const mnemonic = decryptMnemonic(encryptedMnemonic, pin);
        await submitSignedOperation(mnemonic, operations);
      }
      dispatch(getMyProjects(currentPagination));
      setIsFinish(true);
    } catch (e) {
      console.log(e);
      setIsFailed(true);
      notification.error({ message: e });
    }

    setIsProcessing(false);
  };

  const onCreateEscrow = async () => {
    if (!pin || pin === "") {
      return;
    }

    try {
      setIsProcessing(true);
      const operations = await activateProject(
        token,
        selectedProject.project_id
      );

      const encryptedMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const mnemonic = decryptMnemonic(encryptedMnemonic, pin);
      await submitSignedOperation(mnemonic, operations);
      dispatch(getMyProjects(currentPagination));
      setIsFinish(true);
    } catch (e) {
      console.log(e);
      setIsFailed(true);
    }

    setIsProcessing(false);
  };

  const onStartOrCompletionProject = async (record) => {
    const isPolygon = record?.chain === "POLYGON";

    if (!isPolygon && (!pin || pin === "")) {
      return;
    }

    try {
      setIsProcessing(true);
      if (modalMode === "start") {
        await startProject(token, selectedProject.project_id);
      } else if (modalMode === "close_project") {
        await completionProject(token, selectedProject.project_id);
      }

      if (isPolygon) {
        setSelectedProject(record);
        setModalMode("close_project");

        setModalOpen(true);

        await completionProject(token, record.project_id);
      }

      await dispatch(getMyProjects(currentPagination));
      setIsProcessing(false);
      setIsFinish(true);
    } catch (e) {
      console.log(e);
      setIsFailed(true);
    }
  };

  const onCreateProject = () => {
    dispatch(openCreateProjectModal());
  };

  const handleActionButton = (data, type) => {
    setSelectedProject(data);
    setModalMode(type);
    setModalOpen(true);
  };

  // Withdraw Donation (Owner Project Side)
  const { config: configWithdrawDonation } = usePrepareContractWrite({
    address: benevolenceDonationAddress,
    abi: AbiBenevolanceDonation,
    functionName: "withdrawDonation",
    args: [
      nonce, // id = nonce
    ],
  });

  const {
    writeAsync: writeWithdrawDonation,
    isSuccess: isSuccessWithdrawDonation,
    isLoading: isLoadingWithdrawDonation,
    isError: isErrorWithdrawDonation,
  } = useContractWrite(configWithdrawDonation);

  useEffect(() => {
    if (nonce.length > 0) {
      writeWithdrawDonation();
    }
  }, [nonce]);

  useEffect(() => {
    if (isSuccessWithdrawDonation) {
      notification.success({
        description:
          "Your Withdraw was successful. Your Balance will soon be increased, and the transaction activity can be viewed on your wallet app",
      });
      setNonce("");
    } else if (isErrorWithdrawDonation) {
      setNonce("");
    }
  }, [isLoadingWithdrawDonation]);

  const columns = [
    {
      title: "Project",
      dataIndex: "name",
      key: "name",
      width: windowDimensions.width > 1024 ? 200 : null,
      render: (name, record) => {
        let percent = 0;
        if (record.project_type === "ASSET") {
          // let sumRecentDonation = getSumRecentDonation(token, record.project_id)
          percent =
            (fromIBFx(
              record.donation_amount == null
                ? 0
                : fromIUSD(record.donation_amount)
            ) /
              fromIBFx(
                record.fund_goal == null ? 0 : fromIUSD(record.fund_goal)
              )) *
            100;
        }

        if (record.project_type === "EFFORT") {
          // let recentVolunteers = getRecentVolunteers(token, record.project_id)
          percent = (record.total_hours_accepted / record.hours_goal) * 100;
        }

        return (
          <Row>
            <Col span={24}>{name}</Col>
            <Col span={24}>
              <Progress
                percent={percent}
                status="active"
                showInfo={false}
                strokeWidth={3}
                strokeColor={
                  percent <= 50
                    ? "#EB5757"
                    : percent <= 75
                    ? "#F2994A"
                    : percent > 75
                    ? "#27AE60"
                    : ""
                }
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Chain",
      dataIndex: "chain",
      key: "chain",
      render: (chain) => <>{chain}</>,
    },
    {
      title: "End Date",
      dataIndex: "end_at",
      key: "end_at",
      render: (time) => <>{Intl.DateTimeFormat("en-US").format(time * 1000)}</>,
    },
    {
      title: "Type",
      dataIndex: "project_type",
      key: "project_type",
      render: (type) => (
        <>{type === "EFFORT" ? "Efforts (SE)" : "Assets (SA)"}</>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => <>{category}</>,
    },
    {
      title: "Donors/Volunteers",
      dataIndex: "donors",
      key: "total_volunteer",
      align: "center",
      render: (volunteer, record) => {
        return <>{record.total_donation + record.total_volunteer || "-"}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status, record) => {
        let color = "green";
        let text = "";

        if (status.toLowerCase() === "progress") {
          color = "gold";
          text = "In-Progress";
        }
        if (status === PROJECT_STATUS.PENDING) {
          color = "yellow";
          text = "Pending";
        } else if (status === PROJECT_STATUS.DEPLOYED) {
          color = "geekblue";
          text = "Deployed";
        } else if (status === PROJECT_STATUS.APPROVED) {
          color = "green";
          text = "Approved";
        } else if (status === PROJECT_STATUS.REJECTED) {
          color = "volcano";
          text = "Rejected";
        } else if (status === PROJECT_STATUS.CLOSED) {
          color = "volcano";
          text = "Closed";
        } else if (status === PROJECT_STATUS.SUBMITED) {
          color = "lime";
          text = "Submitted";
        } else if (status === PROJECT_STATUS.COMPLETED) {
          color = "#858585";
          text = "Completed";
        } else if (status === PROJECT_STATUS.OPENED) {
          if (moment().unix() > record.end_at) {
            color = "volcano";
            text = "Pending Closure";
          } else {
            color = "blue";
            text = "Opened";
          }
        } else if (status === PROJECT_STATUS.STARTING) {
          color = "magenta";
          text = "Starting";
        }

        return (
          <>
            <Tag className="tag-custom" color={color}>
              {text.toUpperCase()}
            </Tag>
          </>
        );
      },
    },

    {
      title: "Actions",
      key: "action",
      align: "right",
      render: (text, record) => {
        const isPolygon = record?.chain === "POLYGON";
        const status = record.status.toLowerCase();
        const isEnd = moment().unix() > record.end_at;
        return (
          <Space>
            {status === "approved" &&
              record.project_type === PROJECT_TYPE.ASSET && (
                <ActionButtonStyled
                  loading={
                    isProcessing &&
                    selectedProject?.project_id === record?.project_id
                  }
                  disabled={isPolygon && !isConnected}
                  onClick={async () => {
                    if (isPolygon) {
                      await setSelectedProject(record);
                      await onDeployProject(record);
                    } else {
                      handleActionButton(record, "deploy");
                    }
                  }}
                >
                  Deploy
                </ActionButtonStyled>
              )}
            {status === "approved" &&
              record.project_type === PROJECT_TYPE.EFFORT && (
                <ActionButtonStyled
                  onClick={() => handleActionButton(record, "start")}
                >
                  Start
                </ActionButtonStyled>
              )}
            {status === "deployed" && (
              <ActionButtonStyled
                onClick={() => {
                  handleActionButton(record, "create_escrow");
                }}
              >
                Create Escrow
              </ActionButtonStyled>
            )}
            {isPolygon &&
              status === "completed" &&
              record.project_type === PROJECT_TYPE.ASSET && (
                <ActionButtonStyled
                  loading={nonce === record?.nonce && isLoadingWithdrawDonation}
                  onClick={async () => setNonce(record?.nonce)}
                >
                  Withdraw
                </ActionButtonStyled>
              )}
            {/* {status === 'opened' && isEnd && (
							<ActionButtonStyled
								onClick={() => {
									setSelectedProject(record)
									setModalMode('close_project')
									setModalOpen(true)
								}}
							>
								Close
							</ActionButtonStyled>
						)} */}
            <Dropdown
              overlay={
                <Menu
                  className="canvas-dropdown-custom"
                  onClick={(e) => {
                    if (isPolygon && e.key === "close") {
                      onStartOrCompletionProject(record);
                    } else {
                      handleMenuClick(e, record);
                    }
                  }}
                >
                  <Menu.Item key="view-project">
                    <Row>
                      <Col span={24} className="menu-dropdown-custom">
                        <img src={projectIcon} alt="icon" />
                        <span>View Project</span>
                      </Col>
                    </Row>
                  </Menu.Item>
                  {/* {record.project_type === 'EFFORT' && (
										<Menu.Item key="view-volunteer">
											<Row>
												<Col span={24} className="menu-dropdown-custom">
													<img src={halfHeart} alt="icon" />
													<span>View Volunteers</span>
												</Col>
											</Row>
										</Menu.Item>
									)} */}
                  {record.project_type === "ASSET" &&
                    record.status === PROJECT_STATUS.OPENED && (
                      <Menu.Item key="close">
                        <Row>
                          <MenuDropdownCustom>
                            <img src={closeIcon} alt="icon" />
                            <span>Close Project</span>
                          </MenuDropdownCustom>
                        </Row>
                      </Menu.Item>
                    )}
                  {record.project_type === "EFFORT" &&
                    status === "opened" &&
                    isEnd && (
                      <Menu.Item key="close">
                        <Row>
                          <MenuDropdownCustom>
                            <img src={closeIcon} alt="icon" />
                            <span>Close Project</span>
                          </MenuDropdownCustom>
                        </Row>
                      </Menu.Item>
                    )}
                </Menu>
              }
              placement="bottomRight"
            >
              <MoreInfoBtnStyled>
                <img src={moreIcon} alt="more" />
              </MoreInfoBtnStyled>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const resolveHasProject = () => {
    return !hasProject ? (
      <ColCenter
        style={{
          height: "100%",
        }}
      >
        <Image preview={false} src={projectIcon} alt="icon" width={"112px"} />
        <NotFoundTitleStyled>No Project Found</NotFoundTitleStyled>
        <NotFoundTextStyled>
          Click on the button below to add your
          <br />
          very first SE or SA project!
        </NotFoundTextStyled>
      </ColCenter>
    ) : windowDimensions.width > 925 ? (
      <Table
        className="table-project"
        columns={columns}
        dataSource={searchResult.length > 0 ? searchResult : projects.data}
        scroll={{ y: "100%" }}
        pagination={{
          current: currentPagination,
          onChange: onPaginationChange,
          pageSize: 12,
          total: projects.totalItems,
        }}
      />
    ) : (
      projects?.data?.map((i) => (
        <CardBenevolenceManage
          page={"manage"}
          data={i}
          handleActionButton={handleActionButton}
          handleMenuClick={handleMenuClick}
        />
      ))
    );
  };

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 925 ? "5rem" : "4.5rem",
      height: windowDimensions.width > 925 ? "5rem" : "4.5rem",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 925 ? "5rem" : "4.5rem",
      height: windowDimensions.width > 925 ? "5rem" : "4.5rem",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  return (
    <Col
      style={{
        height: "100%",
      }}
    >
      {resolveHasProject()}
      <Modal centered visible={isModalOpen} closeIcon={<></>} footer={null}>
        {isProcessing ? (
          <>
            <div className="loading-manage-projects">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 100, color: "#4E73F8" }}
                    spin
                  />
                }
              />
              <h2>
                {modalMode === "deploy"
                  ? "Deploying Project"
                  : modalMode === "start"
                  ? "Start Project"
                  : modalMode === "close_project"
                  ? "Close Project"
                  : "Creating Escrow"}
              </h2>
              <p>
                Please wait while we{" "}
                {modalMode === "deploy"
                  ? ["deploy your", <br />]
                  : modalMode === "start"
                  ? ["start your", <br />, "your"]
                  : modalMode === "close_project"
                  ? ["close your", <br />, "your"]
                  : ["create escrow for", <br />, "your"]}{" "}
                project on the blockchain
              </p>
            </div>
          </>
        ) : isFailed ? (
          <div className="finish-manage-projects">
            <img className="success-icon" src={verificationCrossIcon} alt="" />
            <h2>Failed!</h2>
            <p>
              Failed to{" "}
              {modalMode === "deploy"
                ? ["deploy your", <br />]
                : modalMode === "start"
                ? ["start your", <br />, "your"]
                : modalMode === "close_project"
                ? ["close your", <br />, "your"]
                : ["create escrow for", <br />, "your"]}{" "}
              project on the blockchain
            </p>
            <button
              className="purple"
              onClick={() => {
                setModalOpen(false);
                setModalMode("");
                setIsFinish(false);
                setIsProcessing(false);
                setSelectedProject({});
              }}
            >
              Dismiss
            </button>
          </div>
        ) : isFinish ? (
          <div className="finish-manage-projects">
            <img className="success-icon" src={successIcon} alt="" />
            <h2>Success!</h2>
            <p>
              {modalMode === "deploy"
                ? [
                    "Your project is now active and deployed on the blockchain",
                    <br />,
                  ]
                : modalMode === "start"
                ? [
                    "Your project is now active and started on the blockchain",
                    <br />,
                  ]
                : modalMode === "close_project"
                ? ["Your project is now closed", <br />]
                : [
                    "You successfully created escrow",
                    <br />,
                    " on the blockchain",
                  ]}
            </p>
            <button
              className="purple"
              onClick={() => {
                setModalOpen(false);
                setModalMode("");
                setIsFinish(false);
                setIsProcessing(false);
                setSelectedProject({});
              }}
            >
              Dismiss
            </button>
          </div>
        ) : (
          <div className="modal-project">
            <h5>
              {modalMode === "close"
                ? "Close"
                : modalMode === "deploy"
                ? "Deploy"
                : modalMode === "start"
                ? "Start"
                : modalMode === "close_project"
                ? "Close Project"
                : "Create Escrow"}{" "}
              Project
            </h5>
            <p>
              Are you sure that you want to{" "}
              {modalMode === "close"
                ? "close"
                : modalMode === "deploy"
                ? "deploy"
                : modalMode === "start"
                ? "start"
                : modalMode === "close_project"
                ? "close project for"
                : "create escrow for"}
              <br />"{selectedProject.name}”
            </p>
            <p style={{ marginBottom: "8px" }}>Please input your PIN</p>
            <ColCenter span={24} style={{ marginBottom: "24px" }}>
              <ReactCodeInput
                type="password"
                onChange={(e) => setPin(e)}
                fields={4}
                autoComplete="off"
                {...codeProps}
              />
            </ColCenter>
            <button
              className={modalMode === "close" ? "red" : "purple"}
              onClick={() => {
                if (modalMode === "close") {
                  closeProjectAsset();
                } else if (modalMode === "deploy") {
                  onDeployProject();
                } else if (modalMode === "create_escrow") {
                  onCreateEscrow();
                } else if (modalMode === "start") {
                  onStartOrCompletionProject();
                } else if (modalMode === "close_project") {
                  onStartOrCompletionProject();
                }
              }}
            >
              {modalMode === "close"
                ? "Close"
                : modalMode === "deploy"
                ? "Deploy"
                : modalMode === "start"
                ? "Start"
                : modalMode === "close_project"
                ? "Close Project"
                : "Create Escrow"}
            </button>
            <button
              className="outline-purple"
              onClick={() => {
                setModalOpen(false);
                setModalMode("");
                setSelectedProject({});
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </Modal>
    </Col>
  );
}
