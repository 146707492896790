import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Button, Progress } from "antd";
import ClockIcon from "../../assets/images/clock-projects.svg";
import UserIcon from "../../assets/images/user-benev.svg";
import AlgoIcon from "../../assets/images/home/algo.svg";
import PolyIcon from "../../assets/images/home/polygon.svg";
import {
  DollarText1,
  ProjectTitle,
  CardStyled,
  TotalUserText,
  UserImgStyled,
  DollarTextContainer,
  DonateButton,
} from "./styled";
import { formatterCurrency, fromIBFx, titleCase } from "../../utils/utils";
import { ColSpaceBetween, RowSpaceBetween } from "../general_styled";
import { PROJECT_TYPE, PROJECT_STATUS } from "../../constants/constant_common";
import { benevolenceDonationAddress } from "../../configs";
import { setSection } from "../../modules/general/action";
import useWindowDimensions from "../../utils/windowDimensions";
import ModalContributeVolunteer from "../ModalContributeVolunteer";
import ModalClaimVolunteer from "../ModalClaimVolunteer";

import AbiBenevolenceDonation from "../../assets/abi/BenevolanceDonation.json";

import { useContractRead } from "wagmi";

export default function CardBenevolence(props) {
  const isAlgo = props?.data?.chain === "ALGORAND";
  const isVolunteerFull = props?.data?.total_hours_accepted >= props?.data?.hours_goal

  const windowDimensions = useWindowDimensions();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatterTimeLeft = new Intl.RelativeTimeFormat();

  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );

  const { data: dataAchievedDonation } = useContractRead({
    address: benevolenceDonationAddress,
    abi: AbiBenevolenceDonation,
    functionName: "getAchivedValueDonationProject", //getAchivedValueDonationProject
    args: [
      props.nonce, // id = nonce
    ],
  });

  const resolveAmountUnit = (symbol, value, forStart = true) => {
    let _value;

    if (symbol === "IBFX")
      _value = Number((fromIBFx(Number(value)) / ibfxPerUSD).toFixed(2) || 0);
    else if (symbol === "IBFN")
      _value = Number((Number(value) / 6.5).toFixed(2) || 0);
    else _value = Number(value);

    const total  = (Number(_value) + (forStart ? Number(props?.data?.total_external_donation) : 0))
    return total.toFixed(total >= 100 ? 0 : 2)
  };

  const getTimeLeft = (time) => {
    // return project.end_at
    // if (project.end_at) {
    const projectTime = new Date(time * 1000);
    const deltaDays = (projectTime.getTime() - Date.now()) / (1000 * 3600 * 24);
    // return deltaDays
    return formatterTimeLeft
      .format(Math.round(deltaDays), "days")
      .replace("in ", "");
    // }
  };

  const resolveColorProgress = () => {
    const totalDonation = Number(resolveAmountUnit("IBFX", props.currentDonation))
    const targetlDonation = Number(resolveAmountUnit("IBFX", props.totalDonation, false))
    const progressValue =
      props.type === PROJECT_TYPE.ASSET
        ? (totalDonation / targetlDonation) * 100
        : (props.totalHours / props.hoursGoal) * 100;

    if (progressValue < 50) {
      return "#FF4D4F";
    } else if (progressValue <= 80) {
      return "#EC950A";
    } else {
      return "#38CB89";
    }
  };

  const resolveProgressPercent = (param = PROJECT_TYPE.ASSET) => {
    const { data: project } = props;
    const totalDonation = Number(resolveAmountUnit("IBFX", props.currentDonation))
    const targetlDonation = Number(resolveAmountUnit("IBFX", props.totalDonation, false))

    if (param === PROJECT_TYPE.ASSET) {
      return project?.chain === "POLYGON"
        ? ((Number(dataAchievedDonation) / 10 ** 6 +
            project.total_external_donation) /
            project.fund_goal) *
            100
        : (totalDonation / targetlDonation) * 100
    } else {
      return (project?.total_hours_accepted / project?.hours_goal) * 100;
    }
  };

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={windowDimensions.width > 925 ? 12 : 24}
        lg={12}
        xl={8}
        xxl={6}
      >
        <CardStyled
          cover={<img onClick={() => dispatch(setSection("project-detail", props.project_id))} alt="example" src={`${props.image}`} />}
          actions={[
            <Progress
              strokeColor={resolveColorProgress()}
              percent={resolveProgressPercent(props.type)}
              status="active"
              showInfo={false}
            />,
          ]}
        >
          <div className="time-left">
            <img
              style={{ borderRadius: 2 }}
              src={isAlgo ? AlgoIcon : PolyIcon}
              alt=""
            />
            <div className="text">
              {isAlgo ? "Algorand" : "Polygon"}
            </div>
          </div>
          <ColSpaceBetween className="h-100 stretch">
            <RowSpaceBetween className="start">
              <div className="mb-12px" style={{ height: "48px" }}>
                <ProjectTitle className="mr-12px">{props.title}</ProjectTitle>
              </div>
            </RowSpaceBetween>
            <div>
              {props.type === PROJECT_TYPE.ASSET &&
                props.status === PROJECT_STATUS.OPENED && (
                  <DonateButton
                    block
                    type="primary"
                    onClick={() =>
                      dispatch(setSection("project-detail", props.project_id))
                    }
                    className={resolveProgressPercent(props.type) >= 100 && 'funded'}
                  >
                    {resolveProgressPercent(props.type) >= 100 ? 'Funded' :t("donate")}
                  </DonateButton>
                )}
              {props.type === PROJECT_TYPE.EFFORT &&
                props.status === PROJECT_STATUS.OPENED && (
                  <ModalContributeVolunteer
                    data={props.data}
                    text={"Volunteer"}
                    isVolunteerFull={isVolunteerFull}
                  />
                )}
              {props.type === PROJECT_TYPE.EFFORT &&
                props.status === PROJECT_STATUS.COMPLETED && (
                  <ModalClaimVolunteer data={props.data} />
                )}
              <DollarTextContainer>
                <DollarText1>
                  {props.type === PROJECT_TYPE.ASSET
                    ? "$ " +
                      formatterCurrency.format(
                        !isAlgo
                          ? resolveAmountUnit(
                              props?.payment?.key,
                              Number(dataAchievedDonation) / 10 ** 6
                            )
                          : resolveAmountUnit("IBFX", props.currentDonation)
                      )
                    : props.hoursGoal}
                  <span className="ml-2px" style={{ color: "#5B7697" }}>
                    {props.type === PROJECT_TYPE.ASSET ? "Funded" : t("hours")}
                  </span>
                </DollarText1>
                <DollarText1 style={{ marginLeft: "2px" }}>
                  <UserImgStyled className="mr-4px" src={UserIcon} />
                  <TotalUserText>
                    {props.type === PROJECT_TYPE.ASSET
                      ? props.donors + props.data?.total_external_donator
                      : props.type === PROJECT_TYPE.EFFORT
                      ? props.volunteer
                      : 0}
                  </TotalUserText>
                  <span className="ml-2px" style={{ color: "#5B7697" }}>
                    {props.type === PROJECT_TYPE.ASSET ? "Donors" : "Volunteers"}
                  </span>
                </DollarText1>
                <DollarText1 style={{ marginLeft: "2px" }}>
                  <UserImgStyled className="mr-4px" src={ClockIcon} />
                  {titleCase(getTimeLeft(props.time))}
                  <span className="ml-2px" style={{ color: "#5B7697" }}>
                    {t("left")}
                  </span>
                </DollarText1>
                </DollarTextContainer>
            </div>
          </ColSpaceBetween>
        </CardStyled>
      </Col>
    </>
  );
}
