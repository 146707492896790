import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Typography,
  Select,
  Progress,
  Table,
  Collapse,
  Avatar,
  Popover,
} from "antd";
import { useTranslation } from "react-i18next";
import LockMini from "../../assets/images/icon_lock_mini.svg";
import {
  TitleStyled,
  UnlockableContent,
  PriceHistory,
  DividerCard,
  MoreStyled,
  PropertiesCard,
  TextLabel,
  TextValue,
  FullRoyalty,
} from "./styled";
import {
  RowSpaceBetween,
  RowStart,
  RowEnd,
  ButtonOutline,
  ColStart,
  ColEnd,
  ColCenter,
} from "../../components/general_styled";
import { Line } from "@ant-design/plots";
import IBFxIcon from "../../assets/images/ibfx-icon.svg";
import IconLove from "../../assets/images/icon_love.svg";
import IconUnloved from "../../assets/images/icon_unloved.svg";
import IconTwitter from "../../assets/images/icon_twitter.svg";
import UserDummy from "../../assets/images/default-avatar.png";
import IconInstagram from "../../assets/images/icon_instagram.svg";
import IconWeb from "../../assets/images/icon_web.svg";
import CardAuction from "../../components/CardAuction";
import { useDispatch, useSelector } from "react-redux";
import { formatterCurrency, fromIBFx } from "../../utils/utils";
import ModalAffluencePlaceBid from "../../components/ModalAffluencePlaceBid";
import { IconStyled } from "../CredenceNFTDetail/styled";
import {
  ALGO_EXPLORER_URL,
  CREDENCE_HOST,
  MEMBERSHIP_HOST,
} from "../../configs";
import {
  getAllAuction,
  getDetailAuction,
  getPriceHistory,
  setSelectedCollection,
  updateWishlistAuction,
} from "../../modules/affluence/action";
import { getUserDetailById } from "../../modules/auth/action";
import jwtDecode from "jwt-decode";
import ModalDelistingAuctionNFT from "../../components/ModalDelistingAuctionNFT";
import ModalLoansNewRequest from "../../components/ModalLoansNewRequest";
import { setSection } from "../../modules/general/action";
import moment from "moment";
import { useCountdown } from "../../utils/useCountdown";
import SEO from "../../components/SEO";

const { Text, Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

export default function AffluenceAuctionDetailNFT() {
  const { t } = useTranslation();
  // const [creatorData, setCreatorData] = useState()
  const [ownerData, setOwnerData] = useState();
  const [otherCollection, setOtherCollection] = useState([]);
  const [isReadMoreActive, setReadMoreActive] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const dispatch = useDispatch();

  const params = useSelector((state) => state.getIn(["general", "param"]));

  const detailNft = useSelector((state) =>
    state.getIn(["affluence", "detailAuctions"]).toJS()
  );

  const priceHistory = useSelector((state) =>
    state.getIn(["affluence", "priceHistory"]).toJS()
  );

  const wishlist = useSelector((state) =>
    state.getIn(["affluence", "wishlist"]).toJS()
  );

  const param = useSelector((state) => state.getIn(["general", "param"]));

  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );

  const [days, hours, minutes, isExpired] = useCountdown(
    detailNft?.auctionData?.deadline
  );

  useEffect(() => {
    // if (Object.keys(detailNft).length <= 0) {
    initDetailNft();
    // }
    if (!ownerData) {
      initDetailUser();
    }
    if (priceHistory.length <= 0) {
      dispatch(getPriceHistory(detailNft?.auctionData?.nft_id));
    }
    if (!otherCollection) {
      getOtherCollection();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(detailNft).length > 0) {
      if (!ownerData) {
        initDetailUser();
      }
      if (priceHistory.length <= 0) {
        dispatch(getPriceHistory(detailNft?.auctionData?.nft_id));
      }
      if (!otherCollection) {
        getOtherCollection();
      }
    }

    if (localStorage.getItem("USER_AUTH_TOKEN")) {
      const userData = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
      setIsOwner(userData?.user_id === detailNft?.auctionData?.owner_id);
    }
  }, [detailNft]);

  const initDetailNft = async () => {
    await dispatch(getDetailAuction(params));
  };

  const initDetailUser = async () => {
    // const creatorData = await getUserDetailById(detailNft?.auctionData?.NFT?.user_id)
    const ownerData = await getUserDetailById(detailNft?.auctionData?.owner_id);

    // setCreatorData(creatorData)
    setOwnerData(ownerData);
  };

  const getOtherCollection = async () => {
    const tempOtherCollection = await dispatch(
      getAllAuction(null, 1, null, detailNft?.auctionData?.collection_id, true)
    );
    setOtherCollection(tempOtherCollection);
  };

  const isExistOnWishlist = (auctionId) => {
    const exist = wishlist.find((w) => w.auction.auction_id === auctionId);
    return exist ? true : false;
  };

  const onUpdateWishlist = () => {
    dispatch(updateWishlistAuction(detailNft));
  };

  const onUserClicked = () => {
    dispatch(setSection("affluence-user", detailNft?.owner_id));
  };
  const onCollectionClicked = () => {
    dispatch(
      setSelectedCollection({
        collection_id: detailNft?.original_collection_id.toString(),
        ...detailNft?.metadata.collection,
      })
    );
    dispatch(
      setSection(
        "affluence-collection",
        detailNft?.original_collection_id.toString()
      )
    );
  };

  const config = {
    data: priceHistory,
    padding: "auto",
    xField: "created_at",
    yField: "total_ibfx",
    xAxis: {
      // type: 'timeCat',
      // tickCount: 5,
    },
  };

  const onPageChange = (num) => {
    // dispatch(getTransactionHistory(num, walletUser.user_id))
  };

  const columns = [
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (amount) =>
        amount === null ? (
          <>-</>
        ) : (
          <RowStart>
            <Image
              preview={false}
              src={IBFxIcon}
              width={18}
              className="mr-4px"
            />

            <Text className="text-size-14 text-w400 mb-0px ml-4px">
              {fromIBFx(amount)}
            </Text>
          </RowStart>
        ),
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Timestamp",
      dataIndex: "created_at",
      key: "created_at",
      render: (dateTime) => (
        <>{moment(dateTime).format("DD/MM/YYYY HH:mm A")}</>
      ),
    },
  ];

  const substractDate = (type, value) => {
    var d = new Date();
    if (type === "day") {
      d.setDate(d.getDate() - value);
    } else if (type === "month") {
      d.setMonth(d.getMonth() - value);
    } else if (type === "year") {
      d.setFullYear(d.getFullYear() - value);
    }
    return d.getTime();
  };

  const onTimeChange = (value) => {
    if (value === "all_time") {
      dispatch(getPriceHistory(detailNft?.auctionData?.nft_id));
    } else if (value === "3days") {
      dispatch(
        getPriceHistory(
          detailNft?.auctionData?.nft_id,
          substractDate("day", 3)
        ),
        new Date.getTime()
      );
    } else if (value === "1week") {
      dispatch(
        getPriceHistory(
          detailNft?.auctionData?.nft_id,
          substractDate("day", 7)
        ),
        new Date.getTime()
      );
    } else if (value === "1month") {
      dispatch(
        getPriceHistory(
          detailNft?.auctionData?.nft_id,
          substractDate("month", 1)
        ),
        new Date.getTime()
      );
    } else if (value === "3month") {
      dispatch(
        getPriceHistory(
          detailNft?.auctionData?.nft_id,
          substractDate("month", 3)
        ),
        new Date.getTime()
      );
    } else if (value === "6month") {
      dispatch(
        getPriceHistory(
          detailNft?.auctionData?.nft_id,
          substractDate("month", 6)
        ),
        new Date.getTime()
      );
    } else if (value === "1year") {
      dispatch(
        getPriceHistory(
          detailNft?.auctionData?.nft_id,
          substractDate("year", 1)
        ),
        new Date.getTime()
      );
    }
  };

  const formatAttributes = (type) => {
    const attribute = detailNft?.metadata?.attributes;

    if (!attribute) {
      return;
    }

    if (type === "properties") {
      const filteredProperties = attribute?.filter(
        (item) => !item.display_type
      );
      return filteredProperties.map((item, index) => (
        <Col
          key={"property" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="label">{item.trait_type}</div>
            <div className="name">{item.value}</div>
            {/* <div className="value">61% have this trait</div> */}
          </PropertiesCard>
        </Col>
      ));
    } else if (type === "levels") {
      const filteredLevels = attribute?.filter(
        (item) => item.display_type === "Levels"
      );
      return filteredLevels.map((item, index) => (
        <Col
          key={"levels" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="name">{item.trait_type}</div>
            <Progress
              className="mt-8px"
              percent={Math.round(
                (parseInt(item.value) / parseInt(item.max_value)) * 100
              )}
              trailColor="#DEE2E7"
              strokeColor="#3B4552"
            />
          </PropertiesCard>
        </Col>
      ));
    } else if (type === "stats") {
      const filteredStats = attribute?.filter(
        (item) => item.display_type === "Stats"
      );
      return filteredStats.map((item, index) => (
        <Col
          key={"stats" + index}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <PropertiesCard>
            <div className="name">{item.trait_type}</div>
            <div className="value">
              {Math.round(
                (parseInt(item.value) / parseInt(item.max_value)) * 100
              )}
              % have this trait
            </div>
          </PropertiesCard>
        </Col>
      ));
    }
  };

  const formatLink = (additionalUrl, link) => {
    const isHaveWWW = link.includes("www");
    const isHaveHTTP = link.includes("http");
    const isHaveOnlyCOM = !(isHaveHTTP || isHaveHTTP) && link.includes("com");
    if (isHaveHTTP) {
      return link;
    } else if (isHaveHTTP && isHaveWWW) {
      return link;
    } else if (!isHaveHTTP && isHaveWWW) {
      return `http://${link}`;
    } else if (isHaveOnlyCOM) {
      return `http://${link}`;
    } else {
      return `${additionalUrl}${link}`;
    }
  };

  const checkAttribute = (type) => {
    if (detailNft) {
      const attribute = detailNft?.metadata?.attributes;
      if (type === "properties") {
        return attribute?.find((item) => !item.display_type);
      } else if (type === "properties") {
        return attribute?.find((item) => item.display_type === "Levels");
      } else if (type === "stats") {
        return attribute?.find((item) => item.display_type === "Stats");
      }
    }
  };

  const renderDetailInfo = () => (
    <Row gutter={[24, 24]}>
      <Col xs={24} sm={24} md={12} lg={10} xl={10} xxl={10}>
        <Row>
          <Col span={24}>
            <Collapse defaultActiveKey={["description"]}>
              <Panel header={t("description")} key="description">
                <Row gutter={[16, 16]}>
                  <Text>
                    {detailNft?.metadata?.description.length > 170 &&
                    !isReadMoreActive
                      ? [
                          detailNft?.metadata?.description.substring(0, 169) +
                            "...",
                          <>
                            <br />
                            <a onClick={() => setReadMoreActive(true)}>
                              {t("read_more")}
                            </a>
                          </>,
                        ]
                      : detailNft?.metadata?.description}
                  </Text>
                </Row>
              </Panel>
              {checkAttribute("properties") && (
                <Panel header={t("properties")} key="properties">
                  <Row gutter={[16, 16]}>
                    {detailNft && formatAttributes("properties")}
                  </Row>
                </Panel>
              )}
              {checkAttribute("levels") && (
                <Panel header={t("levels")} key="levels">
                  <Row gutter={[16, 16]}>
                    {detailNft && formatAttributes("levels")}
                  </Row>
                </Panel>
              )}
              {checkAttribute("stats") && (
                <Panel header={t("stats")} key="stats">
                  <Row gutter={[16, 16]}>
                    {detailNft && formatAttributes("stats")}
                  </Row>
                </Panel>
              )}
              <Panel header="Details" key="details">
                <Row>
                  <Col span={24}>
                    <RowSpaceBetween>
                      <Text className="text-size-14 text-w400 mb-0px ">
                        Contact Address
                      </Text>
                      <Text className="text-size-16 text-w500 mb-0px ">
                        {detailNft?.auctionData?.algo_address
                          ? detailNft?.auctionData?.algo_address.substring(
                              0,
                              7
                            ) +
                            "..." +
                            detailNft?.auctionData?.algo_address.substring(
                              detailNft?.auctionData?.algo_address.length - 7,
                              detailNft?.auctionData?.algo_address.length - 1
                            )
                          : "-"}
                      </Text>
                    </RowSpaceBetween>
                    <RowSpaceBetween className="mt-12px">
                      <Text className="text-size-14 text-w400 mb-0px ">
                        Token ID
                      </Text>
                      <Text className="text-size-16 text-w500 mb-0px ">
                        {detailNft?.auctionData?.NFT?.asa_id}
                      </Text>
                    </RowSpaceBetween>
                    <RowSpaceBetween className="mt-12px">
                      <Text className="text-size-14 text-w400 mb-0px ">
                        {t("token_standard")}
                      </Text>
                      <Text className="text-size-16 text-w500 mb-0px ">
                        ARC-721
                      </Text>
                    </RowSpaceBetween>
                    <RowSpaceBetween className="mt-12px">
                      <Text className="text-size-14 text-w400 mb-0px ">
                        Blockchain
                      </Text>
                      <Text className="text-size-16 text-w500 mb-0px ">
                        Algorand
                      </Text>
                    </RowSpaceBetween>
                    <RowSpaceBetween className="mt-12px">
                      <Text className="text-size-14 text-w400 mb-0px ">
                        {t("creator")}
                      </Text>
                      <Text className="text-size-16 text-w500 mb-0px ">
                        {detailNft?.auctionData?.original_owner?.name}
                      </Text>
                    </RowSpaceBetween>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={14} xl={14} xxl={14}>
        <RowSpaceBetween>
          <Text className="text-size-14 text-w600 mb-0px ">
            {t("minimum_bid")}
          </Text>
          <Text className="text-size-14 text-w600 mb-0px ">
            {t("auction_end_in")}
          </Text>
        </RowSpaceBetween>
        <RowSpaceBetween>
          <RowStart>
            <Image preview={false} src={IBFxIcon} width={24} />
            <Text className="text-size-20 text-w600 gray-6 mb-0px ml-8px">
              {formatterCurrency.format(
                fromIBFx(
                  detailNft?.transaction?.total_ibfx ||
                    detailNft?.auctionData?.minimum_bid
                )
              )}
            </Text>
            <Text className="text-size-20 text-w400 gray-6 mb-0px ml-8px">
              ($
              {formatterCurrency.format(
                (
                  fromIBFx(
                    detailNft?.transaction?.total_ibfx ||
                      detailNft?.auctionData?.minimum_bid
                  ) / ibfxPerUSD
                ).toFixed(2)
              )}
              )
            </Text>
          </RowStart>
          <RowEnd>
            <Text className="text-size-20 text-w600 gray-6 mb-0px ml-8px">
              {!isExpired
                ? t("auction_expired")
                : `${days}D : ${hours}H : ${minutes}M`}
            </Text>
          </RowEnd>
        </RowSpaceBetween>
        <RowSpaceBetween className="mt-20px mb-16px">
          <ButtonOutline
            block
            className="mr-8px"
            style={{ height: 40, borderRadius: 4 }}
            onClick={() =>
              window.open(
                `${ALGO_EXPLORER_URL}/asset/${detailNft?.auctionData?.NFT?.asa_id}`,
                ""
              )
            }
          >
            {t("view_on_algo")}
          </ButtonOutline>
          {!isOwner ? (
            <ModalLoansNewRequest
              isPayLater
              loanAmount={fromIBFx(detailNft?.price)}
              disabled={!isExpired}
            />
          ) : (
            <ModalDelistingAuctionNFT
              isFromDetail
              data={detailNft}
              auction_id={detailNft?.auctionData.auction_id}
              name={detailNft?.metadata?.name}
              collection={detailNft?.metadata?.collection?.name}
              fragmentation={detailNft?.metadata?.fragmentation}
              listed_on={detailNft?.created_at}
              price={fromIBFx(detailNft?.price)}
              ipfsHash={detailNft?.metadata?.image?.replace("ipfs://", "")}
            />
          )}
        </RowSpaceBetween>
        {!isOwner && (
          <ModalAffluencePlaceBid
            auctionId={param}
            fragmentation={detailNft?.auctionData?.NFT?.fragmentation || 0}
            disabled={!isExpired}
          />
        )}
        {renderTable()}
      </Col>
    </Row>
  );

  const renderTable = () => {
    const formatedData = detailNft?.transaction
      ? [
          {
            event: detailNft?.transaction?.type,
            price: detailNft?.transaction?.total_ibfx,
            from: detailNft?.transaction?.user?.algo_address,
            created_at: detailNft?.transaction?.created_at,
          },
        ]
      : [];

    return (
      <Row className="pt-24px">
        <Col span={24}>
          <Table
            dataSource={formatedData || []}
            columns={columns}
            pagination={{
              onChange: onPageChange,
              current: 1,
              pageSize: 15,
              total: 15,
            }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <SEO
      title={detailNft?.metadata?.name}
      description={detailNft?.metadata?.description}
      image={
        CREDENCE_HOST + "/files/" + detailNft?.auctionData?.NFT?.cover_file
      }
    >
      <Row className="mt-24px" gutter={[24, 24]}>
        <Col xs={24} sm={24} md={12} lg={10} xl={10} xxl={10}>
          <ColCenter className="h-100 w-100" style={{ aspectRatio: "1/1" }}>
            {detailNft?.auctionData?.NFT?.cover_file ? (
              <Image
                preview={false}
                alt={detailNft?.metadata?.name}
                width={"100%"}
                height={"500px"}
                style={{ borderRadius: 4, objectFit: "cover" }}
                src={
                  CREDENCE_HOST +
                  "/files/" +
                  detailNft?.auctionData?.NFT?.cover_file
                }
              />
            ) : (
              <Image
                preview={false}
                alt={detailNft?.metadata?.name}
                width={"100%"}
                height={"100%"}
                style={{ borderRadius: 4, objectFit: "cover" }}
                src={`https://ipfs.io/ipfs/${detailNft?.metadata?.image?.replace(
                  "ipfs://",
                  ""
                )}`}
              />
            )}
          </ColCenter>
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
          <RowSpaceBetween>
            <TitleStyled>{detailNft?.metadata?.name}</TitleStyled>
            <RowEnd>
              {detailNft?.metadata?.twitter_link && (
                <IconStyled
                  src={IconTwitter}
                  onClick={() => {
                    if (detailNft?.metadata?.twitter_link) {
                      const link = formatLink(
                        "https://twitter.com/",
                        detailNft?.metadata?.twitter_link
                      );
                      window.open(link, "_blank");
                    }
                  }}
                  width={24}
                />
              )}
              {detailNft?.metadata?.instagram_link && (
                <IconStyled
                  src={IconInstagram}
                  onClick={() => {
                    if (detailNft?.metadata?.instagram_link) {
                      const link = formatLink(
                        "https://instagram.com/",
                        detailNft?.metadata?.instagram_link
                      );
                      window.open(link, "_blank");
                    }
                  }}
                  width={24}
                />
              )}
              {detailNft?.metadata?.web_link && (
                <IconStyled
                  src={IconWeb}
                  onClick={() => {
                    if (detailNft?.metadata?.web_link) {
                      const link = formatLink(
                        "",
                        detailNft?.metadata?.web_link
                      );
                      window.open(link, "_blank");
                    }
                  }}
                  width={24}
                />
              )}
              {isExistOnWishlist(detailNft?.auctionData?.auction_id) ? (
                <IconStyled
                  src={IconLove}
                  onClick={() => onUpdateWishlist()}
                  width={22}
                />
              ) : (
                <IconStyled
                  src={IconUnloved}
                  onClick={() => onUpdateWishlist()}
                  width={22}
                />
              )}
            </RowEnd>
          </RowSpaceBetween>
          <RowSpaceBetween className="mt-16px">
            <RowStart className="pointer" onClick={onUserClicked}>
              <Avatar
                preview={false}
                src={
                  ownerData?.photo !== null
                    ? `${MEMBERSHIP_HOST}/files/${ownerData?.photo}`
                    : UserDummy
                }
                width={36}
                shape="square"
              />
              <ColStart className="ml-8px">
                <TextLabel className="mb-0px">
                  {t("owner").toLocaleUpperCase()}
                </TextLabel>
                <TextValue className="mb-0px">{ownerData?.name}</TextValue>
              </ColStart>
            </RowStart>
            <RowEnd>
              <ColEnd className="end mr-8px" onClick={onCollectionClicked}>
                <TextLabel className="mb-0px">
                  {t("collection").toLocaleUpperCase()}
                </TextLabel>
                <TextValue className="mb-0px">
                  {detailNft?.metadata?.collection?.name || "No Collection"}
                </TextValue>
              </ColEnd>
              <Avatar
                preview={false}
                src={
                  detailNft?.auctionData?.NFT?.Collection?.NFTs?.length > 0
                    ? `${CREDENCE_HOST}/files/${detailNft?.auctionData?.NFT?.Collection?.NFTs[0]?.cover_file}`
                    : UserDummy
                }
                width={36}
                shape="square"
              />
            </RowEnd>
          </RowSpaceBetween>
          {detailNft.content !== null && (
            <RowStart className="mt-16px">
              <UnlockableContent>
                <Image preview={false} src={LockMini} width={12} />
                <div className="ml-4px">{t("include_unlockable")}</div>
              </UnlockableContent>
              {detailNft?.metadata?.seller_fee_basis_point / 100 >= 100 && (
                <Popover
                  placement="bottomRight"
                  title="Creator's Royalty"
                  content={
                    <div style={{ width: "150px" }}>{t("royalty_tooltip")}</div>
                  }
                >
                  <FullRoyalty>100% {t("royalty")}</FullRoyalty>
                </Popover>
              )}
            </RowStart>
          )}
          <PriceHistory>
            <RowSpaceBetween>
              <Title
                className="text-size-14 text-w400 mb-0px "
                level={5}
                align="left"
              >
                {t("nft_name")}
              </Title>

              <Select
                defaultValue="all_time"
                style={{ width: 120 }}
                onChange={(value) => onTimeChange(value)}
              >
                <Option value="all_time">All Time</Option>
                <Option value="3days">3 Days</Option>
                <Option value="1week">1 Week</Option>
                <Option value="1month">1 Month</Option>
                <Option value="3month">3 Months</Option>
                <Option value="6month">6 Months</Option>
                <Option value="1year">1 year</Option>
              </Select>
            </RowSpaceBetween>
            <DividerCard />
            <Line {...config} style={{ height: 250 }} />
          </PriceHistory>
        </Col>
      </Row>
      {renderDetailInfo()}
      {otherCollection.length > 0 && (
        <MoreStyled>{t("more_collection")}</MoreStyled>
      )}
      <Row gutter={[16, 16]}>
        {otherCollection.length > 0 &&
          otherCollection.map((nft) => {
            return (
              <CardAuction
                name={nft.name}
                collection={nft.collection}
                price={nft.price}
                image={nft.image}
              />
            );
          })}
      </Row>
    </SEO>
  );
}
