import styled from "@emotion/styled";
import { RowSpaceBetween } from "../../components/general_styled";

export const BannerStyled = styled(RowSpaceBetween)`
  padding: ${(props) => (props.isDekstop ? "0px 48px" : "24px 24px")};
  gap: 8px;
  background: #15382e;
  border-radius: 10px;
  margin-top: 12px;
  min-height: ${(props) => (props.isDekstop ? "348px" : "200px")};

  .title {
    //styleName: Paragraph 02/ Regular;
    font-size: ${(props) => (props.isDekstop ? "16px" : "12px")};
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  .desc {
    //styleName: Heading 03 / Semi Bold;
    font-family: Roboto;
    font-size: ${(props) => (props.isDekstop ? "34px" : "18px")};
    font-weight: 500;
    line-height: ${(props) => (props.isDekstop ? "40px" : "20px")};
    letter-spacing: 0em;
    text-align: left;
    color: #56bc6c;
    margin: 0px 0px 12px 0px;
  }

  .action {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 183% */

    /* BG White */

    color: #fcfdff;
  }
`;

export const ButtonStyled = styled.div`
  background: #56bc6c;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #15382e;
  border: none;
  padding: ${(props) => (props.isDekstop ? "12px 12px" : "12px 12px")};
  border-radius: 6px;
  cursor: pointer;
  width: 140px;

  &:hover {
    background: #489c5a;
    color: #0c211b;
  }
`;

export const FooterStyled = styled(RowSpaceBetween)`
  gap: 8px;
  background: #eaeeeb;
  border-radius: 6px;
  margin-top: 12px;

  .border-right {
    border-right: ${(props) => (props.isDekstop ? "dashed #d4dad7" : "none")} ;
    border-bottom: ${(props) => (props.isDekstop ? "none" : "dashed #d4dad7")} ;
  }

  .border-bottom {
    border-bottom: dashed #d4dad7;
    margin-bottom: ${(props) => (props.isDekstop ? "0" : "12px")};
  }

  .title {
    //styleName: Paragraph 02/ Regular;
    font-size: ${(props) => (props.isDekstop ? "18px" : "14px")};
    font-family: Inter;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .desc {
    //styleName: Heading 03 / Semi Bold;
    font-size: ${(props) => (props.isDekstop ? "14px" : "12px")};
    line-height: ${(props) => (props.isDekstop ? "22px" : "18px")};
    font-weight: 400;
    color: #657370;
  }

  .img {
    height: ${(props) => (props.isDekstop ? "50px" : "45px")};
  }

  .sdg-img {
    width: ${(props) => (props.isDekstop ? "100%" : "80%")};
  }
`;
