import styled from "@emotion/styled";
import { Card } from "antd";

export const MainCard = styled(Card)`
  border-radius: 4px;
  min-width: 248px;

  .ant-image-img {
    height: 165px;
    object-fit: cover;
  }

  @media screen and (max-width: 1000px) {
    margin: 0px 0 24px 0px;

    &.mini {
      margin-right: 12px !important;
    }
  }

  @media screen and (min-width: 1000px) {
    margin: 0px 24px 24px 0px;
  }

  @media screen and (min-width: 1200px) {
    .ant-image-img {
      min-height: 220px;
      max-height: 50vh;
      object-fit: cover;
    }
  }

  @media screen and (min-width: 1800px) {
    .ant-image-img {
      min-height: 296px;
      max-height: 30vh;
      object-fit: cover;
    }
  }
`;

export const GreenText = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  letter-spacing: 1px;

  color: #38cb89;
`;

export const CardSideStyled = styled(Card)`
  // padding: 24px;
  width: 100%;

  background: #ffffff;
  /* Neutral/500 */

  border: 1px solid #edeff2;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  border-radius: 4px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;
