import React, { useEffect, useState } from "react";
import {
  Modal,
  Steps,
  Drawer,
  Typography,
  message,
  Upload,
  Switch,
  Row,
  Col,
  Button,
  Image,
  Alert,
  Progress,
  Spin,
  Select,
  AutoComplete,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  DropboxOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GlobalOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  ContentStyled,
  StepContentStyled,
  StepsStyled,
  AddButtonStyled,
  CanvasNftDetails,
  DividerCard,
  TotalDue,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import {
  ButtonStyled,
  ColCenter,
  ButtonOutline,
  InputStyled,
  RowSpaceBetween,
  RowStart,
  ColStart,
  TextAreaStyled,
  InputNumberStyled,
  RowEnd,
  RowCenter,
  SelectStyled,
  AutoCompleteStyled,
} from "../general_styled";
import useWindowDimensions from "../../utils/windowDimensions";
import {
  CREDENCE_HOST,
  membershipBasicAuth,
  MEMBERSHIP_HOST,
} from "../../configs";
import { COMMON_CONSTANT, INPUT_STATUS } from "../../constants/constant_common";
import GridFour from "../../assets/images/grid-four.svg";
import FileAdd from "../../assets/images/file-add.svg";
import IconBoxes from "../../assets/images/icon_boxes.svg";
import StarCredence from "../../assets/images/star-credence.svg";
import ColumnUp from "../../assets/images/column-up.svg";
import ViewList from "../../assets/images/view-list.svg";
import chevronLeftIcon from "../../assets/images/chevron-left.svg";
import ibfx1 from "../../assets/images/ibfx-1.svg";
import axios from "axios";
import {
  createNFT,
  getCredenceNFTByUserId,
  uploadCoverImage,
} from "../../modules/credence/action";
import { setSection } from "../../modules/general/action";
import { decryptMnemonic, fromIBFx } from "../../utils/utils";
import ReactCodeInput from "react-code-input";
import { useDispatch, useSelector } from "react-redux";
import Pdf from "react-pdf-viewer-js";
import FileResizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";
import CredenceOnBoarding from "../../pages/CredenceOnBoarding";

const PDFJS = window["pdfjs-dist/build/pdf"];
PDFJS.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js";

const { Step } = Steps;
const { Text, Title } = Typography;
const { Dragger } = Upload;

const dataURLtoFile = async (dataurl, filename) => {
  let image = await fetch(dataurl);
  image = await image.blob();
  return new File([image], filename, { type: "image/png" });
};

export default function ModalCreateNFT(props) {
  const windowDimensions = useWindowDimensions();
  const { t } = useTranslation();
  const steps = [
    { title: "Details" },
    { title: "Traits" },
    { title: "Overview" },
  ];

  const dispatch = useDispatch();

  const credenceCurrentPage = useSelector((state) =>
    state.getIn(["credence", "nfts", "currentPage"])
  );
  const nftMaxPercentage = useSelector((state) =>
    state.getIn(["credence", "nftMaxPercentage"])
  );
  const userCollections = useSelector((state) =>
    state.getIn(["credence", "userCollections"]).toJS()
  );
  const credenceUser = useSelector((state) =>
    state.getIn(["credence", "user"]).toJS()
  );

  const [current, setCurrent] = useState(0);
  const [visible, setVisible] = useState(false);
  const [continueDisabled, setContinueDisabled] = useState(false);
  const [file, setFile] = useState();
  const [fileTemp, setFileTemp] = useState();
  const [fileCover, setCoverFile] = useState();
  //   const [compressedImage, setCompressedImage] = useState();
  const [fileCoverTemp, setCoverFileTemp] = useState();
  const [feeBasis, setFeeBasis] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [isFilePdf, setFilePdf] = useState(false);
  const [typeFile, setTypeFile] = useState();
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isNameOverlap, setNameOverlap] = useState(false);
  const [isCollection, setCollection] = useState(false);
  const [showCoverImage, setShowCoverImage] = useState(false);
  // const [isPdfOrAudio, setIsPdfOrAudio] = useState(false);
  const [isMoreOneFragment, setMoreOneFragment] = useState(false);
  const [nftName, setNftName] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [description, setDescription] = useState("");
  const [fragmentation, setFragmentation] = useState();
  const [externalLink, setExternalLink] = useState("");
  const [webLink, setWebLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [properties, setProperties] = useState([]);
  const [levels, setLevels] = useState([]);
  const [stats, setStats] = useState([]);
  const [pinCode, setPinCode] = useState("");
  const [isPinError, setPinError] = useState(false);
  const [processingPercent, setProcessingPercent] = useState(10);
  const [page, setPage] = useState(1);
  const [resultAutoComplete, setResultAutoComplete] = useState([]);
  const [maxPercentage, setMaxPercentage] = useState(0);

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const handleCancel = () => {
    setVisible(false);
    setCurrent(0);
    setFile();
    setFileTemp();
    setCoverFileTemp();
    setIsUpload(false);
    setProcessing(false);
    setSuccess(false);
    setFailed(false);
    setCollection(false);
    setNftName("");
    setDescription("");
    setCollectionName("");
    setCollection(false);
    setFragmentation(false);
    setExternalLink("");
    setWebLink("");
    setInstagramLink("");
    setTwitterLink("");
    setProperties([]);
    setLevels([]);
    setStats([]);
    setPinCode("");
    setFeeBasis(null);
    setPinError(false);
    setNameOverlap(false);
    setProcessingPercent(10);
  };

  const toApproval = () => {
    props.toApproval();
    handleCancel();
  };

  const showModal = () => {
    setVisible(true);
  };

  const onBack = () => {
    setCurrent(current - 1);
    setContinueDisabled(false);
  };

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async () => {
    setPinError(false);
    const _check = checkPin();
    if (!_check) {
      setPinCode("");
      return setPinError(true);
    }

    try {
      setProcessing(true);

      let cover_file = null;
      if (fileCover) {
        const formData = new FormData();
        // const fileObj = await dataURLtoFile(fileCover, uuidv4());
        const resizeImage = await resizeFile(fileCover);
        // new Compressor(resizeImage, {
        // 	quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        // 	success: (compressedResult) => {
        // 		// compressedResult has the compressed file.
        // 		// Use the compressed file to upload the images to your server.
        // 		setCompressedImage(compressedResult)
        // 	},
        // })

        formData.append("file", resizeImage, `${uuidv4()}.jpeg`);

        const uploadCover = await uploadCoverImage(formData);
        cover_file = uploadCover.data.filename;
      }

      const data = {
        name: nftName,
        description: description,
        ipfs_hash: file,
        cover_file,
        is_pdf: isFilePdf,
        fragmentation: isMoreOneFragment ? fragmentation : 1,
        collection_name: isCollection ? collectionName : null,
        fee: 200000,
        fee_ibfx2: 0,
        category: typeFile,
        external_link: externalLink !== "" ? externalLink : null,
        web_link: webLink !== "" ? webLink : null,
        instagram_link: instagramLink !== "" ? instagramLink : null,
        twitter_link: twitterLink !== "" ? twitterLink : null,
        seller_fee_basis_point: feeBasis * 100,
        properties: properties.map((p) => {
          return {
            name: p.type.value,
            value: p.name.value,
          };
        }),
        levels: levels.map((l) => {
          return {
            name: l.name.value,
            value: l.currentValue.value.toString(),
            max_value: l.fromValue.value.toString(),
          };
        }),
        stats: stats.map((s) => {
          return {
            name: s.name.value,
            value: s.currentValue.value.toString(),
            max_value: s.fromValue.value.toString(),
          };
        }),
      };

      // const getMnemonic = localStorage.getItem(
      //   COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      // )
      // const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode)
      await createNFT(data);

      message.success(t("create_nft_success"));
      dispatch(getCredenceNFTByUserId(null, credenceCurrentPage));

      setProcessingPercent(100);
      setProcessing(false);
      setSuccess(true);
    } catch (e) {
      setProcessingPercent(0);
      setProcessing(false);
      setFailed(true);
      message.error(t("create_nft_success"));
    }
  };

  const resolveContinueButton = () => {
    let text = isSuccess || isFailed ? t("dismiss") : t("continue");

    return (
      <ButtonOutline
        size="large"
        style={{ width: windowDimensions.width > 835 ? "20%" : "100%" }}
        type="primary"
        onClick={() => {
          if (current === 0 && file && nftName && description) {
            setCurrent(current + 1);
          } else {
            setCurrent(current + 1);
          }
        }}
        disabled={
          continueDisabled ||
          isProcessing ||
          !(file && nftName && description && feeBasis) ||
          feeBasis > maxPercentage
        }
      >
        {current === 2 ? t("submit_for_review") : text}
      </ButtonOutline>
    );
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        700,
        700,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.addEventListener("load", () => callback(reader.result));
  };

  const beforeUpload = (file) => {
    if (!/jpe?g|png|pdf|svg|gif|heic|mp3|flac|wav|m4a$/.test(file.type)) {
      // message.error('Picture format error');
      file.flag = true;
      return false;
    }

    let firstFeePercentage = 0;

    if (/jpe?g|png|svg|gif$/.test(file.type)) {
      setTypeFile("ARTWORK");
      firstFeePercentage = Number(
        nftMaxPercentage.find((i) => i.key === "ARTWORK")?.first_fee_percentage
      );
    }

    if (/pdf$/.test(file.type)) {
      setTypeFile("EBOOK");
      firstFeePercentage = Number(
        nftMaxPercentage.find((i) => i.key === "EBOOK")?.first_fee_percentage
      );
    }

    if (/mp3|flac|wav|m4a$/.test(file.type)) {
      setTypeFile("AUDIO");
      firstFeePercentage = Number(
        nftMaxPercentage.find((i) => i.key === "AUDIO")?.first_fee_percentage
      );
    }

    setMaxPercentage((Number(10000) - Number(firstFeePercentage)) / 100);

    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error(`${t("error_size_limit")} 50MB!`);
      return false;
    }

    return true;
  };

  const onUpload = async (param) => {
    try {
      const formData = new FormData();

      formData.append("file", param.file, param.file.name);
      const response = (
        await axios.post(`${CREDENCE_HOST}/nft/upload`, formData)
      ).data;

      if (/jpe?g|png|svg|gif$/.test(param.file.type)) {
        setCoverFile(param.file);
      }

      param.onSuccess(response.data, param.file);
    } catch (e) {
      const error = e.response
        ? e.response.data
          ? e.response.data
          : e.response
        : "Failed to upload document";
      param.onError(error, undefined);
    }
  };

  const onGetCoverImageFromPDF = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = function (ev) {
      var loadingTask = PDFJS.getDocument(fileReader.result);

      loadingTask.promise.then(
        function (pdf) {
          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(function (page) {
            var scale = 1.5;
            var viewport = page.getViewport({ scale: scale });

            var canvas = document.getElementById("the-canvas");
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            var renderTask = page.render(renderContext);

            renderTask.promise.then(function async() {
              dataURLtoFile(canvas.toDataURL("image/jpeg")).then((val) =>
                setCoverFile(val)
              );
            });
          });
        },
        function (error) {
          console.log(error);
        }
      );
    };
    fileReader.readAsArrayBuffer(file);
  };

  const propsFile = {
    name: "file",
    multiple: false,
    // action: `${CREDENCE_HOST}/nft/upload`,
    beforeUpload: beforeUpload,
    customRequest: onUpload,
    onChange: (info) => {
      const { status, response } = info.file;
      if (status === "uploading") {
        setIsUpload(true);
      }
      // console.log(info);
      if (status === "done") {
        // getBase64(info.file.originFileObj, (fileUrl) => setFileTemp(fileUrl))
        const base64File = URL.createObjectURL(info.file.originFileObj);
        setFileTemp(base64File);
        if (/pdf$/.test(info.file.type)) {
          onGetCoverImageFromPDF(info.file.originFileObj);
          setFilePdf(true);
        } else {
          setFilePdf(false);
        }

        // if (
        //   /pdf$/.test(info.file.type) ||
        //   /mp3|flac|wav$/.test(info.file.type)
        // ) {
        //   setIsPdfOrAudio(true);
        // }

        setFile(response?.ipfs_hash);
        message.success(`${info.file.name} ${t("file_upload_success")}`);
        setIsUpload(false);
      } else if (status === "error") {
        message.error(`${info.file.name}  ${t("file_upload_failed")}`);
        setIsUpload(false);
      }
    },
  };

  const propsCoverFile = {
    name: "file",
    multiple: false,
    maxCount: 1,
    customRequest: (param) => param.onSuccess({}, param.file),
    onChange: (info) => {
      const { status, response } = info.file;
      // console.log(info);
      // console.log(status);
      // console.log(info.file.name);
      // getData response.data.filename
      if (status !== "uploading") {
      }
      if (status === "done") {
        getBase64(info.file.originFileObj, (imageUrl) => {
          setCoverFileTemp(imageUrl);
        });
        setCoverFile(info.file.originFileObj);
        message.success(`${info.file.name} ${t("file_upload_success")}`);
      } else if (status === "error") {
        message.error(`${info.file.name}  ${t("file_upload_failed")}`);
      }
    },
  };

  const onNameNFTChanged = (e) => {
    if (e.target.value.length > 32) {
      setNameOverlap(true);
    } else {
      setNameOverlap(false);
    }
    setNftName(e.target.value);
  };

  const addProperties = () => {
    properties.push({
      type: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
      name: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
    });
    setProperties([...properties]);
  };

  const addLevels = () => {
    levels.push({
      name: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
      currentValue: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
      fromValue: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
    });
    setLevels([...levels]);
  };

  const addStats = () => {
    stats.push({
      name: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
      currentValue: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
      fromValue: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
    });
    setStats([...stats]);
  };

  const deleteProperty = (key) => {
    setProperties((newProperty) =>
      newProperty.filter((_, index) => index !== key)
    );
  };

  const deleteLevel = (key) => {
    setLevels((newLevels) => newLevels.filter((_, index) => index !== key));
  };

  const deleteStat = (key) => {
    setStats((newStats) => newStats.filter((_, index) => index !== key));
  };

  const handleSearch = (searchText) => {
    setResultAutoComplete(
      !searchText
        ? []
        : userCollections?.data.records.filter(
            (i) => i.name.toLowerCase() === searchText
          )
    );
  };

  const resolveContent = () => {
    switch (current) {
      case 0:
        return (
          <StepContentStyled>
            <div className="detail">
              {fileTemp ? (
                isFilePdf ? (
                  <Pdf file={fileTemp} page={page}>
                    {({ pdfDocument, pdfPage, canvas }) => (
                      <ColCenter>
                        {!pdfDocument && <span>Loading...</span>}
                        {canvas && (
                          <div
                            style={{
                              height: 500,
                              width: "auto",
                              objectFit: "contain",
                            }}
                          >
                            {canvas}
                          </div>
                        )}
                        {Boolean(pdfDocument && pdfDocument.numPages) && (
                          <RowCenter className="w-100 mt-10px">
                            <Button
                              type="primary"
                              style={{ width: "100px" }}
                              disabled={page === 1}
                              onClick={() => setPage(page - 1)}
                            >
                              {t("previous")}
                            </Button>
                            <Button
                              type="primary"
                              className="ml-4px"
                              style={{ width: "100px" }}
                              disabled={page === pdfDocument.numPages}
                              onClick={() => setPage(page + 1)}
                            >
                              {t("next")}
                            </Button>
                          </RowCenter>
                        )}
                      </ColCenter>
                    )}
                  </Pdf>
                ) : (
                  <ColCenter>
                    <img
                      src={fileTemp}
                      alt="avatar"
                      style={{ height: 170, marginTop: -13 }}
                    />
                  </ColCenter>
                )
              ) : (
                <Dragger
                  name="avatar"
                  listType="picture-card"
                  className="mt-24px dragger-nft"
                  showUploadList={false}
                  {...propsFile}
                >
                  {isUpload ? (
                    <Spin />
                  ) : (
                    <div>
                      <p className="ant-upload-drag-icon">
                        <DropboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        {t("click_or_drop")} items
                      </p>
                      <p className="ant-upload-hint">
                        {t("file_types_hint")}: JPG, PNG, SVG & PDF
                      </p>
                    </div>
                  )}
                </Dragger>
              )}
              <Title
                className="text-size-14 text-w400 mb-8px mt-24px"
                level={5}
                align="left"
              >
                {t("nft_name")}
              </Title>
              <InputStyled
                value={nftName}
                onChange={onNameNFTChanged}
                placeholder={t("nft_name_placeholder")}
                size="large"
              />
              {isNameOverlap && (
                <RowStart className="mt-8px align-center">
                  <InfoCircleOutlined style={{ color: "red", fontSize: 13 }} />
                  <Text
                    className="ml-4px text-size-12"
                    style={{ color: "red" }}
                  >
                    {t("name_limit_warning")}
                  </Text>
                </RowStart>
              )}
              {/* {isPdfOrAudio && ( */}
              <>
                <RowSpaceBetween className="mt-24px">
                  <RowStart>
                    <img src={FileAdd} alt="grid-four" />
                    <ColStart className="ml-8px">
                      <Title
                        className="text-size-14 text-w500 mb-0px mt-0px"
                        level={5}
                        align="left"
                      >
                        {t("cover_image")}
                      </Title>
                      <Text className="gray-5 text-size-12 pr-12px">
                        {t("cover_image_toogle")}
                      </Text>
                    </ColStart>
                  </RowStart>
                  <Switch
                    defaultChecked={false}
                    onChange={(v) => setShowCoverImage(v)}
                  />
                </RowSpaceBetween>
                {showCoverImage && (
                  <ColStart>
                    <Title
                      className="text-size-14 text-w400 mb-8px mt-24px"
                      level={5}
                      align="left"
                    >
                      {t("cover_image")}
                    </Title>
                    <Dragger
                      name="avatar"
                      listType="picture-card"
                      className="dragger-nft"
                      showUploadList={false}
                      {...propsCoverFile}
                    >
                      {fileCoverTemp ? (
                        <>
                          <img
                            src={fileCoverTemp}
                            alt="avatar"
                            style={{ height: 170, marginTop: -13 }}
                          />
                        </>
                      ) : (
                        <div>
                          <p className="ant-upload-drag-icon">
                            <DropboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            {t("click_or_drop")} Image
                          </p>
                          <p className="ant-upload-hint">
                            {t("file_types_hint")}: JPG & PNG
                          </p>
                        </div>
                      )}
                    </Dragger>
                  </ColStart>
                )}
              </>
              {/* )} */}
              <RowSpaceBetween className="mt-24px">
                <RowStart>
                  <img src={GridFour} alt="grid-four" />
                  <ColStart className="ml-8px">
                    <Title
                      className="text-size-14 text-w500 mb-0px mt-0px"
                      level={5}
                      align="left"
                    >
                      {t("part_of_collection")}
                    </Title>
                    <Text className="gray-5 text-size-12">
                      {t("part_of_collection_toogle")}
                    </Text>
                  </ColStart>
                </RowStart>
                <Switch
                  defaultChecked={false}
                  onChange={(v) => setCollection(v)}
                />
              </RowSpaceBetween>
              {isCollection && (
                <>
                  <Title
                    className="text-size-14 text-w400 mb-8px mt-24px"
                    level={5}
                    align="left"
                  >
                    {t("collection_name")}
                  </Title>
                  {/* <InputStyled
                    value={collectionName}
                    onChange={(e) => setCollectionName(e.target.value)}
                    placeholder='Enter Collection Name'
                    size='large'
                  /> */}
                  {/* <AutoComplete
										showSearch
										optionFilterProp="children"
										placeholder={'Select Project Category'}
										onSelect={(val) => setCollectionName(val)}
										filterOption="name"
									>
										{userCollections?.data.records.map((i) => {
											return <Select.Option value={i.name}>{i.name}</Select.Option>
										})}
									</AutoComplete> */}
                  <AutoCompleteStyled
                    onSelect={(val) => setCollectionName(val)}
                    onSearch={handleSearch}
                    placeholder={t("collection_name")}
                    onChange={(val) => setCollectionName(val)}
                  >
                    {resultAutoComplete.length > 0
                      ? resultAutoComplete.map((i) => {
                          return (
                            <AutoComplete.Option value={i.name}>
                              {i.name}
                            </AutoComplete.Option>
                          );
                        })
                      : userCollections?.data?.records?.map((i) => {
                          return (
                            <AutoComplete.Option value={i.name}>
                              {i.name}
                            </AutoComplete.Option>
                          );
                        })}
                  </AutoCompleteStyled>
                </>
              )}

              <RowSpaceBetween className="mt-24px">
                <RowStart>
                  <img src={IconBoxes} alt="grid-four" />
                  <ColStart className="ml-8px">
                    <Title
                      className="text-size-14 text-w500 mb-0px mt-0px"
                      level={5}
                      align="left"
                    >
                      {t("fragmentation")}
                    </Title>
                    <Text className="gray-5 text-size-12">
                      {t("fragmentation_toogle")}
                    </Text>
                  </ColStart>
                </RowStart>
                <Switch
                  defaultChecked={false}
                  onChange={(v) => setMoreOneFragment(v)}
                />
              </RowSpaceBetween>
              {isMoreOneFragment && (
                <>
                  <Title
                    className="text-size-14 text-w400 mb-8px mt-24px"
                    level={5}
                    align="left"
                  >
                    {t("fragmentation_supply")}
                  </Title>
                  <InputNumberStyled
                    type="number"
                    className="w-100"
                    value={fragmentation}
                    onChange={(e) => setFragmentation(e)}
                    placeholder={t("enter_quantity")}
                  />
                </>
              )}
              <Title
                className="text-size-14 text-w400 mb-8px mt-24px"
                level={5}
                align="left"
              >
                {t("royalty_percentage")}
              </Title>
              <InputNumberStyled
                type="number"
                className="w-100 with-addon"
                addonAfter={"%"}
                status={feeBasis > maxPercentage ? "error" : null}
                min={1}
                max={100}
                value={feeBasis}
                onChange={(e) => setFeeBasis(e)}
                placeholder={`${t("maximum_allowed")}: ${maxPercentage}%`}
              />
              {feeBasis > maxPercentage ? (
                <RowStart className="mt-8px align-center">
                  <InfoCircleOutlined className="danger" />
                  <Text className="ml-4px text-size-12 danger">
                    {t("amount_limit_warning")}: {maxPercentage}%
                  </Text>
                </RowStart>
              ) : (
                <RowStart className="mt-8px align-center">
                  <InfoCircleOutlined />
                  <Text className="ml-4px text-size-12">
                    {t("amount_limit_warning")}
                  </Text>
                </RowStart>
              )}
              <Title
                className="text-size-14 text-w400 mb-8px mt-24px"
                level={5}
                align="left"
              >
                {t("description")}
              </Title>
              <TextAreaStyled
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t("description_placeholder")}
                size="large"
                rows={4}
              />

              <Title
                className="text-size-14 text-w400 mb-8px mt-24px"
                level={5}
                align="left"
              >
                {t("external_link")}
              </Title>
              <InputStyled
                value={externalLink}
                onChange={(e) => setExternalLink(e.target.value)}
                placeholder={t("external_link_placeholder")}
                size="large"
              />
              <Title
                className="text-size-14 text-w400 mb-8px mt-24px"
                level={5}
                align="left"
              >
                {t("other_links")}{" "}
                <span style={{ color: "#c9c9c9" }}>({t("optional")})</span>
              </Title>
              <InputStyled
                value={webLink}
                onChange={(e) => setWebLink(e.target.value)}
                prefix={<GlobalOutlined className="mr-8px" />}
                placeholder="yoursite.com"
                size="large"
              />
              <InputStyled
                value={twitterLink}
                onChange={(e) => setTwitterLink(e.target.value)}
                className="mt-8px"
                prefix={<TwitterOutlined className="mr-8px" />}
                placeholder="http://twitter.com/twitterhandle"
                size="large"
              />
              <InputStyled
                value={instagramLink}
                onChange={(e) => setInstagramLink(e.target.value)}
                className="mt-8px"
                prefix={<InstagramOutlined className="mr-8px" />}
                placeholder="http://instagram.com/instagramhandle"
                size="large"
              />
            </div>
          </StepContentStyled>
        );

      case 1:
        return (
          <StepContentStyled>
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <RowSpaceBetween>
                  <ColStart>
                    <Title
                      className="text-size-16 text-w600 mb-0px mt-0px"
                      level={5}
                      align="left"
                    >
                      {t("properties")}
                    </Title>
                    <Text
                      style={{ minHeight: 38 }}
                      className="gray-5 text-size-12"
                    >
                      {t("properties_desc")}
                    </Text>
                  </ColStart>
                  <img className="ml-8px" src={ViewList} alt="properties" />
                </RowSpaceBetween>
                <AddButtonStyled onClick={addProperties}>Add</AddButtonStyled>
                <hr color="#D6DAE1" />
                {properties.length > 0 && (
                  <Row className="mt-12px" gutter={[8, 8]}>
                    <Col span={10}>
                      <Title
                        className="text-size-14 text-w400 mb-8px "
                        level={5}
                        align="left"
                      >
                        {t("type")}
                      </Title>
                    </Col>
                    <Col span={10}>
                      <Title
                        className="text-size-14 text-w400 mb-8px "
                        level={5}
                        align="left"
                      >
                        {t("name")}
                      </Title>
                    </Col>
                  </Row>
                )}
                {properties.map((property, key) => (
                  <Row
                    gutter={8}
                    className={key > 0 && "mt-12px"}
                    key={"property" + key}
                  >
                    <Col span={10}>
                      <InputStyled
                        value={property.type.value}
                        onChange={async (val) => {
                          properties[key].type = {
                            validateStatus: INPUT_STATUS.SUCCESS,
                            help: "",
                            value: val.target.value,
                          };
                          setProperties([...properties]);
                        }}
                        placeholder="Enter type"
                      />
                    </Col>
                    <Col span={10}>
                      <InputStyled
                        value={property.name.value}
                        onChange={async (val) => {
                          properties[key].name = {
                            validateStatus: INPUT_STATUS.SUCCESS,
                            help: "",
                            value: val.target.value,
                          };
                          setProperties([...properties]);
                        }}
                        placeholder="Enter name"
                      />
                    </Col>
                    <Col span={2}>
                      <Button
                        icon={<DeleteOutlined style={{ fontSize: 13 }} />}
                        size="large"
                        style={{ borderRadius: "2px" }}
                        onClick={() => deleteProperty(key)}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <RowSpaceBetween>
                  <ColStart>
                    <Title
                      className="text-size-16 text-w600 mb-0px mt-0px"
                      level={5}
                      align="left"
                    >
                      {t("levels")}
                    </Title>
                    <Text
                      style={{ minHeight: 38 }}
                      className="gray-5 text-size-12"
                    >
                      {t("levels_desc")}
                    </Text>
                  </ColStart>
                  <img className="ml-8px" src={StarCredence} alt="levels" />
                </RowSpaceBetween>
                <AddButtonStyled onClick={addLevels}>Add</AddButtonStyled>
                <hr color="#D6DAE1" />
                {levels.length > 0 && (
                  <Row className="mt-12px" gutter={[8, 8]}>
                    <Col span={10}>
                      <Title
                        className="text-size-14 text-w400 mb-8px "
                        level={5}
                        align="left"
                      >
                        {t("name")}
                      </Title>
                    </Col>
                    <Col span={10}>
                      <Title
                        className="text-size-14 text-w400 mb-8px "
                        level={5}
                        align="left"
                      >
                        {t("value")}
                      </Title>
                    </Col>
                  </Row>
                )}

                {levels.map((level, key) => (
                  <Row
                    gutter={8}
                    className={key > 0 && "mt-12px"}
                    key={"level" + key}
                  >
                    <Col span={10}>
                      <InputStyled
                        value={level.name.value}
                        onChange={async (e) => {
                          levels[key].name = {
                            validateStatus: INPUT_STATUS.SUCCESS,
                            help: "",
                            value: e.target.value,
                          };
                          setLevels([...levels]);
                        }}
                        placeholder="Enter name"
                      />
                    </Col>
                    <Col span={10}>
                      <RowSpaceBetween>
                        <InputNumberStyled
                          type="number"
                          value={level.currentValue.value}
                          onChange={async (val) => {
                            levels[key].currentValue = {
                              validateStatus: INPUT_STATUS.SUCCESS,
                              help: "",
                              value: val,
                            };
                            setLevels([...levels]);
                          }}
                          defaultValue={0}
                        />
                        <Text className="gray-5 text-size-12 mx-4px">of</Text>
                        <InputNumberStyled
                          type="number"
                          value={level.fromValue.value}
                          onChange={async (val) => {
                            levels[key].fromValue = {
                              validateStatus: INPUT_STATUS.SUCCESS,
                              help: "",
                              value: val,
                            };
                            setLevels([...levels]);
                          }}
                          defaultValue={0}
                        />
                      </RowSpaceBetween>
                    </Col>
                    <Col span={2}>
                      <Button
                        icon={<DeleteOutlined style={{ fontSize: 13 }} />}
                        size="large"
                        style={{ borderRadius: "2px" }}
                        onClick={() => deleteLevel(key)}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <RowSpaceBetween>
                  <ColStart>
                    <Title
                      className="text-size-16 text-w600 mb-0px mt-0px"
                      level={5}
                      align="left"
                    >
                      {t("stats")}
                    </Title>
                    <Text
                      style={{ minHeight: 38 }}
                      className="gray-5 text-size-12"
                    >
                      {t("stats_desc")}
                    </Text>
                  </ColStart>
                  <img className="ml-8px" src={ColumnUp} alt="stats" />
                </RowSpaceBetween>
                <AddButtonStyled onClick={addStats}>Add</AddButtonStyled>
                <hr color="#D6DAE1" />
                {stats.length > 0 && (
                  <Row className="mt-12px" gutter={[8, 8]}>
                    <Col span={10}>
                      <Title
                        className="text-size-14 text-w400 mb-8px "
                        level={5}
                        align="left"
                      >
                        {t("name")}
                      </Title>
                    </Col>
                    <Col span={10}>
                      <Title
                        className="text-size-14 text-w400 mb-8px "
                        level={5}
                        align="left"
                      >
                        {t("value")}
                      </Title>
                    </Col>
                  </Row>
                )}
                {stats.map((stat, key) => (
                  <Row
                    gutter={8}
                    className={key > 0 && "mt-12px"}
                    key={"level" + key}
                  >
                    <Col span={10}>
                      <InputStyled
                        value={stat.name.value}
                        onChange={async (e) => {
                          stats[key].name = {
                            validateStatus: INPUT_STATUS.SUCCESS,
                            help: "",
                            value: e.target.value,
                          };
                          setStats([...stats]);
                        }}
                        placeholder="Enter name"
                      />
                    </Col>
                    <Col span={10}>
                      <RowSpaceBetween>
                        <InputNumberStyled
                          type="number"
                          value={stat.currentValue.value}
                          onChange={async (val) => {
                            stats[key].currentValue = {
                              validateStatus: INPUT_STATUS.SUCCESS,
                              help: "",
                              value: val,
                            };
                            setStats([...stats]);
                          }}
                          defaultValue={0}
                        />
                        <Text className="gray-5 text-size-12 mx-4px">of</Text>
                        <InputNumberStyled
                          type="number"
                          value={stat.fromValue.value}
                          onChange={async (val) => {
                            stats[key].fromValue = {
                              validateStatus: INPUT_STATUS.SUCCESS,
                              help: "",
                              value: val,
                            };
                            setStats([...stats]);
                          }}
                          defaultValue={0}
                        />
                      </RowSpaceBetween>
                    </Col>
                    <Col span={2}>
                      <Button
                        icon={<DeleteOutlined style={{ fontSize: 13 }} />}
                        size="large"
                        style={{ borderRadius: "2px" }}
                        onClick={() => deleteStat(key)}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </StepContentStyled>
        );

      case 2:
        return (
          <StepContentStyled>
            <Row className="h-100 my-8px " gutter={[24, 24]}>
              <Col
                xs={24}
                sm={24}
                md={windowDimensions.width > 925 ? 12 : 24}
                lg={11}
                xl={11}
                xxl={11}
              >
                <RowCenter>
                  {/* {isFilePdf ? (
                    <Pdf file={fileTemp} page={page}>
                      {({ pdfDocument, pdfPage, canvas }) => (
                        <ColCenter>
                          {!pdfDocument && <span>Loading...</span>}
                          {canvas && (
                            <div
                              style={{
                                height: 500,
                                width: 'auto',
                                objectFit: 'contain',
                              }}
                            >
                              {canvas}
                            </div>
                          )}
                          {Boolean(pdfDocument && pdfDocument.numPages) && (
                            <RowCenter className='w-100 mt-10px'>
                              <Button
                                type='primary'
                                style={{ width: '100px' }}
                                disabled={page === 1}
                                onClick={() => setPage(page - 1)}
                              >
                                Previous
                              </Button>
                              <Button
                                type='primary'
                                className='ml-4px'
                                style={{ width: '100px' }}
                                disabled={page === pdfDocument.numPages}
                                onClick={() => setPage(page + 1)}
                              >
                                Next
                              </Button>
                            </RowCenter>
                          )}
                        </ColCenter>
                      )}
                    </Pdf>
                  ) : ( */}
                  <Image
                    src={fileCoverTemp || fileTemp}
                    alt="img"
                    placeholder={
                      <Image
                        preview={false}
                        src={fileCoverTemp || fileTemp}
                        style={{
                          width: "100%",
                          height: "100%",
                          minHeight: "400px",
                          objectFit: "cover",
                          borderRadius: 4,
                        }}
                      />
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      minHeight: "400px",
                      objectFit: "cover",
                      borderRadius: 4,
                    }}
                  />
                  {/* )} */}
                </RowCenter>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={windowDimensions.width > 925 ? 12 : 24}
                lg={13}
                xl={13}
                xxl={13}
              >
                <Title
                  className="text-w700 gray-7 mb-8px"
                  level={windowDimensions.width > 835 ? 4 : 2}
                >
                  {nftName}
                </Title>
                <Text
                  className={
                    "gray-7 " + (windowDimensions.width < 835 && "text-size-16")
                  }
                >
                  {description}
                </Text>
                <CanvasNftDetails>
                  <Title className="text-w500 gray-7 mb-0px" level={5}>
                    {t("nft_details")}
                  </Title>
                  <DividerCard />
                  <RowSpaceBetween>
                    <Text className="gray-7">{t("collection")}</Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {collectionName}
                    </Text>
                  </RowSpaceBetween>
                  <RowSpaceBetween className="mt-16px">
                    <Text className="gray-7">{t("fragmentation_supply")}</Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {fragmentation}
                    </Text>
                  </RowSpaceBetween>
                  <RowSpaceBetween className="mt-16px">
                    <Text className="gray-7">{t("external_link")}</Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {externalLink}
                    </Text>
                  </RowSpaceBetween>
                  <RowSpaceBetween className="mt-16px">
                    <Text className="gray-7">Twitter</Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {twitterLink}
                    </Text>
                  </RowSpaceBetween>
                  <RowSpaceBetween className="mt-16px">
                    <Text className="gray-7">Instagram</Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {instagramLink}
                    </Text>
                  </RowSpaceBetween>
                </CanvasNftDetails>
              </Col>
            </Row>
          </StepContentStyled>
        );

      case 3:
        if (isProcessing || isSuccess || isFailed) {
          return (
            <StepContentStyled className="center">
              <ColCenter>
                <Progress
                  type="circle"
                  percent={processingPercent}
                  status={isFailed && "exception"}
                />
                <Title className="text-w700 gray-7 mb-8px mt-24px" level={3}>
                  {isProcessing && !isSuccess && !isFailed
                    ? t("processing")
                    : isSuccess && !isFailed
                    ? t("nft_uploaded")
                    : t("failed")}
                </Title>
                <Text
                  style={{ width: "60%" }}
                  className="gray-7"
                  align="center"
                >
                  {isProcessing && !isSuccess && !isFailed
                    ? t("nft_processing")
                    : isSuccess
                    ? t("nft_success_uploaded")
                    : t("nft_failed_uploaded")}
                </Text>
                {!isProcessing && (
                  <ButtonStyled
                    className="mt-24px"
                    type="primary"
                    style={{
                      width: "350px",
                      height: "48px",
                      borderRadius: "4px",
                    }}
                    onClick={() =>
                      isSuccess && !isFailed
                        ? toApproval()
                        : dispatch(setSection("wallet-dashboard"))
                    }
                  >
                    {isSuccess && !isFailed
                      ? t("check_approval")
                      : t("top_up_wallet")}
                  </ButtonStyled>
                )}
                {!isProcessing && (
                  <ButtonOutline
                    className="mt-10px"
                    style={{
                      width: "350px",
                      height: "48px",
                      borderRadius: "4px",
                    }}
                    onClick={() =>
                      isSuccess && !isFailed
                        ? setVisible(false)
                        : dispatch(setSection("wallet-faq", "credence"))
                    }
                  >
                    {isSuccess && !isFailed ? t("dismiss") : t("check_qa")}
                  </ButtonOutline>
                )}
              </ColCenter>
            </StepContentStyled>
          );
        }
        return (
          <StepContentStyled className="center">
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                {t("enter_pin")}
              </Title>
              <Text className="gray-7" align="center">
                {t("enter_pin_desc")}
              </Text>
              <Row className="mt-24px">
                <Col span={24}>
                  <RowCenter>
                    <ReactCodeInput
                      type="password"
                      onChange={(e) => setPinCode(e)}
                      fields={4}
                      {...codeProps}
                    />
                  </RowCenter>
                </Col>
                {isPinError && (
                  <Col className="mt-10px" span={24}>
                    <Alert
                      message={t("enter_pin_error")}
                      type="error"
                      showIcon
                    />
                  </Col>
                )}
              </Row>
              <ButtonStyled
                style={{
                  width: "40%",
                  marginTop: 20,
                  marginBottom: 70,
                }}
                onClick={() => onSubmit()}
                disabled={isProcessing}
              >
                {!isProcessing ? t("confirm_pin") : <Spin />}
              </ButtonStyled>
            </ColCenter>
          </StepContentStyled>
        );
    }
  };

  const resolveChildren = () => {
    if (
      Object.keys(credenceUser).length <= 0 ||
      !localStorage.getItem("ENCRYPTED_MNEMONIC")
    ) {
      return (
        <Modal
          centered
          className="modal-top-up"
          visible={visible}
          footer={null}
          onCancel={handleCancel}
          closeIcon={<img src={closeIcon} onClick={handleCancel} />}
          maskStyle={{ backgroundColor: "#f3f6fa" }}
        >
          <CredenceOnBoarding />
        </Modal>
      );
    } else {
      return windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={visible}
          onCancel={handleCancel}
          className="modal-create-nft"
          destroyOnClose
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
          width="100%"
          footer={
            current >= 3
              ? null
              : [
                  <div className="footer-space-between">
                    <ButtonOutline
                      size="large"
                      style={{
                        width: windowDimensions.width > 835 ? "20%" : 150,
                      }}
                      disabled={current < 1 || current >= 3}
                      onClick={() => onBack()}
                    >
                      {t("previous")}
                    </ButtonOutline>
                    {current < 4 && resolveContinueButton()}
                  </div>,
                ]
          }
        >
          <ContentStyled>
            <ColCenter span={24}>
              <ColCenter style={{ paddingBottom: "24px" }}>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  {t("create_nft")}
                </Title>
                <Text className="gray-7">{t("complete_step_nft")}</Text>
              </ColCenter>
              <StepsStyled
                current={current}
                responsive={false}
                direction="horizontal"
              >
                {steps.map((step, key) => {
                  return (
                    <Step
                      key={key}
                      title={windowDimensions.width < 835 ? "" : step.title}
                    />
                  );
                })}
              </StepsStyled>
              {resolveContent()}
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={visible}
          onCancel={handleCancel}
          placement="bottom"
          className="force-scroll"
          closable={false}
        >
          <ContentStyled>
            <RowSpaceBetween className="w-100">
              {current > 0 && (
                <img
                  style={{ marginRight: 10 }}
                  src={chevronLeftIcon}
                  onClick={() => setCurrent(current - 1)}
                />
              )}
              <img
                style={{ marginRight: 10 }}
                src={closeIcon}
                onClick={handleCancel}
              />
            </RowSpaceBetween>
            <ColCenter>
              <ColCenter style={{ paddingBottom: "24px" }}>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  {t("create_nft")}
                </Title>
                <Text className="gray-7">{t("complete_step_nft")}</Text>
              </ColCenter>
              <StepsStyled
                current={current}
                responsive={false}
                direction="horizontal"
              >
                {steps.map((step, key) => {
                  return (
                    <Step
                      key={key}
                      title={windowDimensions.width < 835 ? "" : step.title}
                    />
                  );
                })}
              </StepsStyled>
              {resolveContent()}
            </ColCenter>
            {current >= 3 ? null : (
              <div className="footer-space-between absolute-bottom">
                {/* <ButtonOutline
					  size="large"
					  className="mr-10px"
					  style={{
						width: 200,
					  }}
					  disabled={current < 1 || current >= 3}
					  onClick={() => onBack()}
					>
					  Previous
					</ButtonOutline> */}
                {current < 4 && resolveContinueButton()}
              </div>
            )}
          </ContentStyled>
        </Drawer>
      );
    }
  };

  return (
    <>
      <canvas
        id="the-canvas"
        style={{ border: "1px solid black", display: "none" }}
      ></canvas>
      <ButtonStyled
        type={"primary"}
        size="large"
        onClick={() => showModal()}
        style={{
          width: "100%",
        }}
      >
        {t("create_nft")}
      </ButtonStyled>
      {resolveChildren()}
    </>
  );
}
