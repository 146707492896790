import { fromJS } from "immutable"
import { GET_BENEVOLENCE_DONORS, GET_BENEVOLENCE_DONORS_FAIL, GET_BENEVOLENCE_DONORS_SUCCESS } from "../constants"

const initialState = fromJS({
  isLoading: false,
  hasError: false,
  errorMessage: "",
  donors: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    totalItems: 0,
    totalPage: 0,
  })
})

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BENEVOLENCE_DONORS:
      return state.merge({
        isLoading: true,
        hasError: false,
      })
    case GET_BENEVOLENCE_DONORS_SUCCESS:
      return state.merge({
        isLoading: false,
        donors: fromJS(action.payload)
      })
    case GET_BENEVOLENCE_DONORS_FAIL:
      return state.merge({
        isLoading: false,
        hasError: true,
        errorMessage: action.payload.errorMessage,
      })
    default:
      return state;
  }
}