import React, { useState, useEffect } from 'react'
import { Typography, Row, Image, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { ButtonStyled, ColCenter, ColStart, RowEnd, RowSpaceBetween } from '../../components/general_styled'
import { SearchStyled } from './styled'
import { getCredenceNFTByUserId } from '../../modules/credence/action'
import { useDispatch, useSelector } from 'react-redux'
import { setSection } from '../../modules/general/action'
import CardNFT from '../../components/CardNFT'
import emptyNfts from '../../assets/images/empty_nfts.svg'
import { getAllTransaction } from '../../modules/affluence/action'
import jwtDecode from 'jwt-decode'
import { TRANSACTION_STATUS } from '../../constants/constant_confluence'

const { Title, Text } = Typography

export default function AffluenceAuctionPurchases() {
	const { t } = useTranslation()
	const [searchVal, setSearchVal] = useState(null)
	const dispatch = useDispatch()

	const pageNfts = useSelector((state) => state.getIn(['credence', 'nfts', 'currentPage']))

	const offers = useSelector((state) => state.getIn(['affluence', 'offers']).toJS())

	const renderSearchBar = () => {
		return (
			<RowEnd>
				<SearchStyled
					className='search-input'
					placeholder='Search'
					autoComplete='off'
					allowClear
					size='large'
					onSearch={(value) => getData(1, value)}
					style={{
						marginRight: '12px',
						width: 300,
					}}
				/>

				<ButtonStyled
					type={'primary'}
					ghost
					size='large'
					style={{
						width: 200,
					}}
					onClick={() => {
						dispatch(setSection('credence'))
					}}
				>
					{t('open_credence')}
				</ButtonStyled>
			</RowEnd>
		)
	}

	const getData = (num, search) => {
		const decoded = jwtDecode(localStorage.getItem('USER_AUTH_TOKEN'))
		dispatch(getAllTransaction(decoded.user_id, num, TRANSACTION_STATUS.BID_ACCEPTED, 'BID', null, search))
	}

	const renderEmpty = (img, title, subtitle) => {
		return (
			<ColCenter style={{ height: '100%', minHeight: 'calc(100vh - 180px)' }}>
				<Image preview={false} src={img} />
				<Title align='center' className='mb-0px mt-24px' level={3}>
					{title}
				</Title>
				<Text style={{ width: '37%', textAlign: 'center' }}>{subtitle}</Text>
			</ColCenter>
		)
	}

	useEffect(() => {
		getData(pageNfts, null)
	}, [])

	return (
		<ColStart>
			<RowSpaceBetween>
				<Title level={3} className='mb-0px mt-0px'>
					{t('purchased')}
				</Title>
				{renderSearchBar()}
			</RowSpaceBetween>
			<Row className='mt-20px'>
				{offers.data.length > 0 ? (
					offers.data.map((nft) => {
						const _nft = nft?.auction?.NFT
						const metadata = JSON.parse(_nft.metadata_json)

						return (
							<CardNFT
								nftId={_nft.nft_id}
								name={metadata.name}
								collectionName={metadata?.collection?.name}
								ipfsHash={metadata.image?.replace('ipfs://', '')}
								is_pdf={metadata.is_pdf || false}
								isPurchase={true}
								data={_nft}
							/>
						)
					})
				) : (
					<Col span={24}>{renderEmpty(emptyNfts, t('no_nft_found'), t('no_nft_desc_2'))}</Col>
				)}
			</Row>
		</ColStart>
	)
}
