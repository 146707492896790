import styled from "@emotion/styled";
import { Typography, Button, Steps, Input } from "antd";

const { Text } = Typography;
const { Search } = Input;

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  // max-width: 1095px;
  // min-height: 610px;

  @media screen and (max-width: 500px) {
    padding-top: 36px;
  }
`;

export const StepsStyled = styled(Steps)`
  background: #fcfcfc;
  border: 1px solid #d6dae1;
  box-sizing: border-box;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  padding: 8px 5%;
  // font-family: "Roboto";
`;

export const StepContentStyled = styled.div`
  min-height: 362px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 24px;
  margin: 1rem 2.5%;

  background: #fcfcfc;
  box-sizing: border-box;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  overflow: auto;

  @media screen and (min-width: 835px) {
    height: 400px;
    width: 95%;
  }

  @media screen and (max-width: 834px) {
    width: 100%;
    height: 58vh;
  }

  &.height-auto {
    min-height: auto;
  }

  &.center {
    justify-content: center;
  }
`;

export const ContentTextStyled = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #273248;

  flex: none;
  order: 0;
  flex-grow: 0;

  max-width: 831px;
`;

export const RedTextStyled = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #f43c3c;

  margin-bottom: 12px;
`;

export const CopyTextStyled = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Daybreak Blue/blue-6 */

  color: #024FF1;
`;

export const ContentIconStyled = styled.img`
  position: absolute;
  right: 24px;
  bottom: 24px;

  width: 96px;
`;

export const UlStyled = styled.ul`
  list-style-type: none !important;
  margin: 0;
  padding: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #273248;
`;

export const LiStyled = styled.li`
  margin: 0 !important;

  &:before {
    content: "• ";
    color: balck;
  }
`;

export const MnemonicSpan = styled.div`
  padding: 8px;

  // width: 160px;
  // max-width: 100%;
  // height: 31px;

  background: #f7f8fa;

  border: 1px solid #edeff2;
  // box-sizing: border-box;

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  // margin: 0px 12px 12px 0px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  /* Text/300 */

  color: #44567b;
`;

export const ButtonMakeImpact = styled(Button)`
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 0;
  width: 200px;
  background-color: #024FF1;

  @media screen and (max-width: 500px) {
    position: relative;
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const SearchStyled = styled(Search)`
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;

  button {
    height: 43px !important;
    /* Neutral/200 */

    background: #f4f6f7;
    /* Neutral/600 */

    border: 1px solid #d6dae1;
    box-sizing: border-box;
    border-radius: 0px 2px 2px 0px;
    border-radius: 2px;
  }

  input {
    background: #f4f6f7;

    border: 1px solid #d6dae1;
    box-sizing: border-box;
    border-radius: 2px;

    padding: 8px 12px;

    &:focus {
      box-shadow: none !important;
    }

    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      /* identical to box height, or 157% */

      /* Text/200 */

      color: #526895;
    }
  }
`;

export const IconCoins = styled.img`
  height: 20px;
  margin-right: 5px;
  &.no-margin {
    margin-right: 0;
  }
`;

export const DividerCard = styled.div`
  width: 95%;
  height: 1px;
  margin: 24px 0;
  margin-right: 5%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const BoxAmountStyled = styled.div`
  /* Shades/White */

  background: #fcfcfc;
  /* Neutral/500 */

  box-sizing: border-box;
  /* Card Shadow */

  // box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px, rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  border-radius: 2px;
  width: 94%;
  margin-right: 5%;
  margin-left: 1%;

  .box-value {
    background: #fafafa;
    padding: 20px;
    border: 1px solid #edeff2;
    box-sizing: border-box;
    border-radius: 2px;

    // font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 157% */

    text-align: center;

    /* Text/400 */

    color: #273248;
  }

  .value {
    // font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height */

    /* Gray/gray-7 */

    color: #273248;
  }

  .label {
    // font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height */

    /* Text/300 */

    color: #44567b;
  }

  .selector {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    margin: 6px;
    cursor: pointer;

    /* Gray/gray-1 */

    background: #fcfcfc;
    /* Neutral/500 */

    border: 1px solid #edeff2;
    box-sizing: border-box;
    border-radius: 2px;

    span {
      /* Semibold / 14px | 22px */

      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      /* identical to box height, or 157% */

      /* Text/300 */

      color: #44567b;
    }

    &.selected {
      background: #fcfcfc;
      /* Primary/500 */

      border: 1px solid #024FF1;
      box-sizing: border-box;
      border-radius: 2px;

      span {
        /* Semibold / 14px | 22px */

        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        /* identical to box height, or 157% */

        /* Primary/500 */

        color: #024FF1;
      }
    }
  }
`;

export const BoxFAQStyled = styled.div`
  background: #f4f6f7;
  padding: 28px;
  width: 100%;
`;
