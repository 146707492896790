import React, { useState, useEffect } from "react"
import { Modal, Typography, Button, Row, Col, Drawer, Progress } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import { ButtonOutline, ColCenter, RowCenter } from "../general_styled"
import { CardStyled, TitleStyled, SubtitleStyled } from "./styled"

import { ContentStyled } from "./styled"
import closeIcon from "../../assets/images/close.svg"
import useWindowDimensions from "../../utils/windowDimensions"
import { AUCTION_STATUS } from "../../constants/constant_credence"
import ModalPinAuction from "./ModalPinAuction"

const { Title, Text } = Typography

export default function ModalSellNFTAuction(props) {
  const windowDimensions = useWindowDimensions()
  const dispatch = useDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [IsInputPin, setIsInputPin] = useState(false)
  const [isCreateLoading, setIsCreateLoading] = useState(false)
  const [isActivationLoading, setIsActivationLoading] = useState(false)
  const [isMode, setIsMode] = useState(0)
  const [file, setFile] = useState(false)
  const [issueDetail, setIssueDetail] = useState()
  const [createPercent, setCreatePercent] = useState(0)
  const [ActivationPercent, setActivationPercent] = useState(0)
  const detailNft = {
    Auctions: [],
  }

  // const detailNft = useSelector((state) =>
  //   state.getIn(["credence", "detail"]).toJS()
  // )

  const auctions = useSelector((state) =>
    state.getIn(["affluence", "ownedAuctions"]).toJS()
  )

  useEffect(() => {}, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsMode(0)
    setIssueDetail()
    setIsModalVisible(false)
  }

  return (
    <>
      {/* <Button
        block
        type={props.isActive ? "primary" : "default"}
        className="mt-24px"
        style={{ height: 48, borderRadius: 6 }}
        onClick={() => {
          props.handleCancel()
          showModal()
        }}
      >
        Sell using Auctions
      </Button> */}
      {props.isCreate ? (
        <ButtonOutline
          className="mt-12px"
          style={{
            height: 40,
            borderRadius: 4,
          }}
          block
          onClick={showModal}
        >
          Create Slot
        </ButtonOutline>
      ) : (
        <Button
          block
          type="primary"
          style={{ height: 40, borderRadius: 4 }}
          onClick={showModal}
        >
          Activate Slot Auction
        </Button>
      )}
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="800px"
          footer={null}
        >
          <ContentStyled>
            {/* onActivateAssets */}

            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Sell using Auctions
              </Title>
              <Text className="gray-7" align="center">
                We need you to complete a few more steps to enable the auctions
                functionality.
              </Text>

              <Row gutter={20} className="w-100 mt-24px">
                <Col span={12}>
                  <CardStyled>
                    <Progress
                      type="circle"
                      percent={
                        props.auction.status === AUCTION_STATUS.INIT ? 0 : 100
                      }
                      width={100}
                    />
                    <TitleStyled
                      className={
                        "mt-20px " +
                        (props.auction.status === AUCTION_STATUS.INIT
                          ? "selected"
                          : "")
                      }
                    >
                      Create Listing Slot
                    </TitleStyled>
                    <SubtitleStyled
                      className={
                        props.auction.status === AUCTION_STATUS.INIT
                          ? "selected"
                          : ""
                      }
                    >
                      The blockchain requires manual <br />
                      creation of an auction slot
                    </SubtitleStyled>
                    <ModalPinAuction
                      setProcessingPercent={setCreatePercent}
                      setLoading={setIsCreateLoading}
                      isLoading={isCreateLoading}
                      type={
                        props.auction.status === AUCTION_STATUS.INIT
                          ? "primary"
                          : "default"
                      }
                      disabled={props.auction.status !== AUCTION_STATUS.INIT}
                    />
                  </CardStyled>
                </Col>
                <Col span={12}>
                  <CardStyled>
                    <Progress
                      type="circle"
                      percent={
                        props.auction.status === AUCTION_STATUS.CREATED ? 0 : 0
                      }
                      width={100}
                    />
                    <TitleStyled
                      className={
                        "mt-20px " +
                        (props.auction.status === AUCTION_STATUS.CREATED
                          ? "selected"
                          : "")
                      }
                    >
                      Activate Listing Slot
                    </TitleStyled>
                    <SubtitleStyled
                      className={
                        props.auction.status === AUCTION_STATUS.CREATED
                          ? "selected"
                          : ""
                      }
                    >
                      The slot needs to be activated to enable
                      <br />a secure smart contract with your assets
                    </SubtitleStyled>

                    <ModalPinAuction
                      setProcessingPercent={setActivationPercent}
                      setLoading={setIsActivationLoading}
                      isLoading={isActivationLoading}
                      type={
                        props.auction.status === AUCTION_STATUS.CREATED
                          ? "primary"
                          : "default"
                      }
                      disabled={props.auction.status !== AUCTION_STATUS.CREATED}
                      isActivate
                      auction={props.auction}
                    />
                  </CardStyled>
                </Col>
              </Row>
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Report
              </Title>
              <Text className="gray-7 mt-5px" align="center">
                Reporting issue for <br />
                Transaction
              </Text>
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  )
}
