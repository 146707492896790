/* eslint-disable import/no-anonymous-default-export */
import { fromJS } from 'immutable'
import {
	SET_CONFLUENCE_COUNTRY,
	SET_CONFLUENCE_IMPACT,
	SET_CONFLUENCE_IMPACTS,
	SET_CONFLUENCE_LOADING,
	SET_CONFLUENCE_PROFILES,
	SET_CONFLUENCE_PROJECT,
	SET_CONFLUENCE_PROJECTS_DASHBOARD,
	SET_CONFLUENCE_SDG,
	SET_CONFLUENCE_SELLER,
	SET_CONFLUENCE_STATE,
	SET_CONFLUENCE_STATISTIC,
	SET_CONFLUENCE_USER,
	SET_CONFLUENCE_IMPACTS_DASHBOARD,
} from '../constants'

const initialState = fromJS({
	isLoading: false,
	hasError: false,
	errorMessage: '',
	user: fromJS({}),
	seller: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	projects: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	impacts: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	profiles: fromJS({
		data: [],
		currentPage: 1,
		hasNext: false,
		total: 0,
	}),
	statistic: fromJS({}),
	impact: fromJS([]),
	sdg: fromJS([]),
	countries: fromJS([]),
	states: fromJS([]),
	projectsDashboard: fromJS([]),
	impactsDashboard: fromJS([]),
})

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_CONFLUENCE_LOADING:
			return state.merge({
				isLoading: action.payload.data,
			})
		case SET_CONFLUENCE_USER:
			return state.merge({
				user: fromJS(action.payload.data),
			})
		case SET_CONFLUENCE_STATISTIC:
			return state.merge({
				statistic: fromJS(action.payload.data),
			})
		case SET_CONFLUENCE_IMPACT:
			return state.merge({
				impact: fromJS(action.payload.data),
			})
		case SET_CONFLUENCE_SDG:
			return state.merge({
				sdg: fromJS(action.payload.data),
			})
		case SET_CONFLUENCE_COUNTRY:
			return state.merge({
				countries: fromJS(action.payload.data),
			})
		case SET_CONFLUENCE_STATE:
			return state.merge({
				states: fromJS(action.payload.data),
			})
		case SET_CONFLUENCE_PROJECT:
			return state.merge({
				projects: fromJS({
					data: action.payload.data,
					hasNext: action.payload.hasNext,
					currentPage: action.payload.page,
					total: action.payload.total,
				}),
			})
		case SET_CONFLUENCE_PROFILES:
			const profilesData = state.getIn(['profiles', 'data']).toJS()

			const profilesCurrentPage = state.getIn(['profiles', 'currentPage'])

			// console.log("action.payload.reset", action.payload.reset)
			return state.merge({
				profiles: fromJS({
					data: action.payload.reset
						? action.payload.data
						: profilesData.length <= 0 || profilesCurrentPage < action.payload.page
						? profilesData.concat(action.payload.data)
						: profilesData,
					hasNext: action.payload.hasNext,
					currentPage: action.payload.page,
					total: action.payload.total,
				}),
			})
		case SET_CONFLUENCE_IMPACTS_DASHBOARD:
			return state.merge({
				impactsDashboard: fromJS(action.payload.data),
			})
		case SET_CONFLUENCE_IMPACTS:
			return state.merge({
				impacts: fromJS({
					data: action.payload.data,
					hasNext: action.payload.hasNext,
					currentPage: action.payload.page,
					total: action.payload.total,
				}),
			})
		case SET_CONFLUENCE_PROJECTS_DASHBOARD:
			return state.merge({
				projectsDashboard: fromJS(action.payload.data),
			})
		case SET_CONFLUENCE_SELLER:
			return state.merge({
				projects: fromJS({
					data: action.payload.data,
					hasNext: action.payload.hasNext,
					currentPage: action.payload.page,
					total: action.payload.total,
				}),
			})
		default:
			return state
	}
}
