import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Tag,
  Space,
  Button,
  Dropdown,
  Menu,
  Table,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getVolunteered } from "../../../modules/benevolence/action";
import { ColCenter } from "../../general_styled";
import projectIcon from "../../../assets/images/projects-side.svg";
import detailIcon from "../../../assets/images/icon_pen.svg";
import moment from "moment";
import ModalClaimVolunteer from "../../ModalClaimVolunteer";
import { ASSETS_NAME, ASSETS_STATUS } from "../../../constants/constant_wallet";
import ModalVolunteered from "../../ModalVolunteered";
import { setSection } from "../../../modules/general/action";

const { Title, Text } = Typography;

export default function BenevolenceVolunteered() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUserVerified, setIsUserVerified] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false)
  const [selectedVolunteer, setSelectedVolunteer] = useState({})

  const voluntered = useSelector((state) =>
    state.getIn(["benevolence", "volunteered"]).toJS()
  );
  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );
  const benevolenceUser = useSelector((state) =>
    state.getIn(["benevolence", "user"]).toJS()
  );

  const checkUserHasWallet = () => {
    const ibfx1 = allAssets.find((asset) => asset.asset_name === ASSETS_NAME.IBFX_TYPE_I);
    if (Object.keys(walletUser).length > 0 && ibfx1?.status === ASSETS_STATUS.ACTIVE && benevolenceUser?.user?.algo_address) {
      setIsUserVerified(true);
    }else {
      setIsUserVerified(false);
    }
  };

  const initData = async () => {
    await dispatch(getVolunteered(1));
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    checkUserHasWallet();
  }, [allAssets, walletUser])

  const columns = [
    {
      title: t("Project"),
      dataIndex: "Project",
      key: "Project",
      width: 290,
      render: (Project) => <span>{Project.name}</span>,
    },
    {
      title: t("job_title"),
      dataIndex: "title",
      key: "title",
      width: 200,
      render: (title) => (
        <div style={{ textTransform: "capitalize" }}>{title}</div>
      ),
    },
    {
      title: "Hour",
      dataIndex: "working_hours",
      key: "working_hours",
      width: 80,
      render: (working_hours) => <div>{working_hours}</div>,
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status, record) => {
        let color = "green";
        let text = "";

        if (status.toLowerCase() === "requested") {
          color = "gold";
          text = "Requested";
        } else if (status.toLowerCase() === "approved") {
          if (
            record.Project?.status === "OPENED" &&
            moment().unix() > record.Project.end_at
          ) {
            color = "volcano";
            text = "Pending Closure";
          } else if (record.Project?.status === "COMPLETED") {
            color = "red";
            text = "Pending Owner Review";
          } else {
            color = "green";
            text = "Approved";
          }
        } else if (status?.toLowerCase() === "rejected") {
          color = "volcano";
          text = "Rejected";
        } else if (status?.toLowerCase() === "finished") {
          color = "lime";
          text = "Finished";
        } else if (status?.toLowerCase() === "reviewed") {
          color = "green";
          text = "Pending Admin Review";
        } else if (status?.toLowerCase() === "finalized") {
          if(record?.is_volunteer_complete || record?.is_volunteer_complete === null) {
            color = "geekblue";
            text = "Finalized";
          } else {
            color = "volcano";
            text = "Owner Marked as Not Completed";
          }
        } else if (status?.toLowerCase() === "completed") {
          color = "green";
          text = "Completed";
        }

        return (
          <>
            <Tag className="tag-custom" color={color}>
              {text.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
    {
      title: t("action"),
      key: "action",
      align: "right",
      width: 250,
      render: (text, record) => {
        const isCanClaim = record.status === "FINALIZED" && (record?.is_volunteer_complete || record?.is_volunteer_complete === null)
        return (
          <Space>
            {isCanClaim && <ModalClaimVolunteer data={record} isUserVerified={isUserVerified} />}
            <Dropdown
              overlay={
                <Menu
                  className="canvas-dropdown-custom"
                  onClick={(e) => handleMenuClick(e, record)}
                >
                  <Menu.Item key="view-project">
                    <Row>
                      <Col span={24} className="menu-dropdown-custom">
                        <img src={projectIcon} alt="icon" />
                        <span>{t("view_project")}</span>
                      </Col>
                    </Row>
                  </Menu.Item>
                  <Menu.Item key="view-detail">
                    <Row>
                      <Col span={24} className="menu-dropdown-custom">
                        <img src={detailIcon} alt="icon" />
                        <span>View Detail</span>
                      </Col>
                    </Row>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const handleRequested = () => {};
  const handleMenuClick = (e, record) => {
    if(e.key === 'view-project') {
      dispatch(setSection('project-detail', record.project_id))
    } else {
      setSelectedVolunteer(record)
      setModalOpen(true)
    }
  };

  const onPageChange = (num) => {
    dispatch(getVolunteered(num));
  };

  return voluntered.data.length > 0 ? (
    <>
      <Row gutter={[24, 24]}>
        <Table
          className="table-volunteers w-100"
          columns={columns}
          dataSource={voluntered.data}
          scroll={{ y: "100%" }}
          pagination={{
            current: voluntered.currentPage,
            total: voluntered.total,
            pageSize: 12,
            onChange: onPageChange,
          }}
        />
      </Row>
      <ModalVolunteered isModalOpen={isModalOpen} setModalOpen={setModalOpen} selectedVolunteer={selectedVolunteer} />
    </>
  ) : (
    <Row gutter={24} style={{ paddingTop: 15, minHeight: "70vh" }}>
      <Col span={24}>
        <ColCenter className="w-100 h-100">
          {/* <img
            style={{ width: "40vh", height: "40vh" }}
            src={EmptyVolunteers}
            alt=""
          /> */}
          <Title level={3} align="center" className="mt-20px mb-4px">
            {t("no_project_available")}
          </Title>
          <Text>{t("no_project_volunteer_desc")}</Text>
        </ColCenter>
      </Col>
    </Row>
  );
}
