import React, { useEffect, useState } from "react";
import { Typography, Row } from "antd";
import {
  ColStart,
  RowEnd,
  RowSpaceBetween,
  RowStart,
} from "../../components/general_styled";
import { SearchStyled } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import CardUserCollectionStandAlone from "../../components/CardUserCollectionStandAlone";
import { useLocation } from "react-router-dom";
import {
  getCollectionDetailByUser,
  getEvmCollectionDetailByAddress,
} from "../../modules/credence/action";
import { setSelectedCollection } from "../../modules/affluence/action";
import CardShoppe from "../../components/CardShoppe";
import CardAuction from "../../components/CardAuction";
import { IS_EVM } from "../../configs";

const { Title, Text } = Typography;

export default function AffluenceUserCollection() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchVal, setSearchVal] = useState(null);
  const [name, setName] = useState();

  const collectionDetail = useSelector((state) =>
    state.getIn(["credence", "collectionDetail"]).toJS()
  );

  const selectedCollection = useSelector((state) =>
    state.getIn(["affluence", "selectedCollection"]).toJS()
  );

  const renderSearchBar = () => {
    return (
      <RowEnd>
        <SearchStyled
          className="search-input"
          placeholder="Search"
          autoComplete="off"
          allowClear
          size="large"
          onSearch={(value) => {
            setSearchVal(value);
          }}
          style={{
            width: 250,
          }}
        />
      </RowEnd>
    );
  };

  useEffect(() => {
    const collection_id =
      selectedCollection.collection_id ||
      location.pathname.split("/affluence-collection/")[1];
    setName(selectedCollection?.name);
    if (IS_EVM) {
      dispatch(
        getEvmCollectionDetailByAddress(selectedCollection?.evm_collection_id)
      );
    } else {
      dispatch(getCollectionDetailByUser(collection_id));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(selectedCollection).length === 0) {
      if (!name && collectionDetail?.data?.length > 0) {
        const collection = JSON.parse(
          collectionDetail.data[0].metadata_json
        )?.collection;
        setName(collection.name);
        dispatch(setSelectedCollection(collection));
      }
    }
  }, [collectionDetail]);

  return (
    <ColStart>
      <RowSpaceBetween>
        {searchVal ? (
          <ColStart>
            <RowStart className="mb-0px">
              <Text className="text-size-16">Search Result for </Text>
              <Text className="text-size-16 text-w600">
                &nbsp;"{searchVal}"
              </Text>
            </RowStart>
            <Text className="text-size-12">0 NFTs Found </Text>
          </ColStart>
        ) : (
          <Title level={3} className="mb-0px mt-0px">
            {name || selectedCollection?.name}
          </Title>
        )}
        {renderSearchBar()}
      </RowSpaceBetween>
      <Row className="mt-20px" gutter={[16, 16]}>
        {collectionDetail?.data?.map((e, i) => {
          return (
            <CardUserCollectionStandAlone
              key={`card-user-collection-${e.nft_id}`}
              data={e}
            />
          );
        })}
      </Row>
    </ColStart>
  );
}
