import styled from "@emotion/styled";
import { Card, Progress } from "antd";

export const CanvasStyled = styled.div`
  border-radius: 4px;

  border: 1px solid transparent;
  backgound-color: red;

  // &:hover {
  // 	.main-card {
  // 		// border: 1px solid #024FF1;
  // 		// border-bottom: none;

  // 		transition: all 0.3s ease;
  // 		box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
  // 	}

  // 	.auction {
  // 		background: #024FF1;
  // 		// border: 1px solid #024FF1;
  // 		transition: all 0.3s ease;
  // 		box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
  // 	}

  // 	.delist-card {
  // 		// background: #024FF1;
  // 		border-bottom: 1px solid #024FF1;
  // 		transition: all 0.3s ease;
  // 		box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
  // 	}
  // }
`;

export const ProgressStyled = styled(Progress)`
  &.ant-progress-circle {
    .ant-progress-text {
      color: rgba(255, 255, 255) !important;
    }
  }
`;

export const CardStyled = styled(Card)`
  background: #0d141b;
  /* Neutral/700 */

  border: 1px solid #0d141b;
  border-bottom: none;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
  color: white;

  &.listing-style {
    // height: 100% !important;
    border-bottom: 1px solid #0d141b;

    &.opened {
      .ant-card-body {
        padding: 8px;
        height: ${(props) =>
          props.status === "OPENED" ? "auto" : "calc(100%)"};
      }
    }

    &.deposit {
      background: #0d141b;
      .ant-card-body {
        padding: 8px 8px 20px 8px;
      }

      .ant-card-cover {
        padding: 0;
      }
    }

    .ant-card-body {
      padding: 20px;
      height: ${(props) => (props.status === "OPENED" ? "auto" : "calc(100%)")};
    }

    .ant-card-cover {
      padding: ${(props) => (props.status === "OPENED" ? "8px" : "0")};

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
  }

  &.add-border-bottom {
    // border-bottom: 1px solid #a7b1c0;
  }

  &.delist-card {
    border-bottom: 1px solid #0d141b;
    border-top: none;
    // border: none;
    box-sizing: border-box;
    border-radius: 0px 0px 4px 4px;

    .ant-card-body {
      padding: 8px;
    }
  }

  .auction {
    padding: 10.5px 8px 18.5px;

    .title {
      // font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      /* identical to box height */

      /* Neutral/600 */

      color: #d6dae1;
    }

    .numeric {
      // font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      /* Neutral/50 */

      color: #fafafa;
      margin-right: 8px;
    }
  }

  .ant-card-body {
    padding: 8px;
  }

  .ant-card-cover {
    padding: 8px;
    aspect-ratio: 1/1;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  // &:hover {
  //   border: 1px solid #024FF1;
  //   border-bottom: none;

  //   transition: all 0.3s ease;
  //   box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
  // }

  @media screen and (max-width: 834px) {
    .ant-card-cover {
      padding: 8px;
      aspect-ratio: 1/1;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
  }
`;

export const ImageStyled = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 4px 4px 0 0;
  height: ${(props) => (props.status === "OPENED" ? "250px" : "405px")};
`;

export const CollectionNameStyled = styled.div`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Neutral/600 */

  color: #d6dae1;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const NFTNameStyled = styled.div`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  /* Neutral/50 */

  color: #fafafa;

  margin-top: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const PriceStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: #ffffff;

  // font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height */

  /* Shades/Black */

  color: #0d141b;
  border-radius: 0 0 4px 4px;

  padding: 11px 10px;

  .icon {
    width: 20px;
    margin-right: 6px;
  }

  .value {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* Shades/Black */

    color: #0d141b;
  }
`;

export const PriceLabelStyled = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height */

  /* Text/300 */

  color: #42556e;
`;

export const PriceValueStyled = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  // line-height: 19px;
  /* identical to box height */

  /* Text/400 */

  color: #364559;
`;

export const StepText = styled.div`
  /* Caption/All Caps */

  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height, or 117% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text/300 */

  color: #fff;
`;

export const StepTitleText = styled.div`
  /* Paragraph 02/Semibold */

  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* Text/400 */

  color: #fff;
`;
export const TitleText = styled.span`
  /* Heading 05 (S) / Bold */

  // font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;

  /* Text/500 */

  color: #293544;
`;

export const DividerCard = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 20px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='4%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  &.no-margin {
    margin: 0;
  }
`;

export const PlaceholderNFT = styled.div`
  background: #f0f2f4;
  border-radius: 2px;
  width: 100%;
  height: 376px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlaceholderNFTText = styled.div`
  background: #f0f2f4;
  border-radius: 6px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
