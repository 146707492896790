import React from "react";
import { Helmet } from "react-helmet";

export default function SEO({
  title = "IBFNex",
  children,
  description,
  image,
  url = window.location.href,
}) {
  return (
    <>
      <Helmet>
        {/* <title>{title}</title> */}
        {/* <meta
					property="og:url"
					content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html"
				/> */}
        {/* <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" itemprop="image" content={image} />
        <meta property="og:image:secure_url" content={image} />
        <meta itemprop="width" content="256" />
        <meta itemprop="height" content="256" />
        <meta property="og:image:width" content="256" />
        <meta property="og:image:height" content="256" />
        <meta name="description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image + '?width=256&height=256'} /> */}
       

        <title>{title}</title>
        <meta name="description" content={description} />

		<meta property="og:site_name" content="IBFNex" />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
		<meta property="og:image:secure_url" content={image} />
        <meta property="og:image:width" content="256" />
        <meta property="og:image:height" content="256" />
		<meta property="og:image:type" content="image/png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="ibfnex.io" />
        <meta property="twitter:url" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

		<link itemprop="thumbnailUrl" href={image} />
        <span
          itemprop="thumbnail"
          itemscope
          itemtype="http://schema.org/ImageObject"
        >
          <link itemprop="url" href={image} />
        </span>
		
      </Helmet>
      {children}
    </>
  );
}