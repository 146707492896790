import { ADD_CART, CLOSE_CART, CLOSE_SUCCESS_COURSE_MODAL, OPEN_CART, OPEN_SUCCESS_COURSE_MODAL, REMOVE_CART } from "../constants"

export const addCart = (course) => (dispatch) => {
  dispatch({
    type: ADD_CART,
    payload: {
      ...course
    }
  })
}

export const removeCart = (course) => (dispatch) => {
  dispatch({
    type: REMOVE_CART,
    payload: {
      ...course
    }
  })
}

export const openCart = () => (dispatch) => {
  dispatch({
    type: OPEN_CART
  })
}

export const closeCart = () => (dispatch) => {
  dispatch({
    type: CLOSE_CART
  })
}

export const openModalSuccessCourse = () => (dispatch) => {
  dispatch({
    type: OPEN_SUCCESS_COURSE_MODAL
  })
}

export const closeModalSuccessCourse = () => (dispatch) => {
  dispatch({
    type: CLOSE_SUCCESS_COURSE_MODAL
  })
}