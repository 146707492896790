import React, { useCallback, useEffect, useState } from "react";
import { Typography, Row, Col, Input, Select } from "antd";
import CardBenevolence from "../../components/CardBenevolence";
// import MasjidIllustration from "../../assets/images/masjid-illustration.svg";
// import EmptyProjects from "../../assets/images/empty_benev_projects.svg";
// import ArrowRightCircle from "../../assets/images/arrow-right-cirlce.svg";
import BG from "../../assets/images/benevolence_og.png";
import Hand from "../../assets/images/hand.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAssetProjects } from "../../modules/benevolence_asset_projects/action";
// import { PROJECT_STATUS } from "../../constants/constant_common";
import { setSection } from "../../modules/general/action";
import {
  ButtonStyled,
  ColCenter,
  RowEnd,
  RowSpaceBetween,
  SelectStyled,
} from "../../components/general_styled";
import { openCreateProjectModal } from "../../modules/benevolence_projects/action";
import ShimmerCustom from "../../components/ShimmerCustom";
import { useTranslation } from "react-i18next";
import { BENEVOLENCE_HOST, IS_EVM } from "../../configs";
import useWindowDimensions from "../../utils/windowDimensions";
// import { BannerStyled } from "./styled";
import SEO from "../../components/SEO";
import { BannerStyled, FooterStyled } from "./styled";

import SDG from "../../assets/images/donate/sdg.svg";
import Stripe from "../../assets/images/donate/stripe.svg";
import IBFN from "../../assets/images/donate/ibfn.svg";
import IBFX from "../../assets/images/donate/ibfx.svg";
import VISA from "../../assets/images/donate/visa.svg";
import MC from "../../assets/images/donate/mc.svg";
import MC2 from "../../assets/images/donate/google.svg";
import MC3 from "../../assets/images/donate/apple.svg";
import CR1 from "../../assets/images/donate/cr1.svg";
import CR2 from "../../assets/images/donate/cr2.svg";
import CR3 from "../../assets/images/donate/cr3.svg";
import CR4 from "../../assets/images/donate/cr4.svg";
import CR5 from "../../assets/images/donate/cr5.svg";
import CR6 from "../../assets/images/donate/cr6.svg";
import CR7 from "../../assets/images/donate/cr7.svg";
import CR8 from "../../assets/images/donate/cr8.svg";
import Pera from "../../assets/images/donate/pera.svg";
import debounce from "lodash.debounce";

const { Title, Text } = Typography;
const { Search } = Input;

const image = [CR1, CR2, CR3, CR4, CR5, CR6, CR7, CR8, Pera, MC, MC2, MC3, VISA, IBFN, IBFX];

export default function BenevolenceDonate(props) {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const projects = useSelector((state) =>
    state.getIn(["benevolenceAssetProjects", "projects"]).toJS()
  );
  const token = useSelector((state) => state.getIn(["auth", "token"]));
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProject({ pagination: 1 });

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
      setIsBottom(true);
    }
  };

  const onScroll = useCallback(
    debounce(() => {
      if (projects.hasNext) {
        getProject({pagination: projects.currentPage + 1});
      }
      setIsBottom(false);
    }, 400),
    [isBottom]
  );

  useEffect(() => {
    if (isBottom) {
      onScroll();
    }
  }, [isBottom]);

  const getProject = async ({
    pagination = 1,
    filter = null,
    search = null,
  }) => {
    setLoading(true);
    await dispatch(getAssetProjects(token, pagination, filter, search));
    setLoading(false);
  };
  
  const onWaqfClicked = () => {
    dispatch(setSection("benevolence-waqf-pool"));
  };

  const onCreateProject = () => {
    dispatch(openCreateProjectModal());
  };

  const handleChangeDropdown = async (val) => {
    if (val !== "ALL_CATEGORY") {
      getProject({ pagination: 1, filter: val });
    } else {
      getProject({ pagination: 1 });
    }
  };

  const handleSearch = (val) => {
    getProject({ pagination: 1, search: val });
  };

  const resolveShimmer = () => {
    let children = [];
    for (let i = 0; i < 9; i++) {
      children.push(
        <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
          <ShimmerCustom width="100%" height="140px" borderRadius="4px" />
          <RowSpaceBetween className="mt-12px">
            <ShimmerCustom width="30%" height="14px" borderRadius="2px" />
            <ShimmerCustom width="15%" height="14px" borderRadius="2px" />
          </RowSpaceBetween>
          <ShimmerCustom
            className="mt-12px"
            width="100%"
            height="24px"
            borderRadius="2px"
          />
          <RowSpaceBetween className="mt-12px">
            <ShimmerCustom width="30%" height="14px" borderRadius="2px" />
            <ShimmerCustom width="15%" height="14px" borderRadius="2px" />
          </RowSpaceBetween>
          <RowSpaceBetween className="mt-12px">
            <ShimmerCustom width="47.5%" height="40px" borderRadius="2px" />
            <ShimmerCustom width="47.5%" height="40px" borderRadius="2px" />
          </RowSpaceBetween>
        </Col>
      );
    }

    return children;
  };

  return (
    <SEO
      title="IBFNEX - Benevolence Donate"
      description="A platform to facilitate charity and volunteering on the blockchain"
      image={BG}
    >

      <BannerStyled className="mx-24px mb-24px" isDekstop={windowDimensions.width > 900}>
          <div>
            <p className="title">Nurturing the Act of Giving</p>
            <p className="desc mt-12">A Platform for Charity and <br /> Volunteering on the Blockchain.</p>
          </div>
          {windowDimensions.width > 900 && <div>
            <img src={Hand} />
          </div>}
      </BannerStyled>

      <div className="px-24px">
        {windowDimensions.width > 900 ? (
          <>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                marginTop: "1rem",
              }}
            >
              <Title level={3} bordered={false} style={{ width: 300 }}>
                {t("donate")}
              </Title>
              <RowEnd>
                <SelectStyled
                  className="mr-12px"
                  placeholder="Type Assets"
                  defaultValue="ALL_CATEGORY"
                  style={{
                    width: "160px",
                  }}
                  onSelect={handleChangeDropdown}
                >
                  <Select.Option value="ALL_CATEGORY">All</Select.Option>
                  <Select.Option value="closest_end">Closest End</Select.Option>
                  <Select.Option value="closest_complete">
                    Closest Complete
                  </Select.Option>
                </SelectStyled>
                <Search
                  placeholder="Search"
                  allowClear
                  onSearch={(val) => handleSearch(val)}
                  size="large"
                  style={{
                    width: 289,
                    float: "right",
                    backgroundColor: "#F4F6F7v",
                  }}
                />
                <ButtonStyled
                  className="ml-8px"
                  style={{ minWidth: 150, borderRadius: 100 }}
                  onClick={onCreateProject}
                >
                  {t("create_project")}
                </ButtonStyled>
              </RowEnd>
            </Row>
            {/* <Row className="waqf-pool-banner" onClick={() => onWaqfClicked()}>
            <div className="text">
              <span className="title">{t("waqf_pool_campaign")}</span>
              <span className="desc">{t("waqf_pool_campaign_desc")}</span>
            </div>
            <img src={MasjidIllustration} />
            <div className="action">
              <span>{t("learn_more")}</span>
              <img src={ArrowRightCircle} alt="" />
            </div>
          </Row> */}
          </>
        ) : (
          <div className="">
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Title level={3} bordered={false} style={{ width: 300 }}>
                {t("donate")}
              </Title>
            </Row>
            {/* <BannerStyled onClick={() => onWaqfClicked()}>
              <div className="title pt-20px">{t("waqf_pool_campaign")}</div>
              <div className="desc">{t("waqf_pool_campaign_desc")}</div>
              <div className="action">
                <span>{t("learn_more")}</span>
                <img className="pl-6px" src={ArrowRightCircle} alt="" />
              </div>
              <img className="pt-6px" src={MasjidIllustration} />
            </BannerStyled> */}
            <Search
              className="pt-12px"
              placeholder="Search"
              allowClear
              onSearch={"null"}
              size="large"
              style={{ backgroundColor: "#F4F6F7" }}
            />
          </div>
        )}
        
        {isLoading || props.isLoading ? (
          <Row gutter={[24, 24]} style={{ paddingTop: 15 }}>
            {resolveShimmer()}
          </Row>
        ) : projects.data.length > 0 ? (
          <Row gutter={[24, 24]} style={{ paddingTop: 15 }}>
            {projects.data.map((p) => {
              const payment = JSON.parse(p?.payment || "{}");
              return (
                <CardBenevolence
                  data={p}
                  project_id={p.project_id}
                  time={p.end_at}
                  image={`${BENEVOLENCE_HOST}/files/${p.cover_image_file}`}
                  title={p.name}
                  donors={
                    IS_EVM ? p?.DonationEvms?.length : p.total_unique_donation
                  }
                  currentDonation={p.donation_amount}
                  totalDonation={p.fund_goal}
                  type={"ASSET"}
                  status={p.status}
                  volunteer={0}
                  totalHours={0}
                  hoursGoal={0}
                  nonce={p?.nonce || "1"}
                  payment={payment}
                  isAlgo={p?.chain === "ALGORAND"}
                />
              );
            })}
          </Row>
        ) : (
          <Row
            gutter={24}
            style={{
              paddingTop: 15,
              minHeight: windowDimensions.width > 900 ? "60vh" : "40vh",
            }}
          >
            <Col span={24}>
              <ColCenter className="w-100 h-100">
                <Title level={3} align="center" className="mt-20px mb-4px">
                  {t("no_project_available")}
                </Title>
                <Text>{t("no_project_desc")}</Text>
              </ColCenter>
            </Col>
          </Row>
        )}

        <FooterStyled isDekstop={windowDimensions.width > 900} className="mt-24px">
          <Row>
            <Col className="border-right px-24px py-48px" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <p className="title">Growing Donation Options</p>
              <p className="desc mt-24px">We support Visa, Mastercard, Apple Pay, Google Pay, and most of the popular Cryptocurrencies to make it easier for you to donate.</p>
              <p className="desc mt-12px mb-24px">This inclusivity ensures that we remain accessible and user-friendly to a broad audience, regardless of their preferred payment method.</p>
              {image.map((i) => (
                <img
                  src={i}
                  className="pr-6px pt-6px pb-6px img"
                />
              ))}
            </Col>
            <Col className="border-right px-24px py-48px" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <p className="title">Alignment with UN SDGs</p>
              <p className="desc mt-24px">Aligning with the United Nations Sustainable Development Goals (SDGs), we highlight the specific goals each campaign supports.</p>
              <img className="my-38px sdg-img" src={SDG} alt="sdg image" />
              <p className="desc mt-12px mb-24px">This feature not only fosters a deeper understanding of the impact of each donation, but also aligns your contributions with your personal or organizational values.</p>
            </Col>
            <Col className="py-48px" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="border-bottom px-24px pb-12px">
                <p className="title">Verified NGOs and Campaigns</p>
                <p className="desc mt-24px">All campaigns and NGOs on Benevolence are verified ensuring your money only goes to those who deserve it.</p>
              </div>
              <div className="px-24px pt-24px">
                <p className="title">Member of Stripe Climate</p>
                <p className="desc mt-12px">We measure and report our emissions, work to reduce them, and invest in carbon removal technologies to become carbon-negative though Stripe's Climate API.</p>
                <img className="my-12px" src={Stripe} alt="sdg image" />
              </div>
            </Col>
          </Row>
        </FooterStyled>
      </div>
    </SEO>
  );
}
