import axios from "axios";
import { BENEVOLENCE_HOST } from "../../configs";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import {
  GET_BENEVOLENCE_DONORS,
  GET_BENEVOLENCE_DONORS_FAIL,
  GET_BENEVOLENCE_DONORS_SUCCESS,
} from "../constants";

export const getMyDonors =
  (token, page = 1) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_BENEVOLENCE_DONORS,
      });

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const result = (
        await axios.get(`${BENEVOLENCE_HOST}/manage-project/donors`, {
          headers,
          params: {
            page: page,
          },
        })
      ).data;
      const { data } = result;
      dispatch({
        type: GET_BENEVOLENCE_DONORS_SUCCESS,
        payload: {
          data: data.items,
          currentPage: page,
          hasNext: page < data.total_pages,
          totalItems: data.total_items,
          totalPage: data.total_pages,
        },
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: GET_BENEVOLENCE_DONORS_FAIL,
        payload: {
          errorMessage: extractErrorMessage(e),
        },
      });
      throw e;
    }
  };

export const getWaqfPoolBalance = async () => {
  try {
    const result = (await axios.get(`${BENEVOLENCE_HOST}/donations/ajrpool`))
      .data;
    return result.data.fund_amount;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getWaqfPoolDonors = async () => {
  try {
    const result = (await axios.get(`${BENEVOLENCE_HOST}/pool/donors`)).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getWaqfPoolInfo = async () => {
  try {
    const result = (await axios.get(`${BENEVOLENCE_HOST}/pool/info`)).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getCoinbaseDonor = async (projectId) => {
  try {
    const result = (
      await axios.get(
        `${BENEVOLENCE_HOST}/coinbase-donation?project_id=${projectId}`
      )
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getStripeDonor = async (projectId) => {
  try {
    const result = (
      await axios.get(
        `${BENEVOLENCE_HOST}/stripe-donation?project_id=${projectId}`
      )
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};