import { fromJS } from "immutable";
import {
  GET_ASSET_PROJECTS,
  GET_ASSET_PROJECTS_FAIL,
  GET_ASSET_PROJECTS_SUCCESS,
} from "../constants";

const initialState = fromJS({
  isLoading: fromJS(false),
  hasError: fromJS(false),
  errorMessage: fromJS(""),
  projects: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    totalItems: 0,
    totalPage: 0,
    isFiltered: false,
  }),
  isCreateModalVisible: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSET_PROJECTS:
      return state.merge({
        isLoading: true,
        hasError: false,
      });
    case GET_ASSET_PROJECTS_SUCCESS:
      const projectData = state.getIn(["projects", "data"]).toJS();
      const projectCurrentPage = state.getIn(["projects", "currentPage"]);
      const isProjectFiltered = state.getIn(["projects", "isFiltered"]);
      // const data = projectData.length <= 0 || projectCurrentPage < action.payload.totalPage
      //     ? projectData.concat(action.payload.data)
      //     : projectData;
      let data
      if(action.payload.filter) {
        if((projectData.length <= 0 || projectCurrentPage < action.payload.totalPage) && isProjectFiltered) {
          console.log('emang kesini ta?')
          data = projectData.concat(action.payload.data)
        } else {
          data = action.payload.data
        }
      } else if(projectData.length <= 0 || projectCurrentPage < action.payload.totalPage) {
        data = projectData.concat(action.payload.data)
      } else {
        data = projectData
      }
      const filteredData = []
      if(data.length > 0) {
        data.forEach(prj => {
          if(!filteredData.find(i => i.project_id === prj.project_id)){
            filteredData.push(prj)
          }
        })
      }
      return state.merge({
        projects: fromJS({
          data: filteredData,
          hasNext: action.payload.hasNext,
          currentPage: action.payload.currentPage,
          total: action.payload.totalItems,
          isLoading: false,
          isFiltered: action.payload.filter ? true : false
        }),
      });
    case GET_ASSET_PROJECTS_FAIL:
      return state.merge({
        isLoading: false,
        hasError: true,
        errorMessage: action.payload.errorMessage,
      });
    default:
      return state;
  }
};
