import React from 'react'
import { Col, Row, Typography, Tag } from 'antd'
import { useTranslation } from 'react-i18next'

import { CardStyled, DividerCard } from './styled'
import { RowSpaceBetween } from '../general_styled'
import { fromIBFx } from '../../utils/utils'
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from '../../constants/constant_wallet'
import moment from 'moment'
import ModalReport from '../ModalReport'

const { Title, Text } = Typography

export default function CardTransaction(props) {
	const { t } = useTranslation()

	const resolveType = (type) => {
		// let color = "success"
		let text = ''

		// if (type === TRANSACTION_TYPE.BUY_BACK) {
		//   color = "volcano"
		// }
		// if (type === TRANSACTION_TYPE.PAY) {
		//   color = "orange"
		// }
		// if (type === TRANSACTION_TYPE.REDEEM_VOUCHER) {
		//   color = "blue"
		// }
		if (type === TRANSACTION_TYPE.TOPUP) {
			// color = "purple"
			text = 'TOP-UP'
		}
		return <>{text.length <= 0 ? type.toUpperCase() : text}</>
	}

	const resolveStatus = (status) => {
		let color = 'success'

		if (status === TRANSACTION_STATUS.INIT) {
			color = 'blue'
		}
		if (status === TRANSACTION_STATUS.PROCESSING) {
			color = 'warning'
		}
		if (status === TRANSACTION_STATUS.SUCCESS) {
			color = 'success'
		}
		if (status === TRANSACTION_STATUS.FAILED) {
			color = 'error'
		}
		if (status === TRANSACTION_STATUS.EXPIRED) {
			color = 'volcano'
		}

		return (
			<>
				<Tag className='tag-custom' color={color}>
					{status.toUpperCase()}
				</Tag>
			</>
		)
	}

	return (
		<Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
			<CardStyled>
				<RowSpaceBetween>
					<div>
						<Row>
							<Title level={4} className='gray-8' style={{ marginBottom: 0 }}>
								{fromIBFx(props.data.amount)}
							</Title>
						</Row>
						<Row>
							<Text className='gray-7'>{moment(props.data.dateTime).format('DD/MM/YYYY HH:mm A')}</Text>
						</Row>
					</div>
					{resolveStatus(props.data.status)}
				</RowSpaceBetween>
				<DividerCard />

				{/* <RowSpaceBetween>
          <Title
            level={5}
            className="secondary"
            style={{ marginBottom: 0, lineHeight: 1.7 }}
          >
            Balance
          </Title>
          <Title
            level={4}
            className="gray-8"
            style={{ marginBottom: 0, marginTop: 0 }}
          >
            {/* {fromIBFx(props.data.amount)} 0
          </Title>
        </RowSpaceBetween> */}
				<Row>
					<Col flex={1}>
						<Text className='gray-8'>{t('transaction_id')}</Text>
						<br />
						<Text className='text-size-15 gray-8'>{props.data.transactionCode}</Text>
					</Col>
					<Col flex={1}>
						<Text className='gray-8'>{t('type')}</Text>
						<br />
						<Text className='text-size-15 gray-8'>{resolveType(props.data.type)}</Text>
					</Col>
				</Row>
				<DividerCard />
				<Row>
					<Col span={24}>
						<ModalReport data={props.data} />
					</Col>
				</Row>
			</CardStyled>
		</Col>
	)
}
