import { Button, Col, Image } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSection } from "../../modules/general/action";
import useWindowDimensions from "../../utils/windowDimensions";
import {
  ColCenter,
  ColStart,
  RowCenter,
  RowEnd,
  RowSpaceBetween,
} from "../general_styled";
import {
  CardStyled,
  CollectionNameStyled,
  NFTNameStyled,
  PriceLabelStyled,
  PriceValueStyled,
  PriceContainerStyled,
} from "./styled";

import IBFxLogo from "../../assets/images/ibfx-icon.svg";
import ModalDelistingNFT from "../ModalDelistingNFT";
import { formatterCurrency, fromIBFN, fromIBFx } from "../../utils/utils";
import { setDetailNFT } from "../../modules/essence/action";
import { CREDENCE_HOST, IS_EVM } from "../../configs";

export default function CardShoppe(props) {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();

  return (
    <Col
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={windowDimensions.width < 1441 ? 8 : 6}
      xxl={6}
    >
      <CardStyled
        cover={
          props.data?.NFT.cover_file ? (
            <img
              alt={props.name}
              src={CREDENCE_HOST + "/files/" + props.data?.NFT.cover_file}
              onClick={() => {
                dispatch(
                  setSection(
                    "affluence-buy-sell-detail-nft",
                    props.sell_escrow_id
                  )
                );
                dispatch(setDetailNFT(props.data));
              }}
            />
          ) : (
            <img
              alt={props.name}
              src={`https://ipfs.io/ipfs/${props.ipfsHash}`}
              onClick={() => {
                dispatch(
                  setSection(
                    "affluence-buy-sell-detail-nft",
                    props.sell_escrow_id
                  )
                );
                dispatch(setDetailNFT(props.data));
              }}
            />
          )
        }
      >
        <PriceContainerStyled>
          <RowSpaceBetween className="end">
            <PriceLabelStyled className="">{t("price")}: </PriceLabelStyled>
            <RowEnd>
              {!IS_EVM && (
                <Image preview={false} src={IBFxLogo} width={"18px"} />
              )}
              <PriceValueStyled className="ml-4px">
                {formatterCurrency.format(
                  IS_EVM ? fromIBFN(props.price) : fromIBFx(props.price)
                )}
              </PriceValueStyled>
            </RowEnd>
          </RowSpaceBetween>
        </PriceContainerStyled>
        <RowSpaceBetween
          onClick={() => {
            dispatch(
              setSection("affluence-buy-sell-detail-nft", props.sell_escrow_id)
            );
            dispatch(setDetailNFT(props.data));
          }}
        >
          <ColStart className="pt-4px">
            <CollectionNameStyled
              className={!props.collectionName && "no-collection"}
            >
              {props.collectionName || "No Collection"}
            </CollectionNameStyled>
            <NFTNameStyled>{props.name}</NFTNameStyled>
          </ColStart>
        </RowSpaceBetween>
        {props.isDelisting && <ModalDelistingNFT {...props} />}
      </CardStyled>
    </Col>
  );
}
