export const LOAN_STATUS = {
  INIT: "INIT",
  PENDING: "PENDING",
  CREATED: "CREATED",
  PROVIDING: "PROVIDING",
  PROVIDED: "PROVIDED",
  PAYING: "PAYING",
  PAID: "PAID",
  ON_GOING: "ON_GOING",
  DUE: "DUE",
  OVERDUE: "OVERDUE",
  EXTENSION: "EXTENSION",
  CLOSED: "CLOSED",
  FAILED: "FAILED",
}

export const LOAN_COLLATERAL_STATUS = {
  INIT: "INIT",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  CLAIM_BACK_SUCCESS: "CLAIM_BACK_SUCCESS",
  CLAIM_BACK_PENDING: "CLAIM_BACK_PENDING",
  FAILED: "FAILED",
}
