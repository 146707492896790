import styled from "@emotion/styled";

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;

  .ant-upload.ant-upload-select {
    display: block;
  }

  .img-nft {
    border-radius: 4px !important;
    margin: 24px;
    height: 256px;
    width: 256px;
    object-fit: cover;
  }
`;

export const RemoveListingStyled = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Primary/500 */

  color: #024FF1;
`;

export const DetailStyled = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DividerCard = styled.div`
  width: calc(100% - 40px);
  height: 1px;
  margin: 16px 20px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
`;
