import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Drawer,
  Progress,
  Col,
  Row,
  Spin,
  Alert,
  Image,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  ColCenter,
  ButtonOutline,
  ButtonStyled,
  RowSpaceBetween,
  RowEnd,
  RowCenter,
} from "../general_styled";

import {
  ContentStyled,
  TotalDue,
  CanvasNftDetails,
  DividerCard,
  StepContentStyled,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import { setSection } from "../../modules/general/action";
import ReactCodeInput from "react-code-input";
import ibfx1 from "../../assets/images/ibfx-icon.svg";
import { decryptMnemonic, fromIBFx } from "../../utils/utils";
import {
  createASANFT,
  getCredenceEvmNFTByAddress,
  getCredenceNFTByUserId,
  updateTxhashEvm,
} from "../../modules/credence/action";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { ASSETS_NAME } from "../../constants/constant_wallet";
import ModalInsufficientFunds from "../ModalInsufficientFunds";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import { CREDENCE_HOST, IS_EVM, nftMarketplaceAddress } from "../../configs";
import { IBFx1 } from "../../pages/CourseDetail/styled";
import { useAccount, useContractWrite, usePrepareContractWrite } from "wagmi";
import { nftFactoryAddress } from "../../configs";
import AbiNFTFactory from "../../assets/abi/NFTFactory.json";
import { nftAirdropAddress } from "../../configs";

const { Title, Text } = Typography;

export default function ModalPayFeeNft(props) {
  const windowDimensions = useWindowDimensions();
  const { address, isConnected } = useAccount();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalInsufficientVisible, setIsModalInsufficientVisible] =
    useState(false);
  const [isMode, setIsMode] = useState(0);
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isFailed, setFailed] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [isPinError, setPinError] = useState(false);
  const [processingPercent, setProcessingPercent] = useState(10);
  const metadata = props.metadata;

  const credenceCurrentPage = useSelector((state) =>
    state.getIn(["credence", "nfts", "currentPage"])
  );

  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );

  // Create NFT
  const { config: configCreateNFT } = usePrepareContractWrite({
    address: nftFactoryAddress,
    abi: AbiNFTFactory,
    functionName: "createNFT",
    args: [
      metadata.name,
      metadata.symbol,
      metadata.max_supply, // max Supply if value is 0 = no set max supply. If value > 0 = set max supply
      metadata.is_airdrop
        ? [nftAirdropAddress, nftMarketplaceAddress]
        : [nftMarketplaceAddress], // set value contract address nft Airdrop if choose option airdrop is true. If false, set to array null like []
      false,
      metadata.image,
    ],
  });
  const {
    writeAsync: writeCreateNFT,
    data: dataCreateNFT,
    isSuccess: isSuccessCreateNFT,
  } = useContractWrite(configCreateNFT);

  // console.log([
  //   metadata.name,
  //   metadata.symbol,
  //   metadata.max_supply, // max Supply if value is 0 = no set max supply. If value > 0 = set max supply
  //   metadata.is_airdrop
  //     ? [nftAirdropAddress, nftMarketplaceAddress]
  //     : [nftMarketplaceAddress], // set value contract address nft Airdrop if choose option airdrop is true. If false, set to array null like []
  //   false,
  //   metadata.image,
  // ]);

  useEffect(() => {
    if (isSuccessCreateNFT) {
      updateTxHashAndGet();
    }
  }, [isSuccessCreateNFT]);

  const updateTxHashAndGet = async () => {
    await updateTxhashEvm(metadata.nft_id, {
      txhash: dataCreateNFT.hash,
      creator_wallet_address: address,
    });
    dispatch(getCredenceEvmNFTByAddress(address, credenceCurrentPage));
  };

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsModalVisible(false);
    setProcessing(false);
    setSuccess(false);
    setFailed(false);
    setPinCode("");
    setPinError(false);
    setProcessingPercent(10);
  };

  const toVault = () => {
    props.toVault();
    handleCancel();
  };

  const resolveBalance = (assetName) => {
    const asset = allAssets.find((asset) => asset?.asset_name === assetName);
    return asset ? asset.amount : 0;
  };

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async () => {
    setPinError(false);
    const _check = checkPin();
    if (!_check) {
      setPinCode("");
      return setPinError(true);
    }

    try {
      setProcessing(true);
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);

      await createASANFT(_decryptMnemonic, props.record.nft_id);

      await dispatch(getCredenceNFTByUserId(null, credenceCurrentPage));

      setProcessing(false);
      setSuccess(true);
      setProcessingPercent(100);
    } catch (e) {
      setError(
        extractErrorMessage(e).includes("insufficient")
          ? "You have insufficient funds in your account,\nPlease top up to continue"
          : extractErrorMessage(e)
      );
      setProcessing(false);
      setSuccess(false);
      setFailed(true);
    }
  };

  const evmOnSubmit = async () => {
    try {
      setProcessing(true);
      setIsMode(1);
      await writeCreateNFT();

      setProcessing(false);
      setSuccess(true);
      setProcessingPercent(100);
    } catch (e) {
      console.log(e);
      setError(
        extractErrorMessage(e).includes("insufficient")
          ? "You have insufficient funds in your account,\nPlease top up to continue"
          : "Error when creating nft"
      );
      setProcessing(false);
      setSuccess(false);
      setFailed(true);
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const resolveContent = () => {
    switch (isMode) {
      case 0:
        return (
          <StepContentStyled className="p-12px">
            <Row className="h-100" gutter={[24, 24]}>
              <Col
                xs={24}
                sm={24}
                md={windowDimensions.width > 925 ? 12 : 24}
                lg={11}
                xl={11}
                xxl={11}
              >
                {props.record?.cover_file !== null ? (
                  <Image
                    src={CREDENCE_HOST + "/files/" + props.record?.cover_file}
                    style={{
                      width: "100%",
                      minHeight: "400px",
                      objectFit: "cover",
                      borderRadius: 4,
                    }}
                  />
                ) : (
                  <Image
                    src={
                      "https://ipfs.io/ipfs/" +
                      metadata?.image.replace("ipfs://", "")
                    }
                    placeholder={
                      <Image
                        preview={false}
                        src={
                          "https://ipfs.io/ipfs/" +
                          metadata?.image.replace("ipfs://", "")
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          minHeight: "400px",
                          objectFit: "cover",
                          borderRadius: 4,
                        }}
                      />
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      minHeight: "400px",
                      objectFit: "cover",
                      borderRadius: 4,
                    }}
                  />
                )}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={windowDimensions.width > 925 ? 12 : 24}
                lg={13}
                xl={13}
                xxl={13}
              >
                <Title
                  className="text-w700 gray-7 mb-8px"
                  level={windowDimensions.width > 835 ? 4 : 2}
                >
                  {metadata?.name}
                </Title>
                <Text
                  className={
                    "gray-7 " + (windowDimensions.width < 835 && "text-size-16")
                  }
                >
                  {metadata?.description}
                </Text>
                <CanvasNftDetails>
                  <Title className="text-w500 gray-7 mb-0px" level={5}>
                    NFT Details
                  </Title>
                  <DividerCard />
                  {IS_EVM && (
                    <RowSpaceBetween className="mt-16px">
                      <Text className="gray-7">Symbol</Text>
                      <Text className="text-w500 gray-7 text-size-16">
                        {props.record?.symbol}
                      </Text>
                    </RowSpaceBetween>
                  )}
                  <RowSpaceBetween className="mt-16px">
                    <Text className="gray-7">Collection</Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {metadata?.collection?.name || "-"}
                    </Text>
                  </RowSpaceBetween>
                  <RowSpaceBetween className="mt-16px">
                    <Text className="gray-7">
                      {IS_EVM ? "Max Supply" : "Fragmentation Supply"}
                    </Text>
                    <Text className="text-w500 gray-7 text-size-16">
                      {IS_EVM
                        ? props.record?.max_supply
                        : props.record?.fragmentation}
                    </Text>
                  </RowSpaceBetween>
                  {!IS_EVM && (
                    <RowSpaceBetween className="mt-16px">
                      <Text className="gray-7">External Link</Text>
                      <Text className="text-w500 gray-7 text-size-16">
                        {metadata?.web_link}
                      </Text>
                    </RowSpaceBetween>
                  )}
                  {!IS_EVM && (
                    <RowSpaceBetween className="mt-16px">
                      <Text className="gray-7">Twitter</Text>
                      <Text className="text-w500 gray-7 text-size-16">
                        {metadata?.twitter_link}
                      </Text>
                    </RowSpaceBetween>
                  )}
                  {!IS_EVM && (
                    <RowSpaceBetween className="mt-16px">
                      <Text className="gray-7">Instagram</Text>
                      <Text className="text-w500 gray-7 text-size-16">
                        {metadata?.instagram_link}
                      </Text>
                    </RowSpaceBetween>
                  )}
                </CanvasNftDetails>
                {!IS_EVM && (
                  <TotalDue>
                    <RowSpaceBetween>
                      <Title className="text-w600 gray-6 mb-0px" level={5}>
                        Total Due
                      </Title>
                      <RowEnd>
                        <IBFx1 src={ibfx1} className="mr-4px" />
                        <Title className="text-w600 gray-6 mb-0px" level={4}>
                          {fromIBFx(props.record?.fee)}
                        </Title>
                      </RowEnd>
                    </RowSpaceBetween>
                  </TotalDue>
                )}
              </Col>
            </Row>
          </StepContentStyled>
        );

      case 1:
        if (isProcessing || isSuccess || isFailed) {
          return (
            <StepContentStyled className="center p-12px">
              <ColCenter>
                <Progress
                  type="circle"
                  percent={processingPercent}
                  status={isFailed && "exception"}
                />
                <Title className="text-w700 gray-7 mb-0px mt-24px" level={3}>
                  {isProcessing && !isSuccess && !isFailed
                    ? "Processing"
                    : isSuccess && !isFailed
                    ? "NFT Added Successfully"
                    : "Failed"}
                </Title>
                <Text
                  style={{ width: "50%" }}
                  className="gray-7"
                  align="center"
                >
                  {isProcessing && !isSuccess && !isFailed
                    ? "Please wait while we create your NFT"
                    : isSuccess && !isFailed
                    ? `Your NFT creation is currently under process. You can find your NFT in your vault after it has been minted.`
                    : error}
                </Text>
                {!isProcessing && (
                  <>
                    <ButtonStyled
                      className="mt-24px"
                      style={{
                        width: "350px",
                        height: "48px",
                        borderRadius: "4px",
                      }}
                      onClick={() =>
                        isSuccess && !isFailed
                          ? toVault()
                          : dispatch(setSection("wallet-dashboard"))
                      }
                    >
                      {isSuccess && !isFailed ? "Go to Vault" : "Top up Wallet"}
                    </ButtonStyled>
                    <ButtonOutline
                      className="mt-10px"
                      style={{
                        width: "350px",
                        height: "48px",
                        borderRadius: "4px",
                      }}
                      onClick={() =>
                        isSuccess && !isFailed
                          ? dispatch(setSection("wallet-faq", "credence"))
                          : handleCancel()
                      }
                    >
                      {isSuccess && !isFailed ? "Check FAQ" : "Dismiss"}
                    </ButtonOutline>
                  </>
                )}
              </ColCenter>
            </StepContentStyled>
          );
        }
        return (
          <StepContentStyled className="center p-12px">
            <ColCenter>
              <Row className="mt-24px">
                <Col span={24}>
                  <RowCenter>
                    <ReactCodeInput
                      type="password"
                      onChange={(e) => setPinCode(e)}
                      fields={4}
                      {...codeProps}
                    />
                  </RowCenter>
                </Col>
                {isPinError && (
                  <Col className="mt-10px" span={24}>
                    <ColCenter>
                      <Alert
                        message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                        type="error"
                        showIcon
                        style={{ width: "40%" }}
                      />
                    </ColCenter>
                  </Col>
                )}
              </Row>
              <ButtonStyled
                style={{
                  width: 350,
                  height: 48,
                  marginTop: 30,
                  marginBottom: 70,
                }}
                onClick={() => onSubmit()}
                disabled={isProcessing}
              >
                {!isProcessing ? "Confirm Pin" : <Spin />}
              </ButtonStyled>
            </ColCenter>
          </StepContentStyled>
        );
    }
  };

  const resolveContinueButton = () => {
    return (
      <ButtonStyled
        style={
          windowDimensions.width > 835
            ? { width: 350, height: 48, borderRadius: "4px" }
            : { width: "100%", height: 48, borderRadius: "4px" }
        }
        type="primary"
        onClick={() => {
          if (IS_EVM) {
            evmOnSubmit();
          } else if (fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I)) < 1.99) {
            setIsModalInsufficientVisible(true);
            setIsModalVisible(false);
          } else {
            setIsMode(isMode + 1);
          }
        }}
        disabled={IS_EVM ? !isConnected || isProcessing : isProcessing}
      >
        {IS_EVM ? "Submit" : "Proceed to Pay"}
      </ButtonStyled>
    );
  };

  return (
    <>
      {props.isFromDetail ? (
        <ButtonOutline
          block
          disabled={props.disabled}
          className="ml-8px"
          style={{ height: 40, borderRadius: 4 }}
          onClick={() => {
            showModal();
          }}
        >
          Pay Fee
        </ButtonOutline>
      ) : (
        <a disabled={props.disabled} onClick={!props.disabled && showModal}>
          {IS_EVM ? "Create Contract" : "Pay Now"}
        </a>
      )}
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          className="modal-sell-nft"
          visible={isModalVisible}
          onCancel={handleCancel}
          width="65%"
          maskStyle={{ backgroundColor: "#fafafa" }}
          footer={
            isMode < 1
              ? [
                  <RowCenter className="my-12px">
                    {resolveContinueButton()}
                  </RowCenter>,
                ]
              : null
          }
        >
          <ContentStyled>
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                {isMode === 1 && !IS_EVM ? "Enter Pin" : "Pay"}
              </Title>
              <Text className="gray-7 mb-24px" align="center">
                {isMode === 1 && !IS_EVM
                  ? "Please enter your pin code to confirm the transaction"
                  : "Pay for your approved NFT"}
              </Text>
              {resolveContent()}
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          className={"drawer-create-nft " + (isMode === 0 && "force-scroll")}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                {isMode === 1 ? "Enter Pin" : "Pay"}
              </Title>
              <Text className="gray-7 mb-24px" align="center">
                {isMode === 1
                  ? "Please enter your pin code to confirm the transaction"
                  : "Pay for your approved NFT"}
              </Text>
              {resolveContent()}
            </ColCenter>
            {isMode < 1
              ? [
                  <RowCenter className="my-12px pb-24px">
                    {resolveContinueButton()}
                  </RowCenter>,
                ]
              : null}
          </ContentStyled>
        </Drawer>
      )}
      <ModalInsufficientFunds
        amount={2}
        setModalVisible={setIsModalInsufficientVisible}
        isModalVisible={isModalInsufficientVisible}
      />
    </>
  );
}
