import styled from "@emotion/styled";
import { Button, Divider, InputNumber } from "antd";
import { ButtonStyled, RowSpaceBetween } from "../general_styled";

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
`;

export const BackButtonStyled = styled.img`
  cursor: pointer;
  position: absolute;
  // left: -8px;
  z-index: 2;

  @media screen and (max-width: 834px) {
    top: -18px;
  }
`;

export const BalanceCardStyled = styled.div`
  /* Neutral/100 */

  background: #fff;
  /* Neutral/500 */

  border: 1px solid #edeff2;
  background: #fff;
  box-sizing: border-box;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  border-radius: 10px;
  padding: 22.5px 20px;
  margin-top: 24px;

  &.bg-white {
    background: #ffffff;
  }
`;
export const WarningAmountStyled = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  padding-top: 7px;

  color: #6b7c94;
`;

export const BoxAmountStyled = styled.div`
  /* Shades/White */

  background: #fff;
  /* Neutral/500 */

  border: 1px solid #edeff2;
  box-sizing: border-box;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  border-radius: 10px;
  margin-top: 24px;
  padding: 20px;

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    /* identical to box height, or 117% */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Gray/gray-7 */

    color: #273248;
    margin-bottom: 16px;
  }

  .selector {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    margin: 6px;
    cursor: pointer;

    /* Gray/gray-1 */

    background: #024FF1;
    /* Neutral/500 */

    border: 1px solid #024FF1;
    box-sizing: border-box;
    border-radius: 2px;

    span {
      /* Semibold / 14px | 22px */

      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      /* identical to box height, or 157% */

      /* Text/300 */

      color: #fff;
    }

    &.selected {
      background: #fcfcfc;
      /* Primary/500 */

      border: 1px solid #024FF1;
      box-sizing: border-box;
      border-radius: 2px;

      span {
        /* Semibold / 14px | 22px */

        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        /* identical to box height, or 157% */

        /* Primary/500 */

        color: #024FF1;
      }
    }
  }
`;

export const DividerStyled = styled(Divider)`
  margin-top: 24px !important;
  margin-bottom: 0 !important;
  span {
    /* Footer/ All Caps */

    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    /* identical to box height, or 140% */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Text/100 */

    color: #7e92b8;
  }
`;

export const LabelPayment = styled.span`
  /* Caption/All Caps */

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height, or 117% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Gray/gray-7 */

  color: #273248;
`;

export const BoxPaymentStyled = styled.div`
  margin-top: 16px;
  /* Shades/White */

  background: #fcfcfc;
  /* Neutral/600 */

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px;

  .name {
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    /* identical to box height */

    /* Gray/gray-7 */

    color: #273248;
  }

  .number {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Text/300 */

    color: #44567b;
  }
`;

export const BoxReceivers = styled.div`
  background: #0c2c49;
  padding: 20px;
  /* Neutral/500 */
  border-radius: 10px;

  border: 1px solid #edeff2;
  box-sizing: border-box;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  .label-receiver {
    /* Caption/All Caps */

    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    /* identical to box height, or 117% */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Gray/gray-7 */

    color: #fff;
  }

  .show-receive {
    margin-top: 16px;
    padding: 9px 0;
    /* Neutral/100 */

    background: #f7f8fa;
    /* Neutral/500 */

    border: 1px solid #edeff2;
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    align-items: center;

    h6.receive {
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 0;
      text-align: center;

      /* Semibold / 14px | 22px */

      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      /* identical to box height, or 157% */

      text-align: center;

      /* Text/400 */

      color: #273248;
    }
  }
`;

export const InputStyled = styled.div`
  margin: 100px 0px 14px 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  color: #172b4d;
  background: transparent;
  padding: 8px;
  border: 2px solid #dfe1e6;
`;

export const BoxReceivedAmount = styled(RowSpaceBetween)`
  padding: 20px 22px;
  margin-top: 24px;
  background: #0c2c49;
  height: 60px;

  border: 1px solid #edeff2;
  box-sizing: border-box;
  border-radius: 10px;

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;

    color: #fff;
  }

  .value {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height */

    /* Gray/gray-7 */

    color: #fff;
  }
`;

export const InputNumberStyled = styled(InputNumber)`
  .ant-input-number-lg input {
    height: 50px;
  }
`;
