import React, { useState, useEffect } from "react";
import { ColCenter, RowStart } from "../../components/general_styled";
import { CardStyled, CanvasCenter, ButtonStyled, Divider } from "./styled";
import BenevLogo from "../../assets/images/benevolence-logo.png";
import { Image, Typography, Row, Col, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSection } from "../../modules/general/action";
import {
  ASSETS_NAME,
  ASSETS_STATUS,
  USER_STATUS,
} from "../../constants/constant_wallet";
import {
  getUserDataById,
  registerUserBenevolence,
} from "../../modules/benevolence/action";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;
export default function BenevolenceOnBoarding(props) {
  const { t } = useTranslation();
  const { onClose = () => {} } = props;
  const dispatch = useDispatch();
  const [ibfx1, setIbfx1] = useState({});
  // const [ibfx3, setIbfx3] = useState({})
  // const [ibfs, setIbfs] = useState({})
  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const benevolenceUser = useSelector((state) =>
    state.getIn(["benevolence", "user"]).toJS()
  );

  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );

  const [isLoading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#024FF1" }} spin />
  );

  const initIbfAssets = async () => {
    const getIbfx1 = allAssets.filter(
      (e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_I
    );
    // const getIbfx3 = allAssets.filter((e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_III)
    // const getIbfs = allAssets.filter((e) => e.asset_name === ASSETS_NAME.IBFS)

    setIbfx1(getIbfx1[0]);
    // setIbfx3(getIbfx3[0])
    // setIbfs(getIbfs[0])
  };

  const onButtonClicked = async () => {
    if (
      Object.keys(walletUser) <= 0 ||
      walletUser.status !== USER_STATUS.ACTIVE ||
      ibfx1.status !== ASSETS_STATUS.ACTIVE
      // ibfx3.status !== ASSETS_STATUS.ACTIVE ||
      // ibfs.status !== ASSETS_STATUS.ACTIVE
    ) {
      dispatch(setSection("wallet-dashboard"));
    } else if (!benevolenceUser?.user?.algo_address) {
      try {
        setLoading(true);
        await registerUserBenevolence(walletUser.algo_address, true);
        // if (result.code === "SUCCESS") {
        await dispatch(getUserDataById(walletUser.user_id));
        // await dispatch(setSection("benevolence-manage"));
        // } else {
        //   notification.error({
        //     message: "Register Benevolence Failed!",
        //     description: result.message,
        //   })
        // }
        setLoading(false);
      } catch (e) {
        notification.error({
          message: "Register Benevolence Failed!",
          description: e,
        });
      }
    }
    onClose();
  };

  useEffect(() => {
    dispatch(getUserDataById(walletUser.user_id));
  }, []);

  const [initStatus, setInitStatus] = useState(false);
  useEffect(() => {
    if (allAssets.length > 0) {
      if (!initStatus) {
        initIbfAssets();
        setInitStatus(true);
      }
    }
  }, [allAssets]);

  const resolveRequirement = () => {
    let requirements = [];
    if (!benevolenceUser?.user?.algo_address) {
      requirements.push("Register User");
    }
    if (ibfx1.status !== ASSETS_STATUS.ACTIVE) {
      requirements.push("Activation of IBFX and IBFS in Wallet");
    }
    return requirements.join(", ");
  };

  return (
    <CanvasCenter>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <CardStyled>
          <ColCenter className="start">
            <RowStart className="w-100">
              <Image src={BenevLogo} preview={false} width={140} />
            </RowStart>
            <Divider />
            <Title
              align="left"
              level={3}
              style={{
                fontWeight: "bold",
                margin: 0,
                marginBottom: 8,
                fontSize: 26,
              }}
            >
              {t("welcome")}
            </Title>
            <Text align="left" level={5} style={{ margin: 0 }}>
              {t("benevolence_onboarding")}
            </Text>
            <Text
              align="left"
              italic
              style={{
                fontSize: 13,
                margin: 0,
                marginTop: 12,
                color: "red",
              }}
            >
              {`${t("benevolence_requirement")} ${resolveRequirement()}`}
            </Text>
            <ButtonStyled size="large" block onClick={() => onButtonClicked()}>
              {isLoading ? antIcon : t("start_benevolence")}
            </ButtonStyled>
          </ColCenter>
        </CardStyled>
      </Col>
    </CanvasCenter>
  );
}
