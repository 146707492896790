import styled from "@emotion/styled";

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 686px;

  .ant-upload.ant-upload-select {
    display: block;
  }
`;

export const ContentCardStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  cursor: pointer;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 4px;

  &.selected {
    background: #ffffff;
    border: 1px solid #289a67;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
    border-radius: 4px;
  }

  .check {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const TitleStyled = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #5b7697;

  &.selected {
    color: #364559;
  }
`;

export const SubtitleStyled = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #5b7697;
  text-align: center;

  &.selected {
    color: #364559;
  }
`;

export const IconCoins = styled.img`
  height: 20px;
  margin-right: 5px;
  &.no-margin {
    margin-right: 0;
  }
`;

export const TotalFee = styled.div`
  width: 100%;
  padding: 10px 16px;

  background: #f7f8fa;
  /* Secondary/500 */

  border: 1px solid #3b4552;
  box-sizing: border-box;
  border-radius: 4px;
`;
