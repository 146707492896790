import { Row, Col, Button, Image, Typography, Avatar } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, withRouter } from 'react-router'
import { RowStart } from '../../components/general_styled'
import { MEMBERSHIP_HOST } from '../../configs'
import { setSection } from '../../modules/general/action'
import useWindowDimensions from '../../utils/windowDimensions'
import { CardStyled, CardTitle, GreenText, OverviewText } from '../Feed/styled'
import { MainCard, CardSideStyled, Divider } from './styled'
import { useTranslation } from 'react-i18next'

const { Title, Text } = Typography

function FeedDetails(props) {
	const { t } = useTranslation()
	// const announcementId = props.match.params["announcement_id"]
	const windowDimensions = useWindowDimensions()
	// const { announcement_id } = useParams()
	const [selectedAnnouncement, setSelectedAnnouncement] = useState({})
	const [sideAnnouncements, setSideAnnouncements] = useState([])
	const announcement = useSelector((state) => state.getIn(['announcement']).toJS())
	const announcement_id = useSelector((state) => state.getIn(['general', 'param']))
	const history = useHistory()
	const dispatch = useDispatch()

	const initAnnouncements = () => {
		let newSelectedAnnouncement = null
		let newSideAnnouncements = []
		let totalSideAnnouncement = 0
		for (const data of announcement.announcements.data) {
			if (data.announcement_id === announcement_id) {
				newSelectedAnnouncement = data
				continue
			}

			if (newSelectedAnnouncement && totalSideAnnouncement < 4) {
				newSideAnnouncements.push(data)
				totalSideAnnouncement++
			}
		}

		let index = 0
		while (
			announcement.announcements?.data.length > 3
				? totalSideAnnouncement < 4
				: totalSideAnnouncement < announcement.announcements?.data.length
		) {
			if (announcement.announcements?.data[index]?.announcement_id !== announcement_id) {
				newSideAnnouncements.push(announcement.announcements.data[index])
			}

			index++
			totalSideAnnouncement++
		}

		setSelectedAnnouncement(newSelectedAnnouncement)
		setSideAnnouncements(newSideAnnouncements)
	}

	useEffect(() => {
		if (announcement.announcements.data.length <= 0) {
			dispatch(setSection('feed'))
		}

		initAnnouncements()
	}, [props.announcements, announcement_id])

	// const getContentText = (content) => {
	//   var div = document.createElement("div")
	//   div.innerHTML = content
	//   var text = div.textContent || div.innerText || ""
	//   return text
	// }

	return (
		<Row className='mb-24px'>
			<Col xs={24} sm={24} md={24} lg={16} xl={17} xxl={17}>
				<MainCard
					cover={
						<Image
							style={{
								maxHeight: 165,
								borderTopLeftRadius: 4,
								borderTopRightRadius: 4,
								marginTop: 4,
							}}
							preview={false}
							src={`${MEMBERSHIP_HOST}/files/${selectedAnnouncement.image}`}
						/>
					}
				>
					<Row>
						<Col>
							<GreenText>{moment(selectedAnnouncement.created_date).format('D MMMM[,] YYYY [at] h:mm')}</GreenText>
							<CardTitle>{selectedAnnouncement.title}</CardTitle>
							<div
								className='roboto'
								dangerouslySetInnerHTML={{
									__html: selectedAnnouncement.content,
								}}
							></div>
							{selectedAnnouncement.url != null && (
								<Button
									type='primary'
									className='mt-16px'
									ghost
									style={{ width: '50%', height: '45px' }}
									onClick={() => {
										window.open(selectedAnnouncement.url, '_blank', 'noopener,noreferrer')
									}}
								>
									{t('read_more')}
								</Button>
							)}
						</Col>
					</Row>
				</MainCard>
			</Col>
			<Col xs={24} sm={24} md={24} lg={8} xl={7} xxl={7}>
				<CardSideStyled>
					{windowDimensions.width > 1000 ? (
						<>
							{sideAnnouncements.map((data, key) => {
								var div = document.createElement('div')
								div.innerHTML = data?.content || ''
								var text = div.textContent || div.innerText || ''

								return (
									<div key={key}>
										<RowStart className='align-start'>
											<Avatar
												size={72}
												shape='square'
												style={{ borderRadius: 4, width: 72 }}
												src={`${MEMBERSHIP_HOST}/files/${data?.image}`}
											/>
											<div className='expanded ml-12px'>
												<Title className='mb-4px mt-0px' level={5}>
													{(data?.title.length > 24 ? data?.title.substring(0, 23) + '...' : data?.title) || ''}
												</Title>
												<Text>{text.length > 69 ? text.substring(0, 68) + '...' : text}</Text>
											</div>
										</RowStart>
										<Button
											type='primary'
											ghost
											block
											className='mt-20px'
											style={{ height: '40px' }}
											onClick={() => {
												dispatch(setSection('feed-details', data.announcement_id))
											}}
										>
											{t('view')}
										</Button>
										{key !== sideAnnouncements.length - 1 ? <Divider /> : <div />}
									</div>
								)
							})}
						</>
					) : (
						<Row gutter={[16, 16]}>
							{sideAnnouncements.map((data, key) => {
								var div = document.createElement('div')
								div.innerHTML = data?.content || ''
								var text = div.textContent || div.innerText || ''

								return (
									<Col xs={24} sm={12} md={12} key={key}>
										<RowStart className='align-start'>
											<Avatar
												size={72}
												shape='square'
												style={{ borderRadius: 4, width: 72 }}
												src={`${MEMBERSHIP_HOST}/files/${data?.image}`}
											/>
											<div className='expanded ml-12px'>
												<Title className='mb-4px' level={5}>
													{(data?.title.length > 24 ? data?.title.substring(0, 23) + '...' : data?.title) || ''}
												</Title>
												<Text>{text.length > 69 ? text.substring(0, 68) + '...' : text}</Text>
											</div>
										</RowStart>
										<Button
											type='primary'
											ghost
											block
											className='mt-20px'
											style={{ height: '40px' }}
											onClick={() => {
												dispatch(setSection('feed-details', data.announcement_id))
											}}
										>
											View
										</Button>
										{key !== sideAnnouncements.length - 1 ? <Divider /> : <div />}
									</Col>
									// <Col xs={24} sm={12} md={12}>
									//   <CardStyled
									//     key={key}
									//     className={
									//       key % 2 === 0 && windowDimensions.width > 575
									//         ? "mr-6px"
									//         : "ml-6px"
									//     }
									//     cover={
									//       <Image
									//         preview={false}
									//         src={`${MEMBERSHIP_HOST}/files/${data.image}`}
									//       />
									//     }
									//     actions={[
									//       <Button
									//         type="primary"
									//         ghost
									//         style={{ width: "100%", height: "40px" }}
									//         onClick={() => {
									//           dispatch(
									//             setSection("feed-details", data.announcement_id)
									//           )
									//         }}
									//       >
									//         View
									//       </Button>,
									//     ]}
									//   >
									//     <Col>
									//       <GreenText>
									//         {moment(data.created_date).format(
									//           "D MMMM[,] YYYY [at] h:mm"
									//         )}
									//       </GreenText>
									//       <CardTitle>{data.title}</CardTitle>
									//       <OverviewText>{text}</OverviewText>
									//     </Col>
									//   </CardStyled>
									// </Col>
								)
							})}
						</Row>
					)}
				</CardSideStyled>
			</Col>
		</Row>
	)
}

export default React.memo(withRouter(FeedDetails))
