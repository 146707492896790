import React, { useEffect, useMemo } from "react";
import decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import moment from "moment";
import {
  CardStyled,
  IconStyled,
  Divider,
  ActivityTitle,
  EmptyActivityStyled,
  ActivityTime,
  HomeBannerStyled,
} from "./styled";
import DollarWhiteIcon from "../../assets/images/dollar_home_white.svg";
import BookmarkIcon from "../../assets/images/voucher-home.svg";
import LoveIcon from "../../assets/images/love_home.svg";
import Hand from "../../assets/images/hand.svg";
import LogoWhite from "../../assets/images/home/logo_white.svg";
import HomeBanner from "../../assets/images/home/home_banner.png";
import emptyActivityImg from "../../assets/images/empty_activity.svg";
import {
  ColCenter,
  ColStart,
  RowSpaceBetween,
  RowStart,
} from "../../components/general_styled";
import { useDispatch, useSelector } from "react-redux";
import { getRecentActivity, setSection } from "../../modules/general/action";
import useWindowDimensions from "../../utils/windowDimensions";
import { TRANSACTION_TYPE, ASSETS_NAME } from "../../constants/constant_wallet";
import { fromIBFx, titleCase } from "../../utils/utils";
import { useState } from "react";
import Feed from "../Feed";
import { IS_EVM, LOGOUT_URL } from "../../configs";
import { logout } from "../../modules/auth/action";
import { useAuth0 } from "@auth0/auth0-react";
import { BannerStyled, ButtonStyled as Button } from "../BenevolenceDonate/styled";

const { Title, Text } = Typography;

const resolveDate = (date) => {
  const formattedDate = moment(date);

  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: function (number, withoutSuffix, key, isFuture) {
        return "00:" + (number < 10 ? "0" : "") + number + " minutes";
      },
      m: "01:00 minutes",
      mm: function (number, withoutSuffix, key, isFuture) {
        return (number < 10 ? "0" : "") + number + ":00" + " minutes";
      },
      h: "an hour",
      hh: "%d hours",
      d: "a day",
      dd: "%d days",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });

  return formattedDate.fromNow().includes("month") ||
    formattedDate.fromNow().includes("year")
    ? formattedDate.format("DD MMM YYYY - HH:mm")
    : formattedDate.fromNow();
};

const resolveIcon = (platform) => {
  switch (platform) {
    case "WALLET":
      return DollarWhiteIcon;
    case "BENEVOLENCE":
      return LoveIcon;
    default:
      return BookmarkIcon;
  }
};

const resolveAssetName = (name) => {
  switch (name) {
    case ASSETS_NAME.IBFS:
      return "IBFS";
    case ASSETS_NAME.IBFX_TYPE_I:
      return "IBFX";
    case ASSETS_NAME.IBFX_TYPE_II:
      return "IBFX - 2";
    case ASSETS_NAME.IBFX_TYPE_III:
      return "IBFX - 3";
    case ASSETS_NAME.IBFX_TYPE_IV:
      return "IBFX - 4";
    default:
      return "IBFX";
  }
};

export default function Home() {
  const { t } = useTranslation();
  const auth0 = useAuth0();

  const windowDimensions = useWindowDimensions();
  const isDekstop = useMemo(() => windowDimensions.width > 900, [windowDimensions]); 
  const activities = useSelector((state) =>
    state.getIn(["general", "activities"]).toJS()
  );
  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );

  const dispatch = useDispatch();
  const [isGuest, setIsGuest] = useState(false);

  const onViewAll = () => {
    // if (Object.keys(walletUser).length <= 0) {
    dispatch(setSection("activity"));
    // }
  };

  const resolveName = (e) => {
    if (e.type === TRANSACTION_TYPE.BUY_BACK) {
      return [
        'Convert "$' +
          parseFloat((fromIBFx(e.amount) / ibfxPerUSD).toFixed(2)) +
          " " +
          resolveAssetName(e.asset ? e.asset.assetName : null) +
          '" to' +
          " USD",
      ];
    } else if (e.type === TRANSACTION_TYPE.TOPUP) {
      return [
        'Top Up "' +
          fromIBFx(e.amount) +
          " " +
          resolveAssetName(e.asset ? e.asset.assetName : null) +
          '"',
      ];
    } else if (e.type === TRANSACTION_TYPE.REDEEM_VOUCHER) {
      return [
        'Redeem Voucher "' +
          fromIBFx(e.amount) +
          " " +
          resolveAssetName(e.asset ? e.asset.assetName : null) +
          '"',
      ];
    } else if (e.type === TRANSACTION_TYPE.PAY) {
      if (e.receiver) {
        return [
          'Pay "' +
            fromIBFx(e.amount) +
            " " +
            resolveAssetName(e.asset ? e.asset.assetName : null) +
            " to " +
            e.receiver?.algo_address.substring(0, 7) +
            "..." +
            e.receiver?.algo_address.substring(
              e.receiver?.algo_address.length - 7,
              e.receiver?.algo_address.length - 1
            ) +
            '"',
        ];
      } else if (e.platform === "CREDENCE" && e.event_type == "ESSENCE") {
        const historyMetadata = JSON.parse(e.escrow?.NFT?.metadata_json);
        return (
          "(Affluence) Buy NFT " +
          historyMetadata?.name +
          " - " +
          historyMetadata?.collection?.name +
          " - " +
          "$" +
          (fromIBFx(e.amount) / ibfxPerUSD).toFixed(2)
        );
      } else if (e.platform === "CREDENCE" && e.event_type == "AFFLUENCE") {
        const historyMetadata = JSON.parse(e.escrow?.NFT?.metadata_json);
        return (
          "(Affluence) Accept Bid NFT " +
          historyMetadata?.name +
          " - " +
          historyMetadata?.collection?.name +
          " - " +
          "$" +
          (fromIBFx(e.amount) / ibfxPerUSD).toFixed(2)
        );
      } else {
        return (
          titleCase(e.platform.replace(/_/g, " ")) +
          " - " +
          titleCase(e.event_type.replace(/_/g, " ")) +
          " " +
          "$" +
          (fromIBFx(e.amount) / ibfxPerUSD).toFixed(2)
        );
      }
    } else if (
      e.platform === "CREDENCE" &&
      e.event_type == "CREATE ASSET (ASA)"
    ) {
      return (
        "Create NFT " +
        " - " +
        "$" +
        (fromIBFx(e.amount) / ibfxPerUSD).toFixed(2)
      );
    } else {
      return (
        titleCase(e.platform.replace(/_/g, " ")) +
        " - " +
        titleCase(e.event_type.replace(/_/g, " ")) +
        " - " +
        "$" +
        (fromIBFx(e.amount) / ibfxPerUSD).toFixed(2)
      );
    }
  };

  // const resolveButton = () => {
  //   switch (currentButton) {
  //     case 0:
  //       if (windowDimensions.width < 925) {
  //         return (
  //           <ButtonWhiteStyled
  //             className="mt-12px"
  //             onClick={() => dispatch(setSection("profile"))}
  //           >
  //             Edit Profile
  //           </ButtonWhiteStyled>
  //         );
  //       } else {
  //         return <ModalProfile page="home" />;
  //       }
  //     case 1:
  //       if (IS_EVM) {
  //         return <ConnectWallet page="home" />;
  //       } else {
  //         return <ModalOnboarding page="home" />;
  //       }
  //     case 2:
  //       if (windowDimensions.width < 925) {
  //         return (
  //           <ButtonWhiteStyled
  //             className="mt-12px"
  //             onClick={() => dispatch(setSection("profile"))}
  //           >
  //             Activate Tokens
  //           </ButtonWhiteStyled>
  //         );
  //       } else {
  //         return <ModalWalletDashboard page="home" />;
  //       }
  //     default:
  //       break;
  //   }
  // };


  const checkToken = async () => {
    const token = localStorage.getItem("USER_AUTH_TOKEN");
    if (token) {
      const decoded = decode(token);
      if (Date.now() > decoded.exp * 1000) {
        await auth0.logout({logoutParams: { returnTo: `${LOGOUT_URL}/login` }});
        dispatch(logout());
        return setIsGuest(true);
      }

      dispatch(getRecentActivity(1));
      return setIsGuest(false);
    }
    return setIsGuest(true);
  };

  useEffect(() => {
    checkToken();
  }, []);

  // useEffect(() => {
  //   const isHasAsset = allAssets.length > 0;
  //   const userHasAssetNotActive = allAssets
  //     .filter((i) => i.asset_name === ASSETS_NAME.IBFX_TYPE_I)
  //     .find((i) => !i["opt-status"]);

  //   if (IS_EVM) {
  //     if (!userData?.address || !userData?.country) {
  //       setCurrentButton(0);
  //     } else if (!isConnected) {
  //       setCurrentButton(1);
  //     } else {
  //       setCurrentButton(3);
  //     }
  //   } else {
  //     if (!userData?.address || !userData?.country) {
  //       setCurrentButton(0);
  //     } else if (walletUser?.status === "INACTIVE") {
  //       // } else if (true) {
  //       setCurrentButton(1);
  //     } else if (!isHasAsset || userHasAssetNotActive) {
  //       setCurrentButton(2);
  //     } else {
  //       setCurrentButton(3);
  //     }
  //   }
  // }, [userData, walletUser, allAssets]);

  return (
    <>
      <Banner isDekstop={isDekstop} handleRedirect={() => dispatch(setSection('benevolence-donate'))} />
      {!isGuest && (
        <Row className="mt-24px mb-24px" gutter={[16, 16]}>
          {!IS_EVM && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <CardStyled className="top">
                {activities.data.length > 0 ? (
                  <>
                    <RowSpaceBetween className="w-100">
                      <Title className="mt-0px mb-0px" level={4}>
                        {t("home-recent-activity")}
                        {/* border none, box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px, rgb(9 30 66 / 14%) 0px 0px 20px -6px */}
                      </Title>
                      <Text
                        style={{ color: "#024FF1", cursor: "pointer" }}
                        onClick={onViewAll}
                      >
                        {t("home-view-all")}
                      </Text>
                    </RowSpaceBetween>
                    {activities.data.map((e, i) => {
                      if (i < 3) {
                        return (
                          <>
                            <Divider />
                            <RowStart>
                              <IconStyled
                                className="activity"
                                src={resolveIcon(e.platform)}
                              />
                              <ColStart>
                                <ActivityTitle>{resolveName(e)}</ActivityTitle>
                                <ActivityTime>
                                  {resolveDate(e.created_at)}
                                </ActivityTime>
                              </ColStart>
                            </RowStart>
                          </>
                        );
                      }
                    })}
                    <Divider className="mb-0px" />
                  </>
                ) : <Empty t={t} /> }
              </CardStyled>
            </Col>
          )}
        </Row>
      )}
      <Feed source="home" />
    </>
  );
}


const BannerComponent = ({ isDekstop, handleRedirect }) => {
  return ( 
    <Row className="mt-12px mb-24px" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <HomeBannerStyled img={HomeBanner} isDekstop={isDekstop}>
          <div>
            <img src={LogoWhite} />
            <p className="title mt-24px">The worlds first integrated and {isDekstop && <br />} interconnected suite of blockchain {isDekstop && <br />} applications for the Islamic economy.</p>
            <p className="desc">IBFNEX is powered by {IS_EVM ? 'Polygon': 'Algorand'}, a fast, low-cost, and secure {isDekstop && <br />} blockchain for decentralized applications and digital asset transfers.</p>
          </div>
        </HomeBannerStyled>

        <BannerStyled isDekstop={isDekstop}>
          <div>
            <p className="title">Nurturing the Act of Giving</p>
            <p className="desc">A Platform for Charity and {isDekstop && <br />} Volunteering on the Blockchain.</p>
            <Button onClick={() => handleRedirect()} isDekstop={isDekstop}>View Campaigns</Button>
          </div>
          {isDekstop && <div>
            <img src={Hand} />
          </div>}
        </BannerStyled>
      </Col>
    </Row>
)}

const EmptyComponent = ({ t }) => {
  return (
    <ColCenter className="w-100" style={{ height: "302px" }}>
      <EmptyActivityStyled src={emptyActivityImg} />
      <Title className="mt-12px mb-0px" level={5}>
        {t("home-no-recent-activity")}
      </Title>
      <Text>{t("home-start-recent-activity")}</Text>
    </ColCenter>
  )
}

const Empty = React.memo(EmptyComponent)
const Banner = React.memo(BannerComponent)