import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Divider,
  Drawer,
  Modal,
  Row,
  Typography,
  notification,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { COMMON_CONSTANT, INPUT_STATUS } from "../../constants/constant_common";
import {
  donate,
  donateToWaqfPool,
  submitSignedOperation,
} from "../../modules/benevolence_projects/action";
import { decryptMnemonic, toIBFx } from "../../utils/utils";
import {
  ButtonStyled,
  InputStyled,
  RowStart,
} from "../general_styled";
import VerificationCheck from "../../assets/images/verification-check.svg";
import VerificationCross from "../../assets/images/verification-cross.svg";
import closeIcon from "../../assets/images/close.svg";
import closeModalIcon from "../../assets/images/close_modal.svg";
import ReactCodeInput from "react-code-input";
import useWindowDimensions from "../../utils/windowDimensions";
import { openImportMnemonicModal } from "../../modules/import_mnemonic_modal/action";
import { USER_STATUS } from "../../constants/benevolence_constant";
import { ASSETS_NAME, ASSETS_STATUS } from "../../constants/constant_wallet";
import BenevolenceOnBoarding from "../../pages/BenevolenceOnBoarding";
import ModalCourseWallet from "../ModalCourseWallet";
import { PerawalletContext } from "../../context/perawallet-context";
import ModalCourseStripe from "../ModalCourseStripe";
import { InfoCircleOutlined } from "@ant-design/icons";
import { benevolenceDonationAddress } from "../../configs";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";

import AbiERC20 from "../../assets/abi/ERC20.json";
import AbiBenevolenceDonation from "../../assets/abi/BenevolanceDonation.json";

const { Text } = Typography;

const defaultListAmount = [
  {
    value: "10",
    isSelected: false,
  },
  {
    value: "30",
    isSelected: false,
  },
  {
    value: "50",
    isSelected: false,
  },
  {
    value: "100",
    isSelected: false,
  },
  {
    value: "120",
    isSelected: false,
  },
  {
    value: "150",
    isSelected: false,
  },
];

export default function ModalDonation(props) {
  const { project, mode = 0 } = props;
  const windowDimensions = useWindowDimensions();
  const walletContext = useContext(PerawalletContext);
  const { isConnected, address } = useAccount();

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const dispatch = useDispatch();
  const token = useSelector((state) => state.getIn(["auth", "token"]));
  const user = useSelector((state) => state.getIn(["auth", "userData"]).toJS());
  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );
  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const benevolenceUser = useSelector((state) =>
    state.getIn(["benevolence", "user"]).toJS()
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );

  const [isVerified, setIsVerified] = useState(false);
  const [pin, setPin] = useState("");
  const [isInputPin, setInputPin] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [amount, setAmount] = useState({
    validateStatus: INPUT_STATUS.SUCCESS,
    value: 0,
  });
  const [coin, setCoin] = useState({ key: "IBFX" });
  const [donationValue, setDonationValue] = useState(1);

  const [listAmount, setListAmount] = useState(defaultListAmount);

  useEffect(() => {
    setPin("");
    setInputPin("");
    setAmount({ ...amount, value: "" });
    setFailed(false);
    setProcessing(false);
    setSuccess(false);
    setListAmount(defaultListAmount);
  }, [props.isModalVisible]);

  // Check Allowance Token Payment
  const { data: dataAmountAllowanceTokenPayment } = useContractRead({
    address: coin?.value, // set value contract token payment
    abi: AbiERC20,
    functionName: "allowance",
    args: [
      address, // address donator
      benevolenceDonationAddress,
    ],
  });

  // Set Allowance Token Payment
  const { config: configSetAllowanceTokenPayment } = usePrepareContractWrite({
    address: coin?.value, // set value contract token payment
    abi: AbiERC20,
    functionName: "increaseAllowance",
    args: [
      benevolenceDonationAddress,
      "1000000000000000000", // it doesn't matter if you don't replace it
    ],
  });
  const {
    writeAsync: writeSetAllowanceTokenPayment,
    isLoading: isLoadingSetAllowance,
    isSuccess: isSuccessSetAllowanceNft,
  } = useContractWrite(configSetAllowanceTokenPayment);

  // Donate
  const { config: configDonate } = usePrepareContractWrite({
    address: benevolenceDonationAddress,
    abi: AbiBenevolenceDonation,
    functionName: "donate",
    args: [
      project?.nonce, // id = nonce
      { value: donationValue * 10 ** 6 }, // amount IBFN/USDT
      // { value: ethers.utils.parseEther(amount?.value.toString()) },
    ],
  });
  const {
    writeAsync: writeDonate,
    data: dataDonate,
    isSuccess: isSuccessDonate,
    isLoading: isLoadingDonate,
  } = useContractWrite(configDonate);

  useEffect(() => {
    if (isSuccessSetAllowanceNft) {
      notification.success({
        description:
          "Permission granted for Donation with this payment, Please wait for blockchain confirmation & refresh the page after 1 mins",
      });
    }
  }, [isLoadingSetAllowance]);

  useEffect(() => {
    if (isSuccessDonate) {
      notification.success({
        description:
          "Your Donate was successful. Your Balance will soon be deducted, and the transaction activity can be viewed on your wallet app",
      });
      onClose();
    }
  }, [isLoadingDonate]);

  useEffect(() => {
    setDonationValue(resolveUnitFromUSD(coin.key));
  }, [amount]);

  const onClose = () => {
    props.setModalVisible(false);
    setListAmount(defaultListAmount);
  };

  const onDonate = async () => {
    setProcessing(true);
    try {
      const body = {
        user_id: user.user_id,
        project_id: props.project.project_id,
        amount: toIBFx(resolveUnitFromUSD(coin.key)),
        ...props.behalfDetail
      }
      const operations = await donate(token, body);
      const encryptedMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const mnemonic = decryptMnemonic(encryptedMnemonic, pin);
      await submitSignedOperation(mnemonic, operations);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      setFailed(true);
    }

    setProcessing(false);
  };

  const onDonateToWaqfPool = async () => {
    setProcessing(true);
    try {
      const operations = await donateToWaqfPool(token, {
        user_id: user.user_id,
        amount: toIBFx(resolveUnitFromUSD(coin.key)),
      });
      const encryptedMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const mnemonic = decryptMnemonic(encryptedMnemonic, pin);
      await submitSignedOperation(mnemonic, operations);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      setFailed(true);
    }

    setProcessing(false);
  };

  const renderCardContent = () => {
    return listAmount.map((amt, key) => {
      return (
        <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <span
            className={
              amt.isSelected ? "card-content is-selected" : "card-content"
            }
            onClick={() => {
              setAmount({ ...amount, value: amt.value });
              for (let i = 0; i < listAmount.length; i++) {
                if (i === key) {
                  listAmount[i].isSelected = true;
                } else {
                  listAmount[i].isSelected = false;
                }
              }
              setListAmount([...listAmount]);
            }}
          >
            $ {amt.value}
          </span>
        </Col>
      );
    });
  };

  const renderDonationProcessing = () => {
    return (
      <Col className="donation-modal">
        <FontAwesomeIcon icon={faSpinner} />
        <span className="donating-amount">
          Donating {resolveUnitFromUSD(coin.key)} {coin?.key}
        </span>
        <span className="description">
          Success your transaction has been completed
        </span>
      </Col>
    );
  };

  const renderDonationSuccess = () => {
    return (
      <Col className="donation-modal">
        <img className="success-icon" src={VerificationCheck} />
        <span className="success-title">Success!</span>
        <span className="success-desc">
          You have succesfully donated to the campaign “
          {props.toWaqfPool ? "Waqf Pool" : props.project.name}”
        </span>
        <ButtonStyled
          onClick={onClose}
          style={{
            marginTop: "24px",
            width: "100%",
          }}
        >
          Dismiss
        </ButtonStyled>
      </Col>
    );
  };

  const renderDonationFailed = () => {
    return (
      <Col className="donation-modal">
        <img className="success-icon" src={VerificationCross} />
        <span className="success-title">Error</span>
        <span className="success-desc">
          An error has occured while trying to donate to “
          {props.toWaqfPool ? "Waqf Pool" : props.project.name}”
        </span>
        <ButtonStyled
          onClick={() => {
            setFailed(false);
            setSuccess(false);
            setProcessing(false);
          }}
          style={{
            marginTop: "24px",
            width: "100%",
          }}
        >
          Try Again
        </ButtonStyled>
        <ButtonStyled
          style={{
            marginTop: "24px",
            width: "100%",
          }}
          onClick={onClose}
        >
          Dismiss
        </ButtonStyled>
      </Col>
    );
  };

  const renderInputPin = () => {
    return (
      <Col className="donation-modal" span={24}>
        <div className="close-modal">
          <img src={closeModalIcon} onClick={onClose} />
        </div>
        <span className="title">Pin</span>
        <span className="subtitle">Please input your pin</span>
        <ReactCodeInput
          type={"password"}
          value={pin}
          onChange={(value) => setPin(value)}
          {...codeProps}
        />
        <ButtonStyled
          onClick={props.toWaqfPool ? onDonateToWaqfPool : onDonate}
          style={{
            marginTop: "24px",
            width: "100%",
          }}
        >
          Submit
        </ButtonStyled>
        <ButtonStyled
          onClick={() => {
            dispatch(openImportMnemonicModal("Reset PIN"));
            onClose();
          }}
          style={{
            marginTop: "8px",
            width: "100%",
            color: "#024FF1 !important",
          }}
          type="primary"
          ghost
        >
          Reset PIN
        </ButtonStyled>
      </Col>
    );
  };

  const renderDefault = () => {
    return (
      <Col className="donation-modal" span={24}>
       {windowDimensions.width > 925 && <div className="close-modal">
          <img src={closeModalIcon} onClick={onClose} />
        </div>}
        <span className="title">Donate</span>
        <span className="subtitle">
          You are donating to “
          {props.toWaqfPool ? "Waqf Pool" : props.project.name}” campaign
        </span>
        <Col className="card-amount" span={24}>
          <span className="card-title">Select Amount</span>
          <Row className="row-amount">{renderCardContent()}</Row>
        </Col>
        <Divider className="divider" plain>
          Or
        </Divider>
        <Col className="card-amount" span={24}>
          <span className="card-title">Custom Amount</span>
          <InputStyled
            type="tex"
            value={amount.value}
            placeholder={"Enter USD"}
            onChange={(e) => {
              setAmount({ ...amount, value: e.target.value });
            }}
            suffix={
              <span>
                {coin.key} {resolveUnitFromUSD(coin.key)}
              </span>
            }
          />
          <RowStart
            onMouseEnter={() => setShowNote(!showNote)}
            onMouseLeave={() => setShowNote(!showNote)}
            style={{ width: "100%" }}
            className="mt-8px align-center"
          >
            {/* <Tooltip
              overlayStyle={{
                minWidth: "30vh",
                display: "flex",
                justifyContent: "center",
              }}
              title="Please note there is a 2% platform fee"
            > */}
            <InfoCircleOutlined />

            <Text
              style={{
                color: showNote ? "inherit" : "transparent",
                transition: "ease-in 0.2s",
              }}
              className="ml-4px text-size-12"
            >
              {/* {showNote ? "Please note there is a 2% platform fee" : " "} */}
              Please note there is a 2% platform fee
            </Text>

            {/* </Tooltip> */}
          </RowStart>
        </Col>
        {project?.chain === "POLYGON" ? (
          <div style={{ width: "100%" }} className="mt-12px">
            {!(
              Number(dataAmountAllowanceTokenPayment) >=
              resolveUnitFromUSD(coin.key)
            ) && (
              <span className="pt-4px pb-4px">
                Look's like your payment method is not yet allowed to buy,{" "}
                <span
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => writeSetAllowanceTokenPayment()}
                >
                  click here
                </span>{" "}
                to allow.
              </span>
            )}
            <ButtonStyled
              disabled={
                !isConnected ||
                !dataAmountAllowanceTokenPayment ||
                !(resolveUnitFromUSD(coin.key) > 0)
              }
              onClick={() => writeDonate()}
              style={{
                width: "98%",
                borderRadius: "10px",
              }}
              className="mb-12px"
              loading={isLoadingDonate}
            >
              Process to Payment
            </ButtonStyled>
          </div>
        ) : (
          <>
            {mode === 1 ? (
              <ButtonStyled
                disabled={amount.value < 1}
                onClick={() => setInputPin(true)}
                style={{
                  width: "98%",
                  borderRadius: "10px",
                }}
                className="mb-12px mt-24px"
              >
                Continue to Payment
              </ButtonStyled>
            ) : mode === 2 ? (
              <ModalCourseStripe
                disabled={amount.value < 1}
                totalPayment={resolveUnitFromUSD(coin.key)}
                project={props.project}
                type={
                  Object.keys(props.project).length > 0 ? "donation" : "waqf"
                }
                onClose={onClose}
              />
            ) : !walletContext.isConnected ? (
              <ButtonStyled
                block
                style={{
                  borderRadius: "10px",
                  marginTop: '12px'
                }}
                className={walletContext.isConnected && "ghost"}
                loading={walletContext.isLoading}
                type="primary"
                size="large"
                onClick={() => walletContext.handdleConnect()}
              >
                <span className="text-1">Connect to Pera</span>
              </ButtonStyled>
            ) : (
              <ModalCourseWallet
                disabled={amount.value < 1}
                type={
                  Object.keys(props.project).length > 0 ? "donation" : "waqf"
                }
                project={props.project}
                totalPayment={resolveUnitFromUSD("IBFX")}
                onClose={onClose}
                behalfDetail={props.behalfDetail}
              />
            )}
          </>
        )}
      </Col>
    );
  };

  const resolveContent = () => {
    if (isProcessing) {
      return renderDonationProcessing();
    } else if (isSuccess) {
      return renderDonationSuccess();
    } else if (isFailed) {
      return renderDonationFailed();
    } else if (isInputPin) {
      return renderInputPin();
    } else {
      return renderDefault();
    }
  };

  const initIbfAssets = async () => {
    if (project?.chain === "POLYGON") {
      setIsVerified(true);
    } else {
      const ibfx1 = allAssets.find(
        (e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_I
      );
      // const ibfx3 = allAssets.find((e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_III)
      // const ibfs = allAssets.find((e) => e.asset_name === ASSETS_NAME.IBFS);

      if (
        !benevolenceUser?.user?.algo_address ||
        Object.keys(walletUser) <= 0 ||
        walletUser.status !== USER_STATUS.ACTIVE ||
        ibfx1.status !== ASSETS_STATUS.ACTIVE ||
        // ibfx3.status !== ASSETS_STATUS.ACTIVE ||
        // ibfs.status !== ASSETS_STATUS.ACTIVE ||
        !localStorage.getItem("ENCRYPTED_MNEMONIC")
      ) {
        setIsVerified(false);
      } else {
        setIsVerified(true);
      }
    }
  };

  const resolveUnitToUSD = (symbol) => {
    switch (symbol) {
      case "IBFX":
        return (Number(amount?.value) / ibfxPerUSD).toFixed(2) || 0;
      case "IBFN":
        return (Number(amount?.value) / 6.5).toFixed(2) || 0;
      default:
        return Number(amount?.value);
    }
  };

  const resolveUnitFromUSD = (symbol) => {
    switch (symbol) {
      case "IBFX":
        return (Number(amount?.value) * ibfxPerUSD).toFixed(2) || 0;
      case "IBFN":
        return (Number(amount?.value) * 6.5).toFixed(2) || 0;
      default:
        return Number(amount?.value);
    }
  };

  useEffect(() => {
    if (project?.chain === "POLYGON" && project?.payment) {
      setCoin(JSON.parse(project?.payment));
    }
    setIsVerified(project?.chain === "POLYGON");
  }, [project]);

  // const [initStatus, setInitStatus] = useState(false);
  useEffect(() => {
    if (allAssets.length > 0) {
      // if (!initStatus) {
      initIbfAssets();
      //   setInitStatus(true);
      // }
    }
  }, [allAssets, benevolenceUser]);

  return !isVerified ? (
    <Modal
      visible={props.isModalVisible}
      centered
      className="modal-top-up"
      footer={null}
      onCancel={onClose}
      closeIcon={<img src={closeIcon} onClick={onClose} />}
      maskStyle={{ backgroundColor: "#f3f6fa" }}
    >
      <BenevolenceOnBoarding onClose={onClose} />
    </Modal>
  ) : windowDimensions.width > 925 ? (
    <Modal
      centered
      visible={props.isModalVisible}
      onCancel={onClose}
      closable={false}
      footer={null}
      closeIcon={null}
      width="650px"
    >
      {resolveContent()}
    </Modal>
  ) : (
    <Drawer
      visible={props.isModalVisible}
      onCancel={onClose}
      placement="bottom"
      closeIcon={
        <img
          style={{ marginRight: 10 }}
          src={closeIcon}
          onClick={onClose}
        />
      }
    >
      {resolveContent()}
    </Drawer>
  );
}
