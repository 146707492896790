import "./assets/scss/index.scss";
import "./assets/scss/index.less";
import "react-coinbase-commerce/dist/coinbase-commerce-button.css";

import { Provider } from "react-redux";
import { Routes } from "./routes";
import { configureStore } from "./store";
import ReactGA from "react-ga";
// import { Auth0Provider } from "./context/auth0-context";
import { Auth0Provider } from "@auth0/auth0-react";
import { PerawalletProvider } from "./context/perawallet-context";

import { createWeb3Modal } from "@web3modal/wagmi";
import { walletConnectProvider, EIP6963Connector } from "@web3modal/wagmi";

import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { polygon, polygonMumbai } from "viem/chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { AUTH0_REDIRECT, DEV } from "./configs";

const projectId = "de248b3163647829414646d37aceddd1"; // Replace with your project ID

const { chains, publicClient } = configureChains(
  [DEV ? polygonMumbai : polygon],
  [walletConnectProvider({ projectId }), publicProvider()]
);

const metadata = {
  name: "IBFNex",
  description: "IBFNex Wallet",
  url: "https://ibfnex.io/",
  icons: [""],
};

const wagmiConfig = createConfig({
  // autoConnect: true,
  connectors: [
    new WalletConnectConnector({
      chains,
      options: { projectId, showQrModal: true, metadata },
    }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: metadata.name },
    }),
  ],
  publicClient,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    "--w3m-accent": "#6a6ee6",
    "--w3m-border-radius-master": "15",
  },
});

// const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  const trackingId = "UA-215530597-1";
  ReactGA.initialize(trackingId);
  return (
    <Provider store={configureStore()}>
      <PerawalletProvider>
        <Auth0Provider
          domain="ibfnet.eu.auth0.com"
          clientId="KnEvdzXjiSh0fucN1ykkwcXn3sqgY7hQ"
          authorizationParams={{
            redirect_uri: AUTH0_REDIRECT,
          }}
        >
          <WagmiConfig config={wagmiConfig}>
            <Routes />
          </WagmiConfig>
        </Auth0Provider>
      </PerawalletProvider>
    </Provider>
  );
}

export default App;
