import { fromJS } from "immutable";
import moment from "moment";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { fromIBFN, fromIBFx } from "../../utils/utils";
import {
  SET_DETAIL_ESSENCE,
  SET_ESSENCE,
  SET_PRICE_HISTORY_ESSENCE,
  SET_ESSENCE_PURCHASES,
  UPDATE_ESSENCE_WISHLIST,
} from "../constants";
import { IS_EVM } from "../../configs";

const initialState = fromJS({
  isLoading: false,
  hasError: false,
  errorMessage: "",
  detailNft: fromJS({}),
  priceHistory: fromJS([]),
  nfts: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    total: 0,
  }),
  purchases: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    total: 0,
  }),
  wishlist: localStorage.getItem(COMMON_CONSTANT.WISHLIST_ESSENCE)
    ? fromJS(JSON.parse(localStorage.getItem(COMMON_CONSTANT.WISHLIST_ESSENCE)))
    : fromJS([]),
});

const updateWishlist = (wishlist, course) => {
  let existingIndex = -1;
  for (let index = 0; index < wishlist.length; index++) {
    if (wishlist[index].course_id === course.course_id) {
      existingIndex = index;
    }
  }

  if (existingIndex >= 0) {
    wishlist.splice(existingIndex, 1);
  } else {
    wishlist.push(course);
  }

  localStorage.setItem(
    COMMON_CONSTANT.WISHLIST_COURSE,
    JSON.stringify(wishlist)
  );

  return wishlist;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ESSENCE:
      if (action.payload.fromMarketplace) {
        const essenceData = state.getIn(["nfts", "data"]).toJS();
        return state.merge({
          nfts: fromJS({
            data:
              action.payload.currentPage === 1
                ? action.payload.data
                : essenceData
                    .concat(action.payload.data)
                    .sort((a, b) => b.created_at - a.created_at),
            hasNext: action.payload.hasNext,
            currentPage: action.payload.currentPage,
            total: action.payload.total,
          }),
        });
      } else {
        return state.merge({
          nfts: fromJS(action.payload),
        });
      }
    case SET_DETAIL_ESSENCE:
      return state.merge({
        detailNft: fromJS(action.payload),
      });
    case SET_PRICE_HISTORY_ESSENCE:
      const data = action.payload.map((item) => ({
        created_at: moment(item.created_at).format("YYYY-MM-DD"),
        total_ibfx: IS_EVM
          ? +fromIBFN(item.total_ibfx)
          : +fromIBFx(item.total_ibfx),
      }));

      return state.merge({
        priceHistory: fromJS(data),
      });
    case SET_ESSENCE_PURCHASES:
      return state.merge({
        purchases: fromJS(action.payload),
      });
    case UPDATE_ESSENCE_WISHLIST:
      return state.merge({
        wishlist: fromJS(
          updateWishlist(state.getIn(["wishlist"]).toJS(), action.payload)
        ),
      });
    default:
      return state;
  }
}
