import { Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSection } from "../../modules/general/action";
import useWindowDimensions from "../../utils/windowDimensions";
import {
  ColEnd,
  ColStart,
  RowCenter,
  RowSpaceBetween,
} from "../general_styled";
import { CardStyled, CollectionNameStyled, NFTNameStyled } from "./styled";
import ibfxIcon from "../../assets/images/affluence-shopee-ibfx.svg";
import { fromIBFx } from "../../utils/utils";
import { CREDENCE_HOST } from "../../configs";
import { setNftDetail } from "../../modules/credence/action";
import ModalCredenceRemove from "../ModalCredenceRemove";

export default function CardNFT(props) {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const isAffluence = props?.isAffluence ? true : false;
  const isVault = props?.isVault ? true : false;
  const toSub = props?.toSub ? true : false;

  const handleClick = () => {
    if (props.setSelectedNFT) {
      props.setSelectedNFT(props.nftId);
    } else {
      if (props.type === "SELL_ESCROW") {
        dispatch(
          setSection("affluence-buy-sell-detail-nft", props.sellEscrowId)
        );
      } else if (props.type === "AUCTION") {
        dispatch(setSection("affluence-auction-detail-nft", props.auctionId));
      } else if (toSub) {
        dispatch(
          setSection("credence-nft-sub-detail", `${props.data?.sub_id}`)
        );
        dispatch(setNftDetail(props.data));
      } else {
        dispatch(setSection("credence-nft-detail", `${props.nftId}`));
        dispatch(setNftDetail(props.data));
      }
    }
  };

  return (
    <Col
      key={props.key}
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={windowDimensions.width < 1441 ? 8 : 6}
      xxl={6}
    >
      <>
        <CardStyled
          className={props.selectedNFT === props.nftId ? "selected" : ""}
          cover={
            props.data?.cover_file ? (
              <img
                onClick={handleClick}
                alt={props.name}
                src={CREDENCE_HOST + "/files/" + props.data?.cover_file}
              />
            ) : (
              <img
                onClick={handleClick}
                alt={props.name}
                src={`https://ipfs.io/ipfs/${props.ipfsHash}`}
              />
            )
          }
        >
          <RowSpaceBetween onClick={handleClick}>
            <ColStart>
              <CollectionNameStyled
                className={!props.collectionName && "no-collection"}
              >
                {props.collectionName || t("credence-card-nft-no-collection")}
              </CollectionNameStyled>
              <NFTNameStyled>{props.name}</NFTNameStyled>
            </ColStart>
            {isAffluence && (
              <ColEnd>
                <CollectionNameStyled>
                  {t("credence-card-nft-list-price")}
                </CollectionNameStyled>
                <RowSpaceBetween>
                  <img alt="ibfxIcon" src={ibfxIcon} />
                  <NFTNameStyled>
                    {fromIBFx(props.ibfxAmount) || 0}
                  </NFTNameStyled>
                </RowSpaceBetween>
              </ColEnd>
            )}
          </RowSpaceBetween>
          {isVault && (
            <RowCenter>
              <ModalCredenceRemove nft={props.data} />
            </RowCenter>
          )}
        </CardStyled>
      </>
    </Col>
  );
}
