export const NFT_STATUS = {
  INIT: "INIT",
  APPROVED: "APPROVED",
  CREATING: "CREATING",
  CREATED: "CREATED",
  FAILED: "FAILED",
};

export const TRANSACTION_STATUS = {
  INIT: "INIT",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export const AUCTION_STATUS = {
  INIT: "INIT",
  PENDING: "PENDING",
  CREATED: "CREATED",
  INIT_ESCROW: "INIT_ESCROW",
  PENDING_ESCROW: "PENDING_ESCROW",
  CREATED_WITH_ESCROW: "CREATED_WITH_ESCROW",
  OPENING: "OPENING",
  PENDING_OPENING: "PENDING_OPENING",
  OPENED: "OPENED",
  CLOSED: "CLOSED",
  PENDING_CLOSED: "PENDING_CLOSED",
  FAILED: "FAILED",
};

export const EVM_TRANSACTION_STATUS = {
  ON_SALE: "ON_SALE",
  CANCEL: "CANCEL",
  SOLD: "SOLD",
};
