import axios from "axios";
import decode from "jwt-decode";

import { message } from "antd";

import {
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_FORGOT_PASSWORD,
  SET_REGISTER,
  SET_USER_DATA,
} from "../constants";
import { headers, membershipHeaders, MEMBERSHIP_HOST } from "../../configs";
import { getWalletUser } from "../wallet/action";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import { getUserDataById } from "../benevolence/action";
// import { getConfluenceUser } from '../confluence/action'

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });

  message.success("You have successfully logged out");

  localStorage.removeItem("USER_AUTH_TOKEN");
};

export const recheckToken = () => async (dispatch) => {
  const token = localStorage.getItem("USER_AUTH_TOKEN") || null;
  if (token !== null) {
    const decoded = decode(token);

    if (Date.now() > decoded.exp * 1000) {
      logout();
      return false;
    } else {
      const user = (
        await axios.get(`${MEMBERSHIP_HOST}/users`, {
          headers: {
            ...membershipHeaders,
            Authorization: `Bearer ${token}`,
          },
        })
      ).data;

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: token,
          userData: user.data,
        },
      });
      dispatch(getWalletUserData(user.data.user_id));
      // dispatch(getConfluenceUser(user.data.user_id))
      return true;
    }
  }
};

export const forgotPassword = (body) => async (dispatch) => {
  try {
    const result = (
      await axios.post(
        `${MEMBERSHIP_HOST}/users/request_recover_password	`,
        {
          email: body.email,
        },
        { headers: membershipHeaders }
      )
    ).data;

    if (result.code === "SUCCESS") {
      dispatch({
        type: SET_FORGOT_PASSWORD,
        payload: {
          data: { ...body, id: result.data.id },
        },
      });

      return result;
    }
  } catch (e) {
    console.log(e);

    throw e;
  }
};

export const updatePassword =
  ({ password, jwtToken }) =>
  async (dispatch) => {
    try {
      const jwtTokenLocalStorage = localStorage.getItem("USER_AUTH_TOKEN");

      const result = (
        await axios.put(
          `${MEMBERSHIP_HOST}/users/change_password`,
          {
            password,
          },
          {
            headers: {
              authorization: `Bearer ${jwtTokenLocalStorage || jwtToken}`,
            },
          }
        )
      ).data;

      if (result.code === "SUCCESS") {
        dispatch({
          type: SET_FORGOT_PASSWORD,
          payload: {
            data: {},
          },
        });
        return result;
      }
    } catch (e) {
      console.log(e);

      throw e;
    }
  };

export const confirmForgotPassword =
  ({ body, otp }) =>
  async (dispatch) => {
    try {
      const result = (
        await axios.post(
          `${MEMBERSHIP_HOST}/users/confirm_recover_password`,
          {
            id: body.id,
            otp,
          },
          { headers: membershipHeaders }
        )
      ).data;

      if (result.code === "SUCCESS") {
        dispatch({
          type: SET_FORGOT_PASSWORD,
          payload: {
            data: { ...body, token: result.data.token },
          },
        });
        return result;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

export const sendVerification =
  ({ mode = "email", jwtToken }) =>
  async (_) => {
    try {
      console.log("send verification");
      const result = (
        await axios.post(
          `${MEMBERSHIP_HOST}/users/send_verification/${
            mode === "email" ? "EMAIL" : "PHONE"
          }`,
          {},
          { headers: { authorization: `Bearer ${jwtToken}` } }
        )
      ).data;

      if (result.code === "SUCCESS") {
        return result;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

export const confirmVerification =
  ({ mode = "email", otp, jwtToken }) =>
  async (dispatch) => {
    try {
      const result = (
        await axios.post(
          `${MEMBERSHIP_HOST}/users/confirm_verification/${
            mode === "email" ? "EMAIL" : "PHONE"
          }`,
          { otp },
          { headers: { authorization: `Bearer ${jwtToken}` } }
        )
      ).data;

      if (result.code === "SUCCESS") {
        localStorage.setItem("USER_AUTH_TOKEN", jwtToken);
        const user = (
          await axios.get(`${MEMBERSHIP_HOST}/users`, {
            headers: {
              ...headers,
              Authorization: `Bearer ${jwtToken}`,
            },
          })
        ).data;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            token: jwtToken,
            userData: user.data,
          },
        });
        dispatch(getWalletUserData(user.data.user_id));
        // dispatch(getConfluenceUser(user.data.user_id))
        dispatch({
          type: SET_REGISTER,
          payload: {
            data: {},
          },
        });

        return result;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

export const userRegister = (data) => async (dispatch) => {
  try {
    const result = (
      await axios.post(`${MEMBERSHIP_HOST}/users`, data, {
        headers: membershipHeaders,
      })
    ).data;

    if (result.code === "SUCCESS") {
      const resultLogin = (
        await axios.post(
          `${MEMBERSHIP_HOST}/users/login`,
          {
            email: data.email,
            password: data.password,
          },
          {
            headers: membershipHeaders,
          }
        )
      ).data;
      if (resultLogin.code === "SUCCESS") {
        dispatch({
          type: SET_REGISTER,
          payload: {
            data: { ...data, token: resultLogin.data.token },
          },
        });
        await dispatch(
          sendVerification({
            mode: "email",
            jwtToken: resultLogin.data.token,
          })
        );

        return result;
      }
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const userLogin =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({
      type: LOGIN,
    });

    try {
      const response = await axios.post(
        `${MEMBERSHIP_HOST}/users/login	`,
        {
          email,
          password,
        },
        {
          headers: membershipHeaders,
        }
      );

      const result = response.data;
      localStorage.setItem("USER_AUTH_TOKEN", result.data.token);
      const user = (
        await axios.get(`${MEMBERSHIP_HOST}/users`, {
          headers: {
            ...headers,
            Authorization: `Bearer ${result.data.token}`,
          },
        })
      ).data;

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: result.data.token,
          userData: user.data,
        },
      });
      dispatch(getWalletUserData(user.data.user_id));
      // dispatch(getConfluenceUser(user.data.user_id))
      dispatch(getUserDataById(user.data.user_id));
      return result;
    } catch (e) {
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          message: extractErrorMessage(e),
        },
      });

      throw e;
    }
  };

export const userLoginWithJwt =
  ({ jwt }) =>
  async (dispatch) => {
    dispatch({
      type: LOGIN,
    });

    try {
      localStorage.setItem("USER_AUTH_TOKEN", jwt);
      const user = (
        await axios.get(`${MEMBERSHIP_HOST}/users`, {
          headers: {
            ...headers,
            Authorization: `Bearer ${jwt}`,
          },
        })
      ).data;

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: jwt,
          userData: user.data,
        },
      });
      dispatch(getWalletUserData(user.data.user_id));
      // dispatch(getConfluenceUser(user.data.user_id))
      dispatch(getUserDataById(user.data.user_id));
    } catch (e) {
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          message: extractErrorMessage(e),
        },
      });

      throw e;
    }
  };

export const userLoginWithAuth0 = (req) => async (dispatch) => {
  dispatch({
    type: LOGIN,
  });
  try {
    const response = await axios.post(
      `${MEMBERSHIP_HOST}/users/login-auth0	`,
      req,
      {
        headers: membershipHeaders,
      }
    );
    const result = response.data;
    localStorage.setItem("USER_AUTH_TOKEN", result.data.token);
    const user = (
      await axios.get(`${MEMBERSHIP_HOST}/users`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${result.data.token}`,
        },
      })
    ).data;

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        token: result.data.token,
        userData: user.data,
      },
    });
    dispatch(getWalletUserData(user.data.user_id));
    // dispatch(getConfluenceUser(user.data.user_id))
    dispatch(getUserDataById(user.data.user_id));
    return result;
  } catch (e) {
    dispatch({
      type: LOGIN_FAIL,
      payload: {
        message: extractErrorMessage(e),
      },
    });

    throw e;
  }
};

const getWalletUserData = (userId) => async (dispatch) => {
  try {
    await dispatch(getWalletUser(userId));
  } catch (e) {
    console.log(e);
  }
};

export const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const response = (
      await axios.post(`${MEMBERSHIP_HOST}/files`, formData, {
        headers: membershipHeaders,
      })
    ).data;
    return response.data;
  } catch (e) {
    throw e.response ? (e.response.data ? e.response.data : e.response) : e;
  }
};

export const updateUser = async ({
  photo,
  name,
  address,
  city,
  country,
  referral_email,
}) => {
  try {
    const jwtTokenLocalStorage = localStorage.getItem("USER_AUTH_TOKEN");

    const result = (
      await axios.put(
        `${MEMBERSHIP_HOST}/users`,
        {
          photo,
          name,
          address,
          city,
          country,
          referral_email,
        },
        {
          headers: {
            authorization: `Bearer ${jwtTokenLocalStorage}`,
          },
        }
      )
    ).data;

    if (result.code === "SUCCESS") {
      return result;
    }
  } catch (e) {
    console.log(e);

    throw e;
  }
};

export const getUserDetail = () => async (dispatch) => {
  try {
    const jwtTokenLocalStorage = localStorage.getItem("USER_AUTH_TOKEN");

    const user = (
      await axios.get(`${MEMBERSHIP_HOST}/users`, {
        headers: {
          authorization: `Bearer ${jwtTokenLocalStorage}`,
        },
      })
    ).data;

    dispatch({
      type: SET_USER_DATA,
      payload: {
        userData: user.data,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUserDetailById = async (userId) => {
  try {
    const user = (
      await axios.get(`${MEMBERSHIP_HOST}/users/${userId}`, {
        headers: membershipHeaders,
      })
    ).data;

    return user.data;
  } catch (e) {
    console.log(e);
  }
};

export const getUserDetailByAddress = async (address) => {
  try {
    const user = (
      await axios.get(`${MEMBERSHIP_HOST}/polygon/user/${address}`, {
        headers: membershipHeaders,
      })
    ).data;

    return user.data;
  } catch (e) {
    console.log(e);
  }
};

export const setRegisterData = (email, password, name, token) => (dispatch) => {
  dispatch({
    type: SET_REGISTER,
    payload: {
      data: { email, password, name, token },
    },
  });
};

export const getNonce = async (walletAddress) => {
  const jwtTokenLocalStorage = localStorage.getItem("USER_AUTH_TOKEN");
  const data = await axios.post(
    `${MEMBERSHIP_HOST}/polygon/nonce`,
    { wallet_address: walletAddress },
    {
      headers: {
        authorization: `Bearer ${jwtTokenLocalStorage}`,
      },
    }
  );
  return data.data?.data?.nonce;
};

export const verifyNonce = async (body) => {
  const jwtTokenLocalStorage = localStorage.getItem("USER_AUTH_TOKEN");
  const data = await axios.post(
    `${MEMBERSHIP_HOST}/polygon/nonce/verify`,
    body,
    {
      headers: {
        authorization: `Bearer ${jwtTokenLocalStorage}`,
      },
    }
  );
  return data.data?.data?.isValid;
};
