import { Button, Col, Image, Row, Typography, Divider, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateWishlist } from "../../modules/course_wishlist/action";
import {
  CartItem,
  CartSubTitle,
  CartTitle,
  CourseDetails,
  DrawerStyled,
  LabelMedium,
  LabelNormal,
  PaymentCard,
  PaymentList,
  PaymentSummary,
  Divider as DividerCard,
  AlertDiscount,
  TitleMiniCard,
  CanvasCart,
} from "./styled";
import {
  HeartOutlined,
  HeartFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { MEMBERSHIP_HOST } from "../../configs";
import {
  ButtonOutline,
  ButtonPeraConnect,
  ColSpaceBetween,
  ColStart,
  RowEnd,
  RowSpaceBetween,
  RowStart,
} from "../general_styled";
import { PriceText, TimeText } from "../../pages/Courses/styled";
import IBFxIcon from "../../assets/images/ibfx-icon.svg";
import MasterCardIcon from "../../assets/images/mc-icon.svg";
import VisaIcon from "../../assets/images/visa-icon.svg";
import AlgoLogo from "../../assets/images/algo_logo.svg";
import IBFx1Logo from "../../assets/images/ibfx-1.svg";
// import IBFx2Logo from '../../assets/images/ibfx-2.svg'
import ArrowLeft from "../../assets/images/arrowleft.svg";
import TrashIcon from "../../assets/images/trash.svg";
import peraIcon from "../../assets/images/pera.png";
import { closeCart, removeCart } from "../../modules/course_cart/action";
import { useContext, useState } from "react";
import { ASSETS_NAME } from "../../constants/constant_wallet";
import CoursePayment from "./CoursePayment";
import ModalCourseStripe from "../ModalCourseStripe";
import { selectCourse } from "../../modules/courses/action";
import { setSection } from "../../modules/general/action";
import useWindowDimensions from "../../utils/windowDimensions";
import { useEffect } from "react";
import { formatterCurrency, fromIBFx } from "../../utils/utils";
import { PerawalletContext } from "../../context/perawallet-context";
import ModalCourseWallet from "../ModalCourseWallet";

const { Panel } = Collapse;

const { Title, Text } = Typography;

export default function CourseCart() {
  const { t } = useTranslation();

  const walletContext = useContext(PerawalletContext);

  const windowDimensions = useWindowDimensions();
  const isDrawerOpen = useSelector((state) =>
    state.getIn(["courseCart", "isDrawerOpen"])
  );
  const cart = useSelector((state) =>
    state.getIn(["courseCart", "cart"]).toJS()
  );
  const discount = useSelector((state) =>
    state.getIn(["courseCart", "discount"])
  );
  const wishlist = useSelector((state) =>
    state.getIn(["courseWishlist", "wishlist"]).toJS()
  );

  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );
  const algoPerUSD = useSelector((state) =>
    state.getIn(["wallet", "algoPerUSD"])
  );

  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ibfx1Amount, setIbfx1Amount] = useState(0);
  // const [ibfx2Amount, setIbfx2Amount] = useState(0)
  const [activePanel, setActivePanel] = useState("2");

  const isExistOnWishlist = (courseId) => {
    const exist = wishlist.find((w) => w.course_id === courseId);
    return exist ? true : false;
  };

  const calculateTotal = (type) => {
    let amount = 0;
    for (var course of cart) {
      amount += course.price;
    }

    if (type === "index") {
      return amount;
    } else {
      return amount - ibfx1Amount;
    }
  };

  const calculateAlgoNeed = () => {
    const totalAmount = calculateTotal("index");
    return (totalAmount / ibfxPerUSD).toFixed(2) * algoPerUSD;
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const paymentCardBody = (type, modePayment) => {
    return (
      <Col>
        {type === "pay" ? (
          <>
            <PaymentList>
              <LabelNormal>{t("total_items")}</LabelNormal>
              <LabelMedium>{cart.length}</LabelMedium>
            </PaymentList>
            <PaymentList>
              <LabelNormal>{t("total_cost")}</LabelNormal>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                <Image preview={false} src={IBFxIcon} width={"18px"} />
                <LabelMedium
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  {calculateTotal("index")}
                </LabelMedium>
              </Row>
            </PaymentList>
            <PaymentList>
              <LabelNormal>{t("discount")}</LabelNormal>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                <Image preview={false} src={IBFxIcon} width={"18px"} />
                <LabelMedium
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  {discount}
                </LabelMedium>
              </Row>
            </PaymentList>
            <DividerCard />
            <PaymentList>
              <LabelNormal>IBFX</LabelNormal>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                <Image preview={false} src={IBFx1Logo} width={"18px"} />
                <LabelMedium
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  {ibfx1Amount}
                </LabelMedium>
              </Row>
            </PaymentList>
            {/* <PaymentList>
							<LabelNormal>IBFX - 2</LabelNormal>
							<Row
								wrap={false}
								style={{
									alignItems: 'center',
								}}
							>
								<Image preview={false} src={IBFx2Logo} width={'18px'} />
								<LabelMedium
									style={{
										marginLeft: '4px',
									}}
								>
									{ibfx2Amount}
								</LabelMedium>
							</Row>
						</PaymentList> */}
            <DividerCard />
            <PaymentSummary>
              <LabelMedium>{t("total")}</LabelMedium>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                <Image preview={false} src={IBFxIcon} width={"18px"} />

                <LabelMedium
                  style={
                    calculateTotal() < 0
                      ? {
                          marginLeft: "4px",
                          color: "red",
                        }
                      : calculateTotal() === 0
                      ? {
                          marginLeft: "4px",
                          color: "green",
                        }
                      : {
                          marginLeft: "4px",
                        }
                  }
                >
                  {calculateTotal() - discount}
                </LabelMedium>
              </Row>
            </PaymentSummary>
          </>
        ) : type === "wallet" ? (
          <>
            <RowSpaceBetween>
              <TitleMiniCard>Pay with ALGO</TitleMiniCard>
              <RowEnd>
                <Image preview={false} src={AlgoLogo} width={"28px"} />
              </RowEnd>
            </RowSpaceBetween>
            <DividerCard />
            {/* <PaymentList>
              <LabelNormal>Total Items</LabelNormal>
              <LabelMedium>{cart.length}</LabelMedium>
            </PaymentList> */}
            <PaymentList>
              <LabelNormal>{t("total_cost")}</LabelNormal>
              <Row wrap={false} style={{ alignItems: "center" }}>
                <LabelMedium className="ml-4px">
                  {!modePayment && (
                    <Image
                      preview={false}
                      src={IBFxIcon}
                      className="pb-3px"
                      width={"18px"}
                    />
                  )}
                  {!modePayment && (
                    <span className="usd">{calculateTotal("index") + " "}</span>
                  )}
                  <span>
                    {!modePayment && " ≈ "}
                    {calculateAlgoNeed()}
                    <Image
                      preview={false}
                      src={AlgoLogo}
                      width={"20px"}
                      className="pb-2px"
                    />
                  </span>
                </LabelMedium>
              </Row>
            </PaymentList>
            <PaymentList>
              <LabelNormal>{t("discount")}</LabelNormal>
              <Row wrap={false} style={{ alignItems: "center" }}>
                <LabelMedium style={{ marginLeft: "4px" }}>
                  <span> </span>
                  <span className="usd">
                    0
                    <Image
                      preview={false}
                      src={AlgoLogo}
                      width={"20px"}
                      className="pb-2px"
                    />
                  </span>
                </LabelMedium>
              </Row>
            </PaymentList>
            <AlertDiscount className={"grey"}>
              {t("additional_discount")}
            </AlertDiscount>
            <DividerCard />
            <PaymentSummary>
              <LabelMedium>{t("total_due")}</LabelMedium>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                <LabelMedium className="ml-4px">
                  {!modePayment && (
                    <Image
                      preview={false}
                      src={IBFxIcon}
                      className="pb-3px"
                      width={"18px"}
                    />
                  )}
                  {!modePayment && (
                    <span className="usd">{calculateTotal("index") + " "}</span>
                  )}
                  <span>
                    {!modePayment && " ≈ "}
                    {calculateAlgoNeed()}
                    <Image
                      preview={false}
                      src={AlgoLogo}
                      width={"20px"}
                      className="pb-2px"
                    />
                  </span>
                </LabelMedium>
              </Row>
            </PaymentSummary>
            <DividerCard />
          </>
        ) : (
          <>
            <RowSpaceBetween>
              <TitleMiniCard>
                {!modePayment ? t("pay_with_ibfx") : t("pay_with_dollar")}
              </TitleMiniCard>
              {modePayment ? (
                <RowEnd>
                  <Image preview={false} src={VisaIcon} width={"28px"} />
                  <Image
                    className="ml-4px"
                    preview={false}
                    src={MasterCardIcon}
                    width={"28px"}
                  />
                </RowEnd>
              ) : (
                <RowEnd>
                  <Image preview={false} src={IBFx1Logo} width={"20px"} />
                  {/* <Image className="ml-4px" preview={false} src={IBFx2Logo} width={'20px'} /> */}
                </RowEnd>
              )}
            </RowSpaceBetween>
            <DividerCard />
            {/* <PaymentList>
              <LabelNormal>Total Items</LabelNormal>
              <LabelMedium>{cart.length}</LabelMedium>
            </PaymentList> */}
            <PaymentList>
              <LabelNormal>{t("total_cost")}</LabelNormal>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                {!modePayment && (
                  <Image preview={false} src={IBFxIcon} width={"18px"} />
                )}
                <LabelMedium className="ml-4px">
                  {!modePayment && <span>{calculateTotal("index") + " "}</span>}
                  <span className="usd">
                    {!modePayment && "≈ "}$
                    {(calculateTotal("index") / ibfxPerUSD).toFixed(2)}
                  </span>
                </LabelMedium>
              </Row>
            </PaymentList>
            <PaymentList>
              <LabelNormal>{t("discount")}</LabelNormal>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                {!modePayment && (
                  <Image preview={false} src={IBFxIcon} width={"18px"} />
                )}
                <LabelMedium
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  {modePayment === "stripe" ? (
                    <span> </span>
                  ) : (
                    <span>{discount + " "}</span>
                  )}
                  <span className="usd">
                    {!modePayment && "≈ "}$
                    {!modePayment ? (discount / ibfxPerUSD).toFixed(2) : 0}
                  </span>
                </LabelMedium>
              </Row>
            </PaymentList>
            {/* <AlertDiscount className={modePayment ? 'grey' : 'green'}>
							{modePayment ? t('additional_discount') : `${t('discount_of')} ${discount} ${t('ibfx_applied')}`}
						</AlertDiscount> */}
            <DividerCard />
            <PaymentSummary>
              <LabelMedium>{t("total_due")}</LabelMedium>
              <Row
                wrap={false}
                style={{
                  alignItems: "center",
                }}
              >
                {!modePayment && (
                  <Image preview={false} src={IBFxIcon} width={"18px"} />
                )}

                <LabelMedium className="ml-4px">
                  {modePayment ? (
                    <span></span>
                  ) : (
                    <span>{calculateTotal("index") - discount + " "}</span>
                  )}
                  <span className={"usd " + (modePayment && "black")}>
                    {!modePayment && "≈ "}$
                    {modePayment
                      ? (calculateTotal("index") / ibfxPerUSD).toFixed(2)
                      : (
                          (calculateTotal("index") - discount) /
                          ibfxPerUSD
                        ).toFixed(2)}
                  </span>
                </LabelMedium>
              </Row>
            </PaymentSummary>
            <DividerCard />
          </>
        )}
      </Col>
    );
  };

  return (
    <>
      <DrawerStyled
        placement="right"
        visible={isDrawerOpen}
        width={"100%"}
        closable={false}
        getContainer={false}
      >
        <CanvasCart>
          {/* <CloseCircleOutlined
            className="close-btn"
            style={{ fontSize: "28px" }}
            onClick={() => dispatch(closeCart())}
          /> */}
          <Row gutter={[40, 40]}>
            <Col
              xs={24}
              sm={24}
              md={windowDimensions.width > 925 ? 12 : 24}
              lg={12}
              xl={16}
              xxl={18}
            >
              <div className="cart-items">
                <CartTitle>{t("cart")}</CartTitle>
                <CartSubTitle>{t("add_or_remove_items")}</CartSubTitle>
                {cart.map((course, key) => {
                  return windowDimensions.width > 834 ? (
                    <CartItem key={key}>
                      <Image
                        src={`${MEMBERSHIP_HOST}/files/${course.image}`}
                        preview={false}
                        height={72}
                        width={72}
                        className="pointer"
                        onClick={() => {
                          dispatch(selectCourse(course));
                          dispatch(
                            setSection("course-detail", JSON.stringify(course))
                          );
                        }}
                      />
                      <CourseDetails>
                        <RowSpaceBetween
                          style={{
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Title
                            level={4}
                            className="pointer text-w500"
                            onClick={() => {
                              dispatch(selectCourse(course));
                              dispatch(
                                setSection(
                                  "course-detail",
                                  JSON.stringify(course)
                                )
                              );
                            }}
                          >
                            {course.title}
                          </Title>
                          <Row
                            style={{
                              alignItems: "center",
                              flexWrap: "nowrap",
                              marginLeft: "20px",
                            }}
                          >
                            <Image preview={false} src={IBFxIcon} width={20} />
                            <TimeText style={{ marginLeft: "4px" }}>
                              {formatterCurrency.format(course.price)}
                            </TimeText>
                          </Row>
                        </RowSpaceBetween>
                        <RowSpaceBetween className="w-100">
                          <Row
                            style={{
                              flexWrap: "nowrap",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            <Row
                              style={{
                                alignItems: "center",
                                fontStyle: "normal",
                                fontSize: "15px",
                                lineHeight: "20px",
                                flexWrap: "nowrap",

                                color: "#526895",
                              }}
                            >
                              {isExistOnWishlist(course.course_id) ? (
                                <div
                                  onClick={() =>
                                    dispatch(updateWishlist(course))
                                  }
                                >
                                  <HeartFilled
                                    style={{
                                      cursor: "pointer",
                                      color: "#FF4D4F",
                                    }}
                                  />
                                  <Text
                                    style={{
                                      marginLeft: "4px",
                                      fontWeight: "600",
                                      fontSize: "13px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {t("remove_wishlist")}
                                  </Text>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    dispatch(removeCart(course));
                                    dispatch(updateWishlist(course));
                                  }}
                                >
                                  <HeartOutlined
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                  <Text
                                    style={{
                                      marginLeft: "4px",
                                      fontWeight: "600",
                                      fontSize: "13px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {t("remove_wishlist")}
                                  </Text>
                                </div>
                              )}
                            </Row>
                            <Row
                              style={{
                                alignItems: "center",
                                fontStyle: "normal",
                                fontSize: "15px",
                                lineHeight: "20px",
                                marginLeft: "12px",
                                flexWrap: "nowrap",
                                color: "#526895",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                dispatch(removeCart(course));
                              }}
                            >
                              <Image
                                src={TrashIcon}
                                preview={false}
                                style={{
                                  width: "16px",
                                  cursor: "pointer",
                                }}
                              />
                              <Text
                                style={{
                                  marginLeft: "4px",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {t("remove_cart")}
                              </Text>
                            </Row>
                          </Row>
                          <PriceText style={{ marginLeft: "4px" }}>
                            {"≈$" +
                              formatterCurrency.format(
                                (course.price / ibfxPerUSD).toFixed(2)
                              )}
                          </PriceText>
                        </RowSpaceBetween>
                      </CourseDetails>
                    </CartItem>
                  ) : (
                    <CartItem key={key}>
                      <ColStart className="w-100">
                        <RowSpaceBetween className="w-100">
                          <RowStart>
                            <Image
                              src={`${MEMBERSHIP_HOST}/files/${course.image}`}
                              preview={false}
                              height={48}
                              width={48}
                              style={{ borderRadius: 4 }}
                              className="pointer"
                              onClick={() => {
                                dispatch(selectCourse(course));
                                dispatch(
                                  setSection("course-detail", course.course_id)
                                );
                              }}
                            />
                            <Text
                              className="pointer text-size-18 text-w500 ml-12px mb-0px"
                              style={{ lineHeight: "22px" }}
                              align="left"
                              onClick={() => {
                                dispatch(selectCourse(course));
                                dispatch(
                                  setSection("course-detail", course.course_id)
                                );
                              }}
                            >
                              {course.title}
                            </Text>
                          </RowStart>

                          <ColStart className="end">
                            <RowEnd>
                              <Image
                                preview={false}
                                src={IBFxIcon}
                                width={20}
                              />
                              <TimeText style={{ marginLeft: "4px" }}>
                                {course.price}
                              </TimeText>
                            </RowEnd>
                            <PriceText style={{ marginLeft: "4px" }}>
                              ≈${(course.price / ibfxPerUSD).toFixed(2)}
                            </PriceText>
                          </ColStart>
                        </RowSpaceBetween>
                        <RowSpaceBetween className="mt-16px space-evently">
                          {isExistOnWishlist(course.course_id) ? (
                            <div
                              onClick={() => dispatch(updateWishlist(course))}
                            >
                              <HeartFilled
                                style={{
                                  cursor: "pointer",
                                  color: "#FF4D4F",
                                  margin: "2px 0",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <Text
                                style={{
                                  marginLeft: "4px",
                                  cursor: "pointer",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  lineHeight: "20px",
                                  margin: "2px 0",
                                }}
                              >
                                {t("remove_wishlist")}
                              </Text>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                dispatch(removeCart(course));
                                dispatch(updateWishlist(course));
                              }}
                            >
                              <HeartOutlined
                                style={{
                                  cursor: "pointer",
                                  margin: "2px 0",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                              <Text
                                style={{
                                  cursor: "pointer",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  lineHeight: "20px",
                                  margin: "2px 0",
                                  marginLeft: "4px",
                                }}
                              >
                                {t("move_wishlist")}
                              </Text>
                            </div>
                          )}
                          <div
                            style={{
                              fontStyle: "normal",
                              fontSize: "15px",
                              lineHeight: "20px",
                              flexWrap: "nowrap",
                              color: "#526895",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              dispatch(removeCart(course));
                            }}
                          >
                            <Image
                              src={TrashIcon}
                              preview={false}
                              style={{
                                width: "16px",
                                height: "16px",
                                cursor: "pointer",
                                margin: "2px 0",
                              }}
                            />
                            <Text
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "15px",
                                lineHeight: "20px",
                                marginTop: "4px",
                                marginLeft: "4px",
                              }}
                            >
                              {t("remove_cart")}
                            </Text>
                          </div>
                        </RowSpaceBetween>
                      </ColStart>
                    </CartItem>
                  );
                })}
                {windowDimensions.width > 834 && (
                  <ButtonOutline
                    style={{
                      borderColor: "#3B4552",
                      height: 40,
                      color: "#3B4552",
                      borderRadius: 4,
                    }}
                    className="back-button"
                    onClick={() => dispatch(closeCart())}
                  >
                    <img src={ArrowLeft} className="mr-4px" />
                    {t("go_back")} Courses
                  </ButtonOutline>
                )}
              </div>
            </Col>
            {windowDimensions.width > 834 && (
              <Col
                xs={24}
                sm={24}
                md={windowDimensions.width > 925 ? 12 : 24}
                lg={12}
                xl={8}
                xxl={6}
              >
                <ColSpaceBetween className="stretch">
                  <PaymentCard>
                    {walletContext.isConnected
                      ? paymentCardBody("wallet")
                      : paymentCardBody("index", "stripe")}
                    {walletContext.isConnected ? (
                      <RowSpaceBetween className="mt-16px mx-20px">
                        <ModalCourseWallet
                          disabled={calculateTotal() <= 0}
                          totalPayment={calculateTotal()}
                          setIsModalVisible={setIsModalVisible}
                        />
                      </RowSpaceBetween>
                    ) : (
                      <RowSpaceBetween className="mt-16px mx-20px">
                        <ModalCourseStripe
                          disabled={calculateTotal() <= 0}
                          totalPayment={calculateTotal()}
                          setIsModalVisible={setIsModalVisible}
                        />
                      </RowSpaceBetween>
                    )}
                    <RowSpaceBetween className="mx-20px">
                      <ButtonPeraConnect
                        block
                        disabled={true}
                        className={walletContext.isConnected && "ghost"}
                        loading={walletContext.isFetching}
                        type="primary"
                        size="large"
                        onClick={() => {
                          if (walletContext.isConnected) {
                            walletContext.killSession();
                          } else {
                            // walletContext.walletInit();
                          }
                        }}
                      >
                        <span className="text">
                          {walletContext.isConnected
                            ? "Disconnect Wallet & Pay With Stripe"
                            : "Connect to Pera"}
                          {/* {!walletContext.isConnected && (
                            <img
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                height: 30,
                                paddingBottom: 3,
                              }}
                              src={peraIcon}
                            />
                          )} */}
                        </span>
                      </ButtonPeraConnect>
                    </RowSpaceBetween>
                  </PaymentCard>
                  <Divider className="my-36px" plain>
                    {t("or")}
                  </Divider>
                  <PaymentCard>
                    {paymentCardBody("index")}
                    <RowSpaceBetween className="mt-16px mx-20px">
                      {/* <ModalCourseStripe /> */}
                      <Button
                        type="primary"
                        disabled={calculateTotal() <= 0}
                        block
                        style={{
                          minHeight: "40px",
                          borderRadius: 4,
                        }}
                        onClick={() => openModal()}
                      >
                        {t("pay_with_ibfx_s")}
                      </Button>
                    </RowSpaceBetween>
                  </PaymentCard>
                </ColSpaceBetween>
              </Col>
            )}
          </Row>
        </CanvasCart>
        {windowDimensions.width < 834 && (
          <div className="bottom-bar">
            <Collapse
              defaultActiveKey={["2"]}
              activeKey={activePanel}
              onChange={(key) => {
                setActivePanel(key);
              }}
            >
              <Panel
                header={
                  <RowSpaceBetween className="w-100">
                    <div>{t("pay_in_dollars")}</div>
                    <LabelMedium
                      style={
                        calculateTotal() < 0
                          ? {
                              marginLeft: "4px",
                              color: "red",
                            }
                          : calculateTotal() === 0
                          ? {
                              marginLeft: "4px",
                              color: "green",
                            }
                          : {
                              marginLeft: "4px",
                            }
                      }
                    >
                      ${calculateTotal() / ibfxPerUSD}
                    </LabelMedium>
                  </RowSpaceBetween>
                }
                key="1"
              >
                {paymentCardBody("index", "stripe")}
              </Panel>
              <Panel
                header={
                  <RowSpaceBetween className="w-100">
                    <div>{t("pay_in_ibfx")}</div>
                    <LabelMedium className="ml-4px">
                      <span>{calculateTotal("index") - discount + " "}</span>
                      <span className={"usd "}>
                        ≈ $
                        {(
                          (calculateTotal("index") - discount) /
                          ibfxPerUSD
                        ).toFixed(2)}
                      </span>
                    </LabelMedium>
                  </RowSpaceBetween>
                }
                key="2"
              >
                {paymentCardBody("index")}
              </Panel>
            </Collapse>
            <div className="fixed">
              <Button
                type="primary"
                disabled={calculateTotal() <= 0}
                block
                className="mt-24px mb-8px"
                style={{
                  minHeight: "40px",
                  borderRadius: 4,
                }}
                onClick={() => openModal()}
              >
                {t("pay_with_ibfx_s")}
              </Button>
              <ModalCourseStripe
                disabled={calculateTotal() <= 0}
                totalPayment={calculateTotal()}
                setIsModalVisible={setIsModalVisible}
              />
            </div>
          </div>
        )}
      </DrawerStyled>
      <CoursePayment
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        paymentCardBody={paymentCardBody}
        totalPayment={calculateTotal()}
        ibfx1Amount={ibfx1Amount}
        // ibfx2Amount={ibfx2Amount}
        setIbfx1Amount={setIbfx1Amount}
        // setIbfx2Amount={setIbfx2Amount}
      />
    </>
  );
}
