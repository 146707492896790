import algosdk from 'algosdk'
import { algodClient } from '../configs'

export const chainType = {
	MainNet: 'mainnet',
	TestNet: 'testnet',
}

export const apiGetAccountAssets = async (address) => {
	const accountInfo = await algodClient.accountInformation(address).setIntDecoding(algosdk.IntDecoding.BIGINT).do()

	const algoBalance = accountInfo.amount
	const assetsFromRes = accountInfo.assets

	const assets = assetsFromRes.map(({ 'asset-id': id, amount, creator, frozen }) => ({
		id: Number(id),
		amount,
		creator,
		frozen,
		decimals: 0,
	}))

	assets.sort((a, b) => a.id - b.id)

	await Promise.all(
		assets.map(async (asset) => {
			const { params } = await algodClient.getAssetByID(asset.id).do()
			asset.name = params.name
			asset.unitName = params['unit-name']
			asset.url = params.url
			asset.decimals = params.decimals
		})
	)

	assets.unshift({
		id: 0,
		amount: algoBalance,
		creator: '',
		frozen: false,
		decimals: 6,
		name: 'Algo',
		unitName: 'Algo',
	})

	return assets
}

export const txnsToSign = async (operations) => {
	const suggestedParams = await algodClient.getTransactionParams().do()
	const txns = []
	for (const operation of operations) {
		const bytes = Uint8Array.from(Buffer.from(operation.algo_transaction, 'base64'))
		const transaction = algosdk.decodeUnsignedTransaction(bytes)
		transaction.firstRound = suggestedParams.firstRound
		transaction.lastRound = suggestedParams.lastRound
		txns.push({ txn: transaction, message: 'test', operation_id: operation.operation_id })
	}
	return [txns]
}

export const getParams = async () => {
	const params = await algodClient.getTransactionParams().do()

	return params
}

export const indexToGroup = (index, txnsToSigning) => {
	for (let group = 0; group < txnsToSigning.length; group++) {
		const groupLength = txnsToSigning[group].length
		if (index < groupLength) {
			return [group, index]
		}

		index -= groupLength
	}

	throw new Error(`Index too large for groups: ${index}`)
}
