import ReactGA from "react-ga";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { ACTIVITY_HOST } from "../../configs";
import {
  CLOSE_LOGIN_REGISTER_MODAL,
  OPEN_LOGIN_REGISTER_MODAL,
  SET_RECENT_ACTIVITY,
  SET_SECTION,
  SET_PAGE_MENU,
  SET_BENEVOLENCE_PROJECT_TYPE,
  SET_USER_SIGN,
} from "../constants";

export const setSection =
  (section = "", param = "", search = "") =>
  (dispatch) => {
    dispatch({
      type: SET_SECTION,
      payload: { section, param },
    });
    localStorage.setItem("MENU_OPEN", `${section}|${param}`);

    window.history.pushState(
      null,
      "IBFNex - Netverse",
      param && param !== ""
        ? `/${section}${param && "/" + param}`
        : search && search !== ""
        ? `/${section}${search}`
        : `/${section}`
    );

    ReactGA.event({
      category: "Go To page",
      action: `User go to page ${section} with param ${param}`,
    });
  };

export const setBenevolenceProjectType = (type) => (dispatch) => {
  dispatch({
    type: SET_BENEVOLENCE_PROJECT_TYPE,
    payload: type,
  });
};

export const getRecentActivity =
  (pagination = 1) =>
  async (dispatch) => {
    try {
      const token = localStorage.getItem("USER_AUTH_TOKEN");
      const decoded = jwtDecode(token);

      const result = (
        await axios.get(`${ACTIVITY_HOST}/activities`, {
          params: {
            user_id: decoded.user_id,
            // user_id: "fde95ba0-f359-11eb-b99c-65d4adf9ac7e",
            page: pagination,
          },
        })
      ).data;

      dispatch({
        type: SET_RECENT_ACTIVITY,
        payload: {
          data: result.data,
          total: result.data.length,
          hasNext: pagination < result.data.length,
          page: pagination,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const openModalLoginRegister = () => (dispatch) => {
  dispatch({
    type: OPEN_LOGIN_REGISTER_MODAL,
  });
};

export const closeModalLoginRegister = () => (dispatch) => {
  dispatch({
    type: CLOSE_LOGIN_REGISTER_MODAL,
  });
};

export const setPageMenu =
  ({ menu }) =>
  (dispatch) => {
    dispatch({
      type: SET_PAGE_MENU,
      payload: menu,
    });
  };

export const setUSerSign =
  ({ address }) =>
  (dispatch) => {
    dispatch({
      type: SET_USER_SIGN,
      payload: address,
    });
  };
