import React, { useState, useEffect } from "react";
import { Typography, Row, Col } from "antd";
import {
  ButtonStyled,
  ColStart,
  RowEnd,
  RowSpaceBetween,
} from "../../components/general_styled";
import { SearchStyled } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { setSection } from "../../modules/general/action";
import CardNFT from "../../components/CardNFT";
import { getAllEssencePurchase } from "../../modules/essence/action";
import { getEvmNftPurchased } from "../../modules/credence/action";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { useAccount } from "wagmi";
import { IS_EVM } from "../../configs";
import { getCredenceNFTSub } from "../../modules/nft_sub/action";

const { Title, Text } = Typography;

export default function AffluenceShoppePurchases() {
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();

  const [searchVal, setSearchVal] = useState(null);
  const dispatch = useDispatch();

  const page = useSelector((state) =>
    state.getIn(["essence", "purchases", "currentPage"])
  );
  const purchases = useSelector((state) =>
    state.getIn(["essence", "purchases", "data"]).toJS()
  );
  const nftsPurchased = useSelector((state) =>
    state.getIn(["credence", "purchased"]).toJS()
  );

  const nftSub = useSelector((state) => state.getIn(["nftSub", "nfts"]).toJS());

  const renderSearchBar = () => {
    return (
      <RowEnd>
        <SearchStyled
          className="search-input"
          placeholder="Search"
          autoComplete="off"
          allowClear
          size="large"
          onSearch={(value) => setSearchVal(value)}
          style={{
            marginRight: "12px",
            width: 300,
          }}
        />

        <ButtonStyled
          type={"primary"}
          ghost
          size="large"
          style={{
            width: 200,
          }}
          onClick={() => {
            dispatch(setSection("credence"));
          }}
        >
          {t("open_credence")}
        </ButtonStyled>
      </RowEnd>
    );
  };

  const getData = async (page, search) => {
    if (IS_EVM) {
      if (isConnected) {
        dispatch(getCredenceNFTSub(address));
        await dispatch(getEvmNftPurchased({ buyerAddress: address }));
      }
    } else {
      const decoded = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
      await dispatch(getAllEssencePurchase(decoded.user_id, page));
    }
  };

  useEffect(() => {
    getData(page);
  }, []);

  useEffect(() => {
    getData(page);
  }, [isConnected]);

  return (
    <ColStart>
      <RowSpaceBetween>
        <Title level={3} className="mb-0px mt-0px">
          {t("purchased")}
        </Title>
        {renderSearchBar()}
      </RowSpaceBetween>
      <Row className="mt-20px">
        {IS_EVM
          ? nftSub?.data?.map((nft) => {
              const metadata = JSON.parse(
                nft?.metadata_json || nft?.subs?.metadata_json
              );
              return (
                <CardNFT
                  key={nft.nft_id}
                  nftId={nft.nft_id}
                  name={metadata.name}
                  collectionName={metadata.collection.name}
                  ipfsHash={metadata.image.replace("ipfs://", "")}
                  is_pdf={metadata.is_pdf || false}
                  data={{ ...nft, metadata, ...nft?.subs }}
                  isVault={true}
                  toSub={IS_EVM ? true : false}
                />
              );
            })
          : purchases.map((nft) => {
              const metadata = JSON.parse(nft.nft.metadata_json);
              return (
                <CardNFT
                  nftId={nft.nft.nft_id}
                  name={metadata.name}
                  collectionName={metadata?.collection.name}
                  ipfsHash={metadata.image?.replace("ipfs://", "")}
                  is_pdf={metadata.is_pdf || false}
                  isPurchase={true}
                  data={nft?.nft}
                />
              );
            })}
      </Row>
    </ColStart>
  );
}
