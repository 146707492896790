import styled from "@emotion/styled";
import { Card } from "antd";

export const CardStyled = styled(Card)`
  background: #0d141b;
  /* Neutral/700 */

  box-sizing: border-box;
  border-radius: 6px;
  transition: all 0.3s ease;
  cursor: pointer;

  // min-height:

  .ant-card-body {
    padding: 8px;
  }

  &:hover {
    border: 1px solid #024FF1;

    transition: all 0.3s ease;
    box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
  }
`;

export const ImageStyled = styled.img`
  object-fit: cover;
  width: 100%;
  height: 150px;
  border: 0.5px solid #64748b;

  &.blank {
    border: none;
  }

  &.full {
    height: 300px;
  }

  &.img-radius-0 {
    border-radius: 2px 0px 0px 0px;
  }

  &.img-radius-1 {
    border-radius: 0px 2px 0px 0px;
  }

  &.img-radius-2 {
    border-radius: 0px 0px 0px 2px;
  }

  &.img-radius-3 {
    border-radius: 0px 0px 2px 0px;
  }
`;

export const CollectionNameStyled = styled.div`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  /* Shades/White */

  color: #ffffff;

  margin: 20px 12px 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;
