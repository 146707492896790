import React, { useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Progress,
  Row,
  Col,
  Alert,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { ColCenter, RowSpaceBetween, RowCenter } from "../general_styled";

import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  ModalContent,
  DividerCard,
  ModalContentTitle,
  ModalContentKey,
  ModalContentValue,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";

import {
  getConfluenceImpacts,
  acceptImpact,
  rejectImpact,
  acceptCounterOffer,
  cancelImpact,
} from "../../modules/confluence/action";

import ibfx1 from "../../assets/images/ibfx-1.svg";
import ibfx4 from "../../assets/images/ibfx-4.svg";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { decryptMnemonic, fromIBFx } from "../../utils/utils";
import ReactCodeInput from "react-code-input";

const { Title, Text } = Typography;

export default function ModalDispute(props) {
  const confluenceImpacts = useSelector((state) =>
    state.getIn(["confluence", "impacts"]).toJS()
  );

  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMode, setIsMode] = useState(0);
  const [isSuccess, setIsSuccess] = useState(0);
  const [pinCode, setPinCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState(false);
  const isAccept = props.type === "accept";
  const isActive = props.isActive;
  const data = props.data;

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setPinCode("");
    setPinCodeError(false);
    setIsModalVisible(false);
  };

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);
      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async () => {
    setPinCodeError(false);

    const _check = checkPin();
    if (!_check) {
      setPinCode("");
      return setPinCodeError(true);
    }

    setIsLoading(true);

    const getMnemonic = localStorage.getItem(
      COMMON_CONSTANT.ENCRYPTED_MNEMONIC
    );
    const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);

    if (isAccept) {
      try {
        let result;
        if (props.isCounterOffer) {
          result = await acceptCounterOffer(_decryptMnemonic, data.impact_id);
        } else {
          result = await acceptImpact(_decryptMnemonic, data.impact_id);
        }
        if (result.length > 0) {
          setIsSuccess(1);
        } else {
          setIsSuccess(0);
        }
      } catch (error) {
        setIsSuccess(0);
      }
    } else {
      try {
        let result;
        if (props.isCounterOffer) {
          result = await cancelImpact(_decryptMnemonic, data.impact_id);
        } else {
          result = await rejectImpact(data.impact_id);
        }
        if (props.isCounterOffer && result.length > 0) {
          setIsSuccess(1);
        } else if (result) {
          setIsSuccess(1);
        } else {
          setIsSuccess(0);
        }
      } catch (error) {
        setIsSuccess(0);
      }
    }
    setIsMode(1);
    setIsLoading(false);
    dispatch(getConfluenceImpacts(confluenceImpacts.currentPage));
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const resolvePinCode = () => {
    return (
      <ColCenter className="h-100">
        <Title className="text-w700 gray-7 mb-0px" level={3}>
          Enter pin
        </Title>
        <Text className="gray-7" align="center">
          Please enter your pin code to confirm the transaction
        </Text>
        <Row className="mt-24px">
          <Col span={24}>
            <RowCenter>
              <ReactCodeInput
                type="password"
                onChange={(e) => setPinCode(e)}
                fields={4}
                {...codeProps}
              />
            </RowCenter>
          </Col>
          {pinCodeError && (
            <Col className="mt-10px" span={24}>
              <Alert
                message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                type="error"
                showIcon
              />
            </Col>
          )}
        </Row>
        <Button
          block
          style={{ height: 40 }}
          type="primary"
          className="mt-12px"
          onClick={() => onSubmit()}
          disabled={isLoading}
        >
          {!isLoading ? "Confirm" : antIcon}
        </Button>
      </ColCenter>
    );
  };

  const resolveSuccess = (num) => (
    <ColCenter className="h-100" span={24}>
      <Progress
        type="circle"
        percent={100}
        status={num ? "success" : "exception"}
      />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        {num ? "Success!" : "Failed"}
      </TitleStyled>
      <TextStyled>
        {num
          ? isAccept
            ? "Offer Accepted."
            : "Offer Rejected."
          : "Failed when update offer, please try again."}
      </TextStyled>
      <Button
        block
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  return (
    <>
      {windowDimensions.width > 835 ? (
        <>
          <Button
            block
            style={{ height: 40 }}
            type={isAccept ? "primary" : "default"}
            className="mt-12px"
            disabled={!isActive}
            danger={!isAccept}
            onClick={() => {
              if (isActive) {
                props.handleCancel();
                showModal();
              }
            }}
          >
            {isAccept ? "Accept" : "Reject"}
          </Button>
        </>
      ) : (
        <Button
          danger={!isAccept}
          disabled={!isActive}
          block
          size="large"
          onClick={showModal}
        >
          {isAccept ? "Accept" : "Reject"}
        </Button>
      )}
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="500px"
          footer={null}
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {isMode === 1 ? (
              isSuccess ? (
                resolveSuccess(1)
              ) : (
                resolveSuccess(0)
              )
            ) : isMode === 2 ? (
              resolvePinCode()
            ) : (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  {isAccept
                    ? "Accept" + (props.isCounterOffer ? " Counter Offer" : "")
                    : "Reject" + (props.isCounterOffer ? " Counter Offer" : "")}
                </Title>
                <Text className="gray-7 " align="center">
                  Review your received offer
                </Text>
                <ModalContent>
                  <ModalContentTitle>
                    {data.project?.name || "-"}
                  </ModalContentTitle>
                  <DividerCard />
                  <RowSpaceBetween>
                    <ModalContentKey>Date</ModalContentKey>
                    <p>{data.created_at}</p>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>Country</ModalContentKey>
                    <p>{data.country_name}</p>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>SDG</ModalContentKey>
                    <p>{data.impact_sdg?.name || "-"}</p>
                  </RowSpaceBetween>
                  <DividerCard />
                  <RowSpaceBetween>
                    <ModalContentKey>
                      <img
                        src={ibfx1}
                        style={{
                          paddingRight: 10,
                        }}
                      />
                      Offer
                    </ModalContentKey>
                    <ModalContentValue>
                      {fromIBFx(data.amount)}
                    </ModalContentValue>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>
                      <img
                        src={ibfx4}
                        style={{
                          paddingRight: 10,
                        }}
                      />
                      Token
                    </ModalContentKey>
                    <ModalContentValue>
                      {fromIBFx(data.project_amount)}
                    </ModalContentValue>
                  </RowSpaceBetween>
                </ModalContent>
                <Button
                  block
                  style={{ height: 40 }}
                  type={isAccept ? "primary" : "danger"}
                  className="mt-12px"
                  onClick={() => setIsMode(2)}
                >
                  {isLoading
                    ? antIcon
                    : isAccept
                    ? "Accept Offer"
                    : "Reject Offer"}
                </Button>
              </ColCenter>
            )}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
              alt="cancel-button"
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {isMode === 1 ? (
              isSuccess ? (
                resolveSuccess(1)
              ) : (
                resolveSuccess(0)
              )
            ) : isMode === 2 ? (
              resolvePinCode()
            ) : (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  {isAccept ? "Accept" : "Reject"}
                </Title>
                <Text className="gray-7 mt-5px" align="center">
                  Review your received offer
                </Text>
                <ModalContent>
                  <p>{data.project?.name || "-"}</p>
                  <DividerCard />
                  <RowSpaceBetween>
                    <ModalContentKey>Date</ModalContentKey>
                    <p>{data.created_at}</p>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>Country</ModalContentKey>
                    <p>{data.country_name}</p>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>SDG</ModalContentKey>
                    <p>{data.impact_sdg?.name || "-"}</p>
                  </RowSpaceBetween>
                  <DividerCard />
                  <RowSpaceBetween>
                    <ModalContentKey>
                      <img
                        src={ibfx1}
                        style={{
                          paddingRight: 10,
                        }}
                      />
                      Offer
                    </ModalContentKey>
                    <ModalContentValue>{data.amount}</ModalContentValue>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>
                      <img
                        src={ibfx4}
                        style={{
                          paddingRight: 10,
                        }}
                      />
                      Token
                    </ModalContentKey>
                    <ModalContentValue>{data.project_amount}</ModalContentValue>
                  </RowSpaceBetween>
                </ModalContent>
                <Button
                  block
                  style={{ height: 40 }}
                  type={isAccept ? "primary" : "danger"}
                  className="mt-12px"
                  onClick={() => setIsMode(2)}
                >
                  {isLoading
                    ? antIcon
                    : isAccept
                    ? "Accept Offer"
                    : "Reject Offer"}
                </Button>
              </ColCenter>
            )}
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
