import React, { useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Image,
  Row,
  Spin,
  Progress,
  Alert,
  Col,
} from "antd";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  ColCenter,
  RowCenter,
  RowEnd,
  RowSpaceBetween,
  ButtonStyled,
  ButtonOutline,
  ColSpaceBetween,
} from "../general_styled";

import {
  ContentStyled,
  RemoveListingStyled,
  DetailStyled,
  DividerCard,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import IBFxLogo from "../../assets/images/ibfx-icon.svg";
import moment from "moment";
import { decryptMnemonic, fromIBFx } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import ReactCodeInput from "react-code-input";
import {
  delistingAuction,
  getAllOwnedAuction,
} from "../../modules/affluence/action";
import { useTranslation } from "react-i18next";
import { CREDENCE_HOST } from "../../configs";
import jwtDecode from "jwt-decode";

const { Title, Text } = Typography;

export default function ModalDelistingAuctionNFT(props) {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMode, setIsMode] = useState("confirmation");
  const [pinCode, setPinCode] = useState(null);
  const [isPinError, setPinError] = useState(false);
  const [processingPercent, setProcessingPercent] = useState(10);
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const checkPin = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = await decryptMnemonic(getMnemonic, pinCode);
      setPinError(false);
      await onSubmit(_decryptMnemonic);
    } catch (e) {
      console.log(e);
      setPinError(true);
    }
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsMode("confirmation");
    setIsModalVisible(false);
    setPinCode(null);
    setPinError(false);
    setProcessing(false);
    setIsSuccess(true);
    setProcessingPercent(10);
  };

  const onSubmit = async (mnemonic) => {
    setIsLoading(true);
    setIsMode("processing");
    setProcessing(true);

    try {
      await delistingAuction(mnemonic, props?.auction_id);
      setProcessingPercent(100);
      setProcessing(false);
      setIsSuccess(true);

      const decodedUser = jwtDecode(localStorage.getItem("USER_AUTH_TOKEN"));
      await dispatch(getAllOwnedAuction(decodedUser.user_id));
    } catch (error) {
      setProcessingPercent(0);
      setProcessing(false);
      setIsSuccess(false);
    }

    setIsLoading(false);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  return (
    <>
      {props.isFromDetail ? (
        <ButtonOutline
          block
          className={`${!props.noMargin && "ml-8px"}`}
          style={{ height: 40, borderRadius: 4 }}
          onClick={() => {
            showModal();
          }}
        >
          {t("auction_remove")}
        </ButtonOutline>
      ) : (
        <RowCenter
          className="w-100 mt-16px pb-14px pointer"
          onClick={showModal}
        >
          <RemoveListingStyled>{t("auction_remove")}</RemoveListingStyled>
        </RowCenter>
      )}
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="500px"
          className="modal-delisting-nft"
          footer={null}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {isMode === "confirmation" ? (
              props.data && (
                <ColCenter>
                  <Title className="text-w700 gray-7 mb-0px" level={3}>
                    {t("auction_delisting")}
                  </Title>
                  <Text className="gray-7" align="center">
                    {t("auction_delisting_detail")}
                  </Text>
                  <Image
                    preview={false}
                    className="img-nft"
                    alt={props?.data?.metadata?.name}
                    src={
                      CREDENCE_HOST +
                      "/files/" +
                      props?.data?.auctionData?.NFT?.cover_file
                    }
                  />
                  <DetailStyled>
                    <RowSpaceBetween className="px-20px pt-20px">
                      <Text className="gray-7">Fragmentation</Text>
                      <Text className="gray-8 text-size-16 text-w600">
                        {props?.data?.auctionData?.NFT?.fragmentation}
                      </Text>
                    </RowSpaceBetween>
                    <DividerCard />
                    <RowSpaceBetween className="px-20px">
                      <Text className="gray-7">Listed On</Text>
                      <Text className="gray-8 text-size-16 text-w600">
                        {moment(props?.data?.auctionData?.created_at).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </Text>
                    </RowSpaceBetween>
                    <DividerCard />
                    <RowSpaceBetween className="px-20px">
                      <Text className="gray-7 text-w600">Highest Bid</Text>
                      <RowEnd>
                        <Image preview={false} src={IBFxLogo} width={"18px"} />
                        <Text className="ml-4px gray-8 text-size-16 text-w600">
                          {fromIBFx(props?.data?.transaction?.total_ibfx) || 0}
                        </Text>
                      </RowEnd>
                    </RowSpaceBetween>
                    <DividerCard />
                  </DetailStyled>

                  <Button
                    type="primary"
                    danger
                    style={{
                      width: "calc(100% - 40px)",
                      margin: "0 20px",
                      marginTop: 24,
                    }}
                    size="large"
                    onClick={() => setIsMode("pin")}
                  >
                    {isLoading ? antIcon : "Delist NFT"}
                  </Button>
                </ColCenter>
              )
            ) : isMode === "pin" ? (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  {t("enter_pin")}
                </Title>
                <Text className="gray-7" align="center">
                  {t("enter_pin_desc")}
                </Text>
                <ColSpaceBetween className="w-100 h-100 px-20px">
                  <Row className="mt-24px">
                    <Col span={24}>
                      <RowCenter>
                        <ReactCodeInput
                          type="password"
                          onChange={(e) => setPinCode(e)}
                          fields={4}
                          {...codeProps}
                        />
                      </RowCenter>
                    </Col>
                    {isPinError && (
                      <Col className="mt-10px" span={24}>
                        <Alert
                          message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                          type="error"
                          showIcon
                        />
                      </Col>
                    )}
                  </Row>
                  <ButtonStyled
                    style={{
                      width: "calc(100% - 50px)",
                      marginTop: 20,
                    }}
                    onClick={() => checkPin()}
                    disabled={isProcessing}
                  >
                    {!isProcessing ? "Confirm Pin" : <Spin />}
                  </ButtonStyled>
                </ColSpaceBetween>
              </ColCenter>
            ) : (
              <ColCenter>
                <Progress
                  type="circle"
                  percent={processingPercent}
                  status={!isSuccess && "exception"}
                />
                <Title className="text-w700 gray-7 mb-8px mt-24px" level={3}>
                  {isProcessing
                    ? "Processing"
                    : isSuccess
                    ? "Success!"
                    : "Failed!"}
                </Title>
                <Text
                  style={{ width: "60%" }}
                  className="gray-7"
                  align="center"
                >
                  {isProcessing
                    ? "Please wait while we delisting your auction"
                    : isSuccess
                    ? "Your auction has been successfully delisting from auction marketplace!"
                    : "An error occured, please try again later"}
                </Text>
                {!isProcessing && (
                  <ButtonStyled
                    className="mt-24px"
                    type="primary"
                    danger={!isSuccess}
                    style={{
                      width: "350px",
                      height: "48px",
                      borderRadius: "4px",
                    }}
                    onClick={() => handleCancel()}
                  >
                    Dismiss
                  </ButtonStyled>
                )}
              </ColCenter>
            )}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Delist NFT
              </Title>
              <Text className="gray-7" align="center">
                Your listed NFT will be moved back to Credence
              </Text>
              <img
                className="img-nft"
                alt={props.name}
                // src={`https://ipfs.io/ipfs/${props.ipfsHash}`}
                src={props.image}
              />
              <DetailStyled>
                <RowSpaceBetween className="px-20px pt-20px">
                  <Text className="gray-7">Fragmentation</Text>
                  <Text className="gray-8 text-size-16 text-w600">
                    {props.fragmentation}
                  </Text>
                </RowSpaceBetween>
                <DividerCard />
                <RowSpaceBetween className="px-20px">
                  <Text className="gray-7">Listed On</Text>
                  <Text className="gray-8 text-size-16 text-w600">
                    {props.listed_on}
                  </Text>
                </RowSpaceBetween>
                <DividerCard />
                <RowSpaceBetween className="px-20px">
                  <Text className="gray-7 text-w600">Value</Text>
                  <RowEnd>
                    <Image preview={false} src={IBFxLogo} width={"18px"} />
                    <Text className="ml-4px gray-8 text-size-16 text-w600">
                      {props.price}
                    </Text>
                  </RowEnd>
                </RowSpaceBetween>
                <DividerCard />
              </DetailStyled>

              <Button
                type="primary"
                danger
                style={{
                  width: "calc(100% - 40px)",
                  margin: "0 20px",
                  marginTop: 24,
                }}
                size="large"
                onClick={() => onSubmit()}
              >
                {isLoading ? antIcon : "Delist NFT"}
              </Button>
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
