import React, { useEffect } from "react";
import { Row, Select } from "antd";
import {
  ColStart,
  RowSpaceBetween,
  RowStart,
  SelectStyled,
} from "../../components/general_styled";
import CardUserCollection from "../../components/CardUserCollection";
import CardUserCollectionStandAlone from "../../components/CardUserCollectionStandAlone";
import { useSelector } from "react-redux";
import {
  getUserDetailByAddress,
  getUserDetailById,
} from "../../modules/auth/action";
import { setVisitedUser } from "../../modules/affluence/action";
import {
  getCollectionByUser,
  getCollectionDetailByUser,
  getEvmCollectionByAddress,
  getEvmCollectionDetailByAddress,
} from "../../modules/credence/action";
import { IS_EVM, MEMBERSHIP_HOST } from "../../configs";

import { UserImageStyled, UsernameStyled, CollectionStyled } from "./styled";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SEO from "../../components/SEO";

export default function AffluenceUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const param = useSelector((state) => state.getIn(["general", "param"]));

  const visitedUser = useSelector((state) =>
    state.getIn(["affluence", "visitedUser"]).toJS()
  );

  const collections = useSelector((state) =>
    state.getIn(["credence", "collections"]).toJS()
  );

  const collectionDetail = useSelector((state) =>
    state.getIn(["credence", "collectionDetail"]).toJS()
  );

  const handleChangeDropdown = (val) => {
    // console.log(val);
  };

  const getUserData = async () => {
    const _userData = IS_EVM
      ? await getUserDetailByAddress(param)
      : await getUserDetailById(param);
    dispatch(setVisitedUser(_userData));
    if (IS_EVM) {
      dispatch(getEvmCollectionByAddress(param));
      dispatch(getEvmCollectionDetailByAddress(null, true, param));
    } else {
      dispatch(getCollectionByUser(_userData.user_id));
      dispatch(getCollectionDetailByUser(null, true, _userData.user_id));
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <SEO title="IBFNEX" description={t("home-subtitle")}>
      <ColStart>
        <RowSpaceBetween>
          <RowStart>
            <UserImageStyled
              src={`${MEMBERSHIP_HOST}/files/${visitedUser?.photo}`}
            />
            <ColStart>
              <UsernameStyled>{visitedUser?.name}</UsernameStyled>
              <CollectionStyled>
                {collections?.data?.length || 0} Collections
              </CollectionStyled>
            </ColStart>
          </RowStart>
          <SelectStyled
            showSearch
            placeholder="Sort by"
            defaultValue="popular"
            style={{
              width: 250,
            }}
            onSelect={handleChangeDropdown}
          >
            <Select.Option value="popular">Sort by • Popular</Select.Option>
            <Select.Option value="created_at">
              Sort by •Created At
            </Select.Option>
          </SelectStyled>
        </RowSpaceBetween>
        <Row className="mt-20px" gutter={[16, 16]}>
          {collections?.data?.map((data) => {
            return (
              <CardUserCollection
                key={`card-user-collection-${data.collection_id}`}
                data={data}
              />
            );
          })}
          {collectionDetail?.data?.map((data) => {
            return (
              <CardUserCollectionStandAlone
                key={`card-user-collection-${data.nft_id}`}
                data={data}
              />
            );
          })}
        </Row>
      </ColStart>
    </SEO>
  );
}
