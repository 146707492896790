import styled from "@emotion/styled";

export const InactiveLabel = styled.span`
  /* Caption/All Caps */
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height, or 117% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text/300 */

  color: #44567b;
`;
