import styled from "@emotion/styled";
import { Row } from "antd";
import Text from "antd/lib/typography/Text";
import { RowSpaceBetween } from "../general_styled";

export const ContentStyled = styled.div`
  position: relative;
`;

export const TitleStyled = styled(Text)`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;
`;

export const TextStyled = styled(Text)`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* or 167% */

  text-align: center;

  /* Gray/gray-7 */

  color: #273248;
  max-width: 350px;
`;

export const ClaimedVoucher = styled(RowSpaceBetween)`
  width: 100%;

  background: #f7f8fa;
  /* Neutral/500 */

  border: 1px solid #edeff2;
  box-sizing: border-box;
  /* Card Shadow */

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  padding: 20px;

  flex-wrap: nowrap;
  align-items: center;

  .label {
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height */

    /* Secondary/300 */

    color: #6b7c94;
  }

  .value {
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    /* identical to box height */

    /* Gray/gray-7 */

    color: #273248;
  }
`;
