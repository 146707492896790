import React, { useState } from "react";
import moment from "moment";
import { Modal, Typography, Button, Drawer, Progress } from "antd";
import { useTranslation } from "react-i18next";
import {
  ButtonOutline,
  ColCenter,
  RowCenter,
  RowSpaceBetween,
} from "../general_styled";
import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  AcceptButton,
  ModalContent,
  DividerCard,
  FooterButtonContent,
  ModalContentKey,
  ModalContentValue,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";

import ModalConfluenceOffers from "../ModalConfluenceOffers";
import ModalConfluenceOfferCounter from "../ModalConfluenceOfferCounter";

import ibfx1 from "../../assets/images/ibfx-1.svg";
import ibfx4 from "../../assets/images/ibfx-4.svg";
import { fromIBFx } from "../../utils/utils";
import { CREDENCE_HOST } from "../../configs";
import {
  getCredenceNFTByUserId,
  updateDeleteStatus,
} from "../../modules/credence/action";
import { useDispatch } from "react-redux";

const { Title, Text } = Typography;

export default function ModalCredenceRemove(props) {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMode, setIsMode] = useState(2);
  const [isSuccess, setIsSuccess] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isActive = props.isActive;
  const data = props.nft;
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(2);
    setIsModalVisible(false);
  };

  const resolveSuccess = (num) => (
    <ColCenter className="h-100" span={24}>
      <Progress
        type="circle"
        percent={100}
        status={num ? "success" : "exception"}
      />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        {num
          ? t("credence-modal-remove-success-title")
          : t("credence-modal-remove-failed-title")}
      </TitleStyled>
      <TextStyled>
        {num
          ? t("credence-modal-remove-success-subtitle")
          : t("credence-modal-remove-failed-subtitle")}
      </TextStyled>
      <Button
        block
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  const resolvePending = () => (
    <ColCenter className="h-100" span={24}>
      <Progress type="circle" percent={10} status={"active"} />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        {t("credence-modal-remove-pending-title")}
      </TitleStyled>
      <TextStyled>{t("credence-modal-remove-pending-subtitle")}</TextStyled>
      <Button
        block
        disabled
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  const onSubmit = async () => {
    setIsLoading(true);
    setIsMode(3);
    try {
      await updateDeleteStatus(data?.nft_id, { is_deleted: true });

      await dispatch(getCredenceNFTByUserId(null, 1));
      setIsMode(1);
      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      {props.isFromDetail ? (
        <ButtonOutline
          block
          className="ml-8px"
          style={{ height: 40, borderRadius: 4 }}
          onClick={() => {
            showModal();
          }}
        >
          {t("credence-modal-remove-button")}
        </ButtonOutline>
      ) : (
        <AcceptButton
          className={isActive ? "pointer" : "pointer"}
          onClick={() => {
            showModal();
          }}
        >
          {t("credence-modal-remove-button")}
        </AcceptButton>
      )}
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="500px"
          footer={null}
        >
          <ContentStyled>
            {isMode === 1 ? (
              isSuccess ? (
                resolveSuccess(1)
              ) : (
                resolveSuccess(0)
              )
            ) : isMode === 2 ? (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  {t("credence-modal-remove-title")}
                </Title>
                <Text className="gray-7 " align="center">
                  {t("credence-modal-remove-subtitle")}
                </Text>
                <ModalContent>
                  <RowCenter>
                    <img
                      style={{
                        width: 256,
                        height: 256,
                        objectFit: "cover",
                        borderRadius: 4,
                      }}
                      alt={data?.name}
                      src={
                        data?.cover_file
                          ? CREDENCE_HOST + "/files/" + data?.cover_file
                          : `https://ipfs.io/ipfs/${data?.ipfsHash}`
                      }
                    />
                  </RowCenter>
                  <RowSpaceBetween className="mt-10px">
                    <ModalContentKey>{t("fragmentation")}</ModalContentKey>
                    <p>{data?.fragmentation || 0}</p>
                  </RowSpaceBetween>
                  <DividerCard style={{ marginTop: "-10px" }} />
                  <RowSpaceBetween>
                    <ModalContentKey>{t("listed_on")}</ModalContentKey>
                    <p>{moment(data?.sold_on).format("D-MMM-YYYY")}</p>
                  </RowSpaceBetween>
                  <DividerCard style={{ marginTop: "-10px" }} />
                </ModalContent>
                <FooterButtonContent>
                  <RowSpaceBetween>
                    <Button
                      block
                      style={{ height: 40 }}
                      type="danger"
                      onClick={() => onSubmit()}
                      disabled={isLoading}
                    >
                      {!isLoading ? t("remove_nft") : t("dismiss")}
                    </Button>
                  </RowSpaceBetween>
                </FooterButtonContent>
              </ColCenter>
            ) : (
              resolvePending()
            )}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
              alt="cancel-button"
            />
          }
        >
          <ContentStyled>
            {isMode === 1 ? (
              isSuccess ? (
                resolveSuccess(1)
              ) : (
                resolveSuccess(0)
              )
            ) : isMode === 2 ? (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  {t("credence-modal-remove-title")}
                </Title>
                <Text className="gray-7 " align="center">
                  {t("credence-modal-remove-subtitle")}
                </Text>
                <ModalContent>
                  <RowCenter>
                    <img
                      style={{
                        width: 256,
                        height: 256,
                        objectFit: "cover",
                        borderRadius: 4,
                      }}
                      alt={data?.name}
                      src={
                        data?.cover_file
                          ? CREDENCE_HOST + "/files/" + data?.cover_file
                          : `https://ipfs.io/ipfs/${data?.ipfsHash}`
                      }
                    />
                  </RowCenter>
                  <RowSpaceBetween className="mt-10px">
                    <ModalContentKey>{t("fragmentation")}</ModalContentKey>
                    <p>{data?.fragmentation || 0}</p>
                  </RowSpaceBetween>
                  <DividerCard style={{ marginTop: "-10px" }} />
                  <RowSpaceBetween>
                    <ModalContentKey>{t("listed_on")}</ModalContentKey>
                    <p>{moment(data?.sold_on).format("D-MMM-YYYY")}</p>
                  </RowSpaceBetween>
                  <DividerCard style={{ marginTop: "-10px" }} />
                </ModalContent>
                <FooterButtonContent>
                  <RowSpaceBetween>
                    <Button
                      block
                      style={{ height: 40 }}
                      type="danger"
                      onClick={() => onSubmit()}
                      disabled={isLoading}
                    >
                      {!isLoading ? t("remove_nft") : t("dismiss")}
                    </Button>
                  </RowSpaceBetween>
                </FooterButtonContent>
              </ColCenter>
            ) : (
              resolvePending()
            )}
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
