import { Collapse, Tabs } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse
const { TabPane } = Tabs

export default function WalletFAQ(props) {
	const generalSection = useSelector((state) => state.getIn(['general', 'section']))
	const { t } = useTranslation()

	const walletFaq = [
		{
			title: t('faq-wallet-title-1'),
			desc: t('faq-wallet-desc-1'),
		},
		{
			title: t('faq-wallet-title-2'),
			desc: t('faq-wallet-desc-2'),
		},
		{
			title: t('faq-wallet-title-3'),
			desc: t('faq-wallet-desc-3'),
		},
		{
			title: t('faq-wallet-title-4'),
			desc: t('faq-wallet-desc-4'),
		},
		{
			title: t('faq-wallet-title-5'),
			desc: t('faq-wallet-desc-5'),
		},
		{
			title: t('faq-wallet-title-6'),
			desc: t('faq-wallet-desc-6'),
		},
		{
			title: t('faq-wallet-title-7'),
			desc: t('faq-wallet-desc-7'),
		},
		// {
		// 	title: t('faq-wallet-title-8'),
		// 	desc: t('faq-wallet-desc-8'),
		// },
		// {
		// 	title: t('faq-wallet-title-9'),
		// 	desc: t('faq-wallet-desc-9'),
		// },
		// {
		// 	title: t('faq-wallet-title-10'),
		// 	desc: t('faq-wallet-desc-10'),
		// },
		{
			title: t('faq-wallet-title-11'),
			desc: t('faq-wallet-desc-11'),
		},
	]

	const credenceFaq = [
		{
			title: t('faq-credence-title-1'),
			desc: t('faq-credence-desc-1'),
		},
		{
			title: t('faq-credence-title-2'),
			desc: t('faq-credence-desc-2'),
		},
		{
			title: t('faq-credence-title-3'),
			desc: t('faq-credence-desc-3'),
		},
		{
			title: t('faq-credence-title-4'),
			desc: t('faq-credence-desc-4'),
		},
		{
			title: t('faq-credence-title-5'),
			desc: t('faq-credence-desc-5'),
		},
		{
			title: t('faq-credence-title-6'),
			desc: t('faq-credence-desc-6'),
		},
		{
			title: t('faq-credence-title-7'),
			desc: t('faq-credence-desc-7'),
		},
		{
			title: t('faq-credence-title-8'),
			desc: t('faq-credence-desc-8'),
		},
		{
			title: t('faq-credence-title-9'),
			desc: t('faq-credence-desc-9'),
		},
		{
			title: t('faq-credence-title-10'),
			desc: t('faq-credence-desc-10'),
		},
		{
			title: t('faq-credence-title-11'),
			desc: t('faq-credence-desc-11'),
		},
		{
			title: t('faq-credence-title-12'),
			desc: t('faq-credence-desc-12'),
		},
		{
			title: t('faq-credence-title-13'),
			desc: t('faq-credence-desc-13'),
		},
		{
			title: t('faq-credence-title-14'),
			desc: t('faq-credence-desc-14'),
		},
		// {
		// 	title: t('faq-credence-title-15'),
		// 	desc: t('faq-credence-desc-15'),
		// },
	]

	return (
		<>
			<Tabs className="mt-12px w-100" defaultActiveKey={generalSection} size="large">
				<TabPane tab="Wallet" key="wallet">
					<Collapse accordion>
						{walletFaq.map((e, i) => (
							<Panel header={e.title} key={i}>
								<p>{e.desc}</p>
							</Panel>
						))}
					</Collapse>
				</TabPane>
				<TabPane tab="Credence-Affluence" key="credence">
					<Collapse accordion>
						{credenceFaq.map((e, i) => (
							<Panel header={e.title} key={i}>
								<div dangerouslySetInnerHTML={{ __html: e.desc }}></div>
							</Panel>
						))}
					</Collapse>
				</TabPane>
				<TabPane tab="Excellence" key="excellence"></TabPane>
				<TabPane tab="Benevolence" key="benevolence"></TabPane>
				{/* <TabPane tab="Confluence" key="confluence"></TabPane> */}
				{/* <TabPane tab="Transactions" key="transactions"></TabPane> */}
			</Tabs>
		</>
	)
}
