import axios from "axios";
import { BENEVOLENCE_HOST } from "../../configs";
import { PROJECT_TYPE } from "../../constants/constant_common";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import {
  GET_ASSET_PROJECTS,
  GET_ASSET_PROJECTS_FAIL,
  GET_ASSET_PROJECTS_SUCCESS,
} from "../constants";

export const getAssetProjects =
  (token, page = 1, filter = null, search) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ASSET_PROJECTS,
      });

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const params = {
        type: PROJECT_TYPE.ASSET,
        page: page,
        is_active: true,
        status: "OPENED",
        // chain: IS_EVM ? "POLYGON" : "ALGORAND",
        chain: "ALGORAND",
      };

      if (filter) params[filter] = true;
      if (search) params.search = search;

      const result = (
        await axios.get(`${BENEVOLENCE_HOST}/projects`, {
          headers,
          params,
        })
      ).data;
      const { data } = result;

      dispatch({
        type: GET_ASSET_PROJECTS_SUCCESS,
        payload: {
          filter,
          data: data.items,
          currentPage: page,
          hasNext: page < data.total_pages,
          totalItems: data.total_items,
          totalPage: data.total_pages,
        },
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: GET_ASSET_PROJECTS_FAIL,
        payload: {
          errorMessage: extractErrorMessage(e),
        },
      });
      throw e;
    }
  };

export const getNgoByUserId = async ({ token, userId = null }) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = {};

    if (userId) params.user_id = userId;

    const result = (
      await axios.get(`${BENEVOLENCE_HOST}/ngo-projects`, {
        headers,
        params,
      })
    ).data;
    const { data } = result;
    return data;
  } catch (e) {
    throw e;
  }
};
