import styled from "@emotion/styled";
import { Button, Typography } from "antd";

const { Text } = Typography;

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: 1095px;
  min-height: 610px;

  @media screen and (max-width: 835px) {
    height: 100%;
  }

  @media screen and (max-width: 500px) {
    padding-top: 36px;
  }
`;

export const StepContentStyled = styled.div`
  width: 100%;
  min-height: 362px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 24px;
  margin: 24px 0px;

  background: #fcfcfc;
  border: 1px solid #edeff2;
  box-sizing: border-box;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  overflow: auto;

  @media screen and (max-width: 835px) {
    padding-bottom: 100px;
  }
`;

export const ContentTextStyled = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #273248;

  flex: none;
  order: 0;
  flex-grow: 0;

  max-width: 831px;
`;

export const RedTextStyled = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #f43c3c;

  margin-bottom: 12px;
`;

export const CopyTextStyled = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Daybreak Blue/blue-6 */

  color: #024FF1;
`;

export const ContentIconStyled = styled.img`
  position: absolute;
  right: 24px;
  bottom: 24px;

  width: 96px;
`;

export const UlStyled = styled.ul`
  list-style-type: none !important;
  margin: 0;
  padding: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #273248;
`;

export const LiStyled = styled.li`
  margin: 0 !important;

  &:before {
    content: "• ";
    color: balck;
  }
`;

export const MnemonicSpan = styled.div`
  padding: 8px;

  // width: 160px;
  // max-width: 100%;
  // height: 31px;

  background: #f7f8fa;

  border: 1px solid #edeff2;
  // box-sizing: border-box;

  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;

  // margin: 0px 12px 12px 0px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  /* Text/300 */

  color: #44567b;
`;

export const CanvasButtonStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 835px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 28px;

    background: #fcfcfc;
    box-shadow: 0px -4px 6px rgba(197, 205, 234, 0.25);
  }
`;

export const ButtonContinueStyled = styled(Button)`
  height: 48px;

  @media screen and (min-width: 834px) {
    height: 48px;
    max-width: 20rem;
`;
