import { ColCenter, RowCenter } from "../../components/general_styled"
import ComingSoonLogo from "../../assets/images/coming-soon-logo.svg"
import { DescStyled, TitleStyled } from "./styled"
import useWindowDimensions from "../../utils/windowDimensions"

export default function Affluence() {
  const { width } = useWindowDimensions()

  return width > 1000 ? (
    <RowCenter>
      <ColCenter
        className="start"
        style={{
          height: "100%",
          minHeight: "calc(100vh - 120px)",
        }}
      >
        <TitleStyled>Coming Soon</TitleStyled>
        <DescStyled>
          The Affluence platform is a market place for NFTs with the unique
          features of deferred payment sale and collateralization. While the
          current NFT ecosystem is dominated by Ethereum based platforms with
          serious environmental and other issues, the existing non-Ethereum
          eco-friendly platforms are not feature-rich. None have “zero-interest”
          buy-now-pay-later feature and/or collateralization feature to mitigate
          risk for the seller. In addition to these value propositions,
          Affluence will also have a few novel features, such as, personal
          customizable gallery resulting in significant improvement in user
          interface and user experience.
        </DescStyled>
      </ColCenter>
      <img
        src={ComingSoonLogo}
        width={324}
        style={{
          marginLeft: 100,
          maxWidth: "100%",
        }}
      />
    </RowCenter>
  ) : (
    <ColCenter
      className="w-100"
      style={{
        height: "100%",
        minHeight: "calc(100vh - 120px)",
      }}
    >
      <img
        src={ComingSoonLogo}
        width={324}
        style={{
          maxWidth: "100%",
          marginBottom: "2rem",
        }}
      />
      <TitleStyled>Coming Soon</TitleStyled>
      <DescStyled align="center">
        The Affluence platform is a market place for NFTs with the unique
        features of deferred payment sale and collateralization. While the
        current NFT ecosystem is dominated by Ethereum based platforms with
        serious environmental and other issues, the existing non-Ethereum
        eco-friendly platforms are not feature-rich. None have “zero-interest”
        buy-now-pay-later feature and/or collateralization feature to mitigate
        risk for the seller. In addition to these value propositions, Affluence
        will also have a few novel features, such as, personal customizable
        gallery resulting in significant improvement in user interface and user
        experience.
      </DescStyled>
    </ColCenter>
  )
}
