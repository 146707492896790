export const TRANSACTION_TYPE = {
  TOPUP: "TOP_UP",
  PAY: "PAY",
  REDEEM_VOUCHER: "REDEEM_VOUCHER",
  BUY_BACK: "BUY_BACK",
  TRANSFER: "TRANSFER",
  TOP_UP_WITH_WALLETCONNECT: "TOP_UP_WITH_WALLETCONNECT",
  PAY_WITH_WALLETCONNECT: "PAY_WITH_WALLETCONNECT",
};

export const TRANSACTION_STATUS = {
  INIT: "INIT",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  EXPIRED: "EXPIRED",
};

export const ASSETS_NAME = {
  IBFX_TYPE_I: "IBFX_TYPE_I",
  IBFX_TYPE_II: "IBFX_TYPE_II",
  IBFX_TYPE_III: "IBFX_TYPE_III",
  IBFX_TYPE_IV: "IBFX_TYPE_IV",
  IBFS: "IBFS",
  IBFN: "IBFN",
};

export const ABILITIES_ASSETS = {
  CONVERT: "CONVERT",
  TRANSFER: "TRANSFER",
  TOPUP: "TOPUP",
  VOUCHER: "VOUCHER",
};

export const USER_STATUS = {
  INACTIVE: "INACTIVE",
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
};

export const ASSETS_STATUS = {
  INACTIVE: "INACTIVE",
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
};
