import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Progress,
  Switch,
  Row,
  Col,
  Alert,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  ButtonOutline,
  ColCenter,
  ColStart,
  InputNumberStyled,
  RowEnd,
  RowSpaceBetween,
  RowStart,
  RowCenter,
} from "../general_styled";

import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  DividerCard,
  LabelStyled,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import IBFxLogo from "../../assets/images/ibfx-icon.svg";
import CollateralIcon from "../../assets/images/collateral_icon.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import { setSection } from "../../modules/general/action";
import ReactCodeInput from "react-code-input";
import { decryptMnemonic, fromIBFx, toIBFx } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { getAllLoans, postProvideLoan } from "../../modules/loans/action";
import { LOAN_STATUS } from "../../constants/constant_loans";
import jwtDecode from "jwt-decode";
import CredenceOnBoarding from "../../pages/CredenceOnBoarding";

const { Title, Text } = Typography;

export default function ModalLoansApprove(props) {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestCollateral, setRequestCollateral] = useState(true);
  const [isMode, setIsMode] = useState(0);
  const [loanPercentage, setLoanPercentage] = useState(50);
  const [pinCode, setPinCode] = useState();
  const [isPinError, setPinError] = useState(false);
  const [isTokenMoreThanRequest, setIsTokenMoreThanRequest] = useState(false);
  const [userId, setUserId] = useState("");

  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );
  const credenceUser = useSelector((state) =>
    state.getIn(["credence", "user"]).toJS()
  );

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setPinCode();
    setPinError(false);
    setIsModalVisible(false);
  };

  const onSubmit = async (mnemonic) => {
    setIsLoading(true);

    await postProvideLoan(mnemonic, {
      loan_id: props.record?.loan_id,
      is_collateral_required: requestCollateral,
    });
    await dispatch(getAllLoans(userId, "open", 1, LOAN_STATUS.CREATED));

    setIsMode(1);
    // setIsLoading(false)
    // handleCancel()
  };

  const getMonthBetween = (startDate, endDate) => {
    var start = moment(startDate);
    var end = moment(endDate);
    var months = end.diff(start, "months");
    if (months <= 0) {
      const weeks = end.diff(start, "weeks");
      if (weeks <= 0) {
        const days = end.diff(start, "days");
        return days + " days";
      }
      return weeks + " weeks";
    }
    return months + " months";
  };

  const checkPin = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = await decryptMnemonic(getMnemonic, pinCode);
      setPinError(false);
      await onSubmit(_decryptMnemonic);
    } catch (e) {
      console.log(e);
      setPinError(true);
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const resolveSuccess = () => (
    <ColCenter className="h-100" span={24}>
      <Progress type="circle" percent={100} />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        Success!
      </TitleStyled>
      <TextStyled>Loan has been granted to the user.</TextStyled>
      <Button
        block
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  useEffect(() => {
    setUserId(jwtDecode(localStorage.getItem("USER_AUTH_TOKEN")).user_id);

    const ibfxI = allAssets.find((asset) => asset.asset_name === "IBFX_TYPE_I");
    if (ibfxI?.amount > props.record?.total_ibfx) {
      setIsTokenMoreThanRequest(true);
    } else {
      setIsTokenMoreThanRequest(false);
    }
  }, [props.record]);

  const resolveContent = () => {
    switch (isMode) {
      case 0:
        return (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Approve Loan
            </Title>
            <ColStart className="w-100 mt-24px">
              <RowSpaceBetween>
                <Text>Sent on</Text>
                <Text>
                  {moment(props.record?.created_at).format(
                    "DD/MM/YYYY HH:mm A"
                  )}
                </Text>
              </RowSpaceBetween>
              <DividerCard className="list" />
              <RowSpaceBetween>
                <Text>Duration</Text>
                <Text>
                  {getMonthBetween(
                    props.record?.created_at,
                    props.record?.deadline
                  )}
                </Text>
              </RowSpaceBetween>
              <DividerCard className="list" />
              <RowSpaceBetween>
                <Text className="text-w600">Loan Amount</Text>
                <RowEnd>
                  <img src={IBFxLogo} className="mr-6px" />
                  <Text className="text-w600">
                    {fromIBFx(props.record?.total_ibfx)}
                  </Text>
                </RowEnd>
              </RowSpaceBetween>
              <DividerCard className="list" />
              <RowSpaceBetween
              // className="mb-12px"
              >
                <RowStart>
                  <img src={CollateralIcon} alt="collateral" />
                  <ColStart className="ml-8px">
                    <Title
                      className="text-size-14 text-w500 mb-0px mt-0px"
                      level={5}
                      align="left"
                    >
                      Request Collateral
                    </Title>
                    <Text className="gray-5 text-size-12">
                      User will add collateral for escrow
                    </Text>
                  </ColStart>
                </RowStart>
                <Switch
                  defaultChecked={true}
                  onChange={(v) => setRequestCollateral(v)}
                />
              </RowSpaceBetween>
              {/* <InputNumberStyled
											type="number"
                addonAfter={
                  <Text italic>
                    {"$" +
                      (Math.round(
                        (loanPercentage / 100) *
                          fromIBFx(props.record?.total_ibfx)
                      ) || 0)}
                  </Text>
                }
                style={{ padding: "0px 0px 0px 12px" }}
                className="w-100"
                placeholder="Enter percentage"
                min={0}
                value={loanPercentage}
                readOnly
                // onChange={(value) => {
                //   setLoanPercentage(value)
                // }}
              /> */}
              <DividerCard />
              <Button
                block
                type="primary"
                disabled={!isTokenMoreThanRequest || loanPercentage === 0}
                style={{ height: 40, borderRadius: 4 }}
                onClick={() => setIsMode(2)}
              >
                {isLoading ? antIcon : "Confirm"}
              </Button>
              <ButtonOutline
                block
                style={{ height: 40, borderRadius: 4 }}
                className="mt-12px"
                onClick={() => dispatch(setSection("WalletFAQ"))}
              >
                Check FAQ
              </ButtonOutline>
            </ColStart>
          </ColCenter>
        );
      case 1:
        return resolveSuccess();
      case 2:
        return (
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              Enter pin
            </Title>
            <Text className="gray-7" align="center">
              Please enter your pin code to confirm the transaction
            </Text>
            <Row className="mt-24px">
              <Col span={24}>
                <RowCenter>
                  <ReactCodeInput
                    type="password"
                    onChange={(e) => setPinCode(e)}
                    fields={4}
                    {...codeProps}
                  />
                </RowCenter>
              </Col>
              {isPinError && (
                <Col className="mt-10px" span={24}>
                  <Alert
                    message="You have either entered a wrong pin, or you need to reverify your wallet. Please check and try again."
                    type="error"
                    showIcon
                  />
                </Col>
              )}
            </Row>
            <Button
              style={{
                height: 40,
                borderRadius: 4,
                width: "calc(100% - 40px)",
              }}
              className="mx-20px mt-20px"
              type="primary"
              onClick={() => checkPin()}
              disabled={isLoading}
            >
              {!isLoading ? "Confirm Pin" : antIcon}
            </Button>
          </ColCenter>
        );
    }
  };

  return (
    <>
      <a onClick={showModal}>Lend</a>
      {Object.keys(credenceUser).length <= 0 ||
      !localStorage.getItem("ENCRYPTED_MNEMONIC") ? (
        <Modal
          centered
          className="modal-top-up"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          closeIcon={<img src={closeIcon} onClick={handleCancel} />}
          maskStyle={{ backgroundColor: "#f3f6fa" }}
        >
          <CredenceOnBoarding />
        </Modal>
      ) : windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="500px"
          footer={null}
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {resolveContent()}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            {resolveContent()}
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
