import React, { useEffect } from "react";
import { Typography, Col, Row, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  // ASSETS_NAME,
  TRANSACTION_STATUS,
} from "../../constants/constant_wallet";
import { fromIBFx, titleCase } from "../../utils/utils";
import useWindowDimensions from "../../utils/windowDimensions";
import CardActivity from "../../components/CardActivity";
import { getRecentActivity } from "../../modules/general/action";

const { Title } = Typography;

export default function Activity() {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();
  const activitiesData = useSelector((state) =>
    state.getIn(["general", "activities", "data"]).toJS()
  );
  const activitiesCurrentPage = useSelector((state) =>
    state.getIn(["general", "activities", "currentPage"])
  );
  const activitiesTotal = useSelector((state) =>
    state.getIn(["general", "activities", "total"])
  );

  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );

  const dispatch = useDispatch();

  const onPageChange = (num) => {
    dispatch(getRecentActivity(num));
  };

  // const resolveAssetName = (name) => {
  //   switch (name) {
  //     case ASSETS_NAME.IBFS:
  //       return "IBFS";
  //     case ASSETS_NAME.IBFX_TYPE_I:
  //       return "IBFX";
  //     case ASSETS_NAME.IBFX_TYPE_II:
  //       return "IBFX - 2";
  //     case ASSETS_NAME.IBFX_TYPE_III:
  //       return "IBFX - 3";
  //     case ASSETS_NAME.IBFX_TYPE_IV:
  //       return "IBFX - 4";
  //   }
  // };

  const columns = [
    {
      title: t("activity-table-1"),
      dataIndex: "platform",
      key: "platform",
      render: (platform) => {
        return <div>{titleCase(platform.replace("_", " "))}</div>;
      },
    },
    {
      title: t("activity-table-2"),
      dataIndex: "event_type",
      key: "event_type",
      render: (type) => {
        return <div>{titleCase(type.replace(/_/g, " "))}</div>;
      },
    },
    {
      title: t("activity-table-3"),
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <>${(fromIBFx(amount) / ibfxPerUSD).toFixed(2)}</>,
    },
    {
      title: t("activity-table-4"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (dateTime) => (
        <>{moment(dateTime).format("DD/MM/YYYY HH:mm A")}</>
      ),
    },
    {
      title: t("activity-table-5"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        let color = "success";

        if (status === TRANSACTION_STATUS.INIT) {
          color = "blue";
        }
        if (status === TRANSACTION_STATUS.PROCESSING) {
          color = "warning";
        }
        if (status === TRANSACTION_STATUS.SUCCESS) {
          color = "success";
        }
        if (status === TRANSACTION_STATUS.FAILED) {
          color = "error";
        }
        if (status === TRANSACTION_STATUS.EXPIRED) {
          color = "volcano";
        }
        return (
          <>
            <Tag className="tag-custom" color={color}>
              {status.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getRecentActivity(1));
  }, []);

  return (
    <>
      <Row className="mt-10px">
        <Col span={24}>
          <Title level={3}>Activity</Title>
        </Col>
      </Row>
      {windowDimensions.width > 835 ? (
        <Row className="mt-20px">
          <Col span={24}>
            <Table
              dataSource={activitiesData}
              columns={columns}
              pagination={{
                onChange: onPageChange,
                current: activitiesCurrentPage,
                pageSize: 15,
                total: activitiesTotal,
              }}
            />
          </Col>
        </Row>
      ) : (
        <Row className="mt-10px">
          {activitiesData.map((e, i) => (
            <CardActivity key={i} data={e} />
          ))}
        </Row>
      )}
    </>
  );
}
