import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Col, Typography, Button, Image, Row, notification, Popover } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useHistory } from 'react-router'

import { Canvas, CustomRow, ImageLogin, Content, FormCustom, TextStyled, BlueLink } from './styled'
import mosqueImage from '../../assets/images/bg_landing.jpg'
import { recheckToken, userRegister } from '../../modules/auth/action'

import logoColor from '../../assets/images/logo-beta-black.png'
import { CheckboxStyled, ColStart, InputPasswordStyled, InputStyled, RowStart } from '../../components/general_styled'
import { extractErrorMessage } from '../../utils/extractErrorMessage'
import RemoveIcon from '../../assets/images/remove-red.svg'
import CheckIcon from '../../assets/images/check-green-small.svg'

const { Title, Text } = Typography

export default function Register() {
	const stateAuth = useSelector((state) => state.getIn(['auth']).toJS())
	const dispatch = useDispatch()
	const history = useHistory()

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [referralEmail, setReferralEmail] = useState()
	const [password, setPassword] = useState('')
	const [retypePassword, setRetypePassword] = useState('')
	const [check, setCheck] = useState(false)

	const [nameError, setNameError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [referralEmailError, setReferralEmailError] = useState(false)
	const [passwordError, setPasswordError] = useState(false)
	const [retypeError, setRetypeError] = useState(false)

	useEffect(() => {
		dispatch(recheckToken())
	}, [])

	const validateEmail = (isReferral = false) => {
		if (isReferral) {
			return String(referralEmail)
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
		}

		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
	}

	const resetError = () => {
		setNameError(false)
		setEmailError(false)
		setPasswordError(false)
		setRetypeError(false)
		setReferralEmailError(false)
	}

	const onRegisterClicked = async (e) => {
		resetError()

		if (email.length <= 0) {
			setEmailError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Email cannot be empty',
			})
		}
		if (!validateEmail()) {
			setEmailError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Invalid email',
			})
		}
		if (referralEmail && !validateEmail(true)) {
			setReferralEmailError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Invalid referral email',
			})
		}
		if (name.length <= 0) {
			setNameError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Name cannot be empty',
			})
		}
		if (password.length <= 0) {
			setPasswordError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Password cannot be empty',
			})
		}

		if (!/[a-zA-Z]/.test(password)) {
			setPasswordError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Password must include at least one uppercase and lowercase letter',
			})
		}
		if (!/\d/.test(password)) {
			setPasswordError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Password must include at least one numbers',
			})
		}
		if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
			setPasswordError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Password must include at least one special character',
			})
		}
		if (password.length < 8) {
			setPasswordError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Password must be at least 8 characters',
			})
		}

		if (retypePassword !== password) {
			setPasswordError(true)
			return notification.error({
				message: 'Failed!',
				description: 'Passwords are not the same',
			})
		}

		try {
			let dataRegister = {
				email,
				password,
				name,
			}

			if (referralEmail) {
				dataRegister.referral_email = referralEmail
			}

			const result = await dispatch(userRegister(dataRegister))

			if (result && result.code === 'SUCCESS') {
				notification.success({
					message: 'Success!',
					description: 'Registration Successful',
				})
				history.push('/verification/register')
			} else {
				notification.error({
					message: 'Failed!',
					description: result,
				})
			}
		} catch (e) {
			console.log(e)
			notification.error({
				message: 'Failed!',
				description: extractErrorMessage(e),
			})
		}
	}

	const onFieldChange = (e) => {
		resetError()
		if (e.target.name === 'email') {
			setEmail(e.target.value)
		} else if (e.target.name === 'referral_email') {
			setReferralEmail(e.target.value)
		} else if (e.target.name === 'name') {
			setName(e.target.value)
		} else if (e.target.name === 'retype-password') {
			setRetypePassword(e.target.value)
		} else if (e.target.name === 'password') {
			setPassword(e.target.value)
		}
	}

	const resolvePassword = () => {
		return [
			<RowStart className="align-start ">
				<img
					src={/[a-zA-Z]/.test(password) ? CheckIcon : RemoveIcon}
					style={{
						paddingRight: 10,
						paddingTop: 1,
					}}
				/>
				<ColStart>
					<Text className="text-size-12 text-w500">Add at least one uppercase & lowercase letter</Text>
					<Text className="text-size-12">Example - ABCDefgh</Text>
				</ColStart>
			</RowStart>,
			<RowStart className="align-start mt-4px">
				<img
					src={/\d/.test(password) ? CheckIcon : RemoveIcon}
					style={{
						paddingRight: 10,
						paddingTop: 1,
					}}
				/>
				<ColStart>
					<Text className="text-size-12 text-w500">Add at least one number</Text>
					<Text className="text-size-12">Example - 1234</Text>
				</ColStart>
			</RowStart>,
			<RowStart className="align-start mt-4px">
				<img
					src={/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password) ? CheckIcon : RemoveIcon}
					style={{
						paddingRight: 10,
						paddingTop: 1,
					}}
				/>
				<ColStart>
					<Text className="text-size-12 text-w500">Add at least one special character</Text>
					<Text className="text-size-12">Example - !@#$%^&*()/{'{}'}</Text>
				</ColStart>
			</RowStart>,
			<RowStart className="align-start mt-4px">
				<img
					src={password.length >= 8 ? CheckIcon : RemoveIcon}
					style={{
						paddingRight: 10,
						paddingTop: 1,
					}}
				/>
				<ColStart>
					<Text className="text-size-12 text-w500">Must be at least 8 characters</Text>
					<Text className="text-size-12">Example - Passw0rd$</Text>
				</ColStart>
			</RowStart>,
		]
	}

	const resolveUI = () => {
		if (stateAuth.isAuthorized) {
			return <Redirect to="/" />
		}

		return (
			<Canvas>
				<CustomRow>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
						<ImageLogin img={mosqueImage} />
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
						<Content>
							<Image src={logoColor} width={204} style={{ marginBottom: '36px' }} preview={false} />
							<Title level={2} style={{ marginBottom: 0 }}>
								Register
							</Title>
							<Text>Enter your details to create your new account</Text>
							<FormCustom>
								<InputStyled
									name="name"
									className={nameError && 'danger'}
									placeholder="Name"
									size="large"
									onChange={onFieldChange}
								/>
								<InputStyled
									name="email"
									placeholder="Email"
									className={emailError && 'danger'}
									size="large"
									onChange={onFieldChange}
									style={{ marginTop: 10 }}
								/>
								<InputStyled
									name="referral_email"
									placeholder="Referral Email (Optional)"
									className={referralEmailError && 'danger'}
									size="large"
									onChange={onFieldChange}
									style={{ marginTop: 10 }}
								/>

								<Popover
									placement="bottomLeft"
									title={'Password must contain'}
									content={
										<div style={{ minWidth: 250 }}>
											<div>{resolvePassword()}</div>
										</div>
									}
									trigger="focus"
								>
									<InputPasswordStyled
										name="password"
										size="large"
										placeholder="Password"
										className={passwordError && 'danger'}
										onChange={onFieldChange}
										iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
										style={{ marginTop: 10 }}
									/>
								</Popover>

								<InputPasswordStyled
									name="retype-password"
									size="large"
									placeholder="Re-enter Password"
									className={(password !== retypePassword || retypeError) && 'danger'}
									onChange={onFieldChange}
									iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
									style={{ marginTop: 10, marginBottom: '24px' }}
								/>
								<Row
									style={{
										width: '100%',
										justifyContent: 'space-between',
									}}
								>
									<CheckboxStyled value={check} onChange={(e) => setCheck(e.target.checked)}>
										I Accept Terms & Conditions & Privacy Policy
									</CheckboxStyled>
								</Row>
								<Button
									type="primary"
									block
									disabled={
										!check
										// email !== "" &&
										// password !== "" &&
										// name !== "" &&
										// retypePassword !== "" &&
										// password === retypePassword &&
									}
									style={{ marginTop: 24, marginBottom: 36 }}
									size="large"
									onClick={onRegisterClicked}
								>
									Create Account
								</Button>
								<TextStyled>Already have an IBFNex - Netverse Account?</TextStyled>
								<BlueLink to="/login">Go Back to Login</BlueLink>
							</FormCustom>
						</Content>
					</Col>
				</CustomRow>
			</Canvas>
		)
	}

	return resolveUI()
}
