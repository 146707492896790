import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Landing from "./pages/Landing";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Verification from "./pages/Verification";
import NewPassword from "./pages/NewPassword";
import Register from "./pages/Register";

// import ExamplePolygon from "./pages/Login/example";

export const Routes = () => (
  <BrowserRouter>
    {/* <Route path="/example-polygon" exact component={ExamplePolygon} /> */}
    <Route path="/login" exact component={Login} />
    <Route path="/login/:redirect" exact component={Login} />
    <Route path="/login-callback**" exact component={Login} />
    <Route path="/register" exact component={Register} />
    <Route path="/forgot-password" exact component={ForgotPassword} />
    <Route path="/verification/:type" exact component={Verification} />
    <Route path="/new-password" exact component={NewPassword} />
    <Route path="/" exact render={() => <Landing section={"home"} />} />
    <Route path="/home" exact render={() => <Landing section={"home"} />} />
    <Route
      path="/dashboard"
      exact
      render={() => <Landing section={"home"} />}
    />
    <Route
      path="/activity"
      exact
      render={() => <Landing section={"activity"} />}
    />
    <Route
      path="/credence"
      exact
      render={() => <Landing section={"credence"} />}
    />
    <Route
      path="/credence/:section"
      exact
      render={() => <Landing section={"credence"} />}
    />
    <Route
      path="/credence-nft-detail/:id"
      exact
      render={() => <Landing section={"credence-nft-detail"} />}
    />
    <Route path="/feed" exact render={() => <Landing section={"feed"} />} />
    <Route
      path="/profile"
      exact
      render={() => <Landing section={"profile"} />}
    />
    <Route
      path="/edit-profile"
      exact
      render={() => <Landing section={"edit-profile"} />}
    />
    <Route path="/donate" exact render={() => <Landing section={"donate"} />} />
    <Route
      path="/project-detail/:id"
      exact
      render={() => <Landing section={"project-detail"} />}
    />
    {/* <Route
      path="/conference"
      exact
      render={() => <Landing section={"conference"} />}
    /> */}
    {/* <Route path="/irshad" exact render={() => <Landing section={"irshad"} />} /> */}
    <Route
      path="/marketplace"
      exact
      render={() => <Landing section={"marketplace"} />}
    />
    <Route
      path="/benevolence"
      exact
      render={() => <Landing section={"benevolence-donate"} />}
    />
    <Route
      path="/benevolence-donate"
      exact
      render={() => <Landing section={"benevolence-donate"} />}
    />
    <Route
      path="/benevolence-volunteer"
      exact
      render={() => <Landing section={"benevolence-volunteer"} />}
    />
    {/* <Route
      path="/confluence-dashboard"
      exact
      render={() => <Landing section={"confluence-dashboard"} />}
    />
    <Route
      path="/confluence-claim"
      exact
      render={() => <Landing section={"confluence-claim"} />}
    />
    <Route
      path="/confluence-profiles"
      exact
      render={() => <Landing section={"confluence-profiles"} />}
    />
    <Route
      path="/confluence-offers"
      exact
      render={() => <Landing section={"confluence-offers"} />}
    /> */}
    <Route
      path="/feed-details/:announcement_id"
      exact
      render={() => <Landing section={"feed-details"} />}
    />
    <Route
      path="/wallet-dashboard"
      exact
      render={() => <Landing section={"wallet-dashboard"} />}
    />
    <Route
      path="/wallet-transactions"
      exact
      render={() => <Landing section={"wallet-transactions"} />}
    />
    <Route
      path="/wallet-faq"
      exact
      render={() => <Landing section={"wallet-faq"} />}
    />
    <Route
      path="/benevolence-manage"
      exact
      render={() => <Landing section={"benevolence-manage"} />}
    />
   <Route
      path="/courses"
      exact
      render={() => <Landing section={"courses"} />}
    />
    {/*  <Route
      path="/course-detail/:id"
      exact
      render={() => <Landing section={"course-detail"} />}
    /> */}
    {/* <Route
      path="/benevolence-waqf-pool"
      exact
      render={() => <Landing section={"benevolence-waqf-pool"} />}
    /> */}
    <Route
      path="/affluence"
      exact
      render={() => <Landing section={"affluence-buy-sell-marketplace"} />}
    />
    <Route
      path="/affluence-auction-marketplace"
      exact
      render={() => <Landing section={"affluence-auction-marketplace"} />}
    />
    <Route
      path="/affluence-auction"
      exact
      render={() => <Landing section={"affluence-auction-marketplace"} />}
    />
    <Route
      path="/affluence-buy-sell"
      exact
      render={() => <Landing section={"affluence-buy-sell-marketplace"} />}
    />
    <Route
      path="/affluence-buy-sell-purchases"
      exact
      render={() => <Landing section={"affluence-buy-sell-purchases"} />}
    />
    <Route
      path="/affluence-buy-sell-listing"
      exact
      render={() => <Landing section={"affluence-buy-sell-listing"} />}
    />
    <Route
      path="/affluence-buy-sell-marketplace"
      exact
      render={() => <Landing section={"affluence-buy-sell-marketplace"} />}
    />
    <Route
      path="/affluence-buy-sell-settlement"
      exact
      render={() => <Landing section={"affluence-buy-sell-settlement"} />}
    />
    <Route
      path="/affluence-buy-sell-detail-nft/:id"
      exact
      render={() => <Landing section={"affluence-buy-sell-detail-nft"} />}
    />
    <Route
      path="/affluence-auction-detail-nft/:id"
      exact
      render={() => <Landing section={"affluence-auction-detail-nft"} />}
    />
    <Route
      path="/affluence-auction-purchases"
      exact
      render={() => <Landing section={"affluence-auction-purchases"} />}
    />
    <Route
      path="/affluence-auction-offers"
      exact
      render={() => <Landing section={"affluence-auction-offers"} />}
    />
    <Route
      path="/affluence-auction-listing"
      exact
      render={() => <Landing section={"affluence-auction-listing"} />}
    />
    <Route
      path="/affluence-auction-listing"
      exact
      render={() => <Landing section={"affluence-auction-listing"} />}
    />
    <Route
      path="/affluence-microfinance-myloans"
      exact
      render={() => <Landing section={"affluence-microfinance-myloans"} />}
    />
    <Route
      path="/affluence-microfinance-loan-requests"
      exact
      render={() => (
        <Landing section={"affluence-microfinance-loan-requests"} />
      )}
    />
    <Route
      path="/affluence-user/:user_id"
      exact
      render={() => <Landing section={"affluence-user"} />}
    />
    <Route
      path="/affluence-collection/:id"
      exact
      render={() => <Landing section={"affluence-collection"} />}
    />
    <Route
      path="/create-nft"
      exact
      render={() => <Landing section={"create-nft"} />}
    />
    <Route
      path="/credence-nft-sub-detail/:id"
      exact
      render={() => <Landing section={"credence-nft-sub-detail"} />}
    />
  </BrowserRouter>
);
