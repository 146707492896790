import styled from "@emotion/styled";
import { Typography, Card, Button } from "antd";

const { Text } = Typography;

export const DollarText = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #44567b;
`;

export const UserImgStyled = styled.img`
  width: ${(props) => (props.mail ? "21px" : "14px")};
  height: ${(props) => (props.mail ? "21px" : "14px")};
`;

export const TotalUserText = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 1px;

  /* Text/200 */

  color: #526895;
`;

export const DollarTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

export const DollarText1 = styled(Text)`
  font-style: normal;
  font-size: 13px;
  line-height: 20px;

  color: #44567b;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProjectTitle = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #273248;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const RowSpaceBetweenBene = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const CardStyled = styled(Card)`
  border-radius: 10px 10px 0 0;
  position: relative;
  height: 365px;
  min-width: 320px;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  .ant-card-cover {
    height: 200px;
    object-fit: cover;
  }

  .ant-card-cover img {
    border-radius: 10px 10px 0 0;
    height: 200px;
    object-fit: cover;
  }

  .time-left {
    background: #fcfcfc;

    border: 1px solid #d6dae1;
    box-sizing: border-box;
    border-radius: 4px;

    display: flex;
    flex-direction: row;

    position: absolute;
    top: 0.8rem;
    right: 0.8rem;

    padding: 5px 8px;

    /* Caption/Semibold */

    // font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 150% */

    /* Secondary Gray */

    color: #3d424d;

    img {
      margin-right: 5px;
      height: 1.4rem;
      width: 1.4rem;
    }
  }

  .ant-card-cover {
    height: 170px;

    img {
      object-fit: cover;
      height: 100%;
      cursor: pointer;
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px 0px 20px;

    .start {
      margin-bottom: auto;
    }
  }

  .ant-card-actions {
    border: none;
    padding: 0;
    margin: 0;
  }

  .ant-card-actions > li {
    margin: 0;
  }

  .ant-progress-inner {
    margin-bottom: -3%;
  }
`;

export const DonateButton = styled(Button)`
  height: 40px;
  border-radius: 100px;
  background: #000;
  border-color: #000;

  &.funded {
    background: #cbcbcb;
    border-color: #b7b7b7;
    color: #696969
  }
`;
