import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
	fallbackLng: 'en',
	lng: 'en',
	resources: {
		en: {
			translations: require('./locales/en/translations.json'),
		},
		id: {
			translations: require('./locales/id/translations.json'),
		},
	},
	ns: ['translations'],
	defaultNS: 'translations',
})

i18n.languages = ['en', 'id']

export default i18n
