import styled from "@emotion/styled";
import { Typography } from "antd";
import { InputNumberStyled } from "../general_styled";

const { Text } = Typography;

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;

  .ant-upload.ant-upload-select {
    display: block;
  }
`;

export const TitleStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;
`;

export const TextStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* or 167% */

  text-align: center;

  /* Gray/gray-7 */

  color: #273248;
  max-width: 350px;
`;

export const InputNumberReStyled = styled(InputNumberStyled)`
  height: 40px !important;

  input {
    height: 32px !important;
  }
`;
