import styled from "@emotion/styled";
import { Tabs, Button } from "antd";

export const ContentChart = styled.div`
  padding-top: 50px;
`;

export const TabsStyled = styled(Tabs)`
  // @media screen and (max-width: 834px) {
  // .ant-tabs-nav-wrap {
  // position: fixed;
  // overflow: auto;
  // top: 75px;
  // left: 20px;
  // right: 20px;
  // bottom: 0;
  // }
`;

export const ContentRelative = styled.div`
  position: relative;
`;

export const ButtonAbsolute = styled(Button)`
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 0;
  width: 150px;
  background-color: #024FF1;

  border-radius: 2px;
  color: #fcfcfc;
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 500px) {
    position: relative;
    width: 100%;
    margin-bottom: 24px;
  }
`;
