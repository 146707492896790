export const PROJECT_STATUS = {
  SUBMITED: 'SUBMITED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}

export const IMPACTS_STATUS = {
  INIT: 'INIT',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  CANCELLED: 'CANCELLED',
  RETURNED: 'RETURNED',
  FAILED: 'FAILED',
  COUNTER_OFFER: 'COUNTER_OFFER',
  COUNTER_OFFER_PENDING: 'COUNTER_OFFER_PENDING',
  COUNTER_OFFER_CONFIRMED: 'COUNTER_OFFER_CONFIRMED',
}

export const TRANSACTION_STATUS = {
  INIT: 'INIT',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  BID_ACCEPTED: 'BID_ACCEPTED',
  BID_ACCEPT_PENDING: 'BID_ACCEPT_PENDING',
  BID_ACCEPT_FAILED: 'BID_ACCEPT_FAILED',
  OUTBID: 'OUTBID',
  FAILED: 'FAILED'
}