import styled from "@emotion/styled";
import { Typography, Tabs, Card } from "antd";

const { Paragraph } = Typography;

export const TabsStyled = styled(Tabs)`
  .ant-tabs-tab .ant-tabs-tab-btn {
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: -5px;
    font-size: 14px;
  }

  .ant-tabs-tab-active {
    border-bottom: 2px solid #024FF1;
  }

  padding: 0 10px;
  cursor: pointer;
`;

export const SideCardStyled = styled(Card)`
  height: 100%;
  width: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
  box-shadow: 0px 2px 2px 0px #d4d5d966;

  .text-campaign {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }

  .ant-card-actions {
    position: absolute;
    bottom: 0;
    padding: 12px 20px 0 20px;
    width: 100%;
  }

  .ant-card-body {
    height: 100%;
  }
`;

export const RecentContainer = styled(Card)`
  margin: 0 24px;
  box-shadow: 0px 2px 2px 0px #d4d5d966;

  .item {
    min-width: 140px;
    display: flex;
    flex-direction: column;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }

  .time {
    //styleName: Caption/Semibold;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
  }

  .name {
    //styleName: Caption/Regular;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .country {
    color: #a3b6cc;
  }
`;

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .subtitle {
    font-size: 10px;
    font-weight: 400;
    padding-top: 5px;
    // border-bottom: 1px solid #000;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const NgoTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
`;

export const NgoSubtitle = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
`;

export const Media = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: #4f6683;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 2px 4px;
`;

export const UnlockableContent = styled.div`
  display: flex;

  padding: 4px 8px;

  background: ${(props) => (props.verified ? "#09da9326" : "#dee2e7")};
  /* Secondary/300 */

  border: ${(props) =>
    props.verified ? "1px solid #006f49" : "1px solid #6b7c94"};
  box-sizing: border-box;
  border-radius: 4px;

  /* Footer/ All Caps */

  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  /* identical to box height, or 140% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Secondary/600 */

  color: ${(props) => (props.verified ? "#006f49" : "#2f3742")};
  display: flex;
  align-items: center;
`;

// export const MainCard = styled(Card)`
//   margin: 0px 24px 24px 0px;
//   border-radius: 4px;

//   // width: 100%;
//   min-width: 248px;
//   // height: 648px;

//   @media screen and (min-width: 1200px) {
//     .ant-image-img {
//       // height: 296px;
//       max-height: 50vh;
//       object-fit: cover;
//     }
//   }

//   @media screen and (min-width: 1800px) {
//     .ant-image-img {
//       // height: 296px;
//       max-height: 30vh;
//       object-fit: cover;
//     }
//   }
// `

// export const CardTitle = styled(Title)`
//   font-style: normal;
//   font-weight: 600 !important;
//   font-size: 18px !important;
//   line-height: 22px !important;

//   color: #273248 !important;

//   margin: 0 !important;
//   margin-top: 16px !important;
//   margin-bottom: 8px !important;
// `

// export const OverviewText = styled(Text)`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 13px;
//   line-height: 20px;
//   overflow: hidden;
//   text-overflow: ellipsis;

//   color: #44567b;
// `

// export const GreenText = styled(Text)`
//   font-style: normal;
//   font-weight: 600;
//   font-size: 13px;
//   line-height: 15px;

//   letter-spacing: 1px;

//   color: #38cb89;
// `

export const Pic = styled.img`
  box-sizing: border-box;
  width: 100%;
  aspect-ratio: 1.9 / 1;
  object-fit: fill;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0px 2px 2px 0px #d4d5d966;
`;

export const CardTitle = styled.p`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height, or 117% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text/300 */

  color: #44567b;
`;

export const DollarText = styled.p`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/300 */

  color: #44567b;
`;
export const DollarText1 = styled.p`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/400 */

  color: #273248;
  margin: 12px 0px;
`;
export const Para1 = styled(Paragraph)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/400 */

  color: #273248;
`;

export const SdgImg = styled.img`
  margin-top: ${(props) => (props.isMobile ? "0" : "12px")};
  margin-right: ${(props) => (props.isMobile ? "0" : "12px")};
  margin-left: ${(props) => (props.isMobile ? "12px" : "0")};
  width: 36px;
  height: 36px;
`;