import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Typography, Row, Input, Drawer, Image, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { ButtonStyled, ColCenter, RowCenter } from "../general_styled";

import { ContentStyled } from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import { ASSETS_NAME } from "../../constants/constant_wallet";
import emptyNfts from "../../assets/images/empty_nfts.svg";
import { membershipBasicAuth, MEMBERSHIP_HOST, IS_EVM } from "../../configs";
import CardNFT from "../CardNFT";
import { openModalLoginRegister } from "../../modules/general/action";
import { useAccount } from "wagmi";
import { getCredenceNFTSub } from "../../modules/nft_sub/action";
import { getCredenceEvmNFTByAddress } from "../../modules/credence/action";
import ShimmerCustom from "../ShimmerCustom";
import {
  EVM_TRANSACTION_STATUS,
  NFT_STATUS,
  TRANSACTION_STATUS,
} from "../../constants/constant_credence";

const { Title, Text } = Typography;

const { TextArea } = Input;

export default function ModalAffluenceSellNft(props) {
  const { t } = useTranslation();
  const { isConnected, address } = useAccount();
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMode, setIsMode] = useState(0);

  const nftsVault = useSelector((state) =>
    state.getIn(["credence", "nfts", "nftsVault"]).toJS()
  );
  const nftSub = useSelector((state) => state.getIn(["nftSub", "nfts"]).toJS());
  const credenceData = useSelector((state) =>
    state.getIn(["credence", "nfts", "data"]).toJS()
  );

  const showModal = () => {
    if (!localStorage.getItem("USER_AUTH_TOKEN")) {
      return dispatch(openModalLoginRegister());
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsModalVisible(false);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const initData = async () => {
    setIsLoading(true);
    if (IS_EVM) {
      if (isConnected) {
        await dispatch(getCredenceNFTSub(address));
        await dispatch(
          getCredenceEvmNFTByAddress(address, 1, null, NFT_STATUS.CREATED)
        );
      }
    }
    setIsLoading(false);
  };

  const renderEmpty = (img, title, subtitle) => {
    return (
      <ColCenter
        className="w-100"
        style={{ height: "100%", minHeight: "calc(100vh - 180px)" }}
      >
        <Image preview={false} src={img} />
        <Title align="center" className="mb-0px mt-24px" level={3}>
          {title}
        </Title>
        <Text style={{ textAlign: "center" }}>{subtitle}</Text>
      </ColCenter>
    );
  };

  const resolveShimmer = () => {
    let children = [];
    for (let i = 0; i < 9; i++) {
      children.push(
        <Col
          key={i}
          xs={24}
          sm={24}
          md={windowDimensions.width > 925 ? 12 : 24}
          lg={12}
          xl={8}
          xxl={6}
        >
          <ShimmerCustom width="100%" height="322px" borderRadius="2px" />
          <ShimmerCustom
            width="80%"
            height="20px"
            borderRadius="2px"
            className="mt-12px"
          />
          <ShimmerCustom width="60%" height="12px" borderRadius="2px" />
        </Col>
      );
    }

    return children;
  };

  const renderVault = () => {
    let usedData = nftsVault;
    if (IS_EVM) {
      const filteredCredence = credenceData.filter(
        (i) => !i?.NFT?.find((o) => o.status === EVM_TRANSACTION_STATUS.ON_SALE)
      );
      usedData = [...nftSub.data, ...filteredCredence];
    }
    return isLoading ? (
      <Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
        {resolveShimmer()}
      </Row>
    ) : usedData?.length > 0 ? (
      <Row style={{ paddingTop: 15 }}>
        {usedData?.map((nft) => {
          const metadata = JSON.parse(
            nft?.metadata_json || nft?.subs?.metadata_json
          );
          return (
            <CardNFT
              key={nft.nft_id}
              nftId={nft.nft_id}
              name={metadata.name}
              collectionName={metadata.collection.name}
              ipfsHash={metadata.image.replace("ipfs://", "")}
              is_pdf={metadata.is_pdf || false}
              data={{ ...nft, metadata, ...nft?.subs }}
              isVault={true}
              toSub={IS_EVM && nft?.token_id ? true : false}
            />
          );
        })}
      </Row>
    ) : (
      renderEmpty(
        emptyNfts,
        "No NFTs Found",
        "Connect your wallet or Create a new NFT"
      )
    );
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <ButtonStyled
        type={"primary"}
        size="large"
        block={props.fullWidth}
        style={
          !props.fullWidth
            ? {
                width: 200,
              }
            : {}
        }
        onClick={() => showModal()}
      >
        {t("sell_nft")}
      </ButtonStyled>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          className="modal-sell-nft"
          width="100%"
          maskStyle={{ backgroundColor: "#fafafa" }}
          footer={null}
        >
          <ContentStyled>
            <ColCenter className="w-100">
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                {t("sell_nft")}
              </Title>
              <Text className="gray-7" align="center">
                {t("sell_nft_desc")}
              </Text>
              <Row style={{ paddingTop: 15 }} className="w-100">
                {renderVault()}
              </Row>
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                {t("sell_nft")}
              </Title>
              <Text className="gray-7 mt-5px" align="center">
                {t("sell_nft_desc")}
              </Text>
              <Row style={{ paddingTop: 15 }} className="w-100">
                {renderVault()}
              </Row>
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
