import styled from "@emotion/styled";
import { Card, Typography } from "antd";

export const CardStyled = styled(Card)`
  margin: 12px;
  border-radius: 10px;
  // min-width: 300px;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  border: none !important;

  .ant-card-body {
    padding: 20px;
  }
`;

export const BalanceSection = styled.div`
  width: 100%;
  background: #f7f8fa;
  border-radius: 4px;
  padding: 8px 0;
  margin-top: 20px;
`;

export const DividerCard = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const IconCoins = styled.img`
  height: 20px;
  margin-top: 3px;
  margin-right: 8px;
`;

export const LearnMoreButton = styled(Typography.Text)`
  cursor: pointer;
`;

export const LabelStyled = styled(Typography.Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  /* Secondary/300 */

  color: #6b7c94;

  margin-bottom: 4px;
`;
export const ValueStyled = styled(Typography.Text)`
  // font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  /* Text/400 */

  color: #273248;
`;
