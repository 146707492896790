import styled from "@emotion/styled";
import { Drawer } from "antd";

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .ant-upload.ant-upload-select {
    display: block;
  }
`;

export const ContentCardStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 4px;

  &.selected {
    /* Shades/White */

    background: #ffffff;
    /* Neutral/600 */

    border: 1px solid #d6dae1;
    box-sizing: border-box;
    /* Card Elevation */

    // box-shadow: 0px 0px 30px 4px rgba(10, 29, 80, 0.15);
    border-radius: 6px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.checked {
    background: #d6dae1;
  }

  .check {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .title {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-align: left;
  }
`;

export const ContentPaymentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  cursor: pointer;

  border: 1px solid #d6dae1;
  box-sizing: border-box;
  border-radius: 4px;
  background: #d6dae130;

  transition: all 0.2s ease-in-out;

  &.selected {
    background: #d6dae1;
  }

  .title {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .payment-title-m {
    font-size: 10px;
    // font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
  }
`;

export const TitleStyled = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;

  &.selected {
    color: #364559;
  }
`;

export const SubtitleStyled = styled.div`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #5b7697;
  text-align: center;

  &.selected {
    color: #364559;
  }
`;

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-body {
    overflow-y: scroll;
  }
`;
