import { Col, Image, Typography, Row, Tabs, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardNFT from "../../components/CardNFT";
import { ReloadOutlined } from "@ant-design/icons";
import {
  ColCenter,
  ColStart,
  RowCenter,
} from "../../components/general_styled";
import ModalCreateNFT from "../../components/ModalCreateNFT";
import {
  getCredenceCollectionUserById,
  getCredenceEvmCollectionUserByAddress,
  getCredenceEvmNFTByAddress,
  getCredenceNFTByUserId,
  getCredencePercentage,
  getEvmNftOnsale,
  getEvmNftPurchased,
  getNftOnsale,
  getNftPurchased,
} from "../../modules/credence/action";
import CredenceApprovals from "../CredenceApprovals";
import { RowEnd, SearchStyled, TabsStyled } from "./styled";
import emptyNfts from "../../assets/images/empty_nfts.svg";
import credenceAbout from "../../assets/images/credence-about.svg";
import emptyNftsSale from "../../assets/images/empty_nfts_sale.svg";
import emptyNftsPurchase from "../../assets/images/empty_nfts_purchase.svg";
import ShimmerCustom from "../../components/ShimmerCustom";
import useWindowDimensions from "../../utils/windowDimensions";
import { setPageMenu, setSection } from "../../modules/general/action";
import SEO from "../../components/SEO";
import { ButtonStyled } from "../CredenceOnBoarding/styled";
import { getCredenceNFTSub } from "../../modules/nft_sub/action";
import { useAccount } from "wagmi";
import { IS_EVM } from "../../configs";
import { EVM_TRANSACTION_STATUS } from "../../constants/constant_credence";

const { TabPane } = Tabs;
const { Title, Text } = Typography;
export default function Credence() {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const { isConnected, address } = useAccount();

  const pageMenu = useSelector((state) => state.getIn(["general", "pageMenu"]));
  const param = useSelector((state) => state.getIn(["general", "param"]));
  const nfts = useSelector((state) =>
    state.getIn(["credence", "nfts", "data"]).toJS()
  );
  const nftsVault = useSelector((state) =>
    state.getIn(["credence", "nfts", "nftsVault"]).toJS()
  );
  const nftsOnsale = useSelector((state) =>
    state.getIn(["credence", "onsale"]).toJS()
  );
  const nftsPurchased = useSelector((state) =>
    state.getIn(["credence", "purchased"]).toJS()
  );
  const page = useSelector((state) =>
    state.getIn(["credence", "nfts", "currentPage"])
  );
  const nftMaxPercentage = useSelector((state) =>
    state.getIn(["credence", "nftMaxPercentage"])
  );
  const nftSub = useSelector((state) => state.getIn(["nftSub", "nfts"]).toJS());

  const [menu, setMenu] = useState("about");
  const [isLoading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState(null);

  const dispatch = useDispatch();

  const initData = async () => {
    if (!nftMaxPercentage) {
      dispatch(getCredencePercentage());
    }

    if (IS_EVM) {
      if (isConnected) {
        dispatch(getCredenceNFTSub(address));
        dispatch(
          getEvmNftOnsale({
            sellerAddress: address,
            status: EVM_TRANSACTION_STATUS.ON_SALE,
          })
        );
        dispatch(getEvmNftPurchased({ buyerAddress: address }));
        dispatch(getCredenceEvmCollectionUserByAddress(address));
        dispatch(getCredenceEvmNFTByAddress(address, page));
      }
    } else {
      dispatch(getCredenceNFTByUserId(null, page));
      dispatch(getNftOnsale());
      dispatch(getNftPurchased());
      dispatch(getCredenceCollectionUserById());
    }
    setLoading(false);
  };

  const onRefresh = async () => {
    setLoading(true);
    if (IS_EVM) {
      if (isConnected) {
        dispatch(getCredenceEvmNFTByAddress(address, page));
      }
    } else {
      await dispatch(getCredenceNFTByUserId(null, page));
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
    setMenu(param || "about");
    if (pageMenu) {
      setMenu(pageMenu);
    }
  }, []);

  useEffect(() => {
    if (address) {
      initData();
    }
  }, [isConnected]);

  const resolveShimmer = () => {
    let children = [];
    for (let i = 0; i < 9; i++) {
      children.push(
        <Col
          key={i}
          xs={24}
          sm={24}
          md={windowDimensions.width > 925 ? 12 : 24}
          lg={12}
          xl={8}
          xxl={6}
        >
          <ShimmerCustom width="100%" height="322px" borderRadius="2px" />
          <ShimmerCustom
            width="80%"
            height="20px"
            borderRadius="2px"
            className="mt-12px"
          />
          <ShimmerCustom width="60%" height="12px" borderRadius="2px" />
        </Col>
      );
    }

    return children;
  };

  const renderSearchBar = () => {
    return (
      <RowEnd>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col span={menu === "vault" && nfts?.length <= 0 ? 24 : 14}>
            <SearchStyled
              className="search-input"
              placeholder="Search"
              autoComplete="off"
              allowClear
              size="large"
              onSearch={(value) => setSearchVal(value)}
              style={{
                marginRight: "12px",
              }}
            />
          </Col>

          {menu === "vault" && nfts?.length <= 0 ? (
            <></>
          ) : (
            <Col offset={1} span={9}>
              {IS_EVM ? (
                <ButtonStyled
                  style={{ marginTop: 0 }}
                  type="primary"
                  size="large"
                  onClick={() => dispatch(setSection("create-nft"))}
                >
                  {t("create_nft")}
                </ButtonStyled>
              ) : (
                <ModalCreateNFT toApproval={() => setMenu("approvals")} />
              )}
            </Col>
          )}
        </Row>
        {menu === "approvals" && (
          <Button
            onClick={() => onRefresh()}
            type="primary"
            ghost
            className="ml-12px"
            icon={<ReloadOutlined />}
            style={{ minHeight: "40px", minWidth: "40px" }}
            size={22}
          />
        )}
      </RowEnd>
    );
  };

  const renderEmpty = (img, title, subtitle) => {
    return (
      <ColCenter style={{ height: "100%", minHeight: "calc(100vh - 180px)" }}>
        <Image preview={false} src={img} />
        <Title align="center" className="mb-0px mt-24px" level={3}>
          {title}
        </Title>
        <Text style={{ width: "37%", textAlign: "center" }}>{subtitle}</Text>
        {menu === "vault" && (
          <ColCenter className="mt-12px">
            {IS_EVM ? (
              <ButtonStyled
                type={"primary"}
                onClick={() => dispatch(setSection("create-nft"))}
              >
                {t("create_nft")}
              </ButtonStyled>
            ) : (
              <ModalCreateNFT toApproval={() => setMenu("approvals")} />
            )}
          </ColCenter>
        )}
      </ColCenter>
    );
  };

  const renderVault = () => {
    const usedData = IS_EVM ? nftSub?.data : nftsVault;
    return isLoading ? (
      <Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
        {resolveShimmer()}
      </Row>
    ) : usedData?.length > 0 ? (
      <Row style={{ paddingTop: 15 }}>
        {usedData?.map((nft) => {
          const metadata = JSON.parse(
            nft?.metadata_json || nft?.subs?.metadata_json
          );
          return (
            <CardNFT
              key={nft.nft_id}
              nftId={nft.nft_id}
              name={metadata.name}
              collectionName={metadata.collection.name}
              ipfsHash={metadata.image.replace("ipfs://", "")}
              is_pdf={metadata.is_pdf || false}
              data={{ ...nft, metadata, ...nft?.subs }}
              isVault={true}
              toSub={IS_EVM ? true : false}
            />
          );
        })}
      </Row>
    ) : (
      renderEmpty(
        emptyNfts,
        "No NFTs Found",
        "Connect your wallet or Create a new NFT"
      )
    );
  };

  const renderOnsale = () => {
    return isLoading ? (
      <Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
        {resolveShimmer()}
      </Row>
    ) : nftsOnsale?.length > 0 ? (
      <Row style={{ paddingTop: 15 }}>
        {IS_EVM
          ? nftsOnsale?.map((nft) => {
              const metadata = JSON.parse(nft?.NFT.metadata_json);
              return (
                <CardNFT
                  key={nft?.NFT?.nft_id}
                  nftId={nft?.NFT?.nft_id}
                  sellEscrowId={nft?.trx_id}
                  auctionId={null}
                  name={metadata.name}
                  collectionName={metadata.collection.name}
                  ipfsHash={metadata.image.replace("ipfs://", "")}
                  is_pdf={metadata.is_pdf || false}
                  data={{ ...nft, metadata, ...nft?.NFT }}
                />
              );
            })
          : nftsOnsale?.map((nft) => {
              const metadata = JSON.parse(nft.metadata_json);
              return (
                <CardNFT
                  key={nft.nft_id}
                  nftId={nft.nft_id}
                  sellEscrowId={
                    nft.SellEscrows && nft.SellEscrows[0].sell_escrow_id
                  }
                  auctionId={nft.Auctions && nft.Auctions[0].auction_id}
                  name={metadata.name}
                  collectionName={metadata.collection.name}
                  ipfsHash={metadata.image.replace("ipfs://", "")}
                  is_pdf={metadata.is_pdf || false}
                  // type={nft.type}
                  data={{ ...nft, metadata }}
                />
              );
            })}
      </Row>
    ) : (
      renderEmpty(
        emptyNftsSale,
        "No NFTs Found",
        "Sell a NFT and it will show up here"
      )
    );
  };

  const renderPurchased = () => {
    return isLoading ? (
      <Row style={{ paddingTop: 15 }} gutter={[16, 16]}>
        {resolveShimmer()}
      </Row>
    ) : nftsPurchased?.length > 0 ? (
      <Row style={{ paddingTop: 15 }}>
        {IS_EVM
          ? nftsPurchased?.map((nft) => {
              const metadata = JSON.parse(
                nft?.EvmNFTTransaction?.NFT.metadata_json
              );
              return (
                <CardNFT
                  key={nft?.EvmNFTTransaction?.NFT?.nft_id}
                  nftId={nft?.EvmNFTTransaction?.NFT?.nft_id}
                  sellEscrowId={nft?.EvmNFTTransaction?.trx_id}
                  auctionId={null}
                  name={metadata.name}
                  collectionName={metadata.collection.name}
                  ipfsHash={metadata.image.replace("ipfs://", "")}
                  is_pdf={metadata.is_pdf || false}
                  data={{ ...nft, metadata, ...nft?.EvmNFTTransaction?.NFT }}
                />
              );
            })
          : nftsPurchased?.map((nft) => {
              const metadata = JSON.parse(nft.nft.metadata_json);
              return (
                <CardNFT
                  key={nft.nft_id}
                  nftId={nft.nft.nft_id}
                  sellEscrowId={nft.sell_escrow_id && nft.sell_escrow_id}
                  auctionId={nft.auction_id && nft.auction_id}
                  name={metadata.name}
                  collectionName={metadata.collection.name}
                  ipfsHash={metadata.image.replace("ipfs://", "")}
                  is_pdf={metadata.is_pdf || false}
                  // type={nft.type}
                  data={nft?.nft}
                />
              );
            })}
      </Row>
    ) : (
      renderEmpty(
        emptyNftsPurchase,
        "No NFTs Found",
        "Buy a NFT from Marketplace or Auctions and it will show up here"
      )
    );
  };

  const renderAbout = () => {
    return (
      <ColCenter style={{ height: "100%", minHeight: "calc(100vh - 180px)" }}>
        <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
          >
            {windowDimensions.width < 835 ? (
              <RowCenter>
                <Image
                  width={400}
                  className="mb-24px"
                  preview={false}
                  src={credenceAbout}
                />
              </RowCenter>
            ) : (
              <ColStart className="w-100">
                <Title className="mb-12px mt-24px" level={3}>
                  {t("credence-about-1-1")} <br />
                  {t("credence-about-1-2")}
                </Title>
                <Text>{t("credence-about-desc")}</Text>
              </ColStart>
            )}
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
          >
            {windowDimensions.width > 834 ? (
              <Image preview={false} src={credenceAbout} />
            ) : (
              <ColCenter>
                <Title className="mb-12px mt-24px" align="center" level={3}>
                  {t("credence-about-2-1")}
                  <br /> {t("credence-about-2-2")}
                </Title>
                <Text className="pl-12px pr-12px" align="center">
                  {t("credence-about-desc")}
                </Text>
              </ColCenter>
            )}
          </Col>
        </Row>
      </ColCenter>
    );
  };

  return (
    <>
      <SEO title="IBFNEX - Credence">
        {windowDimensions.width < 835 && renderSearchBar()}
        <TabsStyled
          size="large"
          tabBarExtraContent={
            windowDimensions.width > 834 ? renderSearchBar() : null
          }
          onChange={(val) => setMenu(val)}
          defaultActiveKey={pageMenu}
          activeKey={menu}
        >
          <TabPane tab={t("credence-menu-1")} key="about">
            {renderAbout()}
          </TabPane>
          <TabPane
            tab={IS_EVM ? t("credence-menu-4") : t("credence-menu-2")}
            key="vault"
          >
            {renderVault()}
          </TabPane>
          <TabPane tab={t("credence-menu-3")} key="onsale">
            {renderOnsale()}
          </TabPane>
          {!IS_EVM && (
            <TabPane tab={t("credence-menu-4")} key="purchased">
              {renderPurchased()}
            </TabPane>
          )}
          <TabPane
            tab={IS_EVM ? "Dashboard" : t("credence-menu-5")}
            key="approvals"
          >
            <CredenceApprovals toVault={() => setMenu("vault")} />
          </TabPane>
        </TabsStyled>
      </SEO>
    </>
  );
}
