import styled from "@emotion/styled";
import { ButtonStyled } from "../../components/general_styled";
import { Col, Menu } from "antd";

export const CreateButtonStyled = styled(ButtonStyled)`
  height: 100%;
  margin: 0;
`;

export const NotFoundTitleStyled = styled.h1`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;

  /* Primary Gray */

  color: #0b0e14;
  margin-bottom: 4px;
  padding-bottom: 0;
`;

export const NotFoundTextStyled = styled.p`
  padding-bottom: 0;
  margin-bottom: 24px;
  text-align: center;

  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  /* Secondary Gray */

  color: #3d424d;
`;

export const MoreInfoBtnStyled = styled.button`
  background: #eff2f8;
  /* Stroke/Divider */
  border: 1px solid #dfe7f7;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  min-width: 30px;
  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`;

export const MenuDropdownCustom = styled(Col)`
  padding: 4px 8px;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  span {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    /* identical to box height */

    /* Inactive */

    color: #6b7a99;
  }
`;

export const ActionButtonStyled = styled(ButtonStyled)`
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  min-width: 30px;
  padding: 4px 8px;

  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
`;
