import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllAssets } from "../../modules/wallet/action";
import WalletOverview from "../../components/Wallet/WalletOverview";
import WalletTopup from "../../components/Wallet/WalletTopup";
import WalletTransfer from "../../components/Wallet/WalletTransfer";
import WalletConvert from "../../components/Wallet/WalletConvert";
import { TabsStyled } from "./styled";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

export default function WalletDashboard(props) {
  const { t } = useTranslation();

  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const pageMenu = useSelector((state) => state.getIn(["general", "pageMenu"]));
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isAlreadyFetch, setAlreadyFetch] = useState(false);

  const initAssets = async () => {
    await dispatch(getAllAssets(walletUser));
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isAlreadyFetch) {
      if (walletUser.user_id) {
        initAssets();
        setAlreadyFetch(true);
      }
    }
  }, [walletUser]);

  return (
    <>
      <TabsStyled className="w-100" defaultActiveKey={pageMenu} size="large">
        <TabPane tab={t("wallet-menu-1")} key="overview">
          <WalletOverview isLoading={isLoading} />
        </TabPane>
        <TabPane tab={t("wallet-menu-2")} key="topup">
          <WalletTopup isLoading={isLoading} />
        </TabPane>
        <TabPane tab={t("wallet-menu-3")} key="transfer">
          <WalletTransfer isLoading={isLoading} />
        </TabPane>
        <TabPane tab={t("wallet-menu-4")} key="convert" disabled={true}>
          <WalletConvert isLoading={isLoading} />
        </TabPane>
      </TabsStyled>
    </>
  );
}
