import React, { useState } from "react";
import { Modal, Typography, Button, Drawer, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { ColCenter, RowSpaceBetween, ColSpaceBetween } from "../general_styled";
import ModalSellNFTDetail from "../ModalSellNFTDetail";
import {
  ContentStyled,
  ContentCardStyled,
  TitleStyled,
  SubtitleStyled,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import checkImg from "../../assets/images/sell-nft-check.svg";
import shopImg from "../../assets/images/sell-nft-shop.svg";
import shopImgActive from "../../assets/images/sell-nft-shop-active.svg";
import dollarImg from "../../assets/images/sell-nft-dollar.svg";
import dollarImgActive from "../../assets/images/sell-nft-dollar-active.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import ModalSellNFTAuction from "../ModalSellNFTAuction";
import { setSection } from "../../modules/general/action";
import { IS_EVM } from "../../configs";
// import { extractErrorMessage } from "../../utils/extractErrorMessage"

const { Title, Text } = Typography;

export default function ModalSellNFT(props) {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEssence, setEssence] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEssence(true);
  };

  const onSubmit = async () => {
    console.log("submit");
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  return (
    <>
      {props.fromCollateral ? (
        <Button
          block
          style={{ height: 36, borderRadius: 4 }}
          type="primary"
          className={"mt-24px"}
          onClick={showModal}
        >
          Sell on Marketplace
        </Button>
      ) : (
        <Button
          block
          disabled={props.disabled}
          style={{ height: 40, borderRadius: 4 }}
          type="primary"
          className={!props.noMargin && "mt-24px"}
          onClick={showModal}
        >
          Sell NFT
        </Button>
      )}
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          className="modal-sell-nft"
          visible={isModalVisible}
          onCancel={handleCancel}
          maskStyle={{ backgroundColor: "#fafafa" }}
          footer={null}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
          width={"800px"}
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Sell your NFT
              </Title>
              <Text className="gray-7" align="center">
                Select how you want to put your NFT on sale
              </Text>
              <Row gutter={20} className="w-100 mt-24px">
                <Col span={12}>
                  <ContentCardStyled
                    className={isEssence ? "selected" : ""}
                    onClick={() => setEssence(true)}
                  >
                    {isEssence && <img src={checkImg} className="check" />}
                    <ColSpaceBetween>
                      <img
                        src={isEssence ? shopImgActive : shopImg}
                        style={{
                          width: "80px",
                          height: "80px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      />
                      <TitleStyled
                        className={"mt-8px " + (isEssence ? "selected" : "")}
                      >
                        Fixed Price
                      </TitleStyled>
                      <SubtitleStyled className={isEssence ? "selected" : ""}>
                        Have a price in mind? <br /> Sell at a fixed value
                      </SubtitleStyled>
                      <ModalSellNFTDetail
                        isEssence={true}
                        isActive={isEssence}
                        metadata={props.metadata}
                        data={props.data}
                        handleCancel={handleCancel}
                      />
                    </ColSpaceBetween>
                  </ContentCardStyled>
                </Col>
                <Col span={12}>
                  <ContentCardStyled
                    className={`${!isEssence ? "selected" : ""} ${
                      IS_EVM ? "disabled" : ""
                    }`}
                  >
                    {!isEssence && <img src={checkImg} className="check" />}
                    <ColSpaceBetween
                      onClick={() => (IS_EVM ? null : setEssence(false))}
                    >
                      <img
                        src={!isEssence ? dollarImgActive : dollarImg}
                        style={{
                          width: "80px",
                          height: "80px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      />
                      <TitleStyled
                        className={"mt-8px " + (!isEssence ? "selected" : "")}
                      >
                        Auction
                      </TitleStyled>
                      <SubtitleStyled className={!isEssence ? "selected" : ""}>
                        Not sure about the price? <br /> Discover with auctions
                      </SubtitleStyled>
                      {/* <ModalSellNFTAuction
                        isEssence={false}
                        isActive={!isEssence}
                        metadata={props.metadata}
                        handleCancel={handleCancel}
                      /> */}
                      <Button
                        block
                        type={!isEssence ? "primary" : "default"}
                        className="mt-24px"
                        style={{ height: 48, borderRadius: 6 }}
                        disabled={IS_EVM}
                        onClick={() => {
                          dispatch(setSection("affluence-auction-listing"));
                        }}
                      >
                        {IS_EVM ? "Auction Coming Soon" : "Sell using Auctions"}
                      </Button>
                    </ColSpaceBetween>
                  </ContentCardStyled>
                </Col>
              </Row>
              {/*
              <Button
                block
                ghost
                type="primary"
                className="mt-12px"
                size="large"
                onClick={() => handleCancel()}
              >
                Dismiss
              </Button> */}
            </ColCenter>
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          <ContentStyled>
            {/* onActivateAssets */}
            <ColCenter>
              <Title className="text-w700 gray-7 mb-0px" level={3}>
                Sell your NFT
              </Title>
              <Text className="gray-7" align="center">
                Select how you want to put your NFT on sale
              </Text>
              <Row gutter={20} className="w-100 mt-24px">
                <Col span={12}>
                  <ContentCardStyled
                    className={isEssence ? "selected" : ""}
                    onClick={() => setEssence(true)}
                  >
                    {isEssence && <img src={checkImg} className="check" />}
                    <ColSpaceBetween>
                      <img
                        src={isEssence ? shopImgActive : shopImg}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      />
                      <TitleStyled className={isEssence ? "selected" : ""}>
                        Fixed Price
                      </TitleStyled>
                      <SubtitleStyled className={isEssence ? "selected" : ""}>
                        Have a price in mind? <br /> Sell at a fixed value
                      </SubtitleStyled>
                    </ColSpaceBetween>
                  </ContentCardStyled>
                </Col>
                <Col span={12}>
                  <ContentCardStyled className={!isEssence ? "selected" : ""}>
                    {!isEssence && <img src={checkImg} className="check" />}
                    <ColSpaceBetween onClick={() => setEssence(false)}>
                      <img
                        src={!isEssence ? dollarImgActive : dollarImg}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      />
                      <TitleStyled className={!isEssence ? "selected" : ""}>
                        Auction
                      </TitleStyled>
                      <SubtitleStyled className={!isEssence ? "selected" : ""}>
                        Not sure about the price? <br /> Discover with auctions
                      </SubtitleStyled>
                    </ColSpaceBetween>
                  </ContentCardStyled>
                </Col>
              </Row>
              <Button
                block
                type="primary"
                className="mt-24px"
                size="large"
                onClick={() => onSubmit()}
              >
                {isLoading ? antIcon : "Continue"}
              </Button>
              <Button
                block
                ghost
                type="primary"
                className="mt-12px"
                size="large"
                onClick={() => handleCancel()}
              >
                Dismiss
              </Button>
            </ColCenter>
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
