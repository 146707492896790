import { fromJS } from "immutable";
import {
  CLOSE_CREATE_PROJECT_MODAL,
  GET_BENEVOLENCE_PROJECTS,
  GET_BENEVOLENCE_PROJECTS_FAIL,
  GET_BENEVOLENCE_PROJECTS_SUCCESS,
  OPEN_CREATE_PROJECT_MODAL,
} from "../constants";

const initialState = fromJS({
  isLoading: fromJS(false),
  hasError: fromJS(false),
  errorMessage: fromJS(""),
  projects: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    totalItems: 0,
    totalPage: 0,
  }),
  isCreateModalVisible: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BENEVOLENCE_PROJECTS:
      return state.merge({
        isLoading: true,
        hasError: false,
      });
    case GET_BENEVOLENCE_PROJECTS_SUCCESS:
      return state.merge({
        isLoading: false,
        projects: fromJS(action.payload),
      });
    case GET_BENEVOLENCE_PROJECTS_FAIL:
      return state.merge({
        isLoading: false,
        hasError: true,
        errorMessage: action.payload.errorMessage,
      });
    case OPEN_CREATE_PROJECT_MODAL:
      return state.merge({
        isCreateModalVisible: true,
      });
    case CLOSE_CREATE_PROJECT_MODAL:
      return state.merge({
        isCreateModalVisible: false,
      });
    default:
      return state;
  }
};
