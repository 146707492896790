import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { Col, Typography, Row, Button, Image, notification } from "antd"

import {
  Canvas,
  CustomRow,
  ImageLogin,
  Content,
  FormCustom,
  TextStyled,
  BlueLink,
  EmailChange,
} from "./styled"
import ReactCodeInput from "react-code-input"
import { useHistory, useParams } from "react-router"
import mosqueImage from "../../assets/images/bg_landing.jpg"
import {
  confirmForgotPassword,
  confirmVerification,
  forgotPassword,
  recheckToken,
  sendVerification,
  setRegisterData,
} from "../../modules/auth/action"
import { LoadingOutlined, UndoOutlined } from "@ant-design/icons"

import logoColor from "../../assets/images/logo-beta-black.png"
import { RowCenter, RowSpaceBetween } from "../../components/general_styled"
import { extractErrorMessage } from "../../utils/extractErrorMessage"
import useWindowDimensions from "../../utils/windowDimensions"

const { Title, Text } = Typography

export default function Verification() {
  const windowDimensions = useWindowDimensions()
  const { type } = useParams()
  const stateForgotPassword = useSelector((state) =>
    state.getIn(["auth", "forgotPassword"]).toJS()
  )
  const stateDataRegister = useSelector((state) =>
    state.getIn(["auth", "dataRegister"]).toJS()
  )
  const user = useSelector((state) => state.getIn(["auth", "userData"]).toJS())
  const stateAuth = useSelector((state) => state.getIn(["auth"]).toJS())
  const dispatch = useDispatch()
  const history = useHistory()

  const [pinCode, setPinCode] = useState("")
  const [key, setKey] = useState(1)

  const [isLoading, setLoading] = useState(false)

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 767 ? "5vw" : "10vw",
      height: windowDimensions.width > 767 ? "5vw" : "10vw",
      margin: "4px",
      fontSize: windowDimensions.width > 767 ? "1.8vw" : "20px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 767 ? "5vw" : "10vw",
      height: windowDimensions.width > 767 ? "5vw" : "10vw",
      margin: "4px",
      fontSize: windowDimensions.width > 767 ? "1.8vw" : "20px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  }

  useEffect(() => {
    dispatch(recheckToken())
    sendEmailVerification()
  }, [])

  useEffect(() => {
    // if value was cleared, set key to re-render the element
    if (pinCode.length === 0) {
      setKey(key + 1)
      return
    }

    // do something with the pin
  }, [pinCode])

  //In case this page access from home
  const sendEmailVerification = async () => {
    const token = localStorage.getItem("USER_AUTH_TOKEN")
    if (
      !(
        Object.keys(stateDataRegister).length <= 0 &&
        Object.keys(user).length > 0 &&
        token
      )
    ) {
      return
    }

    dispatch(setRegisterData(user.email, "", user.name, token))
    await dispatch(
      sendVerification({
        mode: "email",
        jwtToken: token,
      })
    )
  }

  const onSubmitClicked = async () => {
    setLoading(true)

    if (type === "forgot-password") {
      try {
        const result = await dispatch(
          confirmForgotPassword({
            body: stateForgotPassword,
            otp: pinCode,
          })
        )

        if (result.code === "SUCCESS") {
          history.push("/new-password")
        } else {
          notification.error({
            message: "Failed!",
            description: result,
          })
        }
      } catch (e) {
        console.log(e)
        notification.error({
          message: "Failed!",
          description: extractErrorMessage(e),
        })
      }
    } else {
      try {
        const result = await dispatch(
          confirmVerification({
            mode: "email",
            otp: pinCode,
            jwtToken: stateDataRegister.token,
          })
        )

        if (result.code === "SUCCESS") {
          notification.success({
            message: "Success!",
            description: `Welcome! Login Successful`,
          })
          history.push("/home")
        } else {
          notification.error({
            message: "Failed!",
            description: result,
          })
        }
      } catch (e) {
        console.log(e)
        notification.error({
          message: "Failed!",
          description: extractErrorMessage(e),
        })
      }
    }
    setLoading(false)
  }

  const onResend = async () => {
    setLoading(true)
    setPinCode("")
    try {
      if (type === "forgot-password") {
        await dispatch(
          forgotPassword({
            body: stateForgotPassword,
          })
        )
      } else {
        await dispatch(
          sendVerification({
            mode: "email",
            jwtToken: stateDataRegister.token,
          })
        )
      }
    } catch (e) {
      console.log(e)
      notification.error({
        message: "Failed!",
        description: extractErrorMessage(e),
      })
    }
    setLoading(false)
  }

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  )

  const resolveUI = () => {
    if (stateAuth.isAuthorized) {
      return <Redirect to="/" />
    }
    return (
      <Canvas>
        <CustomRow>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <ImageLogin img={mosqueImage} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Content>
              <Image
                src={logoColor}
                width={204}
                style={{ marginBottom: "36px" }}
                preview={false}
              />
              <Title level={2} style={{ marginBottom: 0 }}>
                Verification
              </Title>
              <Text>We have sent you a 6 digit code to</Text>
              <EmailChange onClick={() => history.goBack()}>
                <RowSpaceBetween className="w-100">
                  <div className="email">
                    {(stateDataRegister.email
                      ? stateDataRegister.email
                      : stateForgotPassword.email) || "..."}
                  </div>
                  <div className="change">Change</div>
                </RowSpaceBetween>
              </EmailChange>
              <FormCustom>
                <ReactCodeInput
                  type="number"
                  onChange={(e) => setPinCode(e)}
                  fields={6}
                  value={pinCode}
                  key={key}
                  {...codeProps}
                />
                <RowCenter className="mt-24px">
                  <Text>Didn't get your OTP? </Text>
                  <Row className="pointer" onClick={onResend}>
                    &nbsp; &nbsp;
                    <UndoOutlined className="mt-4px" />
                    &nbsp;
                    <Text strong> Resend</Text>
                  </Row>
                </RowCenter>
                <Button
                  type="primary"
                  block
                  disabled={!(pinCode !== "")}
                  style={{ marginTop: 24, marginBottom: 36 }}
                  size="large"
                  onClick={onSubmitClicked}
                >
                  {isLoading ? loadingIcon : "Confirm"}
                </Button>
                <TextStyled>Don’t want to reset your password?</TextStyled>
                <BlueLink to="/login">Go Back to Login</BlueLink>
              </FormCustom>
            </Content>
          </Col>
        </CustomRow>
      </Canvas>
    )
  }

  return resolveUI()
}
