import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Row, Col, Button, Progress, Typography, Tag, Popover, Card, Divider, Dropdown, Menu } from 'antd'
import MoreIcon from '../../assets/images/more_round.svg'
import projectIcon from '../../assets/images/projects-side.svg'
import closeIcon from '../../assets/images/close_project.svg'
import {
	DollarText,
	DollarText1,
	ProjectTitle,
	RowSpaceBetween1,
	CardStyled,
	TotalUserText,
	UserImgStyled,
	TitleStyled,
	SubTitleStyled,
	DonationTextStyled,
	DivididerStyled,
} from './styled'
import { formatterCurrency, fromIBFx, fromIUSD, titleCase, toUSD } from '../../utils/utils'
import { ButtonStyled, ColEnd, ColSpaceBetween, ColStart, RowEnd, RowSpaceBetween } from '../general_styled'
import ModalDonation from '../ModalDonation'
import { PROJECT_TYPE, PROJECT_STATUS } from '../../constants/constant_common'
import { useHistory } from 'react-router'
import { BENEVOLENCE_HOST } from '../../configs'
import { setSection } from '../../modules/general/action'
import useWindowDimensions from '../../utils/windowDimensions'
import ModalContributeVolunteer from '../ModalContributeVolunteer'
import moment from 'moment'
import { MenuDropdownCustom } from '../../pages/BenevolenceProjects/styled'

const mappingTag = {
	progress: {
		color: 'gold',
		text: 'In-Progress',
	},
	REQUESTED: {
		color: 'gold',
		text: 'Under Review',
	},
	[PROJECT_STATUS.PENDING]: {
		color: 'yellow',
		text: 'Pending',
	},
	[PROJECT_STATUS.DEPLOYED]: {
		color: 'geekblue',
		text: 'Deployed',
	},
	[PROJECT_STATUS.APPROVED]: {
		color: 'green',
		text: 'Approved',
	},
	[PROJECT_STATUS.REJECTED]: {
		color: 'volcano',
		text: 'Rejected',
	},
	[PROJECT_STATUS.CLOSED]: {
		color: 'volcano',
		text: 'Closed',
	},
	[PROJECT_STATUS.SUBMITED]: {
		color: 'lime',
		text: 'Submitted',
	},
	[PROJECT_STATUS.COMPLETED]: {
		color: '#858585',
		text: 'Completed',
	},
	[PROJECT_STATUS.OPENED]: {
		color: 'blue',
		text: 'Opened',
	},
	[PROJECT_STATUS.STARTING]: {
		color: 'magenta',
		text: 'Starting',
	},
}

export default function CardBenevolenceManage(props) {
	const { data, page } = props
	const windowDimensions = useWindowDimensions()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isModalVisible, setModalVisible] = useState(false)
	const [openMore, setOpenMore] = useState(false)
	const [buttonInfo, setButtonInfo] = useState(null)

	useEffect(() => {
		if (data.status === 'approved' && data.project_type === PROJECT_TYPE.ASSET) {
			setButtonInfo({ handleClick: props.handleActionButton(data, 'deploy'), text: 'Deploy', disable: false })
		} else if (data.status === 'approved' && data.project_type === PROJECT_TYPE.EFFORT) {
			setButtonInfo({ handleClick: props.handleActionButton(data, 'start'), text: 'Start', disable: false })
		} else if (data.status === 'deployed') {
			setButtonInfo({
				handleClick: props.handleActionButton(data, 'create_escrow'),
				text: 'Create Escrow',
				disable: false,
			})
		} else {
			setButtonInfo({ handleClick: null, text: 'Deployed', disable: true })
		}
	}, [])

	const resolveHeader = () => {
		return page === 'manage' ? (
			<RowSpaceBetween>
				{moment().unix() > data.end_at ? (
					<Tag color="volcano">Pending Closure</Tag>
				) : (
					<Tag color={mappingTag[data?.status].color}>{mappingTag[data?.status].text}</Tag>
				)}
				<Dropdown
					overlay={
						<Menu className="canvas-dropdown-custom" onClick={(e) => props.handleMenuClick(e, data)}>
							<Menu.Item key="view-project">
								<Row>
									<Col span={24} className="menu-dropdown-custom">
										<img src={projectIcon} alt="icon" />
										<span>View Project</span>
									</Col>
								</Row>
							</Menu.Item>
							{data.project_type === 'ASSET' && data.status === PROJECT_STATUS.OPENED && (
								<Menu.Item key="close">
									<Row>
										<MenuDropdownCustom>
											<img src={closeIcon} alt="icon" />
											<span>Close Project</span>
										</MenuDropdownCustom>
									</Row>
								</Menu.Item>
							)}
							{data.project_type === 'EFFORT' && data.status === 'OPENED' && moment().unix() > data.end_at && (
								<Menu.Item key="close">
									<Row>
										<MenuDropdownCustom>
											<img src={closeIcon} alt="icon" />
											<span>Close Project</span>
										</MenuDropdownCustom>
									</Row>
								</Menu.Item>
							)}
						</Menu>
					}
					placement="bottomRight"
				>
					<img src={MoreIcon} />
				</Dropdown>
			</RowSpaceBetween>
		) : (
			<></>
		)
	}

	const resolveBody = () => {
		return page === 'manage' ? (
			<>
				<RowSpaceBetween className="pt-14px">
					<ColStart>
						<TitleStyled isTitle={true}>{data?.name}</TitleStyled>
						<SubTitleStyled>{data?.project_type === 'EFFORT' ? 'Effort (SE)' : 'Assets (SA)'}</SubTitleStyled>
					</ColStart>
				</RowSpaceBetween>
				<DivididerStyled dashed />
				<RowSpaceBetween>
					<ColStart>
						<SubTitleStyled>Donors/Volunteers</SubTitleStyled>
						<TitleStyled isTitle={false}>{data?.total_volunteer + data?.total_donation}</TitleStyled>
					</ColStart>
					<ColEnd style={{ alignItems: 'flex-end' }}>
						<SubTitleStyled>Project End Date</SubTitleStyled>
						<TitleStyled isTitle={false}>{moment.unix(data?.end_at).format('DD/MM/YYYY HH:mm')}</TitleStyled>
					</ColEnd>
				</RowSpaceBetween>
				<DivididerStyled dashed />
			</>
		) : page === 'donors' ? (
			<>
				<RowSpaceBetween className="pt-14px">
					<ColStart>
						<TitleStyled isTitle={true}>{data?.user?.name}</TitleStyled>
						<SubTitleStyled>{data?.user?.email}</SubTitleStyled>
					</ColStart>
					<DonationTextStyled>${fromIBFx(data?.amount / 10)}</DonationTextStyled>
				</RowSpaceBetween>
				<DivididerStyled dashed />
				<RowSpaceBetween>
					<ColStart>
						<SubTitleStyled>Project Name</SubTitleStyled>
						<TitleStyled isTitle={false}>{data?.project?.name || '-'}</TitleStyled>
					</ColStart>
					<ColEnd style={{ alignItems: 'flex-end' }}>
						<SubTitleStyled>Country</SubTitleStyled>
						<TitleStyled isTitle={false}>{data?.project?.country?.name || '-'}</TitleStyled>
					</ColEnd>
				</RowSpaceBetween>
				<RowSpaceBetween className="pt-12px">
					<ColStart>
						<SubTitleStyled>Project End Date</SubTitleStyled>
						<TitleStyled isTitle={false}>{moment.unix(data?.project?.end_at).format('DD/MM/YYYY HH:mm')}</TitleStyled>
					</ColStart>
				</RowSpaceBetween>
				<DivididerStyled dashed />
			</>
		) : (
			<>
				<RowSpaceBetween className="pt-14px">
					<ColStart>
						<TitleStyled isTitle={true}>{data?.user?.name}</TitleStyled>
						<SubTitleStyled>{data?.user?.email}</SubTitleStyled>
					</ColStart>
					{/* {moment().unix() > data.end_at ? (
						<Tag color="volcano">Pending Closure</Tag>
					) : (
					)} */}
					<Tag color={mappingTag[data?.status].color}>{mappingTag[data?.status].text}</Tag>
				</RowSpaceBetween>
				<DivididerStyled dashed />
				<RowSpaceBetween>
					<ColStart>
						<SubTitleStyled>Project Name</SubTitleStyled>
						<TitleStyled isTitle={false}>{data?.project?.name || '-'}</TitleStyled>
					</ColStart>
					<ColEnd style={{ alignItems: 'flex-end' }}>
						<SubTitleStyled>Country</SubTitleStyled>
						<TitleStyled isTitle={false}>{data?.project?.country?.name || '-'}</TitleStyled>
					</ColEnd>
				</RowSpaceBetween>
				<RowSpaceBetween className="pt-12px">
					<ColStart>
						<SubTitleStyled>Skills</SubTitleStyled>
						<TitleStyled isTitle={false}>{data?.title}</TitleStyled>
					</ColStart>
				</RowSpaceBetween>
				<DivididerStyled dashed />
			</>
		)
	}

	const resolveFooter = () => {
		return page === 'manage' ? (
			<>
				<Progress
					percent={
						data.type === PROJECT_TYPE.ASSET
							? (fromIBFx(data.donation_amount == null ? 0 : fromIUSD(data.donation_amount)) /
									fromIBFx(data.fund_goal == null ? 0 : fromIUSD(data.fund_goal))) *
							  100
							: (data.total_hours_accepted / data.hours_goal) * 100
					}
					status="active"
					showInfo={false}
					strokeWidth={10}
					strokeColor={30 <= 50 ? '#EB5757' : 30 <= 75 ? '#F2994A' : 30 > 75 ? '#27AE60' : ''}
				/>
				<ButtonStyled
					disabled={buttonInfo?.disable}
					onClick={() => buttonInfo?.handleClick()}
					style={{ minHeight: '48px' }}
					className="mt-12px"
				>
					{buttonInfo?.text}
				</ButtonStyled>
			</>
		) : page == 'donors' ? (
			<ButtonStyled
				onClick={() => dispatch(setSection('project-detail', data?.project_id))}
				style={{ minHeight: '48px' }}
			>
				View Project
			</ButtonStyled>
		) : data.status === 'REQUESTED' ? (
			<RowSpaceBetween>
				<ButtonStyled
					onClick={() => props.handleRequested('rejected', data)}
					block
					ghost
					style={{ minHeight: '48px' }}
					type="primary"
					className="mt-12px mr-6px"
					size="large"
				>
					Reject
				</ButtonStyled>
				<ButtonStyled
					block
					onClick={() => props.handleRequested('approved', data)}
					style={{ minHeight: '48px' }}
					className="mt-12px"
					loading={props.isProcessing}
				>
					Accept
				</ButtonStyled>
			</RowSpaceBetween>
		) : data.status === 'APPROVED' && data?.project.status === 'COMPLETED' ? (
			<ButtonStyled
				onClick={() => props.handleRequested('review', data)}
				style={{ minHeight: '48px' }}
				className="mt-12px"
			>
				Review
			</ButtonStyled>
		) : (
			<ButtonStyled
				// disabled={buttonInfo?.disable}
				style={{ minHeight: '48px' }}
				className="mt-12px"
			>
				View Project
			</ButtonStyled>
		)
	}

	return (
		<>
			<Col xs={24} sm={24} md={windowDimensions.width > 925 ? 12 : 24} lg={12} xl={8} xxl={6}>
				<CardStyled className="mb-12px">
					{resolveHeader()}
					{resolveBody()}
					{resolveFooter()}
				</CardStyled>
			</Col>
		</>
	)
}
