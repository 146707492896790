import styled from "@emotion/styled";
import { Typography } from "antd";

const { Text } = Typography;

export const ContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;

  .ant-upload.ant-upload-select {
    display: block;
  }
`;

export const TitleStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Gray/gray-8 */

  color: #0b0e14;
`;

export const TextStyled = styled(Text)`
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* or 167% */

  text-align: center;

  /* Gray/gray-7 */

  color: #273248;
  max-width: 350px;
`;

export const CanvasNftDetails = styled.div`
  width: 100%;
  padding: 16px;
  margin-top: 20px;

  background: #f7f8fa;
  /* Neutral/300 */

  border: 1px solid #f3f4f6;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const TotalDue = styled.div`
  width: 100%;
  padding: 16px;
  margin-top: 20px;

  background: #f7f8fa;
  /* Secondary/500 */

  border: 1px solid #3b4552;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const DividerCard = styled.div`
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6DAE1FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const StepContentStyled = styled.div`
  min-height: 600px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 24px;
  margin: 1rem 2.5%;

  background: #fcfcfc;
  box-sizing: border-box;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 14%) 0px 0px 20px -6px;
  overflow: auto;

  .detail {
    padding: 0 20%;
  }

  @media screen and (min-width: 835px) {
    height: 400px;
    width: 95%;
  }

  @media screen and (max-width: 834px) {
    width: 100%;
    height: auto;
  }

  &.height-auto {
    min-height: auto;
  }

  &.center {
    justify-content: center;
  }
`;
