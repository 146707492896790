import React from "react";
import { ColCenter, RowCenter } from "../../components/general_styled";
import ConfluenceIllustration from "../../assets/images/credence_empty.svg";
import { Typography } from "antd";
import { TitleStyled, DescStyled } from "./styled";
import useWindowDimensions from "../../utils/windowDimensions";
import ConnectWallet from "../../components/ConnectWallet";

const { Text } = Typography;

export default function CredenceEvmOnBoarding() {
  const { width } = useWindowDimensions();

  return width > 1000 ? (
    <RowCenter>
      <ColCenter
        className="start"
        style={{
          height: "100%",
          minHeight: "calc(100vh - 120px)",
        }}
      >
        <TitleStyled>
          Create Shariah-Compliant <br />
          Digital Assets Using NFTs
        </TitleStyled>
        <DescStyled>
          Preserve tangible and intangible assets - certificates, legal
          documents, works of art, collectibles and any valuables by creating
          transparent, verifiable, and traceable digital records of ownership
          with secure and automatic implementation of smart contracts.
        </DescStyled>
        <Text
          align="left"
          italic
          style={{
            fontSize: 13,
            margin: 0,
            marginTop: 12,
            color: "red",
          }}
        >
          Requirements: Connect Wallet
        </Text>
        <div style={{ width: "100%" }}>
          <ConnectWallet />
        </div>
      </ColCenter>
      <img
        src={ConfluenceIllustration}
        width={324}
        style={{
          marginLeft: 100,
          maxWidth: "100%",
        }}
      />
    </RowCenter>
  ) : (
    <ColCenter
      className="w-100"
      style={{
        height: "100%",
        minHeight: "calc(100vh - 120px)",
      }}
    >
      <img
        src={ConfluenceIllustration}
        width={324}
        style={{
          maxWidth: "100%",
          marginBottom: "2rem",
        }}
      />
      <TitleStyled>
        Create Shariah-Compliant <br />
        Digital Assets Using NFTs
      </TitleStyled>
      <DescStyled align="center">
        Preserve tangible and intangible assets - certificates, legal documents,
        works of art, collectibles and any valuables by creating transparent,
        verifiable, and traceable digital records of ownership with secure and
        automatic implementation of smart contracts.
      </DescStyled>
      <Text
        align="left"
        italic
        style={{
          fontSize: 13,
          margin: 0,
          marginTop: 12,
          color: "red",
        }}
      >
        Requirements: Connect Wallet
      </Text>
      <div style={{ width: "100%" }}>
        <ConnectWallet />
      </div>
    </ColCenter>
  );

  // return (
  //   <RowCenter>
  //     <ColCenter
  //       style={{
  //         height: "100%",
  //         minHeight: "calc(100vh - 120px)",
  //         width: "60%",
  //       }}
  //     >
  //       <Image
  //         src={ConfluenceIllustration}
  //         preview={false}
  //         width={393}
  //         style={{ marginBottom: "16px" }}
  //       />
  //       <Title style={{ fontWeight: "bold", margin: 0 }}>Welcome</Title>
  //       <Text align="center" level={5} style={{ margin: 0 }}>
  //         If you are an impact-sensitive project owner concerned about market
  //         and community perceptions about your project as one making a positive
  //         or negative impact, you are at the right platform. Confluence allows
  //         you to objectively measure the impact of your project both from the
  //         standpoint of SDGs and Maqasid-al-Shariah and earn/acquire social
  //         cryptos. Impact-surplus projects can exchange social cryptos with
  //         impact-deficit ones, thereby further enhancing their resource base.
  //       </Text>
  //       <ButtonStyled size="large" onClick={onButtonClicked}>
  //         Start Using Confluence
  //       </ButtonStyled>
  //     </ColCenter>
  //   </RowCenter>
  // )
}
