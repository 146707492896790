import { useTranslation } from "react-i18next";
import { Col, Modal, Row } from "antd";
import { useSelector } from "react-redux";
import checkMarkIcon from "../../assets/images/check_mark.svg";
import { useEffect, useState } from "react";
import {
  PROJECT_TYPE,
  USER_STATUS,
} from "../../constants/benevolence_constant";
import closeModalIcon from "../../assets/images/close_modal.svg";
import closeIcon from "../../assets/images/close.svg";
import { useDispatch } from "react-redux";
import CreateAssetProject from "./CreateAssetProject";
import CreateEffortProject from "./CreateEffortProject";
import { closeCreateProjectModal } from "../../modules/benevolence_projects/action";
import { ButtonStyled, ColCenter } from "../general_styled";
import {
  CardDescStyled,
  CardLiStyled,
  CardTitleStyled,
  CardUlStyled,
  CreateProjectCard,
  CreateProjectCards,
  ModalStyled,
  SubtitleStyled,
  TitleStyled,
  TypeLabelStyled,
} from "./styled";
import { ASSETS_NAME, ASSETS_STATUS } from "../../constants/constant_wallet";
import BenevolenceOnBoarding from "../../pages/BenevolenceOnBoarding";
import {
  setBenevolenceProjectType,
  setSection,
} from "../../modules/general/action";
import { IS_EVM } from "../../configs";

export default function CreateProjectModal() {
  const { t } = useTranslation();
  const isModalVisible = useSelector((state) =>
    state.getIn(["benevolenceProjects", "isCreateModalVisible"])
  );

  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const benevolenceUser = useSelector((state) =>
    state.getIn(["benevolence", "user"]).toJS()
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );

  const [type, setType] = useState("");
  const [content, setContent] = useState(null);
  const [isVerified, setIsVerified] = useState(IS_EVM);
  const dispatch = useDispatch();

  useEffect(() => {
    setType("");
    setContent(null);
  }, [isModalVisible]);

  // const [initStatus, setInitStatus] = useState(false);
  useEffect(() => {
    if (allAssets.length > 0) {
      // if (!initStatus) {
      initIbfAssets();
      // setInitStatus(true);
      // }
    }
  }, [allAssets, benevolenceUser]);

  const initIbfAssets = async () => {
    if (IS_EVM) {
      setIsVerified(true);
    } else {
      const ibfx1 = allAssets.find(
        (e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_I
      );
      // const ibfx3 = allAssets.find((e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_III)
      // const ibfs = allAssets.find((e) => e.asset_name === ASSETS_NAME.IBFS);

      if (
        Object.keys(benevolenceUser) <= 0 ||
        Object.keys(walletUser) <= 0 ||
        walletUser.status !== USER_STATUS.ACTIVE ||
        ibfx1.status !== ASSETS_STATUS.ACTIVE ||
        // ibfx3.status !== ASSETS_STATUS.ACTIVE ||
        // ibfs.status !== ASSETS_STATUS.ACTIVE ||
        !localStorage.getItem("ENCRYPTED_MNEMONIC")
      ) {
        setIsVerified(false);
      } else {
        setIsVerified(true);
      }
    }
  };

  const defaultView = () => {
    return (
      <ColCenter
        style={{
          height: "100%",
        }}
      >
        <TitleStyled>{t("create_project")}</TitleStyled>
        <SubtitleStyled>{t("create_project_desc")}</SubtitleStyled>
        <CreateProjectCards>
          <CreateProjectCard>
            <TypeLabelStyled>{t("type_1")}</TypeLabelStyled>
            <CardTitleStyled>
              {/* {t('sadaqah_of')}&nbsp;<b>{t('asset_donation_based')}</b> */}
              {t("type_1_title")}
            </CardTitleStyled>
            <CardDescStyled>{/* {t("type_1_desc")} */}</CardDescStyled>
            <CardUlStyled>
              <CardLiStyled>
                <img src={checkMarkIcon} />
                <p>{t("type_1_goal_1")}</p>
              </CardLiStyled>
              <CardLiStyled>
                <img src={checkMarkIcon} />

                <p>{t("type_1_goal_2")}</p>
              </CardLiStyled>
              <CardLiStyled>
                <img src={checkMarkIcon} />

                <p>{t("type_1_goal_3")}</p>
              </CardLiStyled>
            </CardUlStyled>
            <ButtonStyled
              //   onClick={() => setType(PROJECT_TYPE.ASSET)}
              onClick={() => {
                dispatch(setBenevolenceProjectType(PROJECT_TYPE.ASSET));
                dispatch(setSection("create-project"));
                onClose();
              }}
              style={{
                marginTop: "auto",
              }}
            >
              {t("select")}
            </ButtonStyled>
          </CreateProjectCard>
          <CreateProjectCard>
            <TypeLabelStyled>{t("type_2")}</TypeLabelStyled>
            <CardTitleStyled>
              {/* {t('sadaqah_of')}&nbsp;<b>{t('effort_donation_based')}</b> */}
              {t("type_2_title")}
            </CardTitleStyled>
            <CardDescStyled>{/* {t("type_2_desc")} */}</CardDescStyled>
            <CardUlStyled>
              <CardLiStyled>
                <img src={checkMarkIcon} />
                <p>{t("type_2_goal_1")}</p>
              </CardLiStyled>
              <CardLiStyled>
                <img src={checkMarkIcon} />
                <p>{t("type_2_goal_2")}</p>
              </CardLiStyled>
              <CardLiStyled>
                <img src={checkMarkIcon} />
                <p>{t("type_2_goal_3")}</p>
              </CardLiStyled>
            </CardUlStyled>
            <ButtonStyled
              //   onClick={() => setType(PROJECT_TYPE.EFFORT)}
              disabled={IS_EVM}
              onClick={() => {
                dispatch(setBenevolenceProjectType(PROJECT_TYPE.EFFORT));
                dispatch(setSection("create-project"));
                onClose();
              }}
              style={{
                marginTop: "auto",
              }}
            >
              {t("select")}
            </ButtonStyled>
          </CreateProjectCard>
        </CreateProjectCards>
      </ColCenter>
    );
  };

  const resolveContent = () => {
    switch (type) {
      case PROJECT_TYPE.ASSET:
        return <CreateAssetProject setType={setType} />;
      case PROJECT_TYPE.EFFORT:
        return <CreateEffortProject setType={setType} />;
      default:
        return defaultView();
    }
  };

  const onClose = () => {
    dispatch(closeCreateProjectModal());
  };

  return !isVerified ? (
    <Modal
      visible={isModalVisible}
      centered
      className="modal-top-up"
      footer={null}
      onCancel={onClose}
      closeIcon={<img src={closeIcon} onClick={onClose} />}
      maskStyle={{ backgroundColor: "#f3f6fa" }}
    >
      <BenevolenceOnBoarding onClose={onClose} />
    </Modal>
  ) : (
    <ModalStyled
      maskStyle={{ backgroundColor: "#fafafa" }}
      visible={isModalVisible}
      onCancel={() => dispatch(closeCreateProjectModal())}
      closeIcon={
        <img
          style={{ marginRight: 10 }}
          src={closeModalIcon}
          onClick={() => dispatch(closeCreateProjectModal())}
        />
      }
      footer={null}
      width={"1096px"}
    >
      {resolveContent()}
    </ModalStyled>
  );
}
