import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import debounce from "lodash.debounce";
import CardBenevolence from "../../CardBenevolence";
import {
  PROJECT_STATUS,
  PROJECT_TYPE,
} from "../../../constants/constant_common";
import { useDispatch, useSelector } from "react-redux";
import { getAllVolunteer } from "../../../modules/benevolence/action";
import { fromIUSD } from "../../../utils/utils";
import { BENEVOLENCE_HOST } from "../../../configs";
// import EmptyProjects from "../../../assets/images/empty_benev_projects.svg";
import { ColCenter, RowSpaceBetween } from "../../general_styled";
import ShimmerCustom from "../../ShimmerCustom";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

export default function BenevolenceAllProjects() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isBottom, setIsBottom] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const allVolunteers = useSelector((state) =>
    state.getIn(["benevolence", "allVolunteers"]).toJS()
  );
  const userData = useSelector((state) =>
    state.getIn(["auth", "userData"]).toJS()
  );

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
      setIsBottom(true);
    }
  };

  const onScroll = useCallback(
    debounce(() => {
      if (allVolunteers.hasNext) {
        getAllVolunteer(PROJECT_TYPE.EFFORT, allVolunteers.currentPage + 1);
      }
      setIsBottom(false);
    }, 400),
    [isBottom]
  );

  useEffect(() => {
    if (isBottom) {
      onScroll();
    }
  }, [isBottom]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const initData = async () => {
    window.scrollTo(0, 0);
    await dispatch(getAllVolunteer(PROJECT_TYPE.EFFORT, 1));
    setIsProcessing(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const resolveShimmer = () => {
    let children = [];
    for (let i = 0; i < 9; i++) {
      children.push(
        <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
          <ShimmerCustom width="100%" height="140px" borderRadius="4px" />
          <RowSpaceBetween className="mt-12px">
            <ShimmerCustom width="30%" height="14px" borderRadius="2px" />
            <ShimmerCustom width="15%" height="14px" borderRadius="2px" />
          </RowSpaceBetween>
          <ShimmerCustom
            className="mt-12px"
            width="100%"
            height="24px"
            borderRadius="2px"
          />
          <RowSpaceBetween className="mt-12px">
            <ShimmerCustom width="30%" height="14px" borderRadius="2px" />
            <ShimmerCustom width="15%" height="14px" borderRadius="2px" />
          </RowSpaceBetween>
          <RowSpaceBetween className="mt-12px">
            <ShimmerCustom width="47.5%" height="40px" borderRadius="2px" />
            <ShimmerCustom width="47.5%" height="40px" borderRadius="2px" />
          </RowSpaceBetween>
        </Col>
      );
    }

    return children;
  };

  return isProcessing ? (
    <Row gutter={[24, 24]}>{resolveShimmer()}</Row>
  ) : allVolunteers.data.length > 0 ? (
    <Row gutter={[24, 24]}>
      {allVolunteers.data.map((e, i) => {
        return (
          <CardBenevolence
            key={i}
            project_id={e.project_id}
            ownerId={e.project_owner}
            time={e.end_at}
            image={`${BENEVOLENCE_HOST}/files/${e.cover_image_file}`}
            title={e.name}
            donors={e.total_unique_donation || 0}
            currentDonation={
              e.donation_amount == null ? 0 : fromIUSD(e.donation_amount)
            }
            totalDonation={e.fund_goal == null ? 0 : fromIUSD(e.fund_goal)}
            totalHours={e.total_hours_accepted || 0}
            hoursGoal={e.hours_goal || 0}
            isProjectOwner={e.project_owner === userData.user_id}
            type={e.project_type}
            status={e.status}
            volunteer={e.total_volunteer || 0}
            data={e}
          />
        );
      })}
    </Row>
  ) : (
    <Row gutter={24} style={{ paddingTop: 15, minHeight: "60vh" }}>
      <Col span={24}>
        <ColCenter className="w-100 h-100">
          {/* <img
            style={{ width: "40vh", height: "40vh" }}
            src={EmptyProjects}
            alt=""
          /> */}
          <Title level={3} align="center" className="mt-20px mb-4px">
            {t("no_project_available")}
          </Title>
          <Text> {t("no_project_desc")}</Text>
        </ColCenter>
      </Col>
    </Row>
  );
}
