import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  Button,
  notification,
  message as antdMessage,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
  CopyOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  ProfilePic,
  FormRow,
  ImageCanvas,
  LabelText,
  ValueText,
  HeaderSection,
} from "./styled";
import {
  ColStart,
  ColCenter,
  InputStyled,
  SelectStyled,
  TextAreaStyled,
} from "../../components/general_styled";
import ModalChangePassword from "../../components/ModalChangePassword";
import useWindowDimensions from "../../utils/windowDimensions";
import { getCountries } from "../../modules/benevolence/action";
import { useDispatch, useSelector } from "react-redux";
import EditCta from "../../assets/images/edit-cta.svg";
import DefaultAvatar from "../../assets/images/default-avatar.png";
import {
  getNonce,
  getUserDetail,
  updateUser,
  verifyNonce,
} from "../../modules/auth/action";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import { membershipBasicAuth, MEMBERSHIP_HOST, IS_EVM } from "../../configs";
import { useAccount, useSignMessage } from "wagmi";
import { setUSerSign } from "../../modules/general/action";

const { Text } = Typography;

export default function Profile() {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const { isConnected, address: evmAddress } = useAccount();

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [file, setFile] = useState();
  const [fileTemp, setFileTemp] = useState();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [referralEmail, setReferralEmail] = useState();
  const [address, setAddress] = useState(null);
  const [isUserAlreadySign, setIsUserAlreadySign] = useState(false);

  const userData = useSelector((state) =>
    state.getIn(["auth", "userData"]).toJS()
  );
  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );

  const [message, setMessage] = useState(null);
  const { signMessage, status } = useSignMessage({
    // call function signMessage
    message,
    async onSuccess(result) {
      // result to use handle backend for get jsonwebtoken
      const body = {
        wallet_address: evmAddress,
        nonce: message,
        signature_hash: result,
      };
      const isValid = await verifyNonce(body);
      if (isValid) {
        localStorage.setItem("wagmi.usersign", evmAddress);
        setIsUserAlreadySign(true);
        dispatch(setUSerSign({ address: evmAddress }));
      }
      setMessage(null);
      setIsLoading(false);
    },
    onError(err) {
      console.log("err: ", err);
      notification.error({
        message: "Verify Failed",
        description: err,
      });
      setIsLoading(false);
    },
  });

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  useEffect(() => {
    if (status === "success") {
      antdMessage.success("Successfully verify your wallet address");
    }
    if (status === "error") {
      antdMessage.error("Failed verify your wallet address");
    }
  }, [status]);

  useEffect(() => {
    if (message) {
      signMessage?.();
    }
  }, [message]);

  useEffect(() => {
    initContries();
  }, []);

  useEffect(() => {
    initUserEditData();
  }, [userData])

  useEffect(() => {
    const currentSignUser = localStorage.getItem("wagmi.usersign");
    if (currentSignUser && evmAddress === currentSignUser) {
      setIsUserAlreadySign(true);
      dispatch(setUSerSign({ address: currentSignUser }));
    } else {
      setIsUserAlreadySign(false);
    }
  }, [isConnected, evmAddress]);

  const initUserEditData = () => {
    setName(userData.name);
    setEmail(userData.email);
    setAddress(userData.address !== null ? userData.address : "");
    setReferralEmail(userData.referral_email);
  };

  const initContries = async () => {
    const results = await getCountries();
    setCountries(results);
  };

  const onCopyAlgoAddress = () => {
    navigator.clipboard.writeText(
      IS_EVM ? evmAddress : walletUser.algo_address
    );

    antdMessage.success("Copied to clipboard");
  };

  const onEditProfile = () => {
    setEditMode(true);
  };

  const onSubmit = async () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setIsLoading(true);
      try {
        const result = await updateUser({
          photo: file ? file : userData.photo,
          name: name,
          address: address,
          city: userData?.city,
          country: country ? country : userData.country,
          referral_email: referralEmail || userData.referral_email,
        });

        if (result.code === "SUCCESS") {
          notification.success({
            message: "Success!",
            description: `Successfully update your profile`,
          });
          await dispatch(getUserDetail());
        } else {
          notification.error({
            message: "Failed!",
            description: result.message,
          });
        }

        setEditMode(false);
      } catch (e) {
        console.log(e);
        notification.error({
          message: "Failed to Update Profile",
          description: extractErrorMessage(e),
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      antdMessage.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      antdMessage.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const propsLogoFile = {
    name: "file",
    multiple: false,
    action: `${MEMBERSHIP_HOST}/files`,
    beforeUpload: beforeUpload,
    headers: {
      Authorization: membershipBasicAuth,
    },
    onChange: (info) => {
      const { status, response } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        getBase64(info.file.originFileObj, (imageUrl) => setFileTemp(imageUrl));
        setFile(response?.data.filename);
        antdMessage.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        antdMessage.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const trimAddress = (address) => {
    return (
      address.substring(0, 7) +
      "..." +
      address.substring(address.length - 7, address.length - 1)
    );
  };

  const onVerify = async () => {
    try {
      setIsLoading(true);
      const nonce = await getNonce(evmAddress);
      setMessage(nonce);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Verify Failed",
        description: "Error when verify your wallet, please try again later",
      });
    }
  };

  return (
    <>
      <HeaderSection>
        <ImgCrop grid rotate>
          <Upload
            name="avatar"
            // listType="picture-card"
            className="avatar-uploader mt-36px"
            showUploadList={false}
            disabled={!editMode}
            {...propsLogoFile}
          >
            <ImageCanvas>
              <ProfilePic
                shape="square"
                size="large"
                src={
                  fileTemp ? (
                    <img src={fileTemp} />
                  ) : userData && userData.photo && userData.photo !== null ? (
                    <img src={`${MEMBERSHIP_HOST}/files/${userData.photo}`} />
                  ) : (
                    <img src={DefaultAvatar} />
                  )
                }
              />
              {editMode && <img className="edit" src={EditCta} alt="" />}
            </ImageCanvas>
          </Upload>
        </ImgCrop>
        {!editMode && (
          <div
            className={
              "h-100 " +
              (windowDimensions.width > 949 ? "ml-32px mt-36px" : "mt-24px")
            }
          >
            <ColStart>
              <LabelText>{IS_EVM ? "" : "Algorand"} Address</LabelText>
              <ValueText className="my-5px">
                {IS_EVM
                  ? isConnected
                    ? evmAddress
                    : "-"
                  : walletUser.algo_address
                  ? trimAddress(walletUser.algo_address)
                  : "-"}
              </ValueText>
              <div
                onClick={onCopyAlgoAddress}
                style={{ color: "#0236F9", cursor: "pointer" }}
              >
                <CopyOutlined className="mr-5px" style={{ color: "#0066FF" }} />
                Copy
              </div>
            </ColStart>
            {isConnected && (
              <Button
                className="mt-12px"
                style={{ minWidth: "50%" }}
                type="primary"
                disabled={isUserAlreadySign}
                onClick={() => onVerify()}
              >
                {isLoading ? antIcon : "Verify Address"}
              </Button>
            )}
          </div>
        )}
      </HeaderSection>
      {editMode ? (
        <>
          <div style={windowDimensions.width > 1200 ? { maxWidth: "50%" } : {}}>
            <Row className="my-24px">
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Row>
                  <Col span={24}>
                    <ColCenter className="mb-8px mt-16px">
                      <Text className="w-100" align="left">
                        Full Name
                      </Text>
                    </ColCenter>
                  </Col>
                  <Col span={24}>
                    <InputStyled
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Full Name"
                      size="large"
                    />
                    <span style={{ display: "none" }}>{name}</span>
                  </Col>
                  <Col span={24}>
                    <ColCenter className="mb-8px mt-16px">
                      <Text className="w-100" align="left">
                        Email Address
                      </Text>
                    </ColCenter>
                  </Col>
                  <Col span={24}>
                    <InputStyled
                      value={email}
                      readOnly
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address"
                      size="large"
                    />
                  </Col>
                  <Col span={24}>
                    <ColCenter className="mb-8px mt-16px">
                      <Text className="w-100" align="left">
                        Country of Residence
                      </Text>
                    </ColCenter>
                  </Col>
                  <Col span={24}>
                    <SelectStyled
                      showSearch
                      className="w-100"
                      type="dropdown"
                      name="country"
                      size="large"
                      align="left"
                      filterOption={true}
                      placeholder={
                        editMode &&
                        userData.country &&
                        userData.country.toString() !== "null"
                          ? userData.country
                          : "Country of Residence"
                      }
                      value={country}
                      options={countries.map((country) => {
                        return {
                          label: country.name,
                          value: JSON.stringify(country),
                        };
                      })}
                      onChange={async (val) => {
                        const jsonDecode = JSON.parse(val);

                        setCountry(jsonDecode.name);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Row className={windowDimensions.width <= 990 ? "" : "ml-20px"}>
                  <Col span={24}>
                    <ColCenter className="mb-8px mt-16px">
                      <Text className="w-100" align="left">
                        Referral Email
                      </Text>
                    </ColCenter>
                  </Col>
                  <Col span={24}>
                    <InputStyled
                      value={referralEmail}
                      onChange={(e) => setReferralEmail(e.target.value)}
                      placeholder="Referral Email"
                      size="large"
                    />
                  </Col>
                  <Col span={24}>
                    <ColCenter className="mb-8px mt-16px">
                      <Text className="w-100" align="left">
                        Address
                      </Text>
                    </ColCenter>
                  </Col>
                  <Col span={24}>
                    <TextAreaStyled
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      rows={5}
                      placeholder="Address"
                    />

                    <span style={{ display: "none" }}>{address}</span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Button
              type="primary"
              size="large"
              disabled={!(address !== "" && country !== "" && name !== "")}
              onClick={onSubmit}
              block
            >
              {isLoading ? antIcon : "Save Changes"}
            </Button>
          </div>
        </>
      ) : (
        <>
          <Row className="mb-36px mt-12px">
            <Col>
              <FormRow>
                <LabelText>Full Name</LabelText>
                <ValueText>
                  {userData.name && userData.name.toString() !== "null"
                    ? userData.name
                    : "-"}
                </ValueText>
              </FormRow>
              <FormRow>
                <LabelText>Email Address</LabelText>
                <ValueText>
                  {userData.email && userData.email.toString() !== "null"
                    ? userData.email
                    : "-"}
                </ValueText>
              </FormRow>
              <FormRow>
                <LabelText>Country</LabelText>
                <ValueText>
                  {userData.country && userData.country.toString() !== "null"
                    ? userData.country
                    : "-"}
                </ValueText>
              </FormRow>
              <FormRow>
                <LabelText>Address</LabelText>
                <ValueText>
                  {userData.address && userData.address.toString() !== "null"
                    ? userData.address
                    : "-"}
                </ValueText>
              </FormRow>
              <FormRow>
                <LabelText>Referral Email</LabelText>
                <ValueText>
                  {userData.referral_email &&
                  userData.referral_email.toString() !== "null"
                    ? userData.referral_email
                    : "-"}
                </ValueText>
              </FormRow>
            </Col>
          </Row>
          <div
            className="mb-24px"
            style={windowDimensions.width > 1200 ? { maxWidth: "50%" } : {}}
          >
            <Button
              type="primary"
              ghost
              size="large"
              onClick={onEditProfile}
              block
            >
              Edit Profile
            </Button>
            <ModalChangePassword />
          </div>
        </>
      )}
    </>
  );
}
