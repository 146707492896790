import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncement } from "../../modules/announcement/action";
import emptyFeed from "../../assets/images/empty-feed.svg";
import { ColCenter } from "../../components/general_styled";
import { Button, Col, Image, Row, Typography } from "antd";
import { MEMBERSHIP_HOST } from "../../configs";
import { CardStyled, CardTitle, GreenText, OverviewText } from "./styled";
import moment from "moment";
import { debounce } from "lodash";
import { setSection } from "../../modules/general/action";
import ShimmerCustom from "../../components/ShimmerCustom";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export default function Feed() {
  const { t } = useTranslation();
  // const isFromHome = props?.source === "home";
  const announcement = useSelector((state) =>
    state.getIn(["announcement"]).toJS()
  );
  const dispatch = useDispatch();
  const [isBottom, setIsBottom] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    initAnnouncements();
  }, []);

  const initAnnouncements = async () => {
    await dispatch(getAnnouncement());
    setLoading(false);
  };

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (scrollTop + window.innerHeight >= scrollHeight) {
      setIsBottom(true);
    }
  };

  const onScroll = useCallback(
    debounce(() => {
      if (announcement.announcements.hasNext) {
        dispatch(getAnnouncement(announcement.announcements.currentPage + 1));
      }
      setIsBottom(false);
    }, 400),
    [isBottom]
  );
  const resolveContent = () => {
    if (!isLoading) {
      return announcement.announcements.data.length <= 0 ? (
        <ColCenter style={{ height: "100%", minHeight: "calc(100vh - 120px)" }}>
          <Image preview={false} src={emptyFeed} width={251} />
          <Title align="center" level={3}>
            {t("empty_feed")}
          </Title>
        </ColCenter>
      ) : (
        <>
          <Row className="mt-10px mb-5px">
            <Col span={24}>
              <Title level={3}> {t("feed")}</Title>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              alignItems: "stretch",
            }}
          >
            {announcement.announcements.data.map((data, key) => {
              var div = document.createElement("div");
              div.innerHTML = data.content;
              var text = div.textContent || div.innerText || "";

              return (
                <Col id={key} xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                  <CardStyled
                    style={{
                      boxShadow:
                        "rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px, rgb(9 30 66 / 14%) 0px 0px 20px -6px",
                    }}
                    key={key}
                    cover={
                      <Image
                        preview={false}
                        src={`${MEMBERSHIP_HOST}/files/${data.image}`}
                        // src={"https://wallpaperaccess.com/full/1677778.jpg"}
                      />
                    }
                    actions={[
                      <Button
                        type="primary"
                        ghost
                        style={{ width: "100%", height: "40px", borderRadius: '100px' }}
                        onClick={() => {
                          dispatch(
                            setSection("feed-details", data.announcement_id)
                          );
                        }}
                      >
                        {t("view")}
                      </Button>,
                    ]}
                  >
                    <Col>
                      <GreenText>
                        {moment(data.created_date).format(
                          "D MMMM[,] YYYY [at] h:mm"
                        )}
                      </GreenText>
                      <CardTitle>{data.title}</CardTitle>
                      <OverviewText>
                        {text.length > 150
                          ? text.substring(0, 149) + "..."
                          : text}
                      </OverviewText>
                    </Col>
                  </CardStyled>
                </Col>
              );
            })}
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row className="mt-10px mb-5px">
            <Col span={24}>
              <Title level={3}> {t("feed")}</Title>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              alignItems: "stretch",
            }}
          >
            {resolveShimmer()}
          </Row>
        </>
      );
    }
  };

  const resolveShimmer = () => {
    let children = [];
    for (let i = 0; i < 9; i++) {
      children.push(
        <Col
          key={i}
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <ShimmerCustom width="100%" height="150px" borderRadius="2px" />
          <ShimmerCustom
            width="80%"
            height="20px"
            borderRadius="2px"
            className="mt-12px"
          />
          <ShimmerCustom width="60%" height="12px" borderRadius="2px" />
          <ShimmerCustom width="50%" height="12px" borderRadius="2px" />
        </Col>
      );
    }

    return children;
  };

  useEffect(() => {
    if (isBottom) {
      onScroll();
    }
  }, [isBottom]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return <React.Fragment>{resolveContent()}</React.Fragment>;
}
