import { useTranslation } from 'react-i18next'
import { Col, message, notification, Upload } from 'antd'
import axios from 'axios'
import UploadIcon from '../../../../assets/images/upload_icon.svg'
import { BENEVOLENCE_HOST, headers } from '../../../../configs'
import { DraggerStyled } from '../../../general_styled'
import { FormGroupTitle } from '../../styled'

export default (props) => {
	const { t } = useTranslation()

	const { Dragger } = Upload

	const onUpload = async (param) => {
		try {
			const formData = new FormData()
			formData.append('file', param.file, param.file.name)
			const response = (await axios.post(`${BENEVOLENCE_HOST}/files`, formData, { headers: headers })).data
			param.onSuccess(response.data, param.file)
			message.success(t('success_upload_document'))
		} catch (e) {
			const error = e.response ? (e.response.data ? e.response.data : e.response) : t('failed_to_upload')
			param.onError(error, undefined)
			message.success(error)
		}
	}

	const beforeUpload = (file) => {
		const isLt2M = file.size / 1024 / 1024 < 10.1
		if (!isLt2M) {
			message.error(t('file_must_smaller_10mb'))
		}
		return isLt2M
	}

	const draggerProps = {
		name: 'file',
		multiple: false,
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		maxCount: 3,
		defaultFileList: props.documents,
		customRequest: onUpload,
		beforeUpload: beforeUpload,
		onChange(info) {
			const { status } = info.file
			if (status !== 'uploading') {
			}
			if (status === 'done') {
				props.setDocuments([...info.fileList])
			} else if (status === 'error') {
				notification.error({
					message: t('upload_failed'),
					description: info.file.error,
				})
			} else if (status === 'removed') {
				props.setDocuments([...info.fileList])
			}
		},
	}

	return (
		<Col span={18}>
			<FormGroupTitle
				style={{
					marginBottom: '24px',
				}}
			>
				{t('upload_project_related_doc')}
			</FormGroupTitle>
			<div className="upload-form">
				<div className="upload-document">
					<DraggerStyled {...draggerProps}>
						<div className="details">
							<img src={UploadIcon} />
							<span className="title">
								{t('drop_file')} <span style={{ color: '#EF348D', fontWeight: 'bold' }}>{t('browse')}</span>
							</span>
							<span className="desc">{t('max_size')} 10mb</span>
						</div>
					</DraggerStyled>
				</div>
			</div>
		</Col>
	)
}
