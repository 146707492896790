import styled from "@emotion/styled";
import { Card, Divider, Typography } from "antd";

const { Text } = Typography;

export const CardStyled = styled(Card)`
  border-radius: 4px;
  position: relative;
  height: 100%;

  .ant-card-body {
    min-height: 316px;
    display: flex;
    flex-direction: column;

    .start {
      margin-bottom: auto;
    }
  }
`;

export const DivididerStyled = styled(Divider)`
  .ant-divider-dashed {
    border: 1px dashed #d6dae1 !important;
  }
`;

export const TitleStyled = styled(Text)`
  font-weight: ${(props) => (props.isTitle ? "500" : "400")};
  font-size: 15px;
  line-height: 20px;
  color: #364559;
`;

export const SubTitleStyled = styled(Text)`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #364559;
`;
export const DonationTextStyled = styled(Text)`
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  color: #293544;
`;
