import { fromJS } from "immutable";
import { CLOSE_MODAL_CREATE_NFT, OPEN_MODAL_CREATE_NFT } from "../constants";

const initialState = fromJS({
  isModalVisible : false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL_CREATE_NFT:
      return state.merge({
        isModalVisible: true
      });
    case CLOSE_MODAL_CREATE_NFT:
      return state.merge({
        isModalVisible: false
      });
    default:
      return state;
  }
};