import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { CREDENCE_HOST } from '../../configs'
import {
	SET_AFFLUENCE,
	SET_AVAILABLE_NFT,
	SET_DETAIL_AFFLUENCE,
	SET_OFFER,
	SET_OWNED_AUCTION,
	SET_PRICE_HISTORY_AUCTION,
	SET_SELECTED_COLLECTION,
	SET_VISITED_USER,
	UPDATE_AUCTION_WISHLIST,
} from '../constants'
import { signingOperation } from '../wallet/action'

export const getAllAuction =
	(userId, page = 1, search, collection_id, category = null, callback) =>
	async (dispatch) => {
		try {
			const jwt = localStorage.getItem('USER_AUTH_TOKEN')
			let url = `${CREDENCE_HOST}/auctions?page=${page}&size=12`
			if (userId) {
				url += `&owner_id=${userId}`
			}
			if (search) {
				url += `&search=${search}`
			}
			if (collection_id) {
				url += `&collection_id=${collection_id}`
			}
			if (category) {
				url += `&category=${category}`
			}
			const nfts = (await axios.get(url, { headers: { Authorization: `Bearer ${jwt}` } })).data
			if (callback) {
				return nfts
			} else {
				// console.log({
				//   data: nfts.data.records,
				//   currentPage: page,
				//   hasNext: page * 15 < nfts.data.total_items,
				//   total: nfts.data.total_items,
				// });

				dispatch({
					type: SET_AFFLUENCE,
					payload: {
						data: nfts.data.records,
						currentPage: page,
						hasNext: page * 12 < nfts.data.total_items,
						total: nfts.data.total_items,
					},
				})
			}
		} catch (e) {
			console.log(e)
		}
	}

export const createBidToAuction = async (mnemonic, body) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(
				`${CREDENCE_HOST}/transactions/bid`,
				{ ...body },
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const setDetailNFT = (data, fromAPI) => async (dispatch) => {
	let metadata
	if (fromAPI) {
		metadata = JSON.parse(data.auctionData.NFT.metadata_json)
	} else {
		metadata = JSON.parse(data.NFT.metadata_json)
	}
	dispatch({
		type: SET_DETAIL_AFFLUENCE,
		payload: { ...data, metadata },
	})
}

export const getAllTransaction =
	(userId, page = 1, status, type, received, search, callback) =>
	async (dispatch) => {
		try {
			const jwt = localStorage.getItem('USER_AUTH_TOKEN')
			let url = `${CREDENCE_HOST}/transactions?page=${page}&size=15`
			if (userId) {
				url += `&user_id=${userId}`
			}
			if (status) {
				url += `&status=${status}`
			}
			if (type) {
				url += `&type=${type}`
			}
			if (received) {
				url += `&bid_received=true`
			}
			if (search) {
				url += `&search=${search}`
			}
			const nfts = (await axios.get(url, { headers: { Authorization: `Bearer ${jwt}` } })).data
			if (callback) {
				return nfts
			} else {
				dispatch({
					type: SET_OFFER,
					payload: {
						data: nfts.data.records,
						currentPage: page,
						hasNext: page * 15 < nfts.data.total_items,
						total: nfts.data.total_items,
					},
				})
			}
		} catch (e) {
			console.log(e)
		}
	}

export const acceptBidAuction = async (mnemonic, id) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(
				`${CREDENCE_HOST}/auctions/accept/${id}`,
				{},
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const createDepositNft = async (mnemonic, body) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${CREDENCE_HOST}/auctions/deposit`, body, {
				headers: headers,
			})
		).data

		if (result.data.code === 'PARAM_ILLEGAL') {
			throw result.data.message
		}

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.response.message
	}
}

export const createAuction = async (mnemonic) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(
				`${CREDENCE_HOST}/auctions`,
				{},
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const activateAuction = async (mnemonic, body) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(
				`${CREDENCE_HOST}/auctions/create-escrow`,
				{ ...body },
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getAllOwnedAuction = (userId, callback) => async (dispatch) => {
	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')
		let url = `${CREDENCE_HOST}/auctions/owned?page=1&size=15&owner_id=${userId}`
		const auctions = (await axios.get(url, { headers: { Authorization: `Bearer ${jwt}` } })).data
		if (callback) {
			return auctions
		} else {
			dispatch({
				type: SET_OWNED_AUCTION,
				payload: auctions.data.records,
			})
		}
	} catch (e) {
		console.log(e)
	}
}

export const delistingAuction = async (mnemonic, id) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(
				`${CREDENCE_HOST}/auctions/close/${id}`,
				{},
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getDetailAuction = (id) => async (dispatch) => {
	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')

		const nfts = (
			await axios.get(`${CREDENCE_HOST}/auctions/${id}`, {
				headers: { Authorization: `Bearer ${jwt}` },
			})
		).data

		// console.log({ nfts })
		dispatch(setDetailNFT(nfts.data, true))
	} catch (e) {
		console.log(e)
	}
}

export const getPriceHistory = (nft_id, startDate, endDate) => async (dispatch) => {
	try {
		// const jwt = localStorage.getItem('USER_AUTH_TOKEN')

		const result = (
			await axios.get(`${CREDENCE_HOST}/nft/price_history/${nft_id}`, {
				params: {
					start_date: startDate,
					end_date: endDate,
					type: 'BID',
				},
			})
		).data

		const priceHistory = result?.data?.nft?.priceHistory

		dispatch({
			type: SET_PRICE_HISTORY_AUCTION,
			payload: priceHistory || [],
		})
	} catch (e) {
		console.log(e)
	}
}

export const updateWishlistAuction = (course) => (dispatch) => {
	dispatch({
		type: UPDATE_AUCTION_WISHLIST,
		payload: {
			...course,
		},
	})
}

export const getAvailableNFT = () => async (dispatch) => {
	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')
		const decodedUser = jwtDecode(localStorage.getItem('USER_AUTH_TOKEN'))

		const result = (
			await axios.get(`${CREDENCE_HOST}/nft/deposit`, {
				headers: { Authorization: `Bearer ${jwt}` },
				params: {
					user_id: decodedUser.user_id,
				},
			})
		).data

		const nfts = result?.data?.nfts

		dispatch({
			type: SET_AVAILABLE_NFT,
			payload: nfts,
		})
	} catch (e) {
		console.log(e)
	}
}

export const setVisitedUser = (userData) => (dispatch) => {
	dispatch({
		type: SET_VISITED_USER,
		payload: {
			...userData,
		},
	})
}

export const setSelectedCollection = (collection) => (dispatch) => {
	dispatch({
		type: SET_SELECTED_COLLECTION,
		payload: {
			...collection,
		},
	})
}
