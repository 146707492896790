import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { setDetailNFT } from "../../modules/essence/action";
import { setDetailNFT as affluenceSetDetail } from "../../modules/affluence/action";
import { setSection } from "../../modules/general/action";
import { CardStyled, CollectionNameStyled, ImageStyled } from "./styled";
import useWindowDimensions from "../../utils/windowDimensions";
import { IS_EVM } from "../../configs";

export default function CardUserCollectionStandAlone(props) {
  const windowDimensions = useWindowDimensions();
  const metadata = JSON.parse(props.data.metadata_json);
  const dispatch = useDispatch();

  const onCardClicked = async () => {
    if (IS_EVM) {
      const transaction = props?.data?.NFT[0];
      dispatch(
        setSection(
          "affluence-buy-sell-detail-nft",
          transaction?.trx_id.toString()
        )
      );
      dispatch(setDetailNFT({ ...transaction, NFT: { ...props?.data } }));
    } else {
      if (props.data.active_sell_escrow) {
        dispatch(
          setSection(
            "affluence-buy-sell-detail-nft",
            props.data?.active_sell_escrow.sell_escrow_id.toString()
          )
        );
        dispatch(
          setDetailNFT({ ...props.data?.active_sell_escrow, NFT: props.data })
        );
      } else if (props.data.active_auction) {
        dispatch(
          setSection(
            "affluence-auction-detail-nft",
            props.data?.active_auction.auction_id.toString()
          )
        );
        dispatch(
          affluenceSetDetail({ ...props.data?.active_auction, NFT: props.data })
        );
      }
    }
  };

  const renderImage = () => {
    return (
      <Col span={24}>
        <ImageStyled className={`full`} src={props.data?.file} />
      </Col>
    );
  };

  return (
    <Col
      key={props.key}
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={windowDimensions.width < 1441 ? 8 : 6}
      xxl={6}
    >
      <CardStyled onClick={() => onCardClicked()}>
        <Row>{renderImage()}</Row>
        <CollectionNameStyled>{metadata.name}</CollectionNameStyled>
      </CardStyled>
    </Col>
  );
}
