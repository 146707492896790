import { fromJS } from "immutable"
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_FORGOT_PASSWORD,
  SET_REGISTER,
  SET_USER_DATA,
} from "../constants"

const initialState = fromJS({
  isAuthorized: false,
  forgotPassword: fromJS({}),
  dataRegister: fromJS({}),
  token: "",
  userData: fromJS({}),
  hasError: false,
  errorMessage: "",
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return state.merge({
        userData: fromJS(action.payload.userData),
      })
    case LOGIN:
      return state.merge({
        isLoading: true,
      })
    case LOGIN_SUCCESS:
      return state.merge({
        token: action.payload.token,
        isLoading: false,
        isAuthorized: true,
        userData: fromJS(action.payload.userData),
      })
    case LOGIN_FAIL:
      return state.merge({
        isLoading: false,
        hasError: true,
        errorMessage: fromJS(action.payload.message),
      })
    case LOGOUT:
      return state.merge({
        isAuthorized: false,
        userData: fromJS({}),
        hasError: false,
        errorMessage: "",
      })
    case SET_FORGOT_PASSWORD:
      return state.merge({
        forgotPassword: fromJS(action.payload.data),
      })
    case SET_REGISTER:
      return state.merge({
        dataRegister: fromJS(action.payload.data),
      })
    default:
      return state
  }
}
