import styled from "@emotion/styled";
import { Row, Typography } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

export const Canvas = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const CustomRow = styled(Row)`
  width: 100%;
`;

export const ImageLogin = styled.div`
  background-size: cover;
  background-image: url(${(props) => props.img});

  @media screen and (min-width: 835px) {
    width: 100%;
    height: 100vh;
  }

  @media screen and (max-width: 834px) {
    width: 100%;
    height: 0vh;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 835px) {
    height: 100vh;
    // padding: 0 24px;
  }

  @media screen and (max-width: 834px) {
    min-height: 100vh;
    padding: 24px 0;
  }
`;

export const FormCustom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  @media screen and (min-width: 835px) {
    width: 60%;
  }

  @media screen and (max-width: 834px) {
    width: 80%;
  }
`;

export const LinkStyled = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Secondary/500 */

  color: #3b4552;
`;
export const TextStyled = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;

  color: #273248;
`;

export const BlueLink = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Daybreak Blue/blue-6 */

  color: #024FF1;
`;
