import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import UploadCoverPhoto from "../../../../assets/images/upload-cover-photo.svg";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import Dragger from "antd/lib/upload/Dragger";
import { Col, Input, message, notification } from "antd";
import draftToHtml from "draftjs-to-html";
import {
  DraggerStyled,
  EditorStyled,
  InputStyled,
} from "../../../general_styled";
import { FormGroup, FormGroupTitle } from "../../styled";
import axios from "axios";
import { BENEVOLENCE_HOST, headers } from "../../../../configs";
import { INPUT_STATUS } from "../../../../constants/constant_common";

export default (props) => {
  const { t } = useTranslation();
  const [editorFocus, setEditorFocus] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    onEditorStateChange();
  }, [editorState]);

  const onEditorStateChange = () => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    props.setDescription({ ...props.description, value: content });
  };

  const onUpload = async (param) => {
    try {
      const formData = new FormData();
      formData.append("file", param.file, param.file.name);
      const response = (
        await axios.post(`${BENEVOLENCE_HOST}/files`, formData, {
          headers: headers,
        })
      ).data;
      param.onSuccess(response.data, param.file);
      message.success(t("success_upload_cover"));
    } catch (e) {
      const error = e.response
        ? e.response.data
          ? e.response.data
          : e.response
        : t("failed_to_upload_cover");
      param.onError(error, undefined);
      message.success(error);
    }
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    customRequest: onUpload,
    maxCount: 1,
    defaultFileList: props.coverImage ? [props.coverImage] : [],
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        props.setCoverImage([...info.fileList]);
      } else if (status === "error") {
        notification.error({
          message: t("upload_failed"),
          description: info.file.error,
        });
      } else if (status === "removed") {
        props.setCoverImage([...info.fileList]);
      }
    },
  };

  return (
    <Col span={18}>
      <DraggerStyled {...draggerProps}>
        <div className="upload-cover-photo">
          <img src={UploadCoverPhoto} />
          <span className="title">{t("upload_cover_photo")}</span>
          <span className="desc">{t("max_size_cover")}</span>
        </div>
      </DraggerStyled>
      <FormGroupTitle className="mt-24px mb-24px">
        {t("enter_project_details")}
      </FormGroupTitle>
      <FormGroup
        label={t("project_title")}
        colon={false}
        validateStatus={props.name.validateStatus}
        help={props.name.help}
      >
        <InputStyled
          type="text"
          value={props.name.value}
          placeholder={t("project_title")}
          onChange={(e) => {
            if (props.name.value.length <= 40) {
              props.setName({
                validateStatus: INPUT_STATUS.SUCCESS,
                help: "",
                value: e.target.value,
              });
            } else {
              props.setName({
                validateStatus: INPUT_STATUS.ERROR,
                help: t("max_length_title"),
                value: e.target.value,
              });
            }
          }}
        />
      </FormGroup>
      <FormGroup
        className="pt-6px"
        label={t("project_desc")}
        colon={false}
        validateStatus={props.description.validateStatus}
        help={props.description.help}
      >
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={setEditorState}
          placeholder="Description"
          onFocus={() => setEditorFocus(true)}
          onBlur={() => setEditorFocus(false)}
        />
      </FormGroup>
    </Col>
  );
};
