import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Image, Input, Row, Upload } from "antd";
import { useEffect, useState } from "react";
import TrashIcon from "../../../../assets/images/trash.svg";
import { INPUT_STATUS } from "../../../../constants/constant_common";
import { ColCenter, InputStyled } from "../../../general_styled";
import {
  AddContributionStyled,
  FormGroup,
  FormGroupLabel,
  FormGroupSubTitle,
  FormGroupTitle,
} from "../../styled";

export default (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.contributions.length < 1) {
      addContribution();
    }
  }, []);

  const addContribution = () => {
    props.contributions.push({
      skill: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
      totalHour: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: 0,
      },
      ratePerHour: {
        validateStatus: INPUT_STATUS.SUCCESS,
        help: "",
        value: "",
      },
    });
    props.setContributions([...props.contributions]);
  };

  const deleteContribution = (key) => {
    const newContributions = [];
    for (var index = 0; index < props.contributions.length; index++) {
      if (index != key) {
        newContributions.push(props.contributions[index]);
      }
    }
    props.setContributions(newContributions);
  };

  return (
    <Col span={18}>
      <FormGroupTitle>{t("add_skill")}</FormGroupTitle>
      <FormGroupSubTitle>{t("add_skill_desc")}</FormGroupSubTitle>
      {props.contributions.map((contribution, key) => {
        return (
          <Row
            gutter={[24, 24]}
            style={{
              marginBottom: "24px",
            }}
          >
            <Col span={props.contributions.length > 1 ? 11 : 12}>
              <FormGroup
                label={t("skill_required")}
                colon={false}
                validateStatus={contribution.skill.validateStatus}
                help={contribution.skill.help}
              >
                <InputStyled
                  type="text"
                  placeholder={t("skill_required")}
                  value={contribution.skill.value}
                  onChange={(e) => {
                    props.contributions[key].skill = {
                      validateStatus: INPUT_STATUS.SUCCESS,
                      help: "",
                      value: e.target.value,
                    };
                    props.setContributions([...props.contributions]);
                  }}
                />
              </FormGroup>
            </Col>
            <Col span={6}>
              <FormGroup
                label={t("total_working_hours")}
                colon={false}
                validateStatus={contribution.totalHour.validateStatus}
                help={contribution.totalHour.help}
              >
                <InputStyled
                  type="text"
                  placeholder={t("total_working_hours")}
                  value={contribution.totalHour.value}
                  onChange={(e) => {
                    props.contributions[key].totalHour = {
                      validateStatus: INPUT_STATUS.SUCCESS,
                      help: "",
                      value: e.target.value,
                    };
                    props.setContributions([...props.contributions]);
                  }}
                />
              </FormGroup>
            </Col>
            <Col span={6}>
              <FormGroup
                label={t("rate_per_hour")}
                colon={false}
                validateStatus={contribution.ratePerHour.validateStatus}
                help={contribution.ratePerHour.help}
                tooltip={{
                  title:
                    "for estimation purpose only; based on what you currently pay to your staff doing similar job",
                }}
              >
                <InputStyled
                  type="text"
                  suffix="$"
                  placeholder={t("rate_per_hour")}
                  value={contribution.ratePerHour.value}
                  currency={t("usd/hr")}
                  onChange={(e) => {
                    props.contributions[key].ratePerHour = {
                      validateStatus: INPUT_STATUS.SUCCESS,
                      help: "",
                      value: e.target.value,
                    };
                    props.setContributions([...props.contributions]);
                  }}
                />
              </FormGroup>
            </Col>
            {props.contributions.length > 1 ? (
              <ColCenter span={1}>
                <Image
                  preview={false}
                  width={24}
                  src={TrashIcon}
                  onClick={() => deleteContribution(key)}
                  style={{
                    pointer: "cursor",
                  }}
                />
              </ColCenter>
            ) : (
              <></>
            )}
          </Row>
        );
      })}

      <AddContributionStyled onClick={() => addContribution()}>
        <FontAwesomeIcon icon={faPlus} />
        <span>{t("add_skill")}</span>
      </AddContributionStyled>
    </Col>
  );
};
