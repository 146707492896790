import React, { useState } from "react";
import { Modal, Typography, Button, Drawer, Progress } from "antd";
import { ColCenter, RowSpaceBetween } from "../general_styled";

import {
  ContentStyled,
  TitleStyled,
  TextStyled,
  AcceptButton,
  ModalContent,
  DividerCard,
  FooterButtonContent,
  ModalContentKey,
  ModalContentValue,
} from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";

import ModalConfluenceOffers from "../ModalConfluenceOffers";
import ModalConfluenceOfferCounter from "../ModalConfluenceOfferCounter";

import ibfx1 from "../../assets/images/ibfx-1.svg";
import ibfx4 from "../../assets/images/ibfx-4.svg";
import { fromIBFx } from "../../utils/utils";

const { Title, Text } = Typography;

export default function ModalConfluenceOfferDetail(props) {
  const windowDimensions = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMode, setIsMode] = useState(0);
  const [isSuccess, setIsSuccess] = useState(0);
  const isActive = props.isActive;
  const data = props.data;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsModalVisible(false);
  };

  const resolveSuccess = (num) => (
    <ColCenter className="h-100" span={24}>
      <Progress
        type="circle"
        percent={100}
        status={num ? "success" : "exception"}
      />
      <TitleStyled
        style={{
          marginTop: "24px",
        }}
      >
        {num ? "Success!" : "Failed"}
      </TitleStyled>
      <TextStyled>
        {num
          ? "Offer Accepted."
          : "Failed when update offer, please try again."}
      </TextStyled>
      <Button
        block
        type="primary"
        style={{
          marginTop: "24px",
          height: 40,
        }}
        onClick={() => {
          handleCancel();
        }}
      >
        Dismiss
      </Button>
    </ColCenter>
  );

  return (
    <>
      <>
        <AcceptButton
          className={isActive ? "pointer" : "inactive"}
          onClick={() => {
            if (isActive) {
              showModal();
            }
          }}
        >
          View Offer
        </AcceptButton>
      </>
      {windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="500px"
          footer={null}
        >
          <ContentStyled>
            {isMode === 1 ? (
              isSuccess ? (
                resolveSuccess(1)
              ) : (
                resolveSuccess(0)
              )
            ) : (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  Offer Details
                </Title>
                <Text className="gray-7 " align="center">
                  {data.project?.name || "-"}
                </Text>
                <ModalContent>
                  <RowSpaceBetween>
                    <ModalContentKey>Date</ModalContentKey>
                    <p>{data.created_at}</p>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>Country</ModalContentKey>
                    <p>{data.country_name}</p>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>SDG</ModalContentKey>
                    <p>{data.impact_sdg?.name || "-"}</p>
                  </RowSpaceBetween>
                  <DividerCard />
                  <RowSpaceBetween>
                    <ModalContentKey>
                      <img
                        src={ibfx1}
                        style={{
                          paddingRight: 10,
                        }}
                      />
                      Offer
                    </ModalContentKey>
                    <ModalContentValue>
                      {fromIBFx(data.amount)}
                    </ModalContentValue>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>
                      <img
                        src={ibfx4}
                        style={{
                          paddingRight: 10,
                        }}
                      />
                      Token
                    </ModalContentKey>
                    <ModalContentValue>
                      {fromIBFx(data.project_amount)}
                    </ModalContentValue>
                  </RowSpaceBetween>
                </ModalContent>
                <ModalConfluenceOffers
                  handleCancel={handleCancel}
                  type="accept"
                  data={data}
                  isActive={isActive}
                  isCounterOffer={props.isCounterOffer}
                />
                <FooterButtonContent>
                  <RowSpaceBetween>
                    {!props.isCounterOffer && (
                      <ModalConfluenceOfferCounter
                        handleCancel={handleCancel}
                        data={data}
                      />
                    )}
                    <div style={{ width: 25 }} />
                    <ModalConfluenceOffers
                      handleCancel={handleCancel}
                      type="reject"
                      data={data}
                      isActive={isActive}
                      isCounterOffer={props.isCounterOffer}
                    />
                  </RowSpaceBetween>
                </FooterButtonContent>
              </ColCenter>
            )}
          </ContentStyled>
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
              alt="cancel-button"
            />
          }
        >
          <ContentStyled>
            {isMode === 1 ? (
              isSuccess ? (
                resolveSuccess(1)
              ) : (
                resolveSuccess(0)
              )
            ) : (
              <ColCenter>
                <Title className="text-w700 gray-7 mb-0px" level={3}>
                  Offer Details
                </Title>
                <Text className="gray-7 " align="center">
                  {data.project?.name || "-"}
                </Text>
                <ModalContent className="px-20px">
                  <RowSpaceBetween>
                    <ModalContentKey>Date</ModalContentKey>
                    <p>{data.created_at}</p>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>Country</ModalContentKey>
                    <p>{data.country_name}</p>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>SDG</ModalContentKey>
                    <p>{data.impact_sdg?.name || "-"}</p>
                  </RowSpaceBetween>
                  <DividerCard />
                  <RowSpaceBetween>
                    <ModalContentKey>
                      <img
                        src={ibfx1}
                        style={{
                          paddingRight: 10,
                        }}
                      />
                      Offer
                    </ModalContentKey>
                    <ModalContentValue>
                      {fromIBFx(data.amount)}
                    </ModalContentValue>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ModalContentKey>
                      <img
                        src={ibfx4}
                        style={{
                          paddingRight: 10,
                        }}
                      />
                      Token
                    </ModalContentKey>
                    <ModalContentValue>
                      {fromIBFx(data.project_amount)}
                    </ModalContentValue>
                  </RowSpaceBetween>
                </ModalContent>
                <ModalConfluenceOffers
                  handleCancel={handleCancel}
                  type="accept"
                  data={data}
                  isActive={isActive}
                  isCounterOffer={props.isCounterOffer}
                />
                <FooterButtonContent>
                  <RowSpaceBetween className="mt-12px">
                    {!props.isCounterOffer && (
                      <ModalConfluenceOfferCounter
                        handleCancel={handleCancel}
                        data={data}
                      />
                    )}
                    <div style={{ width: 25 }} />
                    <div className="mt-12px" style={{ width: "100%" }}>
                      <ModalConfluenceOffers
                        handleCancel={handleCancel}
                        type="reject"
                        data={data}
                        isActive={isActive}
                        isCounterOffer={props.isCounterOffer}
                      />
                    </div>
                  </RowSpaceBetween>
                </FooterButtonContent>
              </ColCenter>
            )}
          </ContentStyled>
        </Drawer>
      )}
    </>
  );
}
