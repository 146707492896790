import axios from "axios";
import { BENEVOLENCE_HOST } from "../../configs";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import jwtDecode from "jwt-decode";
import {
  CLOSE_CREATE_PROJECT_MODAL,
  // CLOSE_DONATION_MODAL,
  // GET_ANNOUNCEMENT_FAIL,
  GET_BENEVOLENCE_PROJECTS,
  GET_BENEVOLENCE_PROJECTS_FAIL,
  GET_BENEVOLENCE_PROJECTS_SUCCESS,
  OPEN_CREATE_PROJECT_MODAL,
  // OPEN_DONATION_MODAL,
} from "../constants";
import { signingOperation } from "../wallet/action";
import { headers } from "../../configs";

export const getSumRecentDonation = async (token, projectId) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = (
      await axios.get(
        `${BENEVOLENCE_HOST}/donations/sum-by-project/${projectId}`,
        {
          headers,
        }
      )
    ).data;
    return result.data.project_donation_sum;
  } catch (e) {
    console.log(e);
  }
};

export const getRecentVolunteers = async (token, projectId) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = (
      await axios.get(
        `${BENEVOLENCE_HOST}/project-volunteers/recent-by-project/${projectId}`,
        {
          headers,
        }
      )
    ).data;

    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const submitProject = async (token, request) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios.post(`${BENEVOLENCE_HOST}/projects`, request, { headers });
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const openCreateProjectModal = () => (dispatch) => {
  dispatch({
    type: OPEN_CREATE_PROJECT_MODAL,
  });
};

export const closeCreateProjectModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_CREATE_PROJECT_MODAL,
  });
};

export const getMyProjects =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_BENEVOLENCE_PROJECTS,
      });

      const jwt = localStorage.getItem("USER_AUTH_TOKEN");
      const decoded = jwtDecode(jwt);

      const headers = {
        Authorization: `Bearer ${jwt}`,
      };
      const result = (
        await axios.get(`${BENEVOLENCE_HOST}/projects`, {
          headers,
          params: {
            project_owner: decoded.user_id,
            page: page,
            // chain: "ALGORAND",
          },
        })
      ).data;

      const { data } = result;
      dispatch({
        type: GET_BENEVOLENCE_PROJECTS_SUCCESS,
        payload: {
          data: data.items,
          currentPage: page,
          hasNext: page < data.total_pages,
          totalItems: data.total_items,
          totalPage: data.total_pages,
        },
      });

      return result.data;
    } catch (e) {
      dispatch({
        type: GET_BENEVOLENCE_PROJECTS_FAIL,
        payload: {
          errorMessage: extractErrorMessage(e),
        },
      });
      throw extractErrorMessage(e);
    }
  };

export const deployProject = async (
  token,
  projectId,
  isPolygon = false,
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const body = { project_id: projectId };

    if (isPolygon) body.is_polygon = true;

    const result = (
      await axios.post(`${BENEVOLENCE_HOST}/projects/deployment`, body, {
        headers,
      })
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw extractErrorMessage(e);
  }
};

export const submitSignedOperation = async (mnemonic, operations) => {
  try {
    const signedOperations = await signingOperation(mnemonic, operations);
    await axios.post(
      `${BENEVOLENCE_HOST}/operations/submit_signature`,
      signedOperations,
      { headers }
    );
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const submitWalletSignedOperation = async (signedOperations) => {
  try {
    await axios.post(
      `${BENEVOLENCE_HOST}/operations/submit_signature`,
      signedOperations,
      { headers }
    );
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const activateProject = async (token, projectId) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = (
      await axios.post(
        `${BENEVOLENCE_HOST}/projects/activation`,
        {
          project_id: projectId,
        },
        { headers }
      )
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const startProject = async (token, projectId) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = (
      await axios.post(
        `${BENEVOLENCE_HOST}/projects/start`,
        {
          project_id: projectId,
        },
        { headers }
      )
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const completionProject = async (token, projectId) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = (
      await axios.post(
        `${BENEVOLENCE_HOST}/projects/completion`,
        {
          project_id: projectId,
        },
        { headers }
      )
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getProjectById = async (projectId) => {
  try {
    const result = (
      await axios.get(`${BENEVOLENCE_HOST}/projects?project_id=${projectId}`)
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const getRecentDonation = async (
  token,
  projectId,
  isPolygon = false
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = (
      await axios.get(
        `${BENEVOLENCE_HOST}/${
          isPolygon ? "evm-" : ""
        }donations/recent-by-project/${projectId}`,
        {
          headers,
        }
      )
    ).data;

    return result.data.donations;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const donateToWaqfPool = async (token, request) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = (
      await axios.post(`${BENEVOLENCE_HOST}/donations/ajrpool`, request, {
        headers,
      })
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const donateToWaqfPoolWitStripe = async (body) => {
  try {
    const token = localStorage.getItem("USER_AUTH_TOKEN");

    const result = (
      await axios.post(`${BENEVOLENCE_HOST}/donations/ajrpool-stripe`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const donateToProjectlWitStripe = async (body) => {
  try {
    const token = localStorage.getItem("USER_AUTH_TOKEN");

    const result = (
      await axios.post(`${BENEVOLENCE_HOST}/donations/project-stripe`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const donateToWaqfPoolWithPera = async (body) => {
  try {
    const token = localStorage.getItem("USER_AUTH_TOKEN");

    const result = (
      await axios.post(
        `${BENEVOLENCE_HOST}/donations/ajrpool-walletconnect`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const donateToProjectWithPera = async (body) => {
  try {
    const token = localStorage.getItem("USER_AUTH_TOKEN");

    const result = (
      await axios.post(
        `${BENEVOLENCE_HOST}/donations/project-walletconnect`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const donate = async (token, request) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = (
      await axios.post(`${BENEVOLENCE_HOST}/donations/project`, request, {
        headers,
      })
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};

export const contribute = async (token, request) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = (
      await axios.post(`${BENEVOLENCE_HOST}/project-volunteers`, request, {
        headers,
      })
    ).data;
    return result.data;
  } catch (e) {
    console.log(e);
    throw e.message;
  }
};
