import styled from '@emotion/styled'
import Search from 'antd/lib/input/Search'

export const SearchStyled = styled(Search)`
	.ant-input-search-button,
	.ant-input-affix-wrapper,
	.ant-input,
	.ant-input::placeholder {
		background: #f4f6f7;
		color: #44567b;
	}
`
