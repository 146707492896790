import React, { useState } from 'react'
import { Modal, Typography, Button, Row, Col, Input, Drawer, Upload, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { ColCenter, RowCenter } from '../general_styled'

import { ContentStyled } from './styled'
import closeIcon from '../../assets/images/close.svg'
import { activateUserAsset, getAllAssets, getTransactionHistory, reportTransaction } from '../../modules/wallet/action'
import { decryptMnemonic } from '../../utils/utils'
import { COMMON_CONSTANT } from '../../constants/constant_common'
import useWindowDimensions from '../../utils/windowDimensions'
import { ASSETS_NAME } from '../../constants/constant_wallet'
import { HOST, membershipBasicAuth, membershipHeaders, MEMBERSHIP_HOST } from '../../configs'

const { Title, Text } = Typography

const { TextArea } = Input

export default function ModalReport(props) {
	const windowDimensions = useWindowDimensions()
	const dispatch = useDispatch()
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isMode, setIsMode] = useState(0)
	const [file, setFile] = useState(false)
	const [issueDetail, setIssueDetail] = useState()
	const walletUser = useSelector((state) => state.getIn(['wallet', 'user']).toJS())

	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleCancel = () => {
		setIsMode(0)
		setIssueDetail()
		setIsModalVisible(false)
	}

	const onSubmit = async () => {
		setIsLoading(true)

		await reportTransaction({
			transaction_id: props.data.transactionId,
			issue: issueDetail,
			document_file: file ? file : null,
		})

		await dispatch(getTransactionHistory(1, walletUser.user_id))
		setIsMode(1)
		setIsLoading(false)
		handleCancel()
	}

	const antIcon = <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />

	const resolveName = () => {
		switch (props.data.asset.asset_name) {
			case ASSETS_NAME.IBFS:
				return 'IBFS'
			case ASSETS_NAME.IBFX_TYPE_I:
				return 'IBFX'
			case ASSETS_NAME.IBFX_TYPE_II:
				return 'IBFX - 2'
			case ASSETS_NAME.IBFX_TYPE_III:
				return 'IBFX - 3'
			case ASSETS_NAME.IBFX_TYPE_IV:
				return 'IBFX - 4'
		}
	}

	const propsFile = {
		name: 'file',
		multiple: false,
		maxCount: 1,
		action: `${MEMBERSHIP_HOST}/files`,
		// beforeUpload: (file) => {
		// if (file.type !== "image/png") {
		//   message.error(`${file.name} is not a png file`)
		// }

		// },
		headers: {
			authorization: membershipBasicAuth,
		},
		onChange: (info) => {
			const { status, response } = info.file
			// getData response.data.filename
			if (status !== 'uploading') {
			}
			if (status === 'done') {
				setFile(response.data.filename)
				message.success(`${info.file.name} file uploaded successfully.`)
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
			}
		},
	}

	return (
		<>
			{windowDimensions.width > 835 ? (
				<a href="#" onClick={showModal}>
					Report
				</a>
			) : (
				<Button block size="large" onClick={showModal}>
					Report
				</Button>
			)}
			{windowDimensions.width > 835 ? (
				<Modal centered visible={isModalVisible} onCancel={handleCancel} width="400px" footer={null}>
					<ContentStyled>
						{/* onActivateAssets */}

						<ColCenter>
							<Title className="text-w700 gray-7 mb-0px" level={3}>
								Report
							</Title>
							<Text className="gray-7" align="center">
								Reporting issue for Transaction #{props.data.transactionCode}
								<span className="text-w600">{resolveName()}</span>
							</Text>
							<TextArea
								className="mt-24px"
								rows={6}
								placeholder="Enter your message here"
								value={issueDetail}
								onChange={(e) => setIssueDetail(e.target.value)}
							/>
							<Upload {...propsFile} className="mt-12px w-100">
								<Button block icon={<UploadOutlined />} style={{ height: 40 }}>
									Upload Any Supporting Documents
								</Button>
							</Upload>
							<Button
								block
								disabled={!issueDetail}
								type="primary"
								className="mt-24px"
								size="large"
								onClick={() => onSubmit()}
							>
								{isLoading ? antIcon : 'Submit'}
							</Button>
						</ColCenter>
					</ContentStyled>
				</Modal>
			) : (
				<Drawer
					visible={isModalVisible}
					onCancel={handleCancel}
					placement="bottom"
					closeIcon={<img style={{ marginRight: 10 }} src={closeIcon} onClick={handleCancel} />}
				>
					<ContentStyled>
						{/* onActivateAssets */}
						<ColCenter>
							<Title className="text-w700 gray-7 mb-0px" level={3}>
								Report
							</Title>
							<Text className="gray-7 mt-5px" align="center">
								Reporting issue for <br />
								Transaction #{props.data.transactionCode}
							</Text>
							<TextArea
								className="mt-24px"
								rows={6}
								placeholder="Enter your message here"
								value={issueDetail}
								onChange={(e) => setIssueDetail(e.target.value)}
							/>
							<Upload {...propsFile} className="mt-12px w-100">
								<Button block icon={<UploadOutlined />} style={{ height: 40 }}>
									Upload Any Supporting Documents
								</Button>
							</Upload>
							<Button
								block
								disabled={!issueDetail}
								type="primary"
								className="mt-24px"
								size="large"
								onClick={() => onSubmit()}
							>
								{isLoading ? antIcon : 'Submit'}
							</Button>
						</ColCenter>
					</ContentStyled>
				</Drawer>
			)}
		</>
	)
}
