import { ButtonStyled, ColCenter } from "../../components/general_styled";
import ExcellenceLogo from "../../assets/images/nv_logo.png";
import { DescStyled } from "./styled";
import { useTranslation } from "react-i18next";

export default function Excellence() {
  const { t } = useTranslation();

  return (
    <ColCenter
      style={{
        minHeight: "calc(100vh - 120px)",
      }}
    >
      <img
        src={ExcellenceLogo}
        width={324}
        style={{
          maxWidth: "100%",
          borderRadius: 10,
        }}
      />
      <DescStyled>{t("excellence_desc")}</DescStyled>
      <ButtonStyled
        style={{
          width: "324px",
          maxWidth: "100%",
        }}
        onClick={() => window.open("https://netversity.io/", "")}
      >
        Visit Netversity
      </ButtonStyled>
    </ColCenter>
  );
}
