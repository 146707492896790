import { fromJS } from "immutable"
import {
  SET_ALL_LOANS,
  SET_LOAN_BY_DETAIL,
  SET_LOAN_REQUESTS,
} from "../constants"

const initialState = fromJS({
  isLoading: false,
  hasError: false,
  errorMessage: "",
  detailLoan: fromJS({}),
  allLoans: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    total: 0,
  }),
  loanRequests: fromJS({
    data: [],
    currentPage: 1,
    hasNext: false,
    total: 0,
  }),
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_LOANS:
      return state.merge({
        allLoans: fromJS(action.payload),
      })
    case SET_LOAN_REQUESTS:
      return state.merge({
        loanRequests: fromJS(action.payload),
      })
    case SET_LOAN_BY_DETAIL:
      return state.merge({
        detailLoan: fromJS(action.payload),
      })

    default:
      return state
  }
}
