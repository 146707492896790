import React, { useEffect, useRef, useState } from "react";
// import GroupBig from '../../assets/images/GroupBig.png'
import {
  Card,
  Row,
  Col,
  Progress,
  Avatar,
  Typography,
  notification,
  Tabs,
  Skeleton,
  Tooltip,
} from "antd";
import {
  CardTitle,
  DollarText,
  DollarText1,
  TabsStyled,
  Media,
  MediaContainer,
  NgoTitle,
  NgoSubtitle,
  RecentContainer,
  SideCardStyled,
  Pic,
  SdgImg,
} from "./styled.js";
import {
  // ButtonStyled,
  RowSpaceBetween,
  RowStart,
  RowCenter,
  ColCenter,
  // ButtonCoinbaseStyled,
} from "../../components/general_styled";
// import { DividerCard } from "../../components/CardBalance/styled";
import {
  getProjectById,
  getRecentDonation,
  getRecentVolunteers,
} from "../../modules/benevolence_projects/action";
import { BENEVOLENCE_HOST, benevolenceDonationAddress } from "../../configs";
import { formatterCurrency, fromIBFx, fromUSD, textSlice } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_TYPE } from "../../constants/benevolence_constant";
import ModalDonation from "../../components/ModalDonation";
// import ModalVolunteer from '../../components/ModalVolunteer'
import Ibfx1 from "../../assets/images/affluence-shopee-ibfx.svg";
import ModalContributeVolunteer from "../../components/ModalContributeVolunteer";
import {
  openModalLoginRegister,
  setSection,
} from "../../modules/general/action";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../utils/windowDimensions";

import AbiBenevolenceDonation from "../../assets/abi/BenevolanceDonation.json";
import { useContractRead } from "wagmi";
import { TitleStyled } from "../../components/CreateProjectModal/styled.js";
import {
  TotalUserText,
  UserImgStyled,
} from "../../components/CardBenevolence/styled.js";
// import ClockIcon from "../../assets/images/clock-projects.svg";
// import UserIcon from "../../assets/images/user-benev.svg";
// import TagUnverified from "../../assets/images/tag_unverified.svg";
// import TagVerified from "../../assets/images/tag_verified.svg";
import Linkedin from "../../assets/images/media/linkedin.svg";
import Whatsapp from "../../assets/images/media/whatsapp.svg";
import Mail from "../../assets/images/media/mail.svg";
import FB from "../../assets/images/media/fb.svg";
import Instagram from "../../assets/images/media/instagram.svg";
import USDT from "../../assets/images/token/usdt.svg";
import IBFN from "../../assets/images/token/ibfn.svg";
import {
  CheckCircleOutlined,
  TwitterOutlined,
  GlobalOutlined,
  QuestionCircleFilled
} from "@ant-design/icons";
import moment from "moment";
import SEO from "../../components/SEO";
import { getCoinbaseDonor, getStripeDonor } from "../../modules/benevolence_donors/action.js";
import ModalDonationSelection from "../../components/ModalDonationSelect/index.js";

const { TabPane } = Tabs;
const { Text } = Typography;

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

const medias = [
  { id: "linkedin_url", component: <UserImgStyled src={Linkedin} /> },
  { id: "whatsapp_url", component: <UserImgStyled src={Whatsapp} /> },
  {
    id: "twitter_url",
    component: <TwitterOutlined style={{ color: "#fff" }} />,
  },
  { id: "email_url", component: <UserImgStyled mail={true} src={Mail} /> },
  { id: "facebook_url", component: <UserImgStyled src={FB} /> },
  { id: "instagram_url", component: <UserImgStyled src={Instagram} /> },
  {
    id: "website_url",
    component: <GlobalOutlined style={{ color: "#fff" }} />,
  },
];

export default function BenevolenceProjectDetail() {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const windowDimensions = useWindowDimensions();

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  const formatterTimeLeft = new Intl.RelativeTimeFormat();
  const dispatch = useDispatch();
  const params = useSelector((state) =>
    state.getIn(["general", "param"]).split(";")
  );

  const ibfxPerUSD = useSelector((state) =>
    state.getIn(["wallet", "ibfxPerUSD"])
  );

  const [project, setProject] = useState(null);
  const [isAsset, setIsAsset] = useState(true)
  const [payment, setPayment] = useState();
  const [contributors, setContributors] = useState([]);
  const token = useSelector((state) => state.getIn(["auth", "token"]));
  const [isModalVisible, setModalVisible] = useState(false);
  const [isVolunteerFull, setVolunteerFull] = useState(false);
  const [menu, setMenu] = useState("details");
  const [mode, setMode] = useState();
  const [behalfDetail, setBehalfDetail] = useState({ behalf_name: null, behalf_email: null });
  const [currentVolunteerValue, setCurrentVolunteerValue] = useState(0)
  const [targetVolunteerValue, setTargetVolunteerValue] = useState(0)

  const { data: dataAchievedDonation } = useContractRead({
    address: benevolenceDonationAddress,
    abi: AbiBenevolenceDonation,
    functionName: "getAchivedValueDonationProject",
    args: [
      project?.nonce, // id = nonce
    ],
  });

  const resolveAmountUnit = (symbol, value, forStart = true) => {
    let _value;

    if (symbol === "IBFX")
      _value = Number((fromIBFx(Number(value)) / ibfxPerUSD).toFixed(2) || 0);
    else if (symbol === "IBFN")
      _value = Number((Number(value) / 6.5).toFixed(2) || 0);
    else _value = Number(value);

    return (
      Number(_value) + (forStart ? Number(project?.total_external_donation) : 0)
    ).toFixed(2);
  };

  const resolveImg = (symbol) => {
    switch (symbol) {
      case "USDT":
        return USDT;
      case "IBFN":
        return IBFN;
      default:
        return Ibfx1;
    }
  };

  const resolveProgressColor = (param = "asset") => {
    const value = resolveProgressPercent(param)

    if (value < 50) {
      return "#FF4D4F";
    } else if (value <= 80) {
      return "#EC950A";
    } else {
      return "#38CB89";
    }

  };

  const resolveProgressPercent = (param = "asset") => {
    if (param === "asset") {
      const totalDonation = Number(resolveAmountUnit("IBFX", project?.donation_amount))
      const targetlDonation = Number(resolveAmountUnit("IBFX", project?.fund_goal, false))
      return project?.chain === "POLYGON"
        ? ((Number(dataAchievedDonation) / 10 ** 6 +
            project?.total_external_donation) /
            project?.fund_goal) *
            100
        : (totalDonation / targetlDonation) * 100
    } else {
      return (project?.total_hours_accepted / project?.hours_goal) * 100;
    }
  };

  const getProject = async () => {
    try {
      const project = await getProjectById(params[0]);
      const _project = {
        ...project,
        ProjectNgo: {
          ...project?.ProjectNgo,
          medias: JSON.parse(project?.ProjectNgo?.website || "{}"),
        },
      };
      setIsAsset(_project?.project_type === PROJECT_TYPE.ASSET)
      setProject(_project);
      if (project?.chain === "POLYGON") {
        setPayment(JSON.parse(project?.payment));
      }

      if (_project?.project_type === PROJECT_TYPE.ASSET) {
        loadRecentDonation(project?.chain === "POLYGON");
      } else if (_project?.project_type === PROJECT_TYPE.EFFORT) {
        const targetValue = _project.effort_requirement?.reduce((acc, item) => {
          return acc + (fromUSD(item.rate_per_hour) * ibfxPerUSD + item.working_hours )
        }, 0)
        setTargetVolunteerValue(targetValue)
        loadRecentVolunteer();

        if (_project.total_hours_accepted >= _project.hours_goal){
          setVolunteerFull(true);
        }
      }

    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Unknown project",
      });
    }
  };

  const loadRecentDonation = async (isPolygon = false) => {
    try {
      const [recentDonation, recentCoinbaseDonation, recentStripeDonation] =
        await Promise.all([
          getRecentDonation(token, params[0], isPolygon),
          getCoinbaseDonor(params[0]),
          getStripeDonor(params[0]),
        ]);

      const mergedDonation = [
        ...recentDonation,
        ...recentCoinbaseDonation.items,
        ...recentStripeDonation.items,
      ].sort((a, b) => b.created_at - a.created_at);
      setContributors(mergedDonation ? mergedDonation : []);
    } catch (e) {
      console.log(e);
    }
  };

  const loadRecentVolunteer = async () => {
    try {
      const recentVolunteers = await getRecentVolunteers(token, params[0]);
      setContributors(recentVolunteers ? recentVolunteers.volunteers : []);
      const currentValue = (recentVolunteers ? recentVolunteers.volunteers : [])?.reduce((acc, item) => {
        return acc + (fromUSD(item.rate_per_hour) * ibfxPerUSD + item.working_hours )
      }, 0)
      setCurrentVolunteerValue(currentValue)
    } catch (e) {
      console.log(e);
    }
  };

  const getTimeLeft = () => {
    // return project?.end_at
    if (project?.end_at) {
      const projectTime = new Date(project?.end_at * 1000);
      const deltaDays =
        (projectTime.getTime() - Date.now()) / (1000 * 3600 * 24);
      // return deltaDays
      return formatterTimeLeft
        .format(Math.round(deltaDays), "days")
        .replace("in ", "");
    }
  };

  const resolveDonors = () => {
    if(isAsset){
      const donors =
        project?.chain === "POLYGON"
          ? project?.DonationEvms?.length
          : project?.total_unique_donation;
      return donors + project?.total_external_donator;
    } 

    return project?.total_volunteer
  };

  const shareProjectToMedia = (param) => {
    const url = window.location.href;
    const title = project?.name;
    if (param === "facebook_url") {
      return window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        "_blank"
      );
    } else if (param === "linkedin_url") {
      return window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`,
        "_blank"
      );
    } else if (param === "twitter_url") {
      return window.open(
        `https://twitter.com/intent/tweet?url=${url}&title=${title}`,
        "_blank"
      );
    } else if (param === "whatsapp_url") {
      return window.open(`https://api.whatsapp.com/send?text=${url}`, "_blank");
    } else if (param === "email_url") {
      return window.open(
        `mailto:?subject=I wanted you to see this project ${title}&amp;body=Check out this site ${url}`,
        "_blank"
      );
    }
  };

  const renderSideCard = () => {
    return (
      <SideCardStyled>
        <RowSpaceBetween>
        <CardTitle>{isAsset ? t("funded").toUpperCase() : 'VOLUNTEERED'}</CardTitle>
        {!isAsset && <CardTitle>TARGET</CardTitle>}
        </RowSpaceBetween>
        <RowSpaceBetween>
          {isAsset ? (
            <DollarText1>
              ${" "}
              {formatterCurrency.format(
                project?.chain === "POLYGON"
                  ? resolveAmountUnit(
                      payment?.key,
                      Number(dataAchievedDonation) / 10 ** 6
                    )
                  : resolveAmountUnit("IBFX", Number(project?.donation_amount))
              )}
            </DollarText1>
          ) : (
            <RowStart>
              <DollarText1 className="text-w400">
                {project?.total_hours_accepted}
              </DollarText1>
              <Text className="ml-4px">{t("hours")}</Text>
            </RowStart>
          )}
          {isAsset ? (
            <DollarText1>
              ${" "}
              {formatterCurrency.format(
                project?.chain === "POLYGON"
                  ? resolveAmountUnit(
                      payment?.key,
                      Number(project?.fund_goal),
                      false
                    )
                  : resolveAmountUnit("IBFX", project?.fund_goal, false)
              )}
            </DollarText1>
          ) : (
            <RowStart>
              <DollarText1>
                {/* / {formatterHours.format(project?.hours_goal).substring(1)} */}
                {project?.hours_goal}
              </DollarText1>
              <Text className="ml-4px">{t("hours")}</Text>
            </RowStart>
          )}
        </RowSpaceBetween>
        <Progress
          strokeColor={resolveProgressColor(isAsset ? "asset": "volunteer")}
          className="my-12px"
          percent={resolveProgressPercent(isAsset ? "asset": "volunteer")}
          status="active"
          showInfo={false}
        />
        {isAsset ? (
          <RowSpaceBetween>
            <RowCenter>
              <img
                src={resolveImg(payment?.key)}
                className="mr-6px"
                alt="icon"
                style={{ width: 20, height: 20, marginTop: -2 }}
              />
              <DollarText>
                {formatterCurrency.format(
                  project?.chain === "POLYGON"
                    ? Number(dataAchievedDonation) / 10 ** 6 +
                        project?.total_external_donation
                    : (
                        Number(fromIBFx(project?.donation_amount)) +
                        Number(project?.total_external_donation) * ibfxPerUSD
                      ).toFixed(2)
                )}
              </DollarText>
            </RowCenter>

            <RowCenter>
              <DollarText className="mr-6px">
                {formatterCurrency.format(
                  project?.chain === "POLYGON"
                    ? project?.fund_goal
                    : fromIBFx(project?.fund_goal)
                )}
              </DollarText>
              <img
                src={resolveImg(payment?.key)}
                alt="icon"
                style={{ width: 20, height: 20, marginTop: -2 }}
              />
            </RowCenter>
          </RowSpaceBetween>
        ) : (
          <RowSpaceBetween>
            <DollarText>
              $ {currentVolunteerValue}{" "}
              <Tooltip
                placement="bottom"
                title="Estimation based on hourly rates"
              >
                <QuestionCircleFilled style={{ fontSize: 10, marginLeft: 5, paddingBottom: 2 }} />
              </Tooltip>
            </DollarText>
            <DollarText>{targetVolunteerValue} $</DollarText>
          </RowSpaceBetween>
        )}

        <ColCenter
          className={!(windowDimensions.width > 900) && "py-24px"}
          style={{ height: "70%" }}
        >
          <RowCenter>
            <DollarText>
              {/* <UserImgStyled className="mr-4px" src={UserIcon} /> */}
              <TotalUserText>{resolveDonors()}</TotalUserText>
              <span className="ml-2px" style={{ color: "#5B769790" }}>
                {isAsset ? "Donors" : "Volunteers"},
              </span>
            </DollarText>
            <DollarText style={{ marginLeft: "4px" }}>
              {/* <UserImgStyled className="mr-4px" src={ClockIcon} /> */}
              {getTimeLeft(project?.end_at) || ""}
              <span className="ml-2px" style={{ color: "#5B769790" }}>
                {t("left")}
              </span>
            </DollarText>
          </RowCenter>
          {isAsset ? (
            <ModalDonationSelection
              project={project}
              openDonationModal={() => setModalVisible(true)}
              mode={mode}
              setMode={setMode}
              setBehalfDetail={setBehalfDetail}
            />
          ) : (
            <ModalContributeVolunteer isVolunteerFull={isVolunteerFull} data={{ ...project }} />
          )}
          <DollarText className="mt-12px">
            <CheckCircleOutlined style={{ color: "#50C8A8" }} />
            <span style={{ color: "#50C8A8", marginLeft: "4px" }}>
              Verified Campaign
            </span>
          </DollarText>
        </ColCenter>
      </SideCardStyled>
    );
  };

  useEffect(() => {
    if (params[0] === "") {
      dispatch(setSection("benevolence-donate"));
    }
    getProject();
  }, []);

  useEffect(() => {
    // Access the container element and style its children
    const container = containerRef.current;
    if (container) {
      const images = container.querySelectorAll("img");
      images.forEach((image) => {
        // Apply styles to each image
        image.style.width = "100%";
        image.style["max-height"] = "300px";
        image.style["object-fit"] = "fill";
      });
    }
  }, [project?.description]);

  return (
    <SEO
      image={`${BENEVOLENCE_HOST}/seo-files/${project?.cover_image_file}`}
      description={`Detail description of Project ${project?.name}`}
    >
      {project ? (
        <>
          <RowCenter>
            <TitleStyled className="py-24px">{project?.name}</TitleStyled>
          </RowCenter>
          <Row className={`${windowDimensions.width > 900 ? "px-24px" : ""}`}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <Pic
                alt="ProjectPic"
                // src='https://api-benevolence.ibf.exchange/api/files/f740f290-9e38-11ee-ae99-5b203cb1c002.png'
                src={`${BENEVOLENCE_HOST}/files/${project?.cover_image_file}`}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              {renderSideCard()}
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <div style={{ display: 'flex', flexWrap: windowDimensions.width > 900 ? "wrap" : 'wrap-reverse' }}>
                <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={16}>
                  {/* {project?.ProjectSdgs?.length > 0 && (
                    <RowStart className="py-24px mx-12px">
                      {project?.ProjectSdgs?.map((sdg) => (
                        <UnlockableContent
                          verified={sdg?.is_verified}
                          className="mr-4px"
                        >
                          <UserImgStyled
                            className="mr-4px"
                            src={sdg.is_verified ? TagVerified : TagUnverified}
                          />
                          <div className="ml-4px">{sdg?.sdg?.name}</div>
                        </UnlockableContent>
                      ))}
                    </RowStart>
                  )} */}
                  <TabsStyled
                    size="large"
                    onChange={(val) => setMenu(val)}
                    defaultActiveKey={menu}
                    activeKey={menu}
                    className="benevolence-tab"
                  >
                    <TabPane tab="DETAILS" key="details">
                      <Typography className="p-4px pr-48px">
                        <div
                          ref={containerRef}
                          dangerouslySetInnerHTML={{ __html: project?.description }}
                        />
                      </Typography>
                    </TabPane>
                    <TabPane tab="UPDATES" key="updates">
                      {/* {renderFavourite()} */}
                    </TabPane>
                  </TabsStyled>
                </Col>

                <Col
                  className={`${windowDimensions.width > 900 ? "" : "px-24px"}`}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={10}
                  xl={8}
                  xxl={8}
                >
                  {project?.ProjectSdgs?.length > 0 && (
                    <Card
                      style={{ boxShadow: "0px 2px 2px 0px #D4D5D966" }}
                      className="my-24px"
                    >
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                          <span className="text-campaign">
                            This campaign focuses on these UN SDGs
                          </span>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                          xs={24}
                          sm={24}
                          md={24}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          {project?.ProjectSdgs?.map((sdg) => (
                            <SdgImg
                              isMobile={windowDimensions.width > 900}
                              src={`${BENEVOLENCE_HOST}/files/${sdg?.sdg?.photo}`}
                            />
                          ))}
                        </Col>
                      </Row>
                    </Card>
                  )}

                  <Card
                    style={{ boxShadow: "0px 2px 2px 0px #D4D5D966" }}
                    className="my-24px"
                  >
                    <Row style={{ alignItems: "center" }}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
                        <div style={{ color: "#42556E" }}>
                          Share this project
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={14}>
                        <MediaContainer>
                          <Row>
                            {medias.slice(0, 5).map((i) => (
                              <Media onClick={() => shareProjectToMedia(i.id)}>
                                {i.component}
                              </Media>
                            ))}
                          </Row>
                          {!token && (
                            <span
                              onClick={() => dispatch(openModalLoginRegister())}
                              className="subtitle"
                            >
                              Login to become a registered donor
                            </span>
                          )}
                        </MediaContainer>
                      </Col>
                    </Row>
                  </Card>

                  {project?.ProjectNgo?.name && (
                    <Card
                      style={{ boxShadow: "0px 2px 2px 0px #D4D5D966" }}
                      className="my-24px"
                    >
                      <ColCenter style={{ alignItems: "center" }}>
                        <Avatar
                          src={`${BENEVOLENCE_HOST}/files/${project?.ProjectNgo?.cover_image_file}`}
                        />
                        <NgoTitle>{project?.ProjectNgo?.name}</NgoTitle>
                        <NgoSubtitle>
                          {`${
                            project?.ProjectNgo?.Projects?.length || 0
                          } Campaigns, ${
                            project?.ProjectNgo?.total_supported
                          } Supported`}
                        </NgoSubtitle>
                        <MediaContainer className="mt-24px">
                          <Row>
                            {Object.keys(project?.ProjectNgo?.medias).map((i) => {
                              const url = project?.ProjectNgo?.medias[i];
                              return (
                                <Media
                                  onClick={() =>
                                    window.open(
                                      i === "email_url" ? `mailto:${url}` : url,
                                      "_blank"
                                    )
                                  }
                                >
                                  {
                                    medias.find((media) => media.id === i)
                                      ?.component
                                  }
                                </Media>
                              );
                            })}
                          </Row>
                          <span
                            onClick={() =>
                              project?.ProjectNgo?.medias["email_url"] &&
                              window.open(
                                `mailto:${project?.ProjectNgo?.medias["email_url"]}`,
                                "_blank"
                              )
                            }
                            className="subtitle"
                          >
                            Contact Customer Support
                          </span>
                        </MediaContainer>
                      </ColCenter>
                    </Card>
                  )}
                </Col>
              </div>
            </Col>

            <ModalDonation
              isModalVisible={
                isModalVisible && isAsset
              }
              setModalVisible={setModalVisible}
              project={project}
              toWaqfPool={false}
              mode={mode}
              behalfDetail={behalfDetail}
            />
          </Row>
          {contributors?.length > 0 && isAsset ? (
            <RecentContainer>
              <CardTitle>RECENT DONORS</CardTitle>
              <RowStart className="scroll" style={{ overflowX: "scroll" }}>
                {contributors.map((item) => {
                  const currentTimestamp = Math.floor(Date.now() / 1000);
                  const duration = moment.duration(
                    currentTimestamp - Math.floor(item.created_at / 1000),
                    "seconds"
                  );
                  let metadata = null;
                  if (item.metadata) metadata = JSON.parse(item.metadata);
                  const formattedDuration = moment.duration(duration).humanize();
                  const isNotLoggedIn = !(item?.user_name || item?.User?.MembershipUser?.name || metadata?.user?.name && metadata?.user?.name !== 'Anonymous')
                  const isAnonymous = item.is_guest

                  const name = item?.behalf_name || item?.user_name || item?.User?.MembershipUser?.name || metadata?.user?.name || item?.name
                  const email = item?.behalf_email || item?.user_country || item?.User?.MembershipUser?.country || metadata?.user?.country || item?.email

                  const mappingData = { name: '', email: '' }
                  if (isNotLoggedIn && isAnonymous) {
                    mappingData.name = 'Anonymous'
                    mappingData.email = 'Anonymous'
                  } else if (isNotLoggedIn && !isAnonymous) {
                    mappingData.name = name
                    mappingData.email = 'Guest Donor'
                  } else if (!isNotLoggedIn && isAnonymous) {
                    mappingData.name = 'Anonymous'
                    mappingData.email = textSlice(email)
                  } else if (!isNotLoggedIn && !isAnonymous) {
                    mappingData.name = name
                    mappingData.email = textSlice(email)
                  }

                  if (
                    item.status === "SUCCESS" ||
                    item.status !== "REQUESTED" ||
                    item.status !== "REJECTED"
                  ) {
                    return (
                      <div className="item px-3px">
                        {project?.project_type === PROJECT_TYPE.EFFORT ? (
                          (item.working_hours || 0) + " Hours"
                        ) : !item.currency ? (
                          <RowStart className="text-w500">
                            {formatter
                              .format(
                                project?.chain === "POLYGON"
                                  ? item.amount
                                  : fromIBFx(item.amount)
                              )
                              .substring(1)}
                            <img
                              src={resolveImg(payment?.key)}
                              alt="icon"
                              className="icon"
                            />
                          </RowStart>
                        ) : (
                          <span className="text-w500">
                            {Number(item.amount)?.toFixed(2)} {item.currency?.toUpperCase()}
                          </span>
                        )}
                        <span className="time">{formattedDuration} ago</span>
                        <span className="name">{mappingData.name}</span>
                        <span className="name country">{mappingData.email}</span>
                      </div>
                    );
                  }
                })}
              </RowStart>
            </RecentContainer>
          ): (
            <RecentContainer>
              <CardTitle>RECENT VOLUNTEERS</CardTitle>
              <RowStart className="scroll" style={{ overflowX: "scroll" }}>
                {contributors.map((item) => {
                  const currentTimestamp = Math.floor(Date.now() / 1000);
                  const duration = moment.duration(
                    currentTimestamp - Math.floor(item.created_at / 1000),
                    "seconds"
                  );
                  const formattedDuration = moment.duration(duration).humanize();
                  if (
                    item.status === "SUCCESS" ||
                    item.status !== "REQUESTED" ||
                    item.status !== "REJECTED"
                  ) {
                    return (
                      <div className="item px-3px">
                          {(item.working_hours || 0) + " Hours"}
                        <span className="name">{item.user_name}</span>
                        <span className="name country">{item.title}</span>
                        <span className="time">{formattedDuration} ago</span>
                      </div>
                    );
                  }
                })}
              </RowStart>
            </RecentContainer>
          )}
        </>
      ) : (
        <>
          <Skeleton active className="p-24px" />
          <Skeleton active className="p-24px" />
        </>
      )}
    </SEO>
  );
}
