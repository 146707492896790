import { GET_COURSE_WISHLIST } from '../constants'
import jwtDecode from 'jwt-decode'
import { message } from 'antd'
import { MEMBERSHIP_HOST } from '../../configs'
import axios from 'axios'

export const updateWishlist = (course, isDelete, dataWishlist) => async (dispatch) => {
	if (isDelete) {
		await deleteCourseWishlist(dataWishlist)
		await dispatch(getCoursesWishlist())

		message.success('Wishlist has been deleted')
	} else {
		await addCourseWishlist(course)
		await dispatch(getCoursesWishlist())

		message.success('Wishlist has been added')
	}
}

export const addCourseWishlist = async (course) => {
	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')
		const decoded = jwtDecode(jwt)

		const body = {
			course_id: course.course_id,
			user_id: decoded.user_id,
		}

		await axios.post(`${MEMBERSHIP_HOST}/wishlist`, body, {
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		})
	} catch (e) {
		throw e
	}
}

export const deleteCourseWishlist = async (course) => {
	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')

		await axios.delete(`${MEMBERSHIP_HOST}/wishlist/${course.wishlist_id}`, {
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		})
	} catch (e) {
		throw e
	}
}

export const getCoursesWishlist = (isReturn) => async (dispatch) => {
	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')
		const decoded = jwtDecode(jwt)

		const response = await axios.get(`${MEMBERSHIP_HOST}/wishlist`, {
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
			params: {
				user_id: decoded.user_id,
				page: 1,
				size: 1000,
			},
		})
		const { data } = response.data

		if (isReturn) {
			return data.data
		}

		dispatch({
			type: GET_COURSE_WISHLIST,
			payload: data.data,
		})
	} catch (e) {
		console.log(e)
		// notification.error({
		//   message: "Failed!",
		//   description: e.response ? e.response.message : "Unknown Error",
		// })
	}
}

// /wishlist?user_id=""
