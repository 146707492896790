import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Col,
  Image,
  Modal,
  Progress,
  Row,
  Alert,
  Typography,
} from "antd";
// import IBFx1Logo from '../../../assets/images/ibfx-1.svg'
// import IBFx2Logo from '../../../assets/images/ibfx-2.svg'
// import IBFx3Logo from '../../../assets/images/ibfx-3.svg'
// import TrashIcon from '../../../assets/images/trash.svg'
import closeIcon from "../../../assets/images/close.svg";
import noWalletIllustration from "../../../assets/images/no-wallet-illustration.png";
import {
  ColCenter,
  ColSpaceBetween,
  InputNumberStyled,
  RowCenter,
  RowSpaceBetween,
} from "../../general_styled";
import { BoxBalanceStyled, ContentStyled } from "../styled";
import { ASSETS_NAME, ASSETS_STATUS } from "../../../constants/constant_wallet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decryptMnemonic, fromIBFx, toIBFx } from "../../../utils/utils";
import { useHistory } from "react-router";
import { combinePayment, createOrder } from "../../../modules/wallet/action";
import { COMMON_CONSTANT } from "../../../constants/constant_common";
import ReactCodeInput from "react-code-input";
import { closeCart, removeCart } from "../../../modules/course_cart/action";
import useWindowDimensions from "../../../utils/windowDimensions";
import CourseOnBoarding from "../../../pages/CourseOnBoarding";

const { Title, Text } = Typography;

export default function CoursePayment(props) {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const [assetType, setAssetType] = useState([ASSETS_NAME.IBFX_TYPE_I]);
  const [processingPercent, setProcessingPercent] = useState(0);
  const [isProcessing, setProcessing] = useState(false);
  // const [isMoreLimit, setMoreLimit] = useState(false)
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [inputPin, setInputPin] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const walletUser = useSelector((state) =>
    state.getIn(["wallet", "user"]).toJS()
  );
  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );
  const cart = useSelector((state) =>
    state.getIn(["courseCart", "cart"]).toJS()
  );

  const discount = useSelector((state) =>
    state.getIn(["courseCart", "discount"])
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  useEffect(() => {
    setAssetType([ASSETS_NAME.IBFX_TYPE_I]);
    setProcessingPercent(0);
    setProcessing(false);
    setSuccess(false);
    setFailed(false);
    setInputPin(false);
    setPinCode("");
  }, [props.isModalVisible]);

  const checkPin = () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = decryptMnemonic(getMnemonic, pinCode);

      if (_decryptMnemonic) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const handleCancel = () => {
    props.setIsModalVisible(false);
    props.setIbfx1Amount(0);
    props.setIbfx2Amount(0);
  };

  const onCheck = (value) => {
    if (assetType.includes(value)) {
      if (value === ASSETS_NAME.IBFX_TYPE_I) {
        props.setIbfx1Amount(0);
      }
      // else if (value === ASSETS_NAME.IBFX_TYPE_II) {
      // 	props.setIbfx2Amount(0)
      // }

      setAssetType((prevValue) => {
        return prevValue.filter((e) => e !== value);
      });
    } else {
      setAssetType((prevValue) => {
        return [...prevValue, value];
      });
    }
  };

  const onSubmit = async () => {
    // if (props.ibfx2Amount > resolveIBFX2Limit()) {
    // 	return setMoreLimit(true)
    // }

    setFailed(false);
    setProcessing(true);
    setProcessingPercent(0);

    try {
      const order = await createOrder({
        user_id: walletUser.user_id,
        order_items: cart.map((c) => {
          return {
            course_id: c.course_id,
            quantity: 1,
            price: toIBFx(c.price) - toIBFx(c.discount),
          };
        }),
      });
      setProcessingPercent(30);
      const encryptedMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const mnemonic = decryptMnemonic(encryptedMnemonic, pinCode);
      await combinePayment(mnemonic, {
        user_algo_address: walletUser.algo_address,
        order_id: order.order_id,
        // assets: assetType.map((e) => ({
        // 	asset_id: resolveAssetId(e),
        // 	amount: toIBFx(resolveSelectedAssetAmount(e)),
        // })),
        assets: [
          {
            asset_id: resolveAssetId(ASSETS_NAME.IBFX_TYPE_I),
            amount: toIBFx(props.totalPayment),
          },
        ],
      });
      setProcessingPercent(100);
      setSuccess(true);

      for (const course of cart) {
        dispatch(removeCart(course));
      }
      dispatch(closeCart());
    } catch (e) {
      console.log(e);
      setFailed(true);
    }
    setProcessing(false);
  };

  const resolveBalance = (assetName) => {
    const asset = allAssets.find((asset) => asset.asset_name === assetName);
    return asset ? asset.amount : 0;
  };

  const resolveIBFX2Limit = () => {
    let limit = 0;

    cart.forEach((element) => {
      const getLimit =
        element.price * ((element.ibfx2_limit_percentage || 0) / 100);
      limit += getLimit;
    });

    return limit;
  };

  const resolveAssetId = (assetName) => {
    const asset = allAssets.find((asset) => asset.asset_name === assetName);
    return asset.asset_id;
  };

  const resolveSelectedAssetAmount = (assetName) => {
    if (assetName === ASSETS_NAME.IBFX_TYPE_I) {
      return props.ibfx1Amount;
    } else if (assetName === ASSETS_NAME.IBFX_TYPE_II) {
      return props.ibfx2Amount;
    }
  };

  const resolveContent = () => {
    if (!walletUser || Object.keys(walletUser) < 0) {
      return (
        <ContentStyled>
          <ColCenter>
            <Image preview={false} src={noWalletIllustration} />
            <Title className="text-w700 gray-7 mb-0px mt-24px" level={3}>
              {t("wallet_not_linked")}
            </Title>
            <Text> {t("wallet_not_linked_desc")}</Text>
            <Button
              type="primary"
              style={{
                width: "294px",
                marginTop: "24px",
              }}
              onClick={() => {
                handleCancel();
                dispatch(closeCart());
                history.push("/wallet-dashboard");
              }}
            >
              {t("create")}
            </Button>
          </ColCenter>
        </ContentStyled>
      );
    }

    if (
      // fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I) + resolveBalance(ASSETS_NAME.IBFX_TYPE_II)) <
      fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I)) <
      props.totalPayment - discount
    ) {
      return (
        <ContentStyled>
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px mt-0px" level={3}>
              {t("insufficient_funds")}
            </Title>
            <Text> {t("insufficient_funds_desc")}</Text>
            <BoxBalanceStyled>
              <span className="label">
                {t("your")} IBFX {t("balance")}
              </span>
              <span className="value">
                {fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I))}
              </span>
            </BoxBalanceStyled>
            {/* <BoxBalanceStyled className="mt-8px">
							<span className="label">
								{t('your')} IBFX - 2 {t('balance')}
							</span>
							<span className="value">{fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_II))}</span>
						</BoxBalanceStyled> */}
            <Col
              style={{
                width: "100%",
                marginTop: "20px",
                background: "#FCFCFC",
                border: "1px solid #EDEFF2",
                boxShadow: "0px 0px 25px 7px rgba(204, 213, 231, 0.14)",
                padding: "20px",
              }}
            >
              {props.paymentCardBody()}
            </Col>
            <Button
              type="primary"
              style={{
                width: "100%",
                marginTop: "24px",
              }}
              onClick={() => {
                handleCancel();
                dispatch(closeCart());
                history.push("/wallet-dashboard?defaultActiveKey=topup");
              }}
            >
              Add Funds
            </Button>
          </ColCenter>
        </ContentStyled>
      );
    }

    if (isProcessing || isSuccess || isFailed) {
      return (
        <ContentStyled>
          <ColCenter>
            <Progress
              type="circle"
              percent={processingPercent}
              status={isFailed && "exception"}
            />
            <Title className="text-w700 gray-7 mb-0px mt-24px" level={3}>
              {isProcessing && !isSuccess && !isFailed
                ? t("processing")
                : isSuccess && !isFailed
                ? t("success")
                : t("failed")}
            </Title>
            <Text className="gray-7" align="center">
              {isProcessing && !isSuccess && !isFailed
                ? t("transaction_success")
                : isSuccess && !isFailed
                ? t("transaction_thank_you")
                : t("payment_failed")}
            </Text>
            <Button
              type="primary"
              style={{
                width: "294px",
                marginTop: "24px",
              }}
              disabled={isProcessing}
              onClick={handleCancel}
            >
              {t("dismiss")}
            </Button>
          </ColCenter>
        </ContentStyled>
      );
    }

    // if (inputPin) {
    return (
      <ContentStyled>
        <ColSpaceBetween className="stretch" style={{ minHeight: 500 }}>
          <ColCenter>
            <Title className="text-w700 gray-7 mb-0px" level={3}>
              {t("pin")}
            </Title>
            <Text className="gray-7">{t("pin_desc")}</Text>
          </ColCenter>
          <RowCenter>
            <ReactCodeInput
              type="password"
              onChange={(e) => setPinCode(e)}
              fields={4}
              {...codeProps}
            />
          </RowCenter>
          <Row className="d-flex">
            <Col span={24}>
              <Row className="d-flex">
                <Col span={24}>
                  <Button
                    block
                    type="primary"
                    size="large"
                    onClick={() => {
                      const _checkPin = checkPin();
                      setInputPin(false);
                      if (_checkPin) {
                        onSubmit(2);
                      }
                    }}
                  >
                    {t("continue")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </ColSpaceBetween>
      </ContentStyled>
    );
    // }

    // return (
    // 	<ContentStyled>
    // 		<ColCenter>
    // 			<ModalTitle>{t('select_ibfx')}</ModalTitle>
    // 			<ModalSubTitle>{t('select_ibfx_desc')}</ModalSubTitle>
    // 		</ColCenter>
    // 		<Row className="mt-24px" gutter={[24, 16]}>
    // 			<Col span={12}>
    // 				<ColCenter>
    // 					<div
    // 						style={
    // 							assetType.includes(ASSETS_NAME.IBFX_TYPE_I)
    // 								? {
    // 										width: '100%',
    // 										background: '#FCFCFC',
    // 										border: '1px solid #024FF1',
    // 										padding: '12px',
    // 								  }
    // 								: {
    // 										background: '#FCFCFC',
    // 										border: '1px solid #D6DAE1',
    // 										boxSizing: 'border-box',
    // 										borderRadius: '2px',
    // 										width: '100%',
    // 										padding: '12px',
    // 								  }
    // 						}
    // 					>
    // 						<RowSpaceBetween wrap={false}>
    // 							<Row wrap={false}>
    // 								<Image preview={false} src={IBFx1Logo} width={40} />
    // 								<ColSpaceBetween
    // 									style={{
    // 										marginLeft: '8px',
    // 										alignItems: 'flex-start',
    // 									}}
    // 								>
    // 									<BalanceLabel>IBFX</BalanceLabel>
    // 									<BalanceValue>
    // 										{t('balance')} • {fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I))}
    // 									</BalanceValue>
    // 								</ColSpaceBetween>
    // 							</Row>
    // 							<Checkbox
    // 								checked={assetType.includes(ASSETS_NAME.IBFX_TYPE_I)}
    // 								onClick={() => onCheck(ASSETS_NAME.IBFX_TYPE_I)}
    // 							/>
    // 						</RowSpaceBetween>
    // 						{assetType.includes(ASSETS_NAME.IBFX_TYPE_I) ? (
    // 							<>
    // 								<InputNumberStyled
    // 									type="number"
    // 									className="mt-12px"
    // 									defaultValue={0}
    // 									style={{ width: '100%' }}
    // 									value={props.ibfx1Amount}
    // 									onChange={(v) => {
    // 										if (v == null) {
    // 											props.setIbfx1Amount(0)
    // 										} else {
    // 											props.setIbfx1Amount(v)
    // 										}
    // 									}}
    // 									min={0}
    // 									// max={fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_I))}
    // 								/>
    // 							</>
    // 						) : (
    // 							<></>
    // 						)}
    // 					</div>
    // 					<div
    // 						style={
    // 							assetType.includes(ASSETS_NAME.IBFX_TYPE_II)
    // 								? {
    // 										width: '100%',
    // 										background: '#FCFCFC',
    // 										border: '1px solid #024FF1',
    // 										padding: '12px',
    // 										marginTop: '16px',
    // 								  }
    // 								: {
    // 										background: '#FCFCFC',
    // 										border: '1px solid #D6DAE1',
    // 										boxSizing: 'border-box',
    // 										borderRadius: '2px',
    // 										width: '100%',
    // 										padding: '12px',
    // 										marginTop: '16px',
    // 								  }
    // 						}
    // 					>
    // 						<RowSpaceBetween wrap={false}>
    // 							<Row wrap={false}>
    // 								<Image preview={false} src={IBFx2Logo} width={40} />
    // 								<ColSpaceBetween
    // 									style={{
    // 										marginLeft: '8px',
    // 										alignItems: 'flex-start',
    // 									}}
    // 								>
    // 									<BalanceLabel>IBFX - 2</BalanceLabel>
    // 									<BalanceValue>
    // 										{t('balance')} • {fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_II))} | {t('max_limit')} •{' '}
    // 										{/* {fromIBFx(resolveBalance(ASSETS_NAME.IBFX_TYPE_II) / 2)} */}
    // 										{resolveIBFX2Limit()}
    // 									</BalanceValue>
    // 								</ColSpaceBetween>
    // 							</Row>
    // 							<Checkbox
    // 								checked={assetType.includes(ASSETS_NAME.IBFX_TYPE_II)}
    // 								onClick={() => onCheck(ASSETS_NAME.IBFX_TYPE_II)}
    // 								style={{
    // 									background: '#F4F6F7',
    // 								}}
    // 							/>
    // 						</RowSpaceBetween>
    // 						{assetType.includes(ASSETS_NAME.IBFX_TYPE_II) ? (
    // 							<>
    // 								<Divider />
    // 								<InputNumberStyled
    // 									type="number"
    // 									defaultValue={0}
    // 									style={{ width: '100%' }}
    // 									value={props.ibfx2Amount}
    // 									onChange={(v) => {
    // 										if (v == null) {
    // 											props.setIbfx2Amount(0)
    // 										} else {
    // 											props.setIbfx2Amount(v)
    // 										}
    // 									}}
    // 									min={0}
    // 									max={resolveIBFX2Limit()}
    // 									// max={fromIBFx(
    // 									//   resolveBalance(ASSETS_NAME.IBFX_TYPE_II) / 2
    // 									// )}
    // 								/>
    // 								{isMoreLimit && <Alert message={t('amount_cant_more_max_limit')} type="error" showIcon closable />}
    // 							</>
    // 						) : (
    // 							<></>
    // 						)}
    // 					</div>
    // 				</ColCenter>
    // 			</Col>
    // 			<Col
    // 				span={12}
    // 				style={{
    // 					background: '#FCFCFC',
    // 					border: '1px solid #EDEFF2',
    // 					boxShadow: '0px 0px 25px 7px rgba(204, 213, 231, 0.14)',
    // 					borderRadius: 4,
    // 				}}
    // 			>
    // 				<ColSpaceBetween className="stretch p-20px">
    // 					{props.paymentCardBody('pay')}
    // 					<Button
    // 						type="primary"
    // 						style={{
    // 							width: '100%',
    // 							marginTop: '12px',
    // 						}}
    // 						onClick={() => {
    // 							setInputPin(true)
    // 							// onSubmit()
    // 						}}
    // 					>
    // 						{t('confirm_pay')}
    // 					</Button>
    // 				</ColSpaceBetween>
    // 			</Col>
    // 		</Row>
    // 	</ContentStyled>
    // )
  };

  return Object.keys(walletUser).length <= 0 ||
    !localStorage.getItem("ENCRYPTED_MNEMONIC") ||
    allAssets.find((e) => e.asset_name === ASSETS_NAME.IBFX_TYPE_I)?.status !==
      ASSETS_STATUS.ACTIVE ? (
    <Modal
      centered
      className="modal-top-up"
      visible={props.isModalVisible}
      footer={null}
      onCancel={handleCancel}
      closeIcon={<img src={closeIcon} onClick={handleCancel} />}
      maskStyle={{ backgroundColor: "#f3f6fa" }}
    >
      <CourseOnBoarding />
    </Modal>
  ) : (
    <Modal
      centered
      visible={props.isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width={
        //   fromIBFx(
        //     resolveBalance(ASSETS_NAME.IBFX_TYPE_I) +
        //       resolveBalance(ASSETS_NAME.IBFX_TYPE_II)
        //   ) < props.totalPayment
        //     ? null
        //     :
        isProcessing || isSuccess || isFailed ? "500px" : "800px"
      }
      style={{
        zIndex: "99",
      }}
      closeIcon={<img style={{ marginRight: 10 }} src={closeIcon} />}
      closable={!(isProcessing || isFailed || isSuccess)}
    >
      {resolveContent()}
    </Modal>
  );
}
