import axios from 'axios'
import { CREDENCE_HOST } from '../../configs'
import { signingOperation } from '../wallet/action'
import { SET_ALL_LOANS, SET_LOAN_BY_DETAIL, SET_LOAN_REQUESTS } from '../constants'

export const getAllLoans =
	(ownerId, lenderId, page = 1, status, isOpen, isProvided, callback) =>
	async (dispatch) => {
		try {
			const jwt = localStorage.getItem('USER_AUTH_TOKEN')
			let url = `${CREDENCE_HOST}/loans?page=${page}&size=15&no_cache=1`
			if (ownerId) {
				url += `&owner_id=${ownerId}`
			}
			if (lenderId && lenderId !== 'open') {
				url += `&lender_id=${lenderId}`
			}
			if (status) {
				url += `&status=${status}`
			}
			if (isOpen) {
				url += `&is_open=${isOpen}`
			}
			if (isProvided) {
				url += `&is_provided=${isProvided}`
			}
			const results = (await axios.get(url, { headers: { Authorization: `Bearer ${jwt}` } })).data
			if (callback) {
				return results.data
			} else {
				if (lenderId) {
					dispatch({
						type: SET_LOAN_REQUESTS,
						payload: {
							data: results.data.records,
							currentPage: page,
							hasNext: page * 15 < results.data.total_items,
							total: results.data.total_items,
						},
					})
				} else {
					dispatch({
						type: SET_ALL_LOANS,
						payload: {
							data: results.data.records,
							currentPage: page,
							hasNext: page * 15 < results.data.total_items,
							total: results.data.total_items,
						},
					})
				}
			}
		} catch (e) {
			console.log(e)
		}
	}

export const setDetailLoan = (data) => async (dispatch) => {
	dispatch({
		type: SET_LOAN_BY_DETAIL,
		payload: data,
	})
}

export const postCreateLoan = async (mnemonic, data) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${CREDENCE_HOST}/loans`, data, {
				headers: headers,
			})
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const getDetailLoan = (loan_id) => async (dispatch) => {
	try {
		const jwt = localStorage.getItem('USER_AUTH_TOKEN')

		const nfts = (
			await axios.get(`${CREDENCE_HOST}/loans/${loan_id}`, {
				headers: { Authorization: `Bearer ${jwt}` },
			})
		).data

		dispatch(setDetailLoan(nfts.data.loan))
	} catch (e) {
		console.log(e)
	}
}

export const postProvideLoan = async (mnemonic, data) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${CREDENCE_HOST}/loans/provide`, data, {
				headers: headers,
			})
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const postPaybackLoan = async (mnemonic, data) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${CREDENCE_HOST}/loans/payback`, data, {
				headers: headers,
			})
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const postClaim = async (mnemonic, id) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.put(
				`${CREDENCE_HOST}/loans/claim/${id}`,
				{},
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const postClaimBack = async (mnemonic, id) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.put(
				`${CREDENCE_HOST}/loans/claim_back/${id}`,
				{},
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const postAddCollateralLoan = async (mnemonic, data) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.post(`${CREDENCE_HOST}/loans/deposit`, data, {
				headers: headers,
			})
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const rejectExtendedDeadline = async (mnemonic, id) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.put(
				`${CREDENCE_HOST}/loans/reject_extend/${id}`,
				{},
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}
export const acceptExtendedDeadline = async (mnemonic, id) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.put(
				`${CREDENCE_HOST}/loans/accept_extend/${id}`,
				{},
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const putExtendDeadline = async (mnemonic, id, data) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.put(`${CREDENCE_HOST}/loans/extend/${id}`, data, {
				headers: headers,
			})
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}

export const putClaimBackCollateral = async (mnemonic, id) => {
	try {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('USER_AUTH_TOKEN')}`,
		}
		const result = (
			await axios.put(
				`${CREDENCE_HOST}/loans/claim_back_collateral/${id}`,
				{},
				{
					headers: headers,
				}
			)
		).data

		const signedOperations = await signingOperation(mnemonic, result.data)
		await axios.post(`${CREDENCE_HOST}/operations/submit_signature`, signedOperations, {
			headers: headers,
		})
	} catch (e) {
		console.log(e)
		throw e.message
	}
}
