import { fromJS } from "immutable"
import { GET_COURSE_WISHLIST } from "../constants"

const initialState = fromJS({
  wishlist: fromJS([]),
})

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE_WISHLIST:
      return state.merge({
        wishlist: fromJS(action.payload),
      })

    default:
      return state
  }
}
