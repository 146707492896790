import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Typography,
  Button,
  Drawer,
  Row,
  Col,
  Progress,
  Spin,
  Alert,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  RowCenter,
  ColCenter,
  ButtonStyled,
  RowSpaceBetween,
  InputNumberStyled,
} from "../general_styled";

import { ContentStyled, ErrorCard } from "./styled";
import closeIcon from "../../assets/images/close.svg";
import useWindowDimensions from "../../utils/windowDimensions";
import { formatterCurrency, fromIBFx, toIBFx } from "../../utils/utils";
import { ASSETS_NAME } from "../../constants/constant_wallet";
import { BalanceCardStyled } from "../ModalWallet/styled";
import { IconCoins } from "../ModalImpact/styled";
import IBFxIcon from "../../assets/images/ibfx-icon.svg";
import {
  openModalLoginRegister,
  setSection,
} from "../../modules/general/action";
import ReactCodeInput from "react-code-input";
import { decryptMnemonic } from "../../utils/utils";
import { COMMON_CONSTANT } from "../../constants/constant_common";
import { createBidToAuction } from "../../modules/affluence/action";
import CredenceOnBoarding from "../../pages/CredenceOnBoarding";

const { Title, Text } = Typography;

export default function ModalAffluencePlaceBid(props) {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenAmount, setTokenAmount] = useState();
  const [isMode, setIsMode] = useState("confirmation");
  const [pinCode, setPinCode] = useState(null);
  const [isPinError, setPinError] = useState(false);
  const [processingPercent, setProcessingPercent] = useState(10);
  const [isProcessing, setProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);

  const allAssets = useSelector((state) =>
    state.getIn(["wallet", "allAssets"]).toJS()
  );
  const credenceUser = useSelector((state) =>
    state.getIn(["credence", "user"]).toJS()
  );

  const codeProps = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "6rem" : "18vw",
      height: windowDimensions.width > 835 ? "6rem" : "18vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "#828CA0",
      border: "1px solid #EDEFF2",
      borderRadius: "2px",
      textAlign: "center",
    },
    inputStyleInvalid: {
      fontFamily: "Roboto",
      fontWeight: 500,
      MozAppearance: "textfield",
      width: windowDimensions.width > 835 ? "5rem" : "20vw",
      height: windowDimensions.width > 835 ? "5rem" : "20vw",
      margin: "4px",
      fontSize: "40px",
      paddingLeft: "7px",
      backgroundColor: "#FBFBFB",
      color: "red",
      border: "1px solid red",
      borderRadius: "2px",
    },
  };

  const showModal = () => {
    if (!localStorage.getItem("USER_AUTH_TOKEN")) {
      return dispatch(openModalLoginRegister());
    }

    setIsModalVisible(true);
  };

  const checkPin = async () => {
    try {
      const getMnemonic = localStorage.getItem(
        COMMON_CONSTANT.ENCRYPTED_MNEMONIC
      );
      const _decryptMnemonic = await decryptMnemonic(getMnemonic, pinCode);
      setPinError(false);
      await onSubmit(_decryptMnemonic);
    } catch (e) {
      console.log(e);
      setPinError(true);
    }
  };

  const handleCancel = () => {
    setIsMode(0);
    setIsMode("confirmation");
    setIsModalVisible(false);
    setPinCode(null);
    setPinError(false);
    setProcessing(false);
    setIsSuccess(true);
    setProcessingPercent(10);
  };

  const onSubmit = async (mnemonic) => {
    setIsLoading(true);
    setIsMode("processing");
    setProcessing(true);

    const body = {
      auction_id: props.auctionId,
      total_ibfx: toIBFx(tokenAmount),
      total_nft: props.fragmentation,
    };
    try {
      await createBidToAuction(mnemonic, body);
      setProcessingPercent(100);
      setProcessing(false);
      setIsSuccess(true);
    } catch (error) {
      setProcessingPercent(0);
      setProcessing(false);
      setIsSuccess(false);
    }

    setIsLoading(false);
  };

  const resolveBalance = () => {
    const asset = allAssets.find(
      (asset) => asset.asset_name === ASSETS_NAME.IBFX_TYPE_I
    );
    return asset ? asset.amount : 0;
  };

  const resolveBalanceCard = () => {
    return (
      <Row className="w-100">
        <Col span={24}>
          <BalanceCardStyled>
            <RowSpaceBetween>
              <Title
                level={5}
                className="secondary text-size-12"
                style={{ marginBottom: 0, lineHeight: 1.7 }}
              >
                IBFX {t("balance")}
              </Title>
              <Title
                level={4}
                className="gray-8 text-size-14"
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                {formatterCurrency.format(fromIBFx(resolveBalance()) || 0)}
              </Title>
            </RowSpaceBetween>
          </BalanceCardStyled>
        </Col>
      </Row>
    );
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
  );

  const content = (
    <ContentStyled>
      {/* onActivateAssets */}
      {isMode === "confirmation" ? (
        <ColCenter>
          <Title className="text-w700 gray-7 mb-0px" level={3}>
            {t("place_bid")}
          </Title>
          <Text className="gray-7" align="center">
            {t("make_offer_desc")}
          </Text>
          {resolveBalanceCard()}
          <InputNumberStyled
            type="number"
            prefix={<IconCoins className="no-margin" src={IBFxIcon} />}
            suffix={"$" + ""}
            style={{ padding: "0px 0px 0px 12px", marginTop: 24 }}
            className="w-100 "
            placeholder={t("enter_pin")}
            min={0}
            value={tokenAmount}
            onChange={(value) => {
              setTokenAmount(value);
            }}
          />
          <ErrorCard>
            {t("bid_amount_required_1")}
            <br />
            {t("bid_amount_required_2")}
          </ErrorCard>

          <Button
            block
            disabled={!tokenAmount}
            style={{ height: 40, borderRadius: 4 }}
            type="primary"
            onClick={() => setIsMode("pin")}
          >
            {isLoading ? antIcon : "Place Bid"}
          </Button>
          <Button
            ghost
            block
            style={{ height: 40, borderRadius: 4 }}
            type="primary"
            className="mt-12px"
            onClick={() => dispatch(setSection("wallet-dashboard"))}
          >
            {t("add_funds")}
          </Button>
        </ColCenter>
      ) : isMode === "pin" ? (
        <ColCenter>
          <Title className="text-w700 gray-7 mb-0px" level={3}>
            {t("enter_pin")}
          </Title>
          <Text className="gray-7" align="center">
            {t("enter_pin_desc")}
          </Text>
          <Row className="mt-24px">
            <Col span={24}>
              <RowCenter>
                <ReactCodeInput
                  type="password"
                  onChange={(e) => setPinCode(e)}
                  fields={4}
                  {...codeProps}
                />
              </RowCenter>
            </Col>
            {isPinError && (
              <Col className="mt-10px" span={24}>
                <Alert message={t("enter_pin_error")} type="error" showIcon />
              </Col>
            )}
          </Row>
          <ButtonStyled
            style={{
              width: "40%",
              marginTop: 20,
              marginBottom: 70,
            }}
            onClick={() => checkPin()}
            disabled={isProcessing}
          >
            {!isProcessing ? t("confirm_pin") : <Spin />}
          </ButtonStyled>
        </ColCenter>
      ) : (
        <ColCenter>
          <Progress
            type="circle"
            percent={processingPercent}
            status={!isSuccess && "exception"}
          />
          <Title className="text-w700 gray-7 mb-8px mt-24px" level={3}>
            {isProcessing
              ? t("processing")
              : isSuccess
              ? t("success") + "!"
              : t("failed") + "!"}
          </Title>
          <Text style={{ width: "60%" }} className="gray-7" align="center">
            {isProcessing
              ? t("please_wait_place_bid")
              : isSuccess
              ? t("success_bid_placed")
              : t("error_occured_try_again")}
          </Text>
          {!isProcessing && (
            <ButtonStyled
              className="mt-24px"
              type="primary"
              danger={!isSuccess}
              style={{
                width: "350px",
                height: "48px",
                borderRadius: "4px",
              }}
              onClick={() => handleCancel()}
            >
              {t("dismiss")}
            </ButtonStyled>
          )}
        </ColCenter>
      )}
    </ContentStyled>
  );

  return (
    <>
      <ButtonStyled
        block
        disabled={props.disabled || false}
        style={{ height: 40, borderRadius: 4 }}
        className="text-size-14 text-w400"
        onClick={showModal}
      >
        {t("make_offer")}
      </ButtonStyled>
      {Object.keys(credenceUser).length <= 0 ||
      !localStorage.getItem("ENCRYPTED_MNEMONIC") ? (
        <Modal
          centered
          className="modal-top-up"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          closeIcon={<img src={closeIcon} onClick={handleCancel} />}
          maskStyle={{ backgroundColor: "#f3f6fa" }}
        >
          <CredenceOnBoarding />
        </Modal>
      ) : windowDimensions.width > 835 ? (
        <Modal
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          width="600px"
          footer={null}
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          {content}
        </Modal>
      ) : (
        <Drawer
          visible={isModalVisible}
          onCancel={handleCancel}
          placement="bottom"
          closeIcon={
            <img
              style={{ marginRight: 10 }}
              src={closeIcon}
              onClick={handleCancel}
            />
          }
        >
          {content}
        </Drawer>
      )}
    </>
  );
}
